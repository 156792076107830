import { connect } from 'react-redux'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { replace } from 'connected-react-router'
import withType from 'components/common/hoc/withType'
import { getLocationPathname } from 'selectors/getPageLocation'
import { ymmePageHasData, isExcludedPathname } from './helpers'
import { VEHICLE_NOT_FOUND } from 'constant'
import OneColumnPage from './OneColumnPage'

const mapStateToProps = ({ app: { isFetching }, ...state }) =>
  ({ isFetching, pathname: getLocationPathname({ ...state }) })

const withMaxWidthPaddingState = withState('maxWidthPadding', 'setMaxWidthPadding', false)

const withSetPadding = withHandlers({
  setPadding: ({ setMaxWidthPadding, pathname }) => () =>
    setMaxWidthPadding(!document.querySelector('[data-at-carousel-wrapper="home-carousel"]') && !isExcludedPathname(pathname))
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { setPadding } = this.props
    setPadding && setPadding()
  },
  componentDidUpdate({ isFetching: prevIsFetching }) {
    const {
      isFetching,
      setPadding,
      pathname,
      placeholders: { mainContent } = {},
      replace
    } = this.props

    if (prevIsFetching && !isFetching) {
      setPadding && setPadding()
      pathname.startsWith('/tires/by-vehicle') && !ymmePageHasData(mainContent) && replace(VEHICLE_NOT_FOUND)
    }
  }
})

export default compose(
  withType({
    type: 'OneColumnPage',
    placeholders: ['footer', 'header', 'mainContent']
  }),
  connect(mapStateToProps, { replace }),
  withMaxWidthPaddingState,
  withSetPadding,
  withLifecycle
)(OneColumnPage)
