import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Modal from 'components/common/Modal'

const BenefitsModal = ({
  benefits = [],
  error,
  handleHide,
  handleUseEnteredAddress,
  handleUseVerifiedAddress,
  ...props
}) =>
  <Modal title="BENEFITS" closeHandler={ handleHide } { ...props }>
    <List>
      {
        benefits.length && benefits.map(benefit =>
          <Item key={ benefit }>{ benefit }</Item>
        )
      }
    </List>
  </Modal>

export default BenefitsModal

BenefitsModal.propTypes = {
  benefits: PropTypes.shape(),
  error: PropTypes.string,
  handleHide: PropTypes.func,
  handleUseEnteredAddress: PropTypes.func,
  handleUseVerifiedAddress: PropTypes.func
}

const List = styled.ul`
  padding: 0;
`

const Item = styled.li`
  line-height: 22px;

  & + & {
    margin-top: 20px;
  }

  ::before {
    display: inline-block;
    width: 3px;
    height: 3px;
    margin-right: 11px;
    clear: both;
    vertical-align: middle;
    background-color: ${Theme.colors.dark_red};
    border-radius: 50%;
    content: '';
  }
`
