import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Media from 'components/common/Media'
import getComponent from 'helpers/component-helper'
import SearchModalForm from '../../SearchModalForm'


const GlobalSearchModal = ({
  title,
  autoSuggestions: {
    contents = []
  } = {},
  data: {
    modalBoxContent
  },
  handleHide
}) =>
  <CustomModal data-at-globalsearchmodal closeHandler={ handleHide } title={ title }>
    <SearchModalForm />
    {
      contents.map((data, index) =>
        React.cloneElement(getComponent(data), { key: index }))
    }
    {
      modalBoxContent.map((data, index) =>
        React.cloneElement(getComponent(data), { key: index }))
    }
  </CustomModal>

export default GlobalSearchModal

GlobalSearchModal.propTypes = {
  autoSuggestions: PropTypes.shape(),
  data: PropTypes.shape({
    modalBoxContent: PropTypes.arrayOf()
  }),
  handleHide: PropTypes.func,
  title: PropTypes.string
}

const CustomModal = styled(Modal)`
  padding-top: 64px;
  ${Media.desktop`
    width: 714px;
    padding: 30px 23px 0;
  `}
`
