import { withHandlers } from 'recompose'
import { scrollToHeader } from 'helpers/scroll'

export default withHandlers({
  scrollToAnchorHandler: ({ targetId, padding }) => () => {
    const elem = document.getElementById(targetId)
    elem && elem.focus && elem.focus()
    scrollToHeader({ element: elem, padding })
  }
})
