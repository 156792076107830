import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'
import { VerticalSeparator } from 'components/common/Separator/Separator'
import { TabPanel } from 'components/common/Tabs/Tabs.styles'
import Promotions from './components'
import { ALL, NATIONWIDE, LOCAL } from './constants'

export const PromotionsList = ({
  regionName,
  storePromotions,
  promotionResultList,
  position,
  setFocusScopeRef,
  menu,
  onClick,
  onKeyDown,
  myStore: {
    address: {
      address1,
      city,
      state,
      zipcode
    } = {}
  } = {},
  storeName,
  noPromotionsMessage,
  homePageVersion,
  ...props
}) =>
  <React.Fragment>
    <TabsWrapper>
      <CustomTabs
        promotionResultList={ promotionResultList }
        storePromotions={ storePromotions }
        aria-label="Tab list"
        role="tablist"
        ref={ setFocusScopeRef }>
        {
          menu.map(({ title }, index) =>
            <React.Fragment key={ index }>
              <TabItem
                id={ `${kebabCase(title)}-tab-item-${index}` }
                link
                role="tab"
                aria-controls={ `${kebabCase(title)}-tab-panel-${index}` }
                aria-selected={ position === index }
                active={ position === index }
                tabIndex={ position === index ? null : -1 }
                onClick={ onClick }
                onKeyDown={ onKeyDown }>
                { title === ALL && <Item>{ title }</Item> }
                { title === NATIONWIDE && <Item>Nationwide <span data-desktop-only>Offers</span></Item> }
                { title === LOCAL &&
                  <React.Fragment>
                    <Media.Desktop>
                      <Item>
                        Offers for {
                          homePageVersion ? `${address1}` :
                            regionName ? <Item titleCase>{ regionName }</Item> :
                              storePromotions ? `${storeName}` : `${address1} ${city} ${state} ${zipcode}`
                        }
                      </Item>
                    </Media.Desktop>
                    <Media.Mobile>
                      <Item titleCase={ regionName }>
                        {
                          homePageVersion ? `${address1}` :
                            regionName || (storePromotions ? `${storeName}` : 'My Store')
                        }
                      </Item>
                    </Media.Mobile>
                  </React.Fragment>
                }
              </TabItem>
              { index < menu.length - 1 && <VerticalSeparator /> }
            </React.Fragment>
          )
        }
      </CustomTabs>
    </TabsWrapper>
    {
      menu.map(({ title }, index) =>
        <CustomTabPanel
          key={ index }
          role="tabpanel"
          className="tab-panel"
          id={ `${kebabCase(title)}-tab-panel-${index}` }
          tabIndex="0"
          aria-labelledby={ `${kebabCase(title)}-tab-item-${index}` }
          active={ position === index }
          storePromotions={ storePromotions }>
          <Promotions
            title={ title }
            regionName={ regionName }
            noResult={ noPromotionsMessage }
            homePageVersion={ homePageVersion }
            { ...props } />
        </CustomTabPanel>
      )
    }
  </React.Fragment>


PromotionsList.propTypes = {
  homePageVersion: PropTypes.bool,
  menu: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string
  })),
  myStore: PropTypes.shape(),
  noPromotionsMessage: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  position: PropTypes.number,
  promotionResultList: PropTypes.arrayOf(PropTypes.shape()),
  regionName: PropTypes.string,
  setFocusScopeRef: PropTypes.func,
  storeName: PropTypes.string,
  storePromotions: PropTypes.arrayOf(PropTypes.shape())
}

const TabsWrapper = styled.div`
  ${Mixin.clearFix()}
`

const CustomTabs = styled.div`
  ${props => props.promotionResultList && css`
    text-align: center;
    ${Mixin.responsive('padding', '0 12px 28px', '12px')}
  `}
  ${props => props.storePromotions && css`
    float: left;
  `}
`

const TabItem = styled(Button)`
  display: inline-block;

  && {
    padding: 0;
    border-bottom: 1px solid transparent;
    ${Mixin.font.GothamBold(14)}
  }

  :hover {
    border-bottom: 1px solid ${Theme.colors.blue};
  }

  ${props => props.active && css`
    && { border-bottom: 1px solid ${Theme.colors.blue}; }
  `}
  ${props => props.myStore && css`
    margin-left: 21px;
  `}
`

const Item = styled.span`
  display: inline-block;

  &,
  & div,
  & span {
    ${Mixin.font.GothamBold(14)}
  }

  ${props => props.titleCase && css`
    text-transform: capitalize;
  `}
`

const CustomTabPanel = styled(TabPanel)`
  ${props => props.storePromotions && css`
    padding: 30px 0 0;
    margin: 0 -12px;
  `}
`
