import { LOCATION_CHANGE } from 'connected-react-router'
import { APP_REQUEST, APP_SUCCESS, APP_FAILURE, APP_START_LOADING, APP_STOP_LOADING } from './actions'
import { NAVIGATIONS_UPDATE } from '../NavigationsTracker/actions'
import { handleActions } from 'redux-actions'

const initialState = {
  isFetching: false,
  isLoading: false,
  isError: false,
  error: null,
  data: null,
  isNavigating: false
}


const app = handleActions({
  [NAVIGATIONS_UPDATE]: (state) =>
    ({ ...state,
      isNavigating: false
    }),
  [LOCATION_CHANGE]: (state) =>
    ({ ...state,
      isNavigating: true
    }),
  [APP_REQUEST]: (state) =>
    ({ ...state,
      isFetching: true,
      isError: false
    }),
  [APP_SUCCESS]: (state, { payload: { data }}) =>
    ({ ...state,
      isFetching: false,
      isNavigating: false,
      data
    }),
  [APP_FAILURE]: (state, { payload: { data }}) =>
    ({ ...state,
      isFetching: false,
      isNavigating: false,
      isError: true,
      error: data && data.message ? data.message : data
    }),
  [APP_START_LOADING]: (state) =>
    ({ ...state,
      isLoading: true
    }),
  [APP_STOP_LOADING]: (state) =>
    ({ ...state,
      isLoading: false
    })
}, initialState)


export default app
