import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import getSearchByVehicleUrl from 'helpers/getSearchByVehicleUrl'
import { getSearchByTireSizeUrlFromVehicle } from 'helpers/getSearchByTireSizeUrl'
import getActiveVehicle, { getIsActiveVehicleTrailer } from 'selectors/getActiveVehicle'
import { getNumberOfTiresOptionsAvailable } from './actions'
import AvailableTires from './AvailableTires'

const mapStateToProps = state => {
  const activeVehicle = getActiveVehicle(state)
  const isTrailer = getIsActiveVehicleTrailer(state)

  return {
    searchUrl: isTrailer ?
      getSearchByTireSizeUrlFromVehicle({ activeVehicle, isTrailer }) :
      getSearchByVehicleUrl(activeVehicle),
    activeVehicleModelId: activeVehicle?.modelId,
    tireSize: activeVehicle?.formattedSize,
    storeId: state.myStore?.storeId,
    numberOfAvailableTires: state.availableTires.numberOfAvailableTires
  }
}

const mapDispatchToProps = dispatch => ({
  getNumberOfTiresOptionsAvailable: bindActionCreators(getNumberOfTiresOptionsAvailable, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailableTires)
