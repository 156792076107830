import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { rest } from 'services'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import PromotionExpirationModal from './PromotionExpirationModal'
import { PROMOTION_EXPIRATION_MODAL } from '../../constants'
import { removePromoFromCart, setModalWasShown, setPromotionDetails } from './actions'
import { formatPhoneNumber } from 'helpers/phone-number'
import { getCurrentStorePhoneNumber } from 'selectors/getCurrentStore'
import { setShoppingData } from 'actions/shoppingData'
import { setShowInRangePromoExpirationNotification } from 'components/ShoppingQuote/BOTProductAndServicesQuoteNew/components/PromotionExpirationModal/actions.js'
import { getLocationPathname } from 'selectors/getPageLocation'
import { getIsOnlineAppointment } from 'components/QuickAppointmentPage/BOTAppointmentSummary/helpers'
import {
  APPOINTMENT_FORM_NAME,
  ACTIVE_PROMOTIONS_FIELD_NAME
} from 'constant'

const mapStateToProps = (state) => {
  const pathname = getLocationPathname(state)

  return {
    storePhoneNumber: getCurrentStorePhoneNumber(state),
    formattedStorePhoneNumber: formatPhoneNumber(getCurrentStorePhoneNumber(state)),
    isQuickAppointmentPage: getIsOnlineAppointment({ pathname })
  }
}

const withExpirationPromoModalHandlers = withHandlers({
  keepPromoHandler: ({
    handleHide,
    onChange,
    setSelectedDate,
    firstAvailableDate,
    quotedItemAndServices,
    setShoppingData,
    setModalWasShown,
    setShowInRangePromoExpirationNotification,
    isQuickAppointmentPage
  }) => () => {
    if (isQuickAppointmentPage) {
      onChange({ date: firstAvailableDate, time: undefined })
      setSelectedDate({ date: firstAvailableDate, time: undefined })
      handleHide()
    } else {
      onChange({ date: firstAvailableDate, time: undefined })
      setSelectedDate({ date: firstAvailableDate, time: undefined })
      setShoppingData({ quotedItemAndServices })
      setModalWasShown(false)
      setShowInRangePromoExpirationNotification(false)
      handleHide()
    }
  },
  removePromoHandler: ({
    handleHide,
    appliedPromotions,
    removePromoFromCart,
    setPromotionDetails,
    isQuickAppointmentPage,
    setModalWasShown,
    change
  }) => () => {
    if (isQuickAppointmentPage) {
      const payload = {
        promotion: appliedPromotions[0].promotionId,
        action: 'removePromo'
      }

      rest.api.addOrRemoveServicePromotion(payload)
        .catch(error => {
          console.log(error)
        })

      change(APPOINTMENT_FORM_NAME, ACTIVE_PROMOTIONS_FIELD_NAME, [])
      setModalWasShown(true)
      handleHide()
    } else {
      const promoIds = appliedPromotions.map(({ promotionId }) => promotionId)
      setPromotionDetails(appliedPromotions)
      removePromoFromCart({ promoIds })
      handleHide()
    }
  }
})

export default compose(
  registerReduxModal({ name: PROMOTION_EXPIRATION_MODAL, destroyOnHide: true }),
  connect(mapStateToProps, {
    removePromoFromCart,
    setShoppingData,
    setModalWasShown,
    setPromotionDetails,
    setShowInRangePromoExpirationNotification,
    change
  }),
  withExpirationPromoModalHandlers
)(PromotionExpirationModal)
