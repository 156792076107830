import { EDIT_MILEAGE_MODAL } from '../../constants'
import { hide } from 'redux-modal'
import { SubmissionError } from 'redux-form'
import { rest } from 'services'
import { refresh } from 'actions/router'

export const handleSubmit = ({ mileage }, dispatch, props) => {
  const { userVehicleId } = props
  return rest.api.updateVehiclePersonalDetails({ mileage, userVehicleId })
    .then(() => {
      dispatch(hide(EDIT_MILEAGE_MODAL))
      dispatch(refresh())
    })
    .catch(err => {
      throw new SubmissionError({
        _error: err.statusList[0].description
      })
    })
}
