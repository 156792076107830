import styled from 'styled-components'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import Link, { A } from 'components/common/Link/Link'
import PhoneLink from 'components/common/PhoneLink'
import Modal from 'components/common/Modal'
import Text from 'components/common/Text/Text'

export const CallStoreModal = styled(Modal)`
  ${Media.tabletNarrow`
    top: ${props => props.dynamicTop}px;
  `}

  padding: 53px 28px 0 !important;
  ${Media.mediumLargeDevices`
    width: 716px;
    height: fit-content;
    top: 50%;
    left: 50%;
    max-height: 80%;
    transform: translate(-50%, -50%);
  `}

  [data-printonly] {
    display: block;
    width: 85px;
    height: 14px;
    ${Media.mediumLargeDevices`
      width: 152px;
      height: 25px;
    `}
  }

  & .modal-header {
    border-bottom: none;
    padding: 0;
  }

  & .modal-body {
    padding: 0;
    margin-top: 18px;
    ${Media.mediumLargeDevices`
      position: relative;
      z-index: 2;
      overflow: visible;
      border-top: 1px solid ${Theme.colors.light_grey};
      margin-top: 23px;
      height: fit-content;
    `}
  }

  #dialog-heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
    margin-top: 6px;
    display: inline-block;
    ${Media.mediumMobile`
      font-size: 20px;
    `}
    letter-spacing: 0;
  }
`

const SharedStyle = `
  font-size: 20px;
  position: absolute;
  top: -53px;
`
export const CallText = styled(Text)`
  font-weight: 700;
  line-height: 24px;
  ${Media.mediumLargeDevices`
    ${SharedStyle}
    margin-left: 80px;
    left: 386px;
  `}
`

export const PhoneLinkText = styled(PhoneLink)`
  ${Media.tabletMedium`
    ${SharedStyle}
    left: 480px;
  `}
`

export const StoreLocationOnMap = styled.div`
  width: 407px;
  height: 120px;
  margin-top: 3px;
  ${Media.mediumLargeDevices`
    height: 219px;
  `}
`

export const StoreAddress = styled(A)`
  font-weight: 700;
  line-height: 24px;
  ${Media.mediumLargeDevices`
    font-size: 16px;
  `}
`

export const StoreDetailsCta = styled(Link)`
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 22px;
  display: block;
  ${Media.mediumLargeDevices`
    font-size: 14px;
  `}
`

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  ${Media.mediumLargeDevices`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 49px;
  `}
`

export const RightContentWrapper = styled.div`
  margin-top: 24px;
  ${Media.mediumLargeDevices`
    margin-top: 0;
    margin-left: 34px;
  `}
`

export const TodaysTimeWrapper = styled.div`
  margin-top: 12px;
`

export const TomorrowTimeWrapper = styled.div`
  margin-top: 20px;
`

export const DisplayTimeWrapper = styled.div``

export const TimeTextHeading = styled(Text)`
  font-weight: 700;
  line-height: 24px;
  ${Media.desktop`
    font-size: 16px;
  `}
`
export const TimeTextValue = styled(Text)`
  padding-left: 8px;
  ${Media.mediumLargeDevices`
    padding-left: 0;
    display: block;
    font-size: 16px;
  `}
`

