import { withProps } from 'recompose'
import { ShopByPlateModal } from 'components/VehicleConfigurator'
import { TireSizesNotFoundContinueBtnText } from '../constants'

const withTireSizesNotFoundModalProps = withProps(({ resetLicencePlateForm }) => ({
  tireSizesNotFoundModalProps: {
    onContinueClick: resetLicencePlateForm,
    continueButtonText: TireSizesNotFoundContinueBtnText
  }
}))

export default withTireSizesNotFoundModalProps(ShopByPlateModal)
