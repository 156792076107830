import { compose, withHandlers } from 'recompose'
import withType from 'components/common/hoc/withType'
import withNavigateToServicesOrShowAddVehicle from 'components/common/hoc/withNavigateToServicesOrShowAddVehicle'
import HeaderMenuLink from './../HeaderMenuLink'

const withShopServicesHandler = withHandlers({
  handleClick: ({ navigateToServicesOrShowAddVehicle }) => (e) => {
    e.preventDefault()
    navigateToServicesOrShowAddVehicle()
  }
})

export default compose(
  withType({ type: 'ServicesHeaderMenuLink' }),
  withNavigateToServicesOrShowAddVehicle,
  withShopServicesHandler
)(HeaderMenuLink)
