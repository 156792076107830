import { css } from 'styled-components'
import Media from 'components/common/Media'

const property = (property, styles) => css`
  ${props => props[property] === true && css([styles])}
  ${props => props[property] === 'desktop' && css`
    ${Media.desktop([styles])}
  `}
  ${props => props[property] === 'mobile' && css`
    ${Media.mobile([styles])}
  `}
`

export default property
