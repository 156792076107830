import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Input } from 'components/common/FormElements/Input'
import Icon from 'components/common/Icon/Icon'
import { ICON_WIDTH, ICON_INDENT } from './constants'

const CheckValidationFormField = ({
  meta: {
    valid
  },
  meta,
  input: {
    value
  },
  input,
  ...props
}) =>
  <Input { ...props } full input={ input } meta={ meta } Icon={ value && valid && <CheckedIcon noLazyLoad /> } />

export default CheckValidationFormField

CheckValidationFormField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string
  }),
  meta: PropTypes.shape({
    valid: PropTypes.bool
  })
}

const CheckedIcon = styled(Icon).attrs({
  asset: 'check-circle',
  width: ICON_WIDTH
})`
  position: absolute;
  right: ${ICON_INDENT}px;
  top: 10px;
  cursor: pointer;
`
