import { QUICK_APPOINTMENT_PAGE, QUOTE_PAGE, QUOTE_SUMMARY_PAGE } from 'constant'
import { getDateByFormat } from 'helpers/time-format'

export const getIsOnlineAppointment = ({ pathname = '' }) =>
  pathname === QUICK_APPOINTMENT_PAGE

export const getIsQuotePage = ({ pathname = '' }) =>
  pathname === QUOTE_PAGE


export const getIsQuoteSummaryPage = ({ pathname = '' }) =>
  pathname === QUOTE_SUMMARY_PAGE

export const formatDate = (appointmentDate) => {
  const date = new Date(appointmentDate)

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export const isDateInAppointments = ({ appointmentDate: { date = '', time: { value } = {}} = {}, dates = {}}) => {
  const [monthYear = '', day = ''] = (getDateByFormat({ date, format: 'MM/YYYY DD' }) || '').split(' ') || []

  const {
    [monthYear]: {
      [day]: [
        ...slots
      ] = []
    } = {}
  } = dates || {}

  return !!date && (slots || [{}]).some(({ value: slotValue }) => !!slotValue && slotValue === value)
}

export const getUserVehicleName = vehicles => vehicles && vehicles[0] && vehicles[0].vehicleNickname
