import { reset } from 'redux-form'
import { show, hide } from 'redux-modal'
import { VEHICLE_CONFIGURATOR_MODAL, CONFIRM_TIRE_SIZE_MODAL } from 'constant'
import { YMME_NAME, LICENSE_PLATE_NAME } from './constants'
import { withYmmeInners } from 'components/common/hoc/withYmme'
import { withTireSizeInners } from 'components/VehicleConfigurator'

const {
  actions: {
    handleYmmeReset
  } = {}
} = withYmmeInners

const {
  actions: {
    setDefaultState: handleShopByTiresSetValuesToDefault,
    setCommittedState: handleShopByTiresSetValuesToCommitted,
    handleCloseRearTireSize
  } = {}
} = withTireSizeInners

export const showConfirmTireSizeModal = props => show(CONFIRM_TIRE_SIZE_MODAL, props)

export const showVehicleConfiguratorModal = props => show(VEHICLE_CONFIGURATOR_MODAL, props)
export const hideVehicleConfiguratorModal = () => hide(VEHICLE_CONFIGURATOR_MODAL)

export const resetYmme = () => handleYmmeReset({ ymmeName: YMME_NAME })
export const resetLicencePlateForm = () => reset(LICENSE_PLATE_NAME)

export const resetShopByTires = () => handleShopByTiresSetValuesToDefault({ isModal: true })
export const resetTiresToCommitted = () => handleShopByTiresSetValuesToCommitted({ isModal: true })
export const closeModalRearTireSize = () => handleCloseRearTireSize({ isModal: true })
