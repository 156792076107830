import { compose, lifecycle } from 'recompose'
import GlobalMessagePlaceholder from './GlobalMessagePlaceholder'

const withGlobalMessageUpdates = lifecycle({
  componentDidUpdate({ globalHeaderMessage: prevGlobalHeaderMessage }) {
    const { handleChange, globalHeaderMessage } = this.props
    if (globalHeaderMessage !== prevGlobalHeaderMessage) {
      handleChange()
    }
  }
})

export default compose(
  withGlobalMessageUpdates
)(GlobalMessagePlaceholder)
