import React from 'react'
import PropTypes from 'prop-types'
import { ModalBox } from '../Shared.styles'
import getComponent from 'helpers/component-helper'

const EmailSignUpModal = ({
  data: { modalBoxContent = []},
  modalWindowTitle,
  handleHide,
  ...props
}) =>
  <ModalBox title={ modalWindowTitle } closeHandler={ handleHide }>
    {
      modalBoxContent
        .map((Component, index) => React.cloneElement(getComponent(Component), { ...props, key: index, handleHide }))
    }
  </ModalBox>

export default EmailSignUpModal

EmailSignUpModal.propTypes = {
  children: PropTypes.node,
  data: PropTypes.shape(),
  handleHide: PropTypes.func,
  modalWindowTitle: PropTypes.string
}

