import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HEADER_CONTAINER_ID } from 'constant'

const StickyHeaderWrapper = ({ sticky, id = HEADER_CONTAINER_ID, handleHeaderChange, children, ...props }) =>
  <Fragment>
    <HeaderContainerView { ...props } id={ id } data-sticky={ sticky || null } data-printless>
      { children }
    </HeaderContainerView>
  </Fragment>

StickyHeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  handleHeaderChange: PropTypes.func,
  id: PropTypes.string,
  sticky: PropTypes.bool
}

const HeaderContainerView = styled.div`
  width: 100%;
  height: ${props => props.dynamicHeight}px;
`

export default StickyHeaderWrapper
