const tireSkuRegex = /(\d)+(-BOT)/
export const validateAddToCartItem = skuId => tireSkuRegex.test(skuId)

export const createSkuIDList = (products = [], promotions = [], services = [], otdpAddons = []) =>
  [...products, ...promotions, ...services, ...otdpAddons]
    .map(({ skuId, promotionId, serviceId, partNo }) => skuId || promotionId || serviceId || partNo)
    .join()

/* eslint-disable camelcase */
export const formatFullStoryProductObj = ({
  id: product_id_str,
  category: category_str,
  name: name_str,
  brand: brand_str,
  variant: variant_str,
  price: price_real,
  dimension2: gbblabel_str
}) => ({
  product_id_str,
  category_str,
  name_str,
  brand_str,
  variant_str,
  price_real,
  gbblabel_str
})
/* eslint-enable camelcase */

export const reduceAdjustmentAmount = (acc, currVal) => acc + currVal

export const createAddToQuoteConversionData = (products, skuId) => {
  const filteredProducts = products.length && products.filter(({ skuId: productSkuId }) => productSkuId === skuId)
  const { rawTotalPrice = '', otdpAddons = [], promotions = []} = filteredProducts[0] || {}

  const totalOTDPPricing = otdpAddons.length &&
    (otdpAddons.map(({ price }) => price).reduce(reduceAdjustmentAmount) || 0)

  const filteredPromotions = (promotions.length && promotions.filter(({ promotionRebate }) => !promotionRebate)) || []
  const totalPromotionsDeduction = filteredPromotions.length &&
    (filteredPromotions.map(({ adjustmentAmount }) => adjustmentAmount).reduce(reduceAdjustmentAmount) || 0)

  const revenue = (rawTotalPrice + totalOTDPPricing) - totalPromotionsDeduction
  const skuIDList = createSkuIDList(filteredProducts, filteredPromotions, [], otdpAddons)

  return {
    revenue,
    skuIDList
  }
}

export const setSessionStorageItem = (item, value) =>
  sessionStorage && sessionStorage.setItem && sessionStorage.setItem(item, JSON.stringify(value))
