import { compose } from 'redux'
import { connect } from 'react-redux'
import { mapProps, withProps, withHandlers, lifecycle, branch } from 'recompose'
import isEqual from 'lodash/isEqual'
import * as actions from './actions'
import * as selectors from './selectors'
import { getLabelGetter } from './helpers'
import { YEARS } from './constants'
import createYmmeFormViewModel from './viewModel'

const getHandlers = ymmeName => (onClick, onSelect) => ({
  onChange: selection => onSelect({ value: selection ? selection.value : '', ymmeName }),
  onClick: () => onClick({ ymmeName })
})

const withLabelFormatter = withProps(() => ({
  getLabelGetter
}))

const withGroupedActions = mapProps(({
  handleYearSelect,
  handleYearsFilterChange,
  toggleYears,
  handleMakeSelect,
  handleMakesFilterChange,
  toggleMakes,
  handleModelSelect,
  handleModelsFilterChange,
  toggleModels,
  handleSubModelSelect,
  handleSubModelsFilterChange,
  toggleSubModels,
  ...props
}) => ({
  ...props,
  ymmeHandlers: {
    years: getHandlers(props.ymmeName)(toggleYears, handleYearSelect, handleYearsFilterChange),
    makes: getHandlers(props.ymmeName)(toggleMakes, handleMakeSelect, handleMakesFilterChange),
    models: getHandlers(props.ymmeName)(toggleModels, handleModelSelect, handleModelsFilterChange),
    subModels: getHandlers(props.ymmeName)(toggleSubModels, handleSubModelSelect, handleSubModelsFilterChange)
  }
}))

const withRegisterYmme = lifecycle({
  UNSAFE_componentWillMount() {
    // ymme state for this name will be created or used if it already exists
    const { ymmeName, registerYmme } = this.props

    if (!ymmeName) {
      throw new Error('You don\'t pass either ymmeName property or miss name property in withYmme hoc')
    }

    registerYmme({ ymmeName: this.props.ymmeName })
  }
})

const withFetchingYearsOnDidMount = lifecycle({
  componentDidMount() {
    // ymme state for this name will be created or used if it already exists
    const { ymmeName, vehicle, years, resetYmme } = this.props
    // If the component already has data for the first dropdown with years,
    // we just set them in redux-store, and do not make an additional requst for years
    if (years) {
      this.props.setValues({ name: YEARS, value: years, ymmeName })
    }
    resetYmme()
    this.props.initialize({ ymmeName, vehicle })
  },
  componentDidUpdate({ vehicle: { vehicleName: prevVehicleName } = {}}) {
    const { ymmeName, vehicle: { vehicleName } = {}, vehicle } = this.props
    if (vehicleName) {
      const activeVehicleChanged = !isEqual(prevVehicleName, vehicleName)
      if (activeVehicleChanged) {
        this.props.initialize({ ymmeName, vehicle })
      }
    }
  }
})

const withOnSubmitAndReset = withHandlers({
  onSubmit: ({ submit, ymmeName, skipTireSizeExperience }) => () =>
    submit({ ymmeName, skipTireSizeExperience }),

  resetYmme: ({ handleYmmeReset, ymmeName }) => () =>
    handleYmmeReset({ ymmeName })
})

const makeMapStateToProps = () => {
  const getYmmeState = selectors.makeGetYmmeState()

  const mapStateToProps = (state, props) =>
    getYmmeState(state, props)

  return mapStateToProps
}

const withYmmeViewModel = withProps(
  ({
    ymme: {
      years: { values: yearsOptions } = {},
      makes: { values: makesOptions } = {},
      models: { values: modelsOptions } = {},
      subModels: { values: subModelsOptions } = {}
    } = {}
  }) => ({
    model: createYmmeFormViewModel({
      yearsOptions,
      makesOptions,
      modelsOptions,
      subModelsOptions
    })
  })
)

export const withYmmeInners = {
  actions,
  selectors
}

export default ({ name, allowMultiColumnMenu = false }) =>
  compose(
    withProps(({ ymmeName }) => ({
      ymmeName: ymmeName || name,
      allowMultiColumnMenu
    })),
    connect(makeMapStateToProps, actions),
    withRegisterYmme,
    withOnSubmitAndReset,
    branch(({ customLifecycle }) => !customLifecycle, withFetchingYearsOnDidMount),
    withGroupedActions,
    withLabelFormatter,
    withYmmeViewModel
  )
