import { connect } from 'react-redux'
import { compose, branch, renderComponent, lifecycle, withPropsOnChange, renderNothing, withProps } from 'recompose'
import { loadShoppingData } from 'actions/shoppingData'
import { init } from './actions'
import NotFound from 'components/NotFound/NotFound'
import { handleCustomActionsOnClick } from './globalEventListeners'
import Main from './Main'
import some from 'lodash/some'
import valuesIn from 'lodash/valuesIn'
import with3rdPartyData from 'components/common/hoc/with3rdPartyData/with3rdPartyData'
import { getStoreToProfileByIp } from 'components/common/hoc/withMakeThisMyLocation/actions'
import isHomePage from 'helpers/isHomePage'
import { getLocationPathname } from 'selectors/getPageLocation'
import { setPilotStoreInfo } from 'actions/storeLocator.js'
import { getUserData } from '../Appointment/selectors'

const mapStateToProps = (state) => {
  const { app: { isFetching, isLoading, isError, data }, modal = {}} = state
  const { isPilotStore } = getUserData(state)

  return {
    isHomePage: isHomePage(getLocationPathname(state)),
    isFetching,
    isLoading,
    isError,
    data,
    isModal: some(valuesIn(modal), Boolean),
    isPilotStore
  }
}

const withNotFound = branch(
  ({ isError }) => isError,
  renderComponent(NotFound)
)

const withHideIfNoData = branch(
  ({ data }) => !data,
  renderNothing
)

const withInitProps = withPropsOnChange(
  ({ location: currentLocation }, { location }) =>
    currentLocation !== location &&
    (
      currentLocation.search !== location.search ||
      currentLocation.pathname !== location.pathname ||
      (location.state && location.state.update)
    ),
  ({ location: { pathname, search }}) => ({
    initProps: { pathname, query: search.slice(1) }
  })
)

const withInit = lifecycle({
  componentDidMount() {
    const {
      data,
      init,
      initProps,
      loadShoppingData,
      handleCustomActionsOnClick,
      getStoreToProfileByIp,
      isHomePage,
      setPilotStoreInfo,
      isPilotStore
    } = this.props

    setPilotStoreInfo(isPilotStore)

    if (!data) {
      init(initProps)
    }

    loadShoppingData() // load shopping data from localStorage to redux.

    window.addEventListener('click', handleCustomActionsOnClick)

    if (isHomePage) {
      getStoreToProfileByIp()
    }
  },

  UNSAFE_componentWillReceiveProps({ initProps: nextInitProps }) {
    nextInitProps !== this.props.initProps && this.props.init(nextInitProps)
  },

  componentDidUpdate({
    location: prevLocation,
    setPilotStoreInfo,
    isPilotStore: prevIsPilotStore
  }) {
    const { isPilotStore, location } = this.props

    prevIsPilotStore !== isPilotStore && setPilotStoreInfo(isPilotStore)
    prevLocation !== location && window.scrollTo(0, 0)
  },

  componentWillUnmount() {
    const { handleCustomActionsOnClick } = this.props

    window.removeEventListener('click', handleCustomActionsOnClick)
  }
})

const withAriaProps = withProps(({ isModal }) => ({
  ariaProps: isModal ? { 'aria-hidden': true } : {}
}))

export default compose(
  connect(mapStateToProps, {
    init,
    loadShoppingData,
    handleCustomActionsOnClick,
    getStoreToProfileByIp,
    setPilotStoreInfo
  }),
  withInitProps,
  withInit,
  withNotFound,
  withHideIfNoData,
  with3rdPartyData,
  withAriaProps
)(Main)
