import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import { components } from 'react-select'

const PopularSizesCustomOption = ({
  value,
  children,
  innerProps: {
    id
  } = {},
  innerProps,
  ...props
}) =>
  <StyledOption
    { ...props }
    value={ value }
    addBorderStyle={ id && id.includes && id.includes('option-9') }
    innerProps={ innerProps }>
    { children }
  </StyledOption>

export default PopularSizesCustomOption

PopularSizesCustomOption.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    id: PropTypes.string
  }),
  value: PropTypes.string
}

const StyledOption = styled(components.Option)`
  ${props => props.addBorderStyle && css`
    margin-bottom: 12px;
    ::before {
      content: '';
      position: absolute;
      background: ${Theme.colors.light_grey};
      height: 1.5px;
      width: 23px;
      bottom: -26px;
    }
  `}
`
