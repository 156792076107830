import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { SPINNER_ID } from 'constant'
import Spinner from './Spinner'

const withEventHandlers = withHandlers({
  handleRef: () => ref => ref && ref.focus && ref.focus(),
  handleBlur: () => (event) => {
    const { target } = event
    event.preventDefault()
    event.stopPropagation()
    // some browsers do not keep focus without a timeout
    setTimeout(() => target && target.focus && target.focus())
  }
})

const withLifecycle = lifecycle({
  componentDidMount() {
    // some browsers need a bit of time to see the wrapper without role="alert"
    setTimeout(() => {
      const { setRoleEnabled } = this.props
      const spinnerElem = document.querySelector(`#${SPINNER_ID}`)

      spinnerElem && setRoleEnabled(true)
    }, 50)
  }
})

export default compose(
  withState('roleEnabled', 'setRoleEnabled', false),
  withLifecycle,
  withEventHandlers
)(Spinner)
