import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import Link from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'
import TeaserTitle from './TeaserTitle'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'

const TeaserContent = ({
  icon,
  image,
  title,
  link: { path = '/' } = {},
  ...props
}) =>
  <Teaser data-at-teaser-content { ...dynamicDataAttribute('teaser', toOneString(title)) } { ...props }>
    <TeaserLink to={ path } >
      {
        icon ?
          <React.Fragment><TeaserIcon alt="" asset={ icon } data-at-teaser-icon /><br /></React.Fragment> :
          <TeaserImage image={ image } />
      }
      <TeaserTitle title={ title } />
    </TeaserLink>
  </Teaser>

TeaserContent.propTypes = {
  icon: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.string
  }),
  title: PropTypes.string
}

TeaserContent.type = 'TeaserContent'

export default TeaserContent

const Teaser = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  ${Mixin.responsive('margin', '25px 30px', '10px 0')}
  ${Mixin.responsive('width', '228px', '25%')}
  ${Media.mobile`
    a span {
      font-size: 3.15vw;
      word-spacing: 10000px;
    }

    a img {
      width: 10vw;
    }
  `}
  ${Media.mobileNarrow`
    width: 50%;
    a span {
      font-size: 3.59vw;
    }

    a img {
      width: 15vw;
    }
  `}
`

const TeaserLink = styled(Link)`
  display: inline-block;
  color: ${Theme.colors.black};

  &:hover {
    ${Media.desktop`
      box-shadow: 0px 0px 8px #999;
      margin: -20px;
      padding: 20px;
    `}
  }
`

const TeaserImage = styled.div`
  ${Mixin.responsive('height', '94px', '85px')}
  background-image: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  ${Mixin.responsive('margin-bottom', '24px', '10px')}
`

const TeaserIcon = styled(Icon)`
  ${Mixin.responsive('height', '94px', '85px')}
  ${Mixin.responsive('margin-bottom', '24px', '10px')}
`
