import { withProps } from 'recompose'
import { TRAILER_TYPE } from 'constant'
import VehicleTile from './VehicleTile'

const withVehicleTileProps = withProps(({ vehicleType, vehicleNickname = '' }) => ({
  isTrailer: vehicleType === TRAILER_TYPE,
  vehicleNicknameWithoutYear: vehicleNickname.replace(/^\d{4} /, '')
}))

export default withVehicleTileProps(VehicleTile)
