import React from 'react'
import { components } from 'react-select'
import { withProps } from 'recompose'

const ReactSelectDropdownIndicator = ({ ...props }) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator { ...props } />
  )

const withModifiedInnerProps = withProps(({ innerProps = {}}) =>
  ({ innerProps: { 'data-at-dropdown-indicator': true, ...innerProps }}))

export default withModifiedInnerProps(ReactSelectDropdownIndicator)
