import { Validator } from 'services/validator'
import {
  LICENCE_PLATE_INVALID,
  STATE_EMPTY,
  MILEAGE_EMPTY
} from 'components/MyGarage2.0/constants'

import { VEHICLE_MILEAGE, LICENCE_NAME, STATE_NAME } from 'constant'

export default Validator.combineValidators({
  [VEHICLE_MILEAGE]: {
    validators: Validator.required(),
    errorMessage: MILEAGE_EMPTY
  },
  [LICENCE_NAME]: {
    validators: Validator.licensePlate(),
    errorMessage: LICENCE_PLATE_INVALID
  },
  [STATE_NAME]: {
    validators: Validator.required(),
    errorMessage: STATE_EMPTY
  }
})
