import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import reduxForm from 'components/common/hoc/withFormErrorFocus'
import ShareViaEmailForm from './ShareViaEmailForm'
import { SHARE_VIA_EMAIL_FORM } from './constants'
import model from './form'
import { sharePageViaEmail } from './actions'
import validate from './validate'

const withOnSubmit = withHandlers({
  onSubmit: ({ sharePageViaEmail, requestPayload }) => values =>
    sharePageViaEmail({ ...values, ...requestPayload })
})

export default compose(
  connect(null, { sharePageViaEmail }),
  withOnSubmit,
  withProps({ model }),
  reduxForm({ form: SHARE_VIA_EMAIL_FORM, validate, isModal: true })
)(ShareViaEmailForm)
