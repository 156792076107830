import React from 'react'
import { inputTypes } from 'components/common/FormElements/Input'
import CarfaxCheckboxLabel from './CarfaxCheckboxLabel'
import { CONNECT_TO_CARFAX_FORM_CONFIRMATION_FIELD, CONNECT_TO_CARFAX_FORM_EMAIL_FIELD } from './constants'

const createViewModel = () => ({
  email: {
    label: 'Email',
    placeholder: 'Email',
    name: CONNECT_TO_CARFAX_FORM_EMAIL_FIELD,
    required: true
  },
  confirmation: {
    label: <CarfaxCheckboxLabel />,
    name: CONNECT_TO_CARFAX_FORM_CONFIRMATION_FIELD,
    inputType: inputTypes.CHECKBOX_INPUT,
    value: false,
    required: true,
    'data-at-servicehistory-carfax-agree': true
  }
})

export default createViewModel
