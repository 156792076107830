import { replace } from 'connected-react-router'
import { reset } from 'redux-form'
import { createAction } from 'redux-actions'
import { rest } from 'services'
import { getPageLocation } from 'selectors'
import { getRecordsAndSortOptionsFromData } from './selectors'
import { CUSTOM_PRICE_TYPE, CUSTOM_PRICE_MIN_PARAM, CUSTOM_PRICE_MAX_PARAM, CUSTOM_PRICE_RANGE_FORM, OE_PARAM } from './constants'
import {
  createFilterPath,
  getSearchValue,
  getSearchByUrl,
  getCombinedSearchStr,
  getAllResultsNextRequestUrl,
  removePriceRangeFromSearch, removeOeFilterFromSearch
} from './helpers'
import isEmpty from 'lodash/isEmpty'
import getSearchByVehicleUrl from 'helpers/getSearchByVehicleUrl'
import { getSearchByTireSizeUrlFromVehicle } from 'helpers/getSearchByTireSizeUrl'
import getActiveVehicle, { getIsActiveVehicleTrailer } from 'selectors/getActiveVehicle'


export const setPromotionData = createAction('SEARCH_RESULT_PAGE/SET_SEARCH_PROMOTION_DATA')
export const setFiltersData = createAction('SEARCH_RESULT_PAGE/SET_FILTERS_DATA')
export const setSortOptionsData = createAction('SEARCH_RESULT_PAGE/SET_SORT_OPTIONS_DATA')
export const setIsAllCompatible = createAction('SEARCH_RESULT_PAGE/SET_IS_ALL_COMPATIBLE')
export const addToProductCompare = createAction('SEARCH_RESULT_PAGE/ADD_TO_PRODUCT_COMPARE')
export const showCompareTray = createAction('SEARCH_RESULT_PAGE/OPEN_COMPARE_TRAY')
export const toggleCompareTray = createAction('SEARCH_RESULT_PAGE/TOGGLE_COMPARE_TRAY')
export const setCustomPriceRangeData = createAction('SEARCH_RESULTS_PAGE/SET_CUSTOM_PRICE_RANGE_DATA')
export const resetCustomPriceRangeData = createAction('SEARCH_RESULTS_PAGE/RESET_CUSTOM_PRICE_RANGE_DATA')
export const clearCompareItems = createAction('SEARCH_RESULT_PAGE/CLEAR_COMPARE_ITEMS')
export const setShowNoEligibleTiresForPromo = createAction('SEARCH_RESULT_PAGE/SET_SHOW_NO_ELIGIBLE_TIRES_FOR_PROMO')
export const setOeItemIdsData = createAction('SEARCH_RESULT_PAGE/SET_OE_ITEM_IDS_DATA')
export const addOeItemData = createAction('SEARCH_RESULT_PAGE/ADD_OE_ITEM_DATA')
export const setOeFilterItemsData = createAction('SEARCH_RESULT_PAGE/SET_OE_FILTER_ITEMS_DATA')
export const setOeFilter = createAction('SEARCH_RESULT_PAGE/SET_OE_FILTER')

export const cleanCustomPriceRange = () => dispatch => {
  dispatch(resetCustomPriceRangeData())
  dispatch(reset(CUSTOM_PRICE_RANGE_FORM))
}

export const applyPriceRangeFilter = ({ min, max, navigationState = '', count }) => (dispatch, getState) => {
  const { pathname, ...currentLocation } = getPageLocation(getState())
  const [navigationPath, navigationSearch = ''] = navigationState.split(/(?=\?)/)
  const finalSearch = getCombinedSearchStr({
    search: removeOeFilterFromSearch({ search: navigationSearch }),
    params: { [CUSTOM_PRICE_MIN_PARAM]: min, [CUSTOM_PRICE_MAX_PARAM]: max }
  })

  dispatch(setCustomPriceRangeData({ min, max, count }))

  dispatch(replace({
    ...currentLocation,
    search: finalSearch,
    pathname: `${getSearchByUrl(pathname)}${navigationPath}`
  }))
}

export const clearPriceRangeFilter = () => (dispatch, getState) => {
  const { pathname, search, ...currentLocation } = getPageLocation(getState())
  const finalSearch = removePriceRangeFromSearch({ search })

  dispatch(cleanCustomPriceRange())

  dispatch(replace({
    ...currentLocation,
    search: finalSearch,
    pathname
  }))
}

export const filterItems = ({ navigationState, type } = {}) => (dispatch, getState) => {
  if (type === CUSTOM_PRICE_TYPE) {
    return dispatch(clearPriceRangeFilter())
  }
  const { pathname, search, ...currentLocation } = getPageLocation(getState())
  const { pathname: newPathname, search: newSearch = '' } = createFilterPath(pathname, navigationState)

  const finalSearch = getSearchValue({
    navigationState,
    search: removeOeFilterFromSearch({ search }),
    newSearch: removeOeFilterFromSearch({ search: newSearch }) })

  dispatch(replace({
    ...currentLocation,
    search: finalSearch,
    pathname: newPathname
  }))
}

export const resetFilters = () => dispatch => {
  dispatch(filterItems({ navigationState: '' }))
  dispatch(cleanCustomPriceRange())
}

export const activateOeFilter = () => (dispatch, getState) => {
  const activeVehicle = getActiveVehicle(getState())
  const isTrailer = getIsActiveVehicleTrailer(getState())
  const pathname = isTrailer
    ? getSearchByTireSizeUrlFromVehicle({ activeVehicle })
    : getSearchByVehicleUrl(activeVehicle)
  const currentLocation = getPageLocation(getState())

  dispatch(clearPriceRangeFilter())
  dispatch(replace({
    ...currentLocation,
    pathname,
    search: getCombinedSearchStr({ params: { [OE_PARAM]: true }})
  }))
}

export const preLoadItems = ({
  setPreLoaderOptions,
  setNoNewRecords,
  index
}) => async (dispatch, getState) => {
  const { search, pathname } = getPageLocation(getState())

  try {
    const { data } = await rest.get(getAllResultsNextRequestUrl({ search, pathname, index }))
    const { records: allRecords, sortOptions, lastRecNum } = getRecordsAndSortOptionsFromData(data)
    if (allRecords && !isEmpty(allRecords)) {
      setPreLoaderOptions({
        isViewAll: true,
        isViewAllResultsRequest: true,
        preLoadedSortOptions: sortOptions,
        preLoadedRecords: allRecords,
        recordIndex: lastRecNum
      })
    } else {
      setNoNewRecords({
        noNewRecords: true,
        preLoadedSortOptions: sortOptions,
        preLoadedRecords: allRecords || []
      })
    }
  } catch (e) {
    setNoNewRecords({
      noNewRecords: true,
      preLoadedSortOptions: [],
      preLoadedRecords: []
    })
    console.log(e)
  }
}

