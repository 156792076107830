import { garage } from 'constant/api-interfaces'

export default vehicleDetails =>
  Object
    .keys(vehicleDetails)
    .filter(field => garage.includes(field))
    .reduce((acc, name) => ({
      ...acc,
      [name]: vehicleDetails[name]
    }), {})
