import { connect } from 'react-redux'
import { compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import ContactStoreModal from './ContactStoreModal'
import {
  CONTACT_STORE_MODAL,
  MOBILE_HEADER_HEIGHT,
  HEADER_SYSTEM_ALERT_ID,
  EXPIRED_SESSION_ID
} from 'constant'
import { closeContactStoreModal } from './action'
import { getHeightById } from 'src/helpers/utils'
import { isWideMobile } from 'components/common/Media/helpers'
import { storeOpenTodayTomorrow, storeSpecialHoursTodayTomorrow } from 'src/components/Header/MyStore/components/SelectedMyStore/helpers.js'

const mapStateToProps = state => {
  const headerSystemAlertHeight = getHeightById({ id: HEADER_SYSTEM_ALERT_ID })
  const sessionTimeoutHeight = getHeightById({ id: EXPIRED_SESSION_ID })
  const dynamicTop = isWideMobile()
    ? MOBILE_HEADER_HEIGHT + headerSystemAlertHeight + sessionTimeoutHeight
    : 0

  const { storeClosedHours, storeSpecialHours } = state.myStore

  return {
    dynamicTop,
    storeDetails: state.myStore,
    ...storeOpenTodayTomorrow(storeClosedHours),
    ...storeSpecialHoursTodayTomorrow(storeSpecialHours)
  }
}
const mapDispatchToProps = dispatch => ({
  closeContactStoreModal: () => dispatch(closeContactStoreModal())
})

export default compose(
  registerReduxModal({ name: CONTACT_STORE_MODAL }),
  connect(mapStateToProps, mapDispatchToProps)
)(ContactStoreModal)
