import { compose, lifecycle, withHandlers, withState } from 'recompose'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import withType from 'components/common/hoc/withType'
import Theme from 'components/common/Theme/Theme'
import { calcShowBackToTop } from './helpers'
import BackToTopFooterBlock from './BackToTopFooterBlock.js'

const withShowBackToTop = withState('showBackToTop', 'setShowBackToTop', false)

const handleShowBackToTop = withHandlers({
  handleResize: ({ setShowBackToTop }) =>
    debounce(() => setShowBackToTop(calcShowBackToTop()), Theme.scrollDebounceTimeout)
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { handleResize } = this.props
    handleResize()
    window.addEventListener('resize', handleResize)
  },
  componentDidUpdate({ showBackToTop: prevShowBackToTop, setShowBackToTop }) {
    const newShowBackToTop = calcShowBackToTop()
    !isEqual(newShowBackToTop, prevShowBackToTop) && setShowBackToTop(newShowBackToTop)
  },
  componentWillUnmount() {
    const { handleResize } = this.props
    window.removeEventListener('resize', handleResize)
  }
})

export default compose(
  withType({ type: 'BackToTopFooterBlock' }),
  withShowBackToTop,
  handleShowBackToTop,
  withLifecycle
)(BackToTopFooterBlock)
