import {
  googleAnalyticProductAddToCart,
  googleAnalyticPurchase
} from 'actions/googleAnalytic'
import { applyNowCTAClick } from 'actions/thirdParty'
import { getQuoteInfo } from 'selectors/shoppingData'
import isEmpty from 'lodash/isEmpty'
import { extractQuickAppointmentConfirmationData } from '../GTM/helpers'
import { validateAddToCartItem, createSkuIDList, createAddToQuoteConversionData } from './helpers'

export default store => next => (action, productsAndServices = {}) => {
  try {
    const { fbq } = window
    if (action.type === String(googleAnalyticProductAddToCart) && !isEmpty(productsAndServices)) {
      const { product: { skuId = '' } = {}} = action.payload
      if (skuId) {
        const { products = [], services = []} = productsAndServices

        if (validateAddToCartItem(skuId)) {
          const { revenue: value, skuIDList } = createAddToQuoteConversionData(products, skuId)
          fbq && fbq('track', 'AddToCart', { value, currency: 'USD', content_type: 'product', content_ids: skuIDList })
        } else {
          const { averageSalePrice: value, serviceId: skuIDList } = services.length &&
          services.filter(({ serviceId }) => serviceId === skuId)[0]
          fbq && fbq('track', 'AddToCart', { value, currency: 'USD', content_type: 'product', content_ids: skuIDList })
        }
      }
    }

    if (action.type === String(googleAnalyticPurchase)) {
      const { payload: purchaseData } = action
      const state = store.getState()
      const { revenue: value, products, services } = purchaseData ?
        extractQuickAppointmentConfirmationData(purchaseData) : getQuoteInfo(state)
      const skuIDList = createSkuIDList(products, [], services)

      fbq && fbq('track', 'Purchase', { value, currency: 'USD', content_type: 'product', content_ids: skuIDList })
    }

    if (action.type === String(applyNowCTAClick)) {
      fbq && fbq('track', 'Lead')
    }
  } catch (err) {
    console.log('Error occured during Google Analytic event dispatch', err)
  }

  return next(action)
}
