import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import { Separator } from 'components/common/Separator/Separator'
import { CAR_BAR_ELEMENT_ID } from 'constant'
import Hint from '../Hint'
import { injectProps } from 'helpers/utils'

const VehicleSelector = ({
  expanded,
  toggleHandlers,
  extendedVehicleSelectorBlock: [{
    garageVehicles: [{
      UserVehicleDetails: {
        noOfVehicles: numOfVehiclesInGarage
      } = {}
    } = {}] = []
  } = {}] = [],
  placeholders: { mainVehicleSelectorBlock = [], extendedVehicleSelectorBlock = []} = {}
}) =>
  <Wrapper data-at-carbar id={ CAR_BAR_ELEMENT_ID }>
    <div data-max-width>
      <TopBlock>
        { injectProps(mainVehicleSelectorBlock, { expanded }) }
        <Hint
          numberOfVehicles={ numOfVehiclesInGarage }
          expanded={ expanded }
          toggleHandlers={ toggleHandlers } />
      </TopBlock>
      {
        expanded &&
          <div data-at-extended-vehicle-selector-block>
            <HintSeparator />
            { injectProps(extendedVehicleSelectorBlock, { toggleHandlers }) }
          </div>
      }
    </div>
  </Wrapper>

VehicleSelector.propTypes = {
  expanded: PropTypes.bool,
  extendedVehicleSelectorBlock: PropTypes.arrayOf(PropTypes.shape()),
  placeholders: PropTypes.shape({
    mainVehicleSelectorBlock: PropTypes.arrayOf(PropTypes.element),
    extendedVehicleSelectorBlock: PropTypes.arrayOf(PropTypes.element)
  }),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

export default VehicleSelector


const Wrapper = styled.div`
  ${Media.mobile`
    border-top: 1px solid ${Theme.colors.light_grey};
  `}
  ${Mixin.responsive('padding', '0 45px', '0 16px')}
  background: ${Theme.colors.white};
  position: relative;
  ${Media.desktop`
    ::before {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      top: -3px;
      height: 4px;
      background: ${Theme.colors.light_grey};
    }
  `}
`

const TopBlock = styled.div`
  display: flex;
  width: 100%;
  ${Mixin.responsive('padding', '20px 0', '16px 0')}
  background: ${Theme.colors.white};
  align-items: center;
  justify-content: space-between;
`

const HintSeparator = styled(Separator)`
  ${Media.mobile`
    box-shadow: none;
  `}
`
