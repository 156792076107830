import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'
import Text from 'components/common/Text/Text'
import Modal from 'components/common/Modal'

const CONTINUE_BUTTON_DEFAULT_TEXT = 'Shop by Tire Size'
const DEFAULT_TEXT = 'We don’t have the complete industry information for your specific vehicle.'

const TireSizesNotFoundModal = ({
  handleHide,
  onCancelClick,
  onContinueClick,
  continueButtonText,
  modalText,
  addNewVehicle
}) => {
  const handleClose = fn => () => {
    fn && fn()
    handleHide()
  }

  return (
    <ModalBox title="No Tires Found" closeHandler={ handleClose(onCancelClick) }>
      <Text block>{ modalText || DEFAULT_TEXT }</Text>
      <ButtonsWrapper>
        <Button
          full="mobile"
          stacked="mobile"
          onClick={
            handleClose(
              !continueButtonText
                ? addNewVehicle
                : onContinueClick) }>
          {
            continueButtonText || CONTINUE_BUTTON_DEFAULT_TEXT
          }
        </Button>
        {
          onCancelClick &&
            <Button bolder link full="mobile" stacked="mobile" onClick={ handleClose(onCancelClick) }>
              Cancel
            </Button>
        }
      </ButtonsWrapper>
    </ModalBox>
  )
}

TireSizesNotFoundModal.propTypes = {
  addNewVehicle: PropTypes.func,
  continueButtonText: PropTypes.string,
  handleHide: PropTypes.func,
  modalText: PropTypes.string,
  onCancelClick: PropTypes.func,
  onContinueClick: PropTypes.func
}

export default TireSizesNotFoundModal

const ButtonsWrapper = styled.div`
  ${Mixin.responsive('margin-top', '40px', '20px')}
`

const ModalBox = styled(Modal)`
  ${Media.desktop`
    padding: 25px 24px 67px;
  `}
`
