import {
  DESKTOP_TYPES,
  MOBILE_EDGE,
  DESKTOP_NARROW_EDGE,
  PHONE_EDGE,
  MOBILE,
  TABLET,
  DESKTOP,
  TABLET_NARROW_EDGE
} from './constants'

export const getWidth = device =>
  DESKTOP_TYPES.indexOf(device) !== -1 ? MOBILE_EDGE + 1 : MOBILE_EDGE

export const isMobile = () => window.innerWidth <= MOBILE_EDGE

export const isWideMobile = () => window.innerWidth < TABLET_NARROW_EDGE

export const isNarrowTablet = () => window.innerWidth <= MOBILE_EDGE && window.innerWidth >= TABLET_NARROW_EDGE

export const isTablet = () => window.innerWidth <= DESKTOP_NARROW_EDGE && window.innerWidth >= PHONE_EDGE

export const isDesktop = () => window.innerWidth > MOBILE_EDGE

export const isNotIE = () => !window.navigator.userAgent.match(/MSIE|Trident/)

export const getClientSize = () => {
  const { document } = window

  if (!document) {
    return {}
  }

  return {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  }
}

export const deviceChecker = screenFits => ({
  mobile: screenFits === MOBILE,
  tablet: screenFits === TABLET,
  desktop: screenFits === DESKTOP
})

export const screenFitsHelper = () => {
  const { width } = getClientSize()

  if (width <= MOBILE_EDGE) {
    window.currentDevice = MOBILE

    return MOBILE
  } else if (width > MOBILE_EDGE && width < DESKTOP_NARROW_EDGE) {
    window.currentDevice = TABLET

    return TABLET
  }

  window.currentDevice = DESKTOP
  return DESKTOP
}

