import { handleActions } from 'redux-actions'
import { setSticky } from './actions'

const initialState = {
  sticky: false
}

const reducer = handleActions({
  [setSticky]: (state, { payload: { sticky }}) =>
    ({ ...state,
      sticky
    })
}, initialState)

export default reducer
