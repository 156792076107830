import { createSelector } from 'helpers/reselect'
import { isSearchByTireSizeUrl, isSearchByTrailer } from 'helpers/location'
import { getPageLocation } from 'selectors'
import { getActiveVehicleName } from 'selectors/getActiveVehicle'
import { getArrayObjectByParam } from 'helpers/utils'
import { COMPARE_TRAY_MAX_ITEMS, VEHICLE, SEARCH_RESULT_COMPONENT, OE_PARAM, TRAILER_TIRES_TEXT } from './constants'
import { createCustomPriceRangeFilter, getTireSizeFromUrl, getTireSizeFromProps } from './helpers'
import { getSubmittedValues } from '../VehicleConfigurator/components/withTireSize/selectors'
import { getLocationSearch } from 'selectors/getPageLocation'

export const getIsAllCompatible = ({
  searchResultPage: {
    isAllCompatible
  }
}) => isAllCompatible

export const isCompareTrayFull = ({
  searchResultPage: {
    compareItems
  }
}) => compareItems.length === COMPARE_TRAY_MAX_ITEMS

export const getCompareTrayItems = ({ searchResultPage: { compareItems }}) => compareItems

export const getCompareTrayShow = ({ searchResultPage: { showCompareTray }}) => showCompareTray

export const getPromotionsCartridge = ({ searchResultPage: { BOTSearchPromotion }}) => BOTSearchPromotion

const getOeIds = ({ searchResultPage: { oeItemIdsList }}) => oeItemIdsList

const getOeFilterItemsData = ({ searchResultPage: { oeFilterItems }}) => oeFilterItems

export const getShowNoEligibleTiresForPromo = ({ searchResultPage: { showNoEligibleTiresForPromo }}) =>
  showNoEligibleTiresForPromo

export const getFiltersData = ({
  searchResultPage: {
    filtersData: {
      navigation = [],
      selectedFilters = []
    } = {}
  }
}) => ({
  navigation,
  selectedFilters
})

export const getCustomPriceRangeData = ({
  searchResultPage: {
    customPriceRange: {
      min,
      max,
      count
    } = {}
  }
}) => ({
  min,
  max,
  count
})

export const getOeItemIdsList = createSelector(
  [getOeIds],
  (oeItemIdsList = []) => oeItemIdsList
)

export const getOeFilterItems = createSelector(
  [getOeFilterItemsData],
  (oeFilterItems = []) => oeFilterItems
)

export const getAllSelectedFilters = createSelector(
  [getFiltersData, getCustomPriceRangeData],
  ({ selectedFilters }, { min, max, count }) => [
    ...selectedFilters,
    ...max ? [createCustomPriceRangeFilter({ min, max, count })] : []
  ]
)

export const getOeFilterMode = createSelector(
  [getLocationSearch],
  (search = '') => search.includes(`?${OE_PARAM}=true`) || search.includes(`&${OE_PARAM}=true`)
)

export const getTireSizeFromSearchResults = (state, {
  rightContent: [
    {
      records: [
        {
          attributes: {
            frontTire: [
              {
                frontTireSize
              } = {}
            ] = [],
            rearTire: [
              {
                rearTireSize
              } = {}
            ] = []
          } = {}
        } = {}
      ] = []
    } = {}
  ] = []
}) => ({ frontTireSize, rearTireSize })

export const getMainContentFromData = ({
  contents: [
    {
      mainContent
    } = {}
  ] = []
}) => mainContent

export const getRecordsAndSortOptionsFromData = createSelector(
  [getMainContentFromData],
  (mainContent) => {
    const [
      {
        rightContent: [
          {
            records,
            sortOptions,
            lastRecNum
          } = {}
        ] = []
      } = {}
    ] = getArrayObjectByParam({ array: mainContent, value: SEARCH_RESULT_COMPONENT })
    return ({ records, sortOptions, lastRecNum })
  }
)

export const getSortOptions = ({ searchResultPage: { sortOptions }}) => sortOptions

export const getSectionsLabel = createSelector(
  [
    getPageLocation,
    getActiveVehicleName,
    getTireSizeFromSearchResults,
    getSubmittedValues
  ],
  ({ pathname }, vehicleName, { frontTireSize, rearTireSize }, submittedValues) => {
    const isBySizePage = isSearchByTireSizeUrl({ pathname })
    const isTrailerPage = isSearchByTrailer({ pathname })
    const tireSize = isBySizePage || isTrailerPage
      ? getTireSizeFromUrl(pathname, frontTireSize, rearTireSize) || getTireSizeFromProps(submittedValues)
      : false

    return {
      resultsLabel: (!isTrailerPage ? (tireSize || VEHICLE) : (`${tireSize || ''} ${TRAILER_TIRES_TEXT}`)),
      compatibilityLabel: tireSize || ` your ${vehicleName}`
    }
  }
)
