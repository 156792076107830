import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import PhoneLink from 'components/common/PhoneLink'
import {
  SCHEDULE_AN_APPOINTMENT_TITLE_FIRST_PART,
  SCHEDULE_AN_APPOINTMENT_TITLE_SECOND_PART,
  APPOINTMENT_SCHEDULE_TITLE
} from 'components/QuickAppointmentPage/BOTDateTimeSelector/components/AppointmentIsNotEnable/constants'

const AppointmentUnsupportedModal = ({ handleHide, phoneNumber }) =>
  <Modal closeHandler={ handleHide } title={ APPOINTMENT_SCHEDULE_TITLE }>
    <Description>
      { SCHEDULE_AN_APPOINTMENT_TITLE_FIRST_PART }
      <PhoneLink number={ phoneNumber } />
      { SCHEDULE_AN_APPOINTMENT_TITLE_SECOND_PART }
    </Description>
  </Modal>

AppointmentUnsupportedModal.propTypes = {
  handleHide: PropTypes.func,
  phoneNumber: PropTypes.string
}

export default AppointmentUnsupportedModal

const Description = styled.div`
  line-height: 22px;
  font-size: 16px;
`
