
export const focusMenuItemSibling = ({ e, itemIndex }) => {
  const navItem = e.currentTarget.parentElement.parentElement.querySelector(`#primary-menu-${itemIndex}`)
  setTimeout(() => {
    navItem && navItem.focus()
  })
}

export const focusMenuSubItem = ({ e, parentIndex, itemIndex }) => {
  const subNavItem = e.currentTarget.querySelector(`#menu-item-${parentIndex}-subnav-item-${itemIndex} a`)
  if (subNavItem) {
    setTimeout(() => {
      subNavItem.focus()
    })
  }
}

export const getFocusedSubItemIndex = (e) => {
  const focusedSubItem = e.currentTarget.querySelector('a:focus').closest('[data-at-header-menu-subnavitem]')
  return focusedSubItem ? Number(focusedSubItem.getAttribute('data-at-subnav-index')) : -1
}

