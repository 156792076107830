import React from 'react'
import { compose, withStateHandlers, lifecycle, withProps } from 'recompose'
import withInnerFocusBlurHover from 'components/common/hoc/withInnerFocusBlurHover'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import Carousel from './Carousel'
import slideControls from './slideControls'
import { NextArrow, PrevArrow } from './arrows'

const withSliderMouseHandlers = withStateHandlers(({ pausedByDefault }) => ({
  activeIndex: 0,
  defaultSettings: {},
  isDragging: false,
  isChanging: false,
  isPlaying: null,
  userPromptedPaused: !!pausedByDefault,
  slickPause: null,
  slickPlay: null,
  pausedByDefault: !!pausedByDefault
}), {
  handleSlideMouseDown: () => () => ({
    isDragging: true
  }),
  handleSlideMouseUp: () => () => ({
    isDragging: false
  }),
  handleSlideBeforeChange: () => (nextIndex) => ({
    activeIndex: nextIndex,
    isChanging: true
  }),
  handleSlideAfterChange: () => () => ({
    isChanging: false
  }),
  togglePlayPause: ({ isPlaying, slickPause, slickPlay }) => () => {
    isPlaying ? slickPause() : slickPlay()

    return {
      userPromptedPaused: isPlaying,
      isPlaying: !isPlaying
    }
  },
  setIsPlaying: () => (isPlaying) => ({
    isPlaying
  }),
  setSlideControls: ({ isPlaying, defaultSettings }) => (togglePlayPause) => ({
    defaultSettings: {
      ...defaultSettings,
      appendDots: slideControls({ isPlaying, togglePlayPause })
    }
  }),
  setSliderMethods: () => ({ slickPause, slickPlay }) => ({ slickPause, slickPlay }),
  setDefaultSettings: () => (children, handleSlideBeforeChange, handleSlideAfterChange) => ({
    defaultSettings: {
      dots: true,
      draggable: true,
      infinite: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      autoplay: children && children.length > 1,
      customPaging: i => <button data-at-carousel-dot-button><span data-sr-only>Select Slide {i + 1}</span></button>,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      beforeChange: (currentIndex, nextIndex) => handleSlideBeforeChange(nextIndex),
      afterChange: handleSlideAfterChange
    }
  })
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { setDefaultSettings, children, handleSlideBeforeChange, handleSlideAfterChange } = this.props
    setDefaultSettings(children, handleSlideBeforeChange, handleSlideAfterChange)
  },
  componentDidUpdate({ isInnerFocusedHovered: prevIsInnerFocusedHovered, isPlaying: prevIsPlaying }) {
    const {
      isInnerFocusedHovered,
      userPromptedPaused,
      slickPause,
      slickPlay,
      isPlaying,
      setIsPlayingParent,
      setIsPlaying,
      setSlideControls,
      togglePlayPause,
      pausedByDefault,
      setTogglePlayPauseForParent,
      defaultSettings: { autoplay: defaultAutoplay },
      config: {
        autoplay
      } = {}
    } = this.props
    const hasAutoplayMethods = !isUndefined(autoplay) ?
      slickPause && slickPlay && defaultAutoplay && autoplay :
      slickPause && slickPlay && defaultAutoplay

    if (isPlaying === null && hasAutoplayMethods && pausedByDefault) {
      setIsPlaying(false)
      setIsPlayingParent && setIsPlayingParent(false)
      slickPause()
    }
    if (isPlaying === null && hasAutoplayMethods && !pausedByDefault) {
      setIsPlaying(true)
      setIsPlayingParent && setIsPlayingParent(true)
    }
    if (prevIsPlaying !== isPlaying) {
      setSlideControls(togglePlayPause)
      setTogglePlayPauseForParent && setTogglePlayPauseForParent(togglePlayPause)
      setIsPlayingParent && setIsPlayingParent(isPlaying)
    }
    if (hasAutoplayMethods && !isEqual(prevIsInnerFocusedHovered, isInnerFocusedHovered) && !userPromptedPaused) {
      return isInnerFocusedHovered ? slickPause() : slickPlay()
    }
  }
})

const withFilteredChildren = withProps(({ children, heroBanner }) => {
  const hasContents = (ch) => {
    if (!ch.props.contents && !ch.props.content) {
      return ch.props.children ? hasContents(ch.props.children) : false
    }
    return (ch.props.contents && ch.props.contents.length) || !!ch.props.content
  }

  return { children: heroBanner ? children.filter(hasContents) : children }
})

export default compose(
  withSliderMouseHandlers,
  withInnerFocusBlurHover,
  withLifecycle,
  withFilteredChildren
)(Carousel)
