import { compose, mapProps, withProps } from 'recompose'
import withNormalizeValue from 'components/common/hoc/withNormalizeValue'
import reduxForm from 'components/common/hoc/withFormErrorFocus'
import FindServiceHistoryForm from './FindServiceHistoryForm'
import createViewModel from './createViewModel'
import { VEHICLE_AND_DATA_MISMATCH_FLAG } from '../../constants'
import { FIND_SERVICE_HISTORY_FORM } from './constants'
import { convertStatesToOptions } from 'helpers/options'
import { getInitialValues } from './helpers'
import validate from './validate'

const mapStatesToStatesOptions = mapProps(({ states, ...props }) => ({
  ...props,
  statesOptions: convertStatesToOptions(states)
}))

const withViewModel = withProps(({ statesOptions }) => ({
  viewModel: createViewModel(statesOptions)
}))

const withInitialValues = withProps(props => ({
  initialValues: getInitialValues(props)
}))

const withVehicleAndDataMismatchErrorFlag = withProps(({ error }) => ({
  isVehicleAndDataMismatchError: error === VEHICLE_AND_DATA_MISMATCH_FLAG
}))

export default compose(
  mapStatesToStatesOptions,
  withViewModel,
  withInitialValues,
  reduxForm({ form: FIND_SERVICE_HISTORY_FORM, validate, isModal: true }),
  withVehicleAndDataMismatchErrorFlag,
  withNormalizeValue
)(FindServiceHistoryForm)
