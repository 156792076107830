import { PAGES_WHERE_HIDE_CALL_STORE_INFO, STORE_CLOSED } from './constants'

// Store Closed Helpers & Logic
const formatDate = (date) => `${date.getMonth() + 1}/${date.getDate()}`

const isStoreClosed = (storeHours = [], date = '') =>
  storeHours.length && storeHours.filter(d => d.date === date && d.workingHours === STORE_CLOSED).length

const getDateStrings = () => ({
  today: formatDate(new Date()),
  tomorrow: formatDate(new Date((new Date()).setDate((new Date()).getDate() + 1)))
})

export const storeOpenTodayTomorrow = (storeClosedHours = []) => {
  const { today, tomorrow } = getDateStrings()

  return {
    storeOpenToday: !isStoreClosed(storeClosedHours, today),
    storeOpenTomorrow: !isStoreClosed(storeClosedHours, tomorrow)
  }
}

// Store Special Hours Helpers & Logic
const formatSpecialHours = timeSlot => timeSlot && timeSlot.split('-').map(wh => wh.trim()).join(' - ')

const getStoreSpecialHours = (storeHours = [], date = '') =>
  formatSpecialHours(!!storeHours.length && (storeHours.filter(d => d.date === date)[0] || {}).workingHours)

export const storeSpecialHoursTodayTomorrow = (storeSpecialHours = []) => {
  const { today, tomorrow } = getDateStrings()

  return {
    storeSpecialHoursToday: getStoreSpecialHours(storeSpecialHours, today),
    storeSpecialHoursTomorrow: getStoreSpecialHours(storeSpecialHours, tomorrow)
  }
}

export const isHideCallStoreInfo = (pathname) =>
  PAGES_WHERE_HIDE_CALL_STORE_INFO.some((page) => pathname.includes(page))
