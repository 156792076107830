import { CONTACT_STORE_MODAL } from 'constant'
import { show } from 'redux-modal'
import RequestBuilder from 'services/microServices/requestBuilder'
import apiEndPoints from 'services/microServices/endPoints'
import { setUpdatingBlock } from 'components/common/UpdatingBlock/actions'
import { INVENTORY_STATUS_LOADED } from 'components/common/UpdatingBlock/constants'

const { inventoryService: { url, method, basePath }} = apiEndPoints

export const showContactStoreModal = () => (dispatch) => {
  dispatch(show(CONTACT_STORE_MODAL))
}

export const getTireAvailabilityStatus = (payload, productSKUId) =>
  (dispatch) => new Promise((resolve, reject) => {
    dispatch(setUpdatingBlock({ name: INVENTORY_STATUS_LOADED, value: { skuId: productSKUId, isLoading: true }}))
    new RequestBuilder(url, basePath)
      .withMethod(method)
      .withData(payload)
      .withSuccessHandler((response) => {
        dispatch(setUpdatingBlock({ name: INVENTORY_STATUS_LOADED, value: { skuId: productSKUId, isLoading: false }}))
        resolve(response)
      })
      .withErrorHandler((error) => {
        dispatch(setUpdatingBlock({ name: INVENTORY_STATUS_LOADED, value: { skuId: productSKUId, isLoading: false }}))
        reject(error)
      })
      .execute()
  })
