import prepareForm from 'helpers/prepare-form'

const defaultModel = {
  appRemindersCallMe: false,
  appRemindersEmailMe: false,
  appRemindersDirectMailMe: false,
  appRemindersTextMe: false,
  serviceRemindersCallMe: false,
  serviceRemindersEmailMe: false,
  serviceRemindersTextMe: false,
  promotionalOffersCallMe: false,
  promotionalOffersEmailMe: false,
  promotionalOffersDirectMailMe: false,
  promotionalOffersTextMe: false
}

const checkChannelPresence = (values, value) => values.includes(value)

export const createCommunicationPreferencesRequestModel = ({ reminders, promotions }) => prepareForm({
  ...defaultModel,
  appRemindersCallMe: checkChannelPresence(reminders, 'callMe'),
  appRemindersEmailMe: checkChannelPresence(reminders, 'emailMe'),
  appRemindersDirectMailMe: checkChannelPresence(reminders, 'directMailMe'),
  promotionalOffersCallMe: checkChannelPresence(promotions, 'callMe'),
  promotionalOffersEmailMe: checkChannelPresence(promotions, 'emailMe'),
  promotionalOffersDirectMailMe: checkChannelPresence(promotions, 'directMailMe')
})
