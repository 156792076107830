export const generateId = ({
  form,
  label = '',
  placeholder,
  name,
  uid,
  value
}) => {
  const trimmedLabel = typeof label === 'string' ? (label.match(/[a-zA-Z0-9]/g) || []).join('') : ''
  const valueFromObj = value && value.label ? value.label : value
  const constructorArray = [form, trimmedLabel, placeholder, name, valueFromObj, uid]
  return (constructorArray.filter((item) => !!item)).join('-').replace(/\s/g, '-')
}

export const setRequiredAttrs = (isRequired) => isRequired && ({ required: true, 'aria-required': true })
