import { INPUT_RADIO_NAME } from './constants'
import { inputTypes } from 'components/common/FormElements/Input'
import { extractVehicleData } from '../../helpers'

const createImage = ({
  scene7ImageUrl
}) => `${scene7ImageUrl}`

const createTitle = ({ yearDesc, makeDesc, modelDesc, subModelDesc }) =>
  `${yearDesc} ${makeDesc} ${modelDesc} ${subModelDesc}`

const createInputValue = (data) => JSON.stringify(extractVehicleData(data))

export const createFormViewData = ({ vehicles = [], licensePlate, state } = {}) =>
  vehicles.map(data => ({
    name: INPUT_RADIO_NAME,
    type: 'radio',
    inputType: inputTypes.RADIO_INPUT,
    value: createInputValue(data),
    vehicle: {
      image: createImage(data),
      title: createTitle(data),
      description: `${licensePlate} - ${state}`
    }
  }))

export const getInitialValues = ([vehicle = {}] = []) => ({
  [INPUT_RADIO_NAME]: createInputValue(vehicle)
})

export const getVehicle = values => JSON.parse(values[INPUT_RADIO_NAME])
