const StoreProvider = {
  setStore(store) {
    this.store = store
  },

  getStore() {
    return this.store
  }
}

export default StoreProvider
