import { replaceTireDecimal } from 'components/VehicleConfigurator/components/withTireSize/helpers'
import { formatTireSize } from './getSearchByVehicleUrl'

export const createSizeString = ({ width, ratio, diameter }) =>
  width && ratio && diameter ?
    `${width}/${ratio}R${diameter}` : ''

export const extractSizes = ({ front, rear }) => ({
  tireFrontSize: createSizeString(front),
  tireRearSize: createSizeString(rear)
})

export const createFormattedSizeString = ({ width, ratio, diameter }) =>
  width && ratio && diameter ?
    `${width}${replaceTireDecimal(ratio)}${diameter}` : ''

export const extractFormattedSizes = ({ front, rear }) => ({
  tireFrontSize: formatTireSize(createFormattedSizeString(front)),
  tireRearSize: formatTireSize(createFormattedSizeString(rear))
})
