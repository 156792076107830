import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'

export const Separator = styled.div`
  border-bottom: 1px solid ${Theme.colors.light_grey};
  ${props => props.desktopOnly && Mixin.desktopOnly()};
  ${props => props.mobileOnly && Mixin.mobileOnly()};
  ${props => props.margin && css`
    margin: ${props => props.margin};
  `}
`

export const VerticalSeparator = styled.span`
  display: inline-block;
  height: ${props => props.height || '13px'};
  margin: ${props => props.margin || '0 10px'};
  vertical-align: middle;
  border-right: 1px solid ${Theme.colors.light_grey};
`
