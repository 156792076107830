import { XOCSTATEDATA, AUTHORIZATION } from 'src/constant'

export default config => {
  if (process.env.BOT_IS_SERVER !== 'true' && process.env.BOT_LOG_REQUESTS === 'true') {
    console.log('----------start ssr request headers-----------')
    console.log('url', config.url)
    console.log(XOCSTATEDATA, config.headers[XOCSTATEDATA])
    config.headers[AUTHORIZATION] && console.log(AUTHORIZATION, config.headers[AUTHORIZATION])
    console.log('Cookie', config.headers.Cookie)
    console.log('---------end ssr request headers-------------')
    console.log('\n')
  }
  return config
}
