import { branch, compose, renderNothing, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import HeaderSystemAlert from './HeaderSystemAlert'
import { dispatchPageHeaderChanged } from 'helpers/custom-events'
import { closeHeaderSystemAlertState } from './actions'
import { getPageLocation } from 'selectors'
import { isQuoteSummaryPage, isQuickAppointmentPage } from 'helpers/quoteRelatedPages'

const mapStateToProps = state => {
  const pageLocation = getPageLocation(state)

  return {
    visibility: state.headerSystemAlert.isVisible,
    isQuotePage: isQuoteSummaryPage(pageLocation && pageLocation.pathname),
    isQuickAppointmentPage: isQuickAppointmentPage(pageLocation && pageLocation.pathname)
  }
}

const withHiddenHeaderOnQuotePage = branch(
  ({ isQuotePage, isQuickAppointmentPage }) => isQuotePage || isQuickAppointmentPage,
  renderNothing
)

export default compose(
  withType({ type: 'HeaderSystemAlert' }),
  connect(mapStateToProps, { closeHeaderSystemAlertState }),
  withHiddenHeaderOnQuotePage,
  withHandlers({
    handleClose: ({ closeHeaderSystemAlertState }) => () => {
      dispatchPageHeaderChanged()
      closeHeaderSystemAlertState()
    }
  })
)(HeaderSystemAlert)
