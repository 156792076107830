import { withProps, compose } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import reduxForm from 'components/common/hoc/withFormErrorFocus'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import BOTConnectToCarFax from './BOTConnectToCarFax'
import { CONNECT_TO_CARFAX_FORM } from './constants'
import createViewModel from './createViewModel'
import validate from './validate'
import { connectToCarfax } from './actions'
import { CONNECT_TO_CARFAX_MODAL } from 'components/ServiceHistory/constants'
import { hideConnectToCarfaxModal } from 'components/ServiceHistory/actions'

const withViewModel = withProps(() => ({
  viewModel: createViewModel()
}))
const withAccountEmail = withProps(({ email }) => ({
  accountEmail: email
}))

export default compose(
  withType({ type: 'BOTConnectToCarFax' }),
  connect(null, { onSubmit: connectToCarfax, hideConnectToCarfaxModal }),
  registerReduxModal({ name: CONNECT_TO_CARFAX_MODAL }),
  withAccountEmail,
  reduxForm({ form: CONNECT_TO_CARFAX_FORM, validate }),
  withViewModel
)(BOTConnectToCarFax)
