import { FRONT, REAR, WIDTH, RATIO, DIAMETER, FORM, MODAL, SUBMITTED } from './constants'

export const fieldState = {
  value: '',
  committedValue: '',
  values: [],
  filterValue: '',
  error: '',
  initialized: false,
  active: false,
  loading: false,
  disabled: true
}

export const submittedState = {
  [FRONT]: {
    [WIDTH]: '',
    [RATIO]: '',
    [DIAMETER]: ''
  },
  [REAR]: {
    [WIDTH]: '',
    [RATIO]: '',
    [DIAMETER]: ''
  }
}

export const sizeState = {
  [WIDTH]: {
    ...fieldState
  },
  [RATIO]: {
    ...fieldState
  },
  [DIAMETER]: {
    ...fieldState
  }
}

export const instanceState = {
  [FRONT]: {
    size: { ...sizeState },
    visible: true
  },
  [REAR]: {
    size: { ...sizeState },
    visible: false
  }
}

export const initialState = {
  [FORM]: { ...instanceState },
  [MODAL]: { ...instanceState },
  [SUBMITTED]: { ...submittedState }
}
