import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import SpinnerIcon from 'components/common/SpinnerIcon/SpinnerIcon'
import Text from 'components/common/Text/Text'
import Theme from 'components/common/Theme/Theme'
import { getDataAtProps } from 'helpers/utils'

const UpdatingBlock = ({ message, asset = 'spinner', alt = 'loading...', condition, className, children, ...props }) =>
  <Wrapper className={ className } { ...getDataAtProps(props) }>
    { condition &&
    <LoaderContainer>
      <LoaderCentered>
        <LoadingSpinnerIcon asset={ asset } alt={ alt } />
        { message && <LoadingMessage block>{ message }</LoadingMessage> }
      </LoaderCentered>
    </LoaderContainer> }
    { children }
  </Wrapper>

UpdatingBlock.propTypes = {
  alt: PropTypes.string,
  asset: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  condition: PropTypes.bool,
  message: PropTypes.string
}

export default UpdatingBlock

const Wrapper = styled.div`
  position: relative;
`

const LoaderCentered = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
`

const LoaderContainer = styled.div`
  background-color: ${Theme.colors.transparent_overlay_alt};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`

const spin = keyframes`
  0% { transform: rotate(-360deg); }
  100% { transform: rotate(0deg); }
`

const LoadingSpinnerIcon = styled(SpinnerIcon)`
  animation: ${spin} 1.7s linear infinite;
`

const LoadingMessage = styled(Text)`
  margin-top: 20px;
`
