import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import Link from 'components/common/Link/Link'
import { STORE_LOCATOR_PAGE } from 'constant'
import { menuItemStyles } from '../components/HeaderDropdown/HeaderDropdownMenu'
import * as DD from '../components/HeaderDropdown/HeaderDropdown'
import * as Utility from '../components/UtilityMenuStyles'

const HamburgerMyStoreMenu = ({
  active,
  combinedHandlers,
  mobileMainMenuExpanded,
  stopPropagation,
  store
}) =>
  <DD.Wrapper
    data-at-header-account>
    <Media.Mobile>
      <Utility.Menu hidden={ mobileMainMenuExpanded }>
        <Utility.Item>
          { !store &&
          <DD.TitleLink to={ STORE_LOCATOR_PAGE } data-at-title-link>
            <Icon alt="" asset="location-white" width="14" height="14" margin="-2px 7px 0 0" />
            Find A Store
          </DD.TitleLink>
          }
          { store &&
          <Fragment>
            <DD.Title
              bolder
              active={ active }
              addPaddingBottom={ active }
              { ...combinedHandlers }>
              <Icon data-static asset="location-white" width="14" height="14" margin="-2px 7px 0 0" />
              My Store
              <Icon asset="arrow-right-white" width={ 10 } hidden={ active } />
              <AddressWrapper>
                <AddressLine>
                  {`${store.address.address1}`}
                </AddressLine>
                <AddressLine>
                  {`${store.address.city}, ${store.address.state}, ${store.address.zipcode} `}
                </AddressLine>
              </AddressWrapper>
            </DD.Title>
            <DD.Content hidden={ !active } { ...stopPropagation }>
              <LinkWrapper>
                <Link to={ STORE_LOCATOR_PAGE }>Change My Store</Link>
                <Link to={ store.storeDetailsUrl }>View Store Details</Link>
              </LinkWrapper>
            </DD.Content>
          </Fragment>
          }
        </Utility.Item>
      </Utility.Menu>
    </Media.Mobile>
  </DD.Wrapper>

HamburgerMyStoreMenu.propTypes = {
  active: PropTypes.bool,
  combinedHandlers: PropTypes.shape(),
  mobileMainMenuExpanded: PropTypes.bool,
  stopPropagation: PropTypes.shape({
    onClick: PropTypes.func
  }),
  store: PropTypes.shape()
}

export default HamburgerMyStoreMenu

const LinkWrapper = styled.div`
  & ${Link} {
    ${menuItemStyles};
    ${Mixin.responsive('padding', '10px 0 9px 35px', '17px 0 14px 24px')};
  }
`

const AddressWrapper = styled.div`
  margin: 3.5px 20px;
`

const AddressLine = styled.div`
  padding: 3px 0 1.5px;
  ${Mixin.font.GothamBold(13)}
`
