import { compose, withHandlers, withStateHandlers, lifecycle } from 'recompose'
import isEqual from 'lodash/isEqual'
import Modal from './Modal'
import { MODAL_ID, MODAL_OVERLAY } from './constants'
import { fallbackLazyLoad } from '../hoc/withLazyLoader/helpers'
import { HAS_OBSERVER } from '../hoc/withLazyLoader/constants'
import { MODAL_CONTENT_ID } from 'constant'

const withModalState = withStateHandlers({
  modalFocusScopeRef: null,
  modalScrollHeight: null
}, {
  setModalFocusRef: () => modalFocusScopeRef => ({ modalFocusScopeRef }),
  setModalScrollHeight: () => modalScrollHeight => ({ modalScrollHeight })
})

const withModalRenderFocus = withHandlers({
  modalRenderFocus: ({ modalFocusScopeRef }) => () =>
    modalFocusScopeRef && modalFocusScopeRef.focus && modalFocusScopeRef.focus()
})

const withModalEventHandlers = withHandlers({
  onFocus: ({ modalFocusScopeRef, setModalScrollHeight, modalScrollHeight }) => () => {
    setModalScrollHeight(null)
    setTimeout(() => {
      if (modalFocusScopeRef) {
        const { scrollHeight: newScrollHeight, clientHeight } = modalFocusScopeRef || {}
        if (newScrollHeight !== modalScrollHeight) {
          if (newScrollHeight > clientHeight) {
            setModalScrollHeight(newScrollHeight + 20)
          } else if (!!newScrollHeight && !!clientHeight) {
            setModalScrollHeight(null)
          }
        }
      }
    })
  },
  handleOverlayClick: ({ closeHandler }) => e =>
    e.target && e.target.id === MODAL_OVERLAY && closeHandler()
})

const withLifeCycle = lifecycle({
  componentDidMount() {
    const { modalRenderFocus, handleOverlayClick } = this.props
    modalRenderFocus()

    if (!HAS_OBSERVER) {
      const modalRoot = document.getElementById(MODAL_ID)

      if (modalRoot && modalRoot.children.length) {
        setTimeout(fallbackLazyLoad)
        modalRoot.querySelector(`#${MODAL_CONTENT_ID}`).addEventListener('scroll', fallbackLazyLoad)
      }
    }

    const modalOverlay = document.getElementById(MODAL_OVERLAY)
    if (modalOverlay) {
      modalOverlay.addEventListener('click', handleOverlayClick)
    }
  },
  componentDidUpdate({ modalFocusScopeRef: prevModalFocusScopeRef }) {
    const { modalFocusScopeRef, modalRenderFocus } = this.props
    modalFocusScopeRef && !isEqual(prevModalFocusScopeRef, modalFocusScopeRef) && modalRenderFocus()
  },
  componentWillUnmount() {
    const { handleOverlayClick } = this.props
    if (!HAS_OBSERVER) {
      const modalRoot = document.getElementById(MODAL_ID)
      const modalContent = modalRoot && modalRoot.querySelector(`#${MODAL_CONTENT_ID}`)

      if (modalRoot && modalRoot.children.length && modalContent) {
        modalContent.removeEventListener('scroll', fallbackLazyLoad)
      }
    }

    const modalOverlay = document.getElementById(MODAL_OVERLAY)
    if (modalOverlay) {
      modalOverlay.removeEventListener('click', handleOverlayClick)
    }
  }
})

export default compose(
  withModalState,
  withModalRenderFocus,
  withModalEventHandlers,
  withLifeCycle
)(Modal)
