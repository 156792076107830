import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input } from 'components/common/FormElements/Input'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Vehicle from '../Vehicle'

const VehicleRadioInput = ({ input, vehicle, ...props }) =>
  <InputWrapper>
    <Input { ...props } { ...input }>
      <Vehicle { ...vehicle } />
    </Input>
  </InputWrapper>

export default VehicleRadioInput

VehicleRadioInput.propTypes = {
  input: PropTypes.shape(),
  vehicle: PropTypes.shape()
}

const InputWrapper = styled.div`
  display: table;
  border-bottom: 1px solid ${Theme.colors.light_grey};
  ${Mixin.responsive('padding', '23px 0 9px', '11px 0 9px')}
  ${Mixin.responsive('width', '425px', '100%')}
`
