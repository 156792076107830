import React from 'react'
import PropTypes from 'prop-types'
import Media from 'components/common/Media'
import Portal from 'components/common/Portal/Portal'
import Placeholder from 'components/common/Placeholder'
import ClientRendering from 'components/common/ClientRendering/ClientRendering'
import ChooseStore from './components/ChooseStore'
import SelectedMyStore from './components/SelectedMyStore'
import { BOT_MY_STORE_ID } from 'constant'

const MyStore = ({
  StoreDetails,
  sticky,
  ...props
}) =>
  <ClientRendering>
    <Media.Mobile>
      <Portal id={ BOT_MY_STORE_ID }>
        {
          !StoreDetails ?
            <ChooseStore { ...props } /> :
            <SelectedMyStore storeDetails={ StoreDetails } { ...props } />
        }
      </Portal>
      <Placeholder height="47px" ifTargetIdExists={ BOT_MY_STORE_ID } />
    </Media.Mobile>
    <Media.Desktop>
      {
        !StoreDetails ?
          <ChooseStore { ...props } /> :
          <SelectedMyStore storeDetails={ StoreDetails } { ...props } />
      }
    </Media.Desktop>
  </ClientRendering>


MyStore.propTypes = {
  sticky: PropTypes.bool,
  StoreDetails: PropTypes.shape(),
  storeLocatorLink: PropTypes.shape()
}

export default MyStore
