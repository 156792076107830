import { compose, withProps, withHandlers } from 'recompose'
import Stepper from './Stepper'
import { resetPickedValues, getNames } from '../../helpers'
import { YMME_TITLE } from '../../constant'

const withStepperProps = withProps(({
  stepNames
}) => ({
  totalSteps: stepNames.length - 1
}))

const withStepperHandlers = withHandlers({
  prevStepHandler: ({
    setData,
    data,
    setStep,
    step,
    handlerYmmeResetOnPrevStep,
    ymmeName,
    place,
    stepNames,
    setSearch,
    setPreVehicleDetailsLoadingStatus
  }) => (value) => {
    const clickedIndex = stepNames.findIndex(item => item === value)
    if (clickedIndex < step) {
      setSearch('')
      setData(resetPickedValues(data, clickedIndex, stepNames))
      setStep(clickedIndex)

      if (place === YMME_TITLE) {
        setPreVehicleDetailsLoadingStatus(false)
        const names = getNames(clickedIndex, stepNames)
        handlerYmmeResetOnPrevStep({ ymmeName, names })
      }
    }
  }
})

export default compose(
  withStepperProps,
  withStepperHandlers
)(Stepper)

