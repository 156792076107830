import { compose, withHandlers, withProps } from 'recompose'
import withType from 'components/common/hoc/withType'
import withNavigateToTireSearchOrShowYmme from 'components/common/hoc/withNavigateToTireSearchOrShowYmme'
import withShowConfirmTireSizeModalPreconfigured from 'components/common/hoc/withShowConfirmTireSizeModalPreconfigured'
import ShopByVehicleLink from './ShopByVehicleLink'

const withHandleShopByVehicleClick = withHandlers({
  handleShopByVehicleClick: ({ navigateToTireSearchOrShowYmme }) => e => {
    e.preventDefault()

    navigateToTireSearchOrShowYmme()
  }
})

const withConfigureHandleShopByVehicleClick = withProps(({ onClick, handleShopByVehicleClick }) => ({
  handleShopByVehicleClick: onClick || handleShopByVehicleClick
}))

export default compose(
  withType({ type: 'ShopByVehicleLink' }),
  withNavigateToTireSearchOrShowYmme,
  withHandleShopByVehicleClick,
  withShowConfirmTireSizeModalPreconfigured,
  withConfigureHandleShopByVehicleClick
)(ShopByVehicleLink)
