import { compose, lifecycle } from 'recompose'
import toggle from 'components/common/hoc/toggle'
import MenuItemWithSubmenu from './MenuItemWithSubmenu'

const withTriggerCollapseWhenMobileMenuIsCollapsed = lifecycle({
  UNSAFE_componentWillReceiveProps({ mobileMainMenuExpanded, expanded }) {
    // we need this to handle situation when we expand submenu and then close humburger. Before this fix, when we close
    // humburger with exaanded submenu it still remains expanded. We need to explicitly close submenu.
    if (!mobileMainMenuExpanded && expanded) {
      this.props.toggleHandlers.onClick()
    }
  }
})

export default compose(
  toggle,
  withTriggerCollapseWhenMobileMenuIsCollapsed
)(MenuItemWithSubmenu)
