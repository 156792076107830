import { handleActions } from 'redux-actions'
import {
  changeTab,
  resetModal,
  setVehicleData,
  toggleYmme,
  resetMakeAndModel,
  changeVehicleUpdateHandler,
  setDataFetcher
} from './actions'
import { VEHICLE_DETAILS_TAB } from './constants'

const initialState = {
  currentTab: VEHICLE_DETAILS_TAB,
  changeByLicencePlate: false,
  vehicleData: {},
  vehicleUpdateHandler: null,
  dataFetchHandler: null
}

const handleVehicleDataSet = (state, { payload: vehicleData }) => ({ ...state, vehicleData })

const handleTabChange = (state, { payload: newTab }) => ({
  ...state,
  currentTab: newTab
})

const handleYmmeToggle = ({ changeByLicencePlate, ...state }) => ({
  ...state,
  changeByLicencePlate: !changeByLicencePlate
})

const handleMakeAndModelReset = (state) => ({
  ...state,
  changeByLicencePlate: false
})

const handleVehicleUpdateHandlerChange = (state, { payload: vehicleUpdateHandler }) => ({
  ...state,
  vehicleUpdateHandler
})

const handleDataFetcherSet = (state, { payload: dataFetchHandler }) => ({
  ...state,
  dataFetchHandler
})

const vehicleEditor = handleActions({
  [setVehicleData]: handleVehicleDataSet,
  [changeTab]: handleTabChange,
  [toggleYmme]: handleYmmeToggle,
  [resetMakeAndModel]: handleMakeAndModelReset,
  [changeVehicleUpdateHandler]: handleVehicleUpdateHandlerChange,
  [setDataFetcher]: handleDataFetcherSet,
  [resetModal]: () => initialState
}, initialState)

export default vehicleEditor
