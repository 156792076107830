import { handleActions } from 'redux-actions'
import isArray from 'lodash/isArray'
import {
  setProductData,
  setSkusAddedToCart,
  resetSkusAddedToCart,
  removeSkuAddedToCart,
  addProductsWithChangedAvailabilityOrPrice,
  clearProductsWithChangedAvailabilityOrPrice
} from './actions'

const initialState = {
  recentlyAddedSku: [],
  evSkuIds: [],
  skusAddedToCart: [],
  productsWithChangedAvailability: []
}

const addSkusToLocalStorage = ({ partNo, availableAsOf, activeVehicleId }) => {
  const skusWithPreviousAvailability = JSON.parse(localStorage.getItem('skusWithPreviousAvailability')) || []
  const index = skusWithPreviousAvailability?.findIndex(item => item.partNo === partNo)

  if (index < 0) {
    localStorage.setItem('skusWithPreviousAvailability',
      JSON.stringify([...skusWithPreviousAvailability, { partNo, availableAsOf, activeVehicleId }])
    )
  }
}

const handleSetSkusAddedToCart = (state, payload) => {
  const { skusAddedToCart } = state
  const cartItems = JSON.parse(JSON.stringify(skusAddedToCart))

  if (payload) {
    const {
      partNumber: partNo,
      availableAsOf = '',
      sourceOfDelivery,
      sourceType,
      wareHouseId,
      qty,
      brand,
      availableTomorrow = false,
      availableToday = false,
      activeVehicleId
    } = payload
    const skuIndex = cartItems?.findIndex(item => item.partNo === partNo)
    if (skuIndex < 0) {
      cartItems.push({
        brand,
        partNo,
        qty,
        sourceOfDelivery,
        sourceType,
        wareHouseId,
        warehouse: wareHouseId,
        availableAsOf,
        availableToday,
        availableTomorrow
      })
    } else {
      cartItems[skuIndex] = {
        ...cartItems[skuIndex],
        qty,
        availableAsOf,
        availableToday,
        availableTomorrow,
        sourceOfDelivery,
        sourceType,
        wareHouseId,
        warehouse: wareHouseId
      }
    }
    addSkusToLocalStorage({ partNo, availableAsOf, activeVehicleId })
  }

  return {
    ...state,
    skusAddedToCart: [...cartItems]
  }
}

const handleRemoveSkuAddedToCart = (state, payload) => {
  const { skusAddedToCart } = state
  let cartItems = JSON.parse(JSON.stringify(skusAddedToCart))
  let skusWithPreviousAvailability = JSON.parse(localStorage.getItem('skusWithPreviousAvailability'))

  if (isArray(cartItems) && cartItems.length) {
    cartItems = cartItems.filter(item => item.partNo !== payload)
  }

  if (isArray(skusWithPreviousAvailability) && skusWithPreviousAvailability.length) {
    skusWithPreviousAvailability = skusWithPreviousAvailability.filter(item => item.partNo !== payload)
  }

  localStorage.setItem('skusWithPreviousAvailability', JSON.stringify(skusWithPreviousAvailability))

  return { ...state, skusAddedToCart: [...cartItems]}
}

const addToCartReducer = handleActions({
  [setProductData]: (state, { payload }) => ({
    ...state,
    recentlyAddedSku: payload.addToQuote,
    evSkuIds: payload.evSkuIds
  }),
  [setSkusAddedToCart]: (state, { payload }) => handleSetSkusAddedToCart(state, payload),
  [resetSkusAddedToCart]: (state) => ({ ...state, skusAddedToCart: []}),
  [removeSkuAddedToCart]: (state, { payload }) => handleRemoveSkuAddedToCart(state, payload),
  [addProductsWithChangedAvailabilityOrPrice]: (state, { payload }) =>
    ({ ...state, productsWithChangedAvailability: [...payload]}),
  [clearProductsWithChangedAvailabilityOrPrice]: (state) => ({ ...state, productsWithChangedAvailability: []})
}, initialState)

export default addToCartReducer
