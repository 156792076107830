import React from 'react'
import PropTypes from 'prop-types'
import HTMLContainer from 'components/common/HTMLContainer/HTMLContainer'

const PopoverContent = React.forwardRef(({
  active,
  className,
  info,
  children,
  components: {
    PopoverInnerContent
  } = {},
  ...props
}, ref) =>
  <PopoverInnerContent
    active={ active }
    className={ className }
    ref={ ref }
    { ...props }>
    { children }
    { info && <HTMLContainer content={ info } /> }
  </PopoverInnerContent>
)

PopoverContent.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  components: PropTypes.shape(),
  info: PropTypes.string
}

export default PopoverContent
