/* eslint-disable camelcase */
import {
  googleAnalyticProductImpressions,
  googleAnalyticProductAddToCart,
  googleAnalyticPurchase
} from 'actions/googleAnalytic'
import { getGBBDetails } from 'selectors/getGBBDetails'
import { getCurrentStoreId } from 'selectors/getCurrentStore'
import { getQuoteInfo } from 'selectors/shoppingData'
import {
  getAddToCartProductData,
  getGTMAddToCartProduct,
  getGTMServiceData,
  getGTMKitData,
  getGTMProductData,
  getGTMOTDPData,
  getGTMPromotionAsProductData
} from 'helpers/googleAnalytic'
import { extractQuickAppointmentConfirmationData } from '../GTM/helpers'
import { formatFullStoryProductObj } from './helpers'
import { PRODUCT_IMPRESSION, ADD_TO_CART, CONFIRMATION } from './constants'

export default store => next => action => {
  try {
    const { FullStory } = window
    if (action.type === String(googleAnalyticProductImpressions)) {
      if (FullStory && FullStory.event) {
        [...action.payload.data]
          .forEach(({ dimension1: stockstatus_str, ...product }) =>
            FullStory.event(PRODUCT_IMPRESSION, { stockstatus_str, ...formatFullStoryProductObj(product) }))
      }
    }

    if (action.type === String(googleAnalyticProductAddToCart)) {
      if (FullStory && FullStory.event) {
        const { rawProducts, label, dimension2 } = getAddToCartProductData(store, action.payload.product)
        rawProducts
          .filter(({ product }) => Boolean(product))
          .map(({ product, quantity }) => getGTMAddToCartProduct({ product, quantity, label, dimension2 }))
          .forEach(({ quantity: quantity_real, ...product }) =>
            FullStory.event(ADD_TO_CART, { quantity_real, ...formatFullStoryProductObj(product) }))
      }
    }

    if (action.type === String(googleAnalyticPurchase)) {
      if (FullStory && FullStory.event) {
        const { payload: purchaseData } = action
        const state = store.getState()
        const gBBDetails = getGBBDetails(state)
        const affiliation = `Store #${getCurrentStoreId(state)}`
        const {
          orderId: orderId_str,
          revenue: revenue_real,
          products: productData = [],
          services = [],
          installationKits = [],
          promotions = []
        } = purchaseData ? extractQuickAppointmentConfirmationData(purchaseData) : getQuoteInfo(state)

        const products = [
          ...installationKits.map(getGTMKitData),
          ...productData.map(product => getGTMProductData({ product, gBBDetails })),
          ...getGTMOTDPData(productData),
          ...services.map(service =>
            getGTMServiceData({ service, category: purchaseData && 'Quick Appointment' })),
          ...promotions.map(getGTMPromotionAsProductData)
        ]
          .map(({ quantity: quantity_real, ...product }) =>
            ({ quantity_real, ...formatFullStoryProductObj(product) }))

        FullStory.event(CONFIRMATION, {
          orderId_str,
          revenue_real,
          appointment_store_str: affiliation,
          products
        })
      }
    }
  } catch (err) {
    console.log('Error occured during Google Analytic event dispatch', err)
  }

  return next(action)
}

