import { createGlobalStyle } from 'styled-components'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import prevArrow from './assets/Prev.svg'
import nextArrow from './assets/Next.svg'
import nextArrowDisabled from './assets/NextDisabled.svg'
import prevArrowDisabled from './assets/PrevDisabled.svg'

const CarouselGlobalStyles = createGlobalStyle`
  .slick-list {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;

    :focus {
      outline: none;
    }
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    /* stylelint-disable */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* stylelint-enable */
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-right: auto;
    margin-left: auto;

    ::before,
    ::after {
      display: table;
      content: '';
    }

    ::after {
      clear: both;
    }
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    overflow: hidden;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    padding: 0;
    font-size: 0;
    cursor: pointer;
    background-color: ${Theme.colors.red};
    background-position: 50% 50%;
    background-size: contain;
    border: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
  }

  .slick-arrow + .slick-list {
    ${Mixin.responsive('margin', '0 50px', '0')}
  }

  .slick-prev {
    background-image: url(${prevArrow});
    ${Mixin.responsive('left', '0', '-39px')}
  }

  .slick-next {
    background-image: url(${nextArrow});
    ${Mixin.responsive('right', '0', '-39px')}
  }

  .slick-prev.slick-disabled {
    background-image: url(${prevArrowDisabled});
    background-size: contain;
  }

  .slick-next.slick-disabled {
    background-image: url(${nextArrowDisabled});
    background-size: contain;
  }

  .slick-dots {
    position: absolute;
    bottom: 40px;
    left: 50px;
    padding: 0;
    margin: 0;
    line-height: 0;
    text-align: center;
    list-style: none;
    ${Media.mobile`
      left: 16px;
      bottom: 30px;
      right: 16px;
    `}

    li {
      &:not(:first-child) {
        ${Mixin.responsive('margin-left', '12px', '10px')}
      }

      &.slick-active button {
        background-color: ${Theme.colors.yellow};
        opacity: 1;
      }

      button {
        border: none;
        border-radius: 50%;
        background-color: ${Theme.colors.white};
        opacity: 0.8;
        padding: 0;
        ${Mixin.responsive('width', '12px', '8px')}
        ${Mixin.responsive('height', '12px', '8px')}
      }
    }
  }
`

export default CarouselGlobalStyles
