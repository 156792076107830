import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Root from 'components/Root/Root'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import NavigationsTracker from 'components/NavigationsTracker/NavigationsTracker'

/* eslint-disable react/prop-types */

const App = ({ store, history }) =>
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <ScrollToTop>
        <NavigationsTracker>
          <Root />
        </NavigationsTracker>
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>

export default App
