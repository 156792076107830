import { Validator } from 'services/validator'
import {
  MILEAGE_EMPTY
} from 'components/MyGarage2.0/constants'

import { VEHICLE_MILEAGE } from 'constant'

export default Validator.combineValidators({
  [VEHICLE_MILEAGE]: {
    validators: Validator.required(),
    errorMessage: MILEAGE_EMPTY
  }
})
