import { compose, lifecycle, withHandlers } from 'recompose'
import toggle from 'components/common/hoc/toggle'
import { noop } from 'helpers/utils'
import Expander from './Expander'

const withExpanderHandlers = withHandlers({
  handleClick: ({ toggleHandlers, onClick = noop }) => () => {
    toggleHandlers.onClick()
    onClick()
  }
})

const withParentToggleSetters = lifecycle({
  componentDidMount() {
    const { parentToggleSetter, toggleHandlers } = this.props
    if (parentToggleSetter) {
      parentToggleSetter(toggleHandlers)
    }
  }
})

export default compose(
  toggle,
  withExpanderHandlers,
  withParentToggleSetters
)(Expander)

