import { css } from 'styled-components'

export default () => css`
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;

  @media (-webkit-min-device-pixel-ratio: 0) {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
`
