import { withProps, compose } from 'recompose'
import withHandlePhoneLinkClick from 'components/common/hoc/withHandlePhoneLinkClick'
import { formatPhoneNumber } from 'src/helpers/phone-number'
import PhoneLink from './PhoneLink'

export default compose(
  withProps(
    ({ number }) => ({
      displayFormat: formatPhoneNumber(number),
      href: formatPhoneNumber(number).replace(/[^0-9]/g, '')
    })
  ),
  withHandlePhoneLinkClick
)(PhoneLink)
