import { compose, withHandlers, withProps } from 'recompose'
import { capitalize } from 'lodash'
import Search from './Search'
import { searchAction } from '../../helpers'
import { LICENSE_PLATE_TITLE } from '../../constant'
import { REAR } from 'components/VehicleConfigurator/components/withTireSize/constants'

const withSearchHandlers = withHandlers({
  resetSearch: ({ setSearch }) => () => {
    setSearch('')
  },
  onChangeHandler: ({ optionsData, setEndpointData, setSearch, place, setLicenseError }) => (e) => {
    const value = e.target.value
    setSearch(value)
    if (value) {
      const searchedData = searchAction(optionsData, value)
      setEndpointData(searchedData)
    }

    if (place === LICENSE_PLATE_TITLE) {
      setLicenseError('')
    }
  }
})

const withInfo = withProps(({
  stepNames,
  step,
  activePosition
}) => {
  let placeholder = ''

  if (activePosition) {
    placeholder = activePosition === REAR ? `Find ${capitalize(stepNames[step - 3].value)}:` : `Find ${capitalize(stepNames[step].value)}:`
  } else {
    placeholder = `Find ${capitalize(stepNames[step])}:`
  }

  return { placeholder }
})

export default compose(
  withSearchHandlers,
  withInfo
)(Search)

