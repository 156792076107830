import { CODE_SUCCESS } from 'constant'
import ApiError from './api-error'

export const isApiError = err => err.isApiError

/**
 * response can contain populated statusList which consists of multiple status objects, in this case
 * we should collect all statuses descriptions as array to show them as a list
 * @param statusList
 */
export const checkStatusList = ({ statusList = [], ...payloadData } = {}) => {
  if (statusList.length) {
    throw new ApiError({
      errors: statusList.map(({ description }) => description),
      statusList,
      payloadData
    })
  }
}

export const checkStatus = ({ status, ...payloadData } = {}) => {
  if (status && status.code !== CODE_SUCCESS) {
    throw new ApiError({ status, payloadData })
  }
}

/* eslint-disable no-param-reassign, no-restricted-syntax, guard-for-in */
const globalNullReplacementHandler = obj => {
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      obj[key] = globalNullReplacementHandler(obj[key])
    } else if (obj[key] === null) {
      obj[key] = undefined
    }
  }
  return obj
}

const globalNullReplacementHandlerAndLogger = (obj, parentPath = 'CartridgeRoot') => {
  for (const key in obj) {
    const currentPath = key.match(/\d*/)[0] !== '' ? `${parentPath}[${key}]` : `${parentPath}.${key}`
    if (obj[key] && typeof obj[key] === 'object') {
      obj[key] = globalNullReplacementHandlerAndLogger(obj[key], currentPath)
    } else if (obj[key] === null) {
      console.log('Null Replace: ', currentPath)
      obj[key] = undefined
    }
  }
  return obj
}
/* eslint-enable no-param-reassign, no-restricted-syntax, guard-for-in */

export const globalNullReplacement = data => {
  if (process.env.BOT_DISABLE_GLOBAL_NULL_REPLACEMENT !== 'true') {
    return process.env.BOT_ENABLE_NULL_REPLACEMENT_LOGGER === 'true' ?
      globalNullReplacementHandlerAndLogger(data) :
      globalNullReplacementHandler(data)
  }
  return data
}
