import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AccordionItem from './AccordionItem'

const ArticlesAccordion = ({
  items,
  onItemClick,
  activeIndex,
  handleHide
}) => (
  <Wrapper className="accordion">
    {items.map((item, index) => (
      <AccordionItem
        key={ item.categoryName }
        handleHide={ handleHide }
        contentVisible={ index === activeIndex }
        item={ item }
        onClick={ () => onItemClick(index) } />
    ))}
  </Wrapper>
)

ArticlesAccordion.propTypes = {
  activeIndex: PropTypes.number,
  handleHide: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape()),
  onItemClick: PropTypes.func
}

export default ArticlesAccordion

const Wrapper = styled.nav`
  width: 100%;
`
