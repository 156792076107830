import { Validator, errorMessages } from 'services/validator'
import { EMAIL_PROPERTY, EMAIL_EMPTY_ERROR } from './constants'

export default Validator.combineValidators({
  [EMAIL_PROPERTY]: [{
    validators: Validator.required(),
    errorMessage: EMAIL_EMPTY_ERROR
  },
  {
    validators: Validator.email(),
    errorMessage: errorMessages.EMAIL_ERROR_MESSAGE
  }]
})
