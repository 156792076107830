import { SubmissionError } from 'redux-form'
import { rest, isApiError } from 'services'
import { hide } from 'redux-modal'
import { SHARE_VIA_EMAIL_MODAL } from 'constant'
import { UNKNOWN_ERROR, MESSAGE_OF_SUCCESSFUL_SENDING } from './constants'
import { showSuccessfulSubmitModal } from 'components/SuccessfulSubmitModal/actions'

// TODO: replace with real endpoint for posting page shares
export const sharePageViaEmail = values => dispatch =>
  rest.api.sharePageViaEmail(values)
    .then(() => {
      dispatch(hide(SHARE_VIA_EMAIL_MODAL))
      dispatch(showSuccessfulSubmitModal({
        description: MESSAGE_OF_SUCCESSFUL_SENDING
      }))
    })
    .catch(err => {
      if (isApiError(err)) {
        throw new SubmissionError({ _error: err.status.description })
      } else {
        throw new SubmissionError({ _error: UNKNOWN_ERROR })
      }
    })
