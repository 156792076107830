import { handleActions } from 'redux-actions'
import { VEHICLE_DETAILS, TIRE_SIZES, EXTRA_VEHICLE_DETAILS } from './constants'
import {
  setVehicleDetails,
  setVehicleTireSizes,
  loadConfirmTireSizeData,
  cleanConfirmTireSizeData,
  setExtraVehicleDetails
} from 'actions/confirmTireSize'

const initialState = {
  [VEHICLE_DETAILS]: {},
  [TIRE_SIZES]: [],
  [EXTRA_VEHICLE_DETAILS]: {}
}

export default handleActions({
  [setVehicleDetails]: (state, { payload }) => ({
    ...state,
    [VEHICLE_DETAILS]: {
      ...payload
    }
  }),
  [setExtraVehicleDetails]: (state, { payload }) => ({
    ...state,
    [EXTRA_VEHICLE_DETAILS]: {
      ...payload
    }
  }),
  [setVehicleTireSizes]: (state, { payload }) => ({
    ...state,
    [TIRE_SIZES]: [
      ...payload
    ]
  }),
  [loadConfirmTireSizeData]: (state, { payload }) => payload ? { ...payload } : state,
  [cleanConfirmTireSizeData]: () => ({ ...initialState })
}, initialState)
