export default [
  'BOTAppointmentContentSlotMain',
  'ContentSlotMain',
  'MainHeadlineContent',
  'PromotionBannerSpotlight',
  'ScrollablePromotionBannerSpotlight',
  'ValueProposition',
  'YMMEControll',
  'TeaserHtmlContentBlock'
]

/*
Appears on:
/home,
/my-account/account-details,
/my-account/service-history?access=signup,
/tires,
/resources/all-about-tires,
/resources,
/services,
/services/tire-service-and-repair,
/services/tire-service-and-repair/tire-repair,
/my-account/my-appointments?loggedin=true,
/my-account/my-appointments
*/
