import { compose, lifecycle, mapProps } from 'recompose'
import withType from 'components/common/hoc/withType'
import toggle from 'components/common/hoc/toggle'
import HeaderMobileMainContent from './HeaderMobileMainContent'

const withForceCollapseWhenParentHidden = lifecycle({
  UNSAFE_componentWillReceiveProps({ hidden, expanded }) {
    if (hidden && expanded) {
      this.props.toggleHandlers.onClick()
    }
  }
})

const withHiddenFromParent = mapProps(({ expanded, ...props }) => ({
  ...props,
  hidden: !expanded
}))

export default compose(
  withType({ type: 'HeaderMobileMainContent', placeholders: ['headerContent']}),
  withHiddenFromParent,
  toggle,
  withForceCollapseWhenParentHidden
)(HeaderMobileMainContent)
