import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import HTMLContainer from 'components/common/HTMLContainer/HTMLContainer'
import Button from 'components/common/Button/Button'
import { HEADER_SYSTEM_ALERT_ID } from 'constant'

const HeaderSystemAlert = ({ visibility, content, handleClose }) =>
  <Wrapper hidden={ !visibility } id={ HEADER_SYSTEM_ALERT_ID }>
    <SystemAlertMessage content={ content } />
    <CloseButtonWrapper link aria-label="Close" onClick={ handleClose } >
      <Icon asset="cross-white" alt="" width={ 12 } height={ 12 } />
    </CloseButtonWrapper>
  </Wrapper>

HeaderSystemAlert.propTypes = {
  content: PropTypes.string,
  handleClose: PropTypes.func,
  visibility: PropTypes.bool
}

export default HeaderSystemAlert

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background: ${Theme.colors.light_red};
  overflow: hidden;
  height: 48px;
`

const SystemAlertMessage = styled(HTMLContainer)`
  flex: 1;
  padding-left: 32px;
  color: ${Theme.colors.white};
  text-align: center;
`

const CloseButtonWrapper = styled(Button)`
  background: ${Theme.colors.light_red};
  height: 100%;
`
