import { connect } from 'react-redux'
import { compose, withHandlers, branch, renderComponent, withProps, lifecycle } from 'recompose'
import withType from 'components/common/hoc/withType'
import withScrollToAnchor from 'components/common/hoc/withScrollToAnchor'
import withShowConfirmTireSizeModalPreconfigured from 'components/common/hoc/withShowConfirmTireSizeModalPreconfigured'
import { getHasActiveVehicleStatus, getActiveVehicle, getCurrentStore } from 'selectors'
import { showStoreLocatorModal } from 'actions/storeLocator'
import { addNewVehicle, navigateToTireSearch } from './actions'
import { getEligibleTiresUrl, getRegionNameLinkFromSearch, getAssociatedPromoId } from './helpers'
import BOTPromotionDetails from './BOTPromotionDetails'
import TestBOTPromotionDetails from './TestBOTPromotionDetails'
import { getLocationSearch } from 'selectors/getPageLocation'
import { getIsModal } from 'selectors/modal'
import { getPromoDetailsExperience } from 'selectors/thirdPartyData'
import {
  TEST_PROMO_ID,
  PROMO_TITLE_ID,
  PROMOTION_DESCRIPTION_FOOTNOTE_LINK,
  FOOTNOTE_JUMPBACK_LINK
} from './constants'
import { googleAnalyticPromotionClicks } from 'actions/googleAnalytic'
import { processPromotionLink } from '../PromotionResultList/PromotionsList/helpers'
import { navigateToPromo } from '../PromotionResultList/PromotionsList/actions'
import { getCurrentStoreIsE2EAppointment } from 'selectors/getCurrentStore'
import { SCHEDULE_APPOINTMENT, REQUEST_APPOINTMENT, PROMO_DETAILS_SOURCE, YMME_NAME } from 'constant'
import { showNewShopTiresModal } from '../NewShopTiresModal/actions'

const mapStateToProps = state => ({
  regionNameLink: getRegionNameLinkFromSearch({ search: getLocationSearch(state) }),
  hasStore: Boolean(getCurrentStore(state)),
  hasActiveVehicle: getHasActiveVehicleStatus(state),
  isModal: getIsModal(state),
  vehicle: getActiveVehicle(state),
  promoDetailsExperience: getPromoDetailsExperience(state),
  isE2EAppointment: getCurrentStoreIsE2EAppointment(state)
})

const withFootnoteLinkContent = withProps(({
  promotionDetails,
  promotionDetails: {
    promotionDescription,
    termsAndConditions
  } = {},
  isE2EAppointment
}) => ({
  promotionDetails: {
    ...promotionDetails,
    promotionDescription: `${promotionDescription}${PROMOTION_DESCRIPTION_FOOTNOTE_LINK}`,
    termsAndConditions: `${termsAndConditions}${FOOTNOTE_JUMPBACK_LINK}`
  },
  appointmentBtnTitle: isE2EAppointment ? SCHEDULE_APPOINTMENT : REQUEST_APPOINTMENT
}))

const withScrollToProps = withProps({
  targetId: PROMO_TITLE_ID
})

const withEligibleTiresClickHandler = withHandlers({
  handleSeeEligibleTiresClick: ({
    vehicle,
    hasActiveVehicle,
    promotionDetails: {
      associatedPromotionsLink,
      promotionId
    } = {},
    navigateToTireSearch,
    showNewShopTiresModal
  }) => event => {
    event && event.preventDefault && event.preventDefault()

    const url = getEligibleTiresUrl({ associatedPromotionsLink, promotionId, vehicle, hasActiveVehicle })

    return !hasActiveVehicle ?
      showNewShopTiresModal({
        isOnlyVehicle: true,
        ymmeName: YMME_NAME,
        withShowConfirmTireSizeModal: true,
        source: PROMO_DETAILS_SOURCE,
        promo: url
      }) :
      navigateToTireSearch({ url })
  },
  handleFindStoreClick: ({ showStoreLocatorModal }) => (e) => {
    e.preventDefault()
    showStoreLocatorModal()
  }
})

const withConfigureHandleSeeEligibleTiresClick = withProps(({
  onClick,
  promotionDetails: {
    promotionId,
    associatedPromotionsLink
  } = {},
  handleSeeEligibleTiresClick
}) => ({
  handleSeeEligibleTiresClick: onClick ?
    e => onClick(e,
      {
        promotionId,
        hasActiveVehicle: true,
        associatedPromoId: getAssociatedPromoId(associatedPromotionsLink)
      }) :
    handleSeeEligibleTiresClick
}))

const withRenderTestPromoDetails = branch(({ promoDetailsExperience, promotionDetails: { promotionId = '' } = {}}) =>
  promotionId === TEST_PROMO_ID && !!promoDetailsExperience,
renderComponent(TestBOTPromotionDetails))

const withLifecyle = lifecycle({
  componentDidUpdate({ hasStore: prevHasStore }) {
    const { scrollToAnchorHandler, hasStore } = this.props
    hasStore !== prevHasStore && hasStore && setTimeout(() => scrollToAnchorHandler())
  }
})

const withPromotionClickHandler = withHandlers({
  handlePromotionClick: ({ googleAnalyticPromotionClicks, regionName, navigateToPromo }) => ({ item, index }) => {
    const { promotionLinkUrl } = item
    navigateToPromo(processPromotionLink({ promotionLinkUrl, regionName }))
    googleAnalyticPromotionClicks({ item, index })
  }
})

export default compose(
  withType({ type: 'BOTPromotionDetails' }),
  connect(mapStateToProps, {
    navigateToTireSearch,
    addNewVehicle,
    getActiveVehicle,
    showStoreLocatorModal,
    googleAnalyticPromotionClicks,
    navigateToPromo,
    showNewShopTiresModal
  }),
  withScrollToProps,
  withScrollToAnchor,
  withLifecyle,
  withFootnoteLinkContent,
  withShowConfirmTireSizeModalPreconfigured,
  withEligibleTiresClickHandler,
  withConfigureHandleSeeEligibleTiresClick,
  withRenderTestPromoDetails,
  withPromotionClickHandler
)(BOTPromotionDetails)
