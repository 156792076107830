// Drawer Ids
export const STORE_LOCATION = 'storeLocationDrawer'
export const DATE_TIME_SELECTOR = 'dateTimeSelectorDrawer'
export const APPOINTMENT_INFO = 'appointmentInfoDrawer'
export const PRODUCTS_AND_SERVICES = 'productsAndServicesDrawer'

export const APPOINTMENT_CONTACT_INFO_TARGET_ID = 'appointmentContactInfo'
export const SUBMIT_ERROR_ID = 'submitError'

// General error message
export const NOT_SELECTED_ERROR = 'Please make a selection.'

// BOTDateTimeSelector constants
export const DATE_PICKER_QUESTION = 'Please select a date on the calendar to view available times.'

// Appointment Success Message
export const APPOINTMENT_SUCCESS_MESSAGE = 'Thank you for your request. You will receive a call or email confirming your appointment. Please call your local Big O Tires for any changes or cancellations.'

// Promo Expiration
export const CALL_STORE_DESCRIPTION = 'Please call your store to see if they can find a way to fit you in before the expiration date'
export const SELECT_DATE_DESCRIPTION = 'Please select an earlier appointment date if you want to use this promotion'

export const SELECT_DATE_TITLE = 'A Promotion in your cart expires before your selected appointment date & time.'
export const CALL_STORE_TITLE = 'This promotion expires before your appointment date.'
