import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import DropdownOption from '../common/DropdownOption'
import CommonTextInput from '../CommonTextInput'
import * as UI from '../SharedStyles'

const DropdownInput = ({
  options,
  Option = DropdownOption,
  optionsMaxHeight,
  onChange,
  onBlur,
  onFocus,
  handleToggle,
  ...props
}) =>
  <CommonTextInput
    { ...props }
    readOnly
    dropdown
    Icon={
      <DropdownIcon
        asset="dropdown-arrow"
        alternative="dropdown-arrow-disabled"
        condition={ props.disabled }
        disabled={ props.disabled }
        alt="dropdown"
        width={ 8 } />
    }
    onClick={ handleToggle }>
    {
      props.active &&
        <UI.Options maxHeight={ optionsMaxHeight }>
          {
            options.map((option, index) =>
              <Option
                key={ index }
                value={ option }
                displayName={ option }
                onSelect={ value => {
                  onChange(value)
                  onBlur(value)
                } } />
            )
          }
        </UI.Options>
    }
  </CommonTextInput>

export default DropdownInput

DropdownInput.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  handleToggle: PropTypes.func,
  input: PropTypes.shape(),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  Option: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  optionsMaxHeight: PropTypes.number
}

const DropdownIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 19px;
  cursor: pointer;
  color: ${Theme.colors.black};
  ${props => props.disabled && css`
    color: ${Theme.colors.grey};
  `}
`
