import React from 'react'
import PropTypes from 'prop-types'
import {
  BOT_GLOBAL_MESSAGE_ID,
  BOT_GLOBAL_MESSAGE_PLACEHOLDER_ID,
  BOT_GLOBAL_MESSAGE_PORTAL_ID
} from 'constant'
import Portal from 'components/common/Portal/Portal'
import Placeholder from 'components/common/Placeholder'
import ClientRendering from 'components/common/ClientRendering/ClientRendering'
import GlobalMessagePlaceholder from './components/GlobalMessagePlaceholder'

const HeaderGlobalMessage = ({ globalHeaderMessage, sticky, visibility, placeHolderHeight, ...props }) =>
  <ClientRendering>
    <Portal id={ BOT_GLOBAL_MESSAGE_ID }>
      <GlobalMessagePlaceholder
        globalHeaderMessage={ globalHeaderMessage }
        visibility={ visibility }
        placeHolderHeight={ placeHolderHeight }
        { ...props } />
    </Portal>
    <Portal id={ BOT_GLOBAL_MESSAGE_PORTAL_ID }>
      <Placeholder
        id={ BOT_GLOBAL_MESSAGE_PLACEHOLDER_ID }
        height={ `${placeHolderHeight}px` }
        hidden={ !visibility || !globalHeaderMessage } />
    </Portal>
  </ClientRendering>


HeaderGlobalMessage.propTypes = {
  globalHeaderMessage: PropTypes.string,
  placeHolderHeight: PropTypes.number,
  sticky: PropTypes.bool,
  visibility: PropTypes.bool
}

export default HeaderGlobalMessage
