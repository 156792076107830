import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'

export default WrappedComponent => {
  class WithComponentRef extends Component {
    setComponentRef = component => { this.component = component }
    // eslint-disable-next-line
    getComponentDOMNode = () => findDOMNode(this.component)

    render() {
      return <WrappedComponent
        ref={ this.setComponentRef }
        getComponentDOMNode={ this.getComponentDOMNode }
        { ...this.props } />
    }
  }

  return WithComponentRef
}
