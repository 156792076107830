import React from 'react'
import PropTypes from 'prop-types'
import StickyHeader from 'components/StickyHeader'
import Icon from 'components/common/Icon/Icon'
import StickyHeaderWrapper from 'components/common/StickyHeaderWrapper'
import { MAIN_CONTENT, FOOTER } from 'constant'

const OneColumnPage = ({ placeholders: { footer, header, mainContent }, maxWidthPadding }) =>
  <div>
    <StickyHeaderWrapper>
      { header }
      <StickyHeader data-printless />
    </StickyHeaderWrapper>
    <Icon data-printonly asset="logo" alt="BigO Tire" width={ 228 } height={ 27 } />
    <main
      tabIndex="-1"
      id={ MAIN_CONTENT }
      data-max-width
      data-max-width-padding={ maxWidthPadding }
      data-relative >
      { mainContent }
    </main>
    <div id={ FOOTER }>
      { footer }
    </div>
  </div>

OneColumnPage.propTypes = {
  maxWidthPadding: PropTypes.bool,
  placeholders: PropTypes.shape({
    footer: PropTypes.array,
    header: PropTypes.array,
    mainContent: PropTypes.array
  })
}

export default OneColumnPage
