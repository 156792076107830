import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from 'react-google-maps'
import { coordsToGoogleMapCoords } from '../Map/helpers'
import { DEFAULT_MARKER, assetMap } from './constants'

const GoogleMapsMarker = ({ children, position, asset = DEFAULT_MARKER }) =>
  <Marker position={ coordsToGoogleMapCoords(position) } icon={ assetMap[asset] } >
    { children }
  </Marker>

GoogleMapsMarker.propTypes = {
  asset: PropTypes.string,
  children: PropTypes.node,
  position: PropTypes.shape()
}

export default GoogleMapsMarker
