import { lifecycle, withHandlers, compose, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { getPageLocation } from 'selectors'
import { isTireComparisonUrl } from 'helpers/location'
import debounce from 'lodash/debounce'
import StickyHeader from './StickyHeader'
import { getHeightById, isElementHidden } from 'src/helpers/utils'
import Theme from 'components/common/Theme/Theme'
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_NAVIGATOR_HEIGHT,
  HEADER_SYSTEM_ALERT_ID,
  HEADER_ID,
  HEADER_TOP_NAVIGATOR,
  PAGE_HEADER_CHANGED,
  EXPIRED_SESSION_ID
} from 'constant'
import { isMobile } from 'components/common/Media/helpers'

const mapStateToProps = ({ sticky: { sticky } = {}, ...state }) => ({
  sticky,
  hideCompareHeader: !isTireComparisonUrl(getPageLocation(state))
})

const withDynamicTop = withStateHandlers({ dynamicTop: 0 }, {
  setDynamicTop: () => (dynamicTop, isSystemAlertDisplayed) => ({
    dynamicTop,
    isSystemAlertDisplayed
  })
})

export const withHandleChange = withHandlers({
  handleHeaderChange: ({ setDynamicTop, dynamicTop }) =>
    debounce(() => {
      const headerSystemAlertHeight = getHeightById({ id: HEADER_SYSTEM_ALERT_ID })
      const desktopHeaderHeight = getHeightById({ id: HEADER_ID })
      const sessionTimeoutHeight = getHeightById({ id: EXPIRED_SESSION_ID })
      const navigatorHeight = isElementHidden({ id: HEADER_TOP_NAVIGATOR }) ? DESKTOP_NAVIGATOR_HEIGHT : 0
      const newDynamicTop = isMobile()
        ? MOBILE_HEADER_HEIGHT + headerSystemAlertHeight + sessionTimeoutHeight
        : desktopHeaderHeight + navigatorHeight
      if (newDynamicTop !== dynamicTop) {
        setDynamicTop(newDynamicTop, !!headerSystemAlertHeight)
      }
    }, Theme.scrollDebounceTimeout)
})

const withSystemAlertListeners = lifecycle({
  componentDidMount() {
    const { handleHeaderChange } = this.props
    handleHeaderChange()
    window.addEventListener(PAGE_HEADER_CHANGED, handleHeaderChange)
    window.addEventListener('resize', handleHeaderChange)
  },
  componentWillUnmount() {
    const { handleHeaderChange } = this.props
    window.removeEventListener(PAGE_HEADER_CHANGED, handleHeaderChange)
    window.removeEventListener('resize', handleHeaderChange)
  }
})


export default compose(
  withDynamicTop,
  withHandleChange,
  withSystemAlertListeners,
  connect(mapStateToProps)
)(StickyHeader)
