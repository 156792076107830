export const getAddToQuote = state => state.addToQuote || {}

export const getProductBySkuId = (state, props) => {
  const {
    recentlyAddedSku: {
      recentlyAddedSku = []
    } = {}
  } = getAddToQuote(state)

  return recentlyAddedSku.find(sku => sku.skuId === props.skuId)
}
