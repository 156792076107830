import { extractFormattedSizes } from './createTireSizeString'
import { formatTireSize } from './getSearchByVehicleUrl'
import { SEARCH_BY_SIZE, SEARCH_BY_TRAILER } from 'constant'

const generateUrlWithExtraQueryParams = (url, extraQueryParams) => `${url}${extraQueryParams}`

export default (tireSize, extraQueryParams, isTrailer) => {
  const { tireFrontSize, tireRearSize } = extractFormattedSizes(tireSize)
  const url = `${isTrailer ? SEARCH_BY_TRAILER : SEARCH_BY_SIZE}/${tireFrontSize}/${tireRearSize}`

  if (extraQueryParams) {
    return generateUrlWithExtraQueryParams(url, extraQueryParams)
  }

  return url
}

export const getSearchByTireSizeUrlFromVehicle = ({ activeVehicle = {}, extraQueryParams, isTrailer }) => {
  const { frontTireSize, rearTireSize } = activeVehicle
  let url = `${isTrailer ? SEARCH_BY_TRAILER : SEARCH_BY_SIZE}/${formatTireSize(frontTireSize)}`

  if (rearTireSize) {
    url += `/${formatTireSize(rearTireSize)}`
  }

  if (extraQueryParams) {
    return generateUrlWithExtraQueryParams(url, extraQueryParams)
  }

  return url
}
