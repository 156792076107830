import { connect } from 'react-redux'
import { hide, show } from 'redux-modal'
import { compose, withHandlers } from 'recompose'
import { getHasActiveVehicleStatus } from 'selectors'
import getActiveVehicle, { getIsActiveVehicleTrailer } from 'selectors/getActiveVehicle'
import { navigateToSearchByVehicle } from 'actions/shopByVehicle'
import { navigateToSearchByTireSizes } from 'actions/shopByTires'
import withShowVehicleConfiguratorModalPreconfigured from './withShowVehicleConfiguratorModalPreconfigured'
import { showNewShopTiresModal } from '../../NewShopTiresModal/actions'
import { TAB_YMME_NAME, HEADER_SOURCE, YMME_NAME } from 'constant'

const withNavigateToTireSearchOrShowYmme = withHandlers({
  navigateToTireSearchOrShowYmme: ({
    isTrailer,
    activeVehicle,
    hasActiveVehicle,
    navigateToSearchByVehicle,
    navigateToSearchByTireSizes,
    showNewShopTiresModal
  }) => ({ searchByTireSize, searchByTireSizeForTrailer } = {}) => {
    if (hasActiveVehicle && !isTrailer) {
      return searchByTireSize ?
        navigateToSearchByTireSizes(activeVehicle) :
        navigateToSearchByVehicle(activeVehicle)
    }

    if (isTrailer && searchByTireSizeForTrailer) {
      return navigateToSearchByTireSizes(activeVehicle)
    }

    showNewShopTiresModal({
      tabName: TAB_YMME_NAME,
      ymmeName: YMME_NAME,
      withShowConfirmTireSizeModal: true,
      source: HEADER_SOURCE
    })
  }
})

const mapStateToProps = state => ({
  hasActiveVehicle: getHasActiveVehicleStatus(state),
  activeVehicle: getActiveVehicle(state),
  isTrailer: getIsActiveVehicleTrailer(state)
})

export default compose(
  connect(mapStateToProps, {
    show,
    hide,
    navigateToSearchByVehicle,
    navigateToSearchByTireSizes,
    showNewShopTiresModal
  }),
  withShowVehicleConfiguratorModalPreconfigured,
  withNavigateToTireSearchOrShowYmme
)
