import React from 'react'
import PropTypes from 'prop-types'
import Link, { A } from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'


const Back = ({ className, children, to, handler = () => {}, noIcon, ...props }) =>
  to ?
    <Link to={ to } bolder className={ className } { ...props }>
      { !noIcon && <Icon data-at-back-icon noLazyLoad asset="arrow-left" margin="-2px 9px 0 0" alt="" /> } { children }
    </Link> :
    <A href="#" role="button" tabindex="0" bolder prevent className={ className } handler={ handler } { ...props }>
      { !noIcon && <Icon data-at-back-icon noLazyLoad asset="arrow-left" margin="-2px 9px 0 0" alt="" /> } { children }
    </A>


Back.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  handler: PropTypes.func,
  noIcon: PropTypes.bool,
  to: PropTypes.string
}


export default Back
