import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import Hint from './Hint'
import { getHasActiveVehicleStatus } from 'selectors'
import { showNewShopTiresModal } from '../../../NewShopTiresModal/actions'
import { GENERAL_SOURCE, YMME_NAME } from 'constant'

const mapStateToProps = state => ({
  hasActiveVehicle: getHasActiveVehicleStatus(state)
})

const withHintProps = withProps(({ showNewShopTiresModal }) => ({
  addVehicle: () => showNewShopTiresModal({
    isOnlyVehicle: true,
    ymmeName: YMME_NAME,
    withShowConfirmTireSizeModal: true,
    source: GENERAL_SOURCE
  })
}))

export default compose(
  connect(mapStateToProps, { showNewShopTiresModal }),
  withHintProps
)(Hint)
