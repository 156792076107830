import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Text from 'components/common/Text/Text'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'
import { APPOINTMENT_SUBMISSION_ERROR_MESSAGE } from '../../constants'

const AppointmentSubmissionErrorModal = ({ clearApplicationDataAndRefresh, setCurrentStepIndex }) =>
  <ModalBox title="Appointment Resubmission Required" closeHandler={ () => clearApplicationDataAndRefresh(setCurrentStepIndex) }>
    <Text block lineheight={ 1.5 }>
      { APPOINTMENT_SUBMISSION_ERROR_MESSAGE }
    </Text>
    <ViewButton full="mobile" onClick={ () => clearApplicationDataAndRefresh(setCurrentStepIndex) }>Go Back</ViewButton>
  </ModalBox>

export default AppointmentSubmissionErrorModal

AppointmentSubmissionErrorModal.propTypes = {
  clearApplicationDataAndRefresh: PropTypes.func,
  setCurrentStepIndex: PropTypes.func
}

const ModalBox = styled(Modal)`
  overflow-y: auto;
  ${Mixin.responsive('padding', '25px 24px 30px', '60px 16px 40px')}
`
const ViewButton = styled(Button)`
  margin-top: 20px;
`
