import { KEY_EVENT_CODES, KEY_EVENT_STRINGS } from './constants'

export const buildKeyCallbackProps = ({ event, props }) => {
  const callbackConstants = event.key ? KEY_EVENT_STRINGS : KEY_EVENT_CODES
  const keyCode = event.key ? event.key : event.keyCode
  Object.keys(callbackConstants)
    .forEach((key) => {
      if (keyCode === callbackConstants[key] && props[key]) {
        props[key](event)
      }
    })
}
