import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Button from 'components/common/Button/Button'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import Popover from 'components/common/Popover'

const PopoverLink = styled(Button)`
  border-radius: 50%;
  padding: 0;
  height: 45px;
  width: 45px;
  transform: none;
  border: none;
  background-color: ${Theme.colors.off_white};
  box-shadow: 0 0 5px 0 ${Theme.colors.light_grey_border};
`

const Toolbox = ({
  isOpen,
  bottomAligned,
  handleToggle,
  handleFeedbackClick,
  handleAudioEyeClick
}) =>
  <Wrapper bottomAligned={ bottomAligned }>
    <CustomPopover
      showOnTop
      asset="ellipsis-red"
      alternative="cross-red"
      smallIcon={ isOpen }
      components={{
        PopoverLink
      }}
      onToggle={ handleToggle }>
      <TrayContent>
        <TrayButton full onClick={ handleAudioEyeClick }>
          <Icon asset="accessibility-red" width="30px" height="30px" alt="" />
          <ButtonText>
            <ButtonTitle>Accessibility</ButtonTitle>
            <ButtonDescription>Explore your accessibility options</ButtonDescription>
          </ButtonText>
        </TrayButton>
        <TrayButton full onClick={ handleFeedbackClick }>
          <Icon asset="feedback-red" width="26px" height="26px" margin="0 0 0 5px" alt="" />
          <ButtonText>
            <ButtonTitle>Feedback</ButtonTitle>
            <ButtonDescription>Give us your feedback</ButtonDescription>
          </ButtonText>
        </TrayButton>
      </TrayContent>
    </CustomPopover>
  </Wrapper>

Toolbox.propTypes = {
  bottomAligned: PropTypes.bool,
  handleAudioEyeClick: PropTypes.func,
  handleFeedbackClick: PropTypes.func,
  handleToggle: PropTypes.func,
  isOpen: PropTypes.bool
}

export default Toolbox

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  right: 10px;
  bottom: ${props => props.bottomAligned ? '5%' : '25%'};
`

const TrayContent = styled.div`
  margin: 0;
  font-size: 12px;
  line-height: 18px;

  && button {
    margin: 0;
  }

  button:first-child {
    border-bottom: 1px solid ${Theme.colors.light_grey};
  }
`

const TrayButton = styled(Button)`
  display: flex;
  background-color: ${Theme.colors.white};
  border: none;
  text-align: left;
  color: ${Theme.colors.dark_grey};
  padding: 15px 10px 5px;
  transform: none;
`

const ButtonText = styled.div`
  margin-left: 15px;
  text-transform: none;
`

const ButtonTitle = styled.h4`
  ${Mixin.font.GothamBook(16, 400)}
  margin: 0;
`

const ButtonDescription = styled.p`
  ${Mixin.font.GothamBook(12, 400)}
  color: ${Theme.colors.caption_grey};
  font-style: italic;
`

const CustomPopover = styled(Popover)`
  border-radius: 50%;
  padding: 0;
  height: 45px;
  width: 45px;
  transform: none;

  & [data-tooltip] {
    height: 28px;
    width: 28px;
    margin: 0;
    ${props => props.smallIcon && css`
      height: 18px;
      width: 18px;
    `}
  }

  & [data-at-popover-link]:hover {
    background-color: ${Theme.colors.off_white};
  }

  & [data-at-popover-inner-content] {
    padding: 5px 0;
    box-shadow: 0 0 5px 0 ${Theme.colors.light_grey_border};
    background-color: ${Theme.colors.white};
    bottom: 40px;
  }
`
