import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ActionButton } from 'components/MyGarage2.0/components/SharedStyles'
import Theme from 'components/common/Theme/Theme'

const VehicleEditorButtons = ({ closeModal, disabled, handleUpdate }) =>
  <Fragment>
    <ActionButton
      data-at-editvehiclemodal-updatevehicle
      full="mobile"
      stacked="mobile"
      narrow
      disabled={ disabled }
      onClick={ handleUpdate }>Update Vehicle</ActionButton>
    <CustomButton
      data-at-editvehiclemodal-cancel
      full="mobile"
      narrow
      onClick={ closeModal }>
      Cancel
    </CustomButton>
  </Fragment>

export default VehicleEditorButtons

VehicleEditorButtons.propTypes = {
  closeModal: PropTypes.func,
  disabled: PropTypes.bool,
  handleUpdate: PropTypes.func
}

const CustomButton = styled(ActionButton)`
  margin: 22px 0 0 0;
  color: ${Theme.colors.bright_red_new};
  background: ${Theme.colors.transparent};
  border: 1px solid ${Theme.colors.transparent};

  &:hover {
    background: ${Theme.colors.transparent};
    color: ${Theme.colors.crimson};
    border-color: ${Theme.colors.crimson};
  }
`
