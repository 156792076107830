import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Link from 'components/common/Link/Link'
import Text from 'components/common/Text/Text'

export const MenuItem = styled.div`
  position: relative;
  padding: 20px 0;
  margin-left: 16px;
  color: ${Theme.colors.white};
  text-transform: uppercase;
  border-bottom: 1px solid ${Theme.colors.light_grey};
  ${props => props.expanded && css`
    border-bottom: none;
  `}
`

export const MenuTitle = styled.div`
  position: relative;

  & ${Link} {
    ${Mixin.font.GothamBold(16)}
    color: ${Theme.colors.white};

    :hover {
      text-decoration: none;
    }
  }

  ${props => props.expanded && css`
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid ${Theme.colors.light_grey};
  `}
`

export const MenuTitleText = styled(Text)`
  display: inline-block;
  ${Mixin.font.GothamBold(16)}
`
