export const getThirdPartyGlobalHeaderMessage = ({ thirdPartyData: { thirdParty: { globalHeaderMessage }}}) =>
  globalHeaderMessage

export const getFullStory = ({ thirdPartyData: { fullStory }}) => fullStory

export const getKenshooEventData = ({ thirdPartyData: { kenshoo: { eventData }}}) => eventData

export const getKenshooRevenue = ({ thirdPartyData: { kenshoo: { revenue }}}) => revenue

export const getPromoDetailsExperience = ({ thirdPartyData: { thirdParty: { promoDetailsExperience }}}) =>
  promoDetailsExperience

export const getBazaar = ({ thirdPartyData: { bazaar }}) => bazaar

export const get3StepperEnabled = ({ thirdPartyData: { thirdParty: { threeStepperEnabled }}}) =>
  threeStepperEnabled

export const getYmmeExperience = ({ thirdPartyData: { thirdParty: { ymmeExperience }} = {}}) =>
  ymmeExperience

export const getStoreLocatorPageExperience = ({ thirdPartyData: { thirdParty: { storeLocatorPageExperience }}}) =>
  storeLocatorPageExperience
