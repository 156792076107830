import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Title, FindButton, ErrorBanner, InnerWrapper } from '../SharedStyles'
import YmmeSelectors from '../YmmeSelectors'
import LicenceSwitcher from '../LicenceSwitcher'

const ShopByVehicle = ({
  children,
  className,
  toggle,
  handleCustomSubmit,
  ymmeError,
  ...props
}) =>
  <InnerWrapper
    className={ className }
    data-at-shop-by-vehicle-wrapper>
    {
      <VariantWrapper>
        <LicenceSwitcher handleSwitch={ toggle } alternateHeading />
        <StyledSpan>-OR-</StyledSpan>
      </VariantWrapper>
    }
    <Title data-at-title>Enter Your Vehicle Information</Title>
    {
      ymmeError &&
        <ErrorBanner error_alternative>{ ymmeError }</ErrorBanner>
    }
    <YmmeSelectors { ...props } />
    {
      children ?
        React.Children.map(children, button =>
          React.cloneElement(button, { onSubmit: handleCustomSubmit })) :
        <FindButton
          data-at-find-my-tires-button
          full
          large
          important
          onClick={ handleCustomSubmit }>Find My Tires</FindButton>
    }
  </InnerWrapper>

ShopByVehicle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  handleCustomSubmit: PropTypes.func,
  toggle: PropTypes.func,
  ymmeError: PropTypes.string
}

export default ShopByVehicle

const VariantWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSpan = styled.span`
  margin-bottom: 15px;
`
