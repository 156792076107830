export default ({
  yearsOptions,
  makesOptions,
  modelsOptions,
  subModelsOptions
}) => ({
  years: {
    label: 'Year',
    placeholder: 'Select Year',
    options: yearsOptions,
    required: true
  },

  makes: {
    label: 'Make',
    placeholder: 'Select Make',
    options: makesOptions,
    required: true
  },

  models: {
    label: 'Model',
    placeholder: 'Select Model',
    options: modelsOptions,
    required: true
  },

  subModels: {
    label: 'Submodel',
    placeholder: 'Select Submodel',
    options: subModelsOptions,
    required: true
  }
})
