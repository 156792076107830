import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Media from 'components/common/Media'
import getComponent from 'helpers/component-helper'
import { FORGOT_USERNAME_TITLE } from './constants'

const ForgotEmailModal = ({
  data: { modalBoxContent = []},
  handleHide
}) =>
  <CustomModal title={ FORGOT_USERNAME_TITLE } closeHandler={ handleHide }>
    {
      modalBoxContent.map((Component, index) => React.cloneElement(getComponent(Component), { key: index, handleHide }))
    }
  </CustomModal>

export default ForgotEmailModal

ForgotEmailModal.propTypes = {
  data: PropTypes.shape({
    modalBoxContent: PropTypes.arrayOf(PropTypes.shape())
  }),
  handleHide: PropTypes.func
}

const CustomModal = styled(Modal)`
  ${Media.desktop`
    top: 265px;
  `}
`
