import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import { TileIcon, TileDescription } from './SharedStyles'

// TODO TileDescription should be changed using vehicleNickname. Potential issue is subModel on separate line
const VehicleTile = ({
  masterVehicle: { yearDesc, year, makeDesc, modelDesc, subModelDesc } = {},
  vehicleColor,
  vehicleNicknameWithoutYear
}) =>
  <Fragment>
    <VehicleImage
      make={ makeDesc }
      model={ modelDesc }
      submodel={ subModelDesc }
      year={ parseInt(yearDesc, 10) }
      width={ 80 }
      height={ 40 }
      color={ vehicleColor ? `#${vehicleColor}` : undefined } />
    <TileDescription data-at-tile-description>
      <Year data-at-year>{ yearDesc || year }</Year>
      <VehicleNickname data-at-vehicle-nickname>
        { vehicleNicknameWithoutYear }
      </VehicleNickname>
      <br />
    </TileDescription>
  </Fragment>

VehicleTile.propTypes = {
  masterVehicle: PropTypes.shape({
    yearDesc: PropTypes.string,
    year: PropTypes.string,
    makeDesc: PropTypes.string,
    modelDesc: PropTypes.string,
    subModelDesc: PropTypes.string
  }),
  vehicleColor: PropTypes.string,
  vehicleNicknameWithoutYear: PropTypes.string
}

export default VehicleTile

const VehicleImage = styled(TileIcon)`
  ${Media.mobileNarrow`
    width: 70px;
    height: 35px;
  `}
`

const Year = styled.div`
  ${Mixin.font.GothamBook(12)}
  ${Media.mobile`
    font-size: 11px;
  `}
`

const VehicleNickname = styled.div`
  font-weight: 600;
`
