import { withProps } from 'recompose'
import ReactSelectMenuList from './ReactSelectMenuList'

const withAriaLabel = withProps(
  ({ selectProps: { label }}) => ({
    ariaLabel: label.slice(label.length - 1) === 's' ? label : `${label}s`
  })
)

export default withAriaLabel(ReactSelectMenuList)
