export default [
  'BOTFindLowerPriceProductCompare',
  'BOTProductAdditionalSpecificationCompare',
  'BOTProductBenefitsCompare',
  'BOTProductInformationCompare',
  'BOTProductQtyPriceCompare',
  'OneColumnProductComparePage',
  'ProductCompareResult',
  'ProductPerformanceRatingCompare',
  'ProductPromotionCompare',
  'ProductReviewsCompare',
  'ProductSpecificationCompare'
]

/*
Appears on:
/tires/compare-tires?
*/
