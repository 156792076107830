import { compose, withProps, withHandlers } from 'recompose'
import withKeyPress from 'components/common/hoc/withKeyPress/withKeyPress'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'
import SelectOption from './SelectOption'

const withModifiedInnerProps = withProps(({ innerProps = {}, label }) =>
  ({ innerProps: { ...dynamicDataAttribute('option', toOneString(label)), ...innerProps }}))

const withKeyHandlers = withHandlers({
  space: () => e => {
    e.preventDefault()
    e.stopPropagation()
    const optionElement = e.target
    optionElement && setTimeout(() => optionElement.click(), 100)
  }
})

export default compose(
  withKeyHandlers,
  withKeyPress,
  withModifiedInnerProps
)(SelectOption)
