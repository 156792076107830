import { withState, withHandlers, compose } from 'recompose'
import position from 'components/common/hoc/position'
import withComponentRef from 'components/common/hoc/withComponentRef'
import withEventListener from 'components/common/hoc/withEventListener'
import Theme from 'components/common/Theme/Theme'
import { tabFocusHandler } from './helpers'
import Tabs from './Tabs'

const withSetFocusRefHandler = withState('focusScopeRef', 'setFocusScopeRef', null)

const withTabPositionHandlers = withHandlers({
  activateTabRemotely: ({ position, positionHandler, onTabChanged }) => e => {
    const targetId = e.currentTarget.getAttribute('id') || ''
    const positionToActivate = targetId && +targetId.split('-').slice(-1)
    positionToActivate !== position && positionHandler(positionToActivate)
    onTabChanged && onTabChanged(positionToActivate)
  },
  activateNextTabRemotely: ({ focusScopeRef, positionHandler, position, menu, onTabChanged }) => () => {
    const nextIndex = (position < menu.length - 1) ? (position + 1) : 0
    positionHandler(nextIndex)
    tabFocusHandler(focusScopeRef)
    onTabChanged && onTabChanged(nextIndex)
  },
  activatePreviousTabRemotely: ({ focusScopeRef, positionHandler, position, menu, onTabChanged }) => () => {
    const previousIndex = (position > 0) ? (position - 1) : (menu.length - 1)
    positionHandler(previousIndex)
    tabFocusHandler(focusScopeRef)
    onTabChanged && onTabChanged(previousIndex)
  }
})

const withEventHandlers = withHandlers({
  onClick: ({ activateTabRemotely }) => e => activateTabRemotely(e),
  onKeyDown: ({ activateNextTabRemotely, activatePreviousTabRemotely }) => e => {
    e.keyCode === Theme.keyCode.LEFT && activatePreviousTabRemotely()
    e.keyCode === Theme.keyCode.RIGHT && activateNextTabRemotely()
  }
})

// eventName will be passed as a prop
const withGlobalEventHandler = withEventListener({
  getHandler: ({ positionHandler, tabToActivate, getComponentDOMNode, onTabChanged } = {}) => () => {
    positionHandler(tabToActivate)
    onTabChanged && onTabChanged(tabToActivate)
    const scrollComponent = getComponentDOMNode()
    tabFocusHandler(scrollComponent, true)
  }
})

export const withTabBehavior = compose(
  withSetFocusRefHandler,
  position,
  withComponentRef,
  withTabPositionHandlers,
  withEventHandlers,
  withGlobalEventHandler
)

export default withTabBehavior(Tabs)
