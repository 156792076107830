import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import { EXPIRED_SESSION_ID } from 'constant'

const SessionTimeOutMessage = ({ sessionTimeoutMessage }) =>
  sessionTimeoutMessage
    ? <SessionMessage id={ EXPIRED_SESSION_ID }>{sessionTimeoutMessage}</SessionMessage>
    : null

SessionTimeOutMessage.propTypes = {
  sessionTimeoutMessage: PropTypes.string
}

export default SessionTimeOutMessage

const SessionMessage = styled.div`
  background: ${Theme.colors.bright_red};
  color: ${Theme.colors.white};
  padding: 5px 20px;
  text-align: center;
`
