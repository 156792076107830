import React from 'react'
import PropTypes from 'prop-types'
import { GoogleMap } from 'react-google-maps'
import { MAP_DEFAULTS } from './constants'

const Map = ({
  onMapMounted,
  defaultZoom = MAP_DEFAULTS.zoom,
  children,
  streetViewControl = false,
  scaleControl = false,
  mapTypeControl = false,
  panControl = false,
  zoomControl = false,
  rotateControl = false,
  fullscreenControl = false,
  disableDefaultUI = true,
  ...props
}) =>
  <GoogleMap
    { ...props }
    ref={ onMapMounted }
    options={{
      streetViewControl,
      scaleControl,
      mapTypeControl,
      panControl,
      zoomControl,
      rotateControl,
      fullscreenControl
    }}
    disableDefaultUI={ disableDefaultUI }
    defaultZoom={ defaultZoom }>

    { children }

  </GoogleMap>

Map.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  defaultZoom: PropTypes.number,
  disableDefaultUI: PropTypes.bool,
  fullscreenControl: PropTypes.bool,
  mapTypeControl: PropTypes.bool,
  onMapMounted: PropTypes.func,
  panControl: PropTypes.bool,
  rotateControl: PropTypes.bool,
  scaleControl: PropTypes.bool,
  streetViewControl: PropTypes.bool,
  zoomControl: PropTypes.bool
}

export default Map
