export const focusNextOrPrevItem = ({ e, name, openSubmenu }) => {
  const navItem = e.currentTarget.closest('div[data-top-nav]').querySelector(`#${name} [data-at-dropdown-focus]`)
  setTimeout(() => {
    if (navItem) {
      navItem.focus()
      navItem.nodeName === 'BUTTON' && openSubmenu && navItem.click()
    }
  })
}

export const focusCurrentItem = ({ e, setFocusHoverState }) => {
  const navItem = e.currentTarget.querySelector('[data-at-dropdown-title]')
  setTimeout(() => {
    navItem && navItem.focus()
    setFocusHoverState && setFocusHoverState(false)
  })
}

export const getFocusedSubItemIndex = e => {
  const focusedSubItem = e.currentTarget.querySelector('[data-at-headerdropdown-item]:focus')
  return focusedSubItem ? Number(focusedSubItem.getAttribute('data-at-headerdropdown-item')) : -1
}

export const notEventTargetException = e =>
  e.target.nodeName !== 'INPUT'
