import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import isEqual from 'lodash/isEqual'
import Theme from 'components/common/Theme/Theme'

const active = (WrappedComponent, defaultValue = false) => {
  class Active extends Component {
    constructor(props) {
      super(props)

      this.defaultValue = defaultValue
      this.state = {
        active: defaultValue,
        activeBlockRef: null
      }

      this.toggle = debounce(this.toggle, 0)
      this.customToggleHandler = props.customToggleHandler
      this.handlers = {
        onClick: (e) => {
          !this.state.active && this.toggle(e)
        },
        onKeyDown: (e) => {
          if (e.keyCode === Theme.keyCode.ENTER || e.keyCode === Theme.keyCode.SPACE) {
            e.preventDefault()
            this.toggle(e.keyCode)
          }
        },
        onKeyUp: (e) => e.keyCode === Theme.keyCode.SPACE && e.preventDefault()
      }
      this.propagation = {
        onClick: this.stopPropagation
      }
    }

    componentDidMount() {
      const { activeBlockRef } = this.state
      const { activeRef } = this.props

      isFunction(activeRef) && activeRef(this)
      if (activeBlockRef && activeBlockRef.addEventListener) {
        activeBlockRef.addEventListener('focusout', this.handleBlur)
        activeBlockRef.addEventListener('keydown', this.handleEscape)
      }
    }

    componentDidUpdate(prevProps, { activeBlockRef: prevActiveBlockRef }) {
      const { activeBlockRef } = this.state
      if (!isEqual(prevActiveBlockRef, activeBlockRef)) {
        if (activeBlockRef && activeBlockRef.addEventListener) {
          activeBlockRef.addEventListener('focusout', this.handleBlur)
          activeBlockRef.addEventListener('keydown', this.handleEscape)
        }
      }
    }

    componentWillUnmount() {
      window.removeEventListener('click', this.deactivate)
      const { activeBlockRef } = this.state
      if (activeBlockRef && activeBlockRef.removeEventListener) {
        activeBlockRef.removeEventListener('focusout', this.handleBlur)
        activeBlockRef.removeEventListener('keydown', this.handleEscape)
      }
    }

    setInnerRef = (el) => { this.state.activeBlockRef = el }

    deactivate = () => {
      this.setState({ active: false })
      window.removeEventListener('click', this.deactivate)
    }

    activate = (e) => {
      this.setState(
        { active: true },
        () => this.customToggleHandler && this.state.active && this.customToggleHandler(e)
      )
      window.addEventListener('click', this.deactivate)
    }

    toggle = (e) =>
      this.setState({ active: !this.state.active }, () => {
        this.customToggleHandler && this.state.active && this.customToggleHandler(e)
        if (this.state.active) {
          window.addEventListener('click', this.deactivate)
        } else {
          window.removeEventListener('click', this.deactivate)
        }
      })

    handleBlur = () => {
      setTimeout(() => {
        if (document.activeElement !== document.body &&
          this.state.activeBlockRef &&
          this.state.activeBlockRef.contains &&
          !this.state.activeBlockRef.contains(document.activeElement)) {
          this.deactivate()
        }
      })
    }

    handleEscape = (e) => e.keyCode === Theme.keyCode.ESCAPE && this.deactivate()

    stopPropagation = e => e.stopPropagation()

    render = () =>
      <WrappedComponent
        { ...this.props }
        active={ this.state.active }
        activate={ this.activate }
        deactivate={ this.deactivate }
        toggle={ this.toggle }
        setInnerRef={ this.setInnerRef }
        toggleHandlers={ this.handlers }
        stopPropagation={ this.propagation } />
  }

  Active.propTypes = {
    activeRef: PropTypes.func,
    customToggleHandler: PropTypes.func
  }

  const ActiveWithRouter = withRouter(Active)

  WrappedComponent.type && (ActiveWithRouter.type = WrappedComponent.type)

  return ActiveWithRouter
}

export default active
