import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from 'components/common/ErrorBoundary'
import { loadComponents } from 'components/dynamicComponents/importers'
import * as globalComponents from 'components/dynamicComponents/globalComponents0'
import { BOT_GENERIC_PLACEHOLDER_TYPE } from 'constant'

const typesToComponents = {}

const getTypesToComponents = components => Object
  .values(components)
  .reduce((acc, Component) => {
    const { type, placeholders = ['contents']} = Component

    return type ?
      Array.isArray(type) ? {
        ...acc,
        // if type is array we need to map every type in this array to the same component
        ...type.reduce((typeAcc, typeNext) => ({
          ...typeAcc, [typeNext]: { Component, placeholders }
        }), {})
      } : { ...acc, [type]: { Component, placeholders }} : acc
  }, {})

let typesPopulated = false
const isTypesPopulated = () => typesPopulated

let restBundlesPromise = null

export const loadRestComponents = data => {
  restBundlesPromise = loadComponents(data)
    .then(chunks => {
      chunks.forEach(restComponents => {
        typesToComponents.all = { ...typesToComponents.all, ...getTypesToComponents(restComponents) }
      })
    })

  return restBundlesPromise
}

const LazyComponent = ({
  getComponent,
  componentData,
  componentKey
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    restBundlesPromise.then(() => {
      !isLoaded && setIsLoaded(true)
    })
  }, [])

  return isLoaded ? getComponent({ key: componentKey, ...componentData }) : null
}

LazyComponent.propTypes = {
  componentData: PropTypes.shape(),
  componentKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getComponent: PropTypes.func
}

const populateTypesToComponents = () => {
  typesToComponents.all = { ...typesToComponents.all, ...getTypesToComponents(globalComponents) }
  typesPopulated = true
}

const getComponentType = (data) =>
  data['@type'] === BOT_GENERIC_PLACEHOLDER_TYPE && data.customType ? data.customType : data['@type']

const getComponent = (props, returnAsync = false) => {
  // eslint-disable-next-line
  const { key = 'key', ...data } = props
  if (global.componentsTree) {
    const x = global.componentsTree
    global.componentsTree = null
    return x
  }

  !isTypesPopulated() && populateTypesToComponents()

  const componentType = getComponentType(data)
  const foundedComponent = typesToComponents.all[componentType] || typesToComponents.all.default

  if (returnAsync) {
    return loadRestComponents(props)
      .then(() => getComponent({ key, ...data }))
  }

  if (foundedComponent) {
    const { Component, placeholders } = foundedComponent
    const components = placeholders.reduce((acc, placeholder) => {
      if (data[placeholder]) {
        acc[placeholder] = data[placeholder].map((data, key) => getComponent({ ...data, key }))
      }

      return acc
    }, {})

    return (
      <ErrorBoundary key={ key } type={ data['@type'] }>
        <Component { ...data } placeholders={ components } />
      </ErrorBoundary>
    )
  }

  loadRestComponents(props)
  return <LazyComponent componentKey={ key } componentData={ data } getComponent={ getComponent } />
}

const findComponent = ({ key = 'key', ...data }, type) => {
  const componentType = getComponentType(data)

  const foundedComponent = typesToComponents.all[componentType] || typesToComponents.all.default

  const { Component, placeholders } = foundedComponent

  if (Component.type === type || (Array.isArray(Component.type) && Component.type.includes(type))) {
    return data
  }

  for (let i = 0; i < placeholders.length; i += 1) {
    const placeholder = placeholders[i]

    if (data[placeholder]) {
      for (let j = 0; j < data[placeholder].length; j += 1) {
        const placeholderData = data[placeholder][j]
        const root = findComponent({ ...placeholderData, key }, type)

        if (root) {
          return root
        }
      }
    }
  }
}

export const findRoot = async ({ key = 'key', ...data }, type) => {
  await loadRestComponents(data)

  return findComponent({ key, ...data }, type)
}

export default getComponent
