import { createSelector } from 'helpers/reselect'

const getRouterReducer = state => state.router

export const getLocationPathname = createSelector(
  [getRouterReducer],
  ({
    location: {
      pathname
    } = {}
  } = {}) => pathname
)
