export default [
  'BOTSiteMap',
  'BOTSiteMapColumn',
  'BOTSiteMapLinksGroup'
]

/*
Appears on:
/sitemap
*/
