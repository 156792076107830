import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import VehicleImage from 'components/common/VehicleImage'
import { dynamicDataAttribute } from 'helpers/utils'

const Vehicle = ({
  className,
  description,
  vehicleNickname,
  masterVehicle: { yearDesc, makeDesc, modelDesc, subModelDesc },
  vehicleColor,
  isTrailer,
  number,
  type
}) =>
  <Wrapper className={ className }>
    <Description { ...dynamicDataAttribute(`verifyvehicle-${type}-description`) }>{ description }</Description>
    {
      isTrailer ?
        <Fragment>
          <VehicleIcon { ...dynamicDataAttribute(`verifyvehicle-${type}-icon`) } asset="icon-trailer" width={ 180 } height={ 100 } alt={ vehicleNickname } />
          <Model>{ vehicleNickname }</Model>
        </Fragment> :
        <Fragment>
          <StyledVehicleImage
            make={ makeDesc }
            model={ modelDesc }
            submodel={ subModelDesc }
            year={ Number(yearDesc) }
            height={ 90 }
            width={ 180 }
            color={ vehicleColor ? `#${vehicleColor}` : undefined }
            { ...dynamicDataAttribute(`verifyvehicle-${type}-image`) } />
          <div>
            <Year { ...dynamicDataAttribute(`verifyvehicle-${type}-year`) }>{ yearDesc }</Year>
            <Model { ...dynamicDataAttribute(`verifyvehicle-${type}-model`) }>{ vehicleNickname }</Model>
            {
              number &&
                <PlateNumber>{ number }</PlateNumber>
            }
          </div>
        </Fragment>
    }
  </Wrapper>

Vehicle.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  isTrailer: PropTypes.bool,
  masterVehicle: PropTypes.shape({
    yearDesc: PropTypes.string,
    makeDesc: PropTypes.string,
    modelDesc: PropTypes.string,
    subModelDesc: PropTypes.string
  }),
  number: PropTypes.string,
  type: PropTypes.string,
  vehicleColor: PropTypes.string,
  vehicleImage: PropTypes.string,
  vehicleNickname: PropTypes.string
}

export default Vehicle

const Wrapper = styled.div`
  padding-bottom: 15px;
`

const Description = styled.div`
  padding-bottom: 10px;
  min-height: 85px;
  line-height: 1.4;
  color: ${Theme.colors.dark_grey};
`

const Year = styled.div`
  font-size: 12px;
  line-height: 17px;
`

const Model = styled.div`
  margin-bottom: 5px;
  ${Mixin.font.GothamMedium(18, 500, 24)}
`

const PlateNumber = styled.div`
  line-height: 19px;
  text-transform: uppercase;
  color: ${Theme.colors.medium_grey};
`

const vehicleStyle = css`
  display: block;
  width: 180px;
  margin: 0 auto;
`

const VehicleIcon = styled(Icon)`
  ${vehicleStyle}
`

const StyledVehicleImage = styled(VehicleImage)`
  ${vehicleStyle}
`
