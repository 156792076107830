import { getShoppingData } from 'actions/shoppingData'
import { rest, isApiError } from 'services'
import { SIX_MONTHS_FINANCING_STORE_EXCLUSIONS_KEY, TWELVE_MONTHS_FINANCING_STORE_EXCLUSIONS_KEY } from './BOTProductAndServicesQuote/constants'

export const getCartDetail = () => dispatch =>
  dispatch(getShoppingData(rest.api.cartDetail))

export const getCitiFinancingPromoExcludedStores = () => () => rest.api.getStaticContentDetails({
  keys: [SIX_MONTHS_FINANCING_STORE_EXCLUSIONS_KEY, TWELVE_MONTHS_FINANCING_STORE_EXCLUSIONS_KEY]
})
  .catch(err => {
    if (isApiError(err)) {
      console.error(err)
    }
  })
