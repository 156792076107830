import {
  LAZY_LOADED_CLASS,
  HAS_OBSERVER
} from './constants'
/* eslint-disable  */

// Lazy Loader Logic
let initialized = false
let lazyNodes = []
let lazyNodeObserver = false
let fallbackLazyLoadActive = false

export const performLazyLoad = domNode => {
  if (!domNode.dataset[LAZY_LOADED_CLASS]) {
    if (domNode.tagName === 'IMG') {
      domNode.src = domNode.dataset.src
      if (domNode.dataset.srcset) {
        domNode.srcset = domNode.dataset.srcset
      }
    } else if (domNode.dataset.backgroundSrc) {
      domNode.style.setProperty('background-image', `url('${domNode.dataset.backgroundSrc}')`)
    }
    domNode.dataset[LAZY_LOADED_CLASS] = 'true'
  }
}

const initObserver = () => {
  lazyNodeObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const lazyNode = entry.target
        performLazyLoad(lazyNode)
        lazyNodeObserver.unobserve(lazyNode)
      }
    })
  })
}

export const fallbackLazyLoad = function () {
  if (fallbackLazyLoadActive === false) {
    fallbackLazyLoadActive = true

    setTimeout(() => {
      lazyNodes.forEach((lazyNode) => {
        const { top: lazyTop, bottom: lazyBottom } = lazyNode.getBoundingClientRect()
        if ((lazyTop <= window.innerHeight && lazyBottom >= 0) && getComputedStyle(lazyNode).display !== 'none') {
          performLazyLoad(lazyNode)
          disconnectFallbackLazyLoad()
        }
      })
      lazyNodes = lazyNodes.filter(node => !node.dataset[LAZY_LOADED_CLASS])
      fallbackLazyLoadActive = false
    }, 200)
  }
}

const initFallbackLazyLoad = () => {
  fallbackLazyLoadActive = false
  document.addEventListener('scroll', fallbackLazyLoad)
  window.addEventListener('resize', fallbackLazyLoad)
  window.addEventListener('orientationchange', fallbackLazyLoad)
}

const disconnectFallbackLazyLoad = () => {
  if (lazyNodes.length === 0) {
    document.removeEventListener('scroll', fallbackLazyLoad)
    window.removeEventListener('resize', fallbackLazyLoad)
    window.removeEventListener('orientationchange', fallbackLazyLoad)
    initialized = false
  }
}

const initialize = () => {
  if (!initialized) {
    initialized = true
    HAS_OBSERVER ? initObserver() : initFallbackLazyLoad()
  }
}

export const clearNodes = () => {
  if (HAS_OBSERVER) {
    lazyNodeObserver.disconnect()
  } else {
    lazyNodes = []
    disconnectFallbackLazyLoad()
  }
}

export const removeNode = (node) => {
  node &&
  (HAS_OBSERVER ? lazyNodeObserver.unobserve(node) : lazyNodes = lazyNodes.filter(lazyNode => lazyNode !== node))
}

export const addNode = (node) => {
  initialize()
  node && (HAS_OBSERVER ? lazyNodeObserver.observe(node) : lazyNodes.push(node))
}

export const processStaticHtmlComponents = ({ images, setImages, contentRef }) => {
  // Logic for Images
  images.forEach(img => img && removeNode(img))
  let newDomNodes = []
  const newImages = contentRef.querySelectorAll && [...contentRef.querySelectorAll('img[data-src]')]

  if (newImages && newImages.length) {
    newImages.forEach(img => img && addNode(img))
    setImages(newImages)
    newDomNodes = [...newImages]
  }

  const backgrounds = contentRef.querySelectorAll && [...contentRef.querySelectorAll('[data-background-src]')]

  if (backgrounds && backgrounds.length) {
    backgrounds.forEach(background => {
      if (background && newDomNodes.indexOf(background) === -1) {
        addNode(background)
        newDomNodes.push(background)
      }
    })
  }

  setImages(newDomNodes)
}
/* eslint-enable  */
