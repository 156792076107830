import qs from 'qs'
import {
  COMPATIBILITY_STATUS_COMPATIBLE,
  TIRE_DETAILS_REVIEWS_QUERY,
  VIEW_ALL_PARAM,
  VIEW_ALL_NEXT_PARAM
} from 'constant'
import {
  SEARCH_BASE_REGEXP,
  SEARCH_BY_SIZE_REGEXP,
  SEARCH_BY_VEHICLE_REGEXP,
  SEARCH_RESULT_URL_PATH,
  TIRE_PATH_REGEX,
  CUSTOM_PRICE_MIN_PARAM,
  CUSTOM_PRICE_MAX_PARAM,
  NF_PARAM,
  CUSTOM_PRICE_TYPE,
  SEE_SPECS,
  RECORDS_PER_PAGE, OE_PARAM,
  SEARCH_BY_TRAILER_REGEXP
} from './constants'
import { arrayHasValues } from 'helpers/utils'
import { parseSearchStr } from 'helpers/location'
import { getElectricVehicleSpecifications } from 'components/common/ProductFlag/helpers'

export const getSelectedValue = sortOptions => {
  const { value } = sortOptions.find(({ selected }) => selected) || {}
  return value || null
}

export const isAllCompatible = (records = []) =>
  records.every(({
    attributes: {
      frontTire: [frontTire],
      rearTire: [rearTire]
    } = {}
  }) =>
    (rearTire && rearTire.compatibilityState === COMPATIBILITY_STATUS_COMPATIBLE) ||
    (frontTire && frontTire.compatibilityState === COMPATIBILITY_STATUS_COMPATIBLE)
  )

const isRearTire = rearTire => rearTire ? Boolean(rearTire.length) : false

export const getReviewsLink = (tireDetailsUrl = '') =>
  `${tireDetailsUrl}?tab=${TIRE_DETAILS_REVIEWS_QUERY}`

export const createCustomPriceRangeFilter = ({ min, max, count }) => ({
  type: CUSTOM_PRICE_TYPE,
  label: `Custom: $${min} - $${max}`,
  count
})

const stringifyTireSize = (tireSize = '') =>
  tireSize.replace(/(\d{3})(\d{2})(\d{2})/, (str, width, ratio, diameter) => `${width}/${ratio}R${diameter}`)

export const getTireSizeFromUrl = (pathname = '', frontTireSize, rearTireSize) => {
  const [, tireSizes] = pathname.match(SEARCH_BY_SIZE_REGEXP) || pathname.match(SEARCH_BY_TRAILER_REGEXP) || []

  if (tireSizes) {
    if (frontTireSize) {
      const front = frontTireSize.split(' ')[0]
      const rear = rearTireSize ? ` - ${rearTireSize.split(' ')[0]}` : ''

      return `${front}${rear}`
    }

    const [frontSize, rearSize] = tireSizes.split('/')

    return rearSize ?
      `${stringifyTireSize(frontSize)} - ${stringifyTireSize(rearSize)}` : `${stringifyTireSize(frontSize)}`
  }

  return null
}

export const formatTireSize = (width = '', ratio = '', diameter = '') =>
  diameter.length > 0 && ratio.length > 0 && width.length > 0 &&
  `${width}${ratio !== 'None' ? `/${ratio}` : ''}R${diameter}`

export const getTireSizeFromProps = ({ front = {}, rear = {}}) => {
  const frontSize = formatTireSize(front.width, front.ratio, front.diameter)
  const rearSize = formatTireSize(rear.width, rear.ratio, rear.diameter)
  return rearSize ? `${frontSize} - ${rearSize}` : frontSize
}

/* START  ---------------SEARCH AND FILTERS SECTION------------------ */
export const getViewAllQueryParam = ({ search = '' } = {}) =>
  parseSearchStr({ search })[VIEW_ALL_PARAM]

export const getSearchValue = ({ navigationState, search, newSearch }) => {
  const viewAllQueryParamValue = getViewAllQueryParam({ search })

  return `${
    navigationState ? `?${newSearch}` :
      viewAllQueryParamValue ? `?${VIEW_ALL_PARAM}=${viewAllQueryParamValue}` : ''
  }`
}

export const getCombinedSearchStr = ({ search, params = {}} = {}) =>
  !search ? `?${qs.stringify(params)}` :
    `?${qs.stringify({ ...parseSearchStr({ search }), ...params })}`

/* eslint-disable */
export const removePriceRangeFromSearch = ({ search = ''} = {}) => {
  const {
    [CUSTOM_PRICE_MIN_PARAM]: min, [CUSTOM_PRICE_MAX_PARAM]: max, // just throw them away from search params
    [NF_PARAM]: nf,
    ...params
  } = parseSearchStr({ search })

  return `?${qs.stringify(params)}`
}

export const removeOeFilterFromSearch = ({ search = ''} = {}) => {
  const {
    [OE_PARAM]: oe,
    ...params
  } = parseSearchStr({ search })

  return `?${qs.stringify(params)}`
}

export const getPriceRangeFromSearch = ({ search = '' } = {}) => {
  const { [CUSTOM_PRICE_MIN_PARAM]: min, [CUSTOM_PRICE_MAX_PARAM]: max } = parseSearchStr({ search })

  return max ? { min, max } : {}
}

export const getSearchDefaultUrl = (pathname = '') => {
  const [defaultSearchUrl] = pathname.match(SEARCH_BY_SIZE_REGEXP)
    || pathname.match(SEARCH_BY_TRAILER_REGEXP)
    || pathname.match(SEARCH_BY_VEHICLE_REGEXP) || []

  return defaultSearchUrl
}

export const getSearchByUrl = (pathname = '') => {
  const [searchByUrl] = pathname.match(SEARCH_BASE_REGEXP) || []

  return searchByUrl
}

export const createFilterPath = (currentPath, filter = '') => {
  const [pathname, search] = (
    filter ? `${getSearchByUrl(currentPath)}${filter}` : getSearchDefaultUrl(currentPath)
  ).split('?')

  return { pathname, search }
}

// see https://tbccorp.atlassian.net/browse/DPW-8186
export const getAllResultsQuery = ({ search = '' } = {}) =>
  search ? `${search}&${VIEW_ALL_PARAM}=200` : `?${VIEW_ALL_PARAM}=200`

export const getAllResultsNextQuery = ({ search = '',  index = 12 } = {}) =>
  search ? `${search}&${VIEW_ALL_NEXT_PARAM}=${index}&${VIEW_ALL_PARAM}=${RECORDS_PER_PAGE}` :
    `?${VIEW_ALL_NEXT_PARAM}=${index}&${VIEW_ALL_PARAM}=${RECORDS_PER_PAGE}`

export const getAllResultsRequestUrl = ({ pathname = '', search = '' } = {}) =>
   pathname.replace(TIRE_PATH_REGEX, `$1${SEARCH_RESULT_URL_PATH}`) + getAllResultsQuery({ search })

export const getAllResultsNextRequestUrl = ({ pathname = '', search = '', index } = {}) =>
   pathname.replace(TIRE_PATH_REGEX, `$1${SEARCH_RESULT_URL_PATH}`) + getAllResultsNextQuery({ search, index })


export const getCurrentPageUrl = ({ pathname = '', search = '' } = {}) => pathname + search

/* END  ---------------SEARCH AND FILTERS SECTION------------------ */


// GTM helpers
export const getGTMData = ({
  records = [],
  position,
  source,
  favoriteSkus = [],
  oeItemIdsList = []
}) => {
  try {
    return records.reduce((previousValue, { attributes: {
      tireLineName = [],
      frontTire = [],
      brandName = [],
      label,
      rearTire = []
    }}, index) => {
      if (!frontTire.length) {
        return previousValue
      }

      if (!isRearTire(rearTire)) {
        return [...previousValue,
          {
            name: tireLineName[0],
            id: frontTire[0].frontTireSkuId,
            price: Number(frontTire[0].salePrice || frontTire[0].listPrice),
            brand: brandName[0],
            category: 'Tires',
            variant: frontTire[0].frontTireSize,
            list: source ? source.list : 'Search Result',
            position: (position || index) + 1,
            dimension1: frontTire[0].availability,
            dimension2: label || 'none',
            dimension28: favoriteSkus.includes(frontTire[0].frontTireSkuId),
            dimension30: oeItemIdsList.includes(frontTire[0].frontTireSkuId),
            dimension31: Boolean(getElectricVehicleSpecifications(frontTire[0].specification).length)
          }
        ]
      }

      return [...previousValue,
        {
          name: tireLineName[0],
          id: frontTire[0].frontTireSkuId,
          price: Number(frontTire[0].salePrice || frontTire[0].listPrice),
          brand: brandName[0],
          category: 'Tires',
          variant: frontTire[0].frontTireSize,
          list: source ? source.list : 'Search Result',
          position: (position || index) + 1,
          dimension1: frontTire[0].availability,
          dimension2: label || 'none',
          dimension28: favoriteSkus.includes(frontTire[0].frontTireSkuId),
          dimension30: oeItemIdsList.includes(frontTire[0].frontTireSkuId),
          dimension31: Boolean(getElectricVehicleSpecifications(frontTire[0].specification).length)
        },
        {
          name: tireLineName[0],
          id: rearTire[0].rearTireSkuId,
          price: Number(rearTire[0].salePrice || rearTire[0].listPrice),
          brand: brandName[0],
          category: 'Tires',
          variant: rearTire[0].rearTireSize,
          list: source ? source.list : 'Search Result',
          position: (position || index) + 1,
          dimension1: rearTire[0].availability,
          dimension2: label || 'none',
          dimension28: favoriteSkus.includes(frontTire[0].rearTireSkuId),
          dimension30: oeItemIdsList.includes(frontTire[0].rearTireSkuId),
          dimension31: Boolean(getElectricVehicleSpecifications(frontTire[0].specification).length)
        }
      ]
    }, [])
  } catch (err) {
    console.log(err)
  }
}

export const getExpanderPromoText = (promotionsLength) =>
  `See ${promotionsLength} Promo${promotionsLength > 1 ? 's' : ''}`
export const getPromoAndSpecsMenu = (promotions) =>
  arrayHasValues(promotions) ?
    [{ title: getExpanderPromoText(promotions.length) },
      { title: SEE_SPECS }] :
    [{ title: SEE_SPECS }]

export const productFlagIdCheck = (oeItemIdsList, skuId) => oeItemIdsList.includes(skuId)
