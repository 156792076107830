import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Media from 'components/common/Media'

const Columns = ({ children, className, width = '', adaptive, padding, vertAlign = '', ...props }) => {
  const sizes = width.split(' ')

  return (
    <Table className={ className } adaptive={ adaptive } { ...props }>
      {
        React.Children.toArray(children)
          .map((item, index) =>
            <Column data-column key={ index } width={ sizes[index] || 'auto' } adaptive={ adaptive } padding={ padding } vertAlign={ vertAlign }>{ item }</Column>
          )
      }
    </Table>
  )
}

Columns.propTypes = {
  adaptive: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  columns: PropTypes.number,
  padding: PropTypes.string,
  vertAlign: PropTypes.string,
  width: PropTypes.string
}

export default Columns


const Table = styled.div`
  display: table;
  width: 100%;
  ${props => props.fixed && css`
    table-layout: fixed;
  `}
  ${props => props.adaptive && css`
    ${Media.mobile`
      display: block;
      width: auto;
    `}
  `}
`

const Column = styled.div`
  display: table-cell;
  position: relative;
  vertical-align: ${props => props.vertAlign || 'top'};
  padding: ${props => props.padding || 0};
  width: ${props => props.width};
  ${props => props.adaptive && css`
    ${Media.mobile`
      display: block;
      width: auto;
    `}
  `}
`
