import React from 'react'
import { hydrate } from 'react-dom'
import { loadRestComponents } from 'helpers/component-helper'
import get from 'lodash/get'
import ConfiguredApp from './client.common'


const data = get(window, '__INITIAL_STATE__.app.data', {})

loadRestComponents(data)
  .then(() =>
    hydrate(
      <ConfiguredApp />,
      document.getElementById('root')
    )
  )

