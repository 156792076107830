import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Modal from 'components/common/Modal'
import OriginalTireSize from '../OriginalTireSize'
import CustomTireSize from '../CustomTireSize'
import {
  SelectTireSizeDisclaimer,
  NewLicensePlateWrapper,
  NewInfoWrapper,
  NewPlaceholder,
  NewCol,
  NewLicenseSubTitle,
  NewStaticCol,
  NewYMMEWrapper,
  NewYMMESubTitle,
  EditIcon
} from '../ConfirmTireSize.styles'

const TireConfirmationModal = ({
  customSizeButtonText,
  data: {
    tireConfirmationTitle,
    ...data
  },
  handleTireSizeConfirmation,
  handleTiresSelect,
  handleClose,
  tireSizes,
  vehicle,
  vehicle: {
    licencePlateNumber,
    vin,
    state
  },
  vehicleMake,
  vehicleName,
  skipTireSizeExperience,
  backToNewYmmeModalHandler,
  backToNewLicensePlateModalHandler,
  backToNewStateModalHandler,
  isSkipEditVehicle
}) =>
  <CustomModal
    centered
    title={ tireConfirmationTitle }
    closeHandler={ handleClose }
    adjustBottomMargin={ !skipTireSizeExperience }>
    {
      !skipTireSizeExperience &&
        <SelectTireSizeDisclaimer>
          Shopping for tires? Select tire size to proceed.
        </SelectTireSizeDisclaimer>
    }
    {(!licencePlateNumber || !vin) && !isSkipEditVehicle && (
      <NewYMMEWrapper
        alt={ vehicleName }
        tabIndex="0"
        onClick={ backToNewYmmeModalHandler }>
        <NewYMMESubTitle
          headline
          centered
          data-at-sub-title >
          { vehicleName }
        </NewYMMESubTitle>
        <EditIcon asset="edit-blue" />
      </NewYMMEWrapper>
    )}
    {(licencePlateNumber || vin) && !isSkipEditVehicle && (
      <NewLicensePlateWrapper>
        <NewInfoWrapper>
          <NewPlaceholder>
            License Plate
          </NewPlaceholder>
          <NewCol
            alt="License Plate Edit"
            tabIndex="0"
            onClick={ backToNewLicensePlateModalHandler }>
            <NewYMMESubTitle
              headline
              centered
              data-at-sub-title >
              { licencePlateNumber }
            </NewYMMESubTitle>
            <EditIcon asset="edit-blue" />
          </NewCol>
        </NewInfoWrapper>
        <NewInfoWrapper>
          <NewPlaceholder>
            State
          </NewPlaceholder>
          <NewCol
            alt="State Edit"
            tabIndex="0"
            onClick={ backToNewStateModalHandler }>
            <NewYMMESubTitle
              headline
              centered
              data-at-sub-title >
              { state }
            </NewYMMESubTitle>
            <EditIcon asset="edit-blue" />
          </NewCol>
        </NewInfoWrapper>
        <NewInfoWrapper>
          <NewPlaceholder>
            Your Vehicle
          </NewPlaceholder>
          <NewStaticCol>
            <NewLicenseSubTitle
              headline
              centered
              data-at-sub-title >
              { vehicleName }
            </NewLicenseSubTitle>
          </NewStaticCol>
        </NewInfoWrapper>
      </NewLicensePlateWrapper>
    )}
    <OriginalTireSize
      { ...data }
      handleTireSizeConfirmation={ handleTireSizeConfirmation }
      vehicle={ vehicle }
      vehicleMake={ vehicleMake }
      tireSizes={ tireSizes } />
    <SizeNotListed
      { ...data }
      isModal
      customSizeButtonText={ customSizeButtonText }
      handleTiresSelect={ handleTiresSelect }
      vehicle={ vehicle } />
  </CustomModal>

export default TireConfirmationModal

TireConfirmationModal.propTypes = {
  backToNewLicensePlateModalHandler: PropTypes.func,
  backToNewStateModalHandler: PropTypes.func,
  backToNewYmmeModalHandler: PropTypes.func,
  customSizeButtonText: PropTypes.string,
  data: PropTypes.shape({
    tireConfirmationTitle: PropTypes.string
  }),
  handleClose: PropTypes.func,
  handleTireSizeConfirmation: PropTypes.func,
  handleTiresSelect: PropTypes.func,
  isSkipEditVehicle: PropTypes.bool,
  onModalClose: PropTypes.func,
  skipTireSizeExperience: PropTypes.string,
  tireSizes: PropTypes.shape(),
  vehicle: PropTypes.shape(),
  vehicleMake: PropTypes.string,
  vehicleName: PropTypes.string
}

const CustomModal = styled(Modal)`
  padding-bottom: 0;
  ${props => props.adjustBottomMargin && css`
    h1 {
      padding-bottom: 0;
      margin-bottom: 10px;
    }
  `}
`

const SizeNotListed = styled(CustomTireSize)`
  padding: 14px 0 20px;
`
