import React from 'react'
import PropTypes from 'prop-types'

const ContentSlotFooter = ({ placeholders: { contents }}) =>
  <div>
    { contents }
  </div>

ContentSlotFooter.propTypes = {
  placeholders: PropTypes.shape({
    contents: PropTypes.array
  })
}

ContentSlotFooter.type = 'ContentSlotFooter'

export default ContentSlotFooter
