import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import styled, { css } from 'styled-components'

const Text = styled.span`
  ${props => props.uppercase && css`
    text-transform: uppercase;
  `}
  ${props => props.narrow && css`
    transform: scale(1, 1.3);
  `}
  ${props => props.bold && css`
    ${Mixin.font.GothamMedium()}
  `}
  ${props => props.bolder && css`
    ${Mixin.font.GothamBold()}
  `}
  ${props => props.italic && css`
    ${Mixin.font.GothamBookItalic()}
  `}
  ${props => props.underline && css`
    text-decoration: underline;
  `}
  ${props => props.centered && css`
    text-align: center;
  `}
  ${props => props.noMargin && css`
    margin: 0;
  `}

  /* Main Headline styles
  Please add it to props below if you have it on List of Headlines */
  ${props => (props.headline || props.headline_inversed || props.headline_footer || props.follow_link) && css`
    vertical-align: middle;
    text-transform: uppercase;
    ${Mixin.font.GothamBold(16)}
  `}

  /* List of Headlines style */
  ${props => props.headline && css`
    display: block;
    color: ${Theme.colors.black_new};
    font-size: 26px;
  `}
  ${props => props.headline_inversed && css`
    color: ${Theme.colors.white};
  `}
  ${props => props.headline_narrow && css`
    transform: scale(1, 1.5);
  `}
  ${props => props.headline_footer && css`
    display: block;
    color: ${Theme.colors.white};
    padding-bottom: 9px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${Theme.colors.dark_grey};
  `}
  ${props => props.follow_link && css`
    color: ${Theme.colors.black_new};
    ${Media.mobile`
      font-size: 16px;
    `}
  `}
  ${props => props.small && css`
    display: block;
    font-size: 14px;
    ${Mixin.font.GothamMedium()}
    letter-spacing: -0.1px;
    color: ${Theme.colors.red};
    ${Media.mobile`
      font-size: 14px;
    `}
  `}

  /* Display property */
  ${props => props.block && css`
    display: block;
  `}
  ${props => props.inline && css`
    display: inline-block;
  `}

  /* Property for Set size, weight */
  ${props => props.size && css`
    font-size: ${props => props.size}px;
  `}
  ${props => props.weight && css`
    font-weight: ${props => props.weight};
  `}
  ${props => props.lineheight && css`
    line-height: ${props => props.lineheight};
  `}
`

export default Text

export const H1 = Text.withComponent('h1')

export const H2 = Text.withComponent('h2')

export const H3 = Text.withComponent('h3')

export const H4 = Text.withComponent('h4')

export const H5 = Text.withComponent('h5')

export const H6 = Text.withComponent('h6')

export const Caption = Text.withComponent('caption')
