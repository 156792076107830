import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Mixin from 'components/common/Mixin'
import { LazyDiv } from 'components/common/LazyHtml'

const HTMLContainer = ({ content, ...props }) =>
  <Div content={ content || '' } { ...props } />

HTMLContainer.propTypes = {
  content: PropTypes.string
}

const Div = styled(LazyDiv)`
  ${props => props.inline && css`
    display: inline;
  `}
  ${props => props.bold && css`
    ${Mixin.font.GothamBold()}
  `}
`

export default HTMLContainer
