import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Link from 'components/common/Link/Link'
import Circle from 'components/common/Circle/Circle'
import { filterProps } from 'helpers/utils'

const MyAppointmentsMenuLink = ({
  appointmentsCount,
  displayName,
  link: {
    path = ''
  } = {},
  ...props
}) =>
  <MyAppointmentsWrapper>
    <MyAppointmentsLink { ...props } to={ path }>{ displayName }</MyAppointmentsLink>
    {
      appointmentsCount > 0 &&
      <MyAppointmentsCircle>{ appointmentsCount }</MyAppointmentsCircle>
    }
  </MyAppointmentsWrapper>

export default MyAppointmentsMenuLink


MyAppointmentsMenuLink.propTypes = {
  appointmentsCount: PropTypes.number,
  displayName: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.any
  }),
  name: PropTypes.string
}

const MyAppointmentsWrapper = styled.div`
  position: relative;
  ${Media.mobile`
    ${Link} {
      :first-child {
        margin-top: 0;
      }
    }
  `}
`

const propsToRemove = [
  'altTag',
  'setAppointmentsCount',
  '@type'
]

const MyAppointmentsLink = styled(props => <Link { ...filterProps(props, propsToRemove) } />)([])

const MyAppointmentsCircle = styled(Circle)`
  position: absolute;
  right: 15px;
  top: 50%;
  min-width: 22px;
  transform: translateY(-50%);
  z-index: 2;
`
