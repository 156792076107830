import React from 'react'
import PropTypes from 'prop-types'

const PageSlot = ({ placeholders: { contents = []}}) =>
  <div>{ contents }</div>

PageSlot.propTypes = {
  placeholders: PropTypes.shape({
    contents: PropTypes.array
  })
}

PageSlot.type = 'PageSlot'

export default PageSlot
