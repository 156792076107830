import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from 'src/components/common/Text/Text'
import Modal from 'src/components/common/Modal'
import Media from 'src/components/common/Media'
import Mixin from 'src/components/common/Mixin'
import Button from 'src/components/common/Button/Button'


// TODO We can hardcode title and confirmationExplanation here or we need to pass them when show this modal
const ActionConfirmationModal = ({
  title = 'ADD SIMILAR VEHICLE',
  description,
  handleCancel,
  handleHide,
  handleUpdate
}) =>
  <CustomModal title={ title } closeHandler={ handleHide }>
    {
      description &&
        <Text>{ description }</Text>
    }
    <ButtonsWrapper>
      <Button data-at-add-similiar-button full="mobile" stacked="mobile" onClick={ handleUpdate }>
        Add
      </Button>
      <Button data-at-back-button link full="mobile" stacked="mobile" onClick={ handleCancel }>
        Go Back
      </Button>
    </ButtonsWrapper>
  </CustomModal>

export default ActionConfirmationModal

ActionConfirmationModal.propTypes = {
  description: PropTypes.string,
  handleCancel: PropTypes.func,
  handleHide: PropTypes.func,
  handleUpdate: PropTypes.func,
  title: PropTypes.string
}

const CustomModal = styled(Modal)`
  ${Media.desktop`
    padding: 25px 24px 67px;
  `}
`

const ButtonsWrapper = styled.div`
  ${Mixin.responsive('margin-top', '40px', '20px')}
`
