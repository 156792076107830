import axios from 'axios'
import { TRUMPET_INLINE_RATING_URL, TRUMPET_PAGED_URL } from 'constant'

const { BOT_TRUMPET_RATINGS_API_KEY } = (window.GLOBAL_VARS || process.env)

// Default paginated reviews request parameters
const FILTER_BY_RATING = 1
const PAGE_SIZE = 25
const ORDER_BY = 'recent'
const ENABLE_MIN_RATING = 'true'

export const getTrumpetInlineRating = ({ storeNum }) =>
  axios.get(`${TRUMPET_INLINE_RATING_URL}/${BOT_TRUMPET_RATINGS_API_KEY}/${storeNum}`)
    .catch(err => console.error(err))

export const getTrumpetPaginatedStoreReviews = ({ storeNum, pageIndex = 1 }) =>
  axios.get(`${TRUMPET_PAGED_URL}/${BOT_TRUMPET_RATINGS_API_KEY}/${storeNum}/${FILTER_BY_RATING}/${pageIndex}/${PAGE_SIZE}/${ORDER_BY}/${ENABLE_MIN_RATING}`)
    .catch(err => console.error(err))
