// TODO: Deprecated please remove when no usage,
// TODO: use Field from redux-form together with Input from components/common instead
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Field as commonFormInput } from 'components/common/Fields'

const InputField = ({ name, label, component, ...props }) =>
  <Field { ...props } name={ name } label={ label } component={ component || commonFormInput } />

InputField.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired
}

export default InputField
