import { handleActions } from 'redux-actions'
import { initialState } from './model'

import {
  fetchDataAction,
  fetchMyLocationAction,
  makeThisMyLocationAction,
  setErrorAction,
  resetErrorAction
} from './actions'

const handleDataFetch = (state) => ({
  ...state,
  isLoading: true
})

const handleFetchMyLocations = (state, data) => ({
  ...state,
  myLocations: data,
  isLoading: false
})

const handleMakeThisMyLocation = (state, data) => ({
  ...state,
  myLocations: data,
  isLoading: false
})

const handleSetError = (state, { payload: error }) => ({
  ...state,
  isLoading: false,
  error
})

const handleResetError = (state) => ({
  ...state,
  isLoading: false,
  error: ''
})

export default handleActions({
  [fetchDataAction]: handleDataFetch,
  [fetchMyLocationAction]: handleFetchMyLocations,
  [makeThisMyLocationAction]: handleMakeThisMyLocation,
  [setErrorAction]: handleSetError,
  [resetErrorAction]: handleResetError
}, initialState)
