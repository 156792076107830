import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'

const ReactSelectControl = ({
  children,
  selectProps: {
    handleMobileClick
  },
  ...props
}) =>
  <div onTouchStart={ handleMobileClick }>
    <components.Control { ...props }>
      { children }
    </components.Control>
  </div>

ReactSelectControl.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.shape()
}

export default ReactSelectControl
