
import Theme from 'components/common/Theme/Theme'

export const DATE_FORMAT = 'MM/DD/YYYY'

export const OPEN_KEYS = [Theme.keyCode.SPACE, Theme.keyCode.ENTER]
export const FOCUS_TO_DATE_KEYS = [Theme.keyCode.UP, Theme.keyCode.DOWN]
export const CLOSE_KEY = Theme.keyCode.ESCAPE
export const FOCUS_TO_BUTTON_KEY = Theme.keyCode.TAB
export const FOCUS_TO_NEXT_FIELD = Theme.keyCode.TAB
