import { createSelector } from 'helpers/reselect'

const getVehicleCount = ({
  app: {
    data: {
      userdata: {
        mygarage: {
          vehicleCount
        } = {}
      } = {}
    } = {}
  } = {}
} = {}) => vehicleCount

export default createSelector(
  [getVehicleCount],
  vehicleCount => vehicleCount
)
