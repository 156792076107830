import { mapProps } from 'recompose'
import { TRAILER_TYPE } from 'constant'
import { getVehicleShortName } from 'helpers/vehicle'
import Vehicle from './Vehicle'

const mapVehicleNickname = mapProps(({
  vehicleNickname,
  masterVehicle: { vehicleType } = {},
  masterVehicle = {},
  ...props
}) => ({
  ...props,
  masterVehicle,
  isTrailer: vehicleType === TRAILER_TYPE,
  vehicleNickname: vehicleNickname || getVehicleShortName(masterVehicle)
}))

export default mapVehicleNickname(Vehicle)
