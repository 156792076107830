import { compose, withProps } from 'recompose'
import { getLink, getMeta, getTitle, getCanonicalUrl, getImage, getDescription, isPageForSitewideSchema } from './helpers'
import Head from './Head'

const withHeadData = withProps(({ data }) => {
  let currentUrl = ''

  if (window && window.location) {
    currentUrl = window.location.href
  }

  return {
    canonicalUrl: data.canonical ? getCanonicalUrl(data) : currentUrl,
    image: data ? getImage(data) : '',
    description: data ? getDescription(data) : '',
    title: data ? getTitle(data) : '',
    meta: data ? getMeta(data) : '',
    link: data ? getLink(data) : ''
  }
})

const withIsPageForSchema = withProps(({ canonicalUrl }) => ({
  isPageForSitewideSchema: isPageForSitewideSchema(canonicalUrl)
}))

export default compose(
  withHeadData,
  withIsPageForSchema
)(Head)
