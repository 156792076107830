import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import { handleAddNewVehicle as initiateAddNewVehicle } from 'actions/addVehicle'
import withAddNewVehicleHandler from '../withAddNewVehicleHandler'
import ExtendedVehicleSelectorBlock from './ExtendedVehicleSelectorBlock'
import { showNewShopTiresModal } from '../../../NewShopTiresModal/actions'
import { GENERAL_SOURCE, YMME_NAME } from 'constant'

const withVehicleSelectorBlockProps = withProps(({
  showNewShopTiresModal
}) => ({
  addVehicle: () => showNewShopTiresModal({
    isOnlyVehicle: true,
    ymmeName: YMME_NAME,
    withShowConfirmTireSizeModal: true,
    source: GENERAL_SOURCE
  })
}))

export default compose(
  withType({ type: 'ExtendedVehicleSelectorBlock', placeholders: ['actionLinks']}),
  connect(null, { initiateAddNewVehicle, showNewShopTiresModal }),
  withAddNewVehicleHandler,
  withVehicleSelectorBlockProps
)(ExtendedVehicleSelectorBlock)
