import { REGIONS_QUERY_PARAM } from 'constant'
import { LOCAL, NATIONWIDE } from './constants'

const formatRegionName = regionName => regionName.replace(/ /g, '-').toLowerCase()

export const processPromotionLink = ({ promotionLinkUrl, regionName = '' }) =>
  regionName ? `${promotionLinkUrl}?${REGIONS_QUERY_PARAM}=${formatRegionName(regionName)}` : promotionLinkUrl

export const filterPromotions = (promotionList, title, regionName) =>
  Array.isArray(promotionList) &&
  promotionList.length > 0 &&
  promotionList.filter(({ isNationwidePromotions, isStorePromotions, isRegionPromotion }) => {
    switch (title) {
      case LOCAL:
        return regionName ? isRegionPromotion : isStorePromotions
      case NATIONWIDE:
        return isNationwidePromotions
      default:
        return true
    }
  })

const getPromoIDAsNumber = promotionId => Number(promotionId.replace(/\D/g, ''))

export const sortPromotions = promotions => promotions.sort(({
  displaySequence: aDisplaySequence = 10000,
  promotionId: aPromoId = '1'
}, {
  displaySequence: bDisplaySequence = 10000,
  promotionId: bPromoId = '1'
}) => {
  if (aDisplaySequence < bDisplaySequence) {
    return -1
  } else if (aDisplaySequence > bDisplaySequence) {
    return 1
  }
  return getPromoIDAsNumber(bPromoId) - getPromoIDAsNumber(aPromoId)
})

/* eslint-disable no-param-reassign */
const resizePromotionTile = (loadedDivs, maxHeight, maxWidth) => {
  loadedDivs.forEach(promotionNode => {
    promotionNode.style.height = `${maxHeight}px`
    promotionNode.style.width = `${maxWidth}px`
  })
}

export const alignPromotionTileSize = () => {
  let maxHeight = 0
  let maxWidth = 0
  const loadedDivs = []
  const promotionNodes = [].slice.call(document.querySelectorAll('[data-same-dimension]'))

  promotionNodes.forEach(promotionNode => {
    const img = promotionNode.querySelector('img')

    const calculatePromoTileSize = () => {
      loadedDivs.push(promotionNode)
      maxHeight = Math.max(promotionNode.clientHeight, maxHeight)
      maxWidth = Math.max(promotionNode.clientWidth, maxWidth)
      resizePromotionTile(loadedDivs, maxHeight, maxWidth)
    }

    const imgLoaded = () => {
      calculatePromoTileSize()
      img.removeEventListener('load', imgLoaded)
    }

    img.addEventListener('load', imgLoaded)
  })
}

