export const showNotification = (className) => {
  const message = document.querySelector(`.${className}`)
  message.classList.add('show')
  setTimeout(() => {
    message.classList.remove('show')
    localStorage.removeItem('notification')
  }, 4000)
}

export const getNotificationFromLocalStorage = () => localStorage.getItem('notification')

export const triggerNotification = () => localStorage.setItem('notification', 'true')
