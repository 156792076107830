import { compose } from 'redux'
import { withProps, withHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { CONFIRM_YOUR_VEHICLE_MODAL } from './constants'
import { getInitialValues, createFormViewData, getVehicle } from './helpers'
import ConfirmYourVehicleModal from './ConfirmYourVehicleModal'

const withConfirmYourVehicleFormView = withProps(({ vehicles, licensePlate, state }) => ({
  model: createFormViewData({ vehicles, licensePlate, state }),
  initialValues: getInitialValues(vehicles)
}))

const withOnSubmit = withHandlers({
  onSubmit: ({ submit }) => values => {
    submit(getVehicle(values))
  }
})

export default compose(
  registerReduxModal({ name: CONFIRM_YOUR_VEHICLE_MODAL }),
  withConfirmYourVehicleFormView,
  withOnSubmit
)(ConfirmYourVehicleModal)

export { CONFIRM_YOUR_VEHICLE_MODAL }
