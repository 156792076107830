import { compose } from 'redux'
import { mapProps } from 'recompose'
import reduxForm from 'components/common/hoc/withFormErrorFocus'
import getViewModel from './viewModel'
import { FORM_NAME } from './constants'
import validate from './validate'
import ShopByLicencePlateForm from './ShopByPlateForm'

export const withFormModel = mapProps(({ statesOptions, ...props }) => ({
  ...props,
  model: getViewModel(statesOptions)
}))

export default compose(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  }),
  withFormModel
)(ShopByLicencePlateForm)

export { FORM_NAME, validate }
