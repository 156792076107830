import { compose, withHandlers, withState, lifecycle } from 'recompose'
import withType from 'components/common/hoc/withType'
import { closeGlobalBannerMessage } from 'components/common/hoc/with3rdPartyData/actions'
import HeaderGlobalMessage from './HeaderGlobalMessage'
import { connect } from 'react-redux'
import { getThirdPartyGlobalHeaderMessage } from 'selectors/thirdPartyData'
import debounce from 'lodash/debounce'
import { BOT_GLOBAL_MESSAGE_ID } from 'constant'
import { dispatchPageHeaderChanged } from 'helpers/custom-events'

const withSetHeight = withState('placeHolderHeight', 'setHeight', 0)

const withHandleChange = withHandlers({
  handleChange: ({ setHeight, placeHolderHeight }) =>
    debounce(() => {
      const placeholderTarget = document.getElementById(BOT_GLOBAL_MESSAGE_ID)
      const placeHolderNewHeight = placeholderTarget ? placeholderTarget.offsetHeight : placeHolderHeight
      if (!!placeHolderNewHeight && placeHolderNewHeight !== placeHolderHeight) {
        setHeight(placeHolderNewHeight)
      }
    }, 100)
})

const withGlobalMessageListeners = lifecycle({
  componentDidMount() {
    const { handleChange } = this.props
    handleChange()
    window.addEventListener('scroll', handleChange)
    window.addEventListener('resize', handleChange)
  },
  componentDidUpdate() {
    dispatchPageHeaderChanged()
  },
  componentWillUnmount() {
    const { handleChange } = this.props
    window.removeEventListener('scroll', handleChange)
    window.removeEventListener('resize', handleChange)
  }
})

const withGlobalMessageVisibility = withHandlers({
  handleClose: ({ closeGlobalBannerMessage }) => () => {
    dispatchPageHeaderChanged()
    closeGlobalBannerMessage()
  }
})


const mapStateToProps = state => ({
  globalHeaderMessage: getThirdPartyGlobalHeaderMessage(state),
  visibility: state.thirdPartyData.thirdParty.isVisible
})

export default compose(
  withType({ type: 'HeaderGlobalMessage' }),
  withSetHeight,
  withHandleChange,
  withGlobalMessageListeners,
  connect(mapStateToProps, { closeGlobalBannerMessage }),
  withGlobalMessageVisibility
)(HeaderGlobalMessage)
