import { handleActions, combineActions } from 'redux-actions'
import { FIELDS, ERROR } from './constants'
import { initialState, fieldState, fieldsInitialState, errorInitialState } from './model'
import { isInitialized, makeReducer } from './helpers'
import {
  fetchValues,
  setValues,
  selectValue,
  setFilter,
  setErrors,
  resetItem,
  resetItems,
  toggleItem,
  expand,
  collapse,
  setError,
  resetError,
  setVehicleId,
  setBaseVehicleId,
  setSubModelTypes,
  registerYmme,
  unregisterYmme,
  setYmmeInitialized
} from './actions'

const handleSetValues = (state, { payload: { name, value } = {}}) => ({
  ...state,
  [name]: {
    ...state[name],
    values: value,
    loading: false
  }
})

const handleFilter = (state, { payload: { name, value }}) => ({
  ...state,
  [name]: {
    ...state[name],
    filterValue: value
  }
})

const handleSelectValue = (state, { payload: { name, value }}) => ({
  ...state,
  [name]: {
    ...state[name],
    value,
    active: false,
    error: ''
  }
})

const handleResetItem = (state, { payload: { name }}) => ({
  ...state,
  [name]: {
    ...fieldState
  }
})

/* eslint-disable no-unused-vars */
const handleToggleItem = (state, { payload: { name }}) => {
  const { [name]: exclude, ...fields } = state

  return {
    ...state,
    [name]: {
      ...state[name],
      active: !state[name].active
    },
    ...Object.keys(fields).reduce((acc, next) => ({
      ...acc,
      [next]: {
        ...state[next],
        active: false
      }
    }), {})
  }
}

const handleSetErrors = (state, { payload: { errors }}) =>
  Object.keys(errors).reduce((acc, name) => ({
    ...acc,
    [name]: {
      ...state[name],
      active: false,
      error: isInitialized(state[name]) ? errors[name] : ''
    }
  }), { ...state })

const handleExpand = (state, { payload: { name }}) => {
  // expand one field
  if (name) {
    return {
      ...state,
      [name]: {
        ...state[name],
        active: true
      }
    }
  }
}

const handleCollapse = (state, { payload: { name }}) => {
  // collapse one field
  if (name) {
    return {
      ...state,
      [name]: {
        ...state[name],
        active: false
      }
    }
  }

  // collapse all fields
  return Object.keys(state).reduce((acc, next) => ({
    ...acc,
    [next]: {
      ...state[next],
      active: false
    }
  }), state)
}

// start fetching field's values
const handleFetchValues = (state, { payload: { name }}) => ({
  ...state,
  [name]: {
    ...state[name],
    loading: true
  }
})

// set fields values to default
const handleResetItems = (state, { payload: { names }}) => ({
  ...state,
  ...names.reduce((acc, name) => ({
    ...acc,
    [name]: {
      ...fieldState
    }
  }), state)
})

const handleSetError = (state, { payload: { error }}) => error
const handleResetError = () => initialState.error

const handleSetSubModelTypes = (state, { payload: { ymmeName, value }}) => ({
  ...state,
  [ymmeName]: {
    ...state[ymmeName],
    subModelTypes: value
  }
})


const handleSetVehicleId = (state, { payload: { ymmeName, value }}) => ({
  ...state,
  [ymmeName]: {
    ...state[ymmeName],
    vehicleId: value
  }
})

const handleSetBaseVehicleId = (state, { payload: { ymmeName, value }}) => ({
  ...state,
  [ymmeName]: {
    ...state[ymmeName],
    baseVehicleId: value
  }
})

const handleSetYmmeInitialized = (state, { payload: { ymmeName, value }}) => ({
  ...state,
  [ymmeName]: {
    ...state[ymmeName],
    ymmeInitialized: value
  }
})

const fieldsActionsHandler = handleActions({
  [fetchValues]: handleFetchValues,
  [setFilter]: handleFilter,
  [setValues]: handleSetValues,
  [resetItem]: handleResetItem,
  [resetItems]: handleResetItems,
  [setErrors]: handleSetErrors,
  [expand]: handleExpand,
  [collapse]: handleCollapse,
  [toggleItem]: handleToggleItem,
  [selectValue]: handleSelectValue
}, fieldsInitialState)

const errorActionsHandler = handleActions({
  [setError]: handleSetError,
  [resetError]: handleResetError,
  [selectValue]: handleResetError
}, errorInitialState)


// register and unregister ymme
const handleRegister = (state, { payload: { ymmeName }}) => ({
  ...state,
  [ymmeName]: {
    ...(state[ymmeName] ? state[ymmeName] : initialState)
  }
})

const handleUnregister = (state, { payload: { ymmeName }}) => {
  const { [ymmeName]: unregisterYmme, ...remainingState } = state

  return { ...remainingState }
}

export default handleActions({
  [registerYmme]: handleRegister,
  [unregisterYmme]: handleUnregister,
  [setSubModelTypes]: handleSetSubModelTypes,
  [setVehicleId]: handleSetVehicleId,
  [setBaseVehicleId]: handleSetBaseVehicleId,
  [setYmmeInitialized]: handleSetYmmeInitialized,

  [combineActions(
    fetchValues,
    setFilter,
    setValues,
    resetItem,
    resetItems,
    setErrors,
    collapse,
    expand,
    toggleItem,
    selectValue
  )]: makeReducer(FIELDS, fieldsActionsHandler),

  [combineActions(
    setError,
    resetError,
    selectValue
  )]: makeReducer(ERROR, errorActionsHandler)
}, {})
