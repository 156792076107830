class ApiError extends Error {
  constructor({ status = {}, errors = [], statusList = [], payloadData = {}}) {
    super('ApiError')

    /**
    * this will be an indicator that this is ApiError
    * we can't use err instanceof ApiError as babel-node doesn't allow to extend built-in objects
    * read more {@link https://github.com/babel/babel/issues/3083}.
     */
    this.isApiError = true

    this.status = status
    this.statusList = statusList
    this.errors = errors
    this.errorPayload = payloadData
  }
}

export default ApiError
