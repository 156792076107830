import { compose, withState, lifecycle, branch, renderNothing } from 'recompose'
import Placeholder from './Placeholder'

const withShouldRender = withState('shouldRender', 'setShouldRender', true)

const withLifecycle = lifecycle({
  componentDidMount() {
    const { ifTargetIdExists, setShouldRender } = this.props
    ifTargetIdExists && setShouldRender(!!window.document.getElementById(ifTargetIdExists))
  }
})

const withHideIfNoRender = branch(
  ({ shouldRender = true }) => !shouldRender,
  renderNothing
)

export default compose(
  withShouldRender,
  withLifecycle,
  withHideIfNoRender
)(Placeholder)
