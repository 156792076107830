import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import ActiveCartReminderModal from './ActiveCartReminderModal'
import { ACTIVE_CART_REMINDER_MODAL, QUOTE_SUMMARY_PAGE } from 'constant'
import { getActiveVehicleName } from 'selectors/getActiveVehicle'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  vehicleName: getActiveVehicleName(state)
})

const withSubmitHandlers = withHandlers({
  closeModalHandler: ({ handleHide }) => () => {
    handleHide()
    sessionStorage.setItem('activeCartCustomerExperienceWasShown', 'true')
  },
  proceedAppointment: ({ handleHide, history }) => () => {
    history.push(QUOTE_SUMMARY_PAGE)
    sessionStorage.setItem('activeCartCustomerExperienceWasShown', 'true')
    handleHide()
  }
})

export default compose(
  registerReduxModal({ name: ACTIVE_CART_REMINDER_MODAL }),
  connect(mapStateToProps),
  withRouter,
  withSubmitHandlers
)(ActiveCartReminderModal)
