import { compose, withStateHandlers, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import Theme from 'components/common/Theme/Theme'
import { setSticky } from './actions'

const withStickyStateHandlers = withStateHandlers(
  {
    diff: 0,
    clientHeight: null
  },
  {
    setDiff: () => diff => ({ diff }),
    setClientHeight: () => clientHeight => ({ clientHeight })
  }
)

const withHandleScroll = withHandlers({
  handleScroll: ({
    stickyScrollBoundary = 0,
    diff,
    clientHeight,
    setDiff,
    setClientHeight,
    setSticky,
    sticky: checkSticky
  }) => debounce(() => {
    !diff && setDiff(document.body.clientHeight)
    !clientHeight && setClientHeight(document.body.clientHeight)

    const newClientHeight = document.body.clientHeight
    const pageYOffset = window.pageYOffset
    const newDiff = newClientHeight - pageYOffset

    if (diff === newDiff) {
      return
    }

    if (pageYOffset <= stickyScrollBoundary) {
      const sticky = Boolean(pageYOffset > stickyScrollBoundary)

      setDiff(document.body.clientHeight)
      setSticky({ sticky })
      return
    }

    if (newClientHeight !== clientHeight) {
      setClientHeight(newClientHeight)
      setDiff(newDiff)
      return
    }

    const sticky = diff > newDiff

    setSticky({ sticky })
    setDiff(newDiff)
    setClientHeight(newClientHeight)
  }, Theme.scrollDebounceTimeout, { leading: true, maxWait: 300 })
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { handleScroll } = this.props
    window && window.addEventListener && window.addEventListener('scroll', handleScroll)
  },
  componentWillUnmount() {
    const { handleScroll } = this.props
    window && window.removeEventListener && window.removeEventListener('scroll', handleScroll)
  }
})

export default compose(
  withStickyStateHandlers,
  connect(({ sticky: { sticky } = {}}) => ({ sticky }), { setSticky }),
  withHandleScroll,
  withLifecycle
)
