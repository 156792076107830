import Theme from 'components/common/Theme/Theme'
import {
  EVS_FLAG_TYPE,
  EV_FLAG_SPEC,
  EVC_FLAG_TYPE
} from './constans'

export const getTypeColor = type => {
  switch (type) {
    case 'OE':
      return `color: ${Theme.colors.green}; background: ${Theme.colors.almost_grey};`
    case 'EV':
      return `color: ${Theme.colors.white}; background: ${Theme.colors.green};`
    case 'EV-C':
      return `color: ${Theme.colors.green}; background: ${Theme.colors.white};`
    default:
  }
}

export const getElectricVehicleSpecifications = specifications => specifications.filter(specification =>
  ((specification.specValue === EVS_FLAG_TYPE || specification.specValue === EVC_FLAG_TYPE)
  && specification.specName === EV_FLAG_SPEC))
