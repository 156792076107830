import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import { DefaultPopoverLink } from 'components/common/Popover/elements'
import TireSizeTooltip from '../../../TireSizeTooltip'

export const Instructions = styled.div`
  text-align: left;
  color: ${Theme.colors.dark_grey};
  line-height: 18px;
  ${Mixin.responsive('padding-bottom', '28px', '15px')}
`
export const CustomPopover = styled(TireSizeTooltip)`
  & [data-content] {
    padding: 30px 15px 10px;
    text-align: center;
    ${Mixin.responsive('width', '390px', '320px')}
    ${Media.mobile`
      &::before,
      &::after {
        right: auto;
      }

      &::before {
        margin: 0 0 0 -6px;
      }

      &::after {
        margin: 0 0 0 -15px;
      }
    `}
  }
`

export const PopoverLink = styled(DefaultPopoverLink)`
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

export const OriginalTireSizeWrapper = styled.div`
  margin-bottom: 29px;
`
