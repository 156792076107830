import React from 'react'
import PropTypes from 'prop-types'
import NavigationBack from 'components/common/NavigationBack'

const BOTBreadcrumbBack = ({ displayName }) =>
  <NavigationBack displayName={ displayName } />

BOTBreadcrumbBack.type = 'BOTBreadcrumbBack'

export default BOTBreadcrumbBack

BOTBreadcrumbBack.propTypes = {
  displayName: PropTypes.string
}

