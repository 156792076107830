export const extractVehicleData = ({
  makeId,
  makeDesc,
  yearDesc,
  modelId,
  modelDesc,
  baseVehicleId,
  subModelId,
  subModelDesc,
  subModelImageName,
  vehicleId,
  scene7ImageUrl,
  engineConfigId,
  engineDesc
}) => ({
  makeId,
  makeDesc,
  yearDesc,
  modelId,
  modelDesc,
  baseVehicleId,
  subModelId,
  subModelDesc,
  subModelImageName,
  vehicleId,
  scene7ImageUrl,
  engineConfigId,
  engineDesc
})

export const buildSteps = (steps = []) => steps.map(({
  label: stepName,
  options: stepOptions,
  value: selectedValue,
  onClick: onOptionSelect
}) => ({
  stepName,
  stepOptions,
  selectedValue,
  onOptionSelect
}))
