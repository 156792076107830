export default [
  'BOTAccessServiceHistory',
  'BOTConnectToCarFax',
  'BOTFindServiceHistory',
  'BOTServiceHistory',
  'BOTSkuFindLowerPriceContentInfoForm',
  'BOTSkuFindLowerPriceDescription',
  'BOTSkuFindLowerPriceInfo',
  'CarfaxTeaserContent',
  'OneColumnServiceHistoryPage'
]

/*
Appears on:
/modals/find-lower-price?skuId=000000000001087501-BOT,
/modals/find-service-history?loggedIn=true,
/modals/find-service-history,
/my-account/service-history?access=registered,
/my-account/service-history?access=signup,
/my-account/service-history?vehicle=added
*/
