import { compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
// TODO: default export in index file causes cyclic dependency. Analyze differences between BOT and TA
import registerModalPage from 'components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { PROMOTION_LIGHT_BOX_MODAL } from 'constant'
import PromotionLightBoxModal from './PromotionLightBoxModal'

const pageSrc = '/modals/promo-lightbox'

export default compose(
  registerReduxModal({ name: PROMOTION_LIGHT_BOX_MODAL }),
  registerModalPage({ pageSrc, rootType: MODAL_BOX_TYPE })
)(PromotionLightBoxModal)
