import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { LAZY_IMG_PLACEHOLDER } from 'components/common/hoc/withLazyLoader/constants'

const HighContrastTest = ({ setBlockRef }) =>
  <Container ref={ setBlockRef } />

HighContrastTest.propTypes = {
  setBlockRef: PropTypes.func
}
const Container = styled.div`
  width: 0;
  height: 0;
  background-image: url('${LAZY_IMG_PLACEHOLDER}');
`

export default HighContrastTest
