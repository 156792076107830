export const ALL = 'All Deals'
export const NATIONWIDE = 'Nationwide Offers'
export const LOCAL = 'localOffers'

export const MENU = [
  { title: ALL },
  { title: NATIONWIDE },
  { title: LOCAL }
]

export const PROMOTIONS_CREATIVE_MAP = [
  'store-detail-all-deals',
  'store-detail-nationwide-offers',
  'store-detail-local-offers'
]

export const NATIONWIDE_OFFERS_TAB_INDEX = 1

export const NO_LOCAL_PROMO_MSG = 'There are currently no independent offers for the store selected. Check out our nationwide offers tab.'
