import { handleActions } from 'redux-actions'
import { initialState } from './model'
import { getStatesSuccess } from './actions'

export default handleActions({
  [getStatesSuccess]: (state, { payload: states }) => ({
    ...state,
    states
  })
}, initialState)
