import CommonDatePicker from './CommonDatePicker'
import { compose, withStateHandlers, withHandlers, withProps } from 'recompose'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DATE_FORMAT, OPEN_KEYS, CLOSE_KEY, FOCUS_TO_DATE_KEYS, FOCUS_TO_BUTTON_KEY, FOCUS_TO_NEXT_FIELD } from './constants'
import { setFocus } from './helpers'

dayjs.extend(customParseFormat)

const withDateState = withStateHandlers(({ value }) => {
  const date = dayjs(value, DATE_FORMAT, true)

  return {
    date: date.isValid() ? date : null
  }
}, {
  setDate: () => (date) => ({ date })
})

const withRefsState = withStateHandlers(
  {
    pickerRef: null,
    focusRef: null,
    inputRef: null,
    cellRef: null,
    buttonRef: null,
    popupRef: null
  }, {
    setPickerRef: () => (pickerRef) => ({ pickerRef }),
    setFocusRef: () => (focusRef) => ({ focusRef }),
    setInputRef: () => (inputRef) => ({ inputRef }),
    setCellRef: () => (cellRef) => ({ cellRef }),
    setButtonRef: () => (buttonRef) => ({ buttonRef }),
    setActiveRef: () => (popupRef) => ({ popupRef })
  }
)

const withCommonHandlers = withHandlers({
  handleOpen: ({ popupRef }) => () => {
    popupRef && popupRef.activate()
  },
  handleCloseWithoutFocus: ({ popupRef }) => () => {
    popupRef && popupRef.deactivate()
  },
  handleClose: ({ date, pickerRef, popupRef, focusRef }) => (withoutReset = false) => {
    popupRef && popupRef.deactivate()
    focusRef && setFocus(focusRef)

    if (!withoutReset) {
      pickerRef && pickerRef.resetFocusToCurrent(date)
    }
  },
  handleEventWithFocus: () => (event, handler, element) => {
    handler && handler(event)
    element && setFocus(element)
  }
})

const withSelectionHandlers = withHandlers({
  onDateSelect: ({ onChange, setDate, handleClose }) => (e) => {
    setDate(dayjs(e))

    onChange(dayjs(e).format(DATE_FORMAT))

    handleClose(true)
  },
  withButtonRef: ({ setButtonRef }) => (ref) =>
    el => {
      ref(el)
      setButtonRef(el)
    },
  withCellFocusByKey: ({ popupRef, setFocusRef, cellRef, buttonRef, handleEventWithFocus }) => (handler) =>
    (e) => {
      if (OPEN_KEYS.includes(e.keyCode)) {
        e.preventDefault()

        setFocusRef(buttonRef)
        handleEventWithFocus(e, handler, cellRef)
      } else if (e.keyCode === FOCUS_TO_NEXT_FIELD) {
        popupRef && popupRef.deactivate()
      }
    },
  withCellFocusByClick: ({ setFocusRef, cellRef, buttonRef, handleEventWithFocus }) => (handler) =>
    (e) => {
      e.preventDefault()

      setFocusRef(buttonRef)
      handleEventWithFocus(e, handler, cellRef)
    },
  withButtonFocus: ({ buttonRef, handleEventWithFocus }) => (handler) =>
    (e) => handleEventWithFocus(e, handler, buttonRef),
  handleCloseKeyDown: ({ handleClose }) => (e) => {
    if (e.keyCode === CLOSE_KEY) {
      handleClose()
    }
  }
})

const withInputHandlers = withHandlers({
  onFocus: ({ handleOpen, setFocusRef, focusRef, inputRef }) => (e) => {
    if (focusRef === inputRef) {
      setFocusRef(null)
      return
    }
    setTimeout(() => handleOpen())
  },
  onClick: () => (e) => {
    e.nativeEvent.stopPropagation()
    e.preventDefault()
  },
  onKeyDown: ({ setFocusRef, inputRef, cellRef, handleClose, handleCloseWithoutFocus }) => (e) => {
    if (FOCUS_TO_DATE_KEYS.includes(e.keyCode)) {
      setFocusRef(inputRef)
      cellRef && setFocus(cellRef)
    } else if (e.keyCode === FOCUS_TO_BUTTON_KEY && !e.shiftKey) {
      handleClose()
    } else if (e.keyCode === FOCUS_TO_BUTTON_KEY && e.shiftKey) {
      handleCloseWithoutFocus()
    }
  },
  onKeyUp: ({ pickerRef, value, setDate }) => (e) => {
    const date = dayjs(value, DATE_FORMAT, true)

    if (date.isValid()) {
      setDate(date)
      pickerRef && pickerRef.resetFocusToCurrent(date, false)
    }
  }
})

const withDateProps = withProps(
  ({ withButtonRef, withCellFocusByClick, withCellFocusByKey, withButtonFocus, handleCloseKeyDown }) => ({
    mask: '99/99/9999',
    popoverProps: {
      popoverLinkProps: {
        withButtonRef,
        withCellFocusByClick,
        withCellFocusByKey,
        withButtonFocus
      },
      popoverInnerContentProps: {
        onKeyDownCapture: handleCloseKeyDown
      }
    }
  })
)

export default compose(
  withDateState,
  withRefsState,
  withCommonHandlers,
  withSelectionHandlers,
  withInputHandlers,
  withDateProps
)(CommonDatePicker)
