import { createAction } from 'redux-actions'
import { isApiError, rest } from 'services'
import { showNotification } from './BOTFavoritesNotification'
import { isErrorCode } from 'helpers/utils'
import { CODE_DATA_NOT_AVAILABLE, MY_FAVORITES_KEY } from 'constant'

export const loadFavoriteSkusSuccess = createAction('MY_FAVORITES/LOAD_SKUS')
export const loadFavoriteItemsSuccess = createAction('MY_FAVORITES/LOAD_ITEMS')
export const loadNonCompatibleFavoriteItemsSuccess = createAction('MY_FAVORITES/LOAD_NON_COMPATIBLE_ITEMS')
export const addFavoriteSuccess = createAction('MY_FAVORITES/ADD_FAVORITE_SUCCESS')
export const removeFavoriteSuccess = createAction('MY_FAVORITES/REMOVE_FAVORITE_SUCCESS')
export const clearFavorites = createAction('MY_FAVORITES/CLEAN_FAVORITES')
export const restoreFavoritesSuccess = createAction('MY_FAVORITES/RESTORE_FAVORITES_SUCCESS')
export const loadFavoritesGiftListIdSuccess = createAction('MY_FAVORITES/LOAD_GIFT_LIST_ID')

export const loadFavoriteSkus = () => async dispatch => {
  try {
    const { favoriteItemIds } = await rest.api.getFavoriteItemIds()
    dispatch(loadFavoriteSkusSuccess(favoriteItemIds))
  } catch (err) {
    if (isApiError(err)) {
      if (isErrorCode({ err, code: CODE_DATA_NOT_AVAILABLE })) {
        dispatch(loadFavoriteSkusSuccess([]))
      } else {
        console.error(err)
      }
    }
  }
}

export const loadFavoriteItems = () => async dispatch => {
  try {
    const {
      favoriteItesVOList,
      nonCompatibleFavoriteItemsVOList,
      giftListId } = await rest.api.getFavoriteItemsWithDetails()

    dispatch(loadFavoriteItemsSuccess(favoriteItesVOList))
    dispatch(loadNonCompatibleFavoriteItemsSuccess(nonCompatibleFavoriteItemsVOList))
    dispatch(loadFavoritesGiftListIdSuccess(giftListId))
  } catch (err) {
    if (isApiError(err)) {
      if (isErrorCode({ err, code: CODE_DATA_NOT_AVAILABLE })) {
        dispatch(loadFavoriteItemsSuccess([]))
        dispatch(loadNonCompatibleFavoriteItemsSuccess([]))
      } else {
        console.error(err)
      }
    }
  }
}

export const resetFavoriteItems = () => dispatch => {
  dispatch(loadFavoriteItemsSuccess([]))
}

export const removeFavorite = ({ skuId, ...data }) => async dispatch => {
  try {
    await rest.api.deleteFavoriteItem({ urlKeys: { skuId }})
    showNotification('removed', data)
    dispatch(removeFavoriteSuccess(skuId))
    dispatch(loadFavoriteItems())
  } catch (error) {
    if (isApiError(error)) {
      console.error(error)
    }
  }
}

export const addFavorite = ({ skuId, ...data }) => async dispatch => {
  try {
    const { countFront, countRear, rearTireSize } = data

    let requestData = {
      skuId,
      quantity: countFront
    }

    if (rearTireSize) {
      requestData = {
        skuId,
        staggeredsku: true,
        frontQuantity: countFront,
        rearQuantity: countRear
      }
    }

    await rest.api.addFavoriteItem(requestData)
    showNotification('added', data)
    dispatch(addFavoriteSuccess(skuId))
  } catch (error) {
    if (isApiError(error)) {
      console.error(error)
    }
  }
}

export const restoreFavorites = () => dispatch => {
  try {
    const localState = localStorage.getItem(MY_FAVORITES_KEY)
    if (localState) {
      dispatch(loadFavoriteSkusSuccess(JSON.parse(localState)))
    }
  } catch (err) {
    console.error(err)
  }
}
