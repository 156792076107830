import styled, { css } from 'styled-components'
import Media from 'components/common/Media'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'

export const ModalBox = styled(Modal)`
  overflow-y: auto;
  padding: 25px 24px 34px;
  ${Mixin.responsive('width', '576px', '100%')}
`

export const Section = styled.div`
  ${Mixin.clearFix()};
`

export const Col = styled.div`
  float: left;
  width: ${props => props.width || '18em'};
  padding: ${props => props.padding || '0 24px 15px 0'};
  ${props => props.short && css`
    width: 9em;
  `}
  ${props => props.large && css`
    width: 480px;
    padding-right: 0;
  `}
  ${props => props.clear && css`
    clear: both;
  `}
  ${props => props.lastChild && css`
    padding-right: 0;
  `}
  ${Media.mobile`
    width: 100%;
    padding-right: 0;
  `}
`
