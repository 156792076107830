import { rest } from 'services'
import { hide } from 'redux-modal'
import { createAction } from 'redux-actions'
import { submit, change, getFormValues } from 'redux-form'
import { refresh } from 'actions/router'
import { createCommunicationPreferencesRequestModel } from './requestModel'
import { PROVIDE_PHONE_NUMBER_MODAL } from '../ProvidePhoneNumberModal'
import { PROVIDE_PHONE_NUMBER_FORM } from '../ProvidePhoneNumberModal/components/ProvidePhoneNumberForm'
import {
  COMMUNICATION_PREFERENCES_FORM,
  CALL_ME_PREFERENCE,
  PROMOTIONS_FIELD,
  REMINDERS_FIELD
} from '../CommunicationPreferencesForm/constants'

const filterRule = value => value !== CALL_ME_PREFERENCE

export const setCommunicationPreferencesEditMode = createAction('COMMUNICATION_PREFERENCES/SET_EDIT_MODE')

const updatePreferences = values => dispatch =>
  rest.api
    .updateCommunicationPreferences(createCommunicationPreferencesRequestModel(values))
    .then(() => dispatch(refresh()))

export const submitUpdatePreferences = ({ reminders, promotions }) => async dispatch => {
  await dispatch(updatePreferences({ reminders, promotions }))

  dispatch(setCommunicationPreferencesEditMode(false))
}

export const providePhoneNumber = ({ primaryPhone }) => async dispatch => {
  await rest.api
    .currentUser({ primaryPhone })

  dispatch(hide(PROVIDE_PHONE_NUMBER_MODAL))
  dispatch(refresh())
}

export const submitProvidePhoneNumberForm = () => submit(PROVIDE_PHONE_NUMBER_FORM)

export const hideProvidedNumberPhoneModal = e => (dispatch, getState) => {
  e && e.preventDefault()
  const { reminders, promotions } = getFormValues(COMMUNICATION_PREFERENCES_FORM)(getState())

  reminders.includes(CALL_ME_PREFERENCE) &&
    dispatch(change(COMMUNICATION_PREFERENCES_FORM, REMINDERS_FIELD, reminders.filter(filterRule)))

  promotions.includes(CALL_ME_PREFERENCE) &&
    dispatch(change(COMMUNICATION_PREFERENCES_FORM, PROMOTIONS_FIELD, promotions.filter(filterRule)))

  dispatch(hide(PROVIDE_PHONE_NUMBER_MODAL))
}
