import { compose, mapProps } from 'recompose'
import withType from 'components/common/hoc/withType'
import Breadcrumb from './Breadcrumb'

export const BOTBreadcrumbSimple = compose(
  withType({ type: 'BOTBreadcrumbSimple' }),
  mapProps(({ link: { path } = {}, ...props }) => ({
    ...props,
    link: path
  }))
)(Breadcrumb)

export const BOTBreadcrumbName = withType({
  type: [
    'BOTBreadcrumbPromotionName',
    'BOTBreadcrumbBrandname',
    'BOTBreadcrumbTireLinesName',
    'BOTBreadcrumbTireSearchResult',
    'BOTBreadcrumbGlobalSearchResult',
    'BOTBreadcrumbServiceCategoryName',
    'BOTBreadcrumbServiceName',
    'BOTBreadcrumbPromotionRegion',
    'BOTBreadcrumbArticleCategoryName',
    'BOTBreadcrumbArticleName',
    'BOTBreadcrumbVehicleName',
    'BOTBreadcrumbHome',
    'BOTBreadcrumbService',
    'BOTBreadcrumbStateName',
    'BOTBreadcrumbCityName'
  ]
})(Breadcrumb)
