import { compose, lifecycle } from 'recompose'
import { loadBreadPaymentSdk, runBreadPayment } from './helpers'

const withBrandPaymentLifeCycle = lifecycle({
  componentDidMount() {
    loadBreadPaymentSdk(() => runBreadPayment())
  }
})

export default compose(withBrandPaymentLifeCycle)
