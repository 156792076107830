import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'

const EditExpander = ({ expanded = false }) =>
  <Wrapper expanded={ expanded }>
    <Pencil asset="icon-edit" noLazyLoad alt="" />
    <Edit>Edit</Edit>
  </Wrapper>

export default EditExpander

EditExpander.propTypes = {
  expanded: PropTypes.bool
}

const Wrapper = styled.div`
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 5px;
  ${props => props.expanded && css`
    display: none;
  `}
`

const Edit = styled.span`
  color: ${Theme.colors.blue};
  font-size: 14px;
  text-transform: none;

  :hover {
    text-decoration: underline;
  }
`

const Pencil = styled(Icon)`
  width: 12px;
  margin-right: 5px;
`
