import { DEFAULT_PAGE_TITLE, EXCLUDED_PAGES_FOR_SITEWIDE_SCHEMA } from './constants'
import { noIndexRunTests } from './robotsMeta'
import { getArrayObjectByParam } from 'helpers/utils'

export const getLink = ({
  canonical
} = {}) => canonical ? [{ rel: 'canonical', href: canonical }] : []

const determCartridge = (canonical = '') => {
  let cartridgeInfo = {}

  switch (true) {
    case canonical.includes('sku'):
      cartridgeInfo = {
        type: 'BOTSkuDetail',
        key: 'skuDetails'
      }
      break
    case canonical.includes('line'):
      cartridgeInfo = {
        type: 'BOTTireLineDetails',
        key: 'tireLineDetails'
      }
      break
    default:
  }

  return cartridgeInfo
}

export const getImageUrlFromMainTireContent = ({ mainTireContent = [], value }) => {
  const { type, key } = value
  const [{
    [key]: {
      mainImageUrl
    } = {}
  } = {}] = getArrayObjectByParam({ array: mainTireContent, value: type })

  return mainImageUrl
}

export const getImage = ({
  contents: [{
    mainTireContent
  } = {}] = [],
  canonical
} = {}) => getImageUrlFromMainTireContent({ mainTireContent, value: determCartridge(canonical) })

export const getCanonicalUrl = ({
  canonical
} = {}) => canonical && canonical

export const getTitle = ({
  contents: [{
    title = DEFAULT_PAGE_TITLE
  } = {}] = []
} = {}) => title

export const getMeta = ({
  contents: [{
    metaKeywords = '',
    metaDescription = '',
    mainContent
  } = {}] = [],
  canonical
} = {}) => [
  {
    name: 'keywords',
    content: metaKeywords || ''
  },
  {
    name: 'description',
    content: metaDescription || ''
  },
  ...noIndexRunTests({ canonical, mainContent })
]

export const getDescription = ({
  contents: [{
    metaDescription = ''
  } = {}] = []
} = {}) => metaDescription

export const isPageForSitewideSchema = (link) =>
  !EXCLUDED_PAGES_FOR_SITEWIDE_SCHEMA.some(page => link.indexOf(page) > -1)

export const generateSitewideSchema = (link, title, description) =>
  JSON.stringify({
    '@context': 'http://schema.org',
    '@type': [
      'Webpage'
    ],
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': link
    },
    url: link,
    speakable: {
      '@type': 'SpeakableSpecification',
      xPath: [
        '/html/head/title',
        '/html/head/meta[@name="description"]/@content'
      ]
    },
    significantLink: 'https://www.bigotires.com/appointment/quick-appointment',
    name: title,
    description,
    provider: 'Midas',
    potentialAction: 'http://schema.org/ScheduleAction',
    audience: {
      '@type': 'PeopleAudience',
      name: 'Car Owners'
    },
    mentions: [
      {
        '@type': 'WebPage',
        name: 'Schedule Tire Installation',
        url: 'https://www.bigotires.com/appointment/quick-appointment',
        '@id': 'https://www.bigotires.com/appointment/quick-appointment'
      },
      {
        '@type': 'WebPage',
        name: 'Shop for New Tires',
        url: 'https://www.bigotires.com/tires/',
        '@id': 'https://www.bigotires.com/tires/'
      },
      {
        '@type': 'WebPage',
        name: 'Shop for New Tire by Vehicles',
        url: 'https://www.midas.com/tiresearch',
        '@id': 'https://www.midas.com/tiresearch'
      },
      {
        '@type': 'WebPage',
        name: 'Auto Repair Services',
        url: 'https://www.bigotires.com/services/',
        '@id': 'https://www.bigotires.com/services/'
      },
      {
        '@type': 'WebPage',
        name: 'Local Deals and Specials',
        url: 'https://www.bigotires.com/deals',
        '@id': 'https://www.bigotires.com/deals'
      }
    ]
  })
