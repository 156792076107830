import { createAction } from 'redux-actions'
import { show, hide } from 'redux-modal'
import { rest } from 'services'
import { getShoppingData } from 'actions/shoppingData'
import { googleAnalyticProductAddToCart } from 'actions/googleAnalytic'
import {
  ADD_TO_QUOTE_MODAL,
  PROMOTION_LIGHT_BOX_MODAL,
  CODE_ITEM_EXISTS_IN_CART,
  CODE_ITEM_DISCONTINUED_FOR_STORE,
  GENERIC_MESSAGE_MODAL,
  MY_FAVORITES_PAGE
} from 'constant'
import {
  ITEM_IN_CART_MESSAGE,
  ALREADY_IN_QUOTE,
  ITEM_DISCONTINUED
} from './constants'
import { isErrorCode } from 'helpers/utils'
import { setUpdatingBlock } from 'components/common/UpdatingBlock/actions'
import { ADDING_TO_SHOPPING_QUOTE } from 'components/common/UpdatingBlock/constants'
import AlreadyInQuoteSubComponent from './components/AlreadyInQuoteSubComponent'
import { getLocationPathname } from 'components/Login/selectors'
import { getEvSkuIdsList } from 'components/common/hoc/withAddToQuote/selectors'
import { setEvSkuId } from './helpers'

export const setProductData = createAction('ADD_TO_CART/SET_PRODUCT_DATA')

export const setSkusAddedToCart = createAction('ADD_TO_CART/SET_SKUS_ADDED_TO_CART')

export const removeSkuAddedToCart = createAction('REMOVE_FROM_CART/REMOVE_SKU_ADDED_TO_CART')

export const resetSkusAddedToCart = createAction('ADD_TO_CART/RESET_SKUS_ADDED_TO_CART')

export const addProductsWithChangedAvailabilityOrPrice = createAction('ADD_TO_CART/ADD_PRODUCTS_WITH_CHANGED_AVAILABILITY_OR_PRICE')

export const clearProductsWithChangedAvailabilityOrPrice = createAction('ADD_TO_CART/CLEAR_PRODUCTS_WITH_CHANGED_AVAILABILITY_OR_PRICE')

const getListSource = (url) => {
  let list = ''
  if (url.startsWith('/tires/by-vehicle')) {
    list = 'Search Results'
  }
  if (url.startsWith('/tires/sku')) {
    list = 'Tire Details'
  }
  if (url === MY_FAVORITES_PAGE) {
    list = 'Favorite'
  }
  return { list }
}

// label could be passed as additional parameters to be used in googleAnalyticProductAddToCart
export const addProductToCart = ({
  rearSKUQuantity,
  rearSkuId,
  staggeredSkuId,
  label,
  oeFlag,
  evFlag,
  ...props }) => (dispatch, getState) => {
  const productData = (rearSkuId && rearSKUQuantity) ?
    { rearSkuId, rearSKUQuantity, staggeredSkuId, ...props } : props
  const { skuId } = props
  const state = getState()
  const evSkuIds = getEvSkuIdsList(state)

  dispatch(setUpdatingBlock({
    name: ADDING_TO_SHOPPING_QUOTE,
    value: Array(productData.promoId).join(',') || productData.staggeredSkuId || productData.skuId
  }))
  dispatch(getShoppingData(rest.api.addProductToCart, productData))
    .then(({ addToQuote }) => {
      if (addToQuote) {
        dispatch(setProductData({ addToQuote, evSkuIds: setEvSkuId(evSkuIds, evFlag, skuId) }))
        dispatch(hide(PROMOTION_LIGHT_BOX_MODAL))
        dispatch(show(ADD_TO_QUOTE_MODAL))
        dispatch(setUpdatingBlock({ name: ADDING_TO_SHOPPING_QUOTE, value: null }))
        return { ...productData, label, oeFlag, evFlag }
      }
      dispatch(setUpdatingBlock({ name: ADDING_TO_SHOPPING_QUOTE, value: null }))
    })
    .then((gtmProps) => {
      const currentUrl = getLocationPathname(state)

      gtmProps &&
        dispatch(googleAnalyticProductAddToCart({ product: { ...gtmProps }, ...getListSource(currentUrl) }))
    })
    .catch((err) => {
      if (isErrorCode({ err, code: CODE_ITEM_EXISTS_IN_CART })) {
        const modalOptions = {
          text: ITEM_IN_CART_MESSAGE,
          title: ALREADY_IN_QUOTE,
          SubComponents: AlreadyInQuoteSubComponent
        }
        dispatch(show(GENERIC_MESSAGE_MODAL, modalOptions))
      } else if (isErrorCode({ err, code: CODE_ITEM_DISCONTINUED_FOR_STORE })) {
        const modalOptions = {
          text: err.status.description,
          title: ITEM_DISCONTINUED
        }
        dispatch(show(GENERIC_MESSAGE_MODAL, modalOptions))
      }

      dispatch(setUpdatingBlock({ name: ADDING_TO_SHOPPING_QUOTE, value: null }))
    })
}
