import { VEHICLE_DETAILS, TIRE_SIZES, EXTRA_VEHICLE_DETAILS } from './constants'

export const getConfirmTireSizeData = ({
  confirmTireSizeData: {
    [VEHICLE_DETAILS]: vehicleDetails,
    [TIRE_SIZES]: tireSizes,
    [EXTRA_VEHICLE_DETAILS]: extraVehicleDetails
  } = {}
}) => ({
  vehicleDetails,
  tireSizes,
  extraVehicleDetails
})

