import { compose } from 'redux'
import { connect } from 'react-redux'
import { withHandlers, withProps } from 'recompose'
import { VEHICLE_CONFIGURATOR_MODAL } from 'constant'
import { navigateToSearchByTireSizes } from 'actions/shopByTires'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import getActiveVehicle from 'selectors/getActiveVehicle'
import { processVehicleData } from 'components/common/hoc/withYmme/helpers'
import { YMME_NAME, LICENSE_PLATE_NAME } from './constants'
import VehicleConfigurationModal from './VehicleConfiguratorModal'
import * as actions from './actions'

const withFormNames = withProps({
  ymmeName: YMME_NAME,
  form: LICENSE_PLATE_NAME
})

const withHandleGettingTireSizeSuccess = withHandlers({
  handleGettingTireSizeSuccess: ({
    handleClose,
    showConfirmTireSizeModal,
    confirmTireSizeModalProps
  }) => () => {
    handleClose()
    showConfirmTireSizeModal(confirmTireSizeModalProps)
  }
})

const withHandleClose = withHandlers({
  handleClose: ({
    hideVehicleConfiguratorModal,
    resetYmme,
    resetLicencePlateForm,
    resetTiresToCommitted,
    closeModalRearTireSize
  }) => () => {
    resetYmme()
    resetLicencePlateForm()
    resetTiresToCommitted()
    closeModalRearTireSize()

    hideVehicleConfiguratorModal()
  }
})

const withHandleShopByTireSizesSubmit = withHandlers({
  handleShopByTireSizesSubmit: ({ handleClose, navigateToSearchByTireSizes }) => tireSize => {
    handleClose()
    navigateToSearchByTireSizes(tireSize)
  }
})

const mapStateToProps = (state) => ({ vehicle: processVehicleData(getActiveVehicle(state)) })

export default compose(
  connect(mapStateToProps, { ...actions, navigateToSearchByTireSizes }),
  registerReduxModal({ name: VEHICLE_CONFIGURATOR_MODAL, destroyOnHide: true }),
  withFormNames,
  withHandleClose,
  withHandleShopByTireSizesSubmit,
  withHandleGettingTireSizeSuccess
)(VehicleConfigurationModal)
