import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ButtonNoneStaggered from './ButtonNonStaggered'
import ButtonStaggered from './ButtonStaggered'
import OriginalEquipment from './OriginalEquipment'

const EquipmentOption = ({ diameter, rearDiameter, staggered, ...props }) =>
  <Wrapper>
    <OriginalEquipment staggered={ staggered } diameter={ diameter } rearDiameter={ rearDiameter } />
    {
      staggered ?
        <ButtonStaggered { ...props } /> :
        <ButtonNoneStaggered { ...props } />
    }
  </Wrapper>

EquipmentOption.propTypes = {
  diameter: PropTypes.string,
  rearDiameter: PropTypes.string,
  staggered: PropTypes.bool
}

export default EquipmentOption

const Wrapper = styled.div`
  margin-bottom: 23px;
`
