import qs from 'qs'
import { BRAND_COMPATIBILITY_QUERY_NAME, BRAND_COMPATIBILITY_COMPATIBLE } from 'constant'
import { PAGE_PATH_REGEXP } from './constants'

// Default pathname example: '/tires/brand/michelin/100021'
export const getDefaultPathname = ({ pathname }) => {
  const [defaultPathname] = pathname.match(PAGE_PATH_REGEXP)

  return defaultPathname
}

export const createPath = (navigationState, currentPath) => {
  // Navigation state contains brand id, so we need to omit it
  const pagePath = getDefaultPathname({ pathname: currentPath }).split('/').slice(0, -1).join('/')
  const [pathname, search] = `${pagePath}${navigationState}`.split('?')

  return { pathname, search }
}

export const getCompatibilityParameter = (value = BRAND_COMPATIBILITY_COMPATIBLE) =>
  `${BRAND_COMPATIBILITY_QUERY_NAME}=${value}`

export const updateCompatibility = (query, value) => {
  const params = qs.parse(query.slice(1))

  params[BRAND_COMPATIBILITY_QUERY_NAME] = value || BRAND_COMPATIBILITY_COMPATIBLE

  return `?${qs.stringify(params)}`
}
