import { css } from 'styled-components'

export default () => css`
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`
