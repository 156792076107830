import { handleActions } from 'redux-actions'
import {
  setGlobalBannerMessage,
  closeGlobalBannerMessage,
  setQuickAppointmentStepper,
  setFullStory,
  setKenshooEventData,
  kenshooQuickAddService,
  setPromoDetailsExperience,
  setBazaar,
  set3StepperEnabled,
  setYmmeExperience,
  setStoreLocatorPageExperience
} from './actions'
import { evaluateFullStoryRatio, evaluateKenshooRevenue } from './helpers'

const initialState = {
  fullStory: null,
  thirdParty: {
    globalHeaderMessage: '',
    isVisible: false,
    quickAppointmentStepper: true,
    promoDetailsExperience: null,
    threeStepperEnabled: true,
    ymmeExperience: null,
    storeLocatorPageExperience: null
  },
  kenshoo: {
    eventData: null,
    revenue: 0
  },
  bazaar: false
}

const reducer = handleActions({
  [setGlobalBannerMessage]: (state, { payload: { value }}) =>
    ({ ...state,
      thirdParty: {
        ...state.thirdParty,
        globalHeaderMessage: value,
        isVisible: true
      }
    }),
  [closeGlobalBannerMessage]: (state) =>
    ({ ...state,
      thirdParty: {
        ...state.thirdParty,
        isVisible: false
      }
    }),
  [setQuickAppointmentStepper]: (state, { payload: { value }}) =>
    ({ ...state,
      thirdParty: {
        ...state.thirdParty,
        quickAppointmentStepper: value
      }
    }),
  [setPromoDetailsExperience]: (state, { payload: { value }}) =>
    ({ ...state,
      thirdParty: {
        ...state.thirdParty,
        promoDetailsExperience: value
      }
    }),
  [set3StepperEnabled]: (state, { payload: { value }}) =>
    ({ ...state,
      thirdParty: {
        ...state.thirdParty,
        threeStepperEnabled: value
      }
    }),
  [setYmmeExperience]: (state, { payload: { value }}) =>
    ({ ...state,
      thirdParty: {
        ...state.thirdParty,
        ymmeExperience: value
      }
    }),
  [setStoreLocatorPageExperience]: (state, { payload: { value }}) =>
    ({ ...state,
      thirdParty: {
        ...state.thirdParty,
        storeLocatorPageExperience: value
      }
    }),
  [setFullStory]: (state, { payload: { value }}) =>
    ({ ...state,
      fullStory: evaluateFullStoryRatio(state, value)
    }),
  [setKenshooEventData]: (state, { payload }) =>
    ({ ...state,
      kenshoo: {
        eventData: { ...payload },
        revenue: evaluateKenshooRevenue(payload, state)
      }
    }),
  [kenshooQuickAddService]: (state, { payload: { revenue }}) =>
    ({ ...state,
      kenshoo: {
        ...state.kenshoo,
        revenue: state.kenshoo.revenue + revenue
      }
    }),
  [setBazaar]: (state, { payload: { bazaar }}) =>
    ({ ...state,
      bazaar
    })
}, initialState)

export default reducer
