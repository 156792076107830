import { handleActions } from 'redux-actions'
import { setInitialLocation } from './actions'

const initialState = {
  location: {}
}

const SSR = handleActions({
  [setInitialLocation]: (state, { payload: { location }}) =>
    ({ ...state,
      location
    })
}, initialState)

export default SSR
