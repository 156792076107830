import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Tabs from 'components/common/Tabs'
import Mixin from 'components/common/Mixin'
import Buttons from './components/Buttons'
import YMMEPlate from './components/YmmePlate'
import ShopByTiresModal from './components/ShopByTiresModal'
import { VEHICLE_CONFIGURATOR_YMME_TAB } from 'constant'

const menu = [{ title: 'Shop by Vehicle' }, { title: 'Shop by Tire Size' }]

const VehicleConfiguratorModal = ({ title, handleClose, activeTab = VEHICLE_CONFIGURATOR_YMME_TAB, ...props }) =>
  <CustomModal data-ymme-modal closeHandler={ handleClose } title={ title }>
    <Tabs ymme position={ activeTab } menu={ menu }>
      <YMMEPlate { ...props } handleClose={ handleClose } shopByTiresIndex={ 1 } />
      <ShopByTiresModal { ...props } handleClose={ handleClose }>
        <Buttons onCancel={ handleClose } />
      </ShopByTiresModal>
    </Tabs>
  </CustomModal>

VehicleConfiguratorModal.propTypes = {
  activeTab: PropTypes.number,
  handleClose: PropTypes.func,
  title: PropTypes.string
}

export default VehicleConfiguratorModal

const CustomModal = styled(Modal)`
  padding: 25px;
  ${Mixin.responsive('width', '450px', '100%')}
`
