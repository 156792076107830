export const YMME_STEP_NAMES = ['year', 'make', 'model', 'subModel']
export const LICENSE_PLATE_STEP_NAMES = ['plate', 'state']
export const SEARCH_FORM_FIELD_NAME = 'search'
export const YEARS = 'years'
export const MAKES = 'makes'
export const MODELS = 'models'
export const SUB_MODELS = 'subModels'
export const YMME_TITLE = 'Search by Vehicle Type'
export const TIRE_SIZE_TITLE = 'Search by Tire Size'
export const LICENSE_PLATE_TITLE = 'Search by Vehicle Plate'
export const FRONT_TIRE_SIZE_STEP_NAMES = [
  { name: 'frontWidth', value: 'width' },
  { name: 'frontRatio', value: 'ratio' },
  { name: 'frontDiameter', value: 'diameter' }
]
export const REAR_TIRE_SIZE_STEP_NAMES = [
  { name: 'rearWidth', value: 'width' },
  { name: 'rearRatio', value: 'ratio' },
  { name: 'rearDiameter', value: 'diameter' }
]
export const STEP_NAMES_FOR_STEPPER_RESET = ['diameter', 'width', 'ratio', 'diameter']
export const OPTIONS_WRAPPER_CLASS_NAME = 'options-wrapper'
export const YMME_STEPPER_STEP_ID = 'ymme-stepper-step'

export const MOST_COMMON_ENDPOINT_MIN = 165
export const MOST_COMMON_ENDPOINT_MAX = 325
export const MOST_COMMON_ENDPOINT_ALL_MAX = 1000
export const MOST_UNCOMMON_ENDPOINT_MIN = 8
export const MOST_UNCOMMON_ENDPOINT_MAX = 155

export const SHOW_ALL_SIZES = 'Show More'
export const HIDE_ALL_SIZES = 'Show Less'
