import { compose } from 'redux'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import withType from 'components/common/hoc/withType'
import { logout } from './actions'
import SignOutLink from './SignOutLink'

const withHandleLogout = withHandlers({
  handleLogout: ({ logout }) => e => {
    e.preventDefault()

    return logout()
  }
})

export default compose(
  withType({ type: 'SignOutLink' }),
  connect(null, { logout }),
  withHandleLogout
)(SignOutLink)
