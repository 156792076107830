import { compose, withHandlers } from 'recompose'
import StoreChangeModal from './StoreChangeModal'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { STORE_CHANGE_MODAL } from 'constant'
import { showStoreLocatorModal } from 'actions/storeLocator'
import { connect } from 'react-redux'

const withCloseHandler = withHandlers({
  handleUseMyStore: ({ handleHide }) => () => {
    handleHide()
  },
  handleFindAnotherStore: ({ handleHide, showStoreLocatorModal }) => () => {
    handleHide()
    showStoreLocatorModal()
  }
})

export default compose(
  registerReduxModal({ name: STORE_CHANGE_MODAL }),
  connect(null, { showStoreLocatorModal }),
  withCloseHandler
)(StoreChangeModal)
