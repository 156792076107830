import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BOT_GLOBAL_MESSAGE_CONTENT_ID, BOT_GLOBAL_MESSAGE_CLOSE_ID } from 'constant'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import HTMLContainer from 'components/common/HTMLContainer/HTMLContainer'
import Button from 'components/common/Button/Button'

const GlobalMessagePlaceholder = ({ handleClose, globalHeaderMessage = '', visibility }) =>
  <Wrapper>
    <GlobalMessage
      data-max-width
      data-non-empty
      data-printless
      hidden={ !visibility }
      id={ BOT_GLOBAL_MESSAGE_CONTENT_ID }
      content={ globalHeaderMessage } />
    <CloseButtonWrapper link id={ BOT_GLOBAL_MESSAGE_CLOSE_ID } onClick={ handleClose } >
      <Icon asset="cross-white" alt="Close Global Message" width={ 11 } height={ 11 } />
    </CloseButtonWrapper>
  </Wrapper>

GlobalMessagePlaceholder.propTypes = {
  globalHeaderMessage: PropTypes.string,
  handleClose: PropTypes.func,
  visibility: PropTypes.bool
}

export default GlobalMessagePlaceholder


const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: ${Theme.colors.red};
`

const GlobalMessage = styled(HTMLContainer)`
  padding: 10px 40px;
  color: ${Theme.colors.white};
  text-align: center;

  a {
    color: ${Theme.colors.white};
    text-decoration: underline;
  }
`

const CloseButtonWrapper = styled(Button)`
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  padding: 10px;
`
