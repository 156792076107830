import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { getLeftPostionInPixelsById } from 'src/helpers/utils'
import {
  VEHICLE_DESCRIPTION,
  TRAILER_IMAGE_ALT_TEXT,
  TIRE_SIZE,
  ICON_TRAILER,
  CAR_BAR
} from './constants'
import { LOADING } from 'constant'
import Link from 'components/common/Link/Link'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import VehicleImage from 'components/common/VehicleImage'
import AvailableTires from 'components/common/AvailableTires'

const ActiveVehicle = ({
  expanded,
  isTrailer,
  activeVehicle: {
    userVehicle: [{
      vehicleNickname,
      tireFrontSize,
      hasStaggeredFitment,
      tireRearSize
    }] = []
  } = {},
  year,
  make,
  model,
  submodel,
  color,
  numberOfAvailableTires,
  isWideMobile,
  onClick,
  searchUrl,
  isPilotStore,
  isNumberOfTiresLoading
}) => {
  const [vehicleDescriptionDynamicLeft, setVehicleDescriptionDynamicLeft] = useState(0)
  // getting the active vehicle description component viewport positions to adjust the css for the mobile device.
  useEffect(() => {
    setVehicleDescriptionDynamicLeft(getLeftPostionInPixelsById({ id: VEHICLE_DESCRIPTION }))
  }, [])

  const renderBoundaryElement = (numberOfAvailableTires || isNumberOfTiresLoading) && !isWideMobile && isPilotStore
    ? <BoundaryElement expanded={ expanded } />
    : null

  const renderAvailableTires = isPilotStore
    ? isNumberOfTiresLoading
      ? <LoadingText vehicleDescriptionDynamicLeft={ vehicleDescriptionDynamicLeft }>{LOADING}</LoadingText>
      : (
        <AvailableTires
          componentType={ CAR_BAR }
          vehicleDescriptionDynamicLeft={ vehicleDescriptionDynamicLeft } />
      )
    : null

  return (
    <Wrapper data-at-active-vehicle>
      <VehicleDetailsWrapper>
        <VehicleImg>
          {/* TODO Change that structure in future for another type (Tire, GenericVehicle) */}
          {
            isTrailer
              ? (
                <TrailerImage
                  asset={ ICON_TRAILER }
                  width={ 118 }
                  height={ 71 }
                  alt={ TRAILER_IMAGE_ALT_TEXT }
                  data-at-trailer-image />
              )
              : (
                <CarImage
                  make={ make }
                  model={ model }
                  submodel={ submodel }
                  year={ parseInt(year, 10) }
                  width={ 110 }
                  height={ 55 }
                  mobileWidth={ 50 }
                  mobileHeight={ 25 }
                  color={ color ? `#${color}` : undefined } />
              )
          }
        </VehicleImg>
        <VehicleDescription id={ VEHICLE_DESCRIPTION }>
          <ChosenModel centerContent={ !tireFrontSize }>
            <VehicleName
              bolder
              to={ searchUrl }
              data-at-vehicle-name
              onClick={ onClick }>
              { vehicleNickname }
            </VehicleName>
          </ChosenModel>
          {
            tireFrontSize &&
            <TireSpecs expanded={ expanded }>
              <SizeTitle data-at-size-title>{TIRE_SIZE}</SizeTitle>
              <SizeValue data-at-size-value>{ hasStaggeredFitment ? `${tireFrontSize} - ${tireRearSize}` : tireFrontSize }</SizeValue>
            </TireSpecs>
          }
        </VehicleDescription>
      </VehicleDetailsWrapper>
      {renderBoundaryElement}
      {renderAvailableTires}
    </Wrapper>
  )
}

ActiveVehicle.propTypes = {
  activeVehicle: PropTypes.shape(),
  color: PropTypes.string,
  expanded: PropTypes.bool,
  isNumberOfTiresLoading: PropTypes.bool,
  isPilotStore: PropTypes.bool,
  isTrailer: PropTypes.bool,
  isWideMobile: PropTypes.bool.isRequired,
  make: PropTypes.string,
  model: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape()
  ]),
  numberOfAvailableTires: PropTypes.number,
  onClick: PropTypes.func,
  searchUrl: PropTypes.string,
  submodel: PropTypes.string,
  year: PropTypes.string
}

export default ActiveVehicle

const Wrapper = styled.div`
  display: flex;
  ${Media.tabletNarrow`
    flex-direction: column;
    align-items: flex-start;
  `}
`
const VehicleDetailsWrapper = styled.div`
  display: flex;
`
const VehicleImg = styled.div`
  ${Mixin.responsive('margin-right', '20px', '10px')}
`
const VehicleDescription = styled.div`
  padding: 0 5px;
  ${Media.mobile`
    vertical-align: middle;
    ${Mixin.ellipsis()}
  `}
`
const VehicleName = styled(Link)`
  color: ${Theme.colors.blue};
  text-transform: capitalize;
  font-size: 14px;
  line-height: 26px;
  ${Media.desktop`
    font-size: 16px;
  `}
`
const CarImage = styled(VehicleImage)`
  top: 6px;
  position: relative;
`
const TrailerImage = CarImage.withComponent(Icon)

const ChosenModel = styled.div`
  ${Media.mobile`
    vertical-align: middle;
    ${Mixin.ellipsis()}
  `}
  ${props => props.centerContent && css`
    position: relative;
    display: inline-block;
    top: 25%;
  `}
`
const TireSpecs = styled.div`
  ${Media.mobile`
    ${props => !props.expanded && css`
      display: none;
    `}
  `}
  line-height: 20px;
`
const SizeTitle = styled.span`
  ${Media.mobile`
    ${Mixin.font.GothamBook(12)}
  `}
`
const SizeValue = styled.span`
  ${Media.mobile`
    white-space: nowrap;
    ${Mixin.font.GothamBook(12)}
  `}
`
const BoundaryElement = styled.div`
  ${Media.mobile`
    ${props => !props.expanded && css`
      height: 24px;
    `}
    margin: 0 16px;
  `}
  height: 48px;
  border: solid 0.5px ${Theme.colors.light_grey};
  margin: 0 19px;
`
const LoadingText = styled.span`
  font-size: 14px;
  ${Mixin.font.GothamBold()}
  line-height: 24px;
  ${Media.mediumMobile`
    font-size: 12px;
  `}
  ${Media.desktop`
    font-size: 16px;
  `}
  ${Media.tabletNarrow`
    padding-left: ${props => props.vehicleDescriptionDynamicLeft - 10}px;
  `}
  color: ${Theme.colors.medium_grey};
`
