import Cookies from 'cookies-js'

import {
  GOOGLE_MAPS_BOOKING_API_SANDBOX,
  GOOGLE_MAPS_BOOKING_API_PRODUCTION,
  GOOGLE_MAPS_BOOKING_API_CONVERSION_COOKIE_KEY,
  GOOGLE_MAPS_BOOKING_API_CONVERSION_PARTNER_ID
} from './constants'

export const storeGoogleConversionTracker = (rwToken) => {
  const token = decodeURIComponent(rwToken)

  const cookiesConfig = {
    path: '/',
    expires: 2592000,
    domain: 'bigotires.com'
  }

  Cookies.set(GOOGLE_MAPS_BOOKING_API_CONVERSION_COOKIE_KEY, token, cookiesConfig)
}

export const sendGoogleConversionTracker = async () => {
  const token = Cookies.get(GOOGLE_MAPS_BOOKING_API_CONVERSION_COOKIE_KEY)

  if (!token) return

  const url = process.env.NODE_ENV === 'production' ? GOOGLE_MAPS_BOOKING_API_PRODUCTION : GOOGLE_MAPS_BOOKING_API_SANDBOX

  fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify({
      conversion_partner_id: GOOGLE_MAPS_BOOKING_API_CONVERSION_PARTNER_ID,
      rwg_token: token
    })
  })
}


export const convDate = (dateStr) => {
  // From mm-dd-yyyy to yyyy-mm-ddThh:MM:ssZ
  const dArr = dateStr.split('-')
  return `${dArr[2]}-${dArr[0]}-${dArr[1]}T00:00:00`
}

export const formatPromoListExpiryDates = (promoList) => promoList.map((promo) => ({
  ...promo,
  formattedExpiryDate: convDate(promo.promotionExpiryDate)
}))
