import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import getComponent from 'helpers/component-helper'

const PromotionsDetailModalPage = ({ data, handleHide }) =>
  <CustomModal closeHandler={ handleHide }>
    {
      React.cloneElement(getComponent(data), { handleHide })
    }
  </CustomModal>

export default PromotionsDetailModalPage

PromotionsDetailModalPage.propTypes = {
  data: PropTypes.shape(),
  handleHide: PropTypes.func
}

const CustomModal = styled(Modal)`
  border: 0;
  ${Media.desktop`
    padding-top: 60px;
  `}
  ${Mixin.responsive('width', '80%', '100%')}
`
