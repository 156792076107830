import { handleActions } from 'redux-actions'
import { setBvId, setTireSizeNumber } from './actions'

export const initialState = {
  tireLineDetails: {
    bvId: ''
  },
  tireSizeNumber: null
}

const handleSetBvId = (state, { payload: { bvId }}) => ({
  ...state,
  tireLineDetails: { bvId }
})

const handleSetTireSizeNumber = (state, { payload: number }) => ({
  ...state,
  tireSizeNumber: number
})

export default handleActions({
  [setBvId]: handleSetBvId,
  [setTireSizeNumber]: handleSetTireSizeNumber
}, initialState)
