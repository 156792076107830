import {
  googleAnalyticProductImpressions,
  googleAnalyticProductClicks,
  googleAnalyticProductDetailsView,
  googleAnalyticProductAddToCart,
  googleAnalyticProductRemoveFromCart,
  googleAnalyticPromotionImpressions,
  googleAnalyticPromotionClicks,
  googleAnalyticCheckout,
  googleAnalyticPurchase,
  googleAnalyticStoreNumberPush,
  googleAnalyticQuickStepperActionBarClick,
  googleAnalytic3StepperActionBarClick,
  googleAnalyticZeroTireSearchResults,
  googleAnalyticQuickApptSubmissionError,
  googleAnalyticDatepickerTomorrowSelected,
  googleAnalyticProductAddToCartWithoutQuoteModal,
  googleAnalyticDatepickerTodaysSelected,
  googleAnalyticEmailAddressFill
} from 'actions/googleAnalytic'
import { setSource } from '../../components/TireDetailsPage/components/BOTSkuDetail/actions'
import { getQuoteInfo } from 'selectors/shoppingData'
import { getCurrentStoreNumber } from 'selectors/getCurrentStore'
import { getGBBDetails } from 'selectors/getGBBDetails'
import {
  getGTMAddToCartProduct,
  getGTMOTDPData,
  getGTMPromotionData,
  getGTMPromotionAsProductData,
  getGTMProductData,
  getGTMServiceData,
  getGTMKitData,
  getAddToCartProductData
} from 'helpers/googleAnalytic'
import { isSSR } from 'helpers/isSSR'
import { extractQuickAppointmentConfirmationData } from './helpers'
import { PROMO_CREATIVE } from './constants'
import { getOeItemIdsList } from '../../components/OneColumnSearchResultPage/selectors'
import { getEvSkuIdsList } from '../../components/common/hoc/withAddToQuote/selectors'

/* global dataLayer */

if (isSSR && !global.dataLayer) {
  global.dataLayer = []
}

export default store => next => action => {
  try {
    if (isSSR && global.dataLayer.length) {
      global.dataLayer = []
    }

    if (action.type === String(googleAnalyticProductImpressions)) {
      dataLayer.push({
        event: 'product-impression',
        ecommerce: {
          impressions: [
            ...action.payload.data
          ]
        }
      })
    }

    if (action.type === String(googleAnalyticEmailAddressFill)) {
      dataLayer.push({
        event: 'emailCapture',
        userSignIn: false,
        isFormSubmission: false,
        isPromoOptIn: false,
        firstName: null,
        lastName: null,
        ...action.payload
      })
    }

    if (action.type === String(googleAnalyticProductClicks)) {
      const source = action.payload.list

      dataLayer.push({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { click: source },
            products: Array.isArray(action.payload.data) ?
              [...action.payload.data] :
              [{ ...action.payload.data }]
          }
        }
      })

      store.dispatch(setSource({ source }))
    }

    if (action.type === String(googleAnalyticProductDetailsView)) {
      const state = store.getState()
      const source = state.skuId.source

      dataLayer.push({
        event: 'product-detail-impression',
        ecommerce: {
          detail: {
            actionField: source,
            products: Array.isArray(action.payload.data) ?
              [...action.payload.data] :
              [{ ...action.payload.data }]
          }
        }
      })
    }

    if (action.type === String(googleAnalyticProductAddToCart)) {
      const source = action.payload.list
      const {
        rawProducts,
        label,
        dimension2,
        dimension28,
        dimension30,
        dimension31
      } = getAddToCartProductData(store, action.payload.product)
      const addAttribute = {
        products: rawProducts
          .filter(({ product }) => Boolean(product))
          .map(({ product, quantity }) => getGTMAddToCartProduct({
            product,
            quantity,
            label,
            dimension2,
            dimension28,
            dimension30,
            dimension31
          }))
      }

      dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          add: {
            ...addAttribute,
            actionField: {
              list: source
            }
          }
        }
      })
    }

    if (action.type === String(googleAnalyticProductAddToCartWithoutQuoteModal)) {
      const source = action.payload.list

      dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          add: {
            actionField: {
              list: source
            },
            products: [
              { ...action.payload.data }
            ]
          }
        }
      })
    }

    if (action.type === String(googleAnalyticProductRemoveFromCart)) {
      dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              { ...action.payload.data }
            ]
          }
        }
      })
    }

    if (
      action.type === String(googleAnalyticPromotionImpressions) &&
      action.payload.items &&
      action.payload.items.length
    ) {
      dataLayer.push({
        event: 'promotion-impression',
        ecommerce: {
          promoView: {
            promotions: [
              ...action.payload.items.map((promotion, index) =>
                getGTMPromotionData({
                  promotion,
                  index,
                  creative: action.payload.creative || PROMO_CREATIVE[window.location.pathname]
                })
              )
            ]
          }
        }
      })
    }

    if (action.type === String(googleAnalyticPromotionClicks)) {
      const { item, index, creative } = action.payload

      dataLayer.push({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [
              getGTMPromotionData({
                promotion: item,
                index,
                creative: creative || PROMO_CREATIVE[window.location.pathname]
              })
            ]
          }
        }
      })
    }

    if (action.type === String(googleAnalyticCheckout)) {
      const state = store.getState()
      const {
        products = [],
        services = [],
        installationKits = [],
        promotions = []
      } = getQuoteInfo(state)
      const gBBDetails = getGBBDetails(state)
      const oeItems = getOeItemIdsList(state)
      const evItems = getEvSkuIdsList(state)

      dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: document.location.href },
            products: [
              ...products.map(product => getGTMProductData({ product, gBBDetails, oeItems, evItems })),
              ...getGTMOTDPData(products),
              ...services.map(service => getGTMServiceData({ service })),
              ...installationKits.map(getGTMKitData),
              ...promotions.map(getGTMPromotionAsProductData)
            ]
          }
        }
      })
    }

    if (action.type === String(googleAnalyticPurchase)) {
      const { payload: purchaseData } = action
      const state = store.getState()
      const gBBDetails = getGBBDetails(state)
      const oeItems = getOeItemIdsList(state)
      const evItems = getEvSkuIdsList(state)
      const storeNumber = getCurrentStoreNumber(state)
      const {
        products = [],
        services = [],
        installationKits = [],
        tax,
        orderId: id,
        revenue,
        promotions = [],
        shopFees
      } = purchaseData ? extractQuickAppointmentConfirmationData(purchaseData) : getQuoteInfo(state)

      dataLayer.push({
        event: 'confirmation',
        ecommerce: {
          purchase: {
            actionField: {
              id,
              affiliation: `Store #${storeNumber}`,
              revenue,
              tax,
              ...(shopFees && { shopFees })
            },
            products: [
              ...installationKits.map(getGTMKitData),
              ...products.map(product => getGTMProductData({ product, gBBDetails, oeItems, evItems })),
              ...getGTMOTDPData(products),
              ...services.map(service =>
                getGTMServiceData({ service, category: purchaseData && 'Quick Appointment' })),
              ...promotions.map(getGTMPromotionAsProductData)
            ]
          }
        }
      })
    }

    if (action.type === String(googleAnalyticStoreNumberPush)) {
      const {
        payload: {
          store: {
            storeNumber = null
          } = {}
        } = {}
      } = action

      dataLayer.push({
        event: 'storeNumberPush',
        dlvStoreNumber: storeNumber
      })
    }

    if (action.type === String(googleAnalyticQuickStepperActionBarClick)) {
      const { payload: { apptStepper = null } = {}} = action

      apptStepper && dataLayer.push({
        event: 'apptStepper',
        apptStepper: `${apptStepper}`
      })
    }

    if (action.type === String(googleAnalytic3StepperActionBarClick)) {
      const { payload: { threeStepper = null } = {}} = action

      threeStepper && dataLayer.push({
        event: 'threeStepper',
        threeStepper: `${threeStepper}`
      })
    }

    if (action.type === String(googleAnalyticZeroTireSearchResults)) {
      dataLayer.push({ event: 'noresults', noresults: 'true' })
    }

    if (action.type === String(googleAnalyticQuickApptSubmissionError)) {
      dataLayer.push({ event: 'quickApptSubmissionError', quickApptSubmissionError: true })
    }

    if (action.type === String(googleAnalyticDatepickerTomorrowSelected)) {
      dataLayer.push({
        event: 'appointmentTimesRendered',
        ...action.payload
      })
    }

    if (action.type === String(googleAnalyticDatepickerTodaysSelected)) {
      dataLayer.push({
        event: 'sameDayAppointmentTimesRendered',
        ...action.payload
      })
    }
  } catch (err) {
    console.log('Error occurred while Google Analytic event dispatch', err)
  }

  return next(action)
}
