export const CHANGED_AVAILABILITY_PRICE_MODAL = 'changedAvailabilityPriceModal'
export const AVAILABILITY_PRICE_CHANGED = 'Availability & Price Change'
export const PRICE_CHANGED = 'Price Change'
export const AVAILABILITY_CHANGED = 'Availability Change'
export const DESCRIPTION = 'Looks like the tire(s) you had previously added to your shopping cart has new'
export const CHANGE = 'change'
export const AVAILABILITY_PRICE = 'availability and price'
export const AVAILABILITY = 'availability'
export const PRICE = 'price'
export const NEW_PRICE = 'New Price'
export const NEW_AVAILABILITY = 'New Availability'
export const EACH = '/each'
export const CALL_TEXT = 'Call store for price'
