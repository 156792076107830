export const YEARS = 'years'
export const MAKES = 'makes'
export const MODELS = 'models'
export const SUB_MODELS = 'subModels'

// model fields

export const FIELDS = 'fields'
export const ERROR = 'error'

// validation messages
export const YEAR_EMPTY = 'Please enter your vehicle\'s Year.'
export const MAKE_EMPTY = 'Please enter your vehicle\'s Make.'
export const MODEL_EMPTY = 'Please enter your vehicle\'s Model.'
export const SUB_MODEL_EMPTY = 'Please enter your vehicle\'s Submodel.'
