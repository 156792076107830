import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Link from 'components/common/Link/Link'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import { dynamicDataAttribute, toOneString, injectProps, setLinkDataAttrVal } from 'helpers/utils'
import MenuItemWithSubmenu from './MenuItemWithSubmenu'
import { MenuItem, MenuTitle, MenuTitleText } from './SharedStyles'

const HeaderMenuLink = ({
  mobileMainMenuExpanded,
  mobileMainMenuToggleHandlers,
  isSubmenu,
  isInnerFocusedHovered,
  index,
  name,
  combinedRefSetter,
  focusBlurHoverHandlers,
  handleClick,
  link: {
    path = '/'
  } = {},
  placeholders: {
    links = []
  } = {}
}) =>
  <Wrapper id={ `menu-item-${index}` } { ...dynamicDataAttribute('header-menu-item', toOneString(name)) }>
    <Media.Desktop>
      <NavItem
        submenu={ isSubmenu }
        ref={ combinedRefSetter }
        isInnerFocusedHovered={ isInnerFocusedHovered }>
        <NavLink
          id={ `primary-menu-${index}` }
          data-at-header-menu-itemname
          menu="true"
          narrow
          to={ path }
          submenu={ isSubmenu }
          { ...focusBlurHoverHandlers } >
          { name }
          { isSubmenu &&
            <Icon
              asset={ `arrow-icon-full-white-${isInnerFocusedHovered ? 'up' : 'down'}` }
              margin="0 0 0 5px"
              alt=""
              width={ 10 }
              height={ 8 } />
          }
        </NavLink>
        {
          React.Children.count(links) > 0 &&
            <SubNav
              id={ `menu-item-${index}-subnav` }
              isOpen={ isInnerFocusedHovered }
              role="region"
              aria-expanded={ isInnerFocusedHovered }
              aria-hidden={ !isInnerFocusedHovered }
              aria-labelledby={ `primary-menu-${index}` } >
              {
                React.Children.map(links, (link, snIndex) =>
                  <SubItem
                    id={ `menu-item-${index}-subnav-item-${snIndex}` }
                    data-at-header-menu-subnavitem
                    { ...focusBlurHoverHandlers }
                    { ...dynamicDataAttribute('subnav-index', snIndex) }>
                    { injectProps(link, { ...dynamicDataAttribute('sub-nav-item-link', setLinkDataAttrVal(link)) }) }
                  </SubItem>
                )
              }
            </SubNav>
        }
      </NavItem>
    </Media.Desktop>

    <Media.Mobile>
      {
        isSubmenu ?
          <MenuItemWithSubmenu
            mobileMainMenuExpanded={ mobileMainMenuExpanded }
            mobileMainMenuToggleHandlers={ mobileMainMenuToggleHandlers }
            links={ links }
            name={ name } /> :
          <MenuItem hidden={ mobileMainMenuExpanded }>
            <MenuTitle>
              <Link to={ path } data-at-menu-title-link onClick={ handleClick }>
                <MenuTitleText narrow>{ name }</MenuTitleText>
              </Link>
            </MenuTitle>
          </MenuItem>
      }
    </Media.Mobile>
  </Wrapper>

HeaderMenuLink.propTypes = {
  combinedRefSetter: PropTypes.func,
  focusBlurHoverHandlers: PropTypes.shape({
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  }),
  handleClick: PropTypes.func,
  index: PropTypes.number,
  isInnerFocusedHovered: PropTypes.bool,
  isSubmenu: PropTypes.bool,
  link: PropTypes.shape({
    path: PropTypes.string
  }),
  mobileMainMenuExpanded: PropTypes.bool,
  mobileMainMenuToggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.element)
  }),
  setBlockRef: PropTypes.func
}

export default HeaderMenuLink

const Wrapper = styled.li`
  display: inline-block;
  ${Media.mobile`
    display: block;
  `}
`

const NavItem = styled.div`
  margin-right: 7px;
  padding: 10px 19px 7px;
  ${props => props.submenu && css`
    position: relative;
    z-index: 20;

    ${props => props.isInnerFocusedHovered && css`
      box-shadow: 0 2px 8px ${Theme.colors.transparent_grey_hover};
      background: ${Theme.colors.crimson};
    `}
  `}

  @media (max-width: 1300px) {
    padding: 10px 4px 7px;
  }
`

const NavLink = styled(({ submenu, ...rest }) => <Link { ...rest } />)`
  display: block;
  color: ${Theme.colors.white};

  :hover {
    text-decoration: none;
  }

  ${props => props.submenu && css`
    position: relative;
    z-index: 40;
  `}
`

const SubNav = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none;
  width: auto;
  padding: 0;
  margin-top: -1px;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  white-space: nowrap;
  background: ${Theme.colors.crimson};
  box-shadow: 0 5px 8px ${Theme.colors.transparent_grey_hover};

  ::before {
    content: '';
    position: absolute;
    width: 40%;
    height: 25px;
    margin-top: -5px;
  }

  ${props => props.isOpen && css`
    display: block;
  `}
`

const SubItem = styled.li`
  min-width: 200px;
  padding: 6px 0 6px 20px;
  list-style-type: none;
  border-bottom: 1px solid ${Theme.colors.light_grey};

  &:last-child {
    border-bottom: none;
  }

  & ${Link} {
    color: ${Theme.colors.white};
    ${Mixin.font.GothamBook(16)}
  }
`
