import { compose, withProps, withHandlers } from 'recompose'
import withFilteredHoverHandlers from 'components/common/hoc/withFilteredHoverHandlers'
import withHeaderHorizontalNav from 'components/common/hoc/withHeaderHorizontalNav'
import withCombinedActiveStatesAndHandlers from 'components/common/hoc/withCombinedActiveStatesAndHandlers'
import active from 'components/common/hoc/active'
import { focusCurrentItem, getFocusedSubItemIndex, focusNextOrPrevItem } from 'components/common/hoc/withHeaderHorizontalNav/helpers'
import { focusSubmenuItem, focusLastSubmenuItem } from './helpers'
import HeaderDropDownItem from './HeaderDropDownItem'

const withSubmenuFlag = withProps(({ links = []}) => ({
  isSubmenu: links.length > 0
}))

const withNavigationAndEscapeHandlers = withHandlers({
  escape: ({ setFocusHoverState, isSubmenu }) => (e) =>
    isSubmenu && focusCurrentItem({ e, setFocusHoverState }),
  down: ({
    isSubmenu,
    links,
    nextItem,
    active,
    activate
  }) => (e) => {
    e.preventDefault()
    if (isSubmenu) {
      !active && activate()
      const nextSubItemIndex = getFocusedSubItemIndex(e) + 1
      if (nextSubItemIndex < links.length) {
        focusSubmenuItem({ e, index: nextSubItemIndex })
      } else {
        focusNextOrPrevItem({ e, name: nextItem, openSubmenu: true })
      }
    }
  },
  up: ({ isSubmenu, prevItem, deactivate }) => (e) => {
    e.preventDefault()
    if (isSubmenu) {
      const prevSubItemIndex = getFocusedSubItemIndex(e) - 1
      if (prevSubItemIndex >= 0) {
        focusSubmenuItem({ e, index: prevSubItemIndex })
      } else if (prevSubItemIndex === -1) {
        focusCurrentItem({ e })
      } else {
        focusLastSubmenuItem({ e, prevItem, deactivate })
      }
    }
  }
})

export default compose(
  active,
  withFilteredHoverHandlers,
  withCombinedActiveStatesAndHandlers,
  withSubmenuFlag,
  withNavigationAndEscapeHandlers,
  withHeaderHorizontalNav
)(HeaderDropDownItem)
