export default {
  login: {
    url: '/currentUser/tbcLogin',
    method: 'post'
  },

  logout: {
    url: '/currentUser/tbcLogout',
    method: 'post'
  },

  registration: {
    url: '/currentUser/registration',
    method: 'post'
  },

  resetPasswordEmail: {
    url: '/currentUser/resetPasswordEmail',
    method: 'post'
  },

  resetPassword: {
    url: '/currentUser/resetPassword',
    method: 'post'
  },

  cancelResetPassword: {
    url: '/currentUser/cancelResetPassword',
    method: 'post'
  },

  currentUser: {
    url: '/currentUser',
    method: 'patch'
  },

  updateCommunicationPreferences: {
    url: '/currentUser/updateUserPreferences',
    method: 'post'
  },

  updateEmail: {
    url: '/currentUser/updateEmail',
    method: 'post'
  },

  updatePassword: {
    url: '/currentUser/updatePassword',
    method: 'post'
  },

  addStoreToProfile: {
    url: '/currentUser/addStoreToProfile',
    method: 'post'
  },

  emailSignUp: {
    url: '/currentUser/emailSignup',
    method: 'post'
  },

  updateNickname: {
    url: '/installer/updateNickname',
    method: 'patch'
  },

  removeInstaller: {
    url: '/installer/removeInstaller',
    method: 'delete'
  },

  getStates: {
    url: '/garage/states',
    method: 'get'
  },

  getYears: {
    url: '/garage/years',
    method: 'get'
  },

  getMakes: {
    url: '/garage/makes/:year',
    method: 'get'
  },

  getModels: {
    url: '/garage/years/:makeId/models/:year',
    method: 'get'
  },

  getSubModels: {
    url: '/garage/years/:makeId/models/:year/subModels/:modelId',
    method: 'get'
  },

  getSimilarVehicles: {
    url: '/currentUser/getSimilarVehicles',
    method: 'get'
  },

  addGenericVehicle: {
    url: '/garage/genericVehicle',
    method: 'post'
  },

  addVehicle: {
    url: '/garage',
    method: 'post'
  },

  removeVehicle: {
    url: '/garage',
    method: 'delete'
  },

  updateVehicle: {
    url: '/garage',
    method: 'patch'
  },

  mergeVehicle: {
    url: '/currentUser/vehicleAndOrderMerge',
    method: 'post'
  },

  makeActiveVehicle: {
    url: '/garage/makeActiveVehicle',
    method: 'post'
  },

  customTireSize: {
    url: '/garage/customTireSizes',
    method: 'post'
  },

  tireSizes: {
    url: '/garage/tireSizes/:vehicleId',
    method: 'get'
  },

  getVehicleByPlateNumber: {
    url: '/garage/vehicleByLicencePlateNumber',
    method: 'post'
  },

  findServiceHistory: {
    url: '/currentUser/linkToCarfax',
    method: 'post'
  },

  findServiceHistoryByLicensePlate: {
    url: '/garage/serviceHistoryByLicensePlate',
    method: 'post'
  },

  findServiceHistoryByVin: {
    url: '/garage/serviceHistory',
    method: 'post'
  },

  getMyStore: {
    url: '/store/myStore',
    method: 'get'
  },

  getStoreToProfileByIp: {
    url: '/store/storeToProfileByIp',
    method: 'get'
  },

  storesByLatitudeAndLongitude: {
    url: '/store/storesByLatitudeAndLongitude',
    method: 'post'
  },

  storesByAddress: {
    url: '/store/storesByAddress',
    method: 'post'
  },

  sharePageViaEmail: {
    url: '/currentUser/triggerEmail',
    method: 'post'
  },

  recentlyViewedItems: {
    url: '/catalog/recentlyViewedItems',
    method: 'post'
  },

  getGBBDetails: {
    url: '/catalog/gbbDetails',
    method: 'post'
  },

  changeAppointmentContactDetails: {
    url: '/currentUser/onlineAppointmentContactDetails',
    method: 'post'
  },

  confirmSinglePageQuoteAppointment: {
    url: '/checkout/confirmAppointment',
    method: 'post'
  },

  confirmAppointment: {
    url: '/checkout/bookOnlineAppointment',
    method: 'post'
  },

  checkout: {
    url: '/cart/checkout',
    method: 'post'
  },

  getPromotionLightBox: {
    url: '/cart/promotionLightBox',
    method: 'post'
  },

  addProductToCart: {
    url: '/cart/commerceItems',
    method: 'post'
  },

  addServices: {
    url: '/checkout/proceedToStepTwo',
    method: 'post'
  },

  addDateAndTime: {
    url: '/checkout/proceedToStepThree',
    method: 'post'
  },

  getSearchSuggestions: {
    url: '/search',
    method: 'get'
  },

  submitLowerPriceForm: {
    url: '/catalog/submitLowerPriceForm',
    method: 'post'
  },

  cartDetail: {
    url: '/cart/cartDetail',
    method: 'post'
  },

  miniCart: {
    url: '/cart/miniCart',
    method: 'get'
  },

  removeFromCart: {
    url: '/cart/commerceItems',
    method: 'delete'
  },

  updateProduct: {
    url: '/cart',
    method: 'patch'
  },

  applyCoupon: {
    url: '/cart/applyCoupon',
    method: 'post'
  },

  removeCoupon: {
    url: '/cart/removeCoupon',
    method: 'post'
  },

  contactUs: {
    url: '/currentUser/contactUs',
    method: 'post'
  },

  fleetServicesContactUs: {
    url: '/currentUser/contactUs?pushSite=bigotires',
    method: 'post'
  },

  addAppointmentDetailsToOrder: {
    url: '/checkout/addAppointmentDetailsToOrder',
    method: 'post'
  },

  clearCart: {
    url: '/cart/clearCart',
    method: 'post'
  },

  getTAOSAppointmentDatesAndSlots: {
    url: '/checkout/appointmentDatesAndSlots',
    method: 'post'
  },

  getServiceCategoryDetails: {
    url: '/catalog/categoryDetails',
    method: 'post'
  },

  getStaticContentDetails: {
    url: '/catalog/contentDetails',
    method: 'post'
  },

  getMarketingContent: {
    url: '/catalog/tire/:type/marketingContent/:id',
    method: 'get'
  },

  addSaveForLaterItem: {
    url: '/cart/addSavedItems',
    method: 'post'
  },

  getSaveForLaterItems: {
    url: '/cart/fetchSavedItems',
    method: 'post'
  },

  removeSaveForLaterItems: {
    url: '/cart/removeSavedItems',
    method: 'delete'
  },

  validateAppliedPromoExpDate: {
    url: '/cart/validateAppliedPromoExpDate',
    method: 'post'
  },

  removePromo: {
    url: '/cart/removePromo',
    method: 'post'
  },

  getFavoriteItemIds: {
    url: '/catalog/favoriteItemIds',
    method: 'get'
  },

  getFavoriteItemsWithDetails: {
    url: '/catalog/favoriteItemDetails',
    method: 'get'
  },

  addFavoriteItem: {
    url: '/catalog/addFavoriteItem',
    method: 'post'
  },

  deleteFavoriteItem: {
    url: '/catalog/:skuId/favoriteItem',
    method: 'delete'
  },

  updateVehiclePersonalDetails: {
    url: '/garage/updateVehcilePersonalDetails',
    method: 'post'
  },

  addServiceRecord: {
    url: '/garage/addVehcileServiceDetails',
    method: 'post'
  },

  deleteServiceRecord: {
    url: '/garage/deleteVehcileServiceDetails',
    method: 'post'
  },

  editServiceRecord: {
    url: '/garage/updateVehcileServiceDetails',
    method: 'post'
  },
  getMileageDetails: {
    url: '/garage/getMileageDetails',
    method: 'post'
  },

  getVehicleRecommendedServices: {
    url: '/garage/vehicleRecommendedService',
    method: 'post'
  },

  getManuallyAddedServiceDetails: {
    url: '/garage/getManuallyAddedServiceDetails',
    method: 'get'
  },

  addOrRemoveServicePromotion: {
    url: '/checkout/addOrRemoveServicePromotion',
    method: 'post'
  },

  checkServicePromoExclusiveFromStore: {
    url: '/checkout/checkServicePromoExclusiveFromStore',
    method: 'post'
  }
}
