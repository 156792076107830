import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Banner from 'components/common/Banner/Banner'
import Link from 'components/common/Link/Link'
import { GARAGE_PAGE } from 'constant'

const VehicleAndDataMismatchError = ({ error, iaVehicleAndDataMismatchError, handleMyGarageClick }) =>
  <ErrorMessage error_alternative>
    {
      iaVehicleAndDataMismatchError ?
        <Fragment>
          Entered data and selected vehicle do not match.{ ' ' }
          Please check your vehicle in <Link to={ GARAGE_PAGE } onClick={ handleMyGarageClick }>my garage</Link>
          { ' ' }or License Plate Number or VIN
        </Fragment> :
        error
    }
  </ErrorMessage>

VehicleAndDataMismatchError.propTypes = {
  error: PropTypes.string,
  handleMyGarageClick: PropTypes.func,
  iaVehicleAndDataMismatchError: PropTypes.bool
}

export default VehicleAndDataMismatchError


const ErrorMessage = styled(Banner)`
  margin-bottom: 15px;
  font-size: 14px;
  ${Mixin.responsive('padding', '5px 30px', '5px 0 5px 30px')}
`

