import { Validator } from 'services/validator'
import { YEAR_EMPTY, MAKE_EMPTY, MODEL_EMPTY, SUB_MODEL_EMPTY } from './constants'

export default Validator.combineValidators({
  years: {
    validators: Validator.required(),
    errorMessage: YEAR_EMPTY
  },
  makes: {
    validators: Validator.required(),
    errorMessage: MAKE_EMPTY
  },
  models: {
    validators: Validator.required(),
    errorMessage: MODEL_EMPTY
  },
  subModels: {
    validators: Validator.required(),
    errorMessage: SUB_MODEL_EMPTY
  }
})
