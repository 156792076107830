import React from 'react'
import ShopByVehicleModal from '../ShopByVehicleModal'
import ShopByPlateModal from '../ShopByPlateModal'
import Buttons from '../Buttons'

/* eslint-disable react/prop-types */
const YMMEPlateWrapper = ({ expanded, toggleHandlers, ...props }) =>
  <React.Fragment>
    {
      expanded ?
        <ShopByPlateModal { ...props } toggle={ toggleHandlers.onClick }>
          <Buttons onCancel={ props.handleClose } />
        </ShopByPlateModal> :
        <ShopByVehicleModal { ...props } toggle={ toggleHandlers.onClick }>
          <Buttons onCancel={ props.handleClose } />
        </ShopByVehicleModal>
    }
  </React.Fragment>

export default YMMEPlateWrapper
