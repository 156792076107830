import { createAction } from 'redux-actions'
import { rest } from 'services'
import getShoppingErrorMessages from './getShoppingErrorMessages'
import { loadFavoriteSkus } from 'components/MyFavorites/actions'

export const loadShoppingData = createAction('SHOPPING_DATA/LOAD_DATA')

export const setShoppingData = createAction('SHOPPING_DATA/SET_DATA')

export const setFreeAlignmentData = createAction('SHOPPING_DATA/SET_FREE_ALIGNMENT_DATA')

export const cleanShoppingData = createAction('SHOPPING_DATA/CLEAN_SHOPPING_DATA')

export const cleanShoppingErrors = () => setShoppingData({ shoppingErrors: []})

export const setAvailabilityData = createAction('SHOPPING_DATA/SET_AVAILABILITY_DATA')

export const setCalendarStartDateForAddedSkus = createAction('SHOPPING_DATA/SET_CALENDAR_START_DATE_FOR_ADDED_SKUS')

export const setCalendarStartDateToDefaultValue = createAction('SHOPPING_DATA/SET_CALENDAR_START_DATE_TO_DEFAULT_VALUE')

export const setCalendarStartDateForRemovedSkus = createAction('SHOPPING_DATA/SET_CALENDAR_START_DATE_FOR_REMOVED_SKUS')

export const resetCalendarStartDate = createAction('SHOPPING_DATA/RESET_CALENDAR_START_DATE')

export const handleShoppingError = err =>
  setShoppingData({
    ...err.responseObject,
    shoppingErrors: getShoppingErrorMessages(err)
  })

export const getShoppingData = (apiRequest, requestData = {}) => (dispatch, getState) => {
  const {
    shoppingData: {
      _state
    }
  } = getState()


  dispatch(cleanShoppingErrors())

  return apiRequest(_state ? { _state, ...requestData } : requestData)
    .then(data => {
      dispatch(setShoppingData(data))
      return data
    })
    .catch(err => {
      dispatch(handleShoppingError(err))
      return Promise.reject(err)
    })
}

export const getMiniCartData = () => dispatch =>
  rest.api.miniCart()
    .then(({ miniCart: { totalQuantity } = {}}) => {
      dispatch(setShoppingData({ totalCount: totalQuantity }))
      dispatch(loadFavoriteSkus())
    })

export const hideShoppingError = codeToRemove => (dispatch, getState) => {
  const { shoppingData: { shoppingErrors }} = getState()

  dispatch(setShoppingData({
    shoppingErrors: shoppingErrors.map(error =>
      error.code === codeToRemove ?
        { ...error, visible: false } :
        error
    )
  }))
}
