import { HOME } from 'constant'
import { NAVIGATIONS_UPDATE } from './actions'

const initialState = {
  previous: HOME,
  current: HOME
}

export default (state = initialState, { type, navigation }) =>
  type === NAVIGATIONS_UPDATE ?
    ({
      previous: state.current,
      current: navigation
    }) :
    state
