import { showChangeStoreModal } from 'components/common/StoreChangeModal/actions'
import { ERROR_CODES_LIST_TO_SHOW_ONE_BUTTON_MODAL, ERROR_CODES_LIST_TO_SHOW_TWO_BUTTONS_MODAL } from './constants'

export const handleStoreChangeModalView = (error, haveCurrentStore, dispatch) => {
  const errorCode = error && error.status && error.status.code
  const showOneButtonModal = ERROR_CODES_LIST_TO_SHOW_ONE_BUTTON_MODAL.includes(errorCode)
  const showTwoButtonsModal = ERROR_CODES_LIST_TO_SHOW_TWO_BUTTONS_MODAL.includes(errorCode)

  if (haveCurrentStore) {
    if (showTwoButtonsModal) {
      dispatch(showChangeStoreModal())
    }
  }

  if (!haveCurrentStore) {
    if ((showOneButtonModal && !showTwoButtonsModal) || (showOneButtonModal && showTwoButtonsModal)) {
      dispatch(showChangeStoreModal(showOneButtonModal))
    }
  }
}
