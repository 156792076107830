export const DESKTOP_TYPES = ['desktop', 'tv', 'bot']
export const DESKTOP = 'desktop'
export const HIGH_CONTRAST_CLASS_NAME = 'high-contrast-enabled'

export const MOBILE_EDGE = 1023
export const MOBILE_NARROW_EDGE = 320
export const DESKTOP_NARROW_EDGE = 1279
export const TABLET_SMALL_EDGE = 1080
export const TABLET_NARROW_EDGE = 768
export const PHONE_EDGE = 500
export const MEDIUM_MOBILE = 345

// action types
export const DEVICE_CHANGE = 'DEVICE_CHANGE'

export const TABLET = 'tablet'
export const MOBILE = 'phone'
