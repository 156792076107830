import { handleActions } from 'redux-actions'
import { setBrandData, setFiltersData } from './actions'

const initialState = {
  data: {},
  filters: [],
  filtersMap: {}
}

const handleBrandDataSet = (state, { payload: data }) => ({
  ...state,
  data
})

const handleFiltersDataSet = (state, { payload: filters }) => ({
  ...state,
  filters,
  filtersMap: filters.reduce((acc, { refinements }) => ({
    ...acc,
    ...refinements.reduce((acc, refinement) => ({
      ...acc,
      [refinement.navigationState]: refinement
    }), {})
  }), {})
})

const brandDetails = handleActions({
  [setBrandData]: handleBrandDataSet,
  [setFiltersData]: handleFiltersDataSet
}, initialState)

export default brandDetails
