import { compose } from 'redux'
import { withHandlers } from 'recompose'
import withType from 'components/common/hoc/withType'
import withNavigateToTireSearchOrShowYmme from 'components/common/hoc/withNavigateToTireSearchOrShowYmme'
import withShowConfirmTireSizeModalPreconfigured from 'components/common/hoc/withShowConfirmTireSizeModalPreconfigured'
import ShopAndScheduleTeaser from './ShopAndScheduleTeaser'

const withHandleRequestClick = withHandlers({
  onClick: ({ navigateToTireSearchOrShowYmme, onClick: noTireSizeOnClick }) => e => {
    if (noTireSizeOnClick) {
      noTireSizeOnClick(e)
    } else {
      e.preventDefault()
      navigateToTireSearchOrShowYmme({ searchByTireSizeForTrailer: true })
    }
  }
})

export default compose(
  withType({ type: 'ShopAndScheduleTeaser' }),
  withShowConfirmTireSizeModalPreconfigured,
  withNavigateToTireSearchOrShowYmme,
  withHandleRequestClick
)(ShopAndScheduleTeaser)

