import qs from 'qs'
import { GLOBAL_SEARCH_TIRES_PAGE, GLOBAL_SEARCH_QUERY_KEY } from 'constant'

export const createPath = navigationState => {
  const [pathname, search] = `${GLOBAL_SEARCH_TIRES_PAGE}${navigationState}`.split('?')

  return { pathname, search }
}

export const getDefaultPath = ({ search }) => {
  const { [GLOBAL_SEARCH_QUERY_KEY]: searchQuery } = qs.parse(search.slice(1))

  return createPath(`?${GLOBAL_SEARCH_QUERY_KEY}=${searchQuery}`)
}

export const getClearAllNavigationState = (refinementCrumbs) => refinementCrumbs.length ? refinementCrumbs[0].navigationState : ''
