import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import getComponent from 'helpers/component-helper'

const PromotionLightBoxModal = ({ data: { modalBoxContent, title }, productInfo, handleHide }) =>
  <ModalBox
    title={ title }
    closeHandler={ handleHide }>
    {
      modalBoxContent.map((data, index) =>
        React.cloneElement(getComponent(data), { key: index, productInfo }))
    }
  </ModalBox>

export default PromotionLightBoxModal

PromotionLightBoxModal.propTypes = {
  data: PropTypes.shape({
    modalBoxContent: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string
  }),
  handleHide: PropTypes.func,
  productInfo: PropTypes.shape()
}

const ModalBox = styled(Modal)`
  overflow-y: auto;
  ${Mixin.responsive('padding', '25px 24px 30px', '60px 16px 25px')}
`
