import React from 'react'
import PropTypes from 'prop-types'
import Theme from 'components/common/Theme/Theme'

class KeyPress extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleEscPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscPress)
  }

  handleEscPress = e => {
    if (e.keyCode === Theme.keyCode.ESCAPE) {
      this.props.escape && this.props.escape()
    }
    if (e.keyCode === Theme.keyCode.ENTER) {
      this.props.enter && this.props.enter()
    }
  }

  render() {
    return this.props.children
  }
}

KeyPress.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  enter: PropTypes.func,
  escape: PropTypes.func
}

export default KeyPress
