import { handleActions } from 'redux-actions'
import {
  setPromotionData,
  setFiltersData,
  setSortOptionsData,
  setIsAllCompatible,
  addToProductCompare,
  clearCompareItems,
  showCompareTray,
  toggleCompareTray,
  setCustomPriceRangeData,
  resetCustomPriceRangeData,
  setShowNoEligibleTiresForPromo,
  setOeItemIdsData,
  addOeItemData,
  setOeFilterItemsData,
  setOeFilter
} from './actions'
import { removeFromProductCompare } from 'actions/removeFromProductCompare'

import { OE_CAME_WITH_VEHICLE } from './constants'

const initialState = {
  BOTSearchPromotion: {},
  filtersData: {
    navigation: [],
    selectedFilters: []
  },
  customPriceRange: {},
  sortOptions: [],
  isAllCompatible: false,
  compareItems: [],
  showCompareTray: false,
  showNoEligibleTiresForPromo: false,
  oeItemIdsList: [],
  oeFilterItems: []
}

const handleCompareTrayProductAdd = ({ compareItems, ...state }, { payload: item }) => {
  const isStaggered = compareItems.some(({ isStaggered }) => isStaggered)

  return {
    ...state,
    showCompareTray: true,
    compareItems: isStaggered === item.isStaggered ?
      [...compareItems, item] :
      [item]
  }
}

const handleCompareTrayProductRemove = ({ compareItems, ...state }, { payload: productSkuId }) => {
  const items = compareItems.filter(({ skuId }) => skuId !== productSkuId)

  return {
    ...state,
    compareItems: items,
    showCompareTray: items.length !== 0
  }
}

const handleCompareTrayShow = state => ({
  ...state,
  showCompareTray: true
})

const handleCompareItems = state => ({
  ...state,
  showCompareTray: false,
  compareItems: []
})

const handleCompareTrayToggle = ({ showCompareTray, ...state }) => ({
  ...state,
  showCompareTray: !showCompareTray
})

const handleFilterDataSet = (state, { payload: { navigation = []}}) => ({
  ...state,
  oeFilterMode: false,
  filtersData: {
    navigation: navigation.map(({ refinements = [], ...filterGroup }, groupIndex) => ({
      ...filterGroup,
      refinements: refinements.map(filter => ({
        ...filter,
        groupIndex
      }))
    })),

    selectedFilters: navigation.reduce((acc, curr) => {
      if (curr.refinements) {
        return [...acc, ...curr.refinements.filter(({ selected }) => selected)]
      }

      return acc
    }, [])
  }
})

const handleCustomPriceRangeDataSet = (state, { payload: { min, max, count } = {}}) => ({
  ...state,
  customPriceRange: {
    min,
    max,
    count
  }
})

const handleCustomPriceRangeDataReset = state => ({
  ...state,
  customPriceRange: { ...initialState.customPriceRange }
})

const handleSetOeFilter = (state, { payload }) => {
  const { filtersData, oeFilterItems } = state
  const oeFilter = [{ label: `${OE_CAME_WITH_VEHICLE} (${oeFilterItems.length})`, navigationState: '', selected: true }]
  const currentSelectedFilters = filtersData.selectedFilters

  return {
    ...state,
    filtersData: {
      ...filtersData,
      selectedFilters: payload ? oeFilter : currentSelectedFilters
    }
  }
}

const searchResultPage = handleActions({
  [setPromotionData]: (state, { payload }) => ({
    ...state,
    BOTSearchPromotion: payload
  }),
  [setOeItemIdsData]: (state, { payload }) => ({
    ...state,
    oeItemIdsList: payload
  }),
  [addOeItemData]: (state, { payload }) => ({
    ...state,
    oeItemIdsList: [...state.oeItemIdsList, payload]
  }),
  [setOeFilterItemsData]: (state, { payload }) => ({
    ...state,
    oeFilterItems: payload
  }),
  [setFiltersData]: handleFilterDataSet,
  [setSortOptionsData]: (state, { payload }) => ({
    ...state,
    sortOptions: payload
  }),
  [setIsAllCompatible]: (state, { payload }) => ({
    ...state,
    isAllCompatible: payload
  }),
  [setShowNoEligibleTiresForPromo]: (state, { payload }) => ({
    ...state,
    showNoEligibleTiresForPromo: payload
  }),
  [addToProductCompare]: handleCompareTrayProductAdd,
  [removeFromProductCompare]: handleCompareTrayProductRemove,
  [showCompareTray]: handleCompareTrayShow,
  [clearCompareItems]: handleCompareItems,
  [toggleCompareTray]: handleCompareTrayToggle,
  [setCustomPriceRangeData]: handleCustomPriceRangeDataSet,
  [resetCustomPriceRangeData]: handleCustomPriceRangeDataReset,
  [setOeFilter]: handleSetOeFilter
}, initialState)

export default searchResultPage
