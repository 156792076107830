import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import AddEditButtons from './AddEditButtons'

const mapDispatchToProps = (dispatch, {
  actionLinks: [
    { link: { path: editButtonPath = '' }} = {}
  ] = []
}) => ({
  handleEdit() {
    dispatch(push(editButtonPath))
  }
})

export default connect(null, mapDispatchToProps)(AddEditButtons)
