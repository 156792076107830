import { createAction } from 'redux-actions'
import { show } from 'redux-modal'
import { rest } from 'services'
import { getCurrentStore } from 'selectors'
import { setUpdatingBlock } from 'components/common/UpdatingBlock/actions'
import { ADDING_TO_SHOPPING_QUOTE } from 'components/common/UpdatingBlock/constants'
import { addProductToCart } from '../withAddToQuote/actions'
import { PROMOTION_LIGHT_BOX_MODAL } from 'constant'

export const setPromotionsData = createAction('ADD_TO_CART/SET_PROMOTIONS_DATA')

export const getPromotionLightBox = ({
  rearSkuId,
  staggeredSkuId,
  rearSKUQuantity,
  ...props
}) => (dispatch, getState) => {
  const state = getState()
  const addToQuoteProductData = (rearSkuId && rearSKUQuantity) ?
    { rearSkuId, rearSKUQuantity, staggeredSkuId, ...props } : props

  dispatch(setUpdatingBlock({
    name: ADDING_TO_SHOPPING_QUOTE,
    value: Array(addToQuoteProductData.promoId).join(',') ||
      addToQuoteProductData.staggeredSkuId || addToQuoteProductData.skuId
  }))

  // TODO now we request promotionLightbox for skuId, however what if we have staggered fitment item?
  rest.api.getPromotionLightBox({ requiredQty: props.quantity, skuId: props.skuId })
    .then(({ lightBoxPromotionVo }) => {
      if (lightBoxPromotionVo && getCurrentStore(state)) {
        dispatch(setPromotionsData(lightBoxPromotionVo))
        dispatch(show(PROMOTION_LIGHT_BOX_MODAL, { productInfo: addToQuoteProductData }))
        dispatch(setUpdatingBlock({ name: ADDING_TO_SHOPPING_QUOTE, value: null }))
      } else {
        dispatch(addProductToCart(addToQuoteProductData))
      }
    })
    .catch(() => dispatch(addProductToCart(addToQuoteProductData)))
}
