import { show, hide } from 'redux-modal'
import { isApiError, rest } from 'services'
import { isErrorDescription } from 'helpers/utils'
import {
  SERVICE_RECORD_MODAL,
  CONNECT_TO_CARFAX_MODAL,
  RECORD_DELETE_MODAL,
  SERVICE_MODAL_CONTENT
} from './constants'
import { triggerNotification } from 'components/common/Notification/helpers'
import { serviceRecordApiMismatchErrorHandler, serviceRecordApiFutureErrorHandler } from 'components/ServiceHistory/helpers'
import { SERVICE_HISTORY_PAGE, VEHICLE_DETAILS_PAGE } from 'constant'

export const showServiceRecordModal = ({ type, record, setRecordData, recordData }) =>
  show(SERVICE_RECORD_MODAL, {
    service: SERVICE_MODAL_CONTENT[type],
    type,
    record,
    setRecordData,
    recordData
  })

export const hideAddServiceRecordModal = () => hide(SERVICE_RECORD_MODAL)

export const showConnectToCarfaxModal = () => show(CONNECT_TO_CARFAX_MODAL)

export const hideConnectToCarfaxModal = () => hide(CONNECT_TO_CARFAX_MODAL)

export const showRecordDeleteModal = ({ setRecordData, serviceId }) =>
  show(RECORD_DELETE_MODAL, { serviceId, setRecordData })


export const addServiceRecord = async ({
  setRecordData,
  payload,
  history,
  handleHide,
  setServiceRecordApiMismatchError,
  setServiceRecordApiFutureErrorHandler
}) => {
  try {
    const result = await rest.api.addServiceRecord(payload)

    if (window.location.pathname.startsWith(VEHICLE_DETAILS_PAGE)) {
      localStorage.removeItem('addServiceRecordFromVehicleDetailsShowed')
      history.push(`${SERVICE_HISTORY_PAGE}?wasVehicleDetails`)
    } else {
      triggerNotification()
      setRecordData(result.manuallyAddedServices || [])
    }
    handleHide()
  } catch (err) {
    if (isApiError(err)) {
      if (isErrorDescription({ err, description: 'error_service_record_data_mismatch' })) {
        serviceRecordApiMismatchErrorHandler({ setServiceRecordApiMismatchError })
      }
      if (isErrorDescription({ err, description: 'Slected Service Date is Future Date' })) {
        serviceRecordApiFutureErrorHandler({ setServiceRecordApiFutureErrorHandler })
      }
      console.error(err)
    }
  }
}

export const editServiceRecord = async ({
  setRecordData,
  payload,
  setServiceRecordApiMismatchError,
  setServiceRecordApiFutureErrorHandler,
  handleHide
}) => {
  try {
    const result = await rest.api.editServiceRecord(payload)
    triggerNotification()
    setRecordData(result.manuallyAddedServices)
    handleHide()
  } catch (err) {
    if (isApiError(err)) {
      if (isErrorDescription({ err, description: 'error_service_record_data_mismatch' })) {
        serviceRecordApiMismatchErrorHandler({ setServiceRecordApiMismatchError })
      }
      if (isErrorDescription({ err, description: 'Slected Service Date is Future Date' })) {
        serviceRecordApiFutureErrorHandler({ setServiceRecordApiFutureErrorHandler })
      }
      console.error(err)
    }
  }
}

export const deleteServiceRecord = ({ payload, setRecordData }) => async () => {
  try {
    const result = await rest.api.deleteServiceRecord(payload)
    triggerNotification()
    setRecordData(result.manuallyAddedServices || [])
  } catch (error) {
    if (isApiError(error)) {
      console.error(error)
    }
  }
}
