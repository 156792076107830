import { handleActions } from 'redux-actions'
import {
  storeLocatorModalInit,
  storeLocatorModalComplete,
  storeLocatorModalReset,
  pilotStoreDataLoading,
  setPilotStoreInfo
} from 'actions/storeLocator'

const initialState = {
  initialization: false,
  initialized: false,
  isPilotStoreDataLoading: false,
  isPilotStore: false
}

const handleStoreLocatorInit = state => ({
  ...state,
  initialization: true,
  initialized: false
})

const handleStoreLocatorComplete = state => ({
  ...state,
  initialization: false,
  initialized: true
})

const handleStoreLocatorReset = () => ({
  ...initialState
})

const handlePilotStoreDataLoading = (state, { payload }) => ({
  ...state,
  isPilotStoreDataLoading: payload
})

const handleSetPilotStoreInfo = (state, { payload }) => ({
  ...state,
  isPilotStore: payload
})

export default handleActions({
  [storeLocatorModalInit]: handleStoreLocatorInit,
  [storeLocatorModalComplete]: handleStoreLocatorComplete,
  [storeLocatorModalReset]: handleStoreLocatorReset,
  [pilotStoreDataLoading]: handlePilotStoreDataLoading,
  [setPilotStoreInfo]: handleSetPilotStoreInfo
}, initialState)

