import React from 'react'
import PropTypes from 'prop-types'
import * as UI from '../SharedStyles'
import { ErrorMessage } from '../CommonTextInput'
import { TextAreaComponent, LabelComponent } from '../common'
import { generateId } from '../../helpers'
import { dynamicDataAttribute } from 'helpers/utils'

const TextAreaInput = ({
  Icon = null,
  children,
  error,
  className,
  isError,
  full = false,
  name = '',
  ...props
}) =>
  <UI.Wrapper className={ className } full={ full }>
    <TextAreaComponent
      name={ name }
      isError={ isError }
      id={ generateId({ ...props }) }
      { ...dynamicDataAttribute('textarea', name) }
      { ...props } />

    <LabelComponent
      isError={ isError }
      htmlFor={ generateId({ ...props }) }
      { ...props } />

    { Icon }

    { children }

    { isError && <ErrorMessage htmlFor={ generateId({ ...props }) } { ...props }>{ error }</ErrorMessage> }
  </UI.Wrapper>


export default TextAreaInput

TextAreaInput.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.string,
  full: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  Icon: PropTypes.element,
  isError: PropTypes.bool,
  name: PropTypes.string
}

