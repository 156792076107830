import { compose } from 'recompose'
import { connect } from 'react-redux'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
// TODO: default export in index file causes cyclic dependency. Analyze differences between BOT and TA
import registerModalPage from 'components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { ADD_TO_QUOTE_MODAL } from 'constant'
import AddToQuoteModal from './AddToQuoteModal'
import { getRecentlyAddedSku } from 'components/common/hoc/withAddToQuote/selectors'

const pageSrc = '/modals/add-products-and-services-to-quote'

const mapStateToProps = (state) => {
  const { recentlyAddedSku } = getRecentlyAddedSku(state)

  return {
    isItStaggeredFitmentProduct: recentlyAddedSku.length > 1
  }
}

export default compose(
  registerReduxModal({ name: ADD_TO_QUOTE_MODAL }),
  connect(mapStateToProps),
  registerModalPage({ pageSrc, rootType: MODAL_BOX_TYPE })
)(AddToQuoteModal)
