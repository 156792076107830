import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import withShowVehicleConfiguratorModalPreconfigured from 'components/common/hoc/withShowVehicleConfiguratorModalPreconfigured'
import { TAB_TIRE_SIZE_NAME, YMME_NAME, HEADER_SOURCE } from 'constant'
import ShopByTireSizeLink from './ShopByTireSizeLink'
import { showNewShopTiresModal } from '../../NewShopTiresModal/actions'

const withHandleShopByTireSizeClick = withHandlers({
  handleShopByTireSizeClick: ({
    showNewShopTiresModal
  }) => e => {
    e.preventDefault()

    showNewShopTiresModal({ tabName: TAB_TIRE_SIZE_NAME, ymmeName: YMME_NAME, source: HEADER_SOURCE })
  }
})

export default compose(
  withType({ type: 'ShopByTireSizeLink' }),
  connect(null, { showNewShopTiresModal }),
  withShowVehicleConfiguratorModalPreconfigured,
  withHandleShopByTireSizeClick
)(ShopByTireSizeLink)
