import { Validator } from 'services/validator'
import {
  CONNECT_TO_CARFAX_FORM_EMAIL_FIELD,
  CONNECT_TO_CARFAX_FORM_CONFIRMATION_FIELD,
  CONNECT_TO_CARFAX_FORM_EMAIL_ERROR,
  CONNECT_TO_CARFAX_FORM_CONFIRMATION_ERROR
} from './constants'

export default Validator.combineValidators({
  [CONNECT_TO_CARFAX_FORM_EMAIL_FIELD]: {
    errorMessage: CONNECT_TO_CARFAX_FORM_EMAIL_ERROR,
    validators: [Validator.required(), Validator.email(), Validator.accountEmailTest()]
  },
  [CONNECT_TO_CARFAX_FORM_CONFIRMATION_FIELD]: {
    errorMessage: CONNECT_TO_CARFAX_FORM_CONFIRMATION_ERROR,
    validators: Validator.required()
  }
})
