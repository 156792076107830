import { createSelector } from 'helpers/reselect'
import { getFormValues } from 'redux-form'
import { FORM_NAME, SEARCH_FIELD_NAME } from './constants'

const getGlobalSearchModal = state => state.globalSearchModal

export const getModalData = createSelector(
  [getGlobalSearchModal],
  ({ data }) => data
)

export const getSearchError = createSelector(
  [getGlobalSearchModal],
  ({ searchError }) => searchError
)

export const getSearchData = createSelector(
  [getGlobalSearchModal],
  ({ search }) => search
)

export const getSearchAutoSuggestions = createSelector(
  [getGlobalSearchModal],
  ({ autoSuggestions }) => autoSuggestions
)

// checks whether brands/products/services/articles were found
export const getIsResultFound = createSelector(
  [getSearchData],
  ({ searchError, ...products } = {}) =>
    Object.values(products).some(product => product))

export const getIsSearchEmpty = createSelector(
  [getModalData],
  (data = {}) => !Object.values(data).some(Boolean)
)

export const getSearchValue = state => {
  const values = getFormValues(FORM_NAME)(state) || {}

  return values[SEARCH_FIELD_NAME]
}
