import { compose, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import { isSSR } from 'helpers/isSSR'
import withPreventScriptDownload from 'components/common/hoc/withPreventScriptDownload'
import withFullStory from './withFullStory'
import withKenshoo from './withKenshoo'
import withMarchex from './withMarchex'
import withGoogleOptimize from './withGoogleOptimize'
import withBazaarVoice from './withBazaarVoice'
import withHomepageStructuredData from './withHomepageStructuredData'
import withInitializeMainYmme from './withInitializeMainYmme'
import Init3rdParty from './Init3rdParty'
import {
  ONE_TRUST_SCRIPT,
  KENSHOO_SCRIPT,
  BING_SCRIPT,
  FACEBOOK_SCRIPT,
  FULL_STORY_SCRIPT,
  BAZAAR_SCRIPT,
  AUDIO_EYE_SCRIPT
} from './constants'


export default compose(
  withProps({
    watchScripts: [
      ONE_TRUST_SCRIPT,
      KENSHOO_SCRIPT,
      BING_SCRIPT,
      FACEBOOK_SCRIPT,
      FULL_STORY_SCRIPT,
      BAZAAR_SCRIPT,
      AUDIO_EYE_SCRIPT
    ]
  }),
  withRouter,
  withPreventScriptDownload,
  withBazaarVoice,
  withFullStory,
  withKenshoo,
  withMarchex,
  withGoogleOptimize,
  withHomepageStructuredData,
  withProps(() =>
    ({ smgScriptDomainPrefix: !isSSR && window.location.origin !== 'https://www.bigotires.com' ? 'stage.' : '' })),
  withInitializeMainYmme
)(Init3rdParty)
