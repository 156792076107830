import { compose, withState, lifecycle, branch, renderNothing, withHandlers } from 'recompose'
import isFunction from 'lodash/isFunction'
import { findRoot } from 'helpers/component-helper'
import { rest } from 'services'

const withDataState = withState('data', 'setData', null)

const withDataLoad = lifecycle({
  componentDidMount() {
    this.props.fetchModalPage()
  }
})

const hideIfNoData = branch(
  ({ data }) => !data,
  renderNothing
)

export default ({ pageSrc: getPageSrc, rootType }) => {
  const withDataLoadHandler = withHandlers({
    fetchModalPage: ({ setData, ...props }) => () => {
      const pageSrc = isFunction(getPageSrc) ? getPageSrc(props) : getPageSrc

      return rest.get(pageSrc, { params: { format: 'json' }})
        .then(({ data }) => findRoot(data, rootType))
        .then(setData)
        .catch(err => {
          console.error(err)
          throw err
        })
    }
  })

  return compose(
    withDataState,
    withDataLoadHandler,
    withDataLoad,
    hideIfNoData
  )
}
