import { lifecycle, compose } from 'recompose'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { activateGoogleOptimize } from 'actions/thirdParty'

const withLifecycle = lifecycle({
  componentDidMount() {
    const { activateGoogleOptimize } = this.props
    activateGoogleOptimize()
  },
  componentDidUpdate({ location: { pathname: prevPathname } = {}}) {
    const { activateGoogleOptimize, location: { pathname } = {}} = this.props
    !isEqual(prevPathname, pathname) && activateGoogleOptimize()
  }
})

export default compose(
  connect(null, { activateGoogleOptimize }),
  withLifecycle
)
