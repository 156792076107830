import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { compose, withProps, withStateHandlers, withHandlers, lifecycle } from 'recompose'
import isFunction from 'lodash/isFunction'
import clamp from 'lodash/clamp'
import Theme from 'components/common/Theme/Theme'
import { processMonthData, isElementInViewport, getEventHandlers } from './helpers'
import {
  DEFAULT_TITLE_FORMAT,
  FORWARD_CHANGE,
  BACKWARD_CHANGE,
  CHANGE_MONTH,
  CHANGE_FOCUS,
  CHANGE_NONE
} from './constants'
import Datepicker from './Datepicker'

dayjs.extend(isoWeek)

const findFocusedDate = (today, currentDate, currentMonth) => {
  if (currentDate && dayjs(currentDate).isSame(currentMonth, 'month')) {
    return dayjs(currentDate)
  } else if (today.isSame(currentMonth, 'month')) {
    return dayjs(today)
  }

  return dayjs(currentMonth).startOf('month')
}

export const withDatepickerCustomization = withProps((
  { titleFormat,
    processMonthData: customProcessMonthData,
    dataAtAttrs: customDataAtAttrs
  }) => ({
  titleFormat: titleFormat || DEFAULT_TITLE_FORMAT,
  processMonthData: customProcessMonthData || processMonthData,
  dataAtAttrs: customDataAtAttrs || {
    wrapperAttrs: {
      'data-at-common-calendar': true
    },
    cellAttrs: ({ active }) => ({
      'data-at-common-calendar-day': !active ? true : null,
      'data-at-common-calendar-day-enabled': active ? true : null
    })
  }
}))

export const withDatepickerState = withStateHandlers(({ currentDate }) => ({
  today: dayjs().startOf('day'),
  currentMonth: dayjs().startOf('month'),
  focusedRef: null,
  focusedDate: currentDate ? dayjs(currentDate) : dayjs().startOf('day'),
  changeUnit: null
}), {
  setCurrentMonth: () => (currentMonth) => ({ currentMonth, changeUnit: CHANGE_MONTH }),
  setFocusedRef: () => (focusedRef) => ({ focusedRef }),
  setFocusedDate: () => (focusedDate) => ({ focusedDate, changeUnit: CHANGE_FOCUS }),
  setDates: () => (focusedDate, currentMonth, changeUnit) => ({ focusedDate, currentMonth, changeUnit })
})

export const withDatepickerNavigationState = withProps({
  proceedBack: true,
  proceedForward: true
})

export const withDatepickerStateHandlers = withHandlers({
  setFocusedRef: ({ focusedCellRef, setFocusedRef }) => (focusedRef) => {
    setFocusedRef(focusedRef)
    isFunction(focusedCellRef) && focusedCellRef(focusedRef)
  },
  handleMoveMonthBack: ({ today, currentDate, currentMonth: nextMonth, setDates }) =>
    (e) => {
      e && e.stopPropagation()

      const currentMonth = dayjs(nextMonth).subtract(1, 'month')

      setDates(findFocusedDate(today, currentDate, currentMonth), currentMonth, CHANGE_MONTH)
    },

  handleMoveMonthForward: ({ today, currentDate, currentMonth: previousMonth, setDates }) =>
    (e) => {
      e && e.stopPropagation()

      const currentMonth = dayjs(previousMonth).add(1, 'month')

      setDates(findFocusedDate(today, currentDate, currentMonth), currentMonth, CHANGE_MONTH)
    },

  changeFocusedDate: ({ currentMonth, focusedDate, proceedBack, proceedForward, setDates }) =>
    (newFocusedDate) => {
      const isSameMonth = newFocusedDate.isSame(currentMonth, 'month')

      if (!isSameMonth) {
        const nextMonth = dayjs(newFocusedDate).startOf('month')
        const changeDirection = clamp(nextMonth.month() - currentMonth.month(), -1, 1)

        if ((!proceedBack && changeDirection === BACKWARD_CHANGE) ||
          (!proceedForward && changeDirection === FORWARD_CHANGE)) {
          return setDates(focusedDate, currentMonth, CHANGE_FOCUS)
        }

        return setDates(newFocusedDate, nextMonth, CHANGE_FOCUS)
      }

      setDates(newFocusedDate, currentMonth, CHANGE_FOCUS)
    }
})

export const withResetFocusToCurrent = withHandlers({
  resetFocusToCurrent: ({ today, setDates }) => (date, changeFocus) => {
    const newFocusedDate = date || today

    setDates(dayjs(newFocusedDate), dayjs(newFocusedDate).startOf('month'), changeFocus ? CHANGE_MONTH : CHANGE_NONE)
  }
})

export const withDatepickerLifecycle = lifecycle({
  componentDidUpdate({ focusedRef: prevFocusedRef, currentDate: prevCurrentDate }) {
    const {
      focusedRef,
      changeUnit,
      pickerRef,
      resetFocusToCurrent,
      currentDate,
      setDates
    } = this.props

    const focusedRefChanged = Boolean(focusedRef) && Boolean(prevFocusedRef)
      && focusedRef.dataset.date !== prevFocusedRef.dataset.date

    if (focusedRefChanged && changeUnit === CHANGE_FOCUS) {
      focusedRef.focus()
    }

    if (focusedRefChanged) {
      pickerRef({ resetFocusToCurrent })
    }

    if (prevCurrentDate !== currentDate) {
      setDates(currentDate, currentDate, CHANGE_NONE)
    }
  }
})

export const withDatepickerFocusHandlers = withHandlers({
  handleDateSelect: ({ onDateSelect }) => ({
    target: {
      attributes: {
        disabled = null
      } = {},
      dataset: {
        date = ''
      } = {}
    } = {}
  }) => !disabled && onDateSelect(dayjs(date).toISOString()),
  handleFocusCellForward: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).add(1, 'day')),

  handleFocusCellBack: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).subtract(1, 'day')),

  handleFocusRowForward: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).add(1, 'week')),

  handleFocusRowBack: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).subtract(1, 'week')),

  handleFocusFirstCell: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).startOf('week')),

  handleFocusLastCell: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).endOf('week').startOf('day')),

  handleFocusGridForward: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).add(1, 'month')),

  handleFocusGridBack: ({ focusedDate, changeFocusedDate }) => () =>
    changeFocusedDate(dayjs(focusedDate).subtract(1, 'month')),

  handleFocusCurrent: ({ changeFocusedDate }) => (date) => () => {
    changeFocusedDate(date)
  },

  scrollToFocused: () => (e) => {
    if (!isElementInViewport(e.target)) {
      e.target.scrollIntoView({ block: 'center', inline: 'center' })
    }
  }
})

export const withDatepickerProps = withProps(
  ({ processMonthData, setFocusedDate, handleMoveMonthBack, handleMoveMonthForward, scrollToFocused, ...props }) => {
    const { titleFormat, currentDate, currentMonth, focusedDate } = props

    const weeks = processMonthData(currentMonth, currentDate)
    const isFocusedDate = date => date.isSame(focusedDate)

    return {
      weeks,
      month: currentMonth.format(titleFormat),
      isFocusedDate,
      handleMoveMonthBack,
      handleMoveMonthForward
    }
  })

export const withDatepickerEventHandlers = withProps(({ proceedBack, proceedForward, ...props }) => ({
  eventHandlers: getEventHandlers({
    mouseCapture: {
      click: props.handleDateSelect
    },
    keysCapture: {
      [Theme.keyCode.ENTER]: props.handleDateSelect,
      [Theme.keyCode.SPACE]: props.handleDateSelect,
      [Theme.keyCode.LEFT]: props.handleFocusCellBack,
      [Theme.keyCode.RIGHT]: props.handleFocusCellForward,
      [Theme.keyCode.UP]: props.handleFocusRowBack,
      [Theme.keyCode.DOWN]: props.handleFocusRowForward,
      [Theme.keyCode.HOME]: props.handleFocusFirstCell,
      [Theme.keyCode.END]: props.handleFocusLastCell,
      [Theme.keyCode.PAGE_UP]: proceedBack && props.handleFocusGridBack,
      [Theme.keyCode.PAGE_DOWN]: proceedForward && props.handleFocusGridForward
    }
  })
}))

export default compose(
  withDatepickerCustomization,
  withDatepickerState,
  withDatepickerNavigationState,
  withDatepickerStateHandlers,
  withResetFocusToCurrent,
  withDatepickerFocusHandlers,
  withDatepickerLifecycle,
  withDatepickerProps,
  withDatepickerEventHandlers
)(Datepicker)
