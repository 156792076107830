import desktopOnly from './Desktop'
import mobileOnly from './Mobile'
import font from './Font'
import ellipsis from './Ellipsis'
import middle from './Middle'
import property from './SmartProperty'
import responsive from './ResponsiveCSSProperty'
import opacity from './Opacity'
import srOnly from './SROnly'
import focusOutline from './FocusOutline'
import clearFix from './Clear'

export default {
  desktopOnly,
  mobileOnly,
  font,
  ellipsis,
  property,
  responsive,
  middle,
  opacity,
  srOnly,
  focusOutline,
  clearFix
}
