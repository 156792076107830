import { compose } from 'redux'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import { addVehicle } from 'actions/addVehicle'
import { getTireSizes } from '../../actions'
import withLicencePlate from '../withLicencePlate'
import ShopByPlateView from '../ShopByPlate/ShopByPlate'

const withHandleVehicleSelect = withHandlers({
  handleVehicleSelect: ({
    addVehicle,
    getTireSizes,
    skipTireSizeExperience,
    tiresSelectExtraCallbacks,
    handleGettingTireSizeSuccess
  }) => vehicleDetails => skipTireSizeExperience
    ? addVehicle({ ...vehicleDetails }).then(({ vehicleResponseType: { userVehicleId } = {}}) =>
      handleGettingTireSizeSuccess({ userVehicleId, tiresSelectExtraCallbacks }))
    : getTireSizes({ ...vehicleDetails }).then(handleGettingTireSizeSuccess)
})

const withHandleSubmit = withHandlers({
  handleSubmit: ({
    handleVehicleSelect,
    getVehicleByPlateNumber,
    tireSizesNotFoundModalProps
  }) => data =>
    getVehicleByPlateNumber(data, tireSizesNotFoundModalProps, handleVehicleSelect)
})

// ShopByPlateModal wrapped in basic HOCs
export default compose(
  withLicencePlate,
  connect(null, {
    getTireSizes,
    addVehicle
  }),
  withHandleVehicleSelect,
  withHandleSubmit
)(ShopByPlateView)
