import React from 'react'
import HeaderDropdownItem from './components/HeaderDropdown/HeaderDropDownItem'
import { CC_ELEMENT_ID } from './HeaderCreditCard'

export const CUSTOMER_SERV_ELEMENT_ID = 'header-customer-service'

const HeaderCustomerService = ({ ...props }) =>
  <HeaderDropdownItem
    id={ CUSTOMER_SERV_ELEMENT_ID }
    data-at-header-customerservice
    nextItem={ CC_ELEMENT_ID }
    addPaddingBottom
    { ...props } />

HeaderCustomerService.type = 'HeaderCustomerService'

export default HeaderCustomerService
