import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'redux-form'
import { Input, InputField } from 'components/common/FormElements/Input'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import VehicleEditorButtons from '../../../VehicleEditorButtons'
import { addCommas } from 'components/MyGarage2.0/helpers'
import Banner from 'components/common/Banner/Banner'
import { integersOnly } from './helpers'
import { WRONG_LICENSE_PLATE_OR_STATE_MESSAGE } from 'constant'

const VehicleDetails = ({
  handleSubmit,
  model,
  normalizeValue,
  vehicleDetails: {
    vinNumber,
    state
  } = {},
  licencePlateNumber,
  submitFailed,
  error
}) =>
  <Wrapper>
    <FormWrapper>
      <Row data-at-editvehiclemodal-nickname>
        <Field
          { ...model.nickname }
          full
          component={ Input } />
      </Row>
      <Row>
        <Field
          { ...model.mileage }
          full
          static
          normalize={ integersOnly }
          format={ addCommas }
          component={ Input } />
      </Row>
      { (!vinNumber && !state) && (
        <>
          <Row>
            <Field
              { ...model.licencePlateNumber }
              full
              static
              component={ Input } />
          </Row>
          { licencePlateNumber &&
            <Row>
              <InputField { ...model.state } full normalize={ normalizeValue } />
            </Row>
          }
        </>
      )}
      {
        submitFailed && error &&
        <CustomBanner error_alternative>
          { WRONG_LICENSE_PLATE_OR_STATE_MESSAGE }
        </CustomBanner>
      }
      <ButtonsWrapper>
        <VehicleEditorButtons handleUpdate={ handleSubmit } />
      </ButtonsWrapper>
    </FormWrapper>
  </Wrapper>

export default VehicleDetails

VehicleDetails.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  licencePlateNumber: PropTypes.string,
  model: PropTypes.shape({
    nickname: PropTypes.shape(),
    licencePlateNumber: PropTypes.shape(),
    mileage: PropTypes.shape(),
    state: PropTypes.shape()
  }),
  normalizeValue: PropTypes.func,
  submitFailed: PropTypes.bool,
  vehicleDetails: PropTypes.shape({
    vinNumber: PropTypes.string,
    state: PropTypes.string
  })
}

const Wrapper = styled.div`
  padding-top: 20px;
  ${Mixin.responsive('padding-bottom', '41px', '17px')}
`

const FormWrapper = styled.div`
  ${Media.desktop`
    width: 100%;
    margin: 0 auto;
  `}
`

const Row = styled.div`
  margin-bottom: 25px;
`

const ButtonsWrapper = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CustomBanner = styled(Banner)`
  padding-top: 10px;
`
