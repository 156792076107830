import { handleActions } from 'redux-actions'
import cloneDeep from 'lodash/cloneDeep'
import {
  initialState,
  instanceState as initialInstanceState,
  submittedState as initialSubmittedState,
  sizeState as initialSizeState,
  fieldState as initialFieldState
} from './model'
import { isInitialized } from './helpers'
import { REAR, FRONT, WIDTH, RATIO, DIAMETER, FORM, MODAL, SUBMITTED } from './constants'
import {
  selectValue,
  requestValuesStart,
  requestValuesFailure,
  commitValues,
  setValues,
  setFilter,
  setErrors,
  activate,
  deactivate,
  toggle,
  resetSomeFields,
  resetAllFields,
  resetField,
  reset,
  resetToCommitted,
  openRearTireSize,
  closeRearTireSize,
  submitTireSize,
  resetSubmittedTireSize
} from './actions'

const handleSelectValue = (state, { payload: { name, value }}) => ({
  ...state,
  [name]: {
    ...state[name],
    value,
    error: '',
    active: false
  }
})

const handleCommitValues = (state, { payload: { [FRONT]: front, [REAR]: rear }}) => ({
  ...state,
  [FRONT]: {
    ...state[FRONT],
    size: {
      ...state[FRONT].size,
      [WIDTH]: {
        ...state[FRONT].size[WIDTH],
        committedValue: front[WIDTH]
      },
      [RATIO]: {
        ...state[FRONT].size[RATIO],
        committedValue: front[RATIO]
      },
      [DIAMETER]: {
        ...state[FRONT].size[DIAMETER],
        committedValue: front[DIAMETER]
      }
    }
  },
  [REAR]: {
    ...state[REAR],
    size: {
      ...state[REAR].size,
      [WIDTH]: {
        ...state[REAR].size[WIDTH],
        committedValue: rear[WIDTH]
      },
      [RATIO]: {
        ...state[REAR].size[RATIO],
        committedValue: rear[RATIO]
      },
      [DIAMETER]: {
        ...state[REAR].size[DIAMETER],
        committedValue: rear[DIAMETER]
      }
    }
  }
})

const handleSetValues = (state, { payload: { name, value }}) => ({
  ...state,
  [name]: {
    ...state[name],
    values: value,
    loading: false,
    disabled: false,
    initialized: true
  }
})

const handleRequestValuesStart = (state, { payload: { name }}) => ({
  ...state,
  [name]: {
    ...state[name],
    loading: true
  }
})

const handleRequestValuesFailure = (state, { payload: { name, error }}) => ({
  ...state,
  [name]: {
    ...state[name],
    error,
    loading: false,
    initialized: true
  }
})

const handleSetFilter = (state, { payload: { name, value }}) => ({
  ...state,
  [name]: {
    ...state[name],
    filterValue: value
  }
})

const handleSetErrors = (state, { payload: { errors }}) =>
  Object.keys(errors).reduce((acc, name) => ({
    ...acc,
    [name]: {
      ...acc[name],
      active: false,
      error: isInitialized(state[name]) ? errors[name] : ''
    }
  }), { ...state })

const handleActivate = (state, { payload: { name }}) => ({
  ...state,
  [name]: {
    ...state[name],
    active: true
  }
})

const handleDeactivate = (state, { payload: { name }}) => ({
  ...state,
  [name]: {
    ...state[name],
    active: false
  }
})

const handleToggle = (state, { payload: { name }}) => ({
  ...state,
  [name]: {
    ...state[name],
    active: !state[name].active
  }
})

const handleResetAllFields = () => ({
  ...cloneDeep(initialSizeState)
})

const handleResetField = (state, { payload: { name }}) => ({
  ...state,
  [name]: {
    ...cloneDeep(initialFieldState),
    committedValue: state[name].committedValue
  }
})

const handleResetSomeFields = (state, { payload: { name }}) =>
  name.reduce((acc, name) => ({
    ...acc,
    [name]: {
      ...cloneDeep(initialFieldState),
      committedValue: state[name].committedValue
    }
  }), { ...state })

const handleOpenRearTireSize = state => ({
  ...state,
  [REAR]: {
    ...state[REAR],
    visible: true
  }
})

const handleCloseRearTireSize = state => ({
  ...state,
  [REAR]: {
    ...state[REAR],
    visible: false
  }
})

const handleReset = () => cloneDeep(initialInstanceState)

const handleResetToCommitted = (state) => ({
  ...state,
  [FRONT]: {
    ...state[FRONT],
    size: {
      ...state[FRONT].size,
      [WIDTH]: {
        ...state[FRONT].size[WIDTH],
        value: state[FRONT].size[WIDTH].committedValue
      },
      [RATIO]: {
        ...state[FRONT].size[RATIO],
        value: state[FRONT].size[RATIO].committedValue
      },
      [DIAMETER]: {
        ...state[FRONT].size[DIAMETER],
        value: state[FRONT].size[DIAMETER].committedValue
      }
    }
  },
  [REAR]: {
    ...state[REAR],
    size: {
      ...state[REAR].size,
      [WIDTH]: {
        ...state[REAR].size[WIDTH],
        value: state[REAR].size[WIDTH].committedValue
      },
      [RATIO]: {
        ...state[REAR].size[RATIO],
        value: state[REAR].size[RATIO].committedValue
      },
      [DIAMETER]: {
        ...state[REAR].size[DIAMETER],
        value: state[REAR].size[DIAMETER].committedValue
      }
    }
  }
})

const getActionHandler = handleAction => (state, { payload: { position, ...payload }}) => ({
  ...state,
  [position]: {
    ...state[position],
    size: handleAction(state[position].size, { payload })
  }
})

const getInstanceHandler = handleAction => (state, { payload: { isModal = false, ...payload } = {}}) => {
  const INSTANCE_KEY = isModal ? MODAL : FORM
  return {
    ...state,
    [INSTANCE_KEY]: handleAction(state[INSTANCE_KEY], { payload })
  }
}

const handleSubmittedTireSize = (state, { payload: { front, rear }}) => ({
  ...state,
  [SUBMITTED]: {
    ...state[SUBMITTED],
    [FRONT]: front,
    [REAR]: rear
  }
})

const clearSubmittedTireSize = (state) => ({
  ...state,
  [SUBMITTED]: cloneDeep(initialSubmittedState)
})

export default handleActions({
  [selectValue]: getInstanceHandler(getActionHandler(handleSelectValue)),
  [commitValues]: getInstanceHandler(handleCommitValues),
  [requestValuesStart]: getInstanceHandler(getActionHandler(handleRequestValuesStart)),
  [requestValuesFailure]: getInstanceHandler(getActionHandler(handleRequestValuesFailure)),
  [setValues]: getInstanceHandler(getActionHandler(handleSetValues)),
  [setFilter]: getInstanceHandler(getActionHandler(handleSetFilter)),
  [setErrors]: getInstanceHandler(getActionHandler(handleSetErrors)),
  [activate]: getInstanceHandler(getActionHandler(handleActivate)),
  [deactivate]: getInstanceHandler(getActionHandler(handleDeactivate)),
  [toggle]: getInstanceHandler(getActionHandler(handleToggle)),
  [resetSomeFields]: getInstanceHandler(getActionHandler(handleResetSomeFields)),
  [resetAllFields]: getInstanceHandler(getActionHandler(handleResetAllFields)),
  [resetField]: getInstanceHandler(getActionHandler(handleResetField)),
  [openRearTireSize]: getInstanceHandler(handleOpenRearTireSize),
  [closeRearTireSize]: getInstanceHandler(handleCloseRearTireSize),
  [reset]: getInstanceHandler(handleReset),
  [resetToCommitted]: getInstanceHandler(handleResetToCommitted),
  [submitTireSize]: handleSubmittedTireSize,
  [resetSubmittedTireSize]: clearSubmittedTireSize
}, initialState)
