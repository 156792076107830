export const kenshooCodeFactory = host => `
  (function(a,b,c,d,e,f,g) {
    a.ktag ||
    (
      e=function() {e.sendEvent ? e.sendEvent(arguments) : e.ktq.push(arguments)},
      e.ktq = [],a.ktag = e,f = b.getElementsByTagName(d)[0],g = b.createElement(d),
      g.async = !0,g.src = c,f.parentNode.appendChild(g)
    )
    setTimeout(() => { a.kenshooMounted = true })
  })(window, document, "//resources.xg4ken.com/js/v2/ktag.js?tid=KT-N345C-3EB", "script");
  ktag('setup','KT-N345C-3EB','<USER_ID>');
`
