import React from 'react'
import { compose, withProps, withHandlers } from 'recompose'
import withType from 'components/common/hoc/withType'
import withInnerFocusBlurHover from 'components/common/hoc/withInnerFocusBlurHover'
import withKeyPress from 'components/common/hoc/withKeyPress/withKeyPress'
import HeaderMenuLink from './components/HeaderMenuLink'
import { focusMenuItemSibling, focusMenuSubItem, getFocusedSubItemIndex } from './helpers'

const withSubmenuFlag = withProps(({
  placeholders: {
    links = []
  } = {}
}) => ({
  isSubmenu: Boolean(React.Children.count(links))
}))

const withFilteredHandlers = withProps(({
  focusBlurHoverHandlers: {
    onFocus: onClick,
    ...props
  } = {},
  handleClick
}) => ({
  focusBlurHoverHandlers: {
    onClick: handleClick || onClick,
    ...props
  }
}))

const withCombinedRefSetter = withHandlers({
  combinedRefSetter: ({ setFocusHoverStateBlockRef, setKeypressScopeRef }) => (ref) => {
    setFocusHoverStateBlockRef(ref)
    setKeypressScopeRef(ref)
  }
})

const withNavigationAndEscapeHandlers = withHandlers({
  escape: ({ setFocusHoverState, isSubmenu }) => (e) => {
    if (isSubmenu) {
      const navItem = e.currentTarget.querySelector('[data-at-header-menu-itemname]')
      setTimeout(() => {
        setFocusHoverState(false)
        navItem && navItem.focus()
      })
    }
  },
  space: ({ isSubmenu, setFocusHoverState, isInnerFocusedHovered }) => (e) => {
    if (isSubmenu && getFocusedSubItemIndex(e) < 0) {
      e.preventDefault()
      setFocusHoverState(!isInnerFocusedHovered)
    }
  },
  right: ({ index }) => (e) => {
    focusMenuItemSibling({ e, itemIndex: index + 1 })
  },
  left: ({ index }) => (e) => {
    focusMenuItemSibling({ e, itemIndex: index - 1 })
  },
  down: ({
    index,
    isSubmenu,
    setFocusHoverState,
    isInnerFocusedHovered,
    placeholders: {
      links = []
    } = {}
  }) => (e) => {
    e.preventDefault()
    if (isSubmenu) {
      !isInnerFocusedHovered && setFocusHoverState(true)
      const nextSubItemIndex = getFocusedSubItemIndex(e) + 1
      if (nextSubItemIndex < links.length) {
        focusMenuSubItem({
          e,
          parentIndex: index,
          itemIndex: nextSubItemIndex
        })
      } else {
        focusMenuItemSibling({ e, itemIndex: index + 1 })
      }
    }
  },
  up: ({ index, isSubmenu, setFocusHoverState }) => (e) => {
    e.preventDefault()
    if (isSubmenu) {
      const prevSubItemIndex = getFocusedSubItemIndex(e) - 1
      if (prevSubItemIndex >= 0) {
        focusMenuSubItem({
          e,
          parentIndex: index,
          itemIndex: prevSubItemIndex
        })
      } else {
        focusMenuItemSibling({ e, itemIndex: index })
        setFocusHoverState(false)
      }
    }
  }
})

export default compose(
  withType({ type: 'HeaderMenuLink', placeholders: ['links']}),
  withInnerFocusBlurHover,
  withFilteredHandlers,
  withSubmenuFlag,
  withNavigationAndEscapeHandlers,
  withKeyPress,
  withCombinedRefSetter
)(HeaderMenuLink)
