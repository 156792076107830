import { compose, withProps, withStateHandlers, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  handleYearSelect,
  handleMakeSelect,
  handleModelSelect,
  handleSubModelSelect
} from 'components/common/hoc/withYmme/actions'
import withActivateDropdown from '../withActivateDropdown'
import withDropdownType from '../withDropdownType'
import YmmeSelectors from './YmmeSelectors'
import { scrollToHeader } from 'helpers/scroll'
import { isMobile } from 'components/common/Media/helpers'
import { FIRST, DATA_NAME, YEARS, MAKES, MODELS, SUB_MODELS } from './constants'
import { MULTI_COLUMN_VEHICLE_MENU } from '../MultiColumnModal/constants'

const withComponentStateHandlers = withStateHandlers(
  () => ({
    [YEARS]: null,
    [MAKES]: null,
    [MODELS]: null,
    [SUB_MODELS]: null
  }),
  {
    setRef: (state) => ({ name, ref }) => ({
      ...state,
      [name]: ref || state[name]
    })
  }
)

const withMultiColumnModal = withProps({
  multiColumnMenuModal: MULTI_COLUMN_VEHICLE_MENU
})

const withHandleYearDropdownFocus = withHandlers({
  handleYearDropdownFocus: ({ multiColumnMenu }) => e =>
    !multiColumnMenu && isMobile() && scrollToHeader({ element: e.target, padding: 60 })
})

const withHandleMultiColumnModalOptionSelect = withHandlers({
  onMultiColumnYearSelect: ({ ymmeName, handleYearSelect }) => (value, onNextValuesFetched) =>
    handleYearSelect({ ymmeName, value }).then(() => onNextValuesFetched()),
  onMultiColumnMakeSelect: ({ ymmeName, handleMakeSelect }) => (value, onNextValuesFetched) =>
    handleMakeSelect({ ymmeName, value }).then(() => onNextValuesFetched()),
  onMultiColumnModelSelect: ({ ymmeName, handleModelSelect }) => (value, onNextValuesFetched) =>
    handleModelSelect({ ymmeName, value }).then(() => onNextValuesFetched()),
  onMultiColumnSubModelSelect: ({ ymmeName, handleSubModelSelect }) => value =>
    handleSubModelSelect({ ymmeName, value })
})

export default compose(
  connect(null, {
    handleYearSelect,
    handleMakeSelect,
    handleModelSelect,
    handleSubModelSelect
  }),
  withComponentStateHandlers,
  withMultiColumnModal,
  withDropdownType,
  withHandleMultiColumnModalOptionSelect,
  withActivateDropdown({ first: FIRST, dataName: DATA_NAME }),
  withHandleYearDropdownFocus
)(YmmeSelectors)
