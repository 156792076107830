import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { getCurrentStoreIsE2EAppointment } from 'selectors/getCurrentStore'
import TeaserTitle from './TeaserTitle'
import { REQUEST_APPOINTMENT, SCHEDULE_APPOINTMENT } from 'constant'

const mapStateToProps = (state) => ({
  isE2EAppointment: getCurrentStoreIsE2EAppointment(state)
})

const withTeaserTitleProps = withProps(({
  isE2EAppointment,
  title
}) => ({
  title: (title === REQUEST_APPOINTMENT && isE2EAppointment) ? SCHEDULE_APPOINTMENT : title
}))

export default compose(
  connect(mapStateToProps),
  withTeaserTitleProps
)(TeaserTitle)
