export default [
  'BOTGlobalSearchOptions',
  'BOTRecentSearches',
  'BOTSearchOptions',
  'BOTTopBrands',
  'AutoSuggestPanel',
  'BOTArticlesTypeaheadResult',
  'BOTBrandsTypeaheadResult',
  'BOTProductsTypeaheadResult',
  'BOTServicesTypeaheadResult',
  'BOTYouMayAlsoSearchFor',
  'DimensionSearchAutoSuggestItem',
  'SearchAdjustments'
]

/*
Appears on:
/modals/global-search
*/
