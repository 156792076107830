import { getFormValues } from 'redux-form'
import { createSelector } from 'helpers/reselect'
import { getQuoteTotalCount } from 'selectors/shoppingData'
import { APPOINTMENT_FORM_NAME } from 'constant'
import { FORM_NAME } from '../constants'
import {
  STORE_LOCATION,
  DATE_TIME_SELECTOR,
  APPOINTMENT_INFO,
  PRODUCTS_AND_SERVICES
} from './constants'

export const getAppointmentFormValues = getFormValues(APPOINTMENT_FORM_NAME)
export const getAppointmentConfirmationFormValues = getFormValues(FORM_NAME)

export const getCompletedSteps = (state, appointmentInfoSubmitSucceeded) => {
  const {
    selectedStore,
    appointmentDate
  } = getAppointmentFormValues(state) || {}

  const quoteTotalCount = getQuoteTotalCount(state)

  const steps = [
    {
      id: STORE_LOCATION,
      step: 1,
      isDone: Boolean(selectedStore)
    },
    {
      id: DATE_TIME_SELECTOR,
      step: 2,
      isDone: Boolean(appointmentDate && appointmentDate.time)
    },
    {
      id: APPOINTMENT_INFO,
      step: 3,
      isDone: appointmentInfoSubmitSucceeded
    },
    {
      id: PRODUCTS_AND_SERVICES,
      step: 4,
      isDone: Boolean(quoteTotalCount)
    }
  ]

  const firstIncompleteStep = steps.findIndex(step => !step.isDone)
  const allDone = firstIncompleteStep === -1

  for (let i = 0; i < steps.length; i += 1) {
    steps[i].isDone = allDone || i < firstIncompleteStep
    steps[i].isActive = allDone || i <= firstIncompleteStep
  }

  return { steps, allDone }
}

export const getAppointmentConfirmationContactDetails = ({ appointmentCheckout: { contactDetails } = {}}) =>
  contactDetails

const getStickyHeaderValueSelector = ({ sticky = {}}) => sticky

export const getStickyHeaderValue = createSelector(
  [getStickyHeaderValueSelector],
  ({
    sticky
  }) => ({ isHeaderSticky: sticky })
)

