export default [
  'AccountCredentialRecoveryLinks',
  'Login',
  'LoginContentSlot',
  'LoginCreateAccountLink',
  'LoginHederTitle'
]

/*
Appears on:
/login-desktop,
/login-mobile,
/login
*/
