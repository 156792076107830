import React from 'react'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import { SKIP_TO_MAIN_CONTENT, SKIP_TO_FOOTER, MAIN_CONTENT, FOOTER } from 'constant'

const SkipLink = () =>
  <div>
    <Skip href={ `#${MAIN_CONTENT}` }>{ SKIP_TO_MAIN_CONTENT }</Skip>
    <Skip href={ `#${FOOTER}` }>{ SKIP_TO_FOOTER }</Skip>
  </div>

export default SkipLink

const Skip = styled.a`
  color: ${Theme.colors.blue};
  left: -999px;
  position: absolute;
  text-decoration: none;
  cursor: pointer;
  background-color: white;
  padding: 10px;
  top: auto;
  z-index: 1001;

  &:hover {
    outline-width: medium;
    text-decoration: underline;
  }

  &:focus {
    top: 5px;
    left: 5px;
  }
`
