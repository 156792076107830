import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/common/Button/Button'

const Buttons = ({ onSubmit = () => {}, onCancel = () => {} }) =>
  <React.Fragment>
    <Button data-at-next-button onClick={ onSubmit }>Next</Button>
    <Button type="button" link onClick={ onCancel }>Cancel</Button>
  </React.Fragment>

Buttons.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default Buttons
