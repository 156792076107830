import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import capitalize from 'lodash/capitalize'
import entries from 'lodash/entries'
import isFunction from 'lodash/isFunction'
import isUndefined from 'lodash/isUndefined'
import { HEADER_ID, HEADER_STORE_ID, CAR_BAR_ELEMENT_ID, BOT_APPOINTMENT_PROGRESS_BAR_ID } from 'constant'

dayjs.extend(advancedFormat)
dayjs.extend(isoWeek)

const DAYS = 7

export const processMonthData = (month, date, checkDisabled) => {
  let pointer = dayjs(month).startOf('month').startOf('week')
  const calendarEnd = dayjs(month).endOf('month').endOf('week')
  const selectedMonthNum = dayjs(month).month()
  const selectedDate = dayjs(date)
  const today = dayjs().startOf('day')
  const weeks = []

  while (pointer < calendarEnd) {
    const key = pointer.format('YYYY-WW')
    let pointerCopy = dayjs(pointer)
    const days = Array(DAYS).fill(0).map(() => {
      const current = pointerCopy.isSame(today)
      const empty = pointerCopy.month() !== selectedMonthNum
      const displayName = pointerCopy.date()
      const date = dayjs(pointerCopy)
      const active = pointerCopy.isSame(selectedDate, 'day')
      const disabled = isFunction(checkDisabled) ? checkDisabled(pointerCopy) : false

      pointerCopy = pointerCopy.add(1, 'day')

      return {
        active,
        current,
        disabled,
        displayName,
        empty,
        date,
        value: String(date)
      }
    })
    pointer = dayjs(pointerCopy)
    weeks.push({
      key,
      days
    })
  }

  return weeks
}

export const isElementInViewport = (cellElement) => {
  const { top: cellTopOffset } = cellElement.getBoundingClientRect()

  const { offsetHeight: headerElemHeight = 0 } = document.getElementById(HEADER_ID) || {}
  const { offsetHeight: carBarElemHeight = 0 } = document.getElementById(CAR_BAR_ELEMENT_ID) || {}
  const { offsetHeight: myStoreElemHeight = 0 } = document.getElementById(HEADER_STORE_ID) || {}
  const { offsetHeight: progressBarElemHeight = 0 } = document.getElementById(BOT_APPOINTMENT_PROGRESS_BAR_ID) || {}

  const fullHeaderHeight = headerElemHeight + carBarElemHeight + myStoreElemHeight + progressBarElemHeight

  return cellTopOffset > fullHeaderHeight
}

export const getEventHandlers = ({ mouseCapture, keysCapture }) => {
  const handlers = {}

  entries(mouseCapture).forEach(([eventName, handler]) => {
    handlers[`on${capitalize(eventName)}Capture`] = handler
  })

  if (keysCapture) {
    handlers.onKeyDownCapture = (e) => {
      const { keyCode } = e

      const handler = keysCapture[keyCode]

      if (!isUndefined(handler)) {
        e.preventDefault()
      }
      if (isFunction(handler)) {
        handler(e)
      }
    }
  }

  return handlers
}
