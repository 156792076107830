import React from 'react'
import PropTypes from 'prop-types'

const ContentSlotHeader = ({
  placeholders: { contents = []} = {},
  ...props
}
  // WE need to pass props here because HeaderSignInRegister and HeaderAccountMenu need mobileMainMenuExpanded
  // and they are inserted on page through this slot.
) => React.Children.map(contents, content => React.cloneElement(content, { ...props }))

ContentSlotHeader.propTypes = {
  placeholders: PropTypes.shape({
    contents: PropTypes.array
  })
}

ContentSlotHeader.type = 'HeaderContentSlot'
ContentSlotHeader.placeholders = ['contents']

export default ContentSlotHeader
