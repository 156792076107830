import { connect } from 'react-redux'
import { hide, show } from 'redux-modal'
import { compose, withHandlers } from 'recompose'
import { VEHICLE_CONFIGURATOR_MODAL, CONFIRM_TIRE_SIZE_MODAL } from 'constant'
import { navigateToSearchByVehicle } from 'actions/shopByVehicle'
import { addVehicle } from 'actions/addVehicle'


const withShowVehicleConfiguratorModalPreconfigured = withHandlers({
  showVehicleConfiguratorModalPreconfigured: ({
    show,
    hide,
    addVehicle,
    navigateToSearchByVehicle
  }) => (vehicleConfiguratorModalProps = {}) =>
    show(VEHICLE_CONFIGURATOR_MODAL, {
      title: 'Shop For Tires',
      confirmTireSizeModalProps: {
        handleTiresSelect: async ({
          vehicle,
          frontTireSize,
          rearTireSize,
          loadIndex,
          rearLoadIndex,
          speedRating,
          rearSpeedRating
        }) => {
          await addVehicle({
            ...vehicle,
            tireFrontSize: frontTireSize,
            tireRearSize: rearTireSize,
            loadIndex,
            rearLoadIndex,
            speedRating,
            rearSpeedRating
          })
          hide(CONFIRM_TIRE_SIZE_MODAL)
          navigateToSearchByVehicle({ ...vehicle, frontTireSize, rearTireSize })
        }
      },
      ...vehicleConfiguratorModalProps
    })
})


export default compose(
  connect(null, { show, hide, navigateToSearchByVehicle, addVehicle }),
  withShowVehicleConfiguratorModalPreconfigured
)
