import { ALIGNMENT_SERVICE } from '../ShoppingQuote/BOTProductAndServicesQuoteNew/constants'

export const addCommas = (value = '') => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const isNicknameDuplicate = vehicleNickname => {
  const regex = new RegExp(/^.*?_\d$/)
  return regex.test(vehicleNickname)
}

export const isNicknamePersonalized = (vehicleName, vehicleNickname) => {
  if (isNicknameDuplicate(vehicleNickname)) {
    const withDeleteSufix = vehicleNickname.slice(0, vehicleNickname.indexOf('_'))
    return withDeleteSufix !== vehicleName
  }
  return vehicleName !== vehicleNickname
}

export const withNicknameSufix = vehicleNickname => {
  const modifiedNickname = vehicleNickname.split(' ').slice(0, 2).join(' ')
  const sufix = vehicleNickname.substring(vehicleNickname.indexOf('_'))
  return `${modifiedNickname}${sufix}`
}

export const isVehicleSelected = ({ app }) => {
  const { activeVehicleDetails } = app.data.userdata.mygarage

  return Boolean(activeVehicleDetails.vehicleId)
}

export const getAppointmentsForSelectedVehicle = (appointments = [], vehicleId) =>
  appointments.find(({ vehicle }) => vehicle.userVehicleId === vehicleId)

export const formatTimeTo24HoursFormat = (time) => {
  let hours = Number(time.match(/^(\d+)/)[1])
  const minutes = Number(time.match(/:(\d+)/)[1])
  const AMPM = time.match(/\s(.*)$/)[1].toLowerCase()

  if (AMPM === 'pm' && hours < 12) hours += 12
  if (AMPM === 'am' && hours === 12) hours -= 12

  let sHours = hours.toString()
  let sMinutes = minutes.toString()

  if (hours < 10) sHours = `0${sHours}`
  if (minutes < 10) sMinutes = `0${sMinutes}`

  return `${sHours}:${sMinutes}`
}

const findMin = (arr) => {
  let min

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    if (!min && arr[i] !== 0) min = arr[i]
    if (arr[i] < min && arr[i] !== 0) min = arr[i]
  }
  return arr.indexOf(min)
}

const generateDatesInMilliseconds = (quotedItemAndServices) => {
  const datesInMilliseconds = quotedItemAndServices.map(({ timeSlot }) => {
    const formattedTime = formatTimeTo24HoursFormat(timeSlot.timeSlot)
    const dateString = `${timeSlot.year}-${timeSlot.month}-${timeSlot.day} ${formattedTime}`
    const appointmentDateMilliseconds = Date.parse(dateString, 'yyyy-MM-dd HH:mm')
    const todayMilliseconds = Date.now()

    return appointmentDateMilliseconds > todayMilliseconds ? appointmentDateMilliseconds : 0
  })

  return datesInMilliseconds
}

export const getNextAppointment = (appointment) => {
  const { quotedItemAndServices } = appointment
  const datesInMilliseconds = generateDatesInMilliseconds(quotedItemAndServices)
  const closestDateIndex = findMin(datesInMilliseconds)

  return quotedItemAndServices[closestDateIndex]
}

export const setGaragePageLayoutSequenceDesktop = (noOfVehicles) => {
  switch (true) {
    case (noOfVehicles === 0):
      return `
        'VehiclesGrid EducationalMaterials NextAppointment'
        'VehiclesGrid . .'
      `
    case (noOfVehicles === 1):
      return `
        'VehiclesGrid VehiclesGrid EducationalMaterials'
        'VehiclesGrid VehiclesGrid NextAppointment'
        'VehiclesGrid VehiclesGrid NextAppointment'
      `
    case (noOfVehicles === 2):
      return `
        'VehiclesGrid VehiclesGrid EducationalMaterials'
        'VehiclesGrid VehiclesGrid NextAppointment'
        'VehiclesGrid VehiclesGrid NextAppointment'
      `
    case (noOfVehicles >= 3):
      return `
        'VehiclesGrid VehiclesGrid EducationalMaterials'
        'VehiclesGrid VehiclesGrid NextAppointment'
        'VehiclesGrid VehiclesGrid NextAppointment'
        'VehiclesGrid VehiclesGrid NextAppointment'
      `
    default:
      return ''
  }
}

export const setGaragePageLayoutSequenceLaptop = (noOfVehicles) => {
  switch (true) {
    case (noOfVehicles === 0):
      return `
        'VehiclesGrid EducationalMaterials'
        'VehiclesGrid NextAppointment';
      `
    case (noOfVehicles === 1):
      return `
        'VehiclesGrid VehiclesGrid EducationalMaterials'
        'VehiclesGrid VehiclesGrid NextAppointment'
        'VehiclesGrid VehiclesGrid NextAppointment'
      `
    case (noOfVehicles === 2):
      return `
        'VehiclesGrid VehiclesGrid EducationalMaterials'
        'VehiclesGrid VehiclesGrid NextAppointment'
        'VehiclesGrid VehiclesGrid NextAppointment'
      `
    case (noOfVehicles >= 3):
      return `
        'VehiclesGrid VehiclesGrid EducationalMaterials'
        'VehiclesGrid VehiclesGrid NextAppointment'
        'VehiclesGrid VehiclesGrid NextAppointment'
      `
    default:
      return ''
  }
}

export const setGaragePageLayoutSequenceMobile = (noOfVehicles) => {
  switch (true) {
    case (noOfVehicles >= 0):
      return `
        'VehiclesGrid'
        'EducationalMaterials'
        'NextAppointment';
      `
    default:
      return ''
  }
}

function getMonthName(monthNumber) {
  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  }

  return months[monthNumber]
}

export const formatAppointmentDate = (timeSlot) => {
  const month = getMonthName(timeSlot.month)
  const day = String(timeSlot.day).length > 1 ? timeSlot.day : `0${timeSlot.day}`
  const { year } = timeSlot
  const time = timeSlot.timeSlot

  return `${month} ${day}, ${year} at ${time}`
}


export const getFreeAlignmentCheckBlockState = (services = []) => services && services.length !== 0 &&
    services.some(({ serviceId }) => serviceId === ALIGNMENT_SERVICE)


const generateDateInMillisecondsForAppointment = (quotedItemAndServices) => {
  const { timeSlot } = quotedItemAndServices
  const formattedTime = formatTimeTo24HoursFormat(timeSlot.timeSlot)
  const dateString = `${timeSlot.year}-${timeSlot.month}-${timeSlot.day} ${formattedTime}`
  const appointmentDateMilliseconds = Date.parse(dateString, 'yyyy-MM-dd HH:mm')

  return appointmentDateMilliseconds
}


export const generateUpcomingAppointmentsWithMilliseconds = (appointments = []) => {
  const nextAppointments = []

  appointments.forEach(appointment => {
    const { quotedItemAndServices, vehicle } = appointment

    const allItemsAndServices = quotedItemAndServices.filter(item => item.hasFutureAppoinment)
    const itemsAndServicesWithDateInMilliseconds = allItemsAndServices.map(item => ({
      ...item,
      dateInMilliseconds: generateDateInMillisecondsForAppointment(item)
    }))

    nextAppointments.push({ itemsAndServicesWithDateInMilliseconds, vehicle })
  })

  return nextAppointments
}

export const getFirstNextAppointmentAndVehicle = (futureAppointments) => {
  let nextAppointment = null
  let nextAppointmentVehicle = {}

  futureAppointments.forEach(({ vehicle, itemsAndServicesWithDateInMilliseconds }) => {
    itemsAndServicesWithDateInMilliseconds.forEach((item, index) => {
      if (index === 0 && !nextAppointment) {
        nextAppointment = item
        nextAppointmentVehicle = vehicle
      }

      if (nextAppointment.dateInMilliseconds > item.dateInMilliseconds) {
        nextAppointment = item
        nextAppointmentVehicle = vehicle
      }
    })
  })

  return { nextAppointmentVehicle, nextAppointment }
}

export const getAllNextAppointments = (appointments = []) => {
  let nextAppointments = null
  const quotedItemAndServices = (appointments.length && appointments[0].quotedItemAndServices) || []
  const futureAppointmentsList = quotedItemAndServices.filter(item => item.hasFutureAppoinment)

  if (futureAppointmentsList.length > 0) {
    nextAppointments = futureAppointmentsList
  }

  return nextAppointments
}

export const sortAppointmentInChronologicalOrder = (appointments) => {
  const appointmentsWithDateInMilliseconds = appointments.map(item => ({
    ...item,
    dateInMilliseconds: generateDateInMillisecondsForAppointment(item)
  }))

  return appointmentsWithDateInMilliseconds.sort((a, b) => a.dateInMilliseconds - b.dateInMilliseconds)
}
