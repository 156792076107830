import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import getComponent from 'helpers/component-helper'

const StoreLocatorModal = ({ data: {
  modalBoxContent: [{
    mainContent
  }]
},
isAppointments,
modalWindowSettings,
handleClose,
handleSelectStore,
handleTAOSDateTimeUpdate,
fieldIsEmpty,
navigateToThreeStepper,
externalIdLong,
isPreExpanded
}) =>
  <CustomModal
    closeHandler={ handleClose }
    title="Store Locator"
    modalName="Store Locator"
    titleDataAttribute={{ 'data-at-store-locator-title': true }}
    closeIconDataAttribute={{ 'data-at-storelocatormodal-close': true }}>
    {
      mainContent.map((data, index) =>
        React.cloneElement(getComponent(data), {
          key: index,
          isAppointments,
          handleClose,
          handleSelectStore,
          fieldIsEmpty,
          handleTAOSDateTimeUpdate,
          isStoreLocatorModal: true,
          navigateToThreeStepper,
          externalIdLong,
          isPreExpanded,
          ...modalWindowSettings
        })
      )
    }
  </CustomModal>


export default StoreLocatorModal

StoreLocatorModal.propTypes = {
  data: PropTypes.shape({
    modalBoxContent: PropTypes.arrayOf(PropTypes.shape())
  }),
  externalIdLong: PropTypes.string,
  fieldIsEmpty: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSelectStore: PropTypes.func,
  handleTAOSDateTimeUpdate: PropTypes.func,
  isAppointments: PropTypes.bool,
  isPreExpanded: PropTypes.bool,
  modalWindowSettings: PropTypes.shape(),
  navigateToThreeStepper: PropTypes.bool
}

const CustomModal = styled(Modal)`
  border: 0;
  overflow-y: auto;
  ${Mixin.responsive('width', '80%', '100%')}
`
