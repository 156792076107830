import React from 'react'
import PropTypes from 'prop-types'
import { rest } from 'services'
import getComponent, { findRoot } from 'helpers/component-helper'

class ModalPage extends React.Component {
  state = {
    data: null
  }

  componentDidMount() {
    if (this.props.src) {
      this.loadPage(this.props.src)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ data: null })
      this.loadPage(nextProps.src)
    }
  }

  loadPage = src =>
    rest
      .get(src, {
        params: { format: 'json' }
      })
      .then(({ data }) =>
        this.props.type ?
          findRoot(data, this.props.type) : data)
      .then(data =>
        this.setState({ data }))
      .catch(err =>
        console.error(err))

  render() {
    if (!this.state.data) {
      return null
    }

    return (
      <div>
        { React.cloneElement(getComponent(this.state.data), { ...this.props }) }
      </div>
    )
  }
}

ModalPage.propTypes = {
  src: PropTypes.string,
  type: PropTypes.string
}

export default ModalPage
