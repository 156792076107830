import { connect } from 'react-redux'
import { compose, withProps, withHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { VEHICLE_MERGE_MODAL } from 'constant'
import { getVehicleName } from 'helpers/vehicle'
import { mergeVehicle, confirmAction } from './actions'
import { DUPLICATE_VEHICLE_ID } from './constants'
import VehicleMergeModal from './VehicleMergeModal'

const withVehicleMergeHandlers = withHandlers({
  // TODO should be changed and use vehicleId passed as param
  handleVehicleMerge: ({ userVehicle, mergeVehicle, handleMergeComplete }) => vehicleId =>
    mergeVehicle({ vehicleId: userVehicle.userVehicleId, onComplete: handleMergeComplete }),

  // TODO Potentially could be removed and remains only handleVehicleMerge
  handleVehicleDuplicate: ({ mergeVehicle, handleMergeComplete }) => () =>
    mergeVehicle({ vehicleId: DUPLICATE_VEHICLE_ID, onComplete: handleMergeComplete })
})

const withModalCloseHandler = withHandlers({
  handleModalClose: ({ confirmAction, handleVehicleDuplicate }) => () =>
    confirmAction({ onUpdate: handleVehicleDuplicate })
})

// TODO temporary decision with only one user's vehicle
const withVehicleProps = withProps(({ anonymousVehicle = {}, similarVehicles = []}) => ({
  userVehicle: similarVehicles[0],
  userVehicleName: getVehicleName(anonymousVehicle)
}))

export default compose(
  registerReduxModal({ name: VEHICLE_MERGE_MODAL }),
  connect(null, { mergeVehicle, confirmAction }),
  withVehicleProps,
  withVehicleMergeHandlers,
  withModalCloseHandler
)(VehicleMergeModal)
