import { handleActions } from 'redux-actions'
import {
  setPreviousOrder
} from 'actions/previousOrder'

const previousOrder = handleActions({
  [setPreviousOrder]: (state, { payload }) => ({ ...state, ...payload })
}, null)

export default previousOrder
