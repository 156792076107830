import {
  VEHICLE_MILEAGE
} from 'constant'

export default () => ({
  mileage: {
    label: 'Mileage',
    name: VEHICLE_MILEAGE,
    'data-at-vehicle-edit-mileage': true
  }
})
