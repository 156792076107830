import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Button from 'components/common/Button/Button'
import { getOnClickAndOnKeyPressDownEvents } from 'helpers/getOnClickAndOnKeyPressDownEvents'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'

const Expander = ({
  children,
  title,
  expandedTitle,
  className,
  expanded,
  above,
  right,
  handleClick
}) => (
  <div className={ className } { ...dynamicDataAttribute('expander-wrapper', toOneString(title)) }>
    { above && expanded && children }
    <Title above={ above }>
      <ExpanderButton
        link
        bolder
        data-at-expander-button
        { ...getOnClickAndOnKeyPressDownEvents(handleClick) }
        aria-expanded={ expanded }>
        <Icon
          data-icon
          data-at-expander-icon
          asset="plus-blue"
          alt=""
          alternative="minus-blue"
          condition={ expanded }
          margin={ right ? '0 15px 0 0' : '0 13px 0 0' }
          float={ right ? 'right' : 'none' } />
        { expanded ? (expandedTitle || title) : title }
      </ExpanderButton>
    </Title>
    { !above && expanded && children }
  </div>
)

Expander.propTypes = {
  above: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  expanded: PropTypes.bool,
  expandedTitle: PropTypes.string,
  handleClick: PropTypes.func,
  right: PropTypes.bool,
  title: PropTypes.string
}

export default Expander

const Title = styled.div`
  padding: ${props => props.above ? '15px 0 0 0' : '0 0 15px 0'};
`

const ExpanderButton = styled(Button)`
  && {
    padding: 0;
    line-height: 16.1px;
    overflow: visible;
  }
`
