import { compose, withProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { show } from 'redux-modal'
import { getYmmeExperience } from 'selectors/thirdPartyData'
import Dropdown from './Dropdown'
import { isMobile } from 'components/common/Media/helpers'
import MultiColumnDropdown from './MultiColumnDropdown'

const mapStateToProps = (state, { allowMultiColumnMenu }) => ({
  multiColumnMenu: getYmmeExperience(state) === 'b' && allowMultiColumnMenu
})

const withDropdownComponent = withProps(({ multiColumnMenu }) => ({
  DropdownComponent: multiColumnMenu ? MultiColumnDropdown : Dropdown
}))

const withMultiColumnListboxHandler = withHandlers({
  handleMultiColumnModalShow: ({ showDialog, multiColumnMenu, multiColumnMenuModal }) => step => {
    if (multiColumnMenu && isMobile()) {
      showDialog(multiColumnMenuModal, { step })
    }
  }
})

export default compose(
  connect(mapStateToProps, { showDialog: show }),
  withDropdownComponent,
  withMultiColumnListboxHandler
)
