import { inputTypes } from 'components/common/FormElements/Input'
import {
  VEHICLE_NICKNAME,
  VEHICLE_MILEAGE,
  LICENCE_NAME,
  LICENCE_MAX_LENGTH,
  STATE_NAME
} from 'constant'

export default stateOptions => ({
  nickname: {
    label: 'Vehicle Nickname (optional)',
    name: VEHICLE_NICKNAME,
    'data-at-vehicle-personalization-nickname': true
  },
  mileage: {
    label: 'Mileage',
    name: VEHICLE_MILEAGE,
    'data-at-vehicle-personalization-mileage': true
  },
  licencePlateNumber: {
    name: LICENCE_NAME,
    maxLength: LICENCE_MAX_LENGTH,
    label: 'License Plate # (optional)'
  },
  state: {
    name: STATE_NAME,
    label: 'Select State',
    inputType: inputTypes.SELECT_DROPDOWN_INPUT,
    options: stateOptions,
    isSearchable: false
  }
})
