export const SELECTED_VEHICLE_MESSAGE = 'This is now your selected vehicle'
export const NOTIFICATION_CLASS_NAME = 'vehicle-select-success'
export const GO_BACK_DESKTOP = 'Back to My Garage'
export const GO_BACK_MOBILE = 'MY GARAGE'
export const MY_GARAGE_PATH = '/my-account/vehicles'
export const TIRE_ROTATION_TITLE = 'tire rotation'
export const OIL_CHANGE_TITLE = 'oil change'
export const TIRE_ROTATION_ICON_NAME = 'tire-rotation-secondary'
export const OIL_CHANGE_ICON_NAME = 'oil-change-secondary'
export const RECOMMENDATION_WARNING_MESSAGE = 'We recommend scheduling a service soon.'
export const EDIT_MILEAGE_MODAL = 'editMileageModal'
export const EDIT_MILEAGE_FORM = 'editMileageForm'

export const MILEAGE_WARNING_NOTIFICATION = 'The mileage you have entered is lower than the vehicle\'s last recorded mileage. Click update to save.'
export const RECOMMENDED_SERVICES_TITLE = 'Manufacturer Recommended Services'
export const RECOMMENDATIONS_BASED_ON_SERVE_TEXT = 'These recommendations are based on severe driving conditions.'
export const SEVERE_DRIVING_CONDITIONS_TEXT = 'Severe driving conditions'

export const VEHICLE_MILEAGE = 'mileage'

export const MANUFACTURER_RECOMMENDED_SERVICES_FORM = 'manufacturerRecommendedServicesForm'
export const PLACEHOLDER_TEXT = 'Your service provider does not suggest any recommendations.'
export const DATA_LOAD_ERROR_PLACEHOLDER_TEXT = 'We could not load the data. Please try again.'
export const DEFAULT_RECOMMENDATIONS_PLACEHOLDER_TEXT = 'Please select mileage from the list to see recommendations'
export const NO_SEVERITY_MILEAGE_TEXT = 'No recommended services for this mileage mark.  Please select another.'

export const COULD_NOT_LOAD_DATA_CODE = '003'
export const NO_SEVERITY_MILEAGE_CODE = '11004'

export const MOBILE_EDGE = 768
export const TABLET_EDGE = 1280
