import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import { TitleLink } from 'components/Header/components/HeaderDropdown/HeaderDropdown'
import { Wrapper } from '../../../components/HeaderDropdown/HeaderDropdown'
import { STORE_LOCATOR_PAGE } from 'constant'

const ChooseStore = ({
  combinedRefSetter,
  wrapperID
}) =>
  <Wrapper
    id={ wrapperID }
    ref={ combinedRefSetter }>
    <TitleWrapper
      data-at-header-mystore
      data-at-dropdown-focus
      data-noseparator
      to={ STORE_LOCATOR_PAGE }>
      <Media.Desktop>
        <Icon asset="location-white" alt="" width="10" height="14" margin="-2px 7px 0 0" />My Store: Choose Store
      </Media.Desktop>
      <Media.Mobile>
        <Icon asset="location" alt="" width="10" height="14" margin="-2px 7px 0 0" />My Store: Choose Store
      </Media.Mobile>

    </TitleWrapper>
  </Wrapper>

ChooseStore.propTypes = {
  combinedRefSetter: PropTypes.func,
  wrapperID: PropTypes.string
}

export default ChooseStore

const TitleWrapper = styled(TitleLink)`
  ${Media.mobile`
    border-top: 1px solid ${Theme.colors.light_grey};
    padding: 17px 20px 14px;
    color: ${Theme.colors.black_new};
    background: ${Theme.colors.white};
  `}
`

