import { refresh } from 'actions/router'
import { hide } from 'redux-modal'
import { rest } from 'services'
import { cleanShoppingData } from 'actions/shoppingData'
import { clearQuickAppointmentData } from 'components/QuickAppointmentPage/actions'
import { cleanMyAppointments } from 'actions/myAppointments'
import { APPOINMENT_SUBMISSION_ERROR_MODAL } from '../../constants'

export const clearApplicationDataAndRefresh = setCurrentStepIndex => dispatch =>
  rest.api
    .logout()
    .catch(() => {
      dispatch(hide(APPOINMENT_SUBMISSION_ERROR_MODAL))
      setCurrentStepIndex(0)
      dispatch(cleanShoppingData())
      dispatch(cleanMyAppointments())
      dispatch(clearQuickAppointmentData())
      dispatch(refresh())
    })
