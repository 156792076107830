import React from 'react'
import PropTypes from 'prop-types'

const ErrorBoundary = ({ hasError, children, type = '', ...props }) =>
  hasError ?
    console.error(`Component ${type} is broken`) || null :
    React.Children.map(children, content =>
      React.cloneElement(content, { ...props })
    )

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  hasError: PropTypes.bool,
  type: PropTypes.string
}

export default ErrorBoundary
