import { handleActions } from 'redux-actions'
import { setUpdatingBlock } from './actions'
import isEmpty from 'lodash/isEmpty'
import {
  SHOPPING_QUOTE_TOTAL_QUANTITY,
  ADDING_TO_SHOPPING_QUOTE,
  INVENTORY_STATUS_LOADED,
  APPOINTMENT_SCHEDULING_IN_PROGRESS
} from './constants'

const initialState = {
  [SHOPPING_QUOTE_TOTAL_QUANTITY]: false,
  [ADDING_TO_SHOPPING_QUOTE]: null,
  [INVENTORY_STATUS_LOADED]: [],
  [APPOINTMENT_SCHEDULING_IN_PROGRESS]: false
}

const handleUpdatingBlocks = (state, { payload: { name, value }}) => {
  if (name === INVENTORY_STATUS_LOADED && !isEmpty(value)) {
    // Deep cloning the state object to safe guard from state mutation error.
    let inventoryStatusLoaded = JSON.parse(JSON.stringify(state))[INVENTORY_STATUS_LOADED]

    if (value.isLoading) {
      inventoryStatusLoaded.push(value)
    } else {
      inventoryStatusLoaded = inventoryStatusLoaded.filter(item => item.skuId !== value.skuId)
    }

    return { ...state, [INVENTORY_STATUS_LOADED]: [...inventoryStatusLoaded]}
  }

  return { ...state,
    [name]: value
  }
}

const updatingBlocks = handleActions({
  [setUpdatingBlock]: handleUpdatingBlocks
}, initialState)

export default updatingBlocks
