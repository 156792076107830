import { css } from 'styled-components'
import Media from 'components/common/Media'

const responsive = (property, desktopValue, mobileValue) => {
  const desktopStyle = `${property}: ${desktopValue};`
  const mobileStyle = `${property}: ${mobileValue};`

  return css`
    ${desktopStyle}
    ${Media.mobile([mobileStyle])}
  `
}

export default responsive
