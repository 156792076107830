import { show, hide } from 'redux-modal'
import { createAction } from 'redux-actions'
import { refresh } from 'actions/router'
import prepareForm from 'helpers/prepare-form'
import { rest } from 'services'
import { errorMessages } from 'services/validator'
import { ADDRESS_ERROR_PREFIX, ADDRESS_FULLY_VERIFIED } from './constants'
import { checkAddressVerificationResult } from './helpers'
import { ADDRESS_CONFIRMATION_MODAL } from '../AddressConfirmationModal/constants'

export const updatePersonalInformationRequest = createAction('PERSONAL_INFORMATION/UPDATE_PERSONAL_INFORMATION_REQUEST')
export const updatePersonalInformationSuccess = createAction('PERSONAL_INFORMATION/UPDATE_PERSONAL_INFORMATION_SUCCESS')
export const updatePersonalInformationFailure = createAction('PERSONAL_INFORMATION/UPDATE_PERSONAL_INFORMATION_FAILURE')
export const verifyAddressRequest = createAction('PERSONAL_INFORMATION/VERIFY_ADDRESS_REQUEST')
export const verifyAddressSuccess = createAction('PERSONAL_INFORMATION/VERIFY_ADDRESS_SUCCESS')
export const verifyAddressFailure = createAction('PERSONAL_INFORMATION/VERIFY_ADDRESS_FAILURE')

const updatePersonalInformation = accountDetails => dispatch => {
  dispatch(updatePersonalInformationRequest())

  return rest.api
    .currentUser(prepareForm(accountDetails))
    .then(() => {
      dispatch(updatePersonalInformationSuccess())

      dispatch(refresh())
    })
    .catch(err => dispatch(updatePersonalInformationFailure(err)))
}

const showAddressConfirmationModal = ({ results, accountDetails, verifiedAddress }) => dispatch => {
  dispatch(show(ADDRESS_CONFIRMATION_MODAL, {
    error: checkAddressVerificationResult(
      results,
      ADDRESS_FULLY_VERIFIED,
      ADDRESS_ERROR_PREFIX,
      errorMessages.USPS_VALIDATE_ERROR_MESSAGE
    ),
    verifiedAddress,
    accountDetails
  }))
}

export const submitPersonalInformation = accountDetails => dispatch => {
  const { address1, city, state, zipCode } = accountDetails

  if (!address1) {
    return dispatch(updatePersonalInformation({
      ...accountDetails,
      usedSuggestedAddress: false,
      addressKeepAsIs: true
    }))
  }

  dispatch(verifyAddressRequest())

  rest.api
    .verifyAddress({
      address1,
      city,
      state,
      postalCode: zipCode
    })
    .then(({
      data: {
        Records: [{
          AddressLine1: verifiedAddress1,
          City: verifiedCity,
          PostalCode: verifiedZipCode,
          State: verifiedState,
          Results: results
        } = {}] = []
      } = {}
    }) => {
      dispatch(verifyAddressSuccess())

      if (results !== ADDRESS_FULLY_VERIFIED) {
        return dispatch(showAddressConfirmationModal({
          results,
          verifiedAddress: {
            address1: verifiedAddress1,
            city: verifiedCity,
            zipCode: verifiedZipCode,
            state: verifiedState
          },
          accountDetails
        }))
      }

      dispatch(updatePersonalInformation({
        ...accountDetails,
        usedSuggestedAddress: true,
        addressKeepAsIs: false
      }))
    })
    .catch(err => dispatch(verifyAddressFailure(err)))
}

export const handleChooseAddress = accountDetails => async dispatch => {
  await dispatch(updatePersonalInformation(accountDetails))

  dispatch(hide(ADDRESS_CONFIRMATION_MODAL))
}
