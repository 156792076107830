import { connect } from 'react-redux'
import { preventDefault } from 'helpers/utils'
import { closeModal } from '../VehiclePersonalizationModal/actions'
import VehicleEditorButtons from './VehicleEditorButtons'

const mapDispatchToProps = (dispatch) => ({
  closeModal: preventDefault(() => dispatch(closeModal()))
})

export default connect(null, mapDispatchToProps)(VehicleEditorButtons)
