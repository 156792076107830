import { hide, show } from 'redux-modal'
import { rest } from 'services'
import { refresh } from 'actions/router'
import { getMiniCartData, getShoppingData } from 'actions/shoppingData'
import { VEHICLE_MERGE_MODAL } from 'constant'
import { CONFIRMATION_MODAL, CONFIRMATION_DESCRIPTION } from './constants'

export const finish = (handleFinish, resp) => dispatch => {
  handleFinish(resp)
  dispatch(hide(VEHICLE_MERGE_MODAL))
  dispatch(refresh())
}

export const mergeVehicle = ({ vehicleId, onComplete } = {}) => dispatch =>
  dispatch(getShoppingData(rest.api.mergeVehicle, { activeVehicle: vehicleId }))
    .then(resp => dispatch(finish(onComplete, resp)))
    .then(() => dispatch(getMiniCartData()))
    .catch(err => console.error(err))

export const confirmAction = ({ onUpdate } = {}) =>
  show(CONFIRMATION_MODAL, { onUpdate, description: CONFIRMATION_DESCRIPTION })
