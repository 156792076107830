import { connect } from 'react-redux'
import { lifecycle, withProps, mapProps, compose, withHandlers } from 'recompose'
import { transformTireSizes, getVehicleName } from '../../helpers'
import { loadConfirmTireSizeData } from 'actions/confirmTireSize'
import { getConfirmTireSizeData } from './selectors'
import withLoader from 'components/common/hoc/withLoader'

const withMappedVehicleAndTireSizes = mapProps(({ vehicleDetails, tireSizes, ...props }) => ({
  ...props,
  vehicle: vehicleDetails,
  tireSizes: transformTireSizes(tireSizes)
}))

const withLoadVehicleDetailsOnMounting = lifecycle({
  componentDidMount() {
    const { loadConfirmTireSizeData } = this.props
    loadConfirmTireSizeData()
  }
})

const withVehicleNameAndMake = withProps(({ vehicle }) => ({
  vehicleName: getVehicleName(vehicle),
  vehicleMake: vehicle.makeDesc
}))

const withHandleTireSizeConfirmation = withHandlers({
  handleTireSizeConfirmation: ({
    vehicle,
    handleTiresSelect,
    loaderAsyncWrapper,
    skipTireSizeExperience
  }) =>
    ({
      tireFrontSize,
      tireRearSize,
      loadIndex,
      rearLoadIndex,
      speedRating,
      rearSpeedRating
    }) => {
      loaderAsyncWrapper({
        callback: () => handleTiresSelect({
          vehicle,
          loadIndex,
          rearLoadIndex,
          speedRating,
          rearSpeedRating,
          skipTireSizeExperience,
          frontTireSize: tireFrontSize,
          rearTireSize: tireRearSize
        })
      })
    }
})

export default compose(
  connect(getConfirmTireSizeData, { loadConfirmTireSizeData }),
  withLoader,
  withMappedVehicleAndTireSizes,
  withLoadVehicleDetailsOnMounting,
  withVehicleNameAndMake,
  withHandleTireSizeConfirmation
)
