import React from 'react'
import PropTypes from 'prop-types'
import Theme from 'components/common/Theme/Theme'
import { components } from 'react-select'
import styled, { css } from 'styled-components'

const ReactSelectMenuList = ({
  children,
  selectProps: {
    listboxId,
    popoverWidth
  },
  ariaLabel,
  options = [],
  ...props
}) =>
  <StyledMenuList options={ options } { ...props }>
    <MenuListUL
      popoverWidth={ popoverWidth }
      centerContent={ !options.length }
      role="listbox"
      aria-label={ ariaLabel }
      id={ listboxId }>
      { children }
    </MenuListUL>
  </StyledMenuList>

ReactSelectMenuList.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  handleHomeEndKeyDown: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  selectProps: PropTypes.shape({
    listboxId: PropTypes.string,
    popoverWidth: PropTypes.number
  }),
  setKeypressScopeRef: PropTypes.func
}

export default ReactSelectMenuList

const StyledMenuList = styled(components.MenuList)`
  border-top: 1px solid ${Theme.colors.light_grey};

  &&& {
    max-height: none;
  }
`

const MenuListUL = styled.ul`
  padding: 10px 5px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  flex-direction: column;
  max-height: 440px;
  width: ${props => props.popoverWidth}px;
  ${props => props.centerContent && css`
    justify-content: center;
    flex-direction: column;
  `}

  li {
    min-width: 70px;
  }
`
