import { connect } from 'react-redux'
import { lifecycle, withProps, compose } from 'recompose'
import * as actions from './actions'
import { getStates } from './selectors'
import { getStatesOptions } from './helpers'

const withGettingStatesOnDidMount = lifecycle({
  componentDidMount() {
    if (!this.props.states || !this.props.states.length) {
      this.props.getStates()
    }
  }
})

const withViewData = withProps(({ states }) => ({
  statesOptions: getStatesOptions(states)
}))

const mapStateToProps = state => ({
  states: getStates(state)
})

export default compose(
  connect(mapStateToProps, actions),
  withViewData,
  withGettingStatesOnDidMount
)
