import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import { LazyDiv } from 'components/common/LazyHtml'

const TirePromotionModal = ({
  promotion: {
    promotionTitle,
    promotionDesc
  },
  handleHide
}) =>
  <Modal title={ promotionTitle } closeHandler={ handleHide }>
    <LazyDiv content={ promotionDesc } />
  </Modal>

TirePromotionModal.propTypes = {
  handleHide: PropTypes.func,
  promotion: PropTypes.shape({
    promotionTitle: PropTypes.string,
    showTireDetailsPromotion: PropTypes.func,
    promotionDesc: PropTypes.string
  })
}

export default TirePromotionModal
