import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'

const BreadcrumbLinks = ({
  itemListElement,
  placeholders: {
    links
  } = {}
}) =>
  <Wrapper aria-label="Breadcrumb" data-breadcrumb>
    <Helmet>
      <script type="application/ld+json">
        {
          JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement
          })
        }
      </script>
    </Helmet>
    <ListWrapper>
      { links }
    </ListWrapper>
  </Wrapper>

BreadcrumbLinks.propTypes = {
  itemListElement: PropTypes.arrayOf(PropTypes.shape({})),
  placeholders: PropTypes.shape({
    links: PropTypes.arrayOf()
  })
}

export default BreadcrumbLinks

const Wrapper = styled.nav`
  ${Mixin.responsive('padding', '0', '0 16px')}
  ${Mixin.responsive('margin', '20px auto', '20px auto 50px')}
`

const ListWrapper = styled.ol`
  margin: 0;
  padding: 0;
`
