import React from 'react'
import toggle from 'components/common/hoc/toggle'
import styled from 'styled-components'
import ShopByVehicleModal from './ShopByVehicleModal'
import ShopByPlateModal from './ShopByPlateModal'
import AddVehicleButton from './AddVehicleButton'

/* eslint-disable react/prop-types */
const YMMEPlateWrapper = ({ expanded, toggleHandlers, form, ...props }) =>
  <React.Fragment>
    {
      expanded ?
        <ShopByPlateModal { ...props } form={ form } toggle={ toggleHandlers.onClick }>
          <AddVehicleButton>ADD VEHICLE</AddVehicleButton>
        </ShopByPlateModal> :
        <ShopByVehicleModal { ...props } toggle={ toggleHandlers.onClick }>
          <AddVehicleButtonStyled>ADD VEHICLE</AddVehicleButtonStyled>
        </ShopByVehicleModal>
    }
  </React.Fragment>


export default toggle(YMMEPlateWrapper)

const AddVehicleButtonStyled = styled(AddVehicleButton)`
  margin-bottom: 11px;
`
