import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getPageLocation } from 'selectors'
import withType from 'components/common/hoc/withType'
import HeaderGroup from './HeaderGroup'
import { isQuoteSummaryPage, isQuickAppointmentPage } from 'helpers/quoteRelatedPages'

const mapStateToProps = state => {
  const pageLocation = getPageLocation(state)

  return {
    isQuotePage: isQuoteSummaryPage(pageLocation && pageLocation.pathname),
    isQuickAppointmentPage: isQuickAppointmentPage(pageLocation && pageLocation.pathname)
  }
}

export default compose(
  withType({
    type: 'HeaderGroup',
    placeholders: ['headerContent']
  }),
  connect(mapStateToProps)
)(HeaderGroup)
