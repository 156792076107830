import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Media from 'components/common/Media'

const Image = ({
  src,
  mobileSrc,
  alt,
  className,
  setImgRef,
  lazyPlaceholder = '',
  ...props
}) =>
  mobileSrc
    ? <Fragment>
      <Media.Desktop>
        <Img
          { ...props }
          ref={ setImgRef }
          data-src={ src || '' }
          alt={ alt || '' }
          className={ className } />
      </Media.Desktop>
      <Media.Mobile>
        <Img
          { ...props }
          ref={ setImgRef }
          data-src={ mobileSrc || '' }
          alt={ alt || '' }
          className={ className } />
      </Media.Mobile>
    </Fragment>
    : <Img { ...props } ref={ setImgRef } data-src={ src || '' } alt={ alt || '' } className={ className } />

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  lazyPlaceholder: PropTypes.string,
  mobileHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  mobileSrc: PropTypes.string,
  mobileWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  setImgRef: PropTypes.func,
  src: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

export default Image

const Img = styled.img`
  display: block;
  min-width: 1px;
  ${Media.desktop`
    ${props => props.width && props.height && css`
      width: ${props.width || '100%'};
      height: ${props.height || '100%'};
    `}
  `}
  ${Media.mobile`
    ${props => props.width && props.height && css`
      width: ${props.mobileWidth || props.width || '100%'};
      height: ${props.mobileHeight || props.height || '100%'};
    `}
  `}
`
