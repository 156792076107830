import flattenDepth from 'lodash/flattenDepth'

import homepageComponents0Types from './homepageComponents0TypeList'
import indexableYMME0Types from './indexableYMME0TypeList'
import sharedComponents0Types from './sharedComponents0TypeList'
import sharedComponents1Types from './sharedComponents1TypeList'
import sharedComponents2Types from './sharedComponents2TypeList'
import sharedComponents3Types from './sharedComponents3TypeList'
import sharedComponents4Types from './sharedComponents4TypeList'
import pageSpecificComponents0Types from './pageSpecificComponents0TypeList'
import pageSpecificComponents1Types from './pageSpecificComponents1TypeList'
import pageSpecificComponents2Types from './pageSpecificComponents2TypeList'
import pageSpecificComponents3Types from './pageSpecificComponents3TypeList'
import pageSpecificComponents4Types from './pageSpecificComponents4TypeList'
import pageSpecificComponents5Types from './pageSpecificComponents5TypeList'
import pageSpecificComponents6Types from './pageSpecificComponents6TypeList'
import pageSpecificComponents7Types from './pageSpecificComponents7TypeList'
import pageSpecificComponents8Types from './pageSpecificComponents8TypeList'
import pageSpecificComponents9Types from './pageSpecificComponents9TypeList'
import pageSpecificComponents10Types from './pageSpecificComponents10TypeList'
import pageSpecificComponents11Types from './pageSpecificComponents11TypeList'
import pageSpecificComponents12Types from './pageSpecificComponents12TypeList'
import pageSpecificComponents13Types from './pageSpecificComponents13TypeList'
import pageSpecificComponents14Types from './pageSpecificComponents14TypeList'
import pageSpecificComponents15Types from './pageSpecificComponents15TypeList'
import pageSpecificComponents16Types from './pageSpecificComponents16TypeList'
import pageSpecificComponents18Types from './pageSpecificComponents18TypeList'
import pageSpecificComponents19Types from './pageSpecificComponents19TypeList'
import pageSpecificComponents20Types from './pageSpecificComponents20TypeList'
import pageSpecificComponents21Types from './pageSpecificComponents21TypeList'
import pageSpecificComponents22Types from './pageSpecificComponents22TypeList'
import pageSpecificComponents23Types from './pageSpecificComponents23TypeList'
import pageSpecificComponents24Types from './pageSpecificComponents24TypeList'
import pageSpecificComponents25Types from './pageSpecificComponents25TypeList'
import pageSpecificComponents26Types from './pageSpecificComponents26TypeList'
import pageSpecificComponents27Types from './pageSpecificComponents27TypeList'
import pageSpecificComponents28Types from './pageSpecificComponents28TypeList'
import pageSpecificComponents29Types from './pageSpecificComponents29TypeList'
import pageSpecificComponents30Types from './pageSpecificComponents30TypeList'
import pageSpecificComponents31Types from './pageSpecificComponents31TypeList'
import pageSpecificComponents32Types from './pageSpecificComponents32TypeList'
import pageSpecificComponents33Types from './pageSpecificComponents33TypeList'
import pageSpecificComponents34Types from './pageSpecificComponents34TypeList'
import pageSpecificComponents35Types from './pageSpecificComponents35TypeList'
import pageSpecificComponents36Types from './pageSpecificComponents36TypeList'
import pageSpecificComponents37Types from './pageSpecificComponents37TypeList'
import pageSpecificComponents38Types from './pageSpecificComponents38TypeList'
import pageSpecificComponents39Types from './pageSpecificComponents39TypeList'
import pageSpecificComponents40Types from './pageSpecificComponents40TypeList'
import pageSpecificComponents41Types from './pageSpecificComponents41TypeList'
import pageSpecificComponents42Types from './pageSpecificComponents42TypeList'
import pageSpecificComponents43Types from './pageSpecificComponents43TypeList'
import pageSpecificComponents44Types from './pageSpecificComponents44TypeList'
import pageSpecificComponents45Types from './pageSpecificComponents45TypeList'
import pageSpecificComponents46Types from './pageSpecificComponents46TypeList'
import unknownComponents0Types from './unknownComponents0TypeList'


const importhomepageComponents0 = () => import(
  /* webpackChunkName: "homepageComponents0" */
  './homepageComponents0'
)
const importindexableYMME0 = () => import(
  /* webpackChunkName: "indexableYMME0" */
  './indexableYMME0'
)
const importsharedComponents0 = () => import(
  /* webpackChunkName: "sharedComponents0" */
  './sharedComponents0'
)
const importsharedComponents1 = () => import(
  /* webpackChunkName: "sharedComponents1" */
  './sharedComponents1'
)
const importsharedComponents2 = () => import(
  /* webpackChunkName: "sharedComponents2" */
  './sharedComponents2'
)
const importsharedComponents3 = () => import(
  /* webpackChunkName: "sharedComponents3" */
  './sharedComponents3'
)
const importsharedComponents4 = () => import(
  /* webpackChunkName: "sharedComponents4" */
  './sharedComponents4'
)
const importpageSpecificComponents0 = () => import(
  /* webpackChunkName: "pageSpecificComponents0" */
  './pageSpecificComponents0'
)
const importpageSpecificComponents1 = () => import(
  /* webpackChunkName: "pageSpecificComponents1" */
  './pageSpecificComponents1'
)
const importpageSpecificComponents2 = () => import(
  /* webpackChunkName: "pageSpecificComponents2" */
  './pageSpecificComponents2'
)
const importpageSpecificComponents3 = () => import(
  /* webpackChunkName: "pageSpecificComponents3" */
  './pageSpecificComponents3'
)
const importpageSpecificComponents4 = () => import(
  /* webpackChunkName: "pageSpecificComponents4" */
  './pageSpecificComponents4'
)
const importpageSpecificComponents5 = () => import(
  /* webpackChunkName: "pageSpecificComponents5" */
  './pageSpecificComponents5'
)
const importpageSpecificComponents6 = () => import(
  /* webpackChunkName: "pageSpecificComponents6" */
  './pageSpecificComponents6'
)
const importpageSpecificComponents7 = () => import(
  /* webpackChunkName: "pageSpecificComponents7" */
  './pageSpecificComponents7'
)
const importpageSpecificComponents8 = () => import(
  /* webpackChunkName: "pageSpecificComponents8" */
  './pageSpecificComponents8'
)
const importpageSpecificComponents9 = () => import(
  /* webpackChunkName: "pageSpecificComponents9" */
  './pageSpecificComponents9'
)
const importpageSpecificComponents10 = () => import(
  /* webpackChunkName: "pageSpecificComponents10" */
  './pageSpecificComponents10'
)
const importpageSpecificComponents11 = () => import(
  /* webpackChunkName: "pageSpecificComponents11" */
  './pageSpecificComponents11'
)
const importpageSpecificComponents12 = () => import(
  /* webpackChunkName: "pageSpecificComponents12" */
  './pageSpecificComponents12'
)
const importpageSpecificComponents13 = () => import(
  /* webpackChunkName: "pageSpecificComponents13" */
  './pageSpecificComponents13'
)
const importpageSpecificComponents14 = () => import(
  /* webpackChunkName: "pageSpecificComponents14" */
  './pageSpecificComponents14'
)
const importpageSpecificComponents15 = () => import(
  /* webpackChunkName: "pageSpecificComponents15" */
  './pageSpecificComponents15'
)
const importpageSpecificComponents16 = () => import(
  /* webpackChunkName: "pageSpecificComponents16" */
  './pageSpecificComponents16'
)
const importpageSpecificComponents18 = () => import(
  /* webpackChunkName: "pageSpecificComponents18" */
  './pageSpecificComponents18'
)
const importpageSpecificComponents19 = () => import(
  /* webpackChunkName: "pageSpecificComponents19" */
  './pageSpecificComponents19'
)
const importpageSpecificComponents20 = () => import(
  /* webpackChunkName: "pageSpecificComponents20" */
  './pageSpecificComponents20'
)
const importpageSpecificComponents21 = () => import(
  /* webpackChunkName: "pageSpecificComponents21" */
  './pageSpecificComponents21'
)
const importpageSpecificComponents22 = () => import(
  /* webpackChunkName: "pageSpecificComponents22" */
  './pageSpecificComponents22'
)
const importpageSpecificComponents23 = () => import(
  /* webpackChunkName: "pageSpecificComponents23" */
  './pageSpecificComponents23'
)
const importpageSpecificComponents24 = () => import(
  /* webpackChunkName: "pageSpecificComponents24" */
  './pageSpecificComponents24'
)
const importpageSpecificComponents25 = () => import(
  /* webpackChunkName: "pageSpecificComponents25" */
  './pageSpecificComponents25'
)
const importpageSpecificComponents26 = () => import(
  /* webpackChunkName: "pageSpecificComponents26" */
  './pageSpecificComponents26'
)
const importpageSpecificComponents27 = () => import(
  /* webpackChunkName: "pageSpecificComponents27" */
  './pageSpecificComponents27'
)
const importpageSpecificComponents28 = () => import(
  /* webpackChunkName: "pageSpecificComponents28" */
  './pageSpecificComponents28'
)
const importpageSpecificComponents29 = () => import(
  /* webpackChunkName: "pageSpecificComponents29" */
  './pageSpecificComponents29'
)
const importpageSpecificComponents30 = () => import(
  /* webpackChunkName: "pageSpecificComponents30" */
  './pageSpecificComponents30'
)
const importpageSpecificComponents31 = () => import(
  /* webpackChunkName: "pageSpecificComponents31" */
  './pageSpecificComponents31'
)
const importpageSpecificComponents32 = () => import(
  /* webpackChunkName: "pageSpecificComponents32" */
  './pageSpecificComponents32'
)
const importpageSpecificComponents33 = () => import(
  /* webpackChunkName: "pageSpecificComponents33" */
  './pageSpecificComponents33'
)
const importpageSpecificComponents34 = () => import(
  /* webpackChunkName: "pageSpecificComponents34" */
  './pageSpecificComponents34'
)
const importpageSpecificComponents35 = () => import(
  /* webpackChunkName: "pageSpecificComponents35" */
  './pageSpecificComponents35'
)
const importpageSpecificComponents36 = () => import(
  /* webpackChunkName: "pageSpecificComponents36" */
  './pageSpecificComponents36'
)
const importpageSpecificComponents37 = () => import(
  /* webpackChunkName: "pageSpecificComponents37" */
  './pageSpecificComponents37'
)
const importpageSpecificComponents38 = () => import(
  /* webpackChunkName: "pageSpecificComponents38" */
  './pageSpecificComponents38'
)
const importpageSpecificComponents39 = () => import(
  /* webpackChunkName: "pageSpecificComponents39" */
  './pageSpecificComponents39'
)
const importpageSpecificComponents40 = () => import(
  /* webpackChunkName: "pageSpecificComponents40" */
  './pageSpecificComponents40'
)
const importpageSpecificComponents41 = () => import(
  /* webpackChunkName: "pageSpecificComponents41" */
  './pageSpecificComponents41'
)
const importpageSpecificComponents42 = () => import(
  /* webpackChunkName: "pageSpecificComponents42" */
  './pageSpecificComponents42'
)
const importpageSpecificComponents43 = () => import(
  /* webpackChunkName: "pageSpecificComponents43" */
  './pageSpecificComponents43'
)
const importpageSpecificComponents44 = () => import(
  /* webpackChunkName: "pageSpecificComponents44" */
  './pageSpecificComponents44'
)
const importpageSpecificComponents45 = () => import(
  /* webpackChunkName: "pageSpecificComponents45" */
  './pageSpecificComponents45'
)
const importpageSpecificComponents46 = () => import(
  /* webpackChunkName: "pageSpecificComponents46" */
  './pageSpecificComponents46'
)
const importunknownComponents0 = () => import(
  /* webpackChunkName: "unknownComponents0" */
  './unknownComponents0'
)


const dynamicComponents = [
  {
    isLoaded: false,
    isLoading: false,
    types: homepageComponents0Types,
    importer: importhomepageComponents0,
    chunkName: 'homepageComponents0'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: indexableYMME0Types,
    importer: importindexableYMME0,
    chunkName: 'indexableYMME0'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: sharedComponents0Types,
    importer: importsharedComponents0,
    chunkName: 'sharedComponents0'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: sharedComponents1Types,
    importer: importsharedComponents1,
    chunkName: 'sharedComponents1'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: sharedComponents2Types,
    importer: importsharedComponents2,
    chunkName: 'sharedComponents2'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: sharedComponents3Types,
    importer: importsharedComponents3,
    chunkName: 'sharedComponents3'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: sharedComponents4Types,
    importer: importsharedComponents4,
    chunkName: 'sharedComponents4'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents0Types,
    importer: importpageSpecificComponents0,
    chunkName: 'pageSpecificComponents0'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents1Types,
    importer: importpageSpecificComponents1,
    chunkName: 'pageSpecificComponents1'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents2Types,
    importer: importpageSpecificComponents2,
    chunkName: 'pageSpecificComponents2'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents3Types,
    importer: importpageSpecificComponents3,
    chunkName: 'pageSpecificComponents3'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents4Types,
    importer: importpageSpecificComponents4,
    chunkName: 'pageSpecificComponents4'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents5Types,
    importer: importpageSpecificComponents5,
    chunkName: 'pageSpecificComponents5'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents6Types,
    importer: importpageSpecificComponents6,
    chunkName: 'pageSpecificComponents6'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents7Types,
    importer: importpageSpecificComponents7,
    chunkName: 'pageSpecificComponents7'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents8Types,
    importer: importpageSpecificComponents8,
    chunkName: 'pageSpecificComponents8'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents9Types,
    importer: importpageSpecificComponents9,
    chunkName: 'pageSpecificComponents9'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents10Types,
    importer: importpageSpecificComponents10,
    chunkName: 'pageSpecificComponents10'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents11Types,
    importer: importpageSpecificComponents11,
    chunkName: 'pageSpecificComponents11'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents12Types,
    importer: importpageSpecificComponents12,
    chunkName: 'pageSpecificComponents12'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents13Types,
    importer: importpageSpecificComponents13,
    chunkName: 'pageSpecificComponents13'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents14Types,
    importer: importpageSpecificComponents14,
    chunkName: 'pageSpecificComponents14'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents15Types,
    importer: importpageSpecificComponents15,
    chunkName: 'pageSpecificComponents15'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents16Types,
    importer: importpageSpecificComponents16,
    chunkName: 'pageSpecificComponents16'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents18Types,
    importer: importpageSpecificComponents18,
    chunkName: 'pageSpecificComponents18'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents19Types,
    importer: importpageSpecificComponents19,
    chunkName: 'pageSpecificComponents19'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents20Types,
    importer: importpageSpecificComponents20,
    chunkName: 'pageSpecificComponents20'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents21Types,
    importer: importpageSpecificComponents21,
    chunkName: 'pageSpecificComponents21'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents22Types,
    importer: importpageSpecificComponents22,
    chunkName: 'pageSpecificComponents22'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents23Types,
    importer: importpageSpecificComponents23,
    chunkName: 'pageSpecificComponents23'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents24Types,
    importer: importpageSpecificComponents24,
    chunkName: 'pageSpecificComponents24'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents25Types,
    importer: importpageSpecificComponents25,
    chunkName: 'pageSpecificComponents25'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents26Types,
    importer: importpageSpecificComponents26,
    chunkName: 'pageSpecificComponents26'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents27Types,
    importer: importpageSpecificComponents27,
    chunkName: 'pageSpecificComponents27'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents28Types,
    importer: importpageSpecificComponents28,
    chunkName: 'pageSpecificComponents28'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents29Types,
    importer: importpageSpecificComponents29,
    chunkName: 'pageSpecificComponents29'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents30Types,
    importer: importpageSpecificComponents30,
    chunkName: 'pageSpecificComponents30'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents31Types,
    importer: importpageSpecificComponents31,
    chunkName: 'pageSpecificComponents31'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents32Types,
    importer: importpageSpecificComponents32,
    chunkName: 'pageSpecificComponents32'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents33Types,
    importer: importpageSpecificComponents33,
    chunkName: 'pageSpecificComponents33'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents34Types,
    importer: importpageSpecificComponents34,
    chunkName: 'pageSpecificComponents34'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents35Types,
    importer: importpageSpecificComponents35,
    chunkName: 'pageSpecificComponents35'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents36Types,
    importer: importpageSpecificComponents36,
    chunkName: 'pageSpecificComponents36'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents37Types,
    importer: importpageSpecificComponents37,
    chunkName: 'pageSpecificComponents37'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents38Types,
    importer: importpageSpecificComponents38,
    chunkName: 'pageSpecificComponents38'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents39Types,
    importer: importpageSpecificComponents39,
    chunkName: 'pageSpecificComponents39'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents40Types,
    importer: importpageSpecificComponents40,
    chunkName: 'pageSpecificComponents40'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents41Types,
    importer: importpageSpecificComponents41,
    chunkName: 'pageSpecificComponents41'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents42Types,
    importer: importpageSpecificComponents42,
    chunkName: 'pageSpecificComponents42'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents43Types,
    importer: importpageSpecificComponents43,
    chunkName: 'pageSpecificComponents43'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents44Types,
    importer: importpageSpecificComponents44,
    chunkName: 'pageSpecificComponents44'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents45Types,
    importer: importpageSpecificComponents45,
    chunkName: 'pageSpecificComponents45'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: pageSpecificComponents46Types,
    importer: importpageSpecificComponents46,
    chunkName: 'pageSpecificComponents46'
  },
  {
    isLoaded: false,
    isLoading: false,
    types: unknownComponents0Types,
    importer: importunknownComponents0,
    chunkName: 'unknownComponents0'
  }
]


const getTypes = obj => {
  if (typeof obj === 'object') {
    const arrays = Object.values(obj).filter(Array.isArray)
    const type = [obj['@type']]

    return [...type, ...flattenDepth(arrays.map(arr => arr.map(getTypes)), 2)]
  }

  return []
}

const getListOfUsingTypes = data => Array.from(new Set(getTypes(data).filter(Boolean)))

const getUsingChunks = dataTypes => dynamicComponents.filter(chunk => {
  const { isLoaded, isLoading, types } = chunk
  if (isLoaded) { return false }

  if (isLoading) {
    return true
  }

  const isFounded = dataTypes.some(type => types.includes(type))

  if (isFounded) {
    // eslint-disable-next-line
    chunk.isLoading = true
  }

  return isFounded
})

export const getListOfUsingChunks = data => {
  const types = getListOfUsingTypes(data)
  const usingChunks = getUsingChunks(types).map(({ chunkName }) => chunkName)

  return [
    ...usingChunks,
    ...usingChunks.map(chunkName => `vendors.${chunkName}`)
  ]
}

export const loadComponents = data => {
  if (dynamicComponents.every(({ isLoaded }) => isLoaded)) {
    return Promise.all([])
  }

  const dataTypes = getListOfUsingTypes(data)

  const neededToLoadComponents = getUsingChunks(dataTypes)

  return Promise.all(neededToLoadComponents.map(dynamicComponent => dynamicComponent.importer().then(res => {
    // eslint-disable-next-line
    dynamicComponent.isLoaded = true
    return res
  })))
}
