import { css } from 'styled-components'

export default () => css`
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0 0, 0 0, 0 0);
  -webkit-clip-path: polygon(0 0, 0 0, 0 0);
  overflow: hidden !important;
  white-space: nowrap;
`
