import { withYmmeInners } from 'components/common/hoc/withYmme'
import { isCurrentVehicle } from 'helpers/ymme'
import { processVehicleData } from './helpers'
import { getFormValues } from 'redux-form'
import { VEHICLE_DETAILS_FORM } from './constants'

export const isLicenceFormActive = ({ vehicleEditor: { changeByLicencePlate }}) => changeByLicencePlate

export const getCurrentTab = ({ vehicleEditor: { currentTab }}) => currentTab

export const getVehicleData = ({ vehicleEditor: { vehicleData }}) => vehicleData

export const getUserVehicleId = state => getVehicleData(state).userVehicleId

export const getVehicleUpdateHandler = ({ vehicleEditor: { vehicleUpdateHandler }}) => vehicleUpdateHandler

export const getDataFetcher = ({ vehicleEditor: { dataFetchHandler }}) => dataFetchHandler

const { selectors: { getYmmeFields }} = withYmmeInners

export const isYmmeDirty = (state, { ymmeName }) => {
  const ymmeFields = getYmmeFields(state, { ymmeName })
  const vehicleData = getVehicleData(state)
  const vehicle = processVehicleData(vehicleData)

  return !isCurrentVehicle(ymmeFields, vehicle)
}

export const getShopByLicencePlate = state => state.shopByLicencePlate
export const getStates = state => getShopByLicencePlate(state).states
export const getValues = getFormValues(VEHICLE_DETAILS_FORM)
