// Store not selected error message
export const STORE_NOT_SELECTED_ERROR = 'Please select a store'

// Appointment Info Form Values
export const USER_PLAN = 'userPlan'
export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const EMAIL = 'email'
export const PHONE_NUMBER = 'phoneNumber'

// Anchor Ids
export const MORE_SERVICES_ID = 'more-services'
export const POPULAR_SERVICES_ID = 'popular-services'

export const GOOGLE_MAPS_BOOKING_API_SANDBOX = 'https://www.google.com/maps/conversion/debug/collect'
export const GOOGLE_MAPS_BOOKING_API_PRODUCTION = 'https://www.google.com/maps/conversion/collect'
export const GOOGLE_MAPS_BOOKING_API_CONVERSION_COOKIE_KEY = '_rwg_token'
export const GOOGLE_MAPS_BOOKING_API_CONVERSION_PARTNER_ID = '20001762'

export const PRIVACY_POLICY_LINK = '/privacy-policy'
export const PRIVACY_POLICY_LABEL = 'Privacy Policy'

// Store Not Eligible For Promotion Modal
export const STORE_NOT_ELIGIBLE_FOR_PROMOTION_MODAL = 'storeNotEligibleForPromoModal'
export const STORE_NOT_ELIGIBLE_FOR_PROMOTION_MODAL_TITLE = 'Selected Store / Region Not Eligible For Promotion'
