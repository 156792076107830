import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { handleUpdate3rdPartyDataEvent, set3StepperEnabled } from './actions'
import { initialize3rdPartyDataLayer, mutate3rdPartyDataLayer } from './helpers'
import {
  THREE_STEPPER_ENABLED
} from 'constant'

const with3rdPartyDataEvent = lifecycle({
  componentDidMount() {
    const {
      handleUpdate3rdPartyDataEvent,
      set3StepperEnabled
    } = this.props
    window._thirdPartyDataLayer = window._thirdPartyDataLayer || []
    initialize3rdPartyDataLayer({ ary: window._thirdPartyDataLayer, handler: handleUpdate3rdPartyDataEvent })
    window._thirdPartyDataLayer.push =
      mutate3rdPartyDataLayer({ ary: window._thirdPartyDataLayer, handler: handleUpdate3rdPartyDataEvent })

    const threeStepperSessionValue = JSON.parse(sessionStorage.getItem(THREE_STEPPER_ENABLED))
    threeStepperSessionValue !== null && set3StepperEnabled({ value: threeStepperSessionValue })
  }
})

export default compose(
  connect(null, {
    handleUpdate3rdPartyDataEvent,
    set3StepperEnabled
  }),
  with3rdPartyDataEvent
)
