export const processVehicleData = ({
  masterVehicle: {
    makeId,
    modelId,
    yearDesc,
    subModelId
  }
}) => ({
  make: Number(makeId),
  year: Number(yearDesc),
  model: Number(modelId),
  subModel: Number(subModelId)
})
