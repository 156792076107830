import { RIDE_STYLER_LIVE_BASE_URL } from 'constant'

export const RENDER_VEHICLE_URL = `${RIDE_STYLER_LIVE_BASE_URL}/vehicle/render`

// Url params
export const KEY = 'key'
export const VEHICLE = 'VehicleFilters.Search'
export const SEARCH_OPTIONS = 'VehicleFilters.SearchOptions'
export const VIEW = 'type'
export const HEIGHT = 'height'
export const WIDTH = 'width'
export const COLOR = 'paintColor'
export const POSITION_X = 'positionX'
export const POSITION_Y = 'positionY'

// Url params values
export const ANGLE_VIEW = 'Angle'
export const SIDE_VIEW = 'Side'
export const H_ALIGNMENT = ['Start', 'Center', 'End']
export const V_ALIGNMENT = ['Top', 'Center', 'Bottom']
export const EXACT_MATCH = 'ExactMatch'
export const CLOSEST_MATCH = 'None'

// Defaults
export const DEFAULT_COLOR = '#fff'
export const DEFAULT_VIEW = ANGLE_VIEW
export const DEFAULT_H_ALIGNMENT = H_ALIGNMENT[1]
export const DEFAULT_V_ALIGNMENT = V_ALIGNMENT[1]
export const DEFAULT_WIDTH = 100
export const DEFAULT_HEIGHT = 50

export const NOT_VEHICLE_IMAGE_URL = 'https://tbc.scene7.com/is/image/TBCCorporation/BOT-vehicle-image-not-available'
