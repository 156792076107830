import { compose } from 'redux'
import { lifecycle } from 'recompose'
import toggle from 'components/common/hoc/toggle'
import withEventListener from 'components/common/hoc/withEventListener'
import MenuItemWrapper from './MenuItemWrapper'
import { OPEN_APPOINTMENT_MENU } from 'constant'

const withForceClickOnExpandedChange = lifecycle({
  UNSAFE_componentWillReceiveProps({ mobileMainMenuExpanded, expanded }) {
    if (!mobileMainMenuExpanded && expanded) {
      this.props.toggleHandlers.onClick()
    }
  }
})

export default compose(
  toggle,
  withForceClickOnExpandedChange,
  withEventListener({
    eventName: OPEN_APPOINTMENT_MENU,
    getHandler: props => () => {
      props.mobileMainMenuToggleHandlers.onClick()
      props.toggleHandlers.onClick()
    }
  })
)(MenuItemWrapper)
