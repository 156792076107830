import { compose, withProps } from 'recompose'
import { filterPromotions } from '../helpers'
import { LOCAL, NO_LOCAL_PROMO_MSG } from '../constants'
import Promotions from './Promotions'
import withPromotionTileResize from '../../withPromotionTileResize'

const withFilteredPromotions = withProps(({
  promotionList,
  title,
  regionName,
  noResult,
  homePageVersion
}) => ({
  filteredPromotions: (promotionList && promotionList.length) ? filterPromotions(promotionList, title, regionName) : [],
  noResultMessage: homePageVersion && title === LOCAL ? noResult : NO_LOCAL_PROMO_MSG
}))

export default compose(
  withFilteredPromotions,
  withPromotionTileResize
)(Promotions)
