import React from 'react'
import PropTypes from 'prop-types'

const HeaderGarageContentSlot = ({ placeholders: { contents = []} = {}, ...props }) =>
  <div>
    { contents }
  </div>

HeaderGarageContentSlot.propTypes = {
  placeholders: PropTypes.shape({
    contents: PropTypes.array
  })
}

HeaderGarageContentSlot.type = 'HeaderGarageContentSlot'
HeaderGarageContentSlot.placeholders = ['contents']

export default HeaderGarageContentSlot
