import { lifecycle } from 'recompose'
import ErrorBoundary from './ErrorBoundary'

export default lifecycle({
  state: { hasError: false },

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
  }
})(ErrorBoundary)
