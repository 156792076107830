import { Validator } from 'services/validator'
import { LICENCE_NAME, STATE_NAME, LICENCE_PLATE_EMPTY, LICENCE_PLATE_INVALID, STATE_EMPTY } from './constants'

export default Validator.combineValidators({
  [LICENCE_NAME]: [
    {
      validators: Validator.required(),
      errorMessage: LICENCE_PLATE_EMPTY
    },
    {
      validators: Validator.licensePlate(),
      errorMessage: LICENCE_PLATE_INVALID
    }
  ],
  [STATE_NAME]: {
    validators: Validator.required(),
    errorMessage: STATE_EMPTY
  }
})
