export default [
  'BOTAbout',
  'BOTArticlesCustomText',
  'BOTEmailPromotionDetails',
  'BOTEmailQuote',
  'BOTEmailStoreDetails',
  'BOTEmailTireCompare',
  'BOTGlobalGuidedNavigation',
  'BOTSearchPromotion',
  'BOTSearchTrailerTiresResult',
  'BOTTest',
  'BOTTireSizeDetailsCartridge',
  'CompatibleBrandResultList',
  'FeaturedBrands',
  'FooterMenu',
  'HeaderDesktop',
  'HomeTopBlock',
  'MenuLink',
  'MenuLinkWithContentSpotlight',
  'ModalBoxLink',
  'NonCompatibleBrandResultList',
  'OneColumnGenericPage',
  'OneColumnSearchResultsPage',
  'ProductContentSpotlight-XMSlot',
  'ProgressSlot',
  'RecommendedProducts',
  'default',
  'ScrollableBrandPromotionSpotlight'
]

/*
Appears on:
unknown
*/
