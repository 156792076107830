import axios from 'axios'
import { get } from 'lodash'
import { createAction } from 'redux-actions'
import { axiosConfig } from 'services/rest/config'
import { SEARCH_RESULT_COMPONENT } from 'components/OneColumnSearchResultPage/constants.js'
import { setSessionStorageItem } from 'services/ThirdPartyMiddleware/helpers.js'

export const setNumberOfTiresOptionsAvailable = createAction('AVAILABLE_TIRES/SET_NUMBER_OF_TIRES_OPTIONS_AVAILABLE')

export const setNumberOfTiresOptionsLoading = createAction('AVAILABLE_TIRES/SET_NUMBER_OF_TIRES_OPTIONS_LOADING')

const axiosInstance = axios.create(axiosConfig)

export const getNumberOfTiresOptionsAvailable = (URL) => async (dispatch, getState) => {
  try {
    const storeId = getState().myStore?.storeId
    const numberOfAvailableTires = getState().availableTires.numberOfAvailableTires

    const { requestedURL = '', storeId: id = '' } = JSON.parse(sessionStorage.getItem('srpApiDetails')) || {}

    // check to only call api if store is changed or vehicle is changed or numberOfAvailableTires is null
    if (storeId !== id || URL !== requestedURL || numberOfAvailableTires === null) {
      dispatch(setNumberOfTiresOptionsLoading(true))
      const response = await axiosInstance.get(`${URL}?format=json`)
      dispatch(setNumberOfTiresOptionsLoading(false))
      /*
        Destructuring the mainContent key from the response
        to get the total number of tires options available
        return an empty array if mainContent is not available.
      */
      const mainContent = get(response.data, ['contents', '0', 'mainContent'], [])
      const numberOfAvailableTires = +mainContent
        ?.find(content => content.name === SEARCH_RESULT_COMPONENT)
        ?.rightContent[0].totalNumRecs || 0

      // dispatching the action to update the number of tires available
      dispatch(setNumberOfTiresOptionsAvailable({ numberOfAvailableTires }))
      setSessionStorageItem('srpApiDetails', { requestedURL: URL, storeId })
    }
  } catch (err) {
    dispatch(setNumberOfTiresOptionsLoading(false))
    dispatch(setNumberOfTiresOptionsAvailable({ numberOfAvailableTires: 0 }))
  }
}
