import { getFormValues, isSubmitting } from 'redux-form'
import {
  FIND_SERVICE_HISTORY_FORM,
  FIND_SERVICE_HISTORY_FORM_LICENSE_FIELD,
  FIND_SERVICE_HISTORY_FORM_VIN_FIELD,
  FIND_SERVICE_HISTORY_FORM_STATE_FIELD
} from './constants'

export const getFindServiceHistoryFormValues = getFormValues(FIND_SERVICE_HISTORY_FORM)

export const isFindServiceHistoryFormSubmitting = isSubmitting(FIND_SERVICE_HISTORY_FORM)

export const isRequiredFieldsEmpty = state => {
  const values = getFindServiceHistoryFormValues(state) || {}

  return (!values[FIND_SERVICE_HISTORY_FORM_STATE_FIELD] || !values[FIND_SERVICE_HISTORY_FORM_LICENSE_FIELD]) &&
    !values[FIND_SERVICE_HISTORY_FORM_VIN_FIELD]
}
