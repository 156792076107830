export const FORM_NAME = 'shopTiresByLicencePlate'

export const LICENCE_NAME = 'licensePlate'
export const STATE_NAME = 'state'

export const LICENCE_MAX_LENGTH = 10

// validation messages
export const LICENCE_PLATE_EMPTY = 'Please enter your License Plate Number.'
export const LICENCE_PLATE_INVALID = 'Please enter a valid License Plate Number.'
export const STATE_EMPTY = 'Please enter your State.'
