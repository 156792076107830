import { GLOBAL_SEARCH_PAGE, GLOBAL_SEARCH_QUERY_KEY } from 'constant'
import extractArrayValues from './extract-array-values'

const processValues = func => records => records.map(({ attributes }) =>
  func(extractArrayValues(attributes))
)

export const processArticlesData = processValues(({
  displayName: name,
  articleImageUrl: imageUrl,
  articleLink: url
}) => ({
  name,
  imageUrl,
  itemKey: url,
  url
}))

export const processBrandsData = processValues(({
  brandLogoUrl: brandLogo,
  brandLink: brandUrl
}) => ({
  brandLogo,
  brandUrl,
  itemKey: brandUrl
}))

export const processProductsData = processValues(({
  productDisplayName: name,
  ImagePath: imageUrl,
  productPriceRange: price,
  rates: ratingCount = 0,
  skuLink: url,
  compatibilityState: compatibilityStatus,
  hasMapPrice,
  seePriceIncartMessage,
  staggeredSkuId: isStaggered,
  bvId
}) => {
  const parsedPrice = Number.parseFloat(price)

  return {
    name,
    imageUrl,
    itemKey: url,
    ratingCount: Number(ratingCount),
    url,
    price: parsedPrice === 0 ? '0' : parsedPrice.toFixed(2),
    compatibilityStatus,
    hasMapPrice,
    seePriceIncartMessage,
    isStaggered,
    bvId
  }
})

export const processServicesData = processValues(({
  skuDisplayName: name,
  iconName,
  serviceLink: url,
  price
}) => {
  const productPrice = Number.parseFloat(price)

  return {
    name,
    iconName,
    itemKey: url,
    url,
    price: productPrice ? productPrice.toFixed(2) : null
  }
})

const changeIOsSmartPunctuation = value => value.replace(/‘/g, '\'').replace(/’/g, '\'').replace(/“/g, '\'').replace(/”/g, '\'')

export const omitExcessiveItems = (items, itemsCount) => items.slice(0, itemsCount)

export const createBlockTitle = (blockName, tilesNum) => `${tilesNum} ${blockName}${Number(tilesNum) === 1 ? '' : 's'}`

export const getSearchUrl = (value, pageUrl = GLOBAL_SEARCH_PAGE) => `${pageUrl}?${GLOBAL_SEARCH_QUERY_KEY}=${changeIOsSmartPunctuation(value)}`

