import { rest, isApiError } from 'services'
import { show } from 'redux-modal'
import { createAction } from 'redux-actions'
import { PROMOTION_EXPIRATION_MODAL } from 'components/ShoppingQuote/BOTProductAndServicesQuoteNew/constants'
import { formatDate } from 'components/QuickAppointmentPage/BOTAppointmentSummary/helpers'
import { setShoppingData } from 'actions/shoppingData'


export const setNotInRangeOfPromoDates = createAction('PROMOTION_EXPIRATION_STATUS/SET_NOT_IN_RANGE_OF_PROMO_DATES')
export const setPromotionDetails = createAction('PROMOTION_EXPIRATION_STATUS/SET_PROMOTION_DETAILS')
export const setModalWasShown = createAction('PROMOTION_EXPIRATION_STATUS/SET_MODAL_WAS_SHOWN')
export const setShowCallStorePromoExpirationNotification = createAction('PROMOTION_EXPIRATION_STATUS/SET_SHOW_CALL_STORE_PROMO_EXPIRATION_NOTIFICATION')
export const setShowInRangePromoExpirationNotification = createAction('PROMOTION_EXPIRATION_STATUS/SET_SHOW_IN_RANGE_PROMO_EXPIRATION_NOTIFICATION')

export const validatePromoExpDate = ({
  firstAvailableDate,
  selectedDate,
  onChange,
  setSelectedDate,
  promotionDetailsList
}) => (dispatch, getState) => {
  const { shoppingData: { _state }} = getState()
  const { promotionExpirationStatus: { modalWasShown }} = getState()
  const stillHasPromotions = promotionDetailsList.length > 0

  if (!modalWasShown || (modalWasShown && stillHasPromotions)) {
    return rest.api.validateAppliedPromoExpDate({ _state, appointmentDate: formatDate(selectedDate) })
      .then(({
        appointmentDateAfterPromoExpire,
        appliedPromotions,
        quotedItemAndServices
      }) => {
        if (appointmentDateAfterPromoExpire) {
          dispatch(show(PROMOTION_EXPIRATION_MODAL, {
            appliedPromotions,
            onChange,
            setSelectedDate,
            firstAvailableDate,
            quotedItemAndServices
          }))
          dispatch(setModalWasShown(true))
          dispatch(setShoppingData({ quotedItemAndServices }))
        } else {
          dispatch(setShoppingData({ quotedItemAndServices }))
          dispatch(setModalWasShown(false))
          dispatch(setShowInRangePromoExpirationNotification(false))
        }
      })
      .catch(e => console.error(e))
  }

  if (modalWasShown && !stillHasPromotions) {
    return rest.api.validateAppliedPromoExpDate({ _state, appointmentDate: formatDate(selectedDate) })
      .then(({
        appointmentDateAfterPromoExpire,
        quotedItemAndServices
      }) => {
        if (!appointmentDateAfterPromoExpire && quotedItemAndServices) {
          dispatch(setShoppingData({ quotedItemAndServices }))
          dispatch(setShowInRangePromoExpirationNotification(false))
          dispatch(setModalWasShown(false))
        }
      })
      .catch(e => console.error(e))
  }
}

export const removePromoFromCart = ({ promoIds }) => (dispatch, getState) => {
  const payloadPromoIds = promoIds.join(', ')

  return rest.api.removePromo({ promotionIds: payloadPromoIds })
    .then(({ quotedItemAndServices, _state }) => {
      dispatch(setShoppingData({ quotedItemAndServices, _state }))
      dispatch(setShowInRangePromoExpirationNotification(true))
      dispatch(setModalWasShown(true))
    })
    .catch(err => {
      if (isApiError(err)) {
        console.log({ err })
      }
    })
}
