import Text from 'components/common/Text/Text'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import styled from 'styled-components'

export const Title = styled(Text)`
  ${Mixin.font.GothamBold(24)}
  line-height: 50px;
  ${Media.mobile`
    ${Mixin.font.GothamBold(20)}
  `}
`

export const SubTitle = styled.div`
  margin-bottom: 30px;
  text-align: center;
  ${Mixin.font.GothamBook(20)}
  ${Media.mobile`
    font-size: 18px;
    line-height: 24px;
  `}
`

export const NewYMMEWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto 30px;
  width: max-content;
`

export const NewLicensePlateWrapper = styled.div`
  margin: 0 auto;
  display: block;
  width: max-content;
`

export const NewYMMESubTitle = styled(SubTitle)`
  color: ${Theme.colors.blue};
  margin-bottom: 0;
`

export const NewLicenseSubTitle = styled(NewYMMESubTitle)`
  color: ${Theme.colors.black};
`

export const EditIcon = styled(Icon)`
  width: 15px;
  margin-left: 10px;
`

export const NewCol = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`

export const NewStaticCol = styled(NewCol)`
  cursor: default;
`

export const NewInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const NewPlaceholder = styled.div`
  width: 90px;
  color: ${Theme.colors.dark_grey};
`

export const Wrapper = styled.div`
  ${Mixin.responsive('padding', '30px 40px 105px', '30px 0 0')}
`

export const ConfirmationWrapper = styled.div`
  ${Mixin.responsive('margin', '35px auto', '40px auto 19px auto')}
  ${Mixin.responsive('padding', '45px 50px 39px', '0 16px 5px 16px')}
  ${Mixin.responsive('width', '480px;', '100%')}
  ${Mixin.responsive('border', `1px solid ${Theme.colors.light_grey}`, '0')}
`

export const SelectTireSizeDisclaimer = styled.div`
  ${Mixin.font.GothamBold(18)}
  text-align: center;
  margin-bottom: 25px;
`
