import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Theme from 'components/common/Theme/Theme'
import { STORE_NOT_ELIGIBLE_FOR_PROMOTION_MODAL_TITLE } from 'components/QuickAppointmentPage/ThreeStepper/constants.js'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'

const StoreNotEligibleForPromoModal = ({
  handleHide,
  proceedWithoutPromo,
  storeAddress,
  changeStore
}) =>
  <Container
    title={ STORE_NOT_ELIGIBLE_FOR_PROMOTION_MODAL_TITLE }
    closeHandler={ handleHide }>
    <AddressWrapper>
      <Media.Desktop>
        Selected store: <BoldText>{storeAddress}</BoldText>
      </Media.Desktop>
      <Media.Mobile>
        <Text>Selected store: <strong>{storeAddress}</strong></Text>
      </Media.Mobile>
    </AddressWrapper>

    <Text>
      Sorry! Your selected store is not eligible for the selected promotion.
    </Text>
    <Text>
      Please change store or proceed without promotion
    </Text>

    <ButtonsWrapper>
      <ChangeStoreButton onClick={ changeStore }>
        <PromoIcon asset="tire-promo-white" width="20px" alt="Promo Icon" />
        Change Store
        <ArrowIcon asset="arrow-right-white-secondary" width="12px" alt="Promo Icon" />
      </ChangeStoreButton>
      <ProceedWoPromoButton onClick={ proceedWithoutPromo }>
        Proceed Without Promotion &gt;
      </ProceedWoPromoButton>
    </ButtonsWrapper>
  </Container>

StoreNotEligibleForPromoModal.propTypes = {
  changeStore: PropTypes.func,
  handleHide: PropTypes.func,
  proceedWithoutPromo: PropTypes.func,
  storeAddress: PropTypes.string
}

export default StoreNotEligibleForPromoModal

const Container = styled(Modal)`
  max-width: 825px;
  width: 100%;
  min-height: 315px;
  ${Media.mobile`
    max-width: 335px;
    height: fit-content;
  `}

  & .modal-header {
    padding: 20px 20px;

    & h1 {
      margin: 0;
    }

    & button {
      display: none;
    }

    ${Media.mobile`
      padding: 10px 20px;

      & h1 {
        text-transform: none;
        transform: none;
      }
    `}
  }

  & .modal-body {
    padding: 20px 35px 40px 25px;
    ${Media.mobile`
      padding: 20px 20px 26px;
    `}
  }
`

const AddressWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 0 0 25px 0;
  border: 1px solid ${Theme.colors.black};
  ${Media.mobile`
    padding: 5px;
    margin: 0 0 15px 0;
    text-wrap: balance;

    & p:nth-child(1) {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }
  `}
`

const BoldText = styled.p`
  margin: 0 0 0 5px;
  padding: 0;
  font-weight: 600;
`

const Text = styled.p`
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 18px;
  ${Media.mobile`
    margin: 0 0 20px 0;
  `}
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;

  & button:nth-child(1) {
    margin: 0 15px 0 0;
    ${Media.desktop`
      &:hover {
        text-decoration: underline;
      }
    `}
  }

  & button:nth-child(2) {
    ${Media.desktop`
      &:hover {
        text-decoration: none;
      }
    `}
  }

  ${Media.mobile`
    flex-direction: column;

    & button:nth-child(1) {
      margin: 0 0 20px 0;
    }

    & button {
      width: auto;
    }
  `}
`

const ChangeStoreButton = styled.button`
  display: flex;
  align-items: center;
  width: 300px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  color: ${Theme.colors.white};
  background: ${Theme.colors.green};
  border: none;
  border-radius: 4px;
`

const PromoIcon = styled(Icon)`
  margin: 0 10px 0 60px;
  ${Media.mobile`
    margin: 0 20px 0 45px;
  `}
`

const ArrowIcon = styled(Icon)`
  margin: 0 15px 0 auto;
  ${Media.mobile`
    width: 10px;
    margin: 0 10px 0 auto;
  `}
`

const ProceedWoPromoButton = styled.button`
  width: 300px;
  height: 50px;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid ${Theme.colors.green};
  border-radius: 4px;
  color: ${Theme.colors.green};
  background: ${Theme.colors.white};
`
