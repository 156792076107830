import { compose, withHandlers, withState, lifecycle } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { NEW_SHOP_TIRES_MODAL, GLOBAL_SEARCH_SOURCE, TAB_YMME_NAME } from 'constant'
import NewShopTiresModal from './NewShopTiresModal'
import { connect } from 'react-redux'
import { hideGlobalSearchModal } from '../Header/HeaderSearch/actions'
import { getYmmeValues, getYmmeOptions } from '../common/hoc/withYmme/selectors'
import {
  handleYearSelect,
  handleMakeSelect,
  handleModelSelect,
  handleSubModelSelect
} from '../NewYMME/actions'
import { setValues } from '../common/hoc/withYmme/actions'
import { MAKES, YEARS, MODELS, SUB_MODELS } from '../NewTireSearch/constant'

const withTabsState = withState('tab', 'setTab', ({ tabName }) => tabName || TAB_YMME_NAME)
const withHoverState = withState('hover', 'setHover', false)
const withYmmeValues = withState('ymmeValuesHelper', 'setYmmeValuesHelper', {})
const withPreYmmeOptions = withState('preYmmeOptions', 'setPreYmmeOptions', {})

const mapStateToProps = (state, { ymmeName }) => ({
  additionalYmmeValues: getYmmeValues(state, { ymmeName }),
  ymmePreOptions: getYmmeOptions(state, { ymmeName })
})

const withNewShopTiresModalHandlers = withHandlers({
  setTabHandler: ({ setTab }) => (tabName) => {
    setTab(tabName)
  },
  onMouseEnterHandler: ({ setHover }) => () => {
    setHover(true)
  },
  onMouseLeaveHandler: ({ setHover }) => () => {
    setHover(false)
  },
  closeModal: ({
    handleHide,
    ymmeName,
    ymmeValuesHelper,
    handleYearSelect,
    handleMakeSelect,
    handleModelSelect,
    handleSubModelSelect,
    preYmmeOptions,
    setValues
  }) => async () => {
    const { years, makes, models, subModels } = ymmeValuesHelper
    const { years: yearOptions, makes: makeOptions, models: modelOptions, subModels: subModelOptions } = preYmmeOptions

    handleYearSelect({ value: years, ymmeName })
    handleMakeSelect({ value: makes, ymmeName })
    handleModelSelect({ value: models, ymmeName })
    handleSubModelSelect({ value: subModels, ymmeName })
    setValues({ name: YEARS, value: yearOptions, ymmeName })
    setValues({ name: MAKES, value: makeOptions, ymmeName })
    setValues({ name: MODELS, value: modelOptions, ymmeName })
    await setValues({ name: SUB_MODELS, value: subModelOptions, ymmeName })
    handleHide()
  }
})

const withNewShopTiresModalInitialize = lifecycle({
  componentDidMount() {
    const {
      source,
      hideGlobalSearchModal,
      setYmmeValuesHelper,
      additionalYmmeValues,
      setPreYmmeOptions,
      ymmePreOptions
    } = this.props
    setYmmeValuesHelper(additionalYmmeValues)
    setPreYmmeOptions(ymmePreOptions)

    if (source === GLOBAL_SEARCH_SOURCE) {
      hideGlobalSearchModal()
    }
  }
})

export default compose(
  registerReduxModal({ name: NEW_SHOP_TIRES_MODAL }),
  connect(mapStateToProps, {
    hideGlobalSearchModal,
    handleYearSelect,
    handleMakeSelect,
    handleModelSelect,
    handleSubModelSelect,
    setValues
  }),
  withTabsState,
  withHoverState,
  withPreYmmeOptions,
  withYmmeValues,
  withNewShopTiresModalInitialize,
  withNewShopTiresModalHandlers
)(NewShopTiresModal)
