export const TABS = [
  { title: 'Deals', noTabPanelFocus: true },
  { title: 'Services' },
  { title: 'Reviews' }
]

export const BOT_STORE_INFORMATION_TYPE = 'BOTStoreInformation'

export const SERVICES_TAB_INDEX = 1
export const REVIEWS_TAB_INDEX = 2

export const FLEET_CERTIFIED_MODAL = 'fleetCertifiedModal'

export const GOOGLE_MAPS_URL = 'https://www.google.com/maps/dir//'
