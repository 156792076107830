export default [
  'BOTFeaturedArticles',
  'BOTFeaturedDeals',
  'BOTServiceCategories',
  'BOTServiceCategoryDetails',
  'BOTServiceDetails'
]

/*
Appears on:
/services,
/services/tire-service-and-repair,
/services/tire-service-and-repair/tire-repair
*/
