import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import styled from 'styled-components'

const ReactSelectMenuList = ({ children, selectProps: { listboxId }, ariaLabel, ...props }) =>
  <components.MenuList { ...props }>
    <MenuListUL role="listbox" aria-label={ ariaLabel } id={ listboxId }>
      { children }
    </MenuListUL>
  </components.MenuList>

const MenuListUL = styled.ul`
  padding-left: 0;
  margin: 0;
`

export default ReactSelectMenuList

ReactSelectMenuList.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  handleHomeEndKeyDown: PropTypes.func,
  selectProps: PropTypes.shape({
    listboxId: PropTypes.string
  }),
  setKeypressScopeRef: PropTypes.func
}
