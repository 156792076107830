import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import { SearchInput, SearchInputWrapper, CloseBtn, ClearMobileButton } from 'components/NewTireSearch/SharedStyles'

const Search = ({ placeholder, onChangeHandler, search, resetSearch }) =>
  <SearchInputWrapper>
    <CustomSearchInput
      placeholder={ placeholder }
      aria-label={ placeholder }
      autoComplete="off"
      value={ search }
      onChange={ onChangeHandler } />
    <Media.Desktop>
      <CloseBtn
        alt="Clear search field"
        asset="menu-close"
        tabIndex="0"
        onClick={ resetSearch } />
    </Media.Desktop>
    <Media.Mobile>
      <ClearMobileButton link onClick={ resetSearch }>Clear</ClearMobileButton>
    </Media.Mobile>
  </SearchInputWrapper>

export default Search

Search.propTypes = {
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  resetSearch: PropTypes.func,
  search: PropTypes.string
}

const CustomSearchInput = styled(SearchInput)`
  width: 100%;
  padding: 0 45px 0 15px;
  height: 48px;
  border-radius: 6px;
`

