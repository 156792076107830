import { createSelector } from 'helpers/reselect'
import { fieldState } from './model'
import { mapFields, findLabelUsingValue, isLoading, isValid } from './helpers'

// selectors
export const getYmme = (state, props, ymmeName = '') => ymmeName ? state.ymme[ymmeName] : state.ymme[props.ymmeName]

export const getYmmeFields = createSelector(
  [
    getYmme
  ],
  ({ fields } = {}) => fields
)

export const getYmmeError = createSelector(
  [
    getYmme
  ],
  ({ error } = {}) => error
)

export const getYmmeOptions = createSelector(
  [
    getYmmeFields
  ],
  ({
    years = fieldState,
    makes = fieldState,
    models = fieldState,
    subModels = fieldState
  } = {}) => ({
    years: years.values,
    makes: makes.values,
    models: models.values,
    subModels: subModels.values
  })
)

export const getYmmeWithNormalizedFieldsValues = createSelector(
  [
    getYmmeFields
  ],
  ({
    years = fieldState,
    makes = fieldState,
    models = fieldState,
    subModels = fieldState
  } = {}) => ({
    years: {
      ...years,
      values: years.values.map(year => ({ label: String(year), value: year }))
    },
    makes: {
      ...makes,
      values: makes.values.map(mapFields({ label: 'makeDesc', value: 'makeId' }))
    },
    models: {
      ...models,
      values: models.values.map(mapFields({ label: 'modelName', value: 'modelId' }))
    },
    subModels: {
      ...subModels,
      values: subModels.values.map(mapFields({ label: 'subModelDesc', value: 'subModelId' }))
    }
  })
)

export const getYmmeVehicleId = createSelector(
  [getYmme],
  ({ vehicleId } = {}) => vehicleId
)

export const getYmmeBaseVehicleId = createSelector(
  [getYmme],
  ({ baseVehicleId } = {}) => baseVehicleId
)

export const getYmmeSubModelTypes = createSelector(
  [getYmme],
  ({ subModelTypes } = {}) => subModelTypes
)

export const getYmmeInitialized = createSelector(
  [getYmme],
  ({ ymmeInitialized } = {}) => ymmeInitialized
)

export const getYmmeForConfirmTireSizePage = createSelector(
  [
    getYmmeWithNormalizedFieldsValues,
    getYmmeVehicleId,
    getYmmeBaseVehicleId
  ],
  ({ years, makes, models, subModels } = {}, vehicleId, baseVehicleId) => ({
    vehicleId,
    baseVehicleId,
    yearDesc: years.value,
    makeId: makes.value,
    makeDesc: findLabelUsingValue(makes),
    modelId: models.value,
    modelDesc: findLabelUsingValue(models),
    subModelId: subModels.value,
    subModelDesc: findLabelUsingValue(subModels)
  })
)

export const getYmmeValues = createSelector(
  [getYmmeWithNormalizedFieldsValues],
  ({ years, makes, models, subModels } = {}) => ({
    years: years.value,
    makes: makes.value,
    models: models.value,
    subModels: subModels.value
  })
)

export const getYmmeMetaState = createSelector(
  [
    getYmmeFields,
    getYmmeError
  ],
  (fields = [], ymmeError = '') => ({
    isLoading: isLoading(fields),
    isValid: isValid(fields) && !ymmeError
  })
)

export const getYmmeTireSizesParams = createSelector(
  [
    getYmmeValues,
    getYmmeVehicleId
  ],
  (ymmeValues, vehicleId) => ({
    vehicleId
  })
)

export const makeGetYmmeState = () =>
  createSelector(
    [
      getYmmeWithNormalizedFieldsValues,
      getYmmeValues,
      getYmmeMetaState,
      getYmmeVehicleId,
      getYmmeTireSizesParams,
      getYmmeError
    ],
    (ymme, ymmeValues, ymmeMeta, ymmeVehicleId, ymmeTireSizesParams, ymmeError) => ({
      ymme,
      ymmeValues,
      ymmeMeta,
      ymmeVehicleId,
      ymmeTireSizesParams,
      ymmeError
    })
  )
