export default [
  'BOTPromotionLightbox',
  'BOTStoreLocatorSearchMain',
  'BOTStoreLocatorSearchSummary'
]

/*
Appears on:
/modals/promo-lightbox,
/modals/store-locator-modal,
/store-locator
*/
