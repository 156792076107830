import React from 'react'
import PropTypes from 'prop-types'
import { BOT_VEHICLE_SELECTOR_ID, BOT_VEHICLE_SELECTOR_PLACEHOLDER_ID, PAGE_HEADER_CHANGED } from 'constant'
import Portal from 'components/common/Portal/Portal'
import Placeholder from 'components/common/Placeholder'
import toggle from 'components/common/hoc/toggle'
import VehicleSelector from './VehicleSelector'

const WrappedVehicleSelector = props =>
  <React.Fragment>
    <Portal id={ BOT_VEHICLE_SELECTOR_ID }>
      <VehicleSelector { ...props } />
    </Portal>

    <Portal id={ BOT_VEHICLE_SELECTOR_PLACEHOLDER_ID }>
      {
        props.mainVehicleSelectorBlock[0]['@type'] === 'ActiveVehicle' ?
          <React.Fragment>
            {/* non empty car bar */}
            {/* desktop */}<Placeholder height="101px" alternative="280px" condition={ props.expanded } desktopOnly />
            {/* mobile */}<Placeholder height="58px" alternative="363px" condition={ props.expanded } mobileOnly />
          </React.Fragment> :
          <React.Fragment>
            {/* empty car bar */}
            {/* desktop */}<Placeholder height="76px" alternative="227px" condition={ props.expanded } desktopOnly />
            {/* mobile */}<Placeholder height="61px" alternative="350px" condition={ props.expanded } mobileOnly />
          </React.Fragment>
      }
    </Portal>
  </React.Fragment>

WrappedVehicleSelector.propTypes = {
  expanded: PropTypes.bool,
  mainVehicleSelectorBlock: PropTypes.arrayOf(PropTypes.shape({
    '@type': PropTypes.string
  }))
}

const ConnectedVehicleSelector = toggle(WrappedVehicleSelector, false, PAGE_HEADER_CHANGED)

ConnectedVehicleSelector.type = 'VehicleSelector'
ConnectedVehicleSelector.placeholders = ['mainVehicleSelectorBlock', 'extendedVehicleSelectorBlock']

export default ConnectedVehicleSelector
