import { replace } from 'connected-react-router'
import { createAction } from 'redux-actions'
import { getPageLocation } from 'selectors'
import { createPath, getDefaultPath } from './helpers'

export const setFiltersData = createAction('GLOBAL_SEARCH_RESULT_PAGE/SET_FILTERS_DATA')
export const removeFiltersData = createAction('GLOBAL_SEARCH_RESULT_PAGE/REMOVE_FILTERS_DATA')
export const setGlobalSearchView = createAction('GLOBAL_SEARCH_RESULT_PAGE/SET_GLOBAL_SEARCH_VIEW')

export const applyFilter = ({ navigationState } = {}) => (dispatch, getState) => {
  const location = getPageLocation(getState())
  const { search, pathname } = navigationState ? createPath(navigationState) : getDefaultPath(location)

  dispatch(replace({
    ...location,
    pathname,
    search
  }))
}

export const resetFilters = (navigationState = '') => applyFilter({ navigationState })
