import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import Theme from 'components/common/Theme/Theme'
import { setRequiredAttrs } from '../../helpers'

const ReactAutosuggestInput = ({
  inputProps,
  suggestions,
  renderSuggestion,
  renderInputComponent,
  getSuggestionValue,
  handleSuggestionSelect,
  handleSuggestionsUpdate,
  maxHeight,
  className,
  required,
  onSuggestionsClearRequested,
  ...props
}) =>
  <StyledAutosuggest maxHeight={ maxHeight } className={ className }>
    <Autosuggest
      { ...props }
      className={ className }
      maxHeight={ maxHeight }
      inputProps={ inputProps }
      suggestions={ suggestions }
      renderSuggestion={ renderSuggestion }
      renderInputComponent={ renderInputComponent }
      focusInputOnSuggestionClick={ false }
      { ...setRequiredAttrs(required) }
      getSuggestionValue={ getSuggestionValue }
      onSuggestionSelected={ handleSuggestionSelect }
      onSuggestionsClearRequested={ onSuggestionsClearRequested }
      onSuggestionsFetchRequested={ handleSuggestionsUpdate } />
  </StyledAutosuggest>

export default ReactAutosuggestInput

ReactAutosuggestInput.propTypes = {
  className: PropTypes.string,
  getSuggestionValue: PropTypes.func,
  handleSuggestionSelect: PropTypes.func,
  handleSuggestionsUpdate: PropTypes.func,
  inputProps: PropTypes.shape(),
  maxHeight: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  onSuggestionsClearRequested: PropTypes.func,
  renderInputComponent: PropTypes.func,
  renderSuggestion: PropTypes.func,
  required: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.object)
}

const StyledAutosuggest = styled.div`
  position: relative;

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 2.857em;
    z-index: 899;
    padding: 10px 0;
    width: 100%;
    max-height: ${props => props.maxHeight || 250}px;
    overflow-y: auto;
    background-color: ${Theme.colors.white};
    border: 1px solid ${Theme.colors.transparent_light_blue};
    border-top: none;
    box-shadow: 0 0 2px ${Theme.colors.transparent_blue};
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__input input:required {
    box-shadow: none;
  }
`
