import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'components/common/Icon/Icon'
import { A } from 'components/common/Link/Link'

const ICON_DEFAULT_ASSET = 'twitter'
const ICON_DEFAULT_ALT = 'twitter'

const DEFAULT_WIDTH = 24
const DEFAULT_HEIGHT = 24

const MediaBlock = ({ name, link: { path }, width, height, ...props }) => {
  const alt = name.toLowerCase()
  const asset = alt.replace(/\s/g, '-')

  return (
    <SocialLink data-at-footer-socialmedialink href={ path || '/' } target="_blank" rel="noopener noreferrer">
      <Icon
        asset={ asset || ICON_DEFAULT_ASSET }
        alt={ `Follow us on ${alt || ICON_DEFAULT_ALT}` }
        width={ width || DEFAULT_WIDTH }
        height={ height || DEFAULT_HEIGHT } />
    </SocialLink>)
}


MediaBlock.propTypes = {
  '@type': PropTypes.string,
  altText: PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  imageUrl: PropTypes.string,
  link: PropTypes.shape({
    '@class': PropTypes.string,
    linkType: PropTypes.string,
    path: PropTypes.string,
    queryString: PropTypes.string
  }),
  name: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

MediaBlock.type = 'Media'

export default MediaBlock

const SocialLink = styled(A)`
  margin-left: 20px;
`
