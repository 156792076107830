import qs from 'qs'
import { rest } from 'services'
import { checkIfRedirectNeeded, redirect } from 'helpers/redirect'
import { createAction } from 'redux-actions'
import { loadRestComponents } from 'helpers/component-helper'

export const APP_REQUEST = createAction('MAIN/APP_REQUEST')
export const APP_SUCCESS = createAction('MAIN/APP_SUCCESS')
export const APP_FAILURE = createAction('MAIN/APP_FAILURE')
export const APP_START_LOADING = createAction('MAIN/APP_START_LOADING')
export const APP_STOP_LOADING = createAction('MAIN/APP_STOP_LOADING')


export const init = ({ pathname, timeout, headers = {}, query = '', setLocalLoadingState = null }) => (dispatch, getState) => {
  !setLocalLoadingState && dispatch(APP_REQUEST())
  const params = {
    ...typeof query === 'string' ? qs.parse(query) : query,
    format: 'json'
  }

  return rest
    .getOnce(pathname, {
      timeout,
      params,
      headers
    })
    .then(res => loadRestComponents(res.data).then(() => res))
    .then(({ data, ...props }) => {
      const redirectData = checkIfRedirectNeeded(data)

      dispatch(redirectData ? redirect(redirectData) : APP_SUCCESS({ data }))
      setLocalLoadingState && setLocalLoadingState(false)

      return { data, ...props }
    })
    .catch(err => {
      if (err.canceled) {
        return // do nothing if request was canceled
      }

      dispatch(APP_FAILURE({ data: err }))

      throw err
    })
}
