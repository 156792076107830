import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'

const OriginalEquipmentComponent = ({ diameter, rearDiameter, staggered }) =>
  <OriginalEquipment data-at-original-equipment-size>
    Original Equipment Wheel Diameter:
    { staggered && <br /> }
    {
      staggered ?
        ` Front ${diameter}" / Rear ${rearDiameter}"` :
        ` ${diameter}"`
    }
  </OriginalEquipment>

OriginalEquipmentComponent.propTypes = {
  diameter: PropTypes.string,
  rearDiameter: PropTypes.string,
  staggered: PropTypes.bool
}

export default OriginalEquipmentComponent

const OriginalEquipment = styled.div`
  margin-bottom: 15px;
  ${Mixin.font.GothamMedium(18)}
`
