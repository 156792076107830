import { connect } from 'react-redux'
import { compose, withProps, lifecycle } from 'recompose'
import withType from 'components/common/hoc/withType'
import withShowConfirmTireSizeModalPreconfigured from 'components/common/hoc/withShowConfirmTireSizeModalPreconfigured'
import getSearchByVehicleUrl from 'helpers/getSearchByVehicleUrl'
import { isWideMobile } from 'components/common/Media/helpers'
import { getSearchByTireSizeUrlFromVehicle } from 'helpers/getSearchByTireSizeUrl'
import getActiveVehicle, { getIsActiveVehicleTrailer, getActiveVehicleName } from 'selectors/getActiveVehicle'
import ActiveVehicle from './ActiveVehicle'
import { getYmmeForConfirmTireSizePage } from '../../../common/hoc/withYmme/selectors'
import { getVehicleName } from '../../../VehicleConfigurator/components/ConfirmTireSize/helpers'
import { getVehicleCount, getHasActiveVehicleWithTireSize } from 'selectors'
import { YMME_NAME } from 'constant'
import { isEqual } from 'lodash'
import { setExtraVehicleDetails } from 'actions/confirmTireSize'

const mapStateToProps = state => {
  const activeVehicle = getActiveVehicle(state)
  const isTrailer = getIsActiveVehicleTrailer(state)
  const {
    vehicleDetails: { vehicleId } = {},
    vehicleDetails
  } = getYmmeForConfirmTireSizePage(state, { ymmeName: YMME_NAME }) || {}

  return {
    isTrailer,
    searchUrl: isTrailer ?
      getSearchByTireSizeUrlFromVehicle({ activeVehicle, isTrailer }) :
      getSearchByVehicleUrl(activeVehicle),
    vehicleCount: getVehicleCount(state),
    hasActiveVehicleWithTireSize: getHasActiveVehicleWithTireSize(state),
    activeVehicleName: getActiveVehicleName(state),
    vehicleDetails,
    vehicleId,
    numberOfAvailableTires: state.availableTires.numberOfAvailableTires,
    isWideMobile: isWideMobile(),
    isPilotStore: state.storeLocatorModal.isPilotStore,
    isNumberOfTiresLoading: state.availableTires.isNumberOfTiresLoading
  }
}

const withInit = lifecycle({
  componentDidUpdate({ vehicleId: prevVehicleId }) {
    const {
      vehicleCount,
      hasActiveVehicleWithTireSize,
      setExtraVehicleDetails,
      vehicleDetails,
      activeVehicleName,
      vehicleId
    } = this.props

    const vehicleName = getVehicleName({ ...vehicleDetails })

    if (
      vehicleId &&
      vehicleCount &&
      !hasActiveVehicleWithTireSize &&
      (vehicleName === activeVehicleName) &&
      (!isEqual(vehicleId, prevVehicleId))
    ) {
      setExtraVehicleDetails(vehicleDetails)
    }
  }
})

const withActiveVehicleProps = withProps(({
  activeVehicle: {
    userVehicle: [{
      vehicleColor,
      masterVehicle: {
        yearDesc,
        makeDesc,
        modelDesc,
        subModelDesc
      } = {}
    }] = []
  } = {}
}) => ({
  year: yearDesc,
  make: makeDesc,
  model: modelDesc,
  submodel: subModelDesc,
  color: vehicleColor
}))

export default compose(
  withType({ type: 'ActiveVehicle' }),
  connect(mapStateToProps, { setExtraVehicleDetails }),
  withActiveVehicleProps,
  withShowConfirmTireSizeModalPreconfigured,
  withInit
)(ActiveVehicle)
