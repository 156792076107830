import { compose, withProps, withStateHandlers, withHandlers, lifecycle } from 'recompose'
import withForwardingRef from 'components/common/hoc/withForwardRef'
import withKeyPress from 'components/common/hoc/withKeyPress/withKeyPress'
import { filterOptions } from './helpers'
import debounce from 'lodash/debounce'
import { MenuList, Menu, Option, Control } from './components'
import {
  ReactSelectInput,
  ReactSelectDropdownIndicator,
  ReactSelectContainer,
  ReactSelectValueContainer
} from 'components/common/FormElements/Input/ReactSelectDropdownInput/components'
import {
  INITIAL_TOP_POSITION,
  INITIAL_LEFT_POSITION,
  MAX_ROWS,
  MIN_WIDTH,
  ROW_WIDTH,
  COMPRESSED_ROW_WIDTH
} from './constants'
import ReactSelectDropdownInput from 'components/common/FormElements/Input/ReactSelectDropdownInput/ReactSelectDropdownInput'
import { getSelectedOption } from 'components/common/FormElements/Input/ReactSelectDropdownInput/helpers'
import { isTablet } from 'components/common/Media/helpers'

const withSearchInputStateHandlers = withStateHandlers({
  searchInputRef: null,
  searchFilter: ''
}, {
  setSearchInputRef: () => searchInputRef => ({ searchInputRef }),
  setSearchFilter: () => e => ({ searchFilter: e.target.value }),
  clearSearchFilter: ({ searchInputRef }) => () => {
    searchInputRef && searchInputRef.focus()
    return { searchFilter: '' }
  }
})

const withDropdownStateHandlers = withStateHandlers(({
  verticalOffset = 0,
  horizontalOffset = 0
}) => ({
  dropdownWrapperRef: null,
  menuIsOpen: false,
  topPosition: INITIAL_TOP_POSITION + verticalOffset,
  leftPosition: INITIAL_LEFT_POSITION + horizontalOffset,
  popoverWidth: MIN_WIDTH,
  shrunkMode: isTablet()
}), {
  setDropdownWrapperRef: () => dropdownWrapperRef => ({ dropdownWrapperRef }),
  setShrunkMode: () => shrunkMode => ({ shrunkMode }),
  handleOnMenuOpen: ({ menuIsOpen }, { clearSearchFilter }) => () => {
    !menuIsOpen && clearSearchFilter()
    return { menuIsOpen: !menuIsOpen }
  },
  handleOnMenuClose: (_, { searchInputRef }) => () => {
    const preventClosing = searchInputRef === document.activeElement
    return { menuIsOpen: preventClosing }
  },
  handleUpdateWitdhAndPosition: ({ shrunkMode }, {
    compressedRows,
    horizontalOffset = 0,
    options = []
  }) => () => {
    const rowWidth = compressedRows ? COMPRESSED_ROW_WIDTH : ROW_WIDTH
    const popoverWidth = options.length <= MAX_ROWS || shrunkMode
      ? MIN_WIDTH
      : Math.ceil(options.length / MAX_ROWS) * rowWidth
    const leftPosition = (INITIAL_LEFT_POSITION + horizontalOffset) - popoverWidth
    return { leftPosition, popoverWidth }
  }
})

const withDropdownHandlers = withHandlers({
  handleSearchInputClick: ({ searchInputRef }) => () =>
    searchInputRef && document.activeElement !== searchInputRef && searchInputRef.focus(),
  handleOnMenuFocusout: ({ dropdownWrapperRef, handleOnMenuClose }) => e =>
    (!e.relatedTarget || !dropdownWrapperRef.contains(e.relatedTarget)) && handleOnMenuClose(),
  handleResize: ({ setShrunkMode }) => debounce(() => setShrunkMode(isTablet()), 100)
})

const withKeyHandlers = withHandlers({
  space: ({ handleOnMenuOpen }) => e => {
    e.preventDefault()
    e.stopPropagation()
    handleOnMenuOpen()
  }
})

const withPropsAdapter = withProps(({
  options = [],
  value,
  reset,
  error,
  innerRef,
  searchFilter,
  matchFromStart = true,
  ...props
}) => {
  const isError = Boolean(error)
  const disabled = !options.length
  return {
    isError,
    disabled,
    ref: innerRef,
    options: filterOptions(options, searchFilter),
    isSearchable: false,
    backspaceRemovesValue: false,
    tabSelectsValue: false,
    openMenuOnClick: true,
    openMenuOnFocus: false,
    value: reset ? null : getSelectedOption({ options, value }),
    ...props,
    components: {
      Control,
      Menu,
      MenuList,
      Option,
      Input: ReactSelectInput,
      ValueContainer: ReactSelectValueContainer,
      DropdownIndicator: ReactSelectDropdownIndicator,
      SelectContainer: ReactSelectContainer
    }
  }
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { handleResize } = this.props
    window.addEventListener('resize', handleResize)
  },
  componentDidUpdate({
    menuIsOpen: prevMenuIsOpen,
    shrunkMode: prevShrunkMode,
    searchInputRef: prevInputRef,
    dropdownWrapperRef: prevDropdownWrapperRef
  }) {
    const {
      menuIsOpen,
      searchInputRef,
      dropdownWrapperRef,
      shrunkMode,
      handleOnMenuFocusout,
      handleUpdateWitdhAndPosition
    } = this.props
    prevInputRef !== searchInputRef && searchInputRef && searchInputRef.focus()
    if ((prevMenuIsOpen !== menuIsOpen && menuIsOpen) || (prevShrunkMode !== shrunkMode)) {
      handleUpdateWitdhAndPosition()
    }
    if (prevDropdownWrapperRef !== dropdownWrapperRef && dropdownWrapperRef) {
      dropdownWrapperRef.addEventListener('focusout', handleOnMenuFocusout)
    }
  },
  componentWillUnmount() {
    const {
      dropdownWrapperRef,
      handleOnMenuFocusout,
      handleResize
    } = this.props
    dropdownWrapperRef.removeEventListener('focusout', handleOnMenuFocusout)
    window.removeEventListener('resize', handleResize)
  }
})

export default compose(
  withSearchInputStateHandlers,
  withDropdownStateHandlers,
  withDropdownHandlers,
  withKeyHandlers,
  withKeyPress,
  withPropsAdapter,
  withForwardingRef,
  withLifecycle
)(ReactSelectDropdownInput)
