import React from 'react'
import PropTypes from 'prop-types'

const HeaderMobileMainContent = ({
  hidden,
  expanded,
  toggleHandlers,
  placeholders: {
    headerContent = []
  } = {}
}) =>
  <div hidden={ hidden }>
    {
      React.Children.map(headerContent, content =>
        React.cloneElement(content, {
          mobileMainMenuExpanded: expanded,
          mobileMainMenuToggleHandlers: toggleHandlers
        })
      )
    }
  </div>

HeaderMobileMainContent.propTypes = {
  expanded: PropTypes.bool,
  hidden: PropTypes.bool,
  placeholders: PropTypes.shape({
    headerContent: PropTypes.arrayOf()
  }),
  toggleHandlers: PropTypes.shape()
}
export default HeaderMobileMainContent
