import { show, hide } from 'redux-modal'
import { createAction } from 'redux-actions'
import { reset, SubmissionError } from 'redux-form'
import { rest, isApiError } from 'services'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { FORM_NAME as ShopByPlateFormName } from '../ShopByPlateForm'
import { CONFIRM_YOUR_VEHICLE_MODAL } from '../ConfirmYourVehicleModal'

export const getStatesRequest = createAction('SHOP_BY_LICENCE_PLATE/GET_STATES_REQUEST')
export const getStatesSuccess = createAction('SHOP_BY_LICENCE_PLATE/GET_STATES_SUCCESS')
export const getStatesFailure = createAction('SHOP_BY_LICENCE_PLATE/GET_STATES_FAILURE')

export const getVehicleByPlateNumberRequest = createAction('SHOP_BY_LICENCE_PLATE/GET_VEHICLE_BY_PLATE_NUMBER_REQUEST')
export const getVehicleByPlateNumberSuccess = createAction('SHOP_BY_LICENCE_PLATE/GET_VEHICLE_BY_PLATE_NUMBER_SUCCESS')
export const getVehicleByPlateNumberFailure = createAction('SHOP_BY_LICENCE_PLATE/GET_VEHICLE_BY_PLATE_NUMBER_FAILURE')

export const getStates = () => async dispatch => {
  dispatch(getStatesRequest())

  try {
    const { garageResponse: { states }} = await rest.api.getStates()
    dispatch(getStatesSuccess(states))
  } catch (err) {
    dispatch(getStatesFailure(err))
  }
}


// TODO maybe will be needed to separate getting vehilce and getting tire sizes
export const getVehicleByPlateNumber = (values, tireSizesNotFoundModalProps, onVehicleSelect) => async dispatch => {
  dispatch(getVehicleByPlateNumberRequest())

  try {
    const { garageResponse: { vinDecodeTypes = []}} = await rest.api.getVehicleByPlateNumber(values)
    const plateNumberDetails = {
      licencePlateNumber: values.licensePlate,
      state: values.state
    }

    dispatch(getVehicleByPlateNumberSuccess())

    if (vinDecodeTypes.length === 1) {
      const [{ ...vehicleData }] = vinDecodeTypes

      dispatch(setVehicleDetails({ ...vehicleData, ...plateNumberDetails }))

      await onVehicleSelect({ ...vehicleData, tireSizesNotFoundModalProps })
    } else if (vinDecodeTypes.length > 1) {
      // carfax returns multiple cars
      dispatch(show(CONFIRM_YOUR_VEHICLE_MODAL, {
        vehicles: vinDecodeTypes,
        licensePlate: values.licensePlate,
        state: values.state,
        submit: async ({ vehicleId, ...vehicleData }) => {
          dispatch(setVehicleDetails(vehicleData))

          try {
            await onVehicleSelect({ vehicleId, ...vehicleData, tireSizesNotFoundModalProps })
          } catch (err) {
            console.log(err)
          } finally {
            dispatch(hide(CONFIRM_YOUR_VEHICLE_MODAL))
          }
        }
      }))
    }
  } catch (err) {
    dispatch(getVehicleByPlateNumberFailure(err))

    if (isApiError(err)) {
      throw new SubmissionError({
        _error: err.status && err.status.description
      })
    }
  }
}

export const resetShopByPlateForm = name => dispatch =>
  dispatch(reset(name || ShopByPlateFormName))
