import { createAction } from 'redux-actions'
import { getIdsNotInStore } from './selectors'
import { rest } from 'services'

export const addReviews = createAction('BAZAAR_VOICE/ADD_REVIEWS')
export const setRenderBVReviews = createAction('BAZAAR_VOICE/SET_RENDER_BV_REVIEWS')

export const setRatings = ({ bvIds = []}) => (dispatch, getState) => {
  const idsNotInStore = [...new Set(getIdsNotInStore(getState(), { bvIds }))]
  if (idsNotInStore.length) {
    rest
      .api
      .getBazaarVoiceItemReviews(idsNotInStore)
      .then(({
        data: {
          Results = []
        } = {}
      }) => {
        dispatch(addReviews({
          reviews: {
            ...idsNotInStore.reduce((acc, id) => ({ ...acc, [id]: { average: 0, total: 0 }}), {}),
            ...Results.reduce((
              acc, {
                ProductStatistics: {
                  ProductId = '',
                  ReviewStatistics: {
                    AverageOverallRating = 0,
                    TotalReviewCount = 0
                  } = {}
                } = {}
              }) =>
              ({ ...acc, [ProductId]: { average: AverageOverallRating, total: TotalReviewCount }})
            , {})
          }
        }))
      })
  }
}
