import { createSelector } from 'helpers/reselect'
import { LIMIT_RESULTS, PHONE } from './constants'
import { storesFoundFor, storesFoundNearYou } from './helpers'
import { SEARCH_BY_LOCATION } from 'constant'

export const isMobile = ({ device }) => device === PHONE

export const getStoreLocator = ({ storeLocator = {}}) => ({ ...storeLocator })

export const getSearchRadius = ({ storeLocator: { searchRadius } = {}}) => searchRadius

export const getSearchResultsWithLimitedItems = createSelector(
  [
    getStoreLocator,
    isMobile
  ],
  ({ originalSearchResults = [], showLimitedSearchResultsForMobile, searchMask, initialSearchMethod }, isMobile) => ({
    searchTitle: initialSearchMethod === SEARCH_BY_LOCATION && searchMask ?
      storesFoundFor(originalSearchResults.length, searchMask) :
      storesFoundNearYou(originalSearchResults.length),
    searchResults: isMobile && showLimitedSearchResultsForMobile ?
      originalSearchResults.slice(0, LIMIT_RESULTS) :
      originalSearchResults,
    totalSearchResultsLength: originalSearchResults.length,
    searchHaveMoreResults: isMobile &&
      showLimitedSearchResultsForMobile &&
      originalSearchResults.length >= LIMIT_RESULTS
  })
)

export const getStoreLocatorState = createSelector(
  [
    getStoreLocator,
    getSearchResultsWithLimitedItems
  ],
  (storeLocator, searchResults) => ({
    ...storeLocator,
    ...searchResults
  })
)
