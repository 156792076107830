import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateNavigations } from './actions'

/* eslint-disable react/prop-types */

class NavigationsTracker extends React.Component {
  componentDidMount() {
    this.props.updateNavigations(this.props.location)
  }

  componentDidUpdate({ location: { pathname, search }}) {
    const { location: { pathname: newPathname, search: newSearch }} = this.props

    if (newPathname !== pathname || newSearch !== search) {
      this.props.updateNavigations(this.props.location)
    }
  }

  render() {
    return this.props.children
  }
}

export default compose(
  withRouter,
  connect(null, { updateNavigations })
)(NavigationsTracker)
