import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import LoginForm from './components/LoginForm'

const LoginWrapper = ({
  form,
  error,
  account,
  captchaRef,
  isLoading,
  loginHeader,
  loginContent,
  loginFooter,
  setInnerRef,
  handleCustomSubmit,
  handleSubmit
}) =>
  <Wrapper data-at-login>
    <React.Fragment>
      { loginHeader }
      <LoginForm
        form={ form }
        enter={ handleSubmit(handleCustomSubmit) }
        error={ error }
        account={ account }
        captchaRef={ captchaRef }
        isLoading={ isLoading }
        loginContent={ loginContent }
        setInnerRef={ setInnerRef }
        handleSubmit={ handleSubmit }
        handleCustomSubmit={ handleCustomSubmit } />
      { loginFooter }
    </React.Fragment>
  </Wrapper>

LoginWrapper.propTypes = {
  account: PropTypes.shape({
    captchaValue: PropTypes.string,
    isLocked: PropTypes.bool,
    locked: PropTypes.bool,
    description: PropTypes.string
  }),
  captchaRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) })
  ]),
  error: PropTypes.string,
  form: PropTypes.shape(),
  handleCustomSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  loginContent: PropTypes.arrayOf(PropTypes.node),
  loginFooter: PropTypes.arrayOf(PropTypes.node),
  loginHeader: PropTypes.arrayOf(PropTypes.node),
  setInnerRef: PropTypes.func
}

export default LoginWrapper

const Wrapper = styled.div`
  text-align: left;
  ${Mixin.responsive('width', '320px', '100%')}
  ${Mixin.responsive('padding', '28px 16px 20px', '25px 16px 20px')}
`
