import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'

const Switcher = ({ asset, children, dataAttributes, variantStyleEnabled, alternateHeading }) =>
  <SwitcherWrapper { ...dataAttributes } variantStyleEnabled={ variantStyleEnabled } bold={ alternateHeading }>
    <SwitcherIcon noLazyLoad asset={ asset } alt="" variantStyleEnabled={ variantStyleEnabled } />
    { children }
  </SwitcherWrapper>

Switcher.propTypes = {
  alternateHeading: PropTypes.bool,
  asset: PropTypes.string,
  children: PropTypes.node,
  dataAttributes: PropTypes.objectOf(PropTypes.bool),
  variantStyleEnabled: PropTypes.bool
}

export default Switcher

const SwitcherWrapper = styled.div`
  position: relative;
  padding: 13px 0 13px 70px;
  line-height: 19px;
  color: ${Theme.colors.semidark_grey};
  ${props => props.variantStyleEnabled && css`
    top: 15px;
    background-color: ${Theme.colors.light_blue};
    margin: 15px -20px 0 -20px;
    padding: 8px 0 8px 75px;
  `}
  ${props => props.bold && css`
    font-weight: 600;
  `}
  max-width: 400px;
`

const SwitcherIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  ${props => props.variantStyleEnabled && css`
    padding-left: 20px;
  `}
  width: 50px;
`
