import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const OrSeparator = ({ className }) =>
  <Wrapper className={ className }>
    <Or>or</Or>
  </Wrapper>

OrSeparator.propTypes = {
  className: PropTypes.string
}

export default OrSeparator

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`

const Or = styled.p`
  position: relative;
  margin: 0;
  text-transform: uppercase;
  text-align: center;

  ::after {
    position: absolute;
    display: block;
    content: '';
    height: 1px;
    width: 15px;
    left: 58%;
    top: 50%;
    transform: translate(-50%, 0);
    background: #000;
  }

  ::before {
    position: absolute;
    display: block;
    content: '';
    height: 1px;
    width: 15px;
    right: 53%;
    top: 50%;
    transform: translate(-50%, 0);
    background: #000;
  }
`

