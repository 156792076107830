import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from 'components/common/Link/Link'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import { dynamicDataAttribute, injectProps, setLinkDataAttrVal, toOneString } from 'helpers/utils'
import { MenuItem, MenuTitle, MenuTitleText } from '../SharedStyles'

const MenuItemWithSubmenu = ({
  mobileMainMenuExpanded,
  mobileMainMenuToggleHandlers,
  expanded,
  toggleHandlers,
  links,
  name
}) =>
  <MenuItem expanded={ expanded } hidden={ mobileMainMenuExpanded && !expanded } { ...dynamicDataAttribute('menu-item', toOneString(name)) }>
    <MenuTitle
      expanded={ expanded }
      data-at-menu-title
      onClick={
        () => {
          toggleHandlers.onClick()
          mobileMainMenuToggleHandlers.onClick()
        }
      }
      onKeyPress={
        () => {
          toggleHandlers.onKeyPress()
          mobileMainMenuToggleHandlers.onKeyPress()
        }
      }>
      <Back hidden={ !expanded } >
        <BackIcon asset="arrow-left-white" alt="" height={ 16 } data-at-back-arrow />
      </Back>
      <MenuTitleText narrow>
        { name }
      </MenuTitleText>

      <FollowIcon asset="arrow-right-white" alt="" width={ 10 } hidden={ expanded } data-at-follow-arrow />
    </MenuTitle>
    <SubMenu hidden={ !expanded } >
      {
        React.Children.map(links, link => <SubMenuItem>{ injectProps(link, { ...dynamicDataAttribute('sub-menu-item', setLinkDataAttrVal(link)) }) }</SubMenuItem>)
      }
    </SubMenu>
  </MenuItem>

MenuItemWithSubmenu.propTypes = {
  expanded: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape()),
  mobileMainMenuExpanded: PropTypes.bool,
  mobileMainMenuToggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  name: PropTypes.string,
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })

}

export default MenuItemWithSubmenu


const FollowIcon = styled(Icon)`
  position: absolute;
  right: 22px;
  margin-top: 3px;
`

const Back = styled.div`
  position: absolute;
  top: 2px;
  left: 8px;
  ${Mixin.font.GothamBold(16)}
`

const BackIcon = styled(Icon)`
  margin: -2px 8px 0 0;
`

const SubMenu = styled.div`
  font-weight: normal;
`

const SubMenuItem = styled.div`
  & ${Link} {
    display: block;
    padding: 20px 0;
    color: ${Theme.colors.white};
    text-transform: initial;
    ${Mixin.font.GothamBook(16)}
    border-bottom: 1px solid  ${Theme.colors.light_grey};

    :hover {
      text-decoration: none;
    }
  }
`
