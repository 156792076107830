import markerRedSmall from './assets/marker-red-small.png'
import markerRed from './assets/marker-red.png'
import markerRedBig from './assets/marker-red-big.png'

export const DEFAULT_MARKER = 'marker-red'

export const assetMap = {
  'marker-red-small': markerRedSmall,
  [DEFAULT_MARKER]: markerRed,
  'marker-red-large': markerRedBig
}
