import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import SessionTimeOutMessage from 'components/common/SessionTimeOutMessage'
import withType from 'components/common/hoc/withType'
import toggle from 'components/common/hoc/toggle'
import { HEADER_ID, HEADER_STORE_ID } from 'constant'

const MobilePageHeader = ({
  expanded,
  noStickyHeader,
  toggleHandlers,
  placeholders: {
    headerContentBlock = [],
    myStoreContentBlock = []
  } = {},
  sticky
}) =>
  <Media.Mobile>
    <Placeholder />
    <HeaderMobileContainer expanded={ expanded } id={ HEADER_ID } noStickyHeader={ noStickyHeader } sticky={ sticky }>
      <SessionTimeOutMessage />
      { React.Children.map(headerContentBlock, content =>
        React.cloneElement(content, { expanded, toggleHandlers })) }
    </HeaderMobileContainer>
    <MystoreMobileContainer expanded={ expanded } id={ HEADER_STORE_ID }>
      { React.Children.map(myStoreContentBlock, content =>
        React.cloneElement(content, { expanded, toggleHandlers })) }
    </MystoreMobileContainer>
  </Media.Mobile>

MobilePageHeader.propTypes = {
  expanded: PropTypes.bool,
  noStickyHeader: PropTypes.bool,
  placeholders: PropTypes.shape({
    headerContentBlock: PropTypes.arrayOf(PropTypes.node)
  }),
  sticky: PropTypes.bool,
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

export default compose(
  withType({ type: 'MobilePageHeader', placeholders: ['headerContentBlock', 'myStoreContentBlock']}),
  connect(({ sticky: { sticky } = {}}) => ({ sticky })),
  toggle
)(MobilePageHeader)

const HeaderMobileContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000; /* @TODO */
  width: 100%;
  height: ${props => props.expanded ? '100%' : 'auto'};
  max-height: 100%;
  overflow: auto;
  background: ${Theme.colors.crimson};
  ${props => props.noStickyHeader && css`
    box-shadow: 0 2px 4px ${Theme.colors.transparent_grey_hover};
  `}
  ${props => !props.sticky && css`
    border-bottom: 1px solid ${Theme.colors.light_grey}
  `}
`

const MystoreMobileContainer = styled.div`
  height: ${props => props.expanded ? '100%' : 'auto'};
  max-height: 100%;
  overflow: auto;
  background: ${Theme.colors.white};
`

const Placeholder = styled.div`
  height: 60px;
`
