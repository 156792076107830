import { show } from 'redux-modal'
import { TIRE_DETAILS_PROMOTION_MODAL } from 'constant'
import { createAction } from 'redux-actions'
import { setAllGBBDetails } from 'components/common/hoc/withGBBDetails/actions'
import isEmpty from 'lodash/isEmpty'
import { rest } from 'services'
import { processGBBDetails } from './helpers'
import { getGBBTitles } from 'components/OneColumnSearchResultPage/BOTRecommendedSearchResult/helpers'

export const setSkuId = createAction('TIRE_DETAILS/SET_SKUID_DATA')
export const setSource = createAction('TIRE_DETAILS/SET_SOURCE_DATA')

export const showTireDetailsPromotion = promotion => dispatch => {
  dispatch(show(TIRE_DETAILS_PROMOTION_MODAL, { promotion }))
}

export const updateGBBState = ({ tireSize, storeNumber }) => async (dispatch) => {
  let processedDetails = {}
  if (tireSize) {
    try {
      const {
        goodBetterBest
      } = await rest.api.getGBBDetails({ tireSize })
      const gBBTitles = getGBBTitles()
      processedDetails = processGBBDetails(goodBetterBest, gBBTitles)
    } catch (e) {
      console.error(e)
    }
  }
  dispatch(setAllGBBDetails({
    availability: !isEmpty(processedDetails),
    gBB: processedDetails,
    tireSize,
    storeNumber
  }))
}
