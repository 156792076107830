import { SHOPPING_DATA_KEY } from 'constant'
import { loadShoppingData, setShoppingData, cleanShoppingData } from 'actions/shoppingData'

const shoppingData = ({ getState }) => next => action => {
  if (action.type === String(loadShoppingData)) {
    const localStorageData = localStorage.getItem(SHOPPING_DATA_KEY)

    let shoppingData

    try {
      shoppingData = JSON.parse(localStorageData)
    } catch (err) {
      console.error(err)
    }

    return next({ type: action.type, payload: shoppingData })
  }

  if (action.type === String(setShoppingData)) {
    // eslint-disable-next-line no-unused-vars
    const { shoppingData: { calendarStartDate, ...shoppingDetails }} = getState()
    localStorage.setItem(SHOPPING_DATA_KEY, JSON.stringify({
      ...shoppingDetails,
      ...action.payload
    }))
  }

  if (action.type === String(cleanShoppingData)) {
    localStorage.removeItem(SHOPPING_DATA_KEY)
  }

  return next(action)
}

export default shoppingData
