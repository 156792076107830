import { handleActions } from 'redux-actions'
import { setSkuId, setSource } from './actions'

const initialState = {
  skuId: '',
  source: ''
}

const tireDetails = handleActions({
  [setSkuId]: (state, { payload }) => ({ ...state, ...payload }),
  [setSource]: (state, { payload }) => ({ ...state, ...payload })
}, initialState)

export default tireDetails
