export const SUBHEADING_MAP = {
  VehicleMakerSelector: 'Find Your Car, Light Truck, SUV, Trailer & Winter Tires at Big O Tires®!',
  VehicleModelSelector: 'Shop the Best Tire Brands for Your MAKE At Big O Tires®!',
  VehicleYearSelector: 'Save on Tires for Your MAKE MODEL Now at Big O Tires®!',
  VehicleSubmodelSelector: 'Big O Tires® Has the Right Tire for Your YEAR MAKE MODEL, Shop Today!'
}

export const BRANDS_SUBHEADING_MAP = {
  VehicleMakerSelector: 'Tire Brands',
  VehicleModelSelector: 'MAKE Tire Brands',
  VehicleYearSelector: 'MAKE MODEL Tire Brands',
  VehicleSubmodelSelector: 'MAKE MODEL Tire Brands'
}

export const SELECTOR_SUBHEADING_MAP = {
  VehicleMakerSelector: 'Select Your Make',
  VehicleModelSelector: 'Select Your Model',
  VehicleYearSelector: 'Select Your Year',
  VehicleSubmodelSelector: 'Select Your Trim'
}

export const META_DESCRIPTION_MAP = {
  VehicleMakerSelector: '',
  VehicleModelSelector: 'Compare tire prices for tires that fit your MAKE at Big O Tires today. Shop all the best tire brands and the largest selection of tires for your MAKE.',
  VehicleYearSelector: 'Compare tire prices for tires that fit your MAKE MODEL at Big O Tires today. Shop all the best tire brands and the largest selection of tires for your MAKE MODEL.',
  VehicleSubmodelSelector: 'Compare tire prices for tires that fit your YEAR MAKE MODEL at Big O Tires today. Shop all the best tire brands and the largest selection of tires for your YEAR MAKE MODEL.',
  BOTSizeSelector: 'Compare tire prices for tires that fit your YEAR MAKE MODEL TRIM at Big O Tires today. Shop all the best tire brands and the largest selection of tires for your YEAR MAKE MODEL TRIM.'
}

export const META_TITLE_MAP = {
  VehicleMakerSelector: '',
  VehicleModelSelector: 'Shop Tires for MAKE | Big O Tires',
  VehicleYearSelector: 'Shop Tires for MAKE MODEL | Big O Tires',
  VehicleSubmodelSelector: 'Shop Tires for YEAR MAKE MODEL | Big O Tires',
  BOTSizeSelector: 'Shop Tires for YEAR MAKE MODEL TRIM | Big O Tires'
}

export const SCHEMAS_NAME_MAP = {
  VehicleMakerSelector: '',
  VehicleModelSelector: 'MAKE Tires',
  VehicleYearSelector: 'MAKE MODEL Tires',
  VehicleSubmodelSelector: 'YEAR MAKE MODEL Tires',
  BOTSizeSelector: 'YEAR MAKE MODEL TRIM Tires'
}

export const TYPE_MAP = [
  'VehicleMakerSelector',
  'VehicleModelSelector',
  'VehicleYearSelector',
  'VehicleSubmodelSelector',
  'VehicleTireBrandSelector',
  'BOTSizeSelector'
]

export const YMME_PAGE_IMAGE_WIDTH = 500
export const YMME_PAGE_IMAGE_HEIGHT = 350
export const YMME_PAGE_IMAGE_WIDTH_MOBILE = 300
export const YMME_PAGE_IMAGE_HEIGHT_MOBILE = 210

export const YMME_HEADER_ID = 'ymme_header'
export const YMME_DESCRIPTION_ID = 'ymme_about'
