import { withProps } from 'recompose'
import StepperHeader from './StepperHeader'

export default withProps(({ steps, currentStepIndex }) => {
  const numActualSteps = steps.length - 1
  const numStepsDone = steps.reduce((acc, step) => acc + step.isDone, 0)
  const progress = (Math.max(currentStepIndex, numStepsDone - 1))
  const progressBarWidth = (100 / numActualSteps) * (progress)
  return { progressBarWidth }
})(StepperHeader)
