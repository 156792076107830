import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'
import Modal from 'components/common/Modal'
import Theme from 'components/common/Theme/Theme'
import FindServiceHistoryForm from './components/FindServiceHistoryForm'

const FindServiceHistoryModal = ({
  handleHide,
  handleSubmit,
  submitFindServiceHistoryForm,
  hideFindServiceHistoryModal,
  isSubmitBtnDisabled,
  data: {
    state: {
      states
    } = {},
    description,
    title
  }
}) =>
  <ModalBox data-at-findservicehistorymodal closeHandler={ handleHide }>
    <ModalTitle>{ title }</ModalTitle>
    <FindServiceHistoryForm
      description={ description }
      states={ states }
      hideFindServiceHistoryModal={ hideFindServiceHistoryModal }
      onSubmit={ handleSubmit } />
    <ModalButtonsWrapper>
      <ActionButton
        disabled={ isSubmitBtnDisabled }
        data-at-findservicehistorymodal-continue
        onClick={ submitFindServiceHistoryForm } >
        { title }
      </ActionButton>
      <CancelButton data-at-findservicehistorymodal-cancel onClick={ handleHide }>
        Cancel
      </CancelButton>
    </ModalButtonsWrapper>
  </ModalBox>

FindServiceHistoryModal.propTypes = {
  data: PropTypes.shape({
    state: PropTypes.shape({
      states: PropTypes.arrayOf
    }),
    description: PropTypes.string,
    title: PropTypes.string
  }),
  handleHide: PropTypes.func,
  handleSubmit: PropTypes.func,
  hideFindServiceHistoryModal: PropTypes.func,
  isSubmitBtnDisabled: PropTypes.bool,
  submitFindServiceHistoryForm: PropTypes.func
}

export default FindServiceHistoryModal

const ModalBox = styled(Modal)`
  ${Mixin.responsive('padding', '25px 24px 42px', '67px 16px 50px')}
  overflow: visible;

  & .modal-body {
    padding: 20px 50px;
  }
`

const ModalTitle = styled.div`
  margin: 0 0 24px;
  color: ${Theme.colors.black};
  text-align: center;
  text-transform: uppercase;
  ${Mixin.font.GothamBold(20, 600, 24)};
  transform: scale(1, 1.3);
`

const ModalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0 0 0;
`

const ActionButton = styled(Button)`
  cursor: pointer;
`

const CancelButton = styled(ActionButton)`
  margin: 30px 0 0 !important;
  color: ${Theme.colors.red};
  background: ${Theme.colors.transparent};
  border: none;

  &:hover {
    background: ${Theme.colors.transparent};
    color: ${Theme.colors.red};
  }
`
