import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { MOBILE } from '../constants'

const Mobile = ({ children, screenFits }) =>
  (screenFits === MOBILE) &&
    <Fragment>{ children }</Fragment>

Mobile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  screenFits: PropTypes.string
}

export default Mobile
