import { connect } from 'react-redux'
import { compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
// TODO: default export in index file causes cyclic dependency. Analyze differences between BOT and TA
import VehiclePersonalizationModal from './VehiclePersonalizationModal'
import { closeModal } from './actions'
import { PERSONALIZE_VEHICLE_MODAL } from 'constant'

export default compose(
  registerReduxModal({ name: PERSONALIZE_VEHICLE_MODAL, destroyOnHide: true }),
  connect(null, { closeModal })
)(VehiclePersonalizationModal)

