import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Modal from 'components/common/Modal'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import { PROMOTION_EXPIRATION_MODAL_TITLE } from '../../constants'
import { convDate } from 'components/QuickAppointmentPage/ThreeStepper/helpers'

const PromotionExpirationModal = ({
  appliedPromotions = [],
  keepPromoHandler,
  removePromoHandler,
  appointmentDatesNotInRangeOfPromoDates,
  storePhoneNumber,
  formattedStorePhoneNumber,
  handleHide,
  isQuickAppointmentPage
}) =>
  <Container data-at-service-record-modal closeHandler={ () => {} }>
    <AlertIcon asset="alert-triangle-yellow" alt="Yellow alert triangle" />
    <Title>{PROMOTION_EXPIRATION_MODAL_TITLE}</Title>

    {isQuickAppointmentPage
      ? (
        <PromoDetailsWrapper>
          {Boolean(appliedPromotions && appliedPromotions.length)
            && appliedPromotions.map(({ promotionExpiryDate, promotionName, promotionId }) => (
              <div key={ promotionId }>
                <PromoName>
                  {promotionName}
                </PromoName>
                <PromoDates>
                  {`Valid until ${dayjs(convDate(promotionExpiryDate)).format('MM/DD/YYYY')}`}
                </PromoDates>
              </div>
            ))}
        </PromoDetailsWrapper>
      ) : (
        <PromoDetailsWrapper>
          {Boolean(appliedPromotions && appliedPromotions.length)
            && appliedPromotions.map(({ promotionEndDate, promotionStartDate, promotionName, promotionId }) => (
              <div key={ promotionId }>
                <PromoName>
                  {promotionName}
                </PromoName>
                <PromoDates>
                  {`Valid ${promotionStartDate} - ${promotionEndDate}`}
                </PromoDates>
              </div>
            ))}
        </PromoDetailsWrapper>
      )}


    {appointmentDatesNotInRangeOfPromoDates
      ? (
        <>
          <SubTitle>
            Please call your store to see if they can find a way to fit you in before the expiration date
          </SubTitle>

          <ButtonsWrapper>
            <CallStoreLink href={ `tel:${storePhoneNumber.length === 10 ? '+1' : ''}${storePhoneNumber}` }>
              <PhoneIcon asset="phone-white" alt="Phone icon" />
              {'Call the store at '}
              <PhoneNumber>{formattedStorePhoneNumber}</PhoneNumber>
            </CallStoreLink>

            <CloseButton onClick={ handleHide }>
              Close
            </CloseButton>
          </ButtonsWrapper>
        </>
      )
      : (
        <>
          <SubTitle>
            I want to
          </SubTitle>

          <KeepPromoButton onClick={ keepPromoHandler }>
            Keep Promotion
          </KeepPromoButton>

          <WithoutPromoButton onClick={ removePromoHandler }>
            Select this date and continue without promotion
          </WithoutPromoButton>
        </>
      )
    }
  </Container>

PromotionExpirationModal.propTypes = {
  appliedPromotions: PropTypes.arrayOf(PropTypes.shape()),
  appointmentDatesNotInRangeOfPromoDates: PropTypes.bool,
  formattedStorePhoneNumber: PropTypes.string,
  handleHide: PropTypes.func,
  isQuickAppointmentPage: PropTypes.bool,
  keepPromoHandler: PropTypes.func,
  removePromoHandler: PropTypes.func,
  storePhoneNumber: PropTypes.string
}

export default PromotionExpirationModal

const Container = styled(Modal)`
  max-width: 530px;
  width: 100%;
  height: auto;
  ${Media.mobile`
    max-width: 335px;
  `}

  & .modal-header {
    display: none;
  }

  & .modal-body {
    padding: 36px 58px 22px 90px;
    ${Media.mobile`
      padding: 24px 15px;
    `}
  }
`

const AlertIcon = styled(Icon)`
  position: absolute;
  left: 25px;
  width: 49px;
  height: 47px;
  margin-bottom: 17px;
  ${Media.mobile`
    position: static;
  `}
`

const Title = styled.h1`
  margin: 0 0 19px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  ${Media.mobile`
    font-size: 18px;
  `}
`

const PromoDetailsWrapper = styled.div`
  margin: 0 0 18px 0;
  padding: 17px 0;
  border-top: 1px solid ${Theme.colors.grey};
  border-bottom: 1px solid ${Theme.colors.grey};
`

const PromoName = styled.p`
  margin: 0 0 17px 0;
  font-size: 20px;
  font-weight: 700;
`

const PromoDates = styled.p`
  margin: 0;
  font-size: 18px;
`

const SubTitle = styled.p`
  margin: 0 0 10px 0;
  font-size: 14px;
`

const KeepPromoButton = styled.button`
  width: 100%;
  padding: 10px 0;
  margin: 0 0 22px 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  color: ${Theme.colors.white};
  background: ${Theme.colors.light_green};
  border: 1px solid ${Theme.colors.light_green};
  border-radius: 4px;
  ${Media.desktop`
    &:hover {
      color: ${Theme.colors.light_green};
      background: ${Theme.colors.white};
    }
  `}
`

const WithoutPromoButton = styled.button`
  width: 100%;
  border: none;
  font-size: 18px;
  color: ${Theme.colors.red};
  background: ${Theme.colors.white};
  cursor: pointer;
  text-decoration: underline;
  ${Media.desktop`
    &:hover {
      color: ${Theme.colors.light_green};
    }
  `}
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CallStoreLink = styled.a`
  display: flex;
  width: 100%;
  padding: 10px 30px;
  margin: 20px 0 20px 0;
  font-size: 18px;
  color: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.light_green};
  border-radius: 4px;
  background-color: ${Theme.colors.light_green};
  text-decoration: none;
  ${Media.mobile`
    font-size: 13px;
  `}
`

const PhoneIcon = styled(Icon)`
  margin: 0 16px 0 0;
  ${Media.mobile`
    margin: 0 12px 0 0;
  `}
`

const PhoneNumber = styled.p`
  margin: 0 0 0 5px;
  font-size: 18px;
  font-weight: 700;
  ${Media.mobile`
    font-size: 15px;
  `}
`

const CloseButton = styled.button`
  width: fit-content;
  margin: 0 0 10px 0;
  border: none;
  font-size: 18px;
  color: ${Theme.colors.blue};
  background: ${Theme.colors.white};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  ${Media.desktop`
    &:hover {
      color: ${Theme.colors.light_green};
    }
  `}
`
