import { compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import registerModalPage from 'components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { TPP_MODAL } from 'constant'
import TPPModal from './TPPModal'

export default compose(
  registerReduxModal({ name: TPP_MODAL }),
  registerModalPage({
    pageSrc: '/modals/tire-protection-plan',
    rootType: MODAL_BOX_TYPE
  })
)(TPPModal)
