import isEqual from 'lodash/isEqual'
import {
  EDIT_TYPE_SERVICE_RECORD_MODAL,
  WAS_VEHICLE_DETAILS_QUERY_PARAMS
} from './constants'

const getMonthName = (monthNumber) => {
  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  }

  return months[monthNumber]
}

export const formatDateToRecordDate = (date) => {
  const splittedDate = date.split('/')
  const month = getMonthName(splittedDate[0]).toUpperCase()
  const day = splittedDate[1]
  const year = splittedDate[2]

  return `${month} ${day}, ${year}`
}

export const formatDateToPayloadFormat = (date, type) => {
  if (date) {
    const month = date.getUTCMonth() + 1
    const day = type === EDIT_TYPE_SERVICE_RECORD_MODAL ? (date.getUTCDate() + 1) : date.getUTCDate()
    const year = date.getUTCFullYear()

    return `${month}/${day}/${year}`
  }
}

export const generateServicesPayloadString = ({ isTireRotationChecked, isOilChangeChecked }) => {
  const services = {}

  if (isTireRotationChecked) {
    services.tireRotation = 'Tire Rotation'
  }

  if (isOilChangeChecked) {
    services.oilChange = 'Oil Change'
  }

  if (!isTireRotationChecked && !isOilChangeChecked) {
    return null
  }

  const servicesValues = Object.values(services)

  return servicesValues.length > 1 ? servicesValues.join(',') : servicesValues[0]
}

export const sortRecordsInChronologicalOrder = records => {
  const recordsWithMilliseconds = records.map(item => {
    if (item.serviceDate) {
      return {
        ...item,
        dateInMilliseconds: Date.parse(item.serviceDate)
      }
    }

    return {
      ...item,
      dateInMilliseconds: Date.parse(item.displayDate)
    }
  })

  return recordsWithMilliseconds.sort((a, b) => b.dateInMilliseconds - a.dateInMilliseconds)
}

export const hasTireRotationService = (services) => services.includes('Tire Rotation')

export const hasOilChangeService = (services) => services.includes('Oil Change')

export const parseStringDateToDateObject = (date) => {
  const milliseconds = Date.parse(date)

  return new Date(milliseconds)
}

export const prepareMileage = mileage => {
  const deletedCommas = mileage.replaceAll(',', '')
  if (deletedCommas.length === 8) {
    const deletedExtraLetters = Number(deletedCommas.substring(0, deletedCommas.length - 1))
    return deletedExtraLetters
  }
  return Number(deletedCommas)
}

export const isPageWasVehicleDetails = () => {
  if (window && window.location && window.location.search) {
    const { search } = window.location

    return search.includes(WAS_VEHICLE_DETAILS_QUERY_PARAMS)
  }
}

export const serviceRecordApiMismatchErrorHandler = ({ setServiceRecordApiMismatchError }) => {
  setServiceRecordApiMismatchError(true)

  setTimeout(() => {
    setServiceRecordApiMismatchError(false)
  }, 5000)
}

export const serviceRecordApiFutureErrorHandler = ({ setServiceRecordApiFutureErrorHandler }) => {
  setServiceRecordApiFutureErrorHandler(true)

  setTimeout(() => {
    setServiceRecordApiFutureErrorHandler(false)
  }, 5000)
}

export const prepareForDuplicateCheckRecord = ({ mileage, servicesString, formattedServiceDate }) => ({
  mileage: String(mileage),
  serviceNames: servicesString.split(','),
  serviceDate: new Date(formattedServiceDate)
})

export const isServiceRecordDuplicate = (recordData, prepareForDuplicateCheckRecord) => {
  const transformedData = recordData.map(({ serviceId, serviceDate, ...keepAttrs }) =>
    ({ ...keepAttrs, serviceDate: new Date(serviceDate) }))
  const isDuplicate = (record) => isEqual(record, prepareForDuplicateCheckRecord)
  return transformedData.some(isDuplicate)
}
