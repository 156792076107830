import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import CarouselGlobalStyles from './Carousel.styles'
import styled, { css } from 'styled-components'

const Carousel = ({
  defaultSettings,
  className,
  children,
  config = {},
  handleSlideMouseDown,
  handleSlideMouseUp,
  isDragging,
  isChanging,
  activeIndex,
  setSliderMethods,
  setFocusHoverStateBlockRef,
  focusBlurHoverHandlers,
  heroBanner,
  carouselWrapperProps,
  ...props
}) =>
  <Fragment>
    <CarouselGlobalStyles />
    <CarouselWrapper
      className={ className }
      ref={ setFocusHoverStateBlockRef }
      { ...focusBlurHoverHandlers }
      { ...carouselWrapperProps }>
      <CarouselSlider
        arrows={ !heroBanner }
        { ...defaultSettings }
        { ...config }
        isDragging={ isDragging }
        handleSlideMouseDown={ handleSlideMouseDown }
        ref={ setSliderMethods }
        heroBanner={ heroBanner }
        { ...props }>
        {
          React.Children.map(children, (item, slideIndex) =>
            <Slide
              isActive={ activeIndex === slideIndex }
              slideIndex={ slideIndex }
              isDragging={ isDragging }
              isChanging={ isChanging }
              heroBanner={ heroBanner }
              onMouseDown={ () => { setTimeout(() => { handleSlideMouseDown() }, 150) } }
              onMouseUp={ () => { setTimeout(() => { handleSlideMouseUp() }, 150) } }>{ item }</Slide>
          )
        }
      </CarouselSlider>
    </CarouselWrapper>
  </Fragment>

Carousel.propTypes = {
  activeIndex: PropTypes.number,
  autoplay: PropTypes.bool,
  carouselWrapperProps: PropTypes.shape(),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  config: PropTypes.shape(),
  defaultSettings: PropTypes.shape(),
  focusBlurHoverHandlers: PropTypes.shape({
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  }),
  handleSlideMouseDown: PropTypes.func,
  handleSlideMouseUp: PropTypes.func,
  heroBanner: PropTypes.bool,
  isChanging: PropTypes.bool,
  isDragging: PropTypes.bool,
  setFocusHoverStateBlockRef: PropTypes.func,
  setSliderMethods: PropTypes.func
}

const CarouselWrapper = styled.div`
  .slick-slider {
    /* stylelint-disable */
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
    /* stylelint-enable */
  }
`

const CarouselSlider = styled(Slider)`
  &.slick-initialized .slick-cloned > div {
    display: ${props => props.isDragging ? 'block' : 'none'};
  }
`

const Slide = styled.div`
  ${props => props.heroBanner && css`
    display: none !important;

    ${props => (props.isActive || props.isDragging || props.isChanging) && css`
      display: block !important;
    `}
  `}
`

export default Carousel
