import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Modal from 'components/common/Modal'
import ConfirmYourVehicleForm from '../ConfirmYourVehicleForm'

const ConfirmYourVehicleModal = ({
  model,
  initialValues,
  handleHide,
  onSubmit
}) =>
  <ModalBox title="Confirm Your Vehicle(s)" closeHandler={ handleHide }>
    <Description>
      The following vehicles associated with the plate number and state you provided.
      Please select which vehicle you would like to add to your Big O Tires account.
    </Description>
    <ConfirmYourVehicleForm
      initialValues={ initialValues }
      model={ model }
      handleHide={ handleHide }
      onSubmit={ onSubmit } />
  </ModalBox>

ConfirmYourVehicleModal.propTypes = {
  handleHide: PropTypes.func,
  initialValues: PropTypes.shape(),
  model: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func
}

export default ConfirmYourVehicleModal

const Description = styled.div`
  line-height: 22px;
`

const ModalBox = styled(Modal)`
  ${Media.desktop`
    padding: 25px 24px 67px;
  `}
`
