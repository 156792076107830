export const POPOVER_TOP_OFFSET = 10
export const POPOVER_BOTTOM_OFFSET = 16
export const POPOVER_WIDTH = 250
export const TICK_WIDTH = 19
export const POPOVER_PADDING_ADJUSTMENT = 20

// TODO: TPP comes through but need to confirm TPMS when data available
export const TOOLTIP_TPMS = 'TPMS'
export const TOOLTIP_TPP = 'TPP'

// TODO: Potentially need to make popover content accept raw html
export const TOOLTIPS = {
  [TOOLTIP_TPP]: `
    <strong>Tire Protection On Us!</strong><br />
    Your Big O brand tire purchase includes our Tire Protection Package, free of charge.
    Protection includes complimentary rotations and rebalances for the life of the tire,
    as well as 24-hour Roadside Assistance, available nationwide. Plus, we'll repair,
    replace or refund your tire in the event that it is damaged, free of charge for 3 years
    (pro-rated after). Drive away with confidence and peace of mind. We've got you covered.
    See <a href="/warranty-info" data-action="goToInternalHref">Tire Warranty</a> terms for details.
  `,
  [TOOLTIP_TPMS]: `
    <strong>What is Tire Pressure Monitoring System (TPMS)?</strong>
    Vehicles 2008 or newer have TPMS, an electronic system that monitors the air 
    pressure in your tires and alerts you when they are under or over-inflated. 
    Proper tire inflation not only boosts your safety, but also the life of the tire
     and its fuel economy. Some components of the TPMS sensor may need to be replaced 
     due to wear and corrosion over time. Our TPMS rebuild kit includes all the necessary 
     parts to service your TPMS sensor and keep this important safety feature functioning properly.`
}
