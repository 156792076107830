import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'redux-form'
import { InputField, Input } from 'components/common/FormElements/Input'
import Banner from 'components/common/Banner/Banner'
import { FindButton, FieldWrapper } from '../SharedStyles'

// TODO use Dropdown with search field
const ShopByLicencePlateForm = ({
  error,
  model,
  submitFailed,
  handleSubmit,
  onSubmit,
  children,
  submitting
}) =>
  <form noValidate onSubmit={ handleSubmit(onSubmit) }>
    {
      submitFailed && error &&
      <CustomBanner error_alternative>
        {error}
      </CustomBanner>
    }
    <FieldWrapper>
      <InputField full static { ...model.licence } />
    </FieldWrapper>
    <FieldWrapper data-at-ymme-lic-state>
      <Field
        full
        static
        optionsMaxHeight={ 200 }
        parse={ selection => selection ? selection.value : '' }
        { ...model.state }
        component={ Input } />
    </FieldWrapper>
    {
      children ?
        React.Children.map(children, child => React.cloneElement(child, { disabled: submitting })) :
        <FindButton data-at-find-my-tires-button full large important disabled={ submitting }>Find My Tires</FindButton>
    }
  </form>

ShopByLicencePlateForm.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  model: PropTypes.shape({
    licence: PropTypes.shape(),
    state: PropTypes.shape()
  }),
  onSubmit: PropTypes.func,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool
}

export default ShopByLicencePlateForm

const CustomBanner = styled(Banner)`
  padding-top: 10px;
`
