import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Field } from 'redux-form'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Text from 'components/common/Text/Text'
import Button from 'components/common/Button/Button'
import { Input, ErrorMessage } from 'components/common/FormElements/Input'

const renderSignUpInput = props =>
  <SignUpRow full="mobile">
    <SignoutInput { ...props } />
  </SignUpRow>

export const EmailSignUpInitialForm = ({ model, invalid, submitFailed, handleSubmit }) =>
  <Form noValidate onSubmit={ handleSubmit }>
    <LabelText headline_inversed>Sign Up for Our Latest Deals</LabelText>
    <InputWrapper error={ invalid && submitFailed }>
      <Field { ...model.email } static component={ renderSignUpInput } />
      <SignupButton
        transparent
        narrow
        data-at-footer-emailsignupform-signup
        correctAlignment={ invalid && submitFailed }>
        Sign up
      </SignupButton>
    </InputWrapper>
  </Form>

EmailSignUpInitialForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  model: PropTypes.shape({
    email: PropTypes.shape()
  }),
  showEmailSignUpModal: PropTypes.func,
  submitFailed: PropTypes.bool
}

const SignUpRow = styled.div`
  display: inline-block;
  ${Mixin.responsive('margin-right', '10px', '0')}

  & ${ErrorMessage} {
    color: ${Theme.colors.white};
    ${Media.mobile`
      position: absolute;
      margin-top: 0;
    `}
  }

  ${Mixin.property('full', `
    width: 70%;
  `)}
`

const SignoutInput = styled(Input)`
  vertical-align: middle;
  ${Media.mobile`
    position: relative;
    width: 100%;
    padding-right: 10px;
  `}
`

const Form = styled.form`
  ${Media.desktop`
    position: relative;
    float: left;
    padding: 10px 0;
  `}
  ${Mixin.clearFix()}
`

const InputWrapper = styled.div`
  display: inline-block;
  ${Media.mobile`
    position: relative;
    width: 100%;
    ${props => props.error && css`
      margin-bottom: 15px;
    `};
  `}
`

const LabelText = styled(Text)`
  vertical-align: top;
  ${Mixin.responsive('display', 'inline-block', 'block')}
  ${Mixin.responsive('margin', '12px 20px 0 0', '0 auto')}
  ${Media.mobile`
    padding: 20px 0;
    text-align: center;
  `}
  ${Mixin.font.GothamMedium()}
  transform: scale(1, 1.3);
`

const SignupButton = styled(Button)`
  vertical-align: middle;
  background: ${Theme.colors.bright_red_new};
  padding: 7px 30px 6px;
  ${Mixin.font.GothamBook(16)}

  &:hover {
    background: ${Theme.colors.crimson};
    border: 1px solid ${Theme.colors.white};
  }

  ${props => props.correctAlignment && css`
    ${Media.desktop`
      position: relative;
      bottom: 10.5px;
    `}
  `}
  ${Media.mobile`
    position: absolute;
    right: 0;
    width: 30%;
    padding: 7px 15px 6px;
    margin-top: 4px;
  `}

  @media (max-width: 380px) {
    width: auto;
  }

  ${Media.mobileNarrow`
    width: auto;
  `}
`
