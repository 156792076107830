import { compose } from 'redux'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import withType from 'components/common/hoc/withType'
import registerModalPage from 'src/components/common/hoc/registerModalPage'
import { CONFIRM_TIRE_SIZE_MODAL, TAB_YMME_NAME, TAB_LICENSE_PLATE_NAME, YMME_NAME } from 'constant'
import withTireSizeConfirmation from '../withTireSizeConfirmation'
import TireConfirmationModal from './TireConfirmationModal'
import { showNewShopTiresModal } from '../../../../../NewShopTiresModal/actions'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { getConfirmTireSizeData } from 'components/VehicleConfigurator/components/ConfirmTireSize/components/withTireSizeConfirmation/selectors'
import { isEmpty } from 'lodash'

const pageSrc = '/modals/confirm-tire-size'
const type = 'TireConfirmationModal'

const mapStateToProps = state => {
  const { extraVehicleDetails } = getConfirmTireSizeData(state) || {}

  return {
    extraVehicleDetails
  }
}

const withHandleClose = withHandlers({
  handleClose: ({ handleHide, onModalClose, setVehicleDetails, extraVehicleDetails }) => () => {
    if (onModalClose) {
      onModalClose()
    }

    !isEmpty(extraVehicleDetails) && setVehicleDetails({ ...extraVehicleDetails })
    handleHide()
  }
})

const withBackToSearchOptionModalHandlers = withHandlers({
  backToNewYmmeModalHandler: ({ vehicle, showNewShopTiresModal, handleHide, source, promo, isOnlyVehicle }) => () => {
    const { yearDesc, makeDesc, makeId, modelDesc, modelId } = vehicle
    const vehiclePreData = {
      year: { value: Number(yearDesc), label: String(yearDesc) },
      make: { value: Number(makeId), label: makeDesc },
      model: { value: Number(modelId), label: modelDesc },
      subModel: null
    }

    handleHide()

    showNewShopTiresModal({
      vehiclePreData,
      ymmeName: YMME_NAME,
      tabName: TAB_YMME_NAME,
      isOnlyVehicle,
      withShowConfirmTireSizeModal: true,
      source,
      promo
    })
  },
  backToNewLicensePlateModalHandler: ({ showNewShopTiresModal, handleHide, source, promo, isOnlyVehicle }) => () => {
    const licensePlateData = {
      plate: null,
      state: null
    }

    handleHide()

    showNewShopTiresModal({
      licensePlateData,
      tabName: TAB_LICENSE_PLATE_NAME,
      ymmeName: YMME_NAME,
      isOnlyVehicle,
      withShowConfirmTireSizeModal: true,
      source,
      promo
    })
  },
  backToNewStateModalHandler: ({ showNewShopTiresModal, vehicle, handleHide, source, promo, isOnlyVehicle }) => () => {
    const { licencePlateNumber } = vehicle
    const licensePlateData = {
      plate: { value: licencePlateNumber, label: licencePlateNumber },
      state: null
    }

    handleHide()

    showNewShopTiresModal({
      licensePlateData,
      tabName: TAB_LICENSE_PLATE_NAME,
      ymmeName: YMME_NAME,
      isOnlyVehicle,
      withShowConfirmTireSizeModal: true,
      source,
      promo
    })
  }
})

export default compose(
  withType({ type }),
  registerReduxModal({ name: CONFIRM_TIRE_SIZE_MODAL }),
  connect(mapStateToProps, { showNewShopTiresModal, setVehicleDetails }),
  registerModalPage({ pageSrc, rootType: type }),
  withHandleClose,
  withTireSizeConfirmation,
  withBackToSearchOptionModalHandlers
)(TireConfirmationModal)
