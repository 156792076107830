import { compose, withStateHandlers, withHandlers, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { OPEN_APPOINTMENT_MENU, SCHEDULE_APPOINTMENT } from 'constant'
import active from 'components/common/hoc/active'
import withEventListener from 'components/common/hoc/withEventListener'
import withType from 'components/common/hoc/withType'
import MakeAnAppointmentWrapper from './MakeAnAppointmentWrapper'
import { getCurrentStoreIsE2EAppointment } from 'selectors/getCurrentStore'

const withIsHoveredState = withState('isHovering', 'setIsHovering', false)

const mapStateToProps = (state) => ({
  isE2EAppointment: getCurrentStoreIsE2EAppointment(state)
})

const withMakeAnAppointmentProps = withProps(({
  isE2EAppointment,
  name
}) => ({
  name: isE2EAppointment ? SCHEDULE_APPOINTMENT : name
}))

const withSetFocusRefHandler = withStateHandlers({
  focusRef: null
}, {
  setFocusRef: () => ref => ({ focusRef: ref })
})

const withCustomToggleHandler = withHandlers({
  customToggleHandler: ({ focusRef }) => () => {
    focusRef && focusRef.focus && focusRef.focus()
  }
})

const WithActiveMakeAnAppointment = compose(
  withType({ type: 'MakeAnAppointment', placeholders: ['menus']}),
  connect(mapStateToProps),
  withIsHoveredState,
  withMakeAnAppointmentProps,
  withSetFocusRefHandler,
  withCustomToggleHandler,
  active,
  withEventListener({
    eventName: OPEN_APPOINTMENT_MENU,
    getHandler: props => props.toggleHandlers.onClick
  })
)(MakeAnAppointmentWrapper)

export default WithActiveMakeAnAppointment
