export const bazaarVoiceCodeFactory = () => `
(function() {
  var rp = "^(:?(www|preview|beta)\\.)?bigotires.com$";
  var s = '//display.ugc.bazaarvoice.com/bvstaging/static/bigotires/en_US/bvapi.js';
  var p = '//display.ugc.bazaarvoice.com/static/bigotires/en_US/bvapi.js';
  var h = document.location.host;
  var r = new RegExp(rp);
  var m = document.createElement('script');
  m.type='text/javascript';
  m.async=true;
  m.src=r.test(h) ? p : s;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(m,s);
})();
`
