import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import {
  getCurrentStore,
  getCurrentStorePhoneNumber,
  getCurrentStoreEnableAppointmentFlag
} from 'selectors/getCurrentStore'
import { get3StepperEnabled } from 'selectors/thirdPartyData'
import { showAppointmentUnsupportedModal } from 'components/Header/components/MakeAnAppointment/components/AppointmentUnsupportedModal/actions'
import { showStoreLocatorModal3StepperEnabled } from 'actions/storeLocator'
import { QUICK_APPOINTMENT_PAGE } from 'constant'
import { generateDynamicQuickApptQuery } from 'helpers/generateDynamicQuickApptQuery'

const withHandleRequestAppointmentClick = withHandlers({
  onClick: ({
    myStoreExists,
    myStorePhoneNumber,
    myStoreAppointmentEnableFlag,
    showAppointmentUnsupportedModal,
    threeStepperEnabled,
    showStoreLocatorModal3StepperEnabled,
    navigateToQuickApptPage,
    push,
    serviceQueryParam,
    categoryQueryParam
  }) => e => {
    const query = generateDynamicQuickApptQuery({ serviceQueryParam, categoryQueryParam })
    const quickApptLinkWithService = query ? `${QUICK_APPOINTMENT_PAGE}?${query}` : ''
    const externalIdLong = serviceQueryParam || categoryQueryParam || ''
    const isPreExpanded = Boolean(categoryQueryParam)

    if (myStoreExists && !myStoreAppointmentEnableFlag) {
      e.preventDefault()
      showAppointmentUnsupportedModal({ phoneNumber: myStorePhoneNumber })
    } else if (!myStoreExists && threeStepperEnabled) {
      e.preventDefault()
      showStoreLocatorModal3StepperEnabled(externalIdLong, isPreExpanded)
    } else if (navigateToQuickApptPage) {
      if (serviceQueryParam || categoryQueryParam) {
        push(quickApptLinkWithService)
      } else {
        push(QUICK_APPOINTMENT_PAGE)
      }
    }
  }
})

const mapStateToProps = state => ({
  myStoreExists: Boolean(getCurrentStore(state)),
  myStorePhoneNumber: getCurrentStorePhoneNumber(state),
  myStoreAppointmentEnableFlag: getCurrentStoreEnableAppointmentFlag(state),
  threeStepperEnabled: get3StepperEnabled(state)
})

export default compose(
  connect(mapStateToProps, { showAppointmentUnsupportedModal, showStoreLocatorModal3StepperEnabled, push }),
  withHandleRequestAppointmentClick
)
