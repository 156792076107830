import React from 'react'
import { Field, propTypes as reduxFormPropTypes } from 'redux-form'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Modal from 'components/common/Modal'
import { Input } from 'components/common/FormElements/Input'
import Banner from 'components/common/Banner/Banner'
import { MODAL_DESCRIPTION } from './constants'
import {
  ButtonsWrapper,
  CustomButton,
  ActionButton,
  CarfaxTitle,
  Logo
} from '../../Common.styles'
import CarfaxLogo from '../../assets/CarfaxLogo.jpg'

const InputText = styled(Input)`
  margin: 0 0 30px;
`

const BOTConnectToCarFax = ({
  viewModel,
  handleSubmit,
  handleHide,
  error
}) =>
  <CustomModal closeHandler={ handleHide }>
    <Form noValidate onSubmit={ handleSubmit }>
      <Title data-at-connect-carfax-header>Access Service History</Title>
      <Description data-at-connect-carfax-description>{ MODAL_DESCRIPTION }</Description>
      <List data-at-connect-carfax-list>
        <ListItem>Be the first to know about open recalls that may affect the safety of your vehicle.</ListItem>
        <ListItem>Get timely alerts when your car is due for service.</ListItem>
        <ListItem>Track your vehicle;s service history.</ListItem>
        <ListItem>Receive special offers.</ListItem>
      </List>
      { error && <Banner error>{error}</Banner> }
      <EmailLabel>Enter email associated with Big O Tires account</EmailLabel>
      <Field { ...viewModel.email } full component={ InputText } />
      <Field { ...viewModel.confirmation } size="12" margin="0 0 20px" component={ Input } />
      <ButtonsWrapper>
        <ActionButton data-at-connect-carfax-continue>
          CONTINUE
        </ActionButton>
        <CustomButton onClick={ handleHide }>
          Cancel
        </CustomButton>
      </ButtonsWrapper>
      <Wrapper>
        <CarfaxTitle data-at-connect-carfax-poweredby>Powered by</CarfaxTitle>
        <Logo src={ CarfaxLogo } alt="Carfax" />
      </Wrapper>
    </Form>
  </CustomModal>

BOTConnectToCarFax.propTypes = {
  ...reduxFormPropTypes,
  email: PropTypes.string,
  handleSubmit: PropTypes.func,
  name: PropTypes.string,
  viewModel: PropTypes.shape()
}

export default BOTConnectToCarFax

const CustomModal = styled(Modal)`
  & .modal-body {
    padding: 4px 46px 21px;
  }
`

const Form = styled.form`
  max-width: 480px;
  margin: 0 auto;
`

const Title = styled.div`
  margin: 0 0 24px;
  color: ${Theme.colors.black};
  text-align: center;
  text-transform: uppercase;
  ${Mixin.font.GothamBold(20, 600, 24)};
  transform: scale(1, 1.3);
`

const Description = styled.p`
  ${Mixin.font.GothamBook(14, 400, 16)};
  color: ${Theme.colors.black};
  margin: 0 0 38px 0;
`

const List = styled.ul`
  list-style: none;
  padding-left: 15px;
  margin: 0 0 30px 0;
`

const ListItem = styled.li`
  margin: 8px 0;

  &::before {
    content: "\\2022";
    color: ${Theme.colors.red};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`

const EmailLabel = styled.p`
  ${Mixin.font.GothamBold(14, 600, 17)};
  color: ${Theme.colors.black};
  margin: 0 0 15px 0;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
