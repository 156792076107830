import { compose, withProps, branch } from 'recompose'
import withForwardingRef from 'components/common/hoc/withForwardRef'
import ReactSelectValueContainer from './ReactSelectValueContainer'

const withIsSearchable = withProps(({ selectProps: { isSearchable }}) => ({ isSearchable }))

const withDummyInputProps = withProps(({ children }) => {
  const childrenWithoutInput = children.filter((ch) => ch && !ch.props.readOnly)
  const dummyInput = children.find((ch) => ch && ch.props.readOnly) || {}
  const { innerRef, onFocus, onBlur, onChange } = dummyInput.props
  return {
    children: childrenWithoutInput.length ? childrenWithoutInput : children,
    innerRef,
    onFocus,
    onBlur,
    onChange
  }
})

export default compose(
  withIsSearchable,
  branch(
    ({ isSearchable }) => !isSearchable,
    withDummyInputProps
  ),
  withForwardingRef
)(ReactSelectValueContainer)
