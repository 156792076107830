export const conformSteps = steps => steps.map(({ stepName, selectedValue }, idx) => {
  const isFirstStep = idx === 0
  const prevStepSelectedValue = !isFirstStep ? steps[idx - 1].selectedValue : undefined
  const hasPrevStepSelectedValue = isFirstStep || Boolean(prevStepSelectedValue && prevStepSelectedValue !== '')
  const hasSelectedValue = Boolean(selectedValue && selectedValue !== '')
  return {
    title: stepName,
    isActive: hasPrevStepSelectedValue,
    isDone: hasSelectedValue
  }
})
