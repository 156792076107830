import { DESKTOP } from './constants'
import { handleActions, createAction } from 'redux-actions'
import { screenFitsHelper, getClientSize } from './helpers'

export const deviceChange = createAction('GENERAL/DEVICE_CHANGE')
export const screenChange = createAction('GENERAL/SCREEN_CHANGE')

const initialState = {
  device: DESKTOP, // device based on user-agent
  screenFits: screenFitsHelper(), // device based on screen-width
  screenSize: {
    width: 0,
    height: 0
  }
}

// reducers
const activeDevice = handleActions({
  [deviceChange]: (state, { payload }) => ({ ...state, device: payload }),
  [screenChange]: (state, { payload }) => ({ ...state, screenSize: getClientSize(), screenFits: screenFitsHelper() })
}, initialState)

export default activeDevice
