export const getSizeStructure = data => data.map(item => ({ value: item, label: item }))

export const setDefaultSizeName = ({ isEditTireSize, isRemoveRearSize, isEditRearSize }) => {
  switch (true) {
    case isEditTireSize && (isEditRearSize || isRemoveRearSize):
      return 'ratio'
    default:
      return ''
  }
}

export const handleSelectAllValues = ({ handleSelectValue, tireSizePreData }) => {
  const preDataArray = Object.keys({ ...tireSizePreData })

  preDataArray.forEach(position => {
    const typeObj = tireSizePreData[position]
    const positionValues = Object.values(typeObj)
    const positionNames = Object.keys(typeObj)

    positionValues.forEach((name, index) => {
      const isNotEmpty = name && Boolean(Object.keys(name).length)

      if (isNotEmpty) {
        handleSelectValue({
          position,
          name: positionNames[index],
          value: name.value
        })
      }
    })
  })
}
