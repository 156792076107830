import { createSelector } from 'helpers/reselect'

const getHasActiveVehicleStatus = ({
  app: {
    data: {
      userdata: {
        mygarage: {
          vehicleCount
        } = {}
      } = {}
    } = {}
  } = {}
} = {}) => vehicleCount

export default createSelector(
  [getHasActiveVehicleStatus],
  vehicleCount => !!vehicleCount
)
