import { lifecycle, withHandlers, compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { getPageLocation } from 'selectors'
import { isTireComparisonUrl } from 'helpers/location'
import withType from 'components/common/hoc/withType'
import debounce from 'lodash/debounce'
import { BOT_COMPARE_PAGE_HEADER_CONTAINER_ID } from 'constant'
import HeaderDesktopMainContent from './HeaderDesktopMainContent'
import Theme from 'components/common/Theme/Theme'
import { isQuoteSummaryPage, isQuickAppointmentPage } from 'helpers/quoteRelatedPages'

const mapStateToProps = state => {
  const pageLocation = getPageLocation(state)

  return {
    isTireComparisonPage: isTireComparisonUrl(pageLocation),
    isQuotePage: isQuoteSummaryPage(pageLocation && pageLocation.pathname),
    isQuickAppointmentPage: isQuickAppointmentPage(pageLocation && pageLocation.pathname),
    pageLocation
  }
}

const withIsTireComparisonHeaderShowed = withState('isTireComparisonHeaderDisplayed', 'setTireComparisonHeader', false)

const withHandleChange = withHandlers({
  handleHeaderChange: ({ setTireComparisonHeader }) =>
    debounce(() => {
      const header = document.getElementById(BOT_COMPARE_PAGE_HEADER_CONTAINER_ID)
      const isDisplayed = header && !header.hasAttribute('hidden')
      setTireComparisonHeader(isDisplayed)
    }, Theme.scrollDebounceTimeout)
})

const withSystemAlertListeners = lifecycle({
  componentDidMount() {
    const { handleHeaderChange, isTireComparisonPage } = this.props

    isTireComparisonPage && window.addEventListener('scroll', handleHeaderChange)
  },
  componentWillUnmount() {
    const { handleHeaderChange, isTireComparisonPage } = this.props
    isTireComparisonPage && window.removeEventListener('scroll', handleHeaderChange)
  }
})

export default compose(
  withType({
    type: 'HeaderDesktopMainContent',
    placeholders: ['headerDesktopMainContentBottom', 'headerDesktopMainContentTop']
  }),
  connect(mapStateToProps),
  withIsTireComparisonHeaderShowed,
  withHandleChange,
  withSystemAlertListeners
)(HeaderDesktopMainContent)
