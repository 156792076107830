import { createSelector } from 'helpers/reselect'
import { mapFavoriteItemToProduct, mapFavoriteItemToPriceChangedItems } from './helpers'

const getFavoritesSelector = state => state.favorites

const getApp = state => state.app

export const getFavoriteSkus = createSelector(
  [getFavoritesSelector],
  ({
    favoriteSkus = []
  } = {}) => favoriteSkus
)

export const getFavoriteTotalCount = createSelector(
  [getFavoritesSelector],
  ({
    favoriteSkus = []
  } = {}) => favoriteSkus.length
)

export const getCompatibleFavoriteTotalCount = createSelector(
  [getFavoritesSelector],
  ({
    favoriteItems = []
  } = {}) => favoriteItems.length
)

export const getFavoriteItems = createSelector(
  [getFavoritesSelector],
  ({
    favoriteItems = []
  } = {}) => favoriteItems.map(item => mapFavoriteItemToProduct(item))
)

export const getNonCompatibleFavoriteItems = createSelector(
  [getFavoritesSelector],
  ({
    nonCompatibleFavoriteItems = []
  } = {}) => nonCompatibleFavoriteItems.map(item => mapFavoriteItemToProduct(item))
)

export const getProductWithChangedPrice = createSelector(
  [getFavoritesSelector],
  ({
    favoriteItems = []
  } = {}) => favoriteItems.filter(x => x.priceChanged).flatMap(item => mapFavoriteItemToPriceChangedItems(item))
)

export const getIsUserAuthorized = createSelector(
  [getApp],
  ({
    data: {
      userdata: {
        isAuthorized = false
      } = {}
    } = {}
  } = {}) => isAuthorized
)

export const getFavoritesGiftListId = createSelector(
  [getFavoritesSelector],
  ({
    giftListId = ''
  } = {}) => giftListId
)

export const getIsEmailMe = createSelector(
  [getApp],
  ({
    data: {
      userdata: {
        isEmailme = false
      } = {}
    } = {}
  } = {}) => isEmailme
)
