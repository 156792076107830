import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Button from 'components/common/Button/Button'

const Hint = ({
  expanded,
  toggleHandlers,
  numberOfVehicles = 0,
  addVehicle,
  hasActiveVehicle
}) =>
  <HintWrapper>
    { hasActiveVehicle && (
      <Button data-at-carbar-addvehicle link onClick={ addVehicle }>
        <HintIcon
          alt="plus"
          asset="plus-bright-red-secondary"
          width={ 10 }
          data-at-hint-icon />
        <HintLink>
          ADD VEHICLE
        </HintLink>
      </Button>
    )}
    <CustomButton data-at-carbar-toggle-button link aria-expanded={ expanded } { ...toggleHandlers }>
      <HintLink data-at-carbar-hint>
        { numberOfVehicles ? `Switch Vehicle (${numberOfVehicles})` : 'Add vehicle' }
      </HintLink>
      <ArrowHintIcon
        alt="arrow"
        asset="arrow-icon-full-bright-red-down"
        alternative="arrow-icon-full-bright-red-up"
        condition={ expanded }
        width={ 10 }
        height={ 10 }
        data-at-hint-icon />
    </CustomButton>
  </HintWrapper>

Hint.propTypes = {
  addVehicle: PropTypes.func,
  expanded: PropTypes.bool,
  hasActiveVehicle: PropTypes.bool,
  numberOfVehicles: PropTypes.number,
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  userVehicle: PropTypes.number
}

export default Hint

const HintWrapper = styled.div`
  display: table-cell;
  text-align: right;
  vertical-align: middle;
  width: 400px;
  ${Media.mobile`
    width: ${props => props.isNewYmmeEnabled ? '100px' : '20px'};
  `}

  & ${Button} {
    cursor: pointer;
  }
`

const HintLink = styled.div`
  display: inline-block;
  color: ${Theme.colors.black};
  text-transform: uppercase;
  ${Mixin.font.MakoBold(13)}
  line-height: 1.05em;
  letter-spacing: 0.05em;
  transform: scale(1, 1.3);
  ${Mixin.desktopOnly()};
`

const HintIcon = styled(Icon)`
  display: inline-block;
  margin-left: 0;
  text-align: right;
  margin-right: 5px;
  margin-bottom: 3px;

  :hover {
    cursor: pointer;
  }

  ${Media.desktop`
    vertical-align: middle;
  `}
  ${Media.mobile`
    width: 10px;
    height: 10px;
  `}
`

const ArrowHintIcon = styled(HintIcon)`
  margin: 0 0 0 5px;
  ${Media.mobile`
    width: 10px;
    height: 10px;
    margin: 0 0 5px 0;
  `}
`

const CustomButton = styled(Button)`
  ${Media.mobile`
    margin-left: 0 !important;
  `}
`
