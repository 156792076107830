import React from 'react'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import styled, { css } from 'styled-components'
import { A } from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'

const BackToTopFooterBlock = ({ ...props }) =>
  <Wrapper data-at-footer-backtotop { ...props }>
    <BackToTopLink data-back-to-top anchor href="#root">
      <Icon asset="arrow-up" width={ 12 } alt="" /><br />
      Back to Top
    </BackToTopLink>
  </Wrapper>

BackToTopFooterBlock.propTypes = {
  '@type': PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  name: PropTypes.string
}

export default BackToTopFooterBlock

const Wrapper = styled.div`
  padding: 40px 0;
  text-align: center;
  ${props => !props.showBackToTop && css`
    &[data-at-footer-backtotop] {
      visibility: hidden;
    }
  `}
`

const BackToTopLink = styled(A)`
  ${Mixin.font.GothamBold()}
`
