import React from 'react'
import PropTypes from 'prop-types'
import Popover from 'components/common/Popover'
import TireSizeList from '../TireSizeList'
import {
  Instructions,
  PopoverLink,
  CustomPopover,
  OriginalTireSizeWrapper
} from './OriginalTireSize.styles'

const OriginalTireSize = ({
  tireSizes,
  instructions,
  aboutStaggeredFitmentDescription,
  aboutStaggeredFitment,
  isStaggered,
  ...props
}) =>
  <OriginalTireSizeWrapper>
    {
      instructions &&
      <Instructions data-at-confirm-tire-size-instructions>
        { instructions }
        {
          isStaggered &&
          <Popover
            components={{ PopoverLink }}
            title={ aboutStaggeredFitment }
            info={ aboutStaggeredFitmentDescription }
            { ...props } />
        }
      </Instructions>
    }
    <TireSizeList { ...props } tireSizes={ tireSizes } isStaggered={ isStaggered } />
    <CustomPopover title="Where can I find my tire size?" />
  </OriginalTireSizeWrapper>

OriginalTireSize.propTypes = {
  aboutStaggeredFitment: PropTypes.string,
  aboutStaggeredFitmentDescription: PropTypes.string,
  instructions: PropTypes.string,
  isStaggered: PropTypes.arrayOf(PropTypes.shape()),
  tireSizes: PropTypes.shape()
}

export default OriginalTireSize
