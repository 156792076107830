import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/common/Button/Button'
import Switcher from './Switcher'
import styled from 'styled-components'

const VehicleSwitcher = ({ handleSwitch }) =>
  <Switcher
    dataAttributes={{ 'data-at-vehicle-switcher': true }}
    asset="car-placeholder">
    Not sure? Find your vehicle by entering its&nbsp;
    <VehicleSwitcherButton link bolder prevent data-at-vehicle-switcher-button onClick={ handleSwitch }>
      Year, Make & Model
    </VehicleSwitcherButton>
  </Switcher>

export default VehicleSwitcher

const VehicleSwitcherButton = styled(Button)`
  && {
    padding: 0 0 3px;
  }
`

VehicleSwitcher.propTypes = {
  handleSwitch: PropTypes.func
}
