import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import { POPOVER_WIDTH } from './constants'

export const DefaultPopoverInnerContent = styled.span`
  position: absolute;
  display: ${props => props.active ? 'block' : 'none'};
  ${props => (props.popoverPosition.top || props.popoverPosition.top === 0) && css`
    top: ${props.popoverPosition.top}px;
  `}
  ${props => (props.popoverPosition.bottom || props.popoverPosition.bottom === 0) && css`
    bottom: ${props.popoverPosition.bottom}px;
  `}
  ${props => (props.popoverPosition.right || props.popoverPosition.right === 0) && css`
    right: ${props.popoverPosition.right}px;
  `}
  ${props => (props.popoverPosition.left || props.popoverPosition.left === 0) && css`
    left: ${props.popoverPosition.left}px;
  `}
  z-index: ${props => props.isModal ? '1100' : '998'};
  color: ${Theme.colors.dark_grey};
  padding: 10px;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid ${props => props.popoverPosition.bottom ? Theme.colors.off_white : Theme.colors.light_grey};
  background-color: ${Theme.colors.white};
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  width: ${POPOVER_WIDTH}px;
  line-height: 1em;
  outline: unset;

  &::after {
    display: block;
    position: absolute;
    background: ${Theme.colors.white};
    content: '';
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    ${props => props.popoverPosition.bottom && css`
      border-right: 1px solid rgba(0, 0, 0, 0.25);
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      bottom: -9px;
    `}
    ${props => props.popoverPosition.top && css`
      border-left: 1px solid ${Theme.colors.light_grey};
      border-top: 1px solid ${Theme.colors.light_grey};
      top: -8px;
    `}
    ${props => (props.popoverPosition.tickRight || props.popoverPosition.tickRight === 0) && css`
      right: ${props.popoverPosition.tickRight}px;
    `}
    ${props => (props.popoverPosition.tickLeft || props.popoverPosition.tickLeft === 0) && css`
      left: ${props.popoverPosition.tickLeft}px;
    `}
  }

  a {
    color: ${Theme.colors.blue};
    text-decoration: none;
    ${Mixin.font.GothamBold()}

    &:hover {
      text-decoration: underline;
    }
  }
`

export const DefaultPopoverLink = styled.span`
  position: relative;
  color: #1262ac;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const DefaultPopoverWrapper = styled.span`
  position: relative;
  display: inline-block;
`
