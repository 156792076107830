import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Modal from 'components/common/Modal'
import Theme from 'components/common/Theme/Theme'
import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import Mixin from 'components/common/Mixin'
import VehicleDetails from './components/VehicleDetails'
import { CompactVehicleNickname } from 'components/MyGarage2.0/components/SharedStyles'
import { getOnClickAndOnKeyPressDownEvents } from 'helpers/getOnClickAndOnKeyPressDownEvents'

const VehiclePersonalizationModal = ({
  closeModal,
  vehicleDetails: {
    vehicleName = ''
  } = {},
  vehicleDetails,
  ...props
}) =>
  <>
    <CustomModal
      data-at-vehicle-personalization
      closeHandler={ closeModal } >
      <div>
        <HeaderTitle>
          <Circle asset="check-circle" width={ 16 } height={ 16 } />
          Your vehicle was added succesfully.
        </HeaderTitle>
        <CloseButton
          aria-label="Close Vehicle Personalization dialog"
          link
          data-at-modal-close
          { ...getOnClickAndOnKeyPressDownEvents(closeModal) } >
          <Icon alt="close-btn" asset="cross" />
        </CloseButton>
      </div>
      <Title>NOW YOU CAN<br /> PERSONALIZE YOUR VEHICLE</Title>
      <Model>{ vehicleName }</Model>
      <VehicleDetails vehicleDetails={ vehicleDetails } { ...props } />
    </CustomModal>
  </>

export default VehiclePersonalizationModal

VehiclePersonalizationModal.propTypes = {
  closeModal: PropTypes.func,
  vehicleDetails: PropTypes.shape({
    vehicleName: PropTypes.string
  })
}

const CustomModal = styled(Modal)`
  & .modal-header {
    display: none;
  }

  ${Media.desktop`
    width: 480px;
    padding: 25px 24px 0;
  `}
`

const Circle = styled(Icon)`
  margin-right: 7px;
`

const HeaderTitle = styled.div`
  color: ${Theme.colors.green};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 9px;
  ${Mixin.font.GothamBold(14)};
  margin-bottom: 48px;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  line-height: 12px;
  border-radius: 25px;

  && {
    padding: 15px;
  }

  &&:hover {
    background-color: ${Theme.colors.off_white};
  }

  &&:active {
    background-color: ${Theme.colors.active_light_blue};
  }

  ${Media.mobile`
    top: 5px;
    right: 5px;
  `}
  ${Media.print`
    display: none;
  `}
`

const Title = styled(CompactVehicleNickname)`
  margin: 0 0 20px 0;
  text-align: center;
`

const Model = styled.h3`
  ${Mixin.font.GothamBold(20, 600, 20)};
  color: ${Theme.colors.caption_grey};
  text-align: center;
`
