import { GOOGLE_STATIC_MAPS_API_KEY } from 'constant'

const colors = {
  // shades
  black: '#1e1e1e', // primary text
  semidark_grey: '#333333',
  almost_grey: '#eeeeee',
  dark_grey: '#444444', // instructional text
  sub_grey: '#171717',
  medium_grey: '#777777', // secondary text
  off_medium_grey: '#757575',
  medium_grey_ui: '#919191',
  caption_grey: '#646464',
  grey: '#bbbbbb', // disabled buttons, rule lines
  light_grey: '#dddddd',
  light_grey_border: '#949494',
  off_white: '#f6f6f6', // backgrounds
  white_smoke: '#f2f2f2',
  white_smoke_medium: '#f5f5f5',
  gainsboro: '#d9d9d9',
  light_grey_background: '#f8f8f8',

  // colors
  dark_red: '#96181a', // gradients
  red: '#b02115', // buttons
  light_red: '#E21219', // header system alert
  bright_red: '#cc2c11', // error states
  blue: '#1262ac', // text links
  light_blue: '#E2F1F6',
  active_light_blue: '#D9EDF7', // active state buttons
  green: '#0f874b', // confirmation messages
  light_green: '#026a00', // button color
  yellow: '#ffa500', // hightlights
  pale_yellow: '#ffedcc', // alerts
  orange: '#D64309', // warnings
  light_orange: '#F4A52A',

  // New Design Colors
  bright_red_new: '#ed0d0f', // Button “CTAs” Color, Banner Background Color, Primary Color - Icons
  light_grey_new: '#ececec', // Background color
  crimson: '#96181a', // Button “CTAs” Hover State, Preferred Background Color
  burgundy: '#550706', // Background Color
  black_new: '#000000', // Font Color
  white: '#ffffff',
  chartreuse: '#e3e935', // Badges/Chips, etc, Quick Appt/Req Appt CTAs

  // utility colors
  transparent: 'transparent',
  transparent_white: 'rgba(0, 0, 0, 0.1)',
  transparent_white_count: 'rgba(255,255,255, 0.25)',
  transparent_white_hover: 'rgba(0, 0, 0, 0.2)',
  transparent_grey_hover: 'rgba(0, 0, 0, 0.3)',
  transparent_red: 'rgba(204, 44, 17, 0.05)',
  transparent_overlay: 'rgba(0, 0, 0, 0.5)',
  transparent_overlay_alt: 'rgba(255,255,255,0.8)',
  transparent_overlay_shadow: 'rgba(0, 0, 0, 0.25)',
  transparent_light_blue: 'rgba(0, 112, 223, 0.65)',
  transparent_blue: 'rgba(0, 112, 223, 0.4)'
}

const fonts = {
  Factoria: '"Factoria", "serif"',
  MakoMedium: '"MakoMedium", "serif"',
  MakoBold: '"MakoBold", "serif"',
  MakoExtraBold: '"MakoExtraBold", "serif"',
  GothamMedium: '"GothamMedium", "serif"',
  GothamBold: '"GothamBold", "serif"',
  GothamLight: '"GothamLight", "serif"',
  GothamBook: '"GothamBook", "serif"',
  GothamMediumItalic: '"GothamMediumItalic", "serif"',
  GothamBookItalic: '"GothamBookItalic", "serif"',
  Arial: '"Helvetica", "Arial", "sans-serif"',
  ArialBlack: '"Arial Black", "Arial", "sans-serif"',
  ArialNarrow: '"Arial Narrow", "Arial", "sans-serif"'
}

const keyCode = {
  ENTER: 13,
  ESCAPE: 27,
  TAB: 9,
  CLEAR: 28,
  HOME: 36,
  END: 35,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34
}

const keyString = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  TAB: 'Tab',
  CLEAR: 'Clear',
  HOME: 'Home',
  END: 'End',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  SPACE: ' ',
  PAGE_UP: 'Page Up',
  PAGE_DOWN: 'Page Down'
}

const sticky = {
  edge: 0
}

const scrollDebounceTimeout = 30

const map = {
  API_KEY: GOOGLE_STATIC_MAPS_API_KEY,
  DEFAULT_ZOOM: 18
}

export default {
  colors,
  fonts,
  keyCode,
  keyString,
  sticky,
  map,
  scrollDebounceTimeout
}
