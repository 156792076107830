import { compose, withProps, withHandlers, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { handleSelectValue } from 'components/VehicleConfigurator/components/withTireSize/actions'
import withActivateDropdown from '../withActivateDropdown'
import TireSizeSelector from './TireSizeSelector'
import { FIRST, DATA_NAME, WIDTH, RATIO, DIAMETER } from './constants'
import { FRONT } from 'components/VehicleConfigurator/components/withTireSize/constants'
import { MULTI_COLUMN_FRONT_SIZE, MULTI_COLUMN_REAR_SIZE } from '../MultiColumnModal/constants'
import { MultiColumnFrontSize, MultiColumnRearSize } from '../MultiColumnModal'
import withDropdownType from '../withDropdownType'

const withComponentStateHandlers = withStateHandlers(
  () => ({
    [WIDTH]: null,
    [RATIO]: null,
    [DIAMETER]: null
  }),
  {
    setRef: (state) => ({ name, ref }) => ({
      ...state,
      [name]: ref || state[name]
    })
  }
)

const withMultiColumnModal = withProps(({ position }) => {
  const isFrontSizeSelector = position === FRONT
  return {
    multiColumnMenuModal: isFrontSizeSelector
      ? MULTI_COLUMN_FRONT_SIZE : MULTI_COLUMN_REAR_SIZE,
    MultiColumnMenuComponent: isFrontSizeSelector
      ? MultiColumnFrontSize : MultiColumnRearSize
  }
})

const withHandleMultiColumnModalOptionSelect = withHandlers({
  onMultiColumnWidthSelect: ({ position, handleSelectValue }) => (value, onNextValuesFetched) =>
    handleSelectValue({ position, name: WIDTH, value, isModal: false }).then(() => onNextValuesFetched()),
  onMultiColumnRatioSelect: ({ position, handleSelectValue }) => (value, onNextValuesFetched) =>
    handleSelectValue({ position, name: RATIO, value, isModal: false }).then(() => onNextValuesFetched()),
  onMultiColumnDiameterSelect: ({ position, handleSelectValue }) => value =>
    handleSelectValue({ position, name: DIAMETER, value, isModal: false })
})

export default compose(
  connect(null, { handleSelectValue }),
  withComponentStateHandlers,
  withMultiColumnModal,
  withDropdownType,
  withHandleMultiColumnModalOptionSelect,
  withActivateDropdown({ first: FIRST, dataName: DATA_NAME })
)(TireSizeSelector)
