import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { components } from 'react-select'
import { setRequiredAttrs } from '../../../../helpers'

const ReactSelectInput = ({
  children,
  selectProps: { required, menuIsOpen, currentlyFocusedId, listboxId, isDisabled, inputValue, inputId, value },
  innerRef, // remove innerRef from props
  combinedRefSetter,
  onFocus,
  onBlur,
  onChange,
  ...props
}) =>
  /* eslint-disable jsx-a11y/role-has-required-aria-props */
  <StyledInput inputValue={ inputValue } value={ value }>
    <components.Input
      data-at-input
      { ...setRequiredAttrs(required) }
      role="combobox"
      aria-autocomplete="list"
      aria-expanded={ menuIsOpen }
      aria-haspopup="true"
      aria-owns={ listboxId }
      aria-activedescendant={ currentlyFocusedId }
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      type="text"
      value={ inputValue }
      id={ inputId }
      isDisabled={ isDisabled }
      innerRef={ combinedRefSetter }
      onFocus={ onFocus }
      onBlur={ onBlur }
      onChange={ onChange }
      { ...props }>
      { children }
    </components.Input>
  </StyledInput>

export default ReactSelectInput

ReactSelectInput.propTypes = {
  children: PropTypes.node,
  combinedRefSetter: PropTypes.func,
  handleHomeEndKeyDown: PropTypes.func,
  innerRef: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  selectProps: PropTypes.shape({
    required: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
    currentlyFocusedId: PropTypes.string,
    listboxId: PropTypes.string,
    isDisabled: PropTypes.bool,
    inputValue: PropTypes.string,
    inputId: PropTypes.string,
    value: PropTypes.any
  })
}

const StyledInput = styled.div`
  ${props => props.value && !props.inputValue && css`
    & .react-select__input input {
      opacity: 0 !important;
    }
  `}
`
