// prepare form before send:
// 1) remove excluded fields
// 2) recreate new form with Stringified values
export default (form, exclude = []) =>
  Object
    .keys(form)
    .filter(fieldName => !exclude.includes(fieldName))
    .reduce((newForm, key) => ({
      ...newForm,
      [key]: String(form[key])
    }), {})
