import { cloneDeep } from 'lodash'
import { FRONT, REAR } from '../VehicleConfigurator/components/withTireSize/constants'
import { YMME_STEPPER_STEP_ID } from './constant'

export const isLastElement = (totalSteps, index) => totalSteps !== index

export const isActiveElement = (step, index) => step === index

export const setPickedName = (data, name) => data[name] ? (data[name].label || data[name]) : name

export const setPickedTireSizeName = ({ data, name, step }) => {
  let position = FRONT

  if (step > 2) {
    position = REAR
  }

  return data[position][name]
    ? (data[position][name].label || data[position][name])
    : name
}

export const isPicked = (data, name) => data[name]

export const isTireSizePicked = ({ data, value, position }) => data[position][value]

export const resetPickedValues = (data, clickedIndex, stepNames) => {
  const dataDeepCopy = cloneDeep(data)
  stepNames.forEach((item, index) => {
    if (index >= clickedIndex) {
      dataDeepCopy[item] = null
    }
  })

  return dataDeepCopy
}

export const resetTireSizePickedValues = ({ data, clickedIndex, stepNames, activePosition }) => {
  const dataDeepCopy = cloneDeep(data)

  stepNames.forEach(({ value }, index) => {
    let position = FRONT

    if (activePosition) {
      position = activePosition
    }

    if (index >= clickedIndex) {
      if (index > 2) {
        position = REAR
      }
      dataDeepCopy[position][value] = null
    }
  })

  return dataDeepCopy
}

export const getNames = (clickedIndex, names) => {
  const transformNames = names.map(item => `${item}s`)
  const propperNames = transformNames.slice(clickedIndex, names.length)
  return propperNames
}

export const getTireSizeNames = (clickedIndex, stepNames) => {
  const slicedNames = stepNames.slice(clickedIndex, stepNames.length)
  const properNames = slicedNames.map(name => name.value)
  return properNames
}

export const searchAction = (data, value) =>
  data.filter(item => item.label.toString().toLowerCase().startsWith(value.toLowerCase()) && item)

export const callPropperSelectHandle = ({
  handleYearSelect,
  handleMakeSelect,
  handleModelSelect,
  handleSubModelSelect,
  step,
  value,
  ymmeName
}) => {
  switch (step) {
    case 0:
      handleYearSelect({ value, ymmeName })
      break
    case 1:
      handleMakeSelect({ value, ymmeName })
      break
    case 2:
      handleModelSelect({ value, ymmeName })
      break
    case 3:
      handleSubModelSelect({ value, ymmeName })
      break
    default:
      handleYearSelect({ value, ymmeName })
  }
}

export const scrollCurrentStepIntoView = (step) => {
  const stepElements = document.querySelectorAll(`#${YMME_STEPPER_STEP_ID}`)
  const currentStepElement = [...stepElements][step + 1]

  if (currentStepElement) {
    setTimeout(() => {
      currentStepElement.scrollIntoView({ inline: 'center', behavior: 'smooth' })
    }, 300)
  }
}

export const getOptions = ({ isWidthStep, endpointData, min, max, includeAlphaSizes = false }) => {
  if (!isWidthStep) return []

  return endpointData.filter(({ value }) => {
    const isInRange = value >= min && value <= max

    if (includeAlphaSizes) {
      return isInRange || isNaN(value)
    }

    return isInRange
  })
}

