import { isMobile, isTablet } from 'components/common/Media/helpers'
import { FROM_PROMOTIONS_URL_FLAG, RECOMMENDED_TYPES, GBB_TITLES } from 'constant'
import { RECOMMENDED_PRODUCTS_MINIMUM_COUNT } from './constants'
import { getElectricVehicleSpecifications } from 'components/common/ProductFlag/helpers'

export const userFromPromotionsPage = query => query.includes(FROM_PROMOTIONS_URL_FLAG)

export const getRecommendedProductsWithFixOrder = (source = {}) =>
  RECOMMENDED_TYPES.reduce((acc, curr) => (
    source[curr] && source[curr].productDisplayName ?
      [...acc, { ...source[curr], type: curr }] : acc
  ), []) || []

export const getRecommendedProductsSortedByPrice = source =>
  getRecommendedProductsWithFixOrder(source).sort((firstEl, secondEl) => firstEl.price - secondEl.price)

export const getRecommendedProductSkus = (source, gBBTitles = GBB_TITLES) =>
  source.reduce((acc, { skuId, type }) => (
    ({ ...acc, [skuId]: { title: gBBTitles[type], skuId, type }})
  ), {})

export const checkRecommendedProductsVisibility = ({ products = [], query = '' }) =>
  products.length && !userFromPromotionsPage(query)

export const getGTMRecommendedProductData = product => {
  const {
    productDisplayName: name,
    skuId: id,
    price,
    brandName: brand,
    skuDisplayName: variant,
    // additional props
    position,
    label
  } = (product || {})

  return {
    name,
    id,
    price,
    brand,
    position: position + 1,
    category: 'Tires',
    variant: variant || 'none',
    list: 'Best Matches For Your Vehicle',
    dimension1: 'In Stock',
    dimension2: label
  }
}

export const getGTMRecommendedProducts = ({
  products = [],
  favoriteSkus = [],
  oeItemIdsList = []
}) =>
  products
    .map((product = {}, index) => {
      const productData = getGTMRecommendedProductData(product)

      return {
        ...productData,
        position: index + 1,
        dimension2: GBB_TITLES[product.type],
        dimension28: favoriteSkus.includes(product.skuId),
        dimension30: oeItemIdsList.includes(product.skuId),
        dimension31: Boolean(getElectricVehicleSpecifications(product.specifications).length)
      }
    })

export const getRecommendedProductColWidth = (column) => `${Math.round(100 / column)}%`

export const getShowRecommendedProductsInline = (totalRecommendedProductsCount) => {
  if (isMobile()) return false
  if (isTablet() && totalRecommendedProductsCount === 1) return true
  if (!isTablet() && totalRecommendedProductsCount < RECOMMENDED_PRODUCTS_MINIMUM_COUNT) return true
  return false
}

export const getGBBTitles = () => RECOMMENDED_TYPES.reduce((acc, curr) => ({ ...acc, [curr]: GBB_TITLES[curr] }), {})
