import { compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import registerModalPage from 'components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { CURBSIDE_SERVICE_MODAL } from 'constant'
import CurbsideServiceModal from './CurbsideServiceModal'

export default compose(
  registerReduxModal({ name: CURBSIDE_SERVICE_MODAL }),
  registerModalPage({
    pageSrc: '/modals/curbside-service',
    rootType: MODAL_BOX_TYPE
  })
)(CurbsideServiceModal)
