import React, { PureComponent } from 'react'

/* eslint-disable react/prop-types */
const position = (WrappedComponent, defaultValue = 0) => {
  class Position extends PureComponent {
    constructor(props) {
      super(props)

      this.defaultValue = defaultValue
      this.state = { position: props.position || defaultValue }
    }

    change = (index) => this.setState({ position: index })

    render = () =>
      <WrappedComponent
        { ...this.props }
        position={ this.state.position }
        positionHandler={ this.change } />
  }

  return Position
}

export default position
