import { handleActions } from 'redux-actions'
import { setPromotionsData } from './actions'

const initialState = {
  lightBoxPromotionVo: []
}

const getPromotionsReducer = handleActions(
  {
    [setPromotionsData]: (state, { payload }) => ({ ...state, lightBoxPromotionVo: payload })
  },
  initialState
)

export default getPromotionsReducer
