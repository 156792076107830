import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Media from 'components/common/Media'
import Image from 'components/common/Image'

const LicensePlateInput = React.forwardRef(({ handleContentFocus, active, isFromTrailer }, ref) =>
  <PopoverContent
    isFromTrailer={ isFromTrailer }
    active={ active }
    aria-hidden={ !active }
    role="tooltip"
    tabIndex="-1"
    data-at-popover-inner-content
    ref={ ref }
    onFocus={ handleContentFocus }>
    <TireSizeImage
      data-at-tire-size-tooltip-image
      src="//tbc.scene7.com/is/image/TBCCorporation/bot-tiresize?wid=378&qlt=40"
      alt="The tire size is set by the numbers on the tire. The numbers are width, ratio and diameter in order" />
  </PopoverContent>
)

export default LicensePlateInput

LicensePlateInput.propTypes = {
  active: PropTypes.bool,
  handleContentFocus: PropTypes.func,
  isFromTrailer: PropTypes.bool
}

const TireSizeImage = styled(Image)`
  max-width: 100%;
`

const PopoverContent = styled.div`
  position: absolute;
  display: ${props => props.active ? 'block' : 'none'};
  top: 30px;
  left: -167.719px;
  z-index: 998;
  color: rgb(68, 68, 68);
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(255, 255, 255);
  /* stylelint-disable-next-line length-zero-no-unit */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 4px;
  line-height: 1em;
  outline: unset;
  padding: 30px 5px 5px;
  width: 390px;
  ${Media.mobile`
    width: 320px;
  `}

  &::after {
    display: block;
    position: absolute;
    background: rgb(255, 255, 255);
    content: "";
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-left: 1px solid rgb(221, 221, 221);
    border-top: 1px solid rgb(221, 221, 221);
    top: -8px;
    left: 173px;
  }

  @media (max-width: 1335px) {
    ${props => props.isFromTrailer && css`
      left: -28px;

      &::after {
        left: 28px;
      }
    `}
  }
`
