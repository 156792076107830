import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Link from 'components/common/Link/Link'
import Circle from 'components/common/Circle/Circle'
import { filterProps } from 'helpers/utils'

const HeaderAccountMenuGarageLink = ({
  vehicleCount,
  displayName,
  link: {
    path = ''
  } = {},
  ...props
}) =>
  <GarageLinkWrapper>
    <GarageLink { ...props } to={ path }>{ displayName }</GarageLink>
    {
      vehicleCount > 0 &&
      <GarageCircle>{ vehicleCount }</GarageCircle>
    }
  </GarageLinkWrapper>

export default HeaderAccountMenuGarageLink


HeaderAccountMenuGarageLink.propTypes = {
  displayName: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.any
  }),
  name: PropTypes.string,
  vehicleCount: PropTypes.number
}

const GarageLinkWrapper = styled.div`
  position: relative;
  ${Media.mobile`
    ${Link} {
      :first-child {
        margin-top: 0;
      }
    }
  `}
`

const propsToRemove = [
  'altTag',
  'dispatch',
  '@type'
]

const GarageLink = styled(props => <Link { ...filterProps(props, propsToRemove) } />)([])

const GarageCircle = styled(Circle)`
  position: absolute;
  right: 15px;
  top: 50%;
  min-width: 22px;
  transform: translateY(-50%);
  z-index: 2;
`
