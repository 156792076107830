import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import * as UI from './Tabs.styles.js'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'

const Tabs = ({
  children,
  className,
  menu,
  position,
  positionHandler,
  onClick,
  onKeyDown,
  setFocusScopeRef,
  modal,
  ymme,
  margin
}) =>
  <React.Fragment>
    <UI.Underline className={ className } margin={ margin } ymme={ ymme }>
      <UI.TabSelectorWrapper
        aria-label="Tab list"
        role="tablist"
        modal={ modal }
        ymme={ ymme }
        ref={ setFocusScopeRef }>
        {
          menu.map(({ title, value }, index) =>
            <UI.TabItem
              { ...dynamicDataAttribute('tab-item', toOneString(title)) }
              key={ index }
              id={ `${kebabCase(title)}-tab-item-${index}` }
              title={ title }
              value={ value }
              role="tab"
              tabIndex={ index === position ? null : '-1' }
              aria-selected={ index === position }
              aria-controls={ `${kebabCase(title)}-tab-panel-${index}` }
              isActive={ index === position }
              ymme={ ymme }
              onClick={ onClick }
              onKeyDown={ onKeyDown }>
              { title }
              { value &&
              <UI.Circle>{ value }</UI.Circle>
              }
            </UI.TabItem>
          )
        }
      </UI.TabSelectorWrapper>
    </UI.Underline>
    {
      React.Children.map(children, (child, index) =>
        menu[index] &&
          <UI.TabPanel
            aria-labelledby={ `${kebabCase(menu[index].title)}-tab-item-${index}` }
            id={ `${kebabCase(menu[index].title)}-tab-panel-${index}` }
            role="tabpanel"
            tabIndex={ menu[index].noTabPanelFocus ? null : '0' }
            active={ index === position }>
            { React.cloneElement(child, { changeActiveTab: index => positionHandler(index) }) }
          </UI.TabPanel>
      )
    }
  </React.Fragment>

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  margin: PropTypes.string,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      title: PropTypes.string,
      noTabPanelFocus: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ),
  modal: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  position: PropTypes.number,
  positionHandler: PropTypes.func,
  setFocusScopeRef: PropTypes.func,
  ymme: PropTypes.bool
}

export default Tabs
