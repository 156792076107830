import { MAX_WIDTH } from 'constant'
import some from 'lodash/some'

export const generateCarouselSettingsDesktop = (count) => {
  const breakpoint = (count * 250) + 393
  const defaultSettings = {
    slidesToShow: 5,
    infinite: false,
    arrows: count > 5
  }

  const dynamicBreakpoint = {
    breakpoint: breakpoint < MAX_WIDTH ? breakpoint : 99999,
    settings: {
      ...defaultSettings,
      variableWidth: true
    }
  }

  const responsivePoints = [
    {
      breakpoint: 1303,
      settings: {
        ...defaultSettings,
        arrows: count > 3,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1555,
      settings: {
        ...defaultSettings,
        arrows: count > 4,
        slidesToShow: 4
      }
    },
    {
      breakpoint: 100000,
      settings: {
        ...defaultSettings,
        arrows: false
      }
    }
  ]

  const allResponsivePoints = responsivePoints.reduce((acc, cur, index, orig) => {
    const customBreakpoint = {
      ...dynamicBreakpoint,
      settings: { ...dynamicBreakpoint.settings, slidesToShow: orig[index].settings.slidesToShow }
    }
    const updatedCurrentBreakpoint = {
      breakpoint: cur.breakpoint,
      settings: { ...customBreakpoint.settings, ...cur.settings }
    }
    if (index !== orig.length - 1) {
      if (cur.breakpoint > customBreakpoint.breakpoint && !some(acc, [breakpoint, customBreakpoint.breakpoint])) {
        return [
          ...acc,
          customBreakpoint,
          {
            breakpoint: cur.breakpoint,
            settings: { ...customBreakpoint.settings, ...cur.settings }
          }]
      } else if (cur.breakpoint <= customBreakpoint.breakpoint) {
        return [...acc, { ...updatedCurrentBreakpoint }]
      }
    } else if (!some(acc, [breakpoint, customBreakpoint.breakpoint])) {
      return cur.breakpoint > customBreakpoint.breakpoint ?
        [...acc, customBreakpoint, cur] : [...acc, { ...updatedCurrentBreakpoint }, customBreakpoint]
    }
    return [...acc, cur]
  }, [])

  return ({
    slidesToScroll: 1,
    dots: false,
    draggable: false,
    autoplay: false,
    infinite: false,
    responsive: [
      ...allResponsivePoints
    ]
  })
}

export const generateCarouselSettingsMobile = count => ({
  slidesToScroll: 1,
  slidesToShow: 1,
  dots: false,
  draggable: false,
  autoplay: false,
  arrows: count > 1,
  infinite: false
})
