import { getPageLocation } from 'selectors'
import { getPreviousPageUrl } from 'selectors/navigations'
import { isQuotePage, isApptConfirmationPage } from 'helpers/quoteRelatedPages'
import { QUOTE_APPOINTMENT_INFO_PAGE } from 'constant'


export const getAppointmentConfirmationUrl = (isFromOnlineAppointment, storeId, orderId) => {
  const baseURL = QUOTE_APPOINTMENT_INFO_PAGE
  const URLWithParams = `${baseURL}?isFromOnlineAppointment=${isFromOnlineAppointment}&StoreID=${storeId}`

  if (!isFromOnlineAppointment) {
    return URLWithParams.concat(`&orderId=${orderId}`)
  }

  return URLWithParams
}

export const IsFromAppointmentConfirmationToQuote = state =>
  isQuotePage(getPageLocation(state).pathname) && isApptConfirmationPage(getPreviousPageUrl(state))

export const getActiveUserVehicleId = (vehicles = []) => {
  const [{ userVehicleId } = {}] = vehicles.filter(({ isActiveVehicle }) => isActiveVehicle)
  return userVehicleId
}

export const populateContactInfoField = (values, fieldName, userValue, storedContactDetails) => (values && typeof values[fieldName] === 'string') ?
  values[fieldName] : ((values && values[fieldName] !== null && storedContactDetails && typeof storedContactDetails[fieldName] === 'string') ?
    storedContactDetails[fieldName] : ((values && values[fieldName] !== null) ? userValue : null))
