import { isRearTire } from 'helpers/googleAnalytic'

export const mapFavoriteItemToProduct = ({
  skuId,
  staggeredSkuId,
  name,
  mainImageUrl,
  brandLogo,
  hasRearTire,
  brandName,
  isSpecialOrder,
  specifications = [],
  rearSpecifications = [],
  tireDetailUrl,
  warranties = [],
  promotions = [],
  priceChanged,
  rearSkuId,
  frontQuantity = 0,
  quantity = 0,
  rearQuantity = 0,
  rearTire: {
    bvId: rearTireBvId,
    salePriceAvailable: rearSalePriceAvailable,
    hasMapPrice: rearHasMapPrice,
    salePrice: rearSalePrice,
    availability: rearAvailability,
    tireSize: rearTireSize,
    listPrice: rearListPrice,
    externalId: rearExternalId,
    seePriceIncartMessage: rearSeePriceIncartMessage,
    compatibilityStatus: {
      compatibilityType: rearCompatibilityType
    } = {}
  } = {},
  frontTire: {
    bvId,
    salePriceAvailable: frontSalePriceAvailable,
    hasMapPrice: frontHasMapPrice,
    salePrice: frontSalePrice,
    availability: frontAvailability,
    tireSize: frontTireSize,
    listPrice: frontListPrice,
    externalId: frontExternalId,
    seePriceIncartMessage: frontSeePriceIncartMessage,
    compatibilityStatus: {
      compatibilityType: frontCompatibilityType
    } = {}
  } = {}
}) => ({
  domId: `favoriteItem-${hasRearTire ? staggeredSkuId : skuId}`,
  priceChanged: true,
  hasRearTire,
  attributes: {
    bvId,
    warrantyBadges: warranties.map(({ warrantyName, warrantyValue }) => ({ name: warrantyName, value: warrantyValue })),
    brandName: [brandName],
    isSpecialOrderItem: [isSpecialOrder],
    staggeredSkuId: [staggeredSkuId],
    frontTire: [
      {
        salePriceAvailable: frontSalePriceAvailable,
        salePrice: frontSalePrice,
        frontTireSize,
        externalId: frontExternalId,
        seePriceIncartMessage: frontSeePriceIncartMessage,
        specification: specifications,
        compatibilityState: frontCompatibilityType,
        availability: frontAvailability,
        hasMapPrice: frontHasMapPrice,
        frontTireSkuId: skuId,
        listPrice: frontListPrice,
        quantity: hasRearTire ? frontQuantity : quantity
      }
    ],
    rearTire: hasRearTire ? [
      {
        salePriceAvailable: rearSalePriceAvailable,
        salePrice: rearSalePrice,
        rearTireSize,
        externalId: rearExternalId,
        seePriceIncartMessage: rearSeePriceIncartMessage,
        specification: rearSpecifications,
        compatibilityState: rearCompatibilityType,
        availability: rearAvailability,
        hasMapPrice: rearHasMapPrice,
        rearTireSkuId: rearSkuId,
        listPrice: rearListPrice,
        quantity: rearQuantity,
        bvId: rearTireBvId
      }
    ] : [],
    brandLogoUrl: [brandLogo],
    tireDetailUrl: [tireDetailUrl],
    tireLineName: [name],
    imageUrl: [mainImageUrl],
    ratingScore: [0],
    promotions: promotions.map(({ promotionTitle, promotionId }) =>
      ({ displayName: promotionTitle, link: '', promoId: promotionId })),
    zeroPriceItemMessage: !frontListPrice || !rearListPrice ? ['zero_price_item_message'] : []
  }
})

export const mapFavoriteItemToPriceChangedItems = ({
  brandName: brandLineName,
  brandLogo: brandImageUrl,
  name: productName,
  mainImageUrl: skuImageUrl,
  hasRearTire,
  priceChanged,
  frontTire: {
    listPrice: frontListPrice = 0,
    tireSize: frontTireSize = ''
  } = {},
  rearTire: {
    listPrice: rearListPrice = 0,
    tireSize: rearTireSize = ''
  } = {}
}) => {
  const result = []
  if (frontListPrice > 0) {
    result.push({
      brandLineName,
      brandImageUrl,
      productName,
      skuImageUrl,
      priceChanged,
      price: frontListPrice,
      description: frontTireSize })
  }

  if (hasRearTire && rearListPrice > 0) {
    result.push({
      brandLineName,
      brandImageUrl,
      productName,
      skuImageUrl,
      priceChanged,
      price: rearListPrice,
      description: rearTireSize })
  }

  return result
}

// GTM helpers
export const getGTMDataMyFavorites = ({ records = [], position }) => {
  try {
    return records.reduce((previousValue, { attributes: {
      tireLineName = [],
      frontTire = [],
      brandName = [],
      rearTire = []
    }}, index) => {
      if (!frontTire.length) {
        return previousValue
      }

      if (!isRearTire(rearTire)) {
        return [...previousValue,
          {
            name: tireLineName[0],
            id: frontTire[0].frontTireSkuId,
            price: Number(frontTire[0].salePrice || frontTire[0].listPrice),
            brand: brandName[0],
            category: 'Tires',
            variant: frontTire[0].frontTireSize,
            list: 'Favorites',
            position: (position || index) + 1,
            dimension28: true
          }
        ]
      }

      return [...previousValue,
        {
          name: tireLineName[0],
          id: frontTire[0].frontTireSkuId,
          price: Number(frontTire[0].salePrice || frontTire[0].listPrice),
          brand: brandName[0],
          category: 'Tires',
          variant: frontTire[0].frontTireSize,
          list: 'Favorites',
          position: (position || index) + 1,
          dimension28: true
        },
        {
          name: tireLineName[0],
          id: rearTire[0].rearTireSkuId,
          price: Number(rearTire[0].salePrice || rearTire[0].listPrice),
          brand: brandName[0],
          category: 'Tires',
          variant: rearTire[0].rearTireSize,
          list: 'Favorites',
          position: (position || index) + 1,
          dimension28: true
        }
      ]
    }, [])
  } catch (err) {
    console.log(err)
  }
}

export const getSharedTitle = (firstName, lastName, email) => {
  let sharedTitle = ''

  if (!firstName && !lastName) {
    sharedTitle = email
  }

  if (!firstName && lastName) {
    sharedTitle = lastName
  }

  if (firstName) {
    sharedTitle = firstName
  }

  return sharedTitle
}

export const getOtherFavoritesTitle = (firstName, lastName, email) => {
  let otherFavoritesTitle = ''

  if (!firstName && !lastName) {
    otherFavoritesTitle = email
  }

  if (!firstName && lastName) {
    otherFavoritesTitle = lastName
  }

  if (firstName) {
    otherFavoritesTitle = firstName
  }

  const title = `All other ${otherFavoritesTitle}'s favorites`

  return title
}
