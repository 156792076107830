export default [
  'AppointmentOneColumnPage',
  'AppointmentStepTeaserContent',
  'BOTAppointmentSummary',
  'BOTDateTimeSelector',
  'BOTMakeAppointmentHeader',
  'BOTQuickAppointmentContinue',
  'BOTServicesSelector',
  'BOTStoreLocation',
  'BOTVehicleSelector',
  'BOTProductAndServicesQuote',
  'BOTQuoteAppointmentContinue',
  'BOTShoppingCartQuote',
  'BOTYourStore',
  'BOTYourVehicle',
  'ShoppingQuoteOneColumnPage',
  'BOTAppointmentContactDetails',
  'BOTPaymentOptionsBanner'
]

/*
Appears on:
/appointment/appointment-confirmation,
/appointment/quick-appointment,
/my-account/my-appointments?loggedin=true,
/my-account/my-appointments,
/quote,
/careers,
/location/ca-los-angeles-90064/005768,
/modals/find-lower-price?skuId=000000000001087501-BOT,
/my-account/register,
/my-account/service-history?access=signup,
/regions/bay-area,
/tires/compare-tires,
/tires/trailer,
/tires/winter-snow,
/wheel-visualizer
*/
