import servers from 'config/servers'

const {
  proxy: {
    host,
    port,
    root,
    origin,
    prefix,
    basePath
  },
  webpack: {
    host: webpackDevServerHost,
    port: webpackDevServerPort
  }
} = servers

const useMocks = process.env.BOT_mocks_enable
const onServer = process.env.BOT_IS_SERVER

const baseURL = useMocks === 'true' && onServer === 'true' ?
  // if mocks enable then proxy to webpack dev server
  `${webpackDevServerHost}:${webpackDevServerPort}/mocks` :
  onServer === 'true' ?
    // if we are on server environment use proxy server url
    `${host}:${port}${root}` :
    // on client use a relative url to go to NodeJs server and proxy request from there
    prefix

// will be used as axios instance options
const axiosConfig = {
  baseURL,
  timeout: 40000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Requested-By': '123'
  }
}

// add Host header if we're on server
if (onServer && origin) {
  axiosConfig.headers.Host = origin
}

// define config for api
const apiConfig = {
  base: basePath
}

export { axiosConfig, apiConfig }
