import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { MOBILE } from '../../Media/constants'

const Desktop = ({ children, screenFits }) =>
  (screenFits !== MOBILE) &&
    <Fragment>{ children }</Fragment>

Desktop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  screenFits: PropTypes.string
}

export default Desktop
