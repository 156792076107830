import React from 'react'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'

const generateURL = ({ address1, city, state, zipcode, width }) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${address1},${city},${state},${zipcode}&zoom=${Theme.map.DEFAULT_ZOOM}&size=${width}x100&&markers=color:red%7C${address1},${city},${state},${zipcode}&key=${Theme.map.API_KEY}`

class GoogleMapImage extends React.Component {
  componentDidMount() {
    this.img.src = generateURL({ ...this.props, width: this.img.width })
  }

  render = () => <GoogleImg ref={ img => { this.img = img } } />
}

const GoogleImg = styled.img`
  display: block;
  width: 100%;
  height: 100px;
`
export default GoogleMapImage
