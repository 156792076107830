import { compose, withStateHandlers, withHandlers, withProps } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import {
  INITIAL_STEP,
  MULTI_COLUMN_VEHICLE_MENU,
  MULTI_COLUMN_FRONT_SIZE,
  MULTI_COLUMN_REAR_SIZE
} from './constants'
import { conformSteps } from './helpers'
import MultiColumnModal from './MultiColumnModal'

const withModalStateHandlers = withStateHandlers(
  ({ step = INITIAL_STEP }) => ({
    currentStep: step,
    isLoading: false
  }),
  {
    setStep: () => step => ({ currentStep: step }),
    stepForward: ({ currentStep }) => () => ({
      currentStep: currentStep + 1
    }),
    setIsLoading: () => isLoading => ({ isLoading })
  }
)

const withModalHandlers = withHandlers({
  handleStepForward: ({
    handleHide,
    stepForward,
    isLastStep,
    setIsLoading,
    currentStepOnSelect
  }) => value => {
    setIsLoading(true)
    currentStepOnSelect(value, () => setIsLoading(false))
    isLastStep ? handleHide() : stepForward()
  }
})

const withStepProps = withProps(({ steps, currentStep }) => {
  const {
    stepName: currentStepName,
    stepOptions: currentStepOptions,
    selectedValue: currentStepSelectedValue,
    onOptionSelect: currentStepOnSelect
  } = steps[currentStep]
  return {
    currentStep,
    currentStepName,
    currentStepSelectedValue,
    currentStepOptions,
    currentStepOnSelect,
    isLastStep: currentStep + 1 === steps.length,
    headerSteps: conformSteps(steps)
  }
})

const MultiColumnModalBaseComponent = compose(
  withModalStateHandlers,
  withStepProps,
  withModalHandlers
)(MultiColumnModal)

export default registerReduxModal({
  name: MULTI_COLUMN_VEHICLE_MENU
})(MultiColumnModalBaseComponent)

export const MultiColumnFrontSize = registerReduxModal({
  name: MULTI_COLUMN_FRONT_SIZE
})(MultiColumnModalBaseComponent)

export const MultiColumnRearSize = registerReduxModal({
  name: MULTI_COLUMN_REAR_SIZE
})(MultiColumnModalBaseComponent)
