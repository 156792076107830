import { handleActions } from 'redux-actions'
import { setRelatedArticles } from './actions'

const initialState = {
  articles: []
}

const relatedArticles = handleActions({
  [setRelatedArticles]: (state, { payload: { relatedArticlesData }}) =>
    ({
      ...state,
      articles: [...relatedArticlesData]
    })
}, initialState)

export default relatedArticles
