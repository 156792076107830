import React from 'react'
import PropTypes from 'prop-types'

const withEventListener = ({ eventName, getHandler }) => Component => {
  class WithEventListener extends React.Component {
    componentDidMount() {
      window.addEventListener(this.eventName, this.handleEvent)
    }

    componentWillUnmount() {
      window.removeEventListener(this.eventName, this.handleEvent)
    }

    eventName = eventName || this.props.eventName

    handleEvent = getHandler ? getHandler(this.props) : this.props.handleEvent

    render = () => <Component { ...this.props } />
  }

  WithEventListener.propTypes = {
    eventName: PropTypes.string,
    handleEvent: PropTypes.func
  }

  return WithEventListener
}

export default withEventListener
