import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'
import { TIRE_SIZES_NOT_FOUND_MODAL } from 'constant'
import NewLicensePlate from './NewLicensePlate'
import { LICENSE_PLATE_STEP_NAMES } from '../NewTireSearch/constant'
import withLicencePlate from '../VehicleConfigurator/components/withLicencePlate'
import { transformStates } from './helpers'
import { addVehicle } from 'actions/addVehicle'
import { getTireSizes, navigateToConfirmTireSize } from '../VehicleConfigurator/actions'
import { hide } from 'redux-modal'
import { hideNewShopTiresModal } from '../NewShopTiresModal/actions'
import { trigerConfirmTireSizeModal } from '../NewYMME/actions'
import { refresh } from '../../actions/router'

const withDataLoading = withState('dataLoading', 'setDataLoading', false)
const stepperStepState = withState('step', 'setStep', 0)
const licensePlateEndpointState = withState('endpointData', 'setEndpointData', [])
const licensePlateSelectorState = withState('optionsData', 'setOptionsData', [])
const searchInputValueState = withState('search', 'setSearch', '')
const licensePlateState = withState('data', 'setData', {
  plate: null,
  state: null
})
const plateValue = withState('plate', 'setPlate', '')
const withError = withState('error', 'setError', '')
const licensePlateError = withState('licenseError', 'setLicenseError')

const withNewLicensePlateHandlers = withHandlers({
  getLicensePlateHandler: ({ setPlate, setError, setLicenseError }) => (e) => {
    const value = e.target.value
    setError('')
    setLicenseError('')
    setPlate(value)
  },
  nextClick: ({ setData, data, setStep, step, plate, setError, setPlate }) => () => {
    const stepName = LICENSE_PLATE_STEP_NAMES[step]
    if (/^[a-zA-Z0-9]+$/.test(plate) && plate) {
      setData({
        ...data,
        [stepName]: { label: plate, value: plate }
      })
      setStep(step => step + 1)
      setPlate('')
    } else {
      setError('Please enter a valid License Plate Number.')
    }
  },
  onSubmit: ({
    handleVehicleSelect,
    getVehicleByPlateNumber,
    data,
    setLicenseError,
    setDataLoading
  }) => (state) => {
    const values = {
      licensePlate: data.plate.value,
      state: state.value
    }
    setDataLoading(true)
    const tireSizesNotFoundModalProps = {
      onCancelClick: () => hide(TIRE_SIZES_NOT_FOUND_MODAL)
    }
    getVehicleByPlateNumber(values, tireSizesNotFoundModalProps, handleVehicleSelect)
      .catch(error => {
        setDataLoading(false)
        setLicenseError(error.errors._error)
      })
  }
})

const withHandleVehicleSelect = withHandlers({
  handleVehicleSelect: ({
    addVehicle,
    getTireSizes,
    skipTireSizeExperience,
    navigateToConfirmTireSize,
    hideNewShopTiresModal,
    withShowConfirmTireSizeModal,
    trigerConfirmTireSizeModal,
    source,
    promo,
    refresh,
    isOnlyVehicle
  }) => vehicleDetails => {
    skipTireSizeExperience
      ? addVehicle({ ...vehicleDetails }).then(() => {
        hideNewShopTiresModal()
        refresh()
      })
      : getTireSizes({ ...vehicleDetails })
        .then(hideNewShopTiresModal)
        .then(() => {
          withShowConfirmTireSizeModal
            ? trigerConfirmTireSizeModal({ source, promo, isOnlyVehicle })
            : navigateToConfirmTireSize()
        })
  }
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const {
      states,
      setOptionsData,
      setEndpointData,
      licensePlateData,
      licensePlateData: { plate = '' } = {},
      setStep,
      setData
    } = this.props

    if (plate) {
      setStep(1)
      setData(licensePlateData)
    }

    if (states.length) {
      setOptionsData(transformStates(states))
      setEndpointData(transformStates(states))
    }
  },

  componentDidUpdate({ search: prevSearch, states: prevStates, step: prevStep }) {
    const { states, setOptionsData, search, setEndpointData, optionsData, step, setLicenseError } = this.props

    if (states.length && !isEqual(prevStates, states)) {
      setOptionsData(transformStates(states))
      setEndpointData(transformStates(states))
    }

    if (!search && !isEqual(prevSearch, search)) {
      setEndpointData(optionsData)
    }

    if (!isEqual(prevStep, step)) {
      setLicenseError('')
    }
  }
})

export default compose(
  connect(null, {
    addVehicle,
    getTireSizes,
    navigateToConfirmTireSize,
    hideNewShopTiresModal,
    trigerConfirmTireSizeModal,
    refresh
  }),
  withLicencePlate,
  withDataLoading,
  licensePlateError,
  searchInputValueState,
  licensePlateSelectorState,
  withError,
  plateValue,
  stepperStepState,
  licensePlateEndpointState,
  licensePlateState,
  withHandleVehicleSelect,
  withNewLicensePlateHandlers,
  withLifecycle
)(NewLicensePlate)
