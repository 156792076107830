import React from 'react'
import { components } from 'react-select'
import { withProps } from 'recompose'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'

const ReactSelectContainer = ({ ...props }) =>
  components.SelectContainer && (
    <components.SelectContainer { ...props } />
  )

const withModifiedInnerProps = withProps(({ innerProps = {}, selectProps: { label }}) =>
  ({ innerProps: { ...dynamicDataAttribute('dropdown-container', toOneString(label)), ...innerProps }}))

export default withModifiedInnerProps(ReactSelectContainer)
