import { mapProps, compose, withStateHandlers, withHandlers, withProps } from 'recompose'
import withKeyPress from 'components/common/hoc/withKeyPress/withKeyPress'
import withForwardingRef from 'components/common/hoc/withForwardRef'
import debounce from 'lodash/debounce'
import { generateId } from '../../helpers'
import { getSelectedOption } from './helpers'
import {
  ReactSelectMenuList,
  ReactSelectOption,
  ReactSelectInput,
  ReactSelectValueContainer,
  ReactSelectDropdownIndicator,
  ReactSelectContainer
} from './components'
import ReactSelectDropdownInput from './ReactSelectDropdownInput'

const withValueFromOptions = mapProps(({
  value,
  reset,
  options = [],
  matchFromStart = true,
  components = {},
  ...props }) => ({
  ...props,
  options,
  components: {
    Input: ReactSelectInput,
    MenuList: ReactSelectMenuList,
    Option: ReactSelectOption,
    ValueContainer: ReactSelectValueContainer,
    DropdownIndicator: ReactSelectDropdownIndicator,
    SelectContainer: ReactSelectContainer,
    ...components
  },
  filterConfig: {
    matchFrom: matchFromStart ? 'start' : 'any'
  },
  value: reset ? null : getSelectedOption({ options, value })
}))

const withDropdownRefs = withStateHandlers({ dropdownWrapperRef: null }, {
  setDropdownWrapperRef: () => ref => ({ dropdownWrapperRef: ref })
})

const withFocusIdHandlers = withStateHandlers({ currentlyFocusedId: '' }, {
  setFocusedId: (state, { dropdownWrapperRef }) => () => {
    const focusedOption = dropdownWrapperRef.querySelector('.react-select__option--is-focused')
    return {
      currentlyFocusedId: (focusedOption && focusedOption.parentElement && focusedOption.parentElement.id) ?
        focusedOption.parentElement.id : ''
    }
  },
  clearFocusedId: () => () => ({ currentlyFocusedId: '' })
})

const withKeyHandlers = withHandlers({
  home: () => e => e.stopPropagation(),
  end: () => e => e.stopPropagation(),
  up: ({ setFocusedId }) => () => setTimeout(setFocusedId),
  down: ({ setFocusedId }) => () => setTimeout(setFocusedId)
})

const withMouseHandlers = withHandlers({
  handleOptionMouseOver: ({ setFocusedId }) => debounce(setFocusedId, 150)
})

const withInputHandlers = withHandlers({
  setValueAfterSelection: ({ value: { label: valueLabel }, wrapperRef }) => () => {
    /* eslint-disable no-param-reassign */
    wrapperRef.value = valueLabel
  }
})

const withDropdownStateHandlers = withStateHandlers({ menuIsOpen: false }, {
  handleOnMenuOpen: (state, { setFocusedId }) => () => {
    setTimeout(setFocusedId)
    return { menuIsOpen: true }
  },
  handleOnMenuClose: (state, { clearFocusedId, setValueAfterSelection, value }) => () => {
    setTimeout(clearFocusedId)
    if (value) {
      setTimeout(setValueAfterSelection)
    }
    return { menuIsOpen: false }
  }
})

const withIds = withProps(
  ({ label, value, ...props }) => ({
    listboxId: `${label}-menu-list`,
    inputId: generateId({ label, value, ...props })
  })
)

export default compose(
  withForwardingRef,
  withValueFromOptions,
  withDropdownRefs,
  withFocusIdHandlers,
  withKeyHandlers,
  withKeyPress,
  withMouseHandlers,
  withInputHandlers,
  withDropdownStateHandlers,
  withIds
)(ReactSelectDropdownInput)
