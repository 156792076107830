import { isFunction } from 'helpers/utils'

const NO_WHITE_SPACES = /^(?!\s*$).+/
const FIRST_NAME_REGEXP = /^[a-zA-Z\s-']{0,20}$/
const LAST_NAME_REGEXP = /^[a-zA-Z\s-']{0,20}$/
const ADDRESS1_REGEXP = /^[0-9a-zA-Z\s.,#;:'\-&/()]{0,40}$/
const ADDRESS2_REGEXP = /^[0-9a-zA-Z\s.,#;:'\-&/()]{0,40}$/
const STATE_REGEXP = /^[a-zA-Z\s]{0,25}$/
const CITY_REGEXP = /^[a-zA-Z\s]{0,25}$/
const PRIMARY_PHONE_NUMBER_REGEXP = /(^$|^[0-9]{10}$)/
const ALTERNATIVE_PHONE_NUMBER_REGEXP = /(^$|^[0-9]{10}$)/
const ZIP_CODE_REGEXP = /^(?:\d{5}|\d{5}-\d{4})?$/
const DIGITS_ONLY = /^\d+$/
// eslint-disable-next-line
const EMAIL_REGEXP = /^(?!.{81})([a-zA-Z0-9_\-+]+(\.[a-zA-Z0-9_\-+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,24}))$/

export const validateIfNotEmpty = func => (value, ...rest) =>
  value ? func(value, ...rest) : true

export const required = () =>
  value => Boolean(value)

export const firstName = () => validateIfNotEmpty(
  value => FIRST_NAME_REGEXP.test(value)
)

export const lastName = () => validateIfNotEmpty(
  value => LAST_NAME_REGEXP.test(value)
)

export const address1 = () => validateIfNotEmpty(
  value => ADDRESS1_REGEXP.test(value)
)

export const address2 = () => validateIfNotEmpty(
  value => ADDRESS2_REGEXP.test(value)
)

export const state = () => validateIfNotEmpty(
  value => STATE_REGEXP.test(value)
)

export const city = () => validateIfNotEmpty(
  value => CITY_REGEXP.test(value)
)

export const primaryPhone = () => validateIfNotEmpty(
  value => PRIMARY_PHONE_NUMBER_REGEXP.test(value)
)

export const alternatePhone = () => validateIfNotEmpty(
  value => ALTERNATIVE_PHONE_NUMBER_REGEXP.test(value)
)

export const zipCode = () => validateIfNotEmpty(
  value => ZIP_CODE_REGEXP.test(value)
)

export const email = () => validateIfNotEmpty(
  value => EMAIL_REGEXP.test(value)
)

export const length = (min = -Infinity, max = Infinity) => validateIfNotEmpty(
  value => value.length >= min && value.length <= max
)

export const validatePrice = (value, values, props) =>
  parseFloat(value.substr(1)) < props.lowerPrice


export const containUpperLowerCaseLetter = () => validateIfNotEmpty(value => /(?=.*[a-z])(?=.*[A-Z])/.test(value))

export const containSpecialCharacter = () => validateIfNotEmpty(value => /(?=.*[*!@#$&?])/.test(value))

export const containNumber = () => validateIfNotEmpty(value => /\d/.test(value))

export const licensePlate = () => validateIfNotEmpty(value => /^[a-zA-Z0-9]+$/.test(value))

export const vin = () => validateIfNotEmpty(value => /^[a-zA-Z0-9]{17}$/.test(value))

export const match = fieldName => (value, values) => value === values[fieldName]

export const digitsOnly = () => validateIfNotEmpty(value => DIGITS_ONLY.test(value))

export const notLessThan = fieldName => (value, values) => Number(value || 0) > Number(values[fieldName] || 0)

export const greaterThan = (min = 0) => value => value > min

export const accountEmailTest = () => validateIfNotEmpty((value, values, { accountEmail = '' }) =>
  accountEmail ? new RegExp(accountEmail).test(value) : true)

export const notWhiteSpaceString = () => validateIfNotEmpty(value => NO_WHITE_SPACES.test(value))

export const createFieldValidator = ({ fieldName, validators }) => values => {
  if (Array.isArray(validators)) {
    return validators.every(validator => validator(values[fieldName], values))
  } else if (isFunction(validators)) {
    return validators(values[fieldName], values)
  }

  return true
}

export const validateRule = (value, values, rule, formProps) => {
  const { errorMessage, validators } = rule

  if (Array.isArray(validators)) {
    for (let i = 0; i < validators.length; i += 1) {
      const valid = validators[i](value, values, formProps)

      if (!valid) {
        return errorMessage
      }
    }
  } else if (isFunction(validators)) {
    return !validators(value, values, formProps) ? errorMessage : undefined
  }
}

export const combineValidators = rules => (values, formProps) => {
  const errors = {}

  Object.keys(rules).forEach((field) => {
    const value = values[field]

    if (Array.isArray(rules[field])) {
      for (let i = 0; i < rules[field].length; i += 1) {
        errors[field] = validateRule(value, values, rules[field][i], formProps)

        if (errors[field]) {
          return
        }
      }
    }

    errors[field] = validateRule(value, values, rules[field], formProps)
  })

  return errors
}
