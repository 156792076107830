import { compose, withProps, lifecycle } from 'recompose'
import withFilteredHoverHandlers from 'components/common/hoc/withFilteredHoverHandlers'
import withHeaderHorizontalNav from 'components/common/hoc/withHeaderHorizontalNav'
import withCombinedActiveStatesAndHandlers from 'components/common/hoc/withCombinedActiveStatesAndHandlers'
import withType from 'components/common/hoc/withType'
import { SIGN_IN_ELEMENT_ID } from '../constants'
import { CART_ELEMENT_ID } from 'components/Header/HeaderCart/constants'
import { STORE_ELEMENT_ID } from 'components/Header/MyStore/constants'
import active from 'components/common/hoc/active'
import HeaderSignInRegister from './HeaderSignInRegister'

const withElementsProps = withProps({
  elementId: SIGN_IN_ELEMENT_ID,
  nextItem: CART_ELEMENT_ID,
  prevItem: STORE_ELEMENT_ID
})

const withLifeCycle = lifecycle({
  componentDidMount() {
    if (window) {
      const { activate } = this.props
      window.addEventListener('loginFormOpen', activate)
    }
  },
  componentWillUnmount() {
    if (window) {
      const { activate } = this.props
      window.removeEventListener('loginFormOpen', activate)
    }
  }
})

export default compose(
  withType({ type: 'HeaderSignInRegister' }),
  active,
  withFilteredHoverHandlers,
  withCombinedActiveStatesAndHandlers,
  withElementsProps,
  withHeaderHorizontalNav,
  withLifeCycle
)(HeaderSignInRegister)
