import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'


class Portal extends React.Component {
  UNSAFE_componentWillMount() {
    if (window && window.document && !this.el) {
      this.el = window.document.createElement('div')
      if (this.props.inline) {
        this.el.style.display = 'inline'
      }
    }
  }

  componentDidMount() {
    const root = window.document.getElementById(this.props.id)
    root && root.appendChild(this.el)
  }

  componentWillUnmount() {
    const root = window.document.getElementById(this.props.id)
    root && this.el.parentElement === root && root.removeChild(this.el)
  }

  render() {
    return this.el ?
      ReactDOM.createPortal(
        <React.Fragment>
          { this.props.children }
        </React.Fragment>,
        this.el
      ) : null
  }
}

Portal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  id: PropTypes.string,
  inline: PropTypes.bool
}


export default Portal
