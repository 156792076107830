import { handleActions } from 'redux-actions'
import { setAppointmentConfirmationContactDetails, clearAppointmentConfirmationContactDetails } from './actions'

const initialState = { contactDetails: null }

const reducer = handleActions({
  [setAppointmentConfirmationContactDetails]: (state, { payload }) =>
    ({ contactDetails: payload }),
  [clearAppointmentConfirmationContactDetails]: () =>
    ({ ...initialState })
}
, initialState)

export default reducer
