import styled, { css } from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import UpdatingBlock from 'components/common/UpdatingBlock/UpdatingBlock'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'

export const Title = styled.div`
  ${Mixin.font.GothamBold(24)};
  text-align: center;
  color: ${Theme.colors.dark_grey};
  margin-bottom: 40px;

  @media (max-width: 700px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Step = styled.div`
  text-transform: uppercase;
  position: relative;
  width: max-content;

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: -20px;
    height: 4px;
  }
`

export const ActiveStep = styled(Step)`
  ${Mixin.font.GothamBold(18)};
  color: ${Theme.colors.dark_grey};

  &::after {
    background-color: ${Theme.colors.bright_red_new};
  }
`

export const InactiveStep = styled(Step)`
  ${Mixin.font.GothamBook(18)};
  color: #aaa;

  &::after {
    background-color: ${Theme.colors.transparent};
  }

  ${props => props.isPicked && css`
    cursor: pointer;
    color: ${Theme.colors.blue};
    text-decoration: underline;
  `}
`

export const StepIcon = styled(Icon)`
  margin: 0 10px;
`

export const SearchInputWrapper = styled.div`
  position: relative;
`

export const SearchInput = styled.input`
  border: 1px solid ${Theme.colors.gainsboro};
  ${Mixin.font.GothamBook(13)};
  color: ${Theme.colors.sub_grey};
  position: relative;
  outline: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;

  :focus {
    border: 1px solid ${Theme.colors.transparent_light_blue};
    outline: 0;
  }

  ::placeholder {
    color: ${Theme.colors.medium_grey};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${Theme.colors.medium_grey};
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${Theme.colors.medium_grey};
  }

  ${Media.mobile`
    background-color: ${Theme.colors.white_smoke_medium};
    border: 1px solid ${Theme.colors.white_smoke_medium};

    ::placeholder {
      color: #999;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #999;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #999;
    }
  `}
`

export const CloseBtn = styled(Icon)`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  cursor: pointer;
`

export const ClearMobileButton = styled(Button)`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%) !important;
  cursor: pointer;
  padding: 8px !important;
  border-radius: 20px;
  background: ${Theme.colors.almost_grey} !important;
  text-decoration: underline;
  font-weight: 400 !important;
`

export const ListWrapper = styled.div`
  margin-top: 10px;
  column-count: 5;

  @media (max-width: 700px) {
    column-count: 3;
  }

  @media (max-width: 500px) {
    column-count: 2;
  }
`

export const Col = styled.div`
  padding: 6px;
  cursor: pointer;

  :hover {
    background-color: ${Theme.colors.bright_red_new};
    color: ${Theme.colors.white};
  }
`

export const CustomUpdatingBlock = styled(UpdatingBlock)`
  min-height: 200px;
  max-width: 980px;
`
