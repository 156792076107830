import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'

const RoundIcon = ({ className, ...props }) =>
  <Circle className={ className } { ...props }>
    <Icon alt="" fit { ...props } />
  </Circle>

RoundIcon.propTypes = {
  className: PropTypes.string
}

export default RoundIcon

const Circle = styled.div`
  width: 60px;
  height: 60px;
  padding: 17px;
  margin: 0 auto 15px;
  border-radius: 50%;
  border: 1px solid ${Theme.colors.grey};
  background: ${Theme.colors.white};
  position: relative;
  ${props => props.medium && css`
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 0;
    ${Media.mobile`
      width: 30px;
      height: 30px;
      padding: 0 8px;
    `}
  `}

  ${props => props.small && css`
    width: 30px;
    height: 30px;
    padding: 0 8px;
    margin: 0;
  `}

  ${props => props.inline && css`
    display: inline-block;
  `}

  ${Mixin.middle()}

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px;
  }
`
