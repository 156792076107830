import { show, hide } from 'redux-modal'
import { NEW_SHOP_TIRES_MODAL, YMME_NAME } from 'constant'

export const showNewShopTiresModal = ({
  ymmeName = YMME_NAME,
  ...props
}) => dispatch =>
  dispatch(show(NEW_SHOP_TIRES_MODAL, {
    ymmeName,
    ...props
  }))

export const hideNewShopTiresModal = () => dispatch =>
  dispatch(hide(NEW_SHOP_TIRES_MODAL))
