import { compose } from 'redux'
import { connect } from 'react-redux'
import { refresh } from 'actions/router'
import { withHandlers, withProps } from 'recompose'
import { ADD_VEHICLE_MODAL } from 'constant'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { triggerExtraTiresSelectCallbacks } from 'helpers/ymme'
import { YMME_NAME, LICENSE_PLATE_NAME } from './constants'
import AddVehicleModal from './AddVehicleModal'
import * as actions from './actions'

const withFormNames = withProps({
  ymmeName: YMME_NAME,
  form: LICENSE_PLATE_NAME
})

const withHandleGettingTireSizeSuccess = withHandlers({
  handleGettingTireSizeSuccess: ({
    handleClose,
    showConfirmTireSizeModal,
    confirmTireSizeModalProps,
    skipTireSizeExperience,
    refresh
  }) => ({ userVehicleId, tiresSelectExtraCallbacks }) => {
    handleClose()
    if (skipTireSizeExperience) {
      refresh()
      triggerExtraTiresSelectCallbacks({ tiresSelectExtraCallbacks, userVehicleId })
    } else {
      showConfirmTireSizeModal({
        ...confirmTireSizeModalProps,
        skipTireSizeExperience,
        userVehicleId,
        tiresSelectExtraCallbacks
      })
    }
  }
})

const withHandleClose = withHandlers({
  handleClose: ({ hideAddVehicleModal, resetYmme, resetLicencePlateForm }) => () => {
    resetYmme()
    resetLicencePlateForm()
    hideAddVehicleModal()
  }
})

export default compose(
  connect(null, { ...actions, refresh }),
  registerReduxModal({ name: ADD_VEHICLE_MODAL, destroyOnHide: true }),
  withFormNames,
  withHandleClose,
  withHandleGettingTireSizeSuccess
)(AddVehicleModal)

export { actions }
