import {
  set3StepperEnabled
} from 'components/common/hoc/with3rdPartyData/actions'
import { setSessionStorageItem } from './helpers'
import { THREE_STEPPER_ENABLED } from 'constant'

export default store => next => action => {
  try {
    action.type === String(set3StepperEnabled) &&
      setSessionStorageItem(THREE_STEPPER_ENABLED, action.payload.value)
  } catch (err) {
    console.log('Error occured during 3rdPartyData action dispatch', err)
  }

  return next(action)
}
