import { YEARS, MAKES, MODELS, SUB_MODELS, FIELDS, ERROR } from './constants'

export const fieldState = {
  value: '',
  values: [],
  filterValue: '',
  error: '',
  active: false,
  loading: false
}

export const fieldsInitialState = {
  [YEARS]: { ...fieldState },
  [MAKES]: { ...fieldState },
  [MODELS]: { ...fieldState },
  [SUB_MODELS]: { ...fieldState }
}

export const errorInitialState = ''

export const initialState = {
  [FIELDS]: fieldsInitialState,
  [ERROR]: errorInitialState,
  subModelTypes: null,
  vehicleId: null
}
