import { withProps, compose } from 'recompose'
import { connect } from 'react-redux'
import { getLocationPathname } from 'selectors/getPageLocation'
import { HOME } from 'constant'

const mapStateToProps = state => {
  const pathname = getLocationPathname(state)
  return { isHomepage: pathname === HOME || pathname === '/' }
}

const withStructuredData = withProps(() => ({
  organizationSchema: JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Big O Tires',
    url: 'https://www.bigotires.com',
    logo: 'https://www.bigotires.com/static/media/Logo.1b4cdbf8.svg',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '4280 Professional Center Dr #400',
      addressLocality: 'Palm Beach Gardens',
      addressRegion: 'FL',
      postalCode: '33410',
      addressCountry: 'USA'
    },
    sameAs: [
      'https://www.facebook.com/BigOTires',
      'https://www.twitter.com/bigotires',
      'https://instagram.com/bigotiresofficial/'
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+1-866-834-2652',
        contactType: 'customer support',
        contactOption: 'TollFree',
        areaServed: 'US',
        email: 'customerrelations@bigotires.com'
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1-866-834-2652',
        contactType: 'technical support',
        contactOption: 'TollFree',
        areaServed: 'US'
      }
    ]
  }),
  searchBoxSchema: JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'Big O Tires',
    url: 'https://www.bigotires.com',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://www.bigotires.com/global-search?Ntt={search_term_string}',
      'query-input': 'required name=search_term_string'
    }
  }),
  homepageBreadcrumbSchema: JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem,',
        position: 1,
        item: {
          '@id': 'https://www.bigotires.com',
          name: 'Home'
        }
      }
    ]
  })
}))

export default compose(
  connect(mapStateToProps),
  withStructuredData
)
