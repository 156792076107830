import { VEHICLE_DELETE_MODAL, VEHICLE_DETAILS_PAGE, GARAGE_PAGE, SERVICE_HISTORY_PAGE } from 'constant'
import { show, hide } from 'redux-modal'
import { rest } from 'services'
import { push } from 'connected-react-router'
import { refresh } from 'actions/router'
import { getPageLocation } from 'selectors'

export const deleteVehicle = (userVehicleId) => (dispatch, getState) => {
  const params = { data: { selectedVehicleId: userVehicleId }}
  const location = getPageLocation(getState())
  const isVehicleDetailsPage = location.pathname.startsWith(VEHICLE_DETAILS_PAGE)
  const isServiceHistoryPage = location.pathname.startsWith(SERVICE_HISTORY_PAGE)

  return rest.api
    .removeVehicle(params)
    .then(() => {
      dispatch(hide(VEHICLE_DELETE_MODAL))
      if (isVehicleDetailsPage || isServiceHistoryPage) {
        dispatch(push(GARAGE_PAGE))
        dispatch(refresh())
      }
      dispatch(refresh())
    })
}

export const showVehicleDeleteModal = ({ userVehicleId }) =>
  show(VEHICLE_DELETE_MODAL, { userVehicleId })
