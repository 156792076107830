import { withHandlers, compose } from 'recompose'
import { connect } from 'react-redux'
import { phoneLinkClick } from 'actions/thirdParty'

const withHandlePhoneLinkClick = withHandlers({
  handlePhoneLinkClick: ({ phoneLinkClick }) => () => phoneLinkClick()
})

export default compose(
  connect(null, { phoneLinkClick }),
  withHandlePhoneLinkClick
)
