import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import { H2 } from 'components/common/Text/Text'
import Icon from 'components/common/Icon/Icon'
import toggle from 'components/common/hoc/toggle'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'

const FooterMainMenuContent = ({
  name,
  placeholders: {
    leftFooterContents: left = [],
    centerFooterContents: center = [],
    rightFooterContents: right = []
  } = {},
  expanded,
  toggleHandlers
}) => {
  const isLeftPopulated = Boolean(left.length)
  const isCenterPopulated = Boolean(center.length)
  const isRightPopulated = Boolean(right.length)

  const cols = isLeftPopulated + isCenterPopulated + isRightPopulated

  return (
    <NavItem { ...dynamicDataAttribute('footer-main-menu-content', toOneString(name)) } key={ name }>
      <Title headline_footer { ...toggleHandlers } data-at-title>
        { name }
        <ArrowIcon asset="arrow-down-white" alternative="arrow-up-white" condition={ expanded } width={ 20 } data-at-toggle-arrow />
      </Title>
      <Row expanded={ expanded }>
        { isLeftPopulated && <Col cols={ cols } data-at-left-footer-col>{ left }</Col> }
        { isCenterPopulated && <Col cols={ cols } data-at-center-footer-col>{ center }</Col> }
        { isRightPopulated && <Col cols={ cols } data-at-right-footer-col>{ right }</Col> }
      </Row>
    </NavItem>
  )
}

FooterMainMenuContent.propTypes = {
  displayName: PropTypes.string,
  expanded: PropTypes.bool,
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    footerContents: PropTypes.arrayOf()
  }),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

const FooterMainMenuContentWithToggle = toggle(FooterMainMenuContent)

FooterMainMenuContentWithToggle.type = 'FooterMainMenuContent'
FooterMainMenuContentWithToggle.placeholders = ['leftFooterContents', 'centerFooterContents', 'rightFooterContents']

export default FooterMainMenuContentWithToggle

export const NavItem = styled.div`
  ${Media.desktop`
    display: table-cell;
    padding: 27px 24px 0 0;
    &&:last-child {
      padding: 0;
    }
  `}
  ${Media.mobile`
    border-bottom: 1px solid ${Theme.colors.dark_grey};
    :first-child {
      border-top: 1px solid ${Theme.colors.dark_grey};
    }
  `}
`

const Title = styled(H2)`
  margin-top: 0;
  transform: scale(1, 1.3);
  ${Media.mobile`
    position: relative;
    cursor: pointer;
    padding: 20px 0 16px;
    border-bottom: 0;
    margin-bottom: 0;
  `}
`

const ArrowIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 0;
  ${Mixin.mobileOnly()}
`

const Row = styled.div`
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  ${Media.mobile`
    display: none;
    ${props => props.expanded && css`
      display: block;
    `};
  `}
`

const Col = styled.div.attrs(({ cols }) => ({
  width: cols === 3 ? '33%' :
    cols === 2 ? '50%' :
      '100%'
}))`
  ${Mixin.responsive('display', 'table-cell', 'block')}
  ${Mixin.responsive('width', props => props.width, '100%')}
`
