import { STICKY_HEADER_ID, HEADER_ID } from 'constant'

export const scrollToHeader = ({ element, padding = 0 }) => {
  const { offsetHeight: headerElemHeight = 0 } = document.getElementById(HEADER_ID) || {}
  const { offsetHeight: stickyHeaderHeight = 0 } = document.getElementById(STICKY_HEADER_ID) || {}
  const elementTopPos = window.scrollY + (element ? element.getBoundingClientRect().top : 0)
  const headerOffset = headerElemHeight + stickyHeaderHeight + padding
  const offsetTop = elementTopPos - headerOffset
  window.scrollTo(0, offsetTop)
}
