import { compose } from 'recompose'
import { connect } from 'react-redux'
import { APP_START_LOADING, APP_STOP_LOADING } from 'components/Main/actions'

const mapDispatchToProps = (dispatch) => ({
  loaderAsyncWrapper: async ({ callback }) => {
    dispatch(APP_START_LOADING())
    try {
      await callback()
    } catch (e) {
      console.log(e)
    }
    dispatch(APP_STOP_LOADING())
  }
})

const getIsLoading = ({ app: { isLoading }}) => isLoading

const mapStateToProps = state => ({
  isLoading: getIsLoading(state)
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)
