export const MANUALLY_ADDED_TYPE = 'manuallyAdded'
export const CARFAX_TYPE = 'carfax'

export const SERVICE_RECORD_MODAL = 'serviceRecordModal'

export const CONNECT_TO_CARFAX_MODAL = 'connectToCarfax'
export const RECORD_DELETE_MODAL = 'recordDeleteModal'
export const FIND_SERVICE_HISTORY = 'FIND SERVICE HISTORY'

export const SERVICE_MODAL_FORM = 'serviceModalForm'

export const SERVICE_MODAL_CONTENT = {
  add: {
    title: 'Add Service Record',
    description: 'Please enter the next information to add the service record'
  },
  edit: {
    title: 'Edit Service Record',
    description: 'Please enter the next information to edit the service record'
  }
}

export const SERVICE_SUBTITLE_TEXT = 'The service was about:'

export const ADD_TYPE_SERVICE_RECORD_MODAL = 'add'
export const EDIT_TYPE_SERVICE_RECORD_MODAL = 'edit'

export const ADD_SERVICE_RECORD_NOTIFICATION_MESSAGE = 'Your Service Record was added'
export const EDIT_SERVICE_RECORD_NOTIFICATION_MESSAGE = 'Your Service Record was updated.'
export const DELETE_SERVICE_RECORD_NOTIFICATION_MESSAGE = 'Your Service Record was deleted.'

export const WAS_VEHICLE_DETAILS_QUERY_PARAMS = 'wasVehicleDetails'
