import React from 'react'
import PropTypes from 'prop-types'
import { Title, Hint, InnerWrapper } from '../SharedStyles'
import Back from 'components/common/Back'
import ShopByPlateForm from '../ShopByPlateForm'
import VehicleSwitcher from '../VehicleSwitcher'
import styled from 'styled-components'

const Plate = ({ className, toggle, handleSubmit, ...props }) =>
  <InnerWrapper className={ className } data-at-shop-by-plate-wrapper>
    <BackAnchor data-at-ymme-back-anchor handler={ toggle }>Back</BackAnchor>
    <Title data-at-title>Enter Your License Plate Number</Title>
    <ShopByPlateForm
      { ...props }
      onSubmit={ handleSubmit } />
    <Hint>
      Big O Tires is authorized to access vehicle information
      (limited to year, make, model, and engine)
    </Hint>
    <VehicleSwitcher handleSwitch={ toggle } />
  </InnerWrapper>

Plate.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func
}

const BackAnchor = styled(Back)`
  display: inline-block;
  margin-bottom: 20px;
`

export default Plate
