import { mapProps } from 'recompose'
import { STAGGERED } from '../../constants'
import TireSizeList from './TireSizeList'

// TODO potentially need to move this to TireConfirmation/index.js
const withSplittedTireSizes = mapProps(({ tireSizes, ...props }) => {
  const { [STAGGERED]: staggeredTireSizes = [], ...nonStaggeredTireSizes } = tireSizes

  return {
    ...props,
    staggeredTireSizes,
    nonStaggeredTireSizes
  }
})

export default withSplittedTireSizes(TireSizeList)
