import { TRAILER_NAME, TRAILER_TYPE, TRAILER_RV_ATV_TYPE, TRAILER_TITLE } from 'constant'

export const getIsTrailer = ({ vehicleType }) =>
  vehicleType === TRAILER_TYPE

export const getIsGenericTrailer = ({ vehicleType }) =>
  vehicleType === TRAILER_RV_ATV_TYPE

export const getIsAnyTrailer = ({ vehicleType }) =>
  getIsTrailer({ vehicleType }) || getIsGenericTrailer({ vehicleType })

export const getVehicleName = ({ yearDesc, makeDesc, modelDesc, subModelDesc, vehicleType }) =>
  getIsTrailer({ vehicleType }) ? TRAILER_NAME :
    getIsGenericTrailer({ vehicleType }) ? TRAILER_TITLE :
      `${yearDesc} ${makeDesc} ${modelDesc} ${subModelDesc}`

export const getVehicleShortName = ({ makeDesc, modelDesc, subModelDesc, vehicleType }) =>
  getIsTrailer({ vehicleType }) ? TRAILER_NAME :
    getIsGenericTrailer({ vehicleType }) ? TRAILER_TITLE :
      `${makeDesc} ${modelDesc} ${subModelDesc || ''}`

export const getVehicleTireSize = ({ tireFrontSize, tireRearSize }) =>
  tireRearSize ?
    `${tireFrontSize} - ${tireRearSize}` : tireFrontSize

export const getVehicleNicknameWithoutYear = (vehicleNickname) =>
  vehicleNickname.replace(/^\d{4} /, '')
