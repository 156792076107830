import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Theme from 'components/common/Theme/Theme'
import { ActiveStep, InactiveStep, StepWrapper, StepIcon } from 'components/NewTireSearch/SharedStyles'
import { isLastElement, isActiveElement, isTireSizePicked, setPickedTireSizeName } from '../../helpers'
import { REAR, FRONT } from 'components/VehicleConfigurator/components/withTireSize/constants'
import FrontTireSizePlaceholder from './FrontTireSizesPlaceholder'

const TireSizeStepper = ({
  stepNames,
  step,
  totalSteps,
  data,
  prevStepHandler,
  rearTireSizeChecked,
  isRearTireSizesActive,
  fullFrontTireSizeLabel,
  backToFrontTireSizeHandler,
  isFromTrailer
}) =>
  <Wrapper>
    {isRearTireSizesActive &&
      <FrontTireSizePlaceholderEl
        placeholderTitle={ fullFrontTireSizeLabel }
        onClick={ backToFrontTireSizeHandler } />
    }

    {stepNames.map(({ name, value }, index) =>
      <Step key={ name } index={ index } position={ step > 2 ? REAR : FRONT } isFromTrailer={ isFromTrailer } >
        { isActiveElement(isRearTireSizesActive ? step - 3 : step, index) && <ActiveStepElement tabIndex="0">{ value }</ActiveStepElement> }
        { (!isActiveElement(isRearTireSizesActive ? step - 3 : step, index)) && (
          <InactiveStepElement
            tabIndex={ isTireSizePicked({ data, value, position: step > 2 ? REAR : FRONT }) ? '0' : '-1' }
            isPicked={ isTireSizePicked({ data, value, position: step > 2 ? REAR : FRONT }) }
            onClick={ () => prevStepHandler({ stepName: value, position: step > 2 ? REAR : FRONT }) }>
            { setPickedTireSizeName({ data, name: value, step }) }
          </InactiveStepElement>
        )}
        { isLastElement(totalSteps, index) && <StepIcon asset="arrow-right-gray-secondary" width={ 9 } /> }
      </Step>
    )}

    {(rearTireSizeChecked && (step < 3)) && (
      <StepPlaceholder
        tabIndex={ '-1' }
        isPicked={ false } >
        <PlaceholderText>
          Rear Tire Size
        </PlaceholderText>
      </StepPlaceholder>
    )}
  </Wrapper>

export default TireSizeStepper

TireSizeStepper.propTypes = {
  backToFrontTireSizeHandler: PropTypes.func,
  data: PropTypes.shape(),
  fullFrontTireSizeLabel: PropTypes.string,
  isFromTrailer: PropTypes.bool,
  isRearTireSizesActive: PropTypes.bool,
  prevStepHandler: PropTypes.func,
  rearTireSizeChecked: PropTypes.bool,
  step: PropTypes.number,
  stepNames: PropTypes.arrayOf(PropTypes.shape()),
  totalSteps: PropTypes.number
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  margin-bottom: 10px;
  border-bottom: 3px solid ${Theme.colors.white_smoke};

  @media (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`

const FrontTireSizePlaceholderEl = styled(FrontTireSizePlaceholder)`
  margin-right: 30px;

  @media (max-width: 500px) {
    margin-right: 15px;
  }
`

const Step = styled(StepWrapper)`
  position: relative;
  ${props => (props.index === 0 || props.index === 3) && css`
    &::before {
      content: '${props.position} Tire Size';
      position: absolute;
      top: -20px;
      left: 0;
      font-size: 12px;
      width: 95px;
      text-transform: capitalize;
      color: ${Theme.colors.gainsboro}
    }
  `}
  ${props => props.isFromTrailer && css`
    &:before {
      content: none;
    }
  `}
`

const ActiveStepElement = styled(ActiveStep)`
  @media (max-width: 500px) {
    font-size: 16px;
  }
`

const InactiveStepElement = styled(InactiveStep)`
  @media (max-width: 500px) {
    font-size: 16px;
  }
`

const StepPlaceholder = styled(InactiveStep)`
  margin: 0 0 0 25px;

  &::before {
    content: 'Rear Tire Size';
    position: absolute;
    top: -20px;
    left: 0;
    font-size: 12px;
    width: 95px;
    text-transform: capitalize;
    color: ${Theme.colors.gainsboro};
  }
`

const PlaceholderText = styled.p`
  width: max-content;
  margin: 0;
  font-size: 18px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`
