export const garage = [
  'makeId',
  'makeDesc',
  'yearDesc',
  'modelId',
  'modelDesc',
  'baseVehicleId',
  'subModelId',
  'subModelDesc',
  'subModelImageName',
  'vehicleId',
  'vehicleName',
  'tireFrontSize',
  'engineConfigId',
  'scene7ImageUrl',
  'vehicleType',
  'engineDesc',
  'colorId',
  'mileage',
  'nickname',
  'speedRating',
  'loadIndex',
  'tireRearSize',
  'hasStaggeredFitment',
  'tpmsIndex',
  'rearLoadIndex',
  'rearSpeedRating',
  'rearTpmsIndex',
  'userVehicleId',
  'vin',
  'licencePlateNumber',
  'state'
]
