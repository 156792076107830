import { scrollToHeader } from 'helpers/scroll'

export const tabFocusHandler = (focusScopeRef, scroll = false) => {
  setTimeout(() => {
    if (focusScopeRef && focusScopeRef.querySelector) {
      const focusElem = focusScopeRef.querySelector('button:not([tabindex="-1"])')
      focusElem && focusElem.focus && focusElem.focus()
      scroll && scrollToHeader({ element: focusElem, padding: 30 })
    }
  })
}
