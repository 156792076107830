import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import Text from 'components/common/Text/Text'
import Media from 'components/common/Media'

const Vehicle = ({ image, title, description }) =>
  <Tile>
    <Media.Desktop>
      <Image src={ `${image}?$BOT-ConfirmVehicleModal-Vehicle-Desktop-w86$` } />
    </Media.Desktop>
    <Media.Mobile>
      <Image src={ `${image}?$BOT-ConfirmVehicleModal-Vehicle-Mobile-w58$` } />
    </Media.Mobile>
    <Title>{ title }</Title>
    <Text>{ description }</Text>
  </Tile>

Vehicle.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string
}

export default Vehicle

const Tile = styled.div`
  position: relative;
  cursor: pointer;
  ${Mixin.responsive('padding-left', '109px', '105px')}
`

const Image = styled.img`
  position: absolute;
  top: 50%;
  margin-left: -100px;
  transform: translateY(-50%);
  ${Mixin.responsive('width', '86px', '58px')}
  ${Mixin.responsive('height', '50px', '33px')}
  ${Mixin.responsive('margin-left', '-100px', '-95px')}
`

const Title = styled.div`
  overflow: hidden;
  font-weight: 600;
  vertical-align: middle;
`
