import { handleActions } from 'redux-actions'
import {
  setModalData,
  resetModal,
  clearSearchData,
  throwSearchError,
  clearSearchError,
  setSearchData,
  setSearchAutoSuggestions,
  clearSearchAutoSuggestions
} from './actions'
import { BRANDS, ARTICLES, PRODUCTS, SERVICES } from './constants'

const searchInitialState = {
  [BRANDS]: false,
  [ARTICLES]: false,
  [PRODUCTS]: false,
  [SERVICES]: false
}

const initialState = {
  data: {},
  search: searchInitialState,
  searchError: false,
  autoSuggestions: {}
}

const handleModalDataSet = (state, {
  payload: {
    title,
    didYouMeanFlag,
    placeHolderText,
    mobilePlaceHolderText,
    autoCorrectionFlag,
    symbolsBound,
    hintText
  }
}) => ({
  ...state,
  data: {
    title,
    didYouMeanFlag,
    mobilePlaceHolderText,
    placeHolderText,
    autoCorrectionFlag,
    symbolsBound: Number(symbolsBound),
    hintText
  }
})

const handleSearchDataClear = state => ({
  ...state,
  search: searchInitialState,
  searchError: initialState.searchError
})

const handleSearchErrorThrow = state => ({
  ...state,
  search: searchInitialState,
  searchError: true
})

const handleSearchErrorClear = state => ({
  ...state,
  searchError: initialState.searchError
})

const handleSearchDataSet = (state, { payload }) => ({
  ...state,
  search: {
    ...state.search,
    ...payload
  }
})

const handleSearchAutoSuggestionsSet = (state, { payload: { data }}) => ({
  ...state,
  autoSuggestions: data
})

const handleSearchAutoSuggestionsClear = state => ({
  ...state,
  autoSuggestions: {}
})

const globalSearchModal = handleActions({
  [setModalData]: handleModalDataSet,
  [clearSearchData]: handleSearchDataClear,
  [throwSearchError]: handleSearchErrorThrow,
  [clearSearchError]: handleSearchErrorClear,
  [setSearchData]: handleSearchDataSet,
  [setSearchAutoSuggestions]: handleSearchAutoSuggestionsSet,
  [clearSearchAutoSuggestions]: handleSearchAutoSuggestionsClear,
  [resetModal]: () => initialState
}, initialState)

export default globalSearchModal
