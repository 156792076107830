/* eslint no-plusplus: "off",
          prefer-rest-params: "off",
          prefer-spread: "off",
          no-return-assign: "off"
*/
import { createSelector as originalCreateSelector } from 'reselect'

// MEMORY LEAK: for server-side, we can opt-out the memoization 'cause every request we have a new store
function serverCreateSelector(...funcs) {
  let recomputations = 0
  const resultFunc = funcs.pop()
  const dependencies = Array.isArray(funcs[0]) ? funcs[0] : funcs

  const selector = function selector() {
    const params = []
    const length = dependencies.length

    for (let i = 0; i < length; i++) {
      params.push(dependencies[i].apply(null, arguments))
    }

    return resultFunc.apply(null, params)
  }

  selector.resultFunc = resultFunc
  selector.recomputations = () => recomputations
  selector.resetRecomputations = () => recomputations = 0
  return selector
}

export const createSelector = process.env.BOT_server_side === 'true'
  ? serverCreateSelector
  : originalCreateSelector
