import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'

const ReactSelectLabel = ({
  forId,
  children,
  ...props
}) =>
  <InputLabel
    htmlFor={ forId }
    { ...props }>{ children }</InputLabel>

export default ReactSelectLabel

ReactSelectLabel.propTypes = {
  aboveInput: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  forId: PropTypes.string
}

const InputLabel = styled.label`
  && {
    width: auto;
  }

  ${props => props.aboveInput && css`
    position: absolute;
    top: -11px;
    left: 11px;
    padding: 2px;
    font-size: 12px;
    color: ${Theme.colors.off_medium_grey};
    background-color: ${Theme.colors.white};
  `}
`
