import { LOCATION_FINDER_FORM } from 'constant'
import { createAction } from 'redux-actions'
import { rest, isApiError } from 'services'
import { storesFromApiResponse, apiSearchErrorToObject } from './helpers'
import { getSearchRadius } from './selectors'
import { UNKNOWN_ERROR } from './constants'

export const fetchDataAction = createAction('STORELOCATOR/FETCH_DATA')
export const fetchLocationsByCoordsAction = createAction('STORELOCATOR/FETCH_LOCATIONS_BY_COORDS')
export const fetchLocationsByAddrAction = createAction('STORELOCATOR/FETCH_LOCATIONS_BY_ADDR')
export const setInitialSearchMethodAction = createAction('STORELOCATOR/SET_INITIAL_SEARCH_METHOD')
export const resetLocationsAction = createAction('STORELOCATOR/RESET_LOCATIONS')
export const setShowAllResultsAction = createAction('STORELOCATOR/MOBILE_SHOW_ALL_RESULTS')
export const setSearchRadiusAction = createAction('STORELOCATOR/SET_SEARCH_RADIUS')
export const setSearchErrorAction = createAction('STORELOCATOR/SET_SEARCH_ERROR')
export const setSearchMask = createAction('STORELOCATOR/SET_SEARCH_MASK')
export const setSelectedSearchResultAction = createAction('STORELOCATOR/SET_SELECTED_SEARCH_RESULT')
export const resetSearchMask = createAction('STORELOCATOR/RESET_SEARCH_MASK')
export const resetErrorAction = createAction('STORELOCATOR/RESET_ERROR')
export const resetFormErrorAction = createAction('STORELOCATOR/RESET_FORM_ERROR')
export const resetSearchAction = createAction('STORELOCATOR/RESET_SEARCH')

const handleApiError = ({ error, form = LOCATION_FINDER_FORM }) => dispatch => {
  if (isApiError(error)) {
    const errorData = apiSearchErrorToObject(error)
    dispatch(setSearchErrorAction({ errorData, form }))
  } else {
    dispatch(setSearchErrorAction({ errorData: UNKNOWN_ERROR, form }))
  }
}

export const fetchLocationsByCoords = ({ latitude, longitude }) => async (dispatch, getState) => {
  await dispatch(fetchDataAction())

  try {
    const distanceInMiles = getSearchRadius(getState())
    const payLoad = { latitude, longitude, distanceInMiles }
    const data = await rest.api.storesByLatitudeAndLongitude(payLoad)
    const stores = storesFromApiResponse(data)
    const { storesAtDistance } = data || {}
    await dispatch(fetchLocationsByCoordsAction({ stores, searchCoords: { latitude, longitude }, storesAtDistance }))
  } catch (error) {
    dispatch(handleApiError({ error }))
  }
}

export const fetchLocationsByAddr = (searchMask, params = {}) => async (dispatch, getState) => {
  dispatch(fetchDataAction())

  try {
    const distanceInMiles = getSearchRadius(getState())
    const payLoad = { address: searchMask, distanceInMiles }
    const data = await rest.api.storesByAddress(payLoad, params)
    const stores = storesFromApiResponse(data)
    const { storesAtDistance } = data || {}

    await dispatch(fetchLocationsByAddrAction({ stores, searchMask, storesAtDistance }))
  } catch (error) {
    dispatch(setSearchMask(searchMask))
    dispatch(handleApiError({ error }))
  }
}

export const fetchLocationsByAddrWithDefaultDistance = (searchMask, params = {}, form) =>
  async (dispatch, getState) => {
    dispatch(fetchDataAction())

    try {
      const payLoad = { address: searchMask }
      const data = await rest.api.storesByAddress(payLoad, params)
      const stores = storesFromApiResponse(data)
      const { storesAtDistance } = data || {}

      await dispatch(fetchLocationsByAddrAction({ stores, searchMask, storesAtDistance }))
    } catch (error) {
      dispatch(setSearchMask(searchMask))
      dispatch(handleApiError({ error, form }))
    }
  }

export const resetSearchRadiusThenSearchByAddr = (radius, searchMask) => dispatch => {
  dispatch(setSearchRadiusAction(radius))
  dispatch(fetchLocationsByAddr(searchMask))
}

export const resetSearchRadiusThenSearchByCoords = (radius, coords) => dispatch => {
  dispatch(setSearchRadiusAction(radius))
  dispatch(fetchLocationsByCoords(coords))
}
