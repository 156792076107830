import styled, { css } from 'styled-components'

export const Row = styled.div`
  & + & {
    margin-top: 30px;
  }

  ${props => props.relative && css`
    position: relative;
  `}
  ${props => props.inline && css`
    display: inline-block;
  `}
`
