import { GBB_TITLES } from 'constant'

export const processGBBDetails = (source, gBBTitles = GBB_TITLES) =>
  Object.keys(source).reduce((acc, cur) => source[cur] && source[cur].skuId ? (
    ({ ...acc, [source[cur].skuId]: { title: gBBTitles[cur], skuId: source[cur].skuId, type: cur }})
  ) : acc, {})

const getGTMTireData = ({ tire, brand, id, dimension2, dimension28, dimension30, dimension31 } = {}) => {
  const {
    name,
    listPrice,
    salePrice,
    tireSize: variant,
    availability: dimension1
  } = (tire || {})

  return {
    name,
    id,
    price: Number(salePrice || listPrice),
    brand,
    category: 'Tires',
    variant,
    list: 'Product Detail',
    position: 1,
    dimension1,
    dimension2: dimension2 || 'none',
    dimension28,
    dimension30,
    dimension31
  }
}

export const getGTMData = ({ product, favoriteSkus, oeFlag, evFlag }) => {
  const {
    brandName: brand,
    skuId,
    rearSkuId,
    hasRearTire,
    frontTire = [],
    rearTire = [],
    dimension2
  } = (product || {})

  const dimension28 = favoriteSkus.includes(skuId)
  const dimension30 = false || oeFlag
  const dimension31 = evFlag
  const GTMData = [
    getGTMTireData({ tire: frontTire, brand, id: skuId, dimension2, dimension28, dimension30, dimension31 })
  ]

  return hasRearTire ?
    GTMData.concat(getGTMTireData({ tire: rearTire, brand, id: rearSkuId })) :
    GTMData
}
