import { withProps } from 'recompose'
import { ShopByVehicleModal } from 'components/VehicleConfigurator'
import { TireSizesNotFoundContinueBtnText } from '../constants'

const withTireSizesNotFoundModalProps = withProps(({ resetYmme }) => ({
  tireSizesNotFoundModalProps: {
    onContinueClick: resetYmme,
    continueButtonText: TireSizesNotFoundContinueBtnText
  }
}))

export default withTireSizesNotFoundModalProps(ShopByVehicleModal)
