import { createSelector } from 'helpers/reselect'
import { checkLoading, checkValid } from './helpers'
import { REAR, FRONT, WIDTH, RATIO, DIAMETER, FORM, MODAL, SUBMITTED } from './constants'

export const getAll = state =>
  state.tireSize

const getInstance = (state, { isModal = false } = {}) =>
  getAll(state)[isModal ? MODAL : FORM]

export const getSubmittedValues = createSelector(
  [getAll],
  shopByTires => shopByTires[SUBMITTED]
)

const getFrontSize = createSelector(
  [getInstance],
  shopByTiresInstance => shopByTiresInstance[FRONT].size
)

const getRearSize = createSelector(
  [getInstance],
  shopByTiresInstance => shopByTiresInstance[REAR].size
)

export const getAllSizes = createSelector(
  [
    getFrontSize,
    getRearSize
  ],
  (frontSize, rearSize) => ({
    front: frontSize,
    rear: rearSize
  })
)

export const getRearSizeVisibility = createSelector(
  [getInstance],
  shopByTiresInstance => shopByTiresInstance[REAR].visible
)

export const getFrontSizeValues = createSelector(
  [getFrontSize],
  frontTires => ({
    [WIDTH]: frontTires[WIDTH].value,
    [RATIO]: frontTires[RATIO].value,
    [DIAMETER]: frontTires[DIAMETER].value
  })
)

export const getFrontSizeCommittedValues = createSelector(
  [getFrontSize],
  frontTires => ({
    [WIDTH]: frontTires[WIDTH].committedValue,
    [RATIO]: frontTires[RATIO].committedValue,
    [DIAMETER]: frontTires[DIAMETER].committedValue
  })
)

export const getRearSizeValues = createSelector(
  [getRearSize],
  rearTires => ({
    [WIDTH]: rearTires[WIDTH].value,
    [RATIO]: rearTires[RATIO].value,
    [DIAMETER]: rearTires[DIAMETER].value
  })
)

export const getRearSizeCommittedValues = createSelector(
  [getRearSize],
  rearTires => ({
    [WIDTH]: rearTires[WIDTH].committedValue,
    [RATIO]: rearTires[RATIO].committedValue,
    [DIAMETER]: rearTires[DIAMETER].committedValue
  })
)

export const getAllValues = createSelector(
  [
    getFrontSizeValues,
    getFrontSizeCommittedValues,
    getRearSizeValues,
    getRearSizeCommittedValues
  ],
  (front, frontCommitted, rear, rearCommitted) => ({
    front,
    frontCommitted,
    rear,
    rearCommitted
  })
)

export const getMeta = createSelector(
  [
    getFrontSize,
    getRearSize,
    getRearSizeVisibility
  ],
  (front, rear, isRearVisible) => {
    const isFrontLoading = checkLoading(front)
    const isFrontValid = checkValid(front)
    const isRearLoading = checkLoading(rear)
    const isRearValid = checkValid(rear)

    return {
      isLoading: isRearVisible ? isFrontLoading || isRearLoading : isFrontLoading,
      isValid: isRearVisible ? isFrontValid && isRearValid : isFrontValid
    }
  }
)

export const getFrontWidthValues = createSelector(
  [getFrontSize],
  size => size[WIDTH].values
)

export const getTireSize = createSelector(
  [
    getAllSizes,
    getAllValues,
    getRearSizeVisibility,
    getFrontWidthValues
  ],
  (
    tireSize,
    tireSizeValues,
    isRearTireSizeVisible,
    tireSizeFrontWidthValues
  ) => ({
    tireSize,
    tireSizeValues,
    isRearTireSizeVisible,
    tireSizeFrontWidthValues
  })
)

