import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import AddEditButtons from '../AddEditButtons'

const ExtendedGarageVehicleSelectorBlock = ({
  placeholders: { garageVehicles = []} = {},
  vehiclesCountHint,
  actionLinks,
  handleAddNewVehicle
}) =>
  <React.Fragment>
    <CarouselTitle data-at-carbar-carousel-title>{ vehiclesCountHint }</CarouselTitle>
    <BottomBlock>
      { garageVehicles }
      <AddEditButtons actionLinks={ actionLinks } handleAdd={ handleAddNewVehicle } />
    </BottomBlock>
  </React.Fragment>

ExtendedGarageVehicleSelectorBlock.propTypes = {
  actionLinks: PropTypes.arrayOf(PropTypes.shape()),
  handleAddNewVehicle: PropTypes.func,
  placeholders: PropTypes.shape({
    garageVehicles: PropTypes.arrayOf()
  }),
  vehiclesCountHint: PropTypes.string
}

export default ExtendedGarageVehicleSelectorBlock

const BottomBlock = styled.div`
  display: table;
  width: 100%;
  background: ${Theme.colors.white};
  table-layout: fixed;
  ${Mixin.responsive('padding', '15px 0 20px', '5px 0 20px')}
`
const CarouselTitle = styled.div`
  ${Media.mobile`
    padding-bottom: 15px;
    ${Mixin.font.GothamBold(14)}
  `}
  ${Mixin.font.GothamBold(16)}
  ${Mixin.responsive('padding-top', '25px', '22px')};
  ${Mixin.responsive('margin-left', '26px', '0px')}
`
