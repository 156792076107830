const findSelectedOption = (options, selectedValue) =>
  options.find(({ value, selected }) => value === selectedValue || selected) || null

export const getSelectedOption = ({ options = [], value }) => {
  const grouped = options.every(({ label, options: groupOptions }) => label && groupOptions)
  const flatOptions = !grouped ? options :
    options.reduce((acc, { options }) => acc.concat(options), [])

  return findSelectedOption(flatOptions, value)
}
