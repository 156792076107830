import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import withType from 'components/common/hoc/withType'
import reduxForm from 'components/common/hoc/withFormErrorFocus'
import { FORM_NAME } from './constants'
import { EmailSignUpInitialForm } from './EmailSignUpInitialForm'
import EmailSignUpModal from '../EmailSignUpModal'
import validate from './validate'
import model from './model'
import * as actions from './actions'

const WrappedFooterSignUp = props =>
  <React.Fragment>
    <EmailSignUpInitialForm { ...props } model={ model } />
    <EmailSignUpModal />
  </React.Fragment>

WrappedFooterSignUp.propTypes = {
  isSubscribed: PropTypes.bool.isRequired,
  subscribe: PropTypes.func
}

const mapStateToProps = ({ subscription: { isSubscribed }}) => ({
  isSubscribed
})

const withSubmit = withHandlers({
  onSubmit: ({ showEmailSignUpModal }) => values =>
    showEmailSignUpModal(values)
})

export default compose(
  withType({ type: 'FooterSignUp' }),
  connect(mapStateToProps, actions),
  withSubmit,
  reduxForm({ form: FORM_NAME, validate })
)(WrappedFooterSignUp)
