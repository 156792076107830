export const NOT_SELECTED_ERROR = 'Please make a selection.'

export const STEP_INDEXES = {
  selectVehicle: 0,
  selectLocation: 1,
  selectServices: 2,
  selectDatetime: 3,
  selectContactInfo: 4,
  review: 5
}

export const APPOINMENT_SUBMISSION_ERROR_MODAL = 'appointmentSubmissionErrorModal'
export const APPOINTMENT_SUBMISSION_ERROR_MESSAGE = 'Our apologies, it seems we\'ve failed to receive your appoinment information. Please bear with us. We\'ll take you back to step one so we can try this again.'

export const STEPPER_HEADER_TITLE = 'Request an appointment in 5 easy steps'
export const THREE_STEPPER_HEADER_TITLE = 'Request an appointment in 3 easy steps'
