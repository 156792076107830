import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'
import { HEADER_ID } from 'constant'

const DesktopPageHeader = ({ placeholders: { headerContentBlock = []} = {}, ...props }) =>
  <Media.Desktop>
    <Placeholder />
    <HeaderDesktopContainer id={ HEADER_ID }>
      { headerContentBlock }
    </HeaderDesktopContainer>
  </Media.Desktop>

DesktopPageHeader.propTypes = {
  '@type': PropTypes.string,
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    headerContentBlock: PropTypes.arrayOf()
  })
}

DesktopPageHeader.type = 'DesktopPageHeader'
DesktopPageHeader.placeholders = ['headerContentBlock']

export default DesktopPageHeader

const HeaderDesktopContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000; /* @TODO */
  background: ${Theme.colors.veil};
`

const Placeholder = styled.div`
  height: 110px;
`
