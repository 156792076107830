import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import Cookies from 'cookies-js'
import { getFullStory } from 'selectors/thirdPartyData'
import { getCurrentStoreNumber } from 'selectors/getCurrentStore'
import { GA_USER_ID } from 'constant'

const mapStateToProps = state => ({
  fullStory: getFullStory(state),
  storeSelection: getCurrentStoreNumber(state)
})

const withFullStoryHandlers = withHandlers({
  handleSetGAUserID: () => () => {
    const { FullStory } = window
    const GAUserID = Cookies.get(GA_USER_ID)

    GAUserID && FullStory && FullStory.setUserVars && FullStory.setUserVars({ GAUserID })
  },
  handleSetStoreSelection: ({ storeSelection }) => () => {
    const { FullStory } = window
    storeSelection && FullStory && FullStory.setUserVars && FullStory.setUserVars({ storeSelection })
  },
  // eslint-disable-next-line camelcase
  handleError: () => ({ name: name_str, message: message_str, stack: stack_str }) => {
    const { FullStory } = window
    FullStory && FullStory.event && FullStory.event('Application Error', { name_str, message_str, stack_str })
  },
  handleUnhandledRejection: () => ({ reason }) => {
    const { FullStory } = window
    FullStory && FullStory.event && FullStory.event('Application Error', { name_str: reason, message_str: reason })
  }
})

const withInitializeFullStory = withHandlers({
  initializeFullStory: ({ handleSetGAUserID, handleSetStoreSelection, handleError, handleUnhandledRejection }) =>
    () => setTimeout(() => {
      handleSetGAUserID()
      handleSetStoreSelection()
      window.addEventListener('error', handleError)
      window.addEventListener('unhandledrejection', handleUnhandledRejection)
    })
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { fullStory, initializeFullStory } = this.props
    fullStory && initializeFullStory()
  },
  componentDidUpdate({ fullStory: prevFullStory, storeSelection: prevStoreSelection }) {
    const { fullStory, storeSelection, initializeFullStory, handleSetStoreSelection } = this.props

    prevFullStory !== fullStory && fullStory && initializeFullStory()

    if (fullStory && prevStoreSelection !== storeSelection) {
      handleSetStoreSelection()
    }
  },
  componentWillUnmount() {
    const { handleError, handleUnhandledRejection } = this.props
    window.removeEventListener('error', handleError)
    window.removeEventListener('unhandledrejection', handleUnhandledRejection)
  }
})

export default compose(
  connect(mapStateToProps),
  withFullStoryHandlers,
  withInitializeFullStory,
  withLifecycle
)
