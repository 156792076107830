import React from 'react'
import PropTypes from 'prop-types'
import CommonTextInput from './CommonTextInput'
import RadioInput from './RadioInput'
import DropdownInput from './DropdownInput'
import AutocompleteInput from './AutocompleteInput'
import TextAreaInput from './TextAreaInput'
import CheckBoxInput from '../Checkbox/Checkbox'
import ReactAutosuggestInput from './ReactAutosuggestInput'
import ReactSelectDropdownInput from './ReactSelectDropdownInput'
import CommonDatePicker from './CommonDatePicker'

import {
  TEXT_INPUT,
  RADIO_INPUT,
  DROPDOWN_INPUT,
  AUTOCOMPLETE_INPUT,
  TEXTAREA_INPUT,
  CHECKBOX_INPUT,
  SELECT_DROPDOWN_INPUT,
  REACT_AUTOSUGGEST_INPUT,
  COMMON_DATE_PICKER
} from './inputTypes'
import { getMergedEventHandlers, isErrorState } from './helpers'

const COMPONENTS = {
  [TEXT_INPUT]: CommonTextInput,
  [RADIO_INPUT]: RadioInput,
  [DROPDOWN_INPUT]: DropdownInput,
  [AUTOCOMPLETE_INPUT]: AutocompleteInput,
  [TEXTAREA_INPUT]: TextAreaInput,
  [CHECKBOX_INPUT]: CheckBoxInput,
  [SELECT_DROPDOWN_INPUT]: ReactSelectDropdownInput,
  [REACT_AUTOSUGGEST_INPUT]: ReactAutosuggestInput,
  [COMMON_DATE_PICKER]: CommonDatePicker
}

/**
 * We are supposed that input will be used in redux-form <Field .../> component.
 * Of course this is not required, however most of the useful properties should be managed manually by you.
 * You can read about it here:
 * {@link https://redux-form.com/7.0.4/docs/api/field.md/#props}
 */
const Input = ({
  inputType = TEXT_INPUT,
  meta,
  meta: {
    form,
    error,
    active
  } = {},
  handlers = {},
  input = {},
  ...props
}) => {
  const Component = COMPONENTS[inputType]

  return <Component
    { ...input }
    { ...props }
    { ...getMergedEventHandlers(handlers, input) }
    form={ form }
    error={ error }
    active={ active }
    isError={ isErrorState(meta) } />
}

Input.propTypes = {
  handlers: PropTypes.shape(),
  input: PropTypes.shape(),
  inputType: PropTypes.string,
  meta: PropTypes.shape()
}

Input.defaultProps = {
  full: false
}

export default Input
