import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'
import Media from 'components/common/Media'
import Banner from 'components/common/Banner/Banner'
import YMMEPlate from './components/YmmePlate'

const MODAL_DEFAULT_TITLE = 'Add vehicle'

const AddVehicleModal = ({ handleClose, title, description, ...props }) =>
  <CustomModal data-ymme-modal closeHandler={ handleClose } title={ title || MODAL_DEFAULT_TITLE }>
    {
      description &&
        <Banner warning>{ description }</Banner>
    }
    <YMMEPlate { ...props } shopByTiresIndex={ 1 } />
  </CustomModal>

AddVehicleModal.propTypes = {
  description: PropTypes.string,
  handleClose: PropTypes.func,
  title: PropTypes.string
}

export default AddVehicleModal

const CustomModal = styled(Modal)`
  padding: 25px;
  ${Media.desktop`
    && {
      width: 430px;
    }
  `}

  & .modal-body {
    padding: 20px 60px;
  }
`
