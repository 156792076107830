import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SubmissionError, reset, clearFields } from 'redux-form'
import withRedirect from 'components/common/hoc/withRedirect'
import reduxForm from 'components/common/hoc/withFormErrorFocus'
import { isMobile } from 'components/common/Media/helpers'
import { isApiError } from 'services/rest/helpers'
import { rest } from 'services'
import { setShoppingData } from 'actions/shoppingData'
import { showVehicleMergeModal, mergeApiDecorator } from 'actions/mergeVehicle'
import { loadFavoriteSkus } from 'components/MyFavorites/actions'
import prepareForm from 'helpers/prepare-form'
import LoginWrapper from './LoginWrapper'
import validate from './validate'
import { QUOTE_PAGE, APPOINTMENT_CONFIRMATION_PAGE } from 'constant'
import { FORM_NAME, PASSWORD_PROPERTY } from './constants'
import form from './form'
import axios from 'axios'
import { googleAnalyticEmailAddressFill } from 'actions/googleAnalytic'

/* eslint-disable */

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this); // it gets called in handler passed from props and context gets lost sometimes
    this.captchaRef = React.createRef();
  }

  state = {
    account: {
      isLocked: false,
      description: '',
      apiErrorMsgRef: null,
      apiErrorFocus: false
    }
  }


  handleForgotPasswordClose = () => {
    this.setState({
      account: {
        isLocked: false,
        description: ''
      },
      token: null
    })
  }

  redirectToPreviousPage = () => {
    const to = isMobile() ?
      this.props.navigations.previous.startsWith(APPOINTMENT_CONFIRMATION_PAGE) ? QUOTE_PAGE :
        this.props.navigations.previous :
      this.props.navigations.previous === QUOTE_PAGE ? QUOTE_PAGE :
        this.props.navigations.current

    this.props.redirectTo(to, {
      update: true
    })
  }

  handleApiError = ({ errors: [ description ], statusList, errorPayload }) => {
    this.setState({ apiErrorFocus: true })
    const isAccountLocked = errorPayload?.failureLoginAttempts >= 3
    // set up account as locked
    if (isAccountLocked) {
      this.setState({
        account: {
          isLocked: true,
          description
        }
      })
    }
    this.props.clearFields(FORM_NAME, false, false, [PASSWORD_PROPERTY])
    // setup form error
    throw new SubmissionError({
      _error: description
    })
  }

  handleLoginSuccess = ({ _state, cartCount }) => {
    const payload = !cartCount ? { _state } : { _state, totalCount: cartCount }

    this.props.setShoppingData(payload)
    this.props.loadFavoriteSkus()

    this.props.handleRedirectOnLoginSuccess ?
      this.props.handleRedirectOnLoginSuccess() :
      this.redirectToPreviousPage()
  }

  handleLoginFailure = err => {
    // throw further non ApiError
    if (!isApiError(err)) {
      throw err
    }

    this.handleApiError(err)
  }

  setToken = async () => {
    if (this.state.token) {
      this.captchaRef.current.reset()  // resolves executeAsync issue https://github.com/dozoisch/react-google-recaptcha/issues/191
    }
    const token = await this.captchaRef.current.executeAsync()
    this.setState({ token })
    return token
  }

  verifyToken = (token) => {
    return token ? axios.post('/verifyCaptchaToken', { token }) : Promise.resolve({ status: false })
  }

  async submit (form) {
    const token = this.state.account.isLocked ? await this.setToken() : null
    const submitForm = () => this.props.mergeApiDecorator({
      api: rest.api.login,
      payload: prepareForm(form),
      handleSuccess: this.handleLoginSuccess,
      data: {
        userSignIn: true,
        emailCaptured: form.login,
        isFormSubmission: true,
        emailSubmissionLocation: window.location.href
      },
      googleAnalyticEmailAddressFill: this.props.googleAnalyticEmailAddressFill
    })
    .catch(err => this.handleLoginFailure(err))

    if (this.state.account.isLocked) {
      return this.verifyToken(token)
        .then(res => res.status === 200 ? submitForm() : this.handleLoginFailure(err))
        .catch(err => {
          if (err.response?.data.type === 'captcha') {
            this.props.clearFields(FORM_NAME, false, false, [PASSWORD_PROPERTY])
            throw new SubmissionError({
              _error: err.response?.data.message
            })
          }
        })
    } else {
      return submitForm()
    }
  }

  setInnerRef = el => { this.state.apiErrorMsgRef = el }

  componentDidUpdate() {
    const { error } = this.props
    const { apiErrorMsgRef, apiErrorFocus } = this.state
    if (error && apiErrorFocus) {
      window.scrollTo(0, 0)
      apiErrorMsgRef && apiErrorMsgRef.focus && apiErrorMsgRef.focus()
      this.setState({ apiErrorFocus: false })
    }
  }

  render() {
    const {
      placeholders: {
        loginHeader = [],
        loginContent = [],
        loginFooter = []
      },
      error,
      submitting,
      handleSubmit
    } = this.props

    const { account } = this.state
    return (
      <div>
        <LoginWrapper
          form={ form }
          error={ error }
          account={ account }
          captchaRef={ this.captchaRef }
          isLoading={ submitting }
          loginHeader={ loginHeader }
          loginContent={ loginContent }
          loginFooter={ loginFooter }
          setInnerRef={ this.setInnerRef }
          handleForgotPasswordClose={ this.handleForgotPasswordClose }
          handleSubmit={ handleSubmit }
          handleCustomSubmit={ this.submit } />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  navigations: state.navigations
})

const EnhancedLogin = compose(
  withRedirect,
  reduxForm({
    form: FORM_NAME,
    validate
  }),
  connect(mapStateToProps, {
    reset,
    clearFields,
    setShoppingData,
    showVehicleMergeModal,
    mergeApiDecorator,
    loadFavoriteSkus,
    googleAnalyticEmailAddressFill
  })
)(Login)

EnhancedLogin.propTypes = {
  placeholders: PropTypes.shape({
    loginHeader: PropTypes.array,
    loginContent: PropTypes.array
  })
}

export const type = 'Login'

EnhancedLogin.type = type
EnhancedLogin.placeholders = ['loginHeader', 'loginContent', 'loginFooter']

export default EnhancedLogin
