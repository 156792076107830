import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { deviceChecker } from './helpers'

const mapStateToProps = ({ browser: { device, screenFits, screenSize }}) => ({
  device,
  screenFits,
  screenSize
})

export default compose(
  connect(mapStateToProps),
  withProps(({
    screenFits
  }) => ({
    isScreenFits: deviceChecker(screenFits)
  }))
)
