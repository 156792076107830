import { handleActions } from 'redux-actions'
import { setGBBDetails, setGBBAvailability, clearGBBDetails, setGBBTireSize, setGBBStoreNumber, setAllGBBDetails } from './actions'

const initialState = {
  currentGBBDetails: {},
  gBBAvailable: true,
  gBBTireSize: '',
  gBBStoreNumber: ''
}

const gBBDetails = handleActions({
  [setAllGBBDetails]: (state, { payload: { gBB, tireSize, storeNumber, availability }}) =>
    ({ ...state,
      currentGBBDetails: { ...gBB },
      gBBAvailable: availability,
      gBBTireSize: tireSize,
      gBBStoreNumber: storeNumber

    }),
  [setGBBDetails]: (state, { payload: { gBB }}) =>
    ({ ...state,
      currentGBBDetails: { ...gBB }
    }),
  [setGBBAvailability]: (state, { payload: { availability: gBBAvailable }}) =>
    ({ ...state,
      gBBAvailable
    }),
  [setGBBTireSize]: (state, { payload: { tireSize: gBBTireSize }}) =>
    ({ ...state,
      gBBTireSize
    }),
  [setGBBStoreNumber]: (state, { payload: { storeNumber: gBBStoreNumber }}) =>
    ({ ...state,
      gBBStoreNumber
    }),
  [clearGBBDetails]: state =>
    ({ ...state,
      ...initialState
    })
}, initialState)

export default gBBDetails
