import { HAS_OBSERVER } from '../hoc/withLazyLoader/constants'
import { isSSR } from 'helpers/isSSR'

export const pickImgSrc = ({ condition, asset, alternative }, map) =>
  condition ? map[alternative] : map[asset]

export const pickSrc = ({ condition, lazyPlaceholder, isLoaded, asset, alternative, ...props }, map) => {
  if (isSSR) {
    return lazyPlaceholder
  }

  const hasHidden = Object.prototype.hasOwnProperty.call(props, 'hidden')

  if (hasHidden && !HAS_OBSERVER) {
    return pickImgSrc({ condition, asset, alternative }, map)
  }

  return !isLoaded()
    ? lazyPlaceholder
    : pickImgSrc({ condition, asset, alternative }, map)
}
