/* eslint-disable no-param-reassign */
/**
 * This interceptor allows us to use parametrized urls like '/garage/make/:year/model/:model'
 * in this case you have to pass method config object with urlKeys object.
 * Example:
 *
 * we have an endpoint getCar: { url: '/garage/make/:make/model/:model', method: 'get' }. So when we call
 *
 * rest.api.getCar({
 *   urlKeys: { make: 'mercedes', model: 'cls' }
 * })
 **
 * we make a request to '/garage/make/mercedes/model/cls
 *
 * !NOTE: it's not allowed to start your url key with number as PORT in url will be misreplaced.
 *
 * @param config
 * @returns {*}
 */
export default config => {
  config.url = config.url.replace(
    /:([a-zA-Z][a-zA-Z0-9]+)(\/|\\|$)/g,
    (match, param, delimiter) => `${config.urlKeys[param]}${delimiter}`
  )

  return config
}
