import { connect } from 'react-redux'
import { compose, withProps, lifecycle, withHandlers } from 'recompose'
import StoreLocatorModal from './StoreLocatorPageModal'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import ModalBox from 'components/ModalBox/ModalBox'
import registerModalPage from 'components/common/hoc/registerModalPage'
import { storeLocatorModalInit, storeLocatorModalComplete, storeLocatorModalReset } from 'actions/storeLocator'
import { STORE_LOCATOR_MODAL_PAGE, STORE_LOCATOR_MODAL_PAGE_SRC } from 'constant'

const withModalWindowSettings = withProps({
  modalWindowSettings: {
    preventExpandingQuickAppointmentMenu: true,
    withBackButton: false,
    withPageHeader: false
  }
})

const withInitialization = lifecycle({
  componentDidMount() {
    this.props.storeLocatorModalInit()
  },

  componentWillUnmount() {
    this.props.storeLocatorModalReset()
  }
})

const withCloseHandler = withHandlers({
  handleClose: ({ handleHide, storeLocatorModalComplete }) => () => {
    storeLocatorModalComplete()
    handleHide()
  }
})

export default compose(
  registerReduxModal({ name: STORE_LOCATOR_MODAL_PAGE }),
  registerModalPage({
    pageSrc: STORE_LOCATOR_MODAL_PAGE_SRC,
    rootType: ModalBox.type
  }),
  connect(null, { storeLocatorModalInit, storeLocatorModalComplete, storeLocatorModalReset }),
  withInitialization,
  withCloseHandler,
  withModalWindowSettings
)(StoreLocatorModal)
