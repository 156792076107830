import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'

const FooterTopContentBlock = ({ placeholders: { footerContents = []} = {}, ...props }) =>
  <Wrapper>
    <div data-max-width>
      { footerContents }
    </div>
  </Wrapper>

FooterTopContentBlock.propTypes = {
  '@type': PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    footerContents: PropTypes.arrayOf()
  })
}

export default FooterTopContentBlock

const Wrapper = styled.div`
  min-height: 70px;
  background: ${Theme.colors.dark_red};
  ${Media.desktop`
    max-height: 70px;
  `}
  ${Mixin.responsive('padding', '0 50px', '0 16px')}
  ${Mixin.clearFix()}
`
