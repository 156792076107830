import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { CUSTOM_PROMOTION_MODAL } from '../constants'
import CustomPromotionModal from './CustomPromotionModal'
import CountdownTimer from '../CountdownTimer'
import { buildComponents } from '../helpers'

const withComponents = withState('components', 'setComponents', {})

const mapStateToProps = state => {
  const { modal } = state

  if (!modal[CUSTOM_PROMOTION_MODAL]) {
    return {
      modalContent: '',
      timerConfig: null,
      noPromotionsMessage: '',
      promotionResultList: [],
      regionName: ''
    }
  }

  return modal[CUSTOM_PROMOTION_MODAL].props
}

const withLifecycle = lifecycle({
  componentDidMount() {
    const { modalContent, timerConfig, setComponents } = this.props

    const {
      endDate,
      modal: {
        timerLabels = {},
        hideSeconds
      } = {}
    } = JSON.parse(timerConfig)

    const countdownTimer = () =>
      <CountdownTimer endDate={ endDate } timerLabels={ timerLabels } hideSeconds={ hideSeconds } />

    const componentParts = buildComponents(modalContent)

    setComponents({
      BeforeComponent: componentParts[0],
      TimerComponent: componentParts.length > 1 ? countdownTimer() : null,
      AfterComponent: componentParts.length > 1 ? componentParts[1] : null
    })
  },
  componentDidUpdate() {
    const seeOffersLink = document.querySelector('.promotion-modal__cta-link-btn')

    if (seeOffersLink) {
      seeOffersLink.addEventListener('click', () => {
        window.sessionStorage.setItem('hidePromoModal', true)
      }, false)
    }
  }
})

const withCloseModalHandler = withHandlers({
  handleCloseModal: ({ handleHide }) => () => {
    handleHide()
    window.sessionStorage.setItem('hidePromoModal', true)
  }
})

export default compose(
  registerReduxModal({ name: CUSTOM_PROMOTION_MODAL }),
  connect(mapStateToProps, {}),
  withComponents,
  withLifecycle,
  withCloseModalHandler
)(CustomPromotionModal)

