import { compose } from 'redux'
import { withProps } from 'recompose'
import {
  hasMultipleTireSizes,
  hasStaggeredFitment,
  getStaggeredInformation,
  getMultipleSizesInformation
} from '../../helpers'
import OriginalTireSize from './OriginalTireSize'

const withIsStaggered = withProps(({ tireSizes }) => ({
  isStaggered: hasStaggeredFitment(tireSizes)
}))

const withInstructions = withProps(({ tireSizes, vehicleMake, isStaggered }) => {
  const tireHasMultipleSizes = hasMultipleTireSizes(tireSizes)

  return {
    instructions: isStaggered ? getStaggeredInformation() :
      tireHasMultipleSizes ? getMultipleSizesInformation(vehicleMake) : ''
  }
})

export default compose(
  withIsStaggered,
  withInstructions
)(OriginalTireSize)
