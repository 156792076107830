import React from 'react'
import { filterProps } from 'helpers/utils'

const propsToRemove = ['slideCount', 'currentSlide']

export const PrevArrow = (props) =>
  <button data-at-carousel-prev-arrow { ...filterProps(props, propsToRemove) } />

export const NextArrow = (props) =>
  <button data-at-carousel-next-arrow { ...filterProps(props, propsToRemove) } />
