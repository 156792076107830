import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'components/NewTireSearch/SharedStyles'

const TitleWrapper = ({
  title
}) =>
  <Title>{ title }</Title>


export default TitleWrapper

TitleWrapper.propTypes = {
  title: PropTypes.string
}
