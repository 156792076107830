import { compose, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { getIsModal } from 'selectors/modal'
import toggle from 'components/common/hoc/toggle'
import Drawer from './Drawer'

const withAfterClose = lifecycle({
  componentDidUpdate(prevState) {
    const { expanded, afterClose } = this.props
    if (prevState.expanded !== expanded && !expanded && afterClose) {
      afterClose()
    }
  }
})

const withEnhancedOnClick = withHandlers({
  enhancedOnClick: ({
    toggleHandlers: { onClick },
    drawerProps: { drawerButtonHandler = undefined } = {},
    preventClose,
    expanded
  }) => e => {
    e.preventDefault()
    if (!preventClose) {
      onClick()
      drawerButtonHandler && drawerButtonHandler(expanded)
    }
  }
})

export default compose(
  connect(state => ({ isModal: getIsModal(state) })),
  toggle,
  withEnhancedOnClick,
  withAfterClose
)(Drawer)
