import {
  OPEN_APPOINTMENT_MENU,
  OPEN_BV_REVIEWS_TAB_AND_SCROLL_DOWN,
  PAGE_HEADER_CHANGED,
  STORE_LOCATOR_MODAL_PAGE,
  OPEN_NATIONWIDE_OFFERS_TAB,
  ACTIVATE_MARCHEX_SCRIPT,
  TOGGLE_STORE_DETAILS_BOTTOM_BANNER
} from 'constant'

const createCustomEvent = (name, customEventInit) => {
  const event = new CustomEvent(name, customEventInit)
  window.dispatchEvent(event)
}

export const expandQuickAppointmentButton = () => createCustomEvent(OPEN_APPOINTMENT_MENU)

export const expandBazaarVoiceReviewTabAndScrollDown = () =>
  createCustomEvent(OPEN_BV_REVIEWS_TAB_AND_SCROLL_DOWN)

export const dispatchPageHeaderChanged = () =>
  createCustomEvent(PAGE_HEADER_CHANGED)

export const showStoreLocatorModal = () => createCustomEvent(STORE_LOCATOR_MODAL_PAGE)

export const activateNationwideOffersTab = () => createCustomEvent(OPEN_NATIONWIDE_OFFERS_TAB)

export const activateMarchexScript = () => createCustomEvent(ACTIVATE_MARCHEX_SCRIPT)

export const toggleStoreDetailsBottomBanner = eventDetails =>
  createCustomEvent(TOGGLE_STORE_DETAILS_BOTTOM_BANNER, eventDetails)
