import React from 'react'

export const HOME = '/home'
export const HOME_UPDATED = '/'
export const CONFIRM_TIRE_SIZES = '/confirm-tire-size'
export const TIRES_LANDING_PAGE = '/tires'
export const BRAND_DETAILS_PAGE = '/tires/brand/'
export const BRAND_LANDING_PAGE = '/tires/brands'
export const SKU_DETAILS_PAGE = '/tires/sku'
export const ACCOUNT_REGISTRATION_FORM = '/my-account/register/registration-form'
export const ACCOUNT_DETAILS_PAGE = '/my-account/account-details'
export const SITEMAP = '/sitemap'
export const STORE_LOCATOR_PAGE = '/store-locator'
export const GLOBAL_SEARCH_PAGE = '/global-search'
export const GLOBAL_SEARCH_TIRES_PAGE = `${GLOBAL_SEARCH_PAGE}/tires`
export const SERVICES_PAGE = '/services'
export const COMPARE_PAGE = '/tires/compare-tires'
export const STORE_DETAILS_PAGE = '/location'
export const GARAGE_PAGE = '/my-account/vehicles'
export const MY_APPOINTMENTS_PAGE = '/my-account/my-appointments'
export const QUICK_APPOINTMENT_PAGE = '/appointment/quick-appointment'
export const QUOTE_PAGE = '/quote'
export const QUOTE_SUMMARY_PAGE = '/quote/summary'
export const QUOTE_APPOINTMENT_INFO_PAGE = '/quote/appointment-information'
export const REGIONS_PAGE = '/regions/'
export const APPOINTMENT_CONFIRMATION_PAGE = '/appointment/appointment-confirmation'
export const ORDER_CONFIRMATION_PAGE = '/appointment/orderConfirmation'
export const VEHICLE_NOT_FOUND = '/vehicle-not-found'
export const PROMOTIONS_PAGE = '/deals'
export const MY_FAVORITES_PAGE = '/favorites'
export const VEHICLE_DETAILS_PAGE = '/my-account/vehicle-details'
export const SERVICE_HISTORY_PAGE = '/my-account/service-history'
export const LOCATION_PAGE = '/location'
export const LOADING = 'Loading...'

// http headers / constants
export const BEARER = 'Bearer'
export const AUTHORIZATION = 'authorization'
export const XOCSTATEDATA = 'x-ocstatedata'
export const TOKEN_EXPIRED = 'tokenexpired'
export const IS_AUTHORIZED = 'isAuthorized'
export const CLEAR_CART = 'clearcart'


// input length limits
export const INPUT_MIN_LENGTH = 8
export const INPUT_MAX_LENGTH = 15

export const INPUT_ERROR_PLACEHOLDER = 'None'

export const PHONE_MASK = '(999) 999 - 9999'

// storage keys
export const STORAGE_CONFIRM_TIRE_SIZE = 'confirmTireSize'
export const THREE_STEPPER_ENABLED = 'threeStepperEnabled'

// Modals
export const VEHICLE_EDITOR_MODAL = 'vehicleEditorModal'
export const CONFIRM_TIRE_SIZE_MODAL = 'confirmTireSize'
export const ADD_TO_QUOTE_MODAL = 'addToQuoteModal'
export const PROMOTION_LIGHT_BOX_MODAL = 'PromotionLightBoxModal'
export const GLOBAL_SEARCH_MODAL = 'globalSearch'
export const TIRE_SIZES_NOT_FOUND_MODAL = 'tireSizesNotFound'
export const VEHICLE_CONFIGURATOR_MODAL = 'vehicleConfiguratorModal'
export const ADD_VEHICLE_MODAL = 'addVehicleModal'
export const SHARE_VIA_EMAIL_MODAL = 'shareViaEmailModal'
export const VEHICLE_DELETE_MODAL = 'vehicleDeleteModal'
export const TIRE_DETAILS_PROMOTION_MODAL = 'tireDetailsPromotionModal'
export const APPOINTMENT_UNSUPPORTED_MODAL = 'appointmentUnsupportedModal'
export const VEHICLE_MERGE_MODAL = 'vehicleMergeModal'
export const GENERIC_MESSAGE_MODAL = 'GenericMessageModal'
export const FORGOT_USERNAME_MODAL = 'forgotUsernameModal'
export const FORGOT_PASSWORD_MODAL = 'forgotPasswordModal'
export const TPP_MODAL = 'TireProtectionPlanModal'
export const CURBSIDE_SERVICE_MODAL = 'CurbsideServiceModal'
export const PERSONALIZE_VEHICLE_MODAL = 'PersonalizeVehicleModal'
export const NEW_SHOP_TIRES_MODAL = 'NewShopTiresModal'
export const NEW_YMME_MODAL = 'NewYMMEModal'
export const NEW_TIRE_SIZE_MODAL = 'NewTireSizeModal'
export const NEW_LICENSE_PLATE_MODAL = 'newLicensePlateModal'
export const CONTACT_STORE_MODAL = 'contactStoreModal'
export const ACTIVE_CART_REMINDER_MODAL = 'activeCartReminderModal'

// Custom events
export const OPEN_APPOINTMENT_MENU = 'openAppointmentMenu'
export const OPEN_BV_REVIEWS_TAB_AND_SCROLL_DOWN = 'openBazaarVoiceReviewsTabAndScrollDown'
export const OPEN_TRUMPET_REVIEWS_TAB = 'openTrumpetReviewsTab'
export const OPEN_NATIONWIDE_OFFERS_TAB = 'openNationwideOffersTab'
export const PAGE_HEADER_CHANGED = 'PageHeaderChanged'
export const ACTIVATE_MARCHEX_SCRIPT = 'activateMarchexScript'
export const TOGGLE_STORE_DETAILS_BOTTOM_BANNER = 'toggleBottomBanner'
export const TOGGLE_VEHICLE_OPTIONS = 'toggleVehicleOptions'

// data-attributes
export const PROMO_CALL = 'promoCall'

// Appointment confirmation
export const USER_EMAIL = 'email'
export const USER_FIRST_NAME = 'firstName'
export const USER_LAST_NAME = 'lastName'
export const USER_PHONE_NUMBER = 'phoneNumber'
export const USER_PLAN_FIELD_NAME = 'userPlan'
export const USER_PLAN_DROP_OFF = 'dropOff'
export const USER_PLAN_WAIT = 'stayAndWait'
export const USER_KEEP_IN_CONTACT = 'promonOffers'

// Store Locator
export const SEARCH_BY_LOCATION = 'searchByLocation'
export const SEARCH_BY_COORDS = 'searchByCoords'
export const LOCATION_FINDER_FORM = 'find_address_by_zip_code'
export const LOCATION_FINDER_INPUT = 'searchMask'
export const STORE_LOCATOR_MODAL_PAGE = 'storeSelectorModalPage'
export const STORE_LOCATOR_URL = '/store'
export const STORE_NUMBER_QUERY_PARAM_NAME = 'storeNum'
export const STORE_CHANGE_MODAL = 'storeChangeModal'

export const STORE_LOCATOR_MODAL_PAGE_SRC = '/modals/store-locator-modal'

// StickyHeader
export const BOT_VEHICLE_SELECTOR_ID = 'BOTVehicleSelector'
export const BOT_VEHICLE_SELECTOR_PLACEHOLDER_ID = 'BOTVehicleSelectorPlaceholder'
export const BOT_MY_STORE_ID = 'BOTMyStore'
export const BOT_APPOINTMENT_PROGRESS_BAR_ID = 'BOTQuickAppointmentProgressBar'
export const STICKY_HEADER_ID = 'StickyHeaderID'
export const STICKY_HEADER_COMMON_AREA_ID = 'StickyHeaderCommonAreaID'
export const BOT_GLOBAL_MESSAGE_ID = 'BOTHeaderGlobalMessage'
export const BOT_GLOBAL_MESSAGE_PORTAL_ID = 'BOTHeaderGlobalMessagePortal'
export const BOT_GLOBAL_MESSAGE_PLACEHOLDER_ID = 'BOTHeaderGlobalMessagePlaceholder'
export const BOT_GLOBAL_MESSAGE_CLOSE_ID = 'BOTHeaderGlobalMessageClose'
export const BOT_GLOBAL_MESSAGE_CONTENT_ID = 'BOTHeaderGlobalMessageContent'
export const BOT_COMPARE_PAGE_HEADER_PORTAL_ID = 'BOTTireComparePageStickyHeader'
export const BOT_COMPARE_PAGE_HEADER_CONTAINER_ID = 'BOTTireComparePageStickyHeaderContainer'

// API error codes
export const CODE_SUCCESS = '000'
export const CODE_MERGE_ERROR = '1012'
export const CODE_APPOINTMENT_AVAILABILITY_ERROR = '3017'
export const CODE_QUICK_APPT_SUBMISSION_ERROR = '3002'
export const CODE_ITEM_EXISTS_IN_CART = '3018'
export const CODE_ITEM_DISCONTINUED_FOR_STORE = '5001'
export const CODE_DATA_NOT_AVAILABLE = '002'
export const CODE_PLEASE_SELECT_STORE = '003'

// API error descriptions
export const DESC_PLEASE_SELECT_STORE = 'Please select Store'

// localStorage authorization keys
export const SHOPPING_DATA_KEY = 'SHOPPING_DATA'
export const MY_FAVORITES_KEY = 'MY_FAVORITE_SKUS'

// Promotions
export const REGIONS_QUERY_PARAM = 'regionName'
export const PROMOTIONS_DETAIL_MODAL_PAGE = 'promotionsDetailModalPage'

// Brand details tire compatibility
export const BRAND_COMPATIBILITY_QUERY_NAME = 'brandResultsSet'
export const BRAND_COMPATIBILITY_COMPATIBLE = 'compatible'
export const BRAND_COMPATIBILITY_INCOMPATIBLE = 'nonCompatible'

// Brand details sort by price
export const BRAND_SORT_OPTION_QUERY_NAME = 'sortOption'
export const BRAND_SORT_BY_PRICE_LOW_TO_HIGH = 'lowToHigh'

// Global search results
export const GLOBAL_SEARCH_QUERY_KEY = 'Ntt'

// Appointment
export const APPOINTMENT_FORM_NAME = 'appointmentForm'
export const STORE_SELECTOR_FIELD_NAME = 'selectedStore'
export const VEHICLE_SELECTOR_FIELD_NAME = 'activeVehicleId'
export const DATE_TIME_SELECTOR_FIELD_NAME = 'appointmentDate'
export const SERVICES_SELECTOR_FIELD_NAME = 'catalogRefIds'
export const ACTIVE_PROMOTIONS_FIELD_NAME = 'activePromotions'
export const DROP_OFF_TIME_FIELD_NAME = 'drop-off'
export const DROP_OFF_NEXT_DAY_TIME_FIELD_NAME = 'drop-off-next-day'
export const SERVICES_COMMENT_FIELD_NAME = 'serviceComment'

export const DEFAULT_VEHICLE_ASSET = 'icon-vehicle'
export const DONE_STEP_ASSET = 'check-green'

export const VEHICLE_SELECTOR_TARGET_ID = 'vehicle-selector'
export const LOCATION_SELECTOR_TARGET_ID = 'store-selector'
export const SERVICES_SELECTOR_TARGET_ID = 'services-selector'
export const DATE_TIME_SELECTOR_TARGET_ID = 'date-time-selector'
export const APPOINTMENT_SUMMARY_SELECTOR_TARGET_ID = 'appointment-summary-selector'

// Vehicle configurator tabs
export const VEHICLE_CONFIGURATOR_YMME_TAB = 0
export const VEHICLE_CONFIGURATOR_TIRE_SIZE_TAB = 1

// Tire compatibility statuses
export const COMPATIBILITY_STATUS_COMPATIBLE = 'Compatible'
export const COMPATIBILITY_STATUS_NO_COMPATIBLE_MESSAGE = 'NoCompatibleMessage'
export const COMPATIBILITY_STATUS_NON_COMPATIBLE = 'NonCompatible'
export const COMPATIBILITY_STATUS_SPEED_RATING_NOT_MATCHED = 'SpeedRatingNotMatched'
export const COMPATIBILITY_STATUS_SIZE_NOT_MATCH = 'SizeNotMatch'
export const COMPATIBILITY_STATUS_NO_VEHICLE = 'NoVehicle'

// Content modal
export const CONTENT_MODAL = 'contentModal'

// Filter modal
export const FILTER_MODAL = 'filterModal'
export const FILTER_MODAL_FORM = 'filterModal'
export const FILTER_MODAL_FORM_CHECKBOXES = 'filterCheckboxes'

// 3'd party API keys
export const GOOGLE_STATIC_MAPS_API_KEY = 'AIzaSyCxGcektpe9-tgRKsEPs2o6IOLD-buXMYU'
export const RIDE_STYLER_API_KEY = 'bb5e9483bbc14baea99e32e7b4625f3a'
// TODO: replace with real bazaarVoice API key
export const BAZAARVOICE_API_KEY = 'cahfD0OODNkEPAikvQfjwGaS6ChvxLsmMHCCtkDStyRGs'

// Proxy prefixes
export const GOOGLE_PLACE_AUTOCOMPLETE_PREFIX = '/googleapis/maps/place/autocomplete'
export const BAZAARVOICE_REVIEWS_API_PREFIX = '/bazaarvoice/api/reviews'

// Trumpet Ratings Base Urls
export const TRUMPET_INLINE_RATING_URL = 'https://api.trumpetratings.com/api/trumpetsinglelocationdata'
export const TRUMPET_PAGED_URL = 'https://api.trumpetratings.com/api/trumpetratingspaged'
export const TRUMPET_WRITE_REVIEW_BASE_URL = 'https://www.bigotirescustomerfirst.com'

// Base URLs
export const RIDE_STYLER_LIVE_BASE_URL = 'https://api.ridestyler.net'
export const RIDE_STYLER_BETA_BASE_URL = 'http://api-beta.ridestyler.net'

// Polyfill.io
export const POLYFILL_PROXY_URL = '/polyfill/v3.js'

// shoppingQuote
export const REBATE_PROMOTION_TYPE = 'rebate'
export const CALL_STORE_FOR_PRICE_FIRST_TEXT = 'Call Store for Price '
export const NOT_AVAILIABLE_FIRST_TEXT = 'This item may not be available. Please contact your store at '
export const NOT_AVAILIABLE_SECOND_TEXT = ' to confirm.'
export const ADDING_WITHOUT_SELECTED_VEHICLE = 'Please enter your vehicle information before proceeding.'

// Forms & fields
// Promotion details
export const FROM_PROMOTIONS_URL_FLAG = 'fromPromo'
// Tire
export const TIRE_QUANTITY_FORM_NAME = 'tireQuantityForm'
export const COMPARE_TIRES_QUANTITY_FORM_NAME = 'compareTiresQuantityForm'
export const TIRE_FRONT_INPUT_NAME = 'tireFrontQuantity'
export const TIRE_REAR_INPUT_NAME = 'tireRearQuantity'

export const REGISTRATION_FORM_PATH = '/my-account/register/registration-form'

export const TIRE_DETAILS_REVIEWS_QUERY = 'openReviews'

export const SEARCH_BY_VEHICLE_REGEXP = /tires\/by-vehicle/
export const SEARCH_BY_TIRE_SIZE_REGEXP = /tires\/by-size/
export const TIRE_SEARCH_REGEX = /tires\/(by-vehicle|by-size)/
export const TRAILER_SEARCH_REGEX = /tires\/(trailer)/
export const VIEW_ALL_PARAM = 'Nrpp'
export const VIEW_ALL_NEXT_PARAM = 'No'

export const TRAILER_TYPE = 'trailer'
export const TRAILER_NAME = 'Trailer'
export const TRAILER_RV_ATV_TYPE = 'rtVehicleType'
export const TRAILER_TITLE = 'Trailer / RV / ATV'

// Badges
export const MANUFACTURER_MILEAGE_WARRANTY = 'Manufacturer Mileage Warranty'
export const BIG_O_MILEAGE_WARRANTY = 'BIG O Warranty'
export const TIRE_PROTECTION_PACKAGE = 'Tire Protection Package'
export const TREAD_LIFE_WARRANTY = 'Treadlife Warranty'
export const WORKMANSHIP = 'WORKMANSHIP'

// Date & time
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const DAYS_IN_MONTHS = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const DAYS_OBJ = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
}

export const STATES_OBJ = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

export const DEBOUNCE_INTERVAL = 500

export const MAX_WIDTH = 1570

const BOT_EXCLUSIVE = 'bigoExclusiveItem'
const OUR_BEST_VALUE = 'bigoItem'
const OUR_LOWEST_TIRE = 'oppItem'
const OUR_BEST_TIRE = 'tier1Item'

export const RECOMMENDED_TYPES = [OUR_LOWEST_TIRE, OUR_BEST_VALUE, BOT_EXCLUSIVE, OUR_BEST_TIRE]
export const GBB_TITLES = {
  [BOT_EXCLUSIVE]: 'Our Recommended Tire',
  [OUR_BEST_VALUE]: 'Big O Best Value',
  [OUR_LOWEST_TIRE]: 'Our Lowest Price',
  [OUR_BEST_TIRE]: 'Our Best Tire'
}

// Id-prefixs
export const RECOMMENDED_PRODUCT = 'recommendedProduct'
export const TIRE_RESULT_PRODUCT = 'tireResultProduct'
export const HEADER_ID = 'HeaderRef'
export const HEADER_STORE_ID = 'HeaderMyStoreRef'
export const CAR_BAR_ELEMENT_ID = 'carBarRef'
export const HEADER_CONTAINER_ID = 'HeaderContainerRef'
export const HEADER_SYSTEM_ALERT_ID = 'HeaderSystemAlertRef'
export const HEADER_TOP_NAVIGATOR = 'TopNavigatorRef'
export const EXPIRED_SESSION_ID = 'SessionTimeoutMsg'

// Form field hint ids
export const PASSWORD_HINT_ID = 'password-hints'
export const LOCATION_FINDER_HINT_ID = 'location-finder-hint'

// Link
export const DEFAULT_PATH = '/'
export const EXTERNAL_LINK = /^https?:\/\//i

export const SKIP_TO_MAIN_CONTENT = 'Skip to main content'
export const MAIN_CONTENT = 'main-content'

export const SKIP_TO_FOOTER = 'Skip to footer'
export const FOOTER = 'footer'

export const STATE_OPTIONS = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI',
  'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']

export const BOT_GENERIC_PLACEHOLDER_TYPE = 'BOTGenericPlaceHolder'

// Session Timeout
export const SESSION_EXPIRED = 'sessionExpired'
export const SESSION_EXPIRED_MESSAGE = 'Your session has expired. Please log in again.'

export const MODAL_DIALOG_ID = 'dialog-heading'
export const MODAL_CONTENT_ID = 'ModalContentContainer'

// Default heights
export const DESKTOP_HEADER_MAX_HEIGHT = 110
export const DESKTOP_HEADER_MIN_HEIGHT = 60
export const MOBILE_HEADER_HEIGHT = 60
export const DESKTOP_NAVIGATOR_HEIGHT = 42

// GAUserID Cookie
export const GA_USER_ID = '_gid'

// Kenshoo event conversionTypes
export const ADD_TIRES = 'add_tires'
export const ADD_SERVICES = 'add_services'
export const APPT_CONFIRMATION = 'appointment'

// OTDP Message
export const OTDP_MESSAGE = 'Price is an estimate only. Actual price may vary by location.'

// Schema IDs
export const STORE_DETAILS_SCHEMA_ID = 'store-details-schema'
export const PRODUCT_DETAILS_SCHEMA_ID = 'product-details-schema'

// Spinner ID
export const SPINNER_ID = 'spinnerRef'

// Make an Appointment Button ID
export const MAKE_AN_APPOINTMENT_BUTTON_ID = 'make-an-appointment-button'


// debounce
export const DEBOUNCE_TIMEOUT = 100

// Page sources for Google Analytics
export const SEARCH_RESULT_SOURCE = { list: 'Search Results' }
export const SAVE_FOR_LATER_LIST_SOURCE = { list: 'Saved for Later' }
export const HOME_PAGE_SOURCE = { list: 'Home Page' }
export const CART_SOURCE = { list: 'Cart' }


// bread payment
export const BREAD_LOCATION_LANDING = 'landing'
export const BREAD_LOCATION_CART = 'cart'
export const BREAD_LOCATION_PRODUCT = 'product'
export const BREAD_LOCATION_HEADER = 'header'
export const BREAD_LOCATION_FOOTER = 'footer'

export const BREAD_PLACEMENT_ID_LANDING = '5a43f19f-e696-49c6-91b1-5ffbe6316cfc'
export const BREAD_PLACEMENT_ID_CART = '5a40d382-502f-43b0-8acc-91d447c35d83'
export const BREAD_PLACEMENT_ID_PRODUCT = 'bb9b38b7-62a9-4b3d-bc4e-aae6b5e6eb06'
export const BREAD_PLACEMENT_ID_HEADER = 'ebaecf66-73ae-40e6-be00-ef7363cfe46e'
export const BREAD_PLACEMENT_ID_FOOTER = '99dd1757-90c7-441e-a416-642c173af015'

// Articles
export const MAIN_HEADING_CLASS = 'speakable-heading'
export const ARTICLE_IMAGE_CLASS = 'article-image'

// REDIRECT LINKS
export const SEARCH_BY_SIZE = '/tires/by-size'
export const SEARCH_BY_TRAILER = '/tires/trailer'

// SEO
export const SPEAKABLE_SUMMARY = 'speakable-summary'
export const LOGO_IMAGE = 'https://tbc.scene7.com/is/image/TBCCorporation/BOT-Logo'

// VEHICLE EDIT AND PERSONALIZATION
export const VEHICLE_NICKNAME = 'nickname'
export const VEHICLE_MILEAGE = 'mileage'
export const DISPLAY_MILEAGE = 'displayMileage'
export const LICENCE_NAME = 'licencePlateNumber'
export const LICENCE_MAX_LENGTH = 10
export const STATE_NAME = 'state'
export const WRONG_LICENSE_PLATE_OR_STATE_MESSAGE = 'We were unable to find your vehicle. Please check the License Plate Number and the state information.'
export const LICENSE_PLATE_DO_NOT_MATCH_CODE = '003'
export const LICENSE_PLACE_DO_NOT_MATCH_DESCRIPTION = 'error_license_plate_number_not_match'
export const MILEAGE_METRIC = 'mi'

// QUICK APPOINTMENT
export const SCHEDULE_APPOINTMENT = 'Schedule Appointment'
export const REQUEST_APPOINTMENT = 'Request Appointment'
export const SCHEDULE_APPOINTMENT_THREE_STEPS_TITLE = 'Schedule An Appointment In 3 Easy Steps'
export const REQUEST_APPOINTMENT_THREE_STEPS_TITLE = 'Request An Appointment In 3 Easy Steps'
export const REQUEST_APPOINTMENT_THREE_STEPS_DATE_TITLE = '2. Request Date & Select Vehicle'
export const SCHEDULE_APPOINTMENT_THREE_STEPS_DATE_TITLE = '2. Schedule Date & Select Vehicle'
export const REVIEW_REQUEST_TITLE = '3. Review Your Request'
export const REVIEW_APPOINTMENT_TITLE = '3. Review Your Appointment'
export const SENT_APPOINTMENT_TITLE = 'Appointment Request Sent'
export const SENT_SCHEDULE_TITLE = 'Appointment Scheduled'
export const REQUEST_SENT_BLOCK_DESCRIPTION = 'Thank you! Your appointment has been requested. We will contact you to confirm your appointment details soon.'
export const SCHEDULE_SENT_BLOCK_DESCRIPTION = 'Thank you! Your appointment has been scheduled. Please see your appointment details below.'
export const REACH_OUT_E2E_TITLE = 'with any appointment changes or cancellations.'
export const REACH_OUT_REQUEST_TITLE = 'with any appointment request changes or cancellations.'
export const REACH_OUT_REQUEST_TITLE_NEW = 'Appointment request changes or cancellations?'
export const REQUEST_CONTACT_REVIEW_DESCRIPTION = 'Enter your contact info and verify your details, then click Request Appointment.'
export const SCHEDULE_CONTACT_REVIEW_DESCRIPTION = 'Enter your contact info and verify your details, then click Schedule Appointment.'
export const REQUEST_CALENDAR_TITLE = 'Request Date and Time'
export const SCHEDULE_CALENDAR_TITLE = 'Schedule Date and Time'
export const APPOINTMENT_CONFIRMATION = 'Your appointment is Confirmed'
export const APPOINTMENT_RECEIVED = 'Your appointment is Received'

// NEW YMME
export const TAB_YMME_NAME = 'ymme'
export const TAB_LICENSE_PLATE_NAME = 'licensePlate'
export const TAB_TIRE_SIZE_NAME = 'tireSize'
export const HEADER_SOURCE = 'header'
export const GLOBAL_SEARCH_SOURCE = 'globalSearch'
export const PROMO_DETAILS_SOURCE = 'promotionDetails'
export const GENERAL_SOURCE = 'general'
export const YMME_NAME = 'MainYmme'
export const TRAILER_SOURCE = 'trailerPage'

// Keep in contact
export const KEEP_IN_CONTACT_LABEL = ['Receive the latest offers and promotions', <br />, '(optional)']

// LocalStorage URL key
export const STORE_DETAILS_PAGE_URL = 'storeDetailsPageUrl'
