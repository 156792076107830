import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LazyDiv } from 'components/common/LazyHtml'
import Mixin from 'components/common/Mixin'
import Promotion from './Promotion/Promotion'
import { HomePageCarousel } from '../../CommonStyles'

const Promotions = ({
  homePageVersion,
  handlePromotionClick,
  filteredPromotions = [],
  carouselConfig,
  noResultMessage,
  blackFridayModal = false,
  handleCloseModal = () => {},
  ...props
}) => filteredPromotions.length === 0 ?
  <EmptyResult data-html-block content={ noResultMessage } />
  : !homePageVersion ?
    <PromotionsWrapper>
      { filteredPromotions.map((promotion, index) =>
        <Promotion
          key={ promotion.promotionId }
          promotionData={ promotion }
          onClick={ () => {
            blackFridayModal && handleCloseModal()
            handlePromotionClick({ item: promotion, index })
          } }
          { ...props } />
      )}
    </PromotionsWrapper>
    :
    <HomePageCarousel config={ carouselConfig }>
      { filteredPromotions.map((promotion, index) =>
        <Promotion
          key={ promotion.promotionId }
          promotionData={ promotion }
          onClick={ () => handlePromotionClick({ item: promotion, index }) }
          { ...props } />
      )
      }
    </HomePageCarousel>

Promotions.propTypes = {
  blackFridayModal: PropTypes.bool,
  carouselConfig: PropTypes.shape(),
  filteredPromotions: PropTypes.arrayOf(PropTypes.shape()),
  handleCloseModal: PropTypes.func,
  handlePromotionClick: PropTypes.func,
  homePageVersion: PropTypes.bool,
  noResultMessage: PropTypes.string
}

export default Promotions

const EmptyResult = styled(LazyDiv)`
  text-align: center;
  ${Mixin.responsive('margin', '12px 0 0', '12px 8px 0 8px')}
`

const PromotionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 310px));
  justify-content: center;
`
