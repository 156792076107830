import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import { components } from 'react-select'
import Icon from 'components/common/Icon/Icon'
import { Input } from 'components/common/FormElements/Input'

const Menu = ({
  children,
  ariaLabel,
  selectProps: {
    topPosition,
    leftPosition,
    setSearchInputRef,
    searchFilter,
    setSearchFilter,
    clearSearchFilter,
    handleSearchInputClick
  },
  ...props
}) =>
  <StyledMenu
    topPosition={ topPosition }
    leftPosition={ leftPosition }
    { ...props }>
    <div>
      <InputWrapper>
        <StyledInput
          full
          tabIndex="0"
          placeholder="Search"
          value={ searchFilter }
          inputRef={ setSearchInputRef }
          onClick={ handleSearchInputClick }
          onKeyDown={ e => e.stopPropagation() }
          onChange={ setSearchFilter }>
          { Boolean(searchFilter.length) &&
            <ClearInput
              asset="cross"
              alt=""
              onClick={ clearSearchFilter } /> }
        </StyledInput>
      </InputWrapper>
      { children }
    </div>
  </StyledMenu>


Menu.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  selectProps: PropTypes.shape()
}

export default Menu

const StyledMenu = styled(components.Menu)`
  position: absolute;
  background-color: ${Theme.colors.white};

  &&& {
    display: table;
    border: none !important;
    filter: drop-shadow(0 0 5px ${Theme.colors.light_grey_border});
    height: 480px;
    top: ${props => props.topPosition}px;
    left: ${props => props.leftPosition}px;
    padding: 5px 10px;
    min-width: 300px;
  }

  ::after {
    content: "";
    position: absolute;
    top: ${props => props.topPosition * -1}px;
    right: -40px;
    border-style: solid;
    display: block;
    width: 0;
    z-index: 1;
    border: 20px solid transparent;
    border-left: 20px solid ${Theme.colors.white};
  }
`

const InputWrapper = styled.div`
  width: 200px;
  margin: 10px 0;
`

const StyledInput = styled(Input)`
  && input {
    height: 30px;
    font-weight: 500;
    font-style: italic;
    padding-right: 25px;
  }
`

const ClearInput = styled(Icon)`
  position: relative;
  left: 180px;
  top: -23px;
  height: 12px;
`
