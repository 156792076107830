import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import { compose } from 'redux'
import reduxForm from 'components/common/hoc/withFormErrorFocus'
import { isMobile, isNotIE } from 'components/common/Media/helpers'
import { FORM_NAME, SEARCH_FIELD_NAME } from '../constants'
import { getModalData, getSearchError } from '../selectors'
import {
  applySearch,
  applySuggestion,
  forceSearch,
  handleFormChange,
  openVehicleConfigurator,
  submitForm
} from './actions'
import validate from './validate'
import SearchModalForm from './SearchModalForm'

const THROTTLE_INTERVAL = 250

const withFormChangeHandlers = withHandlers(({ handleFormChange, submitForm }) => {
  const debouncedHandleFormChange = debounce(handleFormChange, THROTTLE_INTERVAL)
  const cancelAutoSuggestHandler = () => debouncedHandleFormChange.cancel()
  return {
    onChange: () => values => debouncedHandleFormChange(values),
    onKeyDown: () => e => submitForm(e, cancelAutoSuggestHandler)
  }
})

const mapStateToProps = (state, { handleFormChange, onChange, onKeyDown }) => {
  const { hintText, placeHolderText, mobilePlaceHolderText } = getModalData(state)
  const autofocus = { ...(isNotIE() && { autofocus: 'true' }) }

  return {
    inputProps: {
      label: hintText,
      name: SEARCH_FIELD_NAME,
      placeholder: isMobile() ? mobilePlaceHolderText : placeHolderText,
      required: true,
      onChange,
      onKeyDown,
      ...autofocus
    },
    searchError: getSearchError(state)
  }
}

export default compose(
  connect(null, { handleFormChange, submitForm }),
  withFormChangeHandlers,
  connect(mapStateToProps),
  reduxForm({ form: FORM_NAME, onSubmit: applySearch, validate, isModal: true })
)(SearchModalForm)


// exports everything you need from here

// actions
export {
  applySuggestion,
  forceSearch,
  openVehicleConfigurator
}
