export const isCurrentVehicle = ({
  makes: { value: makesValue },
  years: { value: yearsValue },
  models: { value: modelsValue },
  subModels: { value: subModelsValue }
}, { make, year, model, subModel }) =>
  make === makesValue &&
  year === yearsValue &&
  model === modelsValue &&
  subModel === subModelsValue

export const triggerExtraTiresSelectCallbacks = ({ tiresSelectExtraCallbacks, userVehicleId }) =>
  tiresSelectExtraCallbacks && tiresSelectExtraCallbacks.forEach(callback => callback(userVehicleId))
