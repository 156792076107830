import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import AddButton from '../AddButton'
import TeaserContent from '../TeaserContent'

const ExtendedVehicleSelectorBlock = ({ teasers = [], addVehicle }) => {
  const centerIndex = Math.ceil(teasers.length / 2)
  const leftColumn = teasers.slice(0, centerIndex)
  const rightColumn = teasers.slice(centerIndex)

  return (
    <Wrapper data-at-extended-vehicle-selector-block>
      <SectionTitle data-at-section-title>Why Add a Vehicle to My Garage?</SectionTitle>
      <OneColumn>
        { leftColumn.map((teaser, index) =>
          <TeaserContent key={ index } number={ index + 1 } { ...teaser } />) }
      </OneColumn>
      <OneColumn>
        { rightColumn.map((teaser, index) =>
          <TeaserContent key={ index } number={ index + centerIndex + 1 } { ...teaser } />) }
      </OneColumn>
      <Button data-at-carbar-addvehicle narrow onClick={ addVehicle }>+ ADD A VEHICLE</Button>
    </Wrapper>
  )
}

ExtendedVehicleSelectorBlock.propTypes = {
  addVehicle: PropTypes.func,
  teasers: PropTypes.arrayOf(PropTypes.shape())
}

export default ExtendedVehicleSelectorBlock

const Wrapper = styled.div`
  position: relative;
  ${Media.desktop`
    padding: 0 180px 20px 0;
  `}
  ${Media.mobileNarrow`
    height: 220px;
    overflow-y: auto;
  `}
`

const SectionTitle = styled.div`
  margin: 18px 0 10px;
  ${Mixin.font.GothamBold(18)}
  ${Media.mobile`
    ${Mixin.font.GothamBold(14)}
  `}
`
const OneColumn = styled.ul`
  display: inline-block;
  padding: 0;
  margin: 0;
  ${Mixin.responsive('width', '370px', '100%')}
`

const Button = styled(AddButton)`
  ${Media.desktop`
    position: absolute;
    top: 54px;
    right: 0;
  `}
  ${Media.mobile`
    margin: 20px 0;
  `}
`
