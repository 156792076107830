import { show, hide } from 'redux-modal'
import { push } from 'connected-react-router'
import { change, submit } from 'redux-form'
import Theme from 'components/common/Theme/Theme'
import { rest } from 'services'
import { addVehicle } from 'actions/addVehicle'
import { navigateToSearchByVehicle } from 'actions/shopByVehicle'
import { getSearchUrl } from 'helpers/global-search'
import { VEHICLE_CONFIGURATOR_MODAL, CONFIRM_TIRE_SIZE_MODAL, GLOBAL_SEARCH_MODAL } from 'constant'
import { clearSearchData, clearSearchAutoSuggestions, setSearchAutoSuggestions } from '../actions'
import { SEARCH_FIELD_NAME, FORM_NAME } from '../constants'
import { getModalData, getIsSearchEmpty } from '../selectors'
import { configureReqParams } from './helpers'
import { noop } from 'helpers/utils'

const searchSuggestions = (value, autoCorrect = true) => async dispatch => {
  let response

  try {
    response = await rest.getOnce('/modals/autosuggest', {
      params: configureReqParams(value, autoCorrect)
    })

    dispatch(setSearchAutoSuggestions({ data: response.data }))
  } catch (err) {
    return console.error(err)
  }
}

export const handleFormChange = values => (dispatch, getState) => {
  const state = getState()
  const { symbolsBound } = getModalData(state)
  const searchQuery = values[SEARCH_FIELD_NAME] || ''

  if (searchQuery.length <= symbolsBound) {
    if (!getIsSearchEmpty(state)) {
      dispatch(clearSearchData())
      dispatch(clearSearchAutoSuggestions())
    }

    return
  }

  dispatch(searchSuggestions(searchQuery))
}

const updateInput = value => change(FORM_NAME, SEARCH_FIELD_NAME, value)

export const applySuggestion = value => dispatch => {
  dispatch(updateInput(value))
  dispatch(searchSuggestions(value))
}

export const forceSearch = value => dispatch => {
  dispatch(updateInput(value))
  dispatch(searchSuggestions(value, false))
}

export const openVehicleConfigurator = props => dispatch => {
  dispatch(hide(GLOBAL_SEARCH_MODAL))

  dispatch(show(VEHICLE_CONFIGURATOR_MODAL, {
    ...props,
    title: 'Shop For Tires',
    confirmTireSizeModalProps: {
      handleTiresSelect: async ({
        vehicle,
        frontTireSize,
        rearTireSize,
        loadIndex,
        rearLoadIndex,
        speedRating,
        rearSpeedRating
      }) => {
        await dispatch(
          addVehicle({
            ...vehicle,
            tireFrontSize: frontTireSize,
            tireRearSize: rearTireSize,
            loadIndex,
            rearLoadIndex,
            speedRating,
            rearSpeedRating
          })
        )
        dispatch(hide(CONFIRM_TIRE_SIZE_MODAL))
        dispatch(navigateToSearchByVehicle({ ...vehicle, frontTireSize, rearTireSize }))
      }
    }
  }))
}

export const applySearch = (values, dispatch) => {
  const value = values[SEARCH_FIELD_NAME]
  const filteredValue = value.replace(/[^a-zA-Z ]/g, '')

  dispatch(push(getSearchUrl(filteredValue)))
}

export const submitForm = (e, cancelAutoSuggestHandler = noop) => dispatch => {
  if (e.keyCode === Theme.keyCode.ENTER) {
    cancelAutoSuggestHandler()
    e.preventDefault()
    dispatch(submit(FORM_NAME))
  }
}
