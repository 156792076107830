import { inputTypes } from 'components/common/FormElements/Input'
import {
  LOGIN_PROPERTY,
  PASSWORD_PROPERTY,
  REMEMBER_ME_PROPERTY
} from './constants'

export default {
  email: {
    name: LOGIN_PROPERTY,
    type: 'email',
    label: 'User Email',
    'data-at-loginform-email': true,
    required: true
  },

  password: {
    name: PASSWORD_PROPERTY,
    type: 'password',
    label: 'Password',
    'data-at-loginform-password': true,
    required: true
  },

  rememberMe: {
    name: REMEMBER_ME_PROPERTY,
    inputType: inputTypes.CHECKBOX_INPUT,
    label: 'Keep me logged in',
    'data-at-login-keep-loggedin': true
  }
}
