import { withHandlers, compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { GENERIC_MESSAGE_MODAL } from 'constant'
import GenericMessageModal from './GenericMessageModal'

export default compose(
  registerReduxModal({ name: GENERIC_MESSAGE_MODAL }),
  withHandlers({
    handleCancel: ({ onCancel, handleHide }) => () => {
      handleHide()
      onCancel()
    },
    handleUpdate: ({ onUpdate, handleHide }) => () => {
      handleHide()
      onUpdate()
    }
  })
)(GenericMessageModal)
