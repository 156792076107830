import { compose, lifecycle, withStateHandlers } from 'recompose'
import { HIGH_CONTRAST_CLASS_NAME } from '../constants'
import HighContrastTest from './HighContrastTest'

const withBlockRef = withStateHandlers({
  blockRef: undefined
}, {
  setBlockRef: () => ref => ({ blockRef: ref })
})

const testHighContrast = (ref) => window.document && window.getComputedStyle && ref &&
  window.getComputedStyle(ref)['background-image'] === 'none'

const withLifecycle = lifecycle({
  componentDidUpdate(prevProps) {
    const { blockRef } = this.props
    if (blockRef !== prevProps.blockRef) {
      const isHighContrast = testHighContrast(blockRef)
      const rootHtml = document.getElementsByTagName('html')[0]
      let classNames = rootHtml.className
      if (isHighContrast && classNames.indexOf(HIGH_CONTRAST_CLASS_NAME) === -1) {
        classNames += ` ${HIGH_CONTRAST_CLASS_NAME}`
      } else if (!isHighContrast && classNames.indexOf(HIGH_CONTRAST_CLASS_NAME) !== -1) {
        classNames = classNames.replace(HIGH_CONTRAST_CLASS_NAME, '')
      }
      rootHtml.className = classNames.trim()
    }
  }
})

export default compose(
  withBlockRef,
  withLifecycle
)(HighContrastTest)

export { highContrast } from './HighContrastMode'
