import { compose, withHandlers, withStateHandlers, lifecycle } from 'recompose'
import Popover from './Popover'
import active from 'components/common/hoc/active'

const withTireSizeTooltipStateHandlers = withStateHandlers({},
  {
    setLinkRef: () => ref => ({ linkRef: ref }),
    setPopoverContentRef: () => ref => ({ popoverContentRef: ref })
  }
)

const withTireSizeTooltipHandlers = withHandlers({
  handleContentFocus: ({ active, popoverContentRef, linkRef }) => () =>
    setTimeout(() => {
      if (document.activeElement === popoverContentRef && active && linkRef) {
        linkRef.focus()
      }
    }, 500)
})

const withLifecycle = lifecycle({
  componentDidUpdate({ active: prevActive }) {
    const { active, popoverContentRef, linkRef } = this.props

    const observer = new MutationObserver(mutations => {
      mutations.forEach(record => {
        if (record.type === 'attributes') {
          const changedAttr = record.attributeName
          const newValue = record.target.getAttribute(changedAttr)
          if (changedAttr === 'aria-label' && newValue === 'undefined information') {
            linkRef.setAttribute('aria-label', 'Find my Tire Size tooltip')
          }
        }
      })
    })

    if (linkRef) {
      observer.observe(linkRef, {
        attributes: true
      })
    }

    if (!prevActive && active &&
        linkRef && document.activeElement === linkRef &&
        popoverContentRef) {
      popoverContentRef.focus()
    }
  }
})

export default compose(
  withTireSizeTooltipStateHandlers,
  active,
  withTireSizeTooltipHandlers,
  withLifecycle
)(Popover)

