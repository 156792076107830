import { compose, withProps, withHandlers } from 'recompose'
import { capitalize } from 'lodash'
import TireSizeStepper from './TireSizeStepper'
import { resetTireSizePickedValues, getTireSizeNames } from '../../helpers'
import { FRONT, REAR } from 'components/VehicleConfigurator/components/withTireSize/constants'
import { FRONT_TIRE_SIZE_STEP_NAMES, REAR_TIRE_SIZE_STEP_NAMES } from '../../constant'

const withStepperProps = withProps(({
  stepNames,
  rearTireSizeChecked,
  step
}) => ({
  totalSteps: stepNames.length - 1,
  isRearTireSizesActive: rearTireSizeChecked && step > 2
}))

const withStepperHandlers = withHandlers({
  prevStepHandler: ({
    setData,
    data,
    setStep,
    handlerTireSizeResetOnPrevStep,
    stepNames,
    setSearch,
    activePosition,
    step
  }) => ({ stepName, position }) => {
    const clickedIndex = stepNames.findIndex(({ name }) => {
      const keyName = `${position}${capitalize(stepName)}`
      return name === keyName
    })
    const names = getTireSizeNames(clickedIndex, stepNames)
    const positions = [activePosition]
    const currentStep = activePosition === REAR ? step - 3 : step

    if (clickedIndex < currentStep) {
      setSearch('')
      setData(resetTireSizePickedValues({
        data,
        clickedIndex,
        stepNames,
        activePosition
      }))
      setStep(activePosition === REAR ? clickedIndex + 3 : clickedIndex)
      handlerTireSizeResetOnPrevStep({ positions, names })
    }
  },
  backToFrontTireSizeHandler: ({
    setActivePosition,
    setStepNames,
    setSizeName,
    handlerTireSizeResetOnPrevStep,
    setSearch,
    setData,
    data,
    setStep
  }) => () => {
    const positions = [FRONT, REAR]
    const lastFrontStepIndex = 2
    const fullListOfStepNames = [...FRONT_TIRE_SIZE_STEP_NAMES, ...REAR_TIRE_SIZE_STEP_NAMES]
    const names = getTireSizeNames(lastFrontStepIndex, fullListOfStepNames)

    setActivePosition(FRONT)
    setStepNames(FRONT_TIRE_SIZE_STEP_NAMES)
    setSizeName(FRONT_TIRE_SIZE_STEP_NAMES[lastFrontStepIndex - 1].value)
    setSearch('')
    setData(resetTireSizePickedValues({
      data,
      clickedIndex: lastFrontStepIndex,
      stepNames: fullListOfStepNames
    }))
    setStep(lastFrontStepIndex)
    handlerTireSizeResetOnPrevStep({ positions, names })
  }
})

export default compose(
  withStepperProps,
  withStepperHandlers
)(TireSizeStepper)

