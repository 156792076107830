import * as requestInterceptors from './interceptors/request'
import * as responseInterceptors from './interceptors/response'

const REQUEST = 'request'
const RESPONSE = 'response'

const getInterceptorsInstaller = axiosInstance => (interceptors, type) =>
  Object.keys(interceptors)
    .forEach(interceptor => {
      if (typeof interceptors[interceptor] === 'function') {
        axiosInstance.interceptors[type].use(interceptors[interceptor])
      } else {
        axiosInstance.interceptors[type].use(...interceptors[interceptor])
      }
    })

export default axiosInstance => {
  const installer = getInterceptorsInstaller(axiosInstance)

  installer(requestInterceptors, REQUEST)
  installer(responseInterceptors, RESPONSE)
}
