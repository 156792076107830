import { compose } from 'redux'
import { connect } from 'react-redux'
import { lifecycle, withHandlers, withProps, withState } from 'recompose'
import withTireSize from '../withTireSize'
import ShopByTires from './ShopByTires'
import getActiveVehicle from 'selectors/getActiveVehicle'
import { getIsTrailer } from 'helpers/vehicle'
import { noop } from 'helpers/utils'
import { reset } from '../withTireSize/actions'

const withExpanderToggle = withState('expanderToggleHandler', 'setExpanderToggleHandlers', noop)

const withFetchingCustomTireSizes = lifecycle({
  componentDidMount() {
    const {
      initialize,
      isModal,
      activeVehicle: { hasStaggeredFitment } = {},
      activeVehicle,
      defaultVehicle,
      position,
      openRearTireSize,
      notTrailerPageOrTrailer,
      handleCloseRearTireSize,
      resetRearSizeFields,
      showPopularSizes,
      isTrailerPage,
      reset
    } = this.props
    resetRearSizeFields({ isModal })
    initialize({ activeVehicle, defaultVehicle, position, notTrailerPageOrTrailer, isModal, showPopularSizes })
    if (hasStaggeredFitment && notTrailerPageOrTrailer) {
      openRearTireSize({ isModal })
    } else {
      handleCloseRearTireSize({ isModal })
    }

    if (isTrailerPage) {
      reset()
    }
  },
  componentDidUpdate({
    activeVehicle: {
      frontTireSize: prevFrontTireSize,
      rearTireSize: prevRearTireSize
    } = {},
    activeVehicle: prevVehicle = {},
    showPopularSizes: prevShowPopularSizes
  }) {
    const {
      initialize,
      activeVehicle,
      activeVehicle: { frontTireSize, rearTireSize, hasStaggeredFitment } = {},
      defaultVehicle,
      position,
      handleCloseRearTireSize,
      openRearTireSize,
      notTrailerPageOrTrailer,
      expanderToggleHandler: { onClick: expanderToggleHandler } = {},
      isRearTireSizeVisible,
      isModal,
      resetRearSizeFields,
      showPopularSizes
    } = this.props
    if ((prevVehicle && activeVehicle && prevFrontTireSize !== frontTireSize &&
      (!hasStaggeredFitment || prevRearTireSize !== rearTireSize)) ||
      prevShowPopularSizes !== showPopularSizes) {
      resetRearSizeFields({ isModal })
      initialize({ activeVehicle, defaultVehicle, position, notTrailerPageOrTrailer, isModal, showPopularSizes })
      if (hasStaggeredFitment && notTrailerPageOrTrailer) {
        if (!isRearTireSizeVisible) {
          expanderToggleHandler()
          openRearTireSize({ isModal })
        }
      } else if (isRearTireSizeVisible) {
        expanderToggleHandler()
        handleCloseRearTireSize({ isModal })
      }
    }
  }
})

const withTrailerPageInfo = withProps(({ activeVehicle: { vehicleType } = {}, isTrailerPage }) =>
  ({
    notTrailerPageOrTrailer: (!isTrailerPage || getIsTrailer({ vehicleType }))
  })
)

const withHandleNavigateToTiresResults = withHandlers({
  handleSubmit: ({ isModal, handleShopByTireSizesSubmit, submitTireSize, submit }) => () => {
    const { valid, tireSizes } = submit(isModal)
    if (valid) {
      const { front, rear } = tireSizes
      submitTireSize({ front, rear })
      handleShopByTireSizesSubmit(tireSizes)
    }
  }
})

const mapStatetoProps = state => ({
  activeVehicle: getActiveVehicle(state)
})

export default compose(
  connect(mapStatetoProps, { reset }),
  withExpanderToggle,
  withTireSize,
  withHandleNavigateToTiresResults,
  withTrailerPageInfo,
  withFetchingCustomTireSizes
)(ShopByTires)
