import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'
import Icon from 'components/common/Icon/Icon'
import PopoverContent from './PopoverContent'
import { DefaultPopoverLink, DefaultPopoverInnerContent, DefaultPopoverWrapper } from './elements'

const Popover = ({
  active,
  className,
  ariaLabel,
  title = '',
  info,
  setLinkRef,
  setPopoverWrapperRef,
  setPopoverContentRef,
  setInnerRef,
  handleContentFocus,
  toggleHandlers,
  popoverUID,
  asset = 'tooltip',
  alternative = 'tooltip-active',
  showIcon = false,
  small,
  components: {
    PopoverLink = DefaultPopoverLink,
    PopoverInnerContent = DefaultPopoverInnerContent,
    PopoverWrapper = DefaultPopoverWrapper
  } = {},
  innerProps: {
    popoverLinkProps = {},
    popoverInnerContentProps = {},
    popoverIconProps = {}
  } = {},
  onMouseEnter,
  onMouseLeave,
  ...props
}) =>
  <Wrapper ref={ setInnerRef } className={ className } >
    <PopoverWrapper ref={ setPopoverWrapperRef } id={ popoverUID } { ...dynamicDataAttribute('popover', toOneString(title)) }>
      <PopoverContent
        { ...props }
        components={{ PopoverInnerContent }}
        ref={ setPopoverContentRef }
        info={ info }
        id={ `${popoverUID}_content` }
        active={ active }
        aria-hidden={ !active }
        role="tooltip"
        tabIndex="-1"
        data-at-popover-inner-content
        onFocus={ handleContentFocus }
        { ...popoverInnerContentProps } />
    </PopoverWrapper>
    <PopoverLink
      ref={ setLinkRef }
      tabIndex="0"
      aria-label={ `${ariaLabel || title} tooltip` }
      role="button"
      data-at-popover-link
      { ...popoverLinkProps }
      { ...toggleHandlers }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }>
      { (!title || showIcon) && <TooltipIcon
        { ...popoverIconProps }
        condition={ active }
        asset={ asset }
        alternative={ alternative }
        data-tooltip
        small={ small }
        alt={ title || 'Tooltip' } />
      }
      { title }
    </PopoverLink>
  </Wrapper>

Popover.propTypes = {
  active: PropTypes.bool,
  alternative: PropTypes.string,
  ariaLabel: PropTypes.string,
  asset: PropTypes.string,
  className: PropTypes.string,
  components: PropTypes.shape(),
  content: PropTypes.node,
  handleContentFocus: PropTypes.func,
  info: PropTypes.string,
  innerProps: PropTypes.shape(),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  popoverUID: PropTypes.string,
  setInnerRef: PropTypes.func,
  setLinkRef: PropTypes.func,
  setPopoverContentRef: PropTypes.func,
  setPopoverWrapperRef: PropTypes.func,
  showIcon: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string,
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  white: PropTypes.bool
}

export default Popover

const Wrapper = styled.span`
  position: relative;
`

const TooltipIcon = styled(Icon)`
  margin: 0 10px 0 0;
  ${props => props.small && css`
    margin: -2px 6px 0 0;
    width: 14px;
    height: 14px;
  `}
`
