import React from 'react'

export default ({ type, placeholders }) => WrappedComponent => {
  const Wrapper = props => <WrappedComponent { ...props } />

  type && (Wrapper.type = type) // eslint-disable-line
  placeholders && (Wrapper.placeholders = placeholders) // eslint-disable-line

  return Wrapper
}
