import { show } from 'redux-modal'
import { rest, isApiError } from 'services'
import { getShoppingData, setShoppingData } from 'actions/shoppingData'
import { VEHICLE_MERGE_MODAL, CODE_MERGE_ERROR } from 'constant'

export const showVehicleMergeModal = props =>
  show(VEHICLE_MERGE_MODAL, props)

export const mergeApiDecorator = ({
  api,
  payload,
  handleSuccess,
  data,
  googleAnalyticEmailAddressFill
}) => (dispatch, getState) =>
  dispatch(getShoppingData(api, payload))
    .then(googleAnalyticEmailAddressFill({ ...data }))
    .then(handleSuccess)
    .catch(async err => {
      const isMergeRequired = isApiError(err) &&
        (err.statusList.some(({ code }) => code === CODE_MERGE_ERROR) ||
          (err.status && err.status.code === CODE_MERGE_ERROR))

      if (isMergeRequired) {
        // Updating cart state with login response _state
        const { _state, cartCount } = err.errorPayload
        dispatch(setShoppingData({
          _state,
          totalCount: cartCount
        }))
        // TODO get userVehicle from errorPayload and remove getActiveVehicle selector
        const {
          similarVehiclesVo: {
            similarVehicles,
            cookieVehicle
          } = {}
        } = await rest.api.getSimilarVehicles()

        return dispatch(showVehicleMergeModal({
          similarVehicles,
          anonymousVehicle: cookieVehicle,
          handleMergeComplete: handleSuccess // data is stored inside errorPayload
        }))
      }

      throw err
    })
