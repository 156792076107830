import axios from 'axios'
import { BAZAARVOICE_REVIEWS_API_PREFIX } from 'constant'

const API_VER = '5.4'
const BAZAARVOICE_API_URL = BAZAARVOICE_REVIEWS_API_PREFIX

const PARAMS = {
  apiversion: API_VER,
  stats: 'Reviews'
}

export const getBazaarVoiceItemReviews = (item, queryOptions) => {
  const url = BAZAARVOICE_API_URL
  const options = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET'
    },
    params: {
      ...PARAMS,
      ...queryOptions,
      Filter: `ProductId:${item}`
    }
  }

  return axios
    .get(url, options)
    .catch(err => {
      console.log('Error', err)
      // TODO add winston logger here to log all errors
      // TODO add service for managing errors
      throw new Error(err)
    })
}
