import { compose, withProps, lifecycle } from 'recompose'

const withSyncActiveStates = lifecycle({
  componentDidUpdate({ active: prevActive }) {
    const { setFocusHoverState, active, isInnerFocusedHovered } = this.props
    !prevActive && active && active !== isInnerFocusedHovered && setFocusHoverState(true)
  }
})

const withCombinedHandlers = withProps(({
  focusBlurHoverHandlers,
  toggleHandlers
}) => ({
  combinedHandlers: {
    ...focusBlurHoverHandlers,
    ...toggleHandlers
  }
}))

export default compose(
  withSyncActiveStates,
  withCombinedHandlers
)
