import { compose } from 'recompose'
import { connect } from 'react-redux'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { clearApplicationDataAndRefresh } from './actions'
import { APPOINMENT_SUBMISSION_ERROR_MODAL } from '../../constants'
import AppointmentSubmissionErrorModal from './AppointmentSubmissionErrorModal'

export default compose(
  registerReduxModal({ name: APPOINMENT_SUBMISSION_ERROR_MODAL }),
  connect(null, { clearApplicationDataAndRefresh })
)(AppointmentSubmissionErrorModal)
