import Theme from 'components/common/Theme/Theme'

export const KEY_EVENT_CODES = {
  enter: Theme.keyCode.ENTER,
  escape: Theme.keyCode.ESCAPE,
  space: Theme.keyCode.SPACE,
  tab: Theme.keyCode.TAB,
  clear: Theme.keyCode.CLEAR,
  home: Theme.keyCode.HOME,
  end: Theme.keyCode.END,
  up: Theme.keyCode.UP,
  down: Theme.keyCode.DOWN,
  right: Theme.keyCode.RIGHT,
  left: Theme.keyCode.LEFT
}

export const KEY_EVENT_STRINGS = {
  enter: Theme.keyString.ENTER,
  escape: Theme.keyString.ESCAPE,
  space: Theme.keyString.SPACE,
  tab: Theme.keyString.TAB,
  clear: Theme.keyString.CLEAR,
  home: Theme.keyString.HOME,
  end: Theme.keyString.END,
  up: Theme.keyString.UP,
  down: Theme.keyString.DOWN,
  right: Theme.keyString.RIGHT,
  left: Theme.keyString.LEFT
}
