import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'

const PromoBanner = ({
  promoImageContent,
  promotionTitle,
  testSrc = ''
}) =>
  <div data-at-promotion-banner>
    <Media.Desktop>
      <Banner src={ testSrc || `${promoImageContent}?$BOT_Promo_Detail_LP_Hero$` } alt={ promotionTitle } />
    </Media.Desktop>
    <Media.Mobile>
      <Banner src={ testSrc || `${promoImageContent}?$BOT-PromotionDetails-Banner-Mobile-w1023$` } alt={ promotionTitle } />
    </Media.Mobile>
  </div>

PromoBanner.propTypes = {
  promoImageContent: PropTypes.string,
  promotionTitle: PropTypes.string,
  testSrc: PropTypes.string
}

export default PromoBanner

const Banner = styled.img`
  display: block;
  width: 100%;
`
