import { withHandlers } from 'recompose'
import ReactSelectInput from './ReactSelectInput'


const withCombinedRefSetter = withHandlers({
  combinedRefSetter: ({ selectProps: { setKeypressScopeRef }, innerRef }) => (ref) => {
    innerRef && innerRef(ref)
    setKeypressScopeRef && setKeypressScopeRef(ref)
  }
})

export default withCombinedRefSetter(ReactSelectInput)
