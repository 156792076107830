import { PASSWORD_MIN_LENGTH } from './constants'

export const FIRST_NAME_ERROR_MESSAGE = 'Your First Name may only consist of up to 20 characters. Please try again.'
export const LAST_NAME_ERROR_MESSAGE = 'Your Last Name may only consist of up to 20 characters. Please try again.'
export const FIRST_NAME_EMPTY = 'Please enter your first name.'
export const LAST_NAME_EMPTY = 'Please enter your last name.'
export const ADDRESS_ERROR_MESSAGE = 'Please enter a valid Address.'
export const STATE_ERROR_MESSAGE = 'Please enter a valid State.'
export const CITY_ERROR_MESSAGE = 'Please enter a valid City.'
export const ZIP_CODE_ERROR_MESSAGE = 'Please enter a valid Zip Code.'
export const CELL_PHONE_ERROR_MESSAGE = 'Please enter a valid Phone Number.'
export const OTHER_PHONE_ERROR_MESSAGE = 'Please enter a valid Other Phone Number.'
export const ADDRESS_GLOBAL_ERROR_MESSAGE = 'Please enter a valid address.'
export const USPS_VALIDATE_ERROR_MESSAGE = 'USPS could not validate this address.'
export const PASSWORD_LENGTH = `Be at least ${PASSWORD_MIN_LENGTH} characters`
export const PASSWORD_LETTER = 'Be a combination of upper case and lower case characters'
export const PASSWORD_NUMBER = 'Be alphanumeric'
export const PASSWORD_SPECIAL_CHARACTER = 'Include at least one of the following special characters   *!@#$&?'
export const PASSWORD_MATCH = 'Match confirmation'
export const PASSWORD_EMPTY = 'Please enter your password.'
export const PRIMARY_PHONE_NUMBER_INVALID = 'Please enter a valid Phone Number.'
export const PRIMARY_PHONE_NUMBER_EMPTY = 'Please enter a valid Phone Number.'
export const EMAIL_EMPTY_MESSAGE = 'Please enter your email address.'
export const EMAIL_ERROR_MESSAGE = 'Please enter a valid email address.'
export const REGISTERED_EMAIL_ERROR_MESSAGE = 'Please enter your registered email address.'
export const ACCEPT_POLICY_MESSAGE = 'Agreement with our policy is required'
export const MILEAGE_ERROR_MESSAGE = 'Mileage may only consist of numbers. Please try again.'
export const MESSAGE_FIELD_EMPTY = 'Please enter your message'

// Find lower price
export const FIND_LOWER_PRICE_FIRST_NAME_EMPTY = 'Please enter your first name.'
export const FIND_LOWER_PRICE_LAST_NAME_EMPTY = 'Please enter your last name.'
export const FIND_LOWER_PRICE_PRICE_VALUE_EMPTY = 'Please specify a lower price you found.'
export const FIND_LOWER_PRICE_LINK_EMPTY = 'Please specify a link to the site.'
export const FIND_LOWER_PRICE_PHONE_EMPTY = 'Please enter your phone number.'
export const FIND_LOWER_PRICE_PHONE_INVALID = 'Please enter a valid phone number.'
export const FIND_LOWER_PRICE_EMAIL_EMPTY = 'Please enter your email address.'
export const FIND_LOWER_PRICE_EMAIL_INVALID = 'Please enter a valid email address.'
export const FIND_LOWER_PRICE_ZIP_CODE_EMPTY = 'Please enter your zip code.'
export const FIND_LOWER_PRICE_ZIP_CODE_INVALID = 'Please enter a valid Zip Code.'
export const FIND_LOWER_PRICE_PRICE_HIGHER_THAN_LOWER_PRICE = 'Please enter a lower price than'

// Service history
export const SERVICE_HISTORY_EMAIL_INVALID = 'Please enter a valid email address.'
export const SERVICE_HISTORY_EMAIL_NOT_ACCOUNT_EMAIL = 'Please enter in the user email address associated with your Big O Tires account.'
export const FIND_SERVICE_HISTORY_LICENSE_PLATE_NUMBER_INVALID = 'Please enter a valid License Plate Number.'
export const FIND_SERVICE_HISTORY_VIN_INVALID = 'Please enter a valid VIN.'
