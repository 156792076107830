import { createAction } from 'redux-actions'

export const setModalData = createAction('GLOBAL_SEARCH_MODAL/SET_MODAL_DATA')

export const resetModal = createAction('GLOBAL_SEARCH_MODAL/RESET_MODAL')

export const clearSearchData = createAction('GLOBAL_SEARCH_MODAL/CLEAR_SEARCH_DATA')

export const throwSearchError = createAction('GLOBAL_SEARCH_MODAL/THROW_SEARCH_ERROR')

export const clearSearchError = createAction('GLOBAL_SEARCH_MODAL/CLEAR_SEARCH_ERROR')

export const setSearchData = createAction('GLOBAL_SEARCH_MODAL/SET_SEARCH_DATA')

export const setSearchAutoSuggestions = createAction('GLOBAL_SEARCH/SET_SEARCH_AUTOSUGGESTIONS')

export const clearSearchAutoSuggestions = createAction('GLOBAL_SEARCH/CLEAR_SEARCH_AUTOSUGGESTIONS')
