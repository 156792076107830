import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import getComponent from 'helpers/component-helper'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'

const FindLowerPriceModal = ({
  handleHide,
  submitFindLowerPriceForm,
  isSubmitButtonDisabled,
  data: { modalBoxContent = []} = {}
}) =>
  <CustomModal title="Find a lower Price" closeHandler={ handleHide }>
    {
      modalBoxContent.map((content, index) =>
        React.cloneElement(getComponent(content), { key: index }))
    }
    <Controls>
      <CustomButton data-at-lower-price-submit-button full="mobile" disabled={ isSubmitButtonDisabled } onClick={ submitFindLowerPriceForm }>Submit</CustomButton>
      <CustomButton data-at-lower-price-cancel-button full="mobile" link onClick={ handleHide }>Cancel</CustomButton>
    </Controls>
  </CustomModal>

export default FindLowerPriceModal

FindLowerPriceModal.propTypes = {
  data: PropTypes.shape(),
  handleHide: PropTypes.func,
  isSubmitButtonDisabled: PropTypes.bool,
  submitFindLowerPriceForm: PropTypes.func
}

const CustomModal = styled(Modal)`
  ${Mixin.responsive('padding', '25px 24px 67px', '60px 10px 67px')}
`

const Controls = styled.div`
  margin-top: 24px;
  text-align: left;
`

const CustomButton = styled(Button)`
  ${Media.mobile`
    margin-bottom: 10px;
    & + & {
      margin-left: 0;
    }
  `}
`
