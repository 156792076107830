export const getSearchByAddressParams = coords => {
  if (coords) {
    const { latitude, longitude } = coords

    return {
      params: {
        latitude,
        longitude
      }
    }
  }

  return {}
}

export const processStoreNumFromURL = pathname => {
  let storeNum = '000000'
  try {
    const splitPathname = pathname.split('/')

    storeNum = splitPathname[splitPathname.length - 1] || storeNum
  } catch (e) {
    console.log(e)
  }
  return storeNum
}

export const generateAddress = ({ storeNumber = '', address: { address1 = '', city = '', state = '', zipcode = '' } = {}} = {}) =>
  storeNumber ? `${address1}, ${city},  ${state} ${zipcode},  [#${storeNumber}]` : ''


export const getQueryParamValue = (parameterName) => {
  let params = ''

  if (window && window.location && window.location.search) {
    params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
  }

  return params[parameterName]
}

export const isPageNotForProcessingByUrlList = (pagesList) => {
  if (window && window.location && window.location.href) {
    const isPageNotForProcessing = pagesList.some(page => window.location.href.includes(page))

    return isPageNotForProcessing
  }
}
