import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import Button from 'components/common/Button/Button'
import PopoverContent from '../PopoverContent'

const LicensePlateInput = ({
  toggleHandlers,
  active,
  setPopoverContentRef,
  handleContentFocus,
  setLinkRef,
  isFromTrailer
}) =>
  <TooltipWrapper>
    <TooltipInnerWrapper>
      <CustomPopover
        aria-label="Find my Tire Size tooltip"
        role="button"
        data-at-popover-link
        ref={ setLinkRef }
        { ...toggleHandlers }>
        <PopoverIcon
          asset={ active ? 'tooltip-active' : 'tooltip' } />
        Find my Tire Size
      </CustomPopover>
      <PopoverContent
        ref={ setPopoverContentRef }
        active={ active }
        handleContentFocus={ handleContentFocus }
        isFromTrailer={ isFromTrailer } />
    </TooltipInnerWrapper>
  </TooltipWrapper>

export default LicensePlateInput

LicensePlateInput.propTypes = {
  active: PropTypes.bool,
  handleContentFocus: PropTypes.func,
  isFromTrailer: PropTypes.bool,
  setLinkRef: PropTypes.func,
  setPopoverContentRef: PropTypes.func,
  toggleHandlers: PropTypes.bool,
  toggleTooltipHandler: PropTypes.func
}

const TooltipWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 185px;
  z-index: 10;

  @media (max-width: 1335px) {
    position: static;
    margin: 10px 0 20px 20px;
  }
`

const CustomPopover = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${Theme.colors.transparent};
  border: none;
  position: relative;
  color: #1262ac;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  transform: none;
  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
    background-color: ${Theme.colors.transparent};
    color: #1262ac;
  }
`

const PopoverIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`

const TooltipInnerWrapper = styled.div`
  position: relative;
`
