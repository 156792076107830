import React from 'react'
import { Row } from 'components/common/FormElements/FormStyles'
import { Input } from 'components/common/FormElements/Input'

const renderFormInput = props =>
  <Row>
    <Input { ...props } />
  </Row>

export default renderFormInput
