import { compose } from 'recompose'
import { connect } from 'react-redux'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import registerModalPage from '../common/hoc/registerModalPage'
import FindLowerPriceModal from './FindLowerPriceModal'
import {
  FIND_LOWER_PRICE_MODAL,
  FIND_LOWER_PRICE_MODAL_ROOT_TYPE
} from './constants'
import { submitFindLowerPriceForm } from './components/BOTSkuFindLowerPriceContentInfoForm/actions'
import { isFindLowerPriceSubmitting } from './components/BOTSkuFindLowerPriceContentInfoForm/selectors'

export { default as BOTSkuFindLowerPriceInfo } from './components/BOTSkuFindLowerPriceInfo'
export { default as BOTSkuFindLowerPriceContentInfoForm } from './components/BOTSkuFindLowerPriceContentInfoForm'
export { default as BOTSkuFindLowerPriceDescription } from './components/BOTSkuFindLowerPriceDescription'

export default compose(
  connect(
    state => ({
      isSubmitButtonDisabled: isFindLowerPriceSubmitting(state)
    }),
    { submitFindLowerPriceForm }
  ),
  registerReduxModal({ name: FIND_LOWER_PRICE_MODAL }),
  registerModalPage({
    pageSrc: ({ pageSrc }) => pageSrc,
    rootType: FIND_LOWER_PRICE_MODAL_ROOT_TYPE
  })
)(FindLowerPriceModal)
