import { compose } from 'redux'
import { withHandlers, lifecycle } from 'recompose'
import withTireSize from '../../../withTireSize'
import CustomTireSize from './CustomTireSize'
import { extractSizes } from 'helpers/createTireSizeString'

const withFetchingWidthOnDidMount = lifecycle({
  componentDidMount() {
    const { isModal, setDefaultState } = this.props
    setDefaultState({ isModal })
  }
})

/* eslint-disable max-len */
const withHandleCustomTireSizeSearch = withHandlers({
  handleCustomTireSizeSearch: ({ isModal, vehicle, handleTiresSelect, submit }) => () => {
    const { valid, tireSizes } = submit(isModal)
    if (valid) {
      const {
        tireFrontSize,
        tireRearSize,
        loadIndex,
        rearLoadIndex,
        speedRating,
        rearSpeedRating
      } = extractSizes(tireSizes)
      // TODO tireFrontNotation needs to be changed
      handleTiresSelect({
        vehicle,
        frontTireSize: tireFrontSize,
        rearTireSize: tireRearSize,
        loadIndex,
        rearLoadIndex,
        speedRating,
        rearSpeedRating
      })
    }
  }
})

const withHandleCloseDrawer = withHandlers({
  handleCloseDrawer: ({ isModal, handleCloseRearTireSize }) => () => {
    handleCloseRearTireSize({ isModal })
  }
})

export default compose(
  withTireSize,
  withHandleCustomTireSizeSearch,
  withHandleCloseDrawer,
  withFetchingWidthOnDidMount
)(CustomTireSize)
