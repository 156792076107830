/* eslint-disable no-prototype-builtins, react/no-find-dom-node */
import { findDOMNode } from 'react-dom'
import {
  POPOVER_TOP_OFFSET,
  POPOVER_BOTTOM_OFFSET,
  POPOVER_WIDTH,
  TICK_WIDTH,
  POPOVER_PADDING_ADJUSTMENT
} from './constants'
import { HEADER_ID, HEADER_STORE_ID, CAR_BAR_ELEMENT_ID, MODAL_CONTENT_ID } from 'constant'

export const getRefPosition = ref => findDOMNode(ref).getClientRects()[0] || {}

export const toggleFlipBehavior = ({
  dimensions: {
    popoverContentHeight = 0,
    popoverWrapperTop = 0,
    linkHeight = 0
  } = {}
}) => {
  const { offsetHeight: headerElemHeight = 0 } = document.getElementById(HEADER_ID) || {}
  const { offsetHeight: carBarElemHeight = 0 } = document.getElementById(CAR_BAR_ELEMENT_ID) || {}
  const { offsetHeight: myStoreElemHeight = 0 } = document.getElementById(HEADER_STORE_ID) || {}

  const showOnBottom = popoverWrapperTop - (headerElemHeight + myStoreElemHeight + carBarElemHeight) -
    popoverContentHeight < 30

  return {
    showOnBottom,
    topOrBottomOffset: showOnBottom
      ? POPOVER_BOTTOM_OFFSET
      : linkHeight + POPOVER_TOP_OFFSET
  }
}

const getDimensions = (linkRef, popoverWrapperRef, popoverContentRef) => {
  const {
    height: linkHeight,
    right: linkRight,
    left: linkLeft,
    width: linkWidth
  } = getRefPosition(linkRef)
  const {
    top: popoverWrapperTop,
    left: popoverWrapperLeft,
    right: popoverWrapperRight
  } = getRefPosition(popoverWrapperRef)
  const {
    width: popoverContentWidth = POPOVER_WIDTH,
    height: popoverContentHeight
  } = getRefPosition(popoverContentRef)
  const modalContainer = document.getElementById(MODAL_CONTENT_ID)
  const { right: windowRightEdge = window.innerWidth } = (modalContainer && modalContainer.getClientRects()[0]) || {}
  const popoverContentRight = popoverWrapperLeft + popoverContentWidth
  return {
    linkHeight,
    linkRight,
    linkLeft,
    linkWidth,
    popoverWrapperTop,
    popoverWrapperLeft,
    popoverWrapperRight,
    popoverContentWidth,
    popoverContentRight,
    popoverContentHeight,
    windowRightEdge
  }
}

const getLeftPositions = ({
  centered,
  dimensions: {
    linkWidth,
    linkLeft,
    popoverWrapperLeft,
    popoverContentWidth,
    popoverContentRight,
    windowRightEdge
  } = {}
}) => {
  let tooltipLeft = linkLeft <= POPOVER_PADDING_ADJUSTMENT ? 0 : 0 - POPOVER_PADDING_ADJUSTMENT
  let tickLeftPosition = linkLeft <= POPOVER_PADDING_ADJUSTMENT && linkWidth < (TICK_WIDTH + POPOVER_PADDING_ADJUSTMENT)
    ? 0 : POPOVER_PADDING_ADJUSTMENT

  // CENTERED PROP
  if (centered) {
    const calculatedPosition = (linkWidth - popoverContentWidth) / 2

    const overhangRight = (popoverContentRight + POPOVER_PADDING_ADJUSTMENT) - windowRightEdge
    const diffRight = Math.max(0, overhangRight - Math.abs(calculatedPosition))
    const overhangLeft = popoverWrapperLeft - POPOVER_PADDING_ADJUSTMENT
    const diffLeft = Math.max(0, Math.abs(calculatedPosition) - overhangLeft)

    const centeredTickPosition = (popoverContentWidth - linkWidth) / 2

    tickLeftPosition = (centeredTickPosition + diffRight) - diffLeft
    tooltipLeft = (calculatedPosition - diffRight) + diffLeft
    if (tickLeftPosition < 0) {
      tooltipLeft += tickLeftPosition
      tickLeftPosition = 1
    }
  }

  return {
    tickLeftPosition,
    tooltipLeft
  }
}

const getRightPositions = ({
  title,
  centered,
  dimensions: {
    linkRight,
    popoverWrapperRight,
    popoverContentWidth,
    popoverWrapperLeft,
    windowRightEdge
  } = {}
}) => {
  const tooltipRight = linkRight > popoverContentWidth ? popoverWrapperRight - linkRight :
    popoverWrapperRight - popoverContentWidth - (title ? 0 : 16)

  return {
    tooltipRight,
    toggleRightAlign: !centered && popoverWrapperLeft + popoverContentWidth > windowRightEdge,
    tickRightPosition: tooltipRight ? 0 - tooltipRight - 14 : POPOVER_PADDING_ADJUSTMENT
  }
}

export const computePopoverPositioning = ({
  linkRef,
  popoverWrapperRef,
  popoverContentRef,
  title,
  centered,
  showOnTop
}) => {
  const dimensions = getDimensions(linkRef, popoverWrapperRef, popoverContentRef)

  const { tooltipLeft, tickLeftPosition } = getLeftPositions({ centered, dimensions })
  const { tooltipRight, tickRightPosition, toggleRightAlign } = getRightPositions({ title, centered, dimensions })
  const { showOnBottom: defaultShowOnBottom, topOrBottomOffset } = toggleFlipBehavior({ dimensions })
  const showOnBottom = showOnTop ? false : defaultShowOnBottom

  return {
    top: showOnBottom && topOrBottomOffset,
    right: toggleRightAlign && tooltipRight,
    bottom: !showOnBottom && topOrBottomOffset,
    left: !toggleRightAlign && tooltipLeft,
    tickRight: toggleRightAlign && tickRightPosition,
    tickLeft: !toggleRightAlign && tickLeftPosition
  }
}
