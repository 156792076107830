import { handleActions } from 'redux-actions'
import { register, unregister, changeType } from './actions'
import { TEXT, PASSWORD } from './constants'

// initial input state
export const inputState = {
  type: PASSWORD
}

/* eslint-disable no-unused-vars */
export default handleActions({
  [register]: (state, { payload: { name }}) => ({
    ...state,
    [name]: {
      ...inputState
    }
  }),

  [unregister]: (state, { payload: { name }}) => {
    const { [name]: removedInputName, ...newState } = state
    return newState
  },

  [changeType]: (state, { payload: { name }}) => ({
    ...state,
    [name]: {
      ...state[name],
      type: state[name].type === TEXT ? PASSWORD : TEXT
    }
  })
}, {})
