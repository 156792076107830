import React from 'react'
import PropTypes from 'prop-types'
import ButtonNonStaggered from '../ButtonNonStaggered'
import EquipmentOption from '../EquipmentOption'
import OriginalEquipment from '../OriginalEquipment'
import {
  TireSizeListWrapper,
  Wrapper
} from './TireSizeList.styles'

// TODO refactor this component
const TireSizeList = ({ staggeredTireSizes, nonStaggeredTireSizes, handleTireSizeConfirmation }) =>
  <TireSizeListWrapper>
    {
      Object.keys(nonStaggeredTireSizes).map(diameter =>
        <Wrapper key={ diameter }>
          <OriginalEquipment diameter={ diameter } />
          {
            nonStaggeredTireSizes[diameter].map((size, index) =>
              <ButtonNonStaggered
                { ...size }
                data-at-ymme-tiresize
                key={ index }
                onClick={ () => handleTireSizeConfirmation(size) } />
            )
          }
        </Wrapper>
      )
    }
    {
      staggeredTireSizes.map((size, index) =>
        <EquipmentOption
          { ...size }
          data-at-ymme-tiresize
          key={ index }
          staggered
          onClick={ () => handleTireSizeConfirmation(size) } />
      )
    }
  </TireSizeListWrapper>

TireSizeList.propTypes = {
  handleTireSizeConfirmation: PropTypes.func,
  nonStaggeredTireSizes: PropTypes.shape(),
  staggeredTireSizes: PropTypes.arrayOf(PropTypes.object)
}

export default TireSizeList
