import Theme from 'components/common/Theme/Theme'

const SELECT_KEYS = [
  Theme.keyCode.ENTER,
  Theme.keyCode.SPACE
]

export const getOnClickAndOnKeyPressDownEvents = handler => ({
  onClick: handler,
  onKeyDown: e => {
    if (SELECT_KEYS.includes(e.keyCode)) {
      e.preventDefault()
      handler(e)
    }
  }
})
