import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/common/Link/Link'

const ShopByVehicleLink = ({ displayName, name, handleShopByVehicleClick }) =>
  <Link to="/" data-at-shop-by-vehicle-link onClick={ handleShopByVehicleClick }>
    { displayName || name }
  </Link>

ShopByVehicleLink.propTypes = {
  displayName: PropTypes.string,
  handleShopByVehicleClick: PropTypes.func,
  name: PropTypes.string
}

export default ShopByVehicleLink

