import { compose } from 'recompose'
import PromotionsDetailModalPage from './PromotionsDetailModalPage'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import PromotionDetailsPage from '../PromotionDetailsPage'
import registerModalPage from 'src/components/common/hoc/registerModalPage'
import { PROMOTIONS_DETAIL_MODAL_PAGE } from 'src/constant'

export default compose(
  registerReduxModal({ name: PROMOTIONS_DETAIL_MODAL_PAGE }),
  registerModalPage({
    pageSrc: ({ pageSrc }) => pageSrc,
    rootType: PromotionDetailsPage.type
  })
)(PromotionsDetailModalPage)
