import { createSelector } from 'helpers/reselect'
import { TRAILER_TYPE, TRAILER_NAME } from 'constant'

const getActiveVehicle = ({
  app: {
    data: {
      userdata: {
        mygarage: {
          activeVehicleDetails = {}
        } = {}
      } = {}
    } = {}
  } = {}
} = {}) => activeVehicleDetails

export default createSelector(
  [getActiveVehicle],
  activeVehicleDetails => activeVehicleDetails
)

export const getIsActiveVehicleTrailer = createSelector(
  [getActiveVehicle],
  ({ vehicleType } = {}) => vehicleType === TRAILER_TYPE
)

export const getActiveVehicleName = createSelector(
  [
    getActiveVehicle,
    getIsActiveVehicleTrailer
  ],
  ({ yearDesc, makeDesc, modelDesc, subModelDesc } = {}, isActiveVehicleTrailer) =>
    isActiveVehicleTrailer ?
      TRAILER_NAME : `${yearDesc} ${makeDesc} ${modelDesc} ${subModelDesc}`
)

export const getActiveVehicleTireSize = createSelector(
  [getActiveVehicle],
  ({ frontTireSize, rearTireSize }) =>
    rearTireSize ?
      `${frontTireSize} - ${rearTireSize}` : frontTireSize
)

export const getActiveVehicleFrontTireSize = createSelector(
  [getActiveVehicle],
  ({ frontTireSize }) => frontTireSize
)

export const getActiveVehicleId = createSelector(
  [getActiveVehicle],
  ({ vehicleId } = {}) => vehicleId
)

export const getActiveVehicleNickname = createSelector(
  [getActiveVehicle],
  ({ vehicleNickName } = {}) => vehicleNickName
)
