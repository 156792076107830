import { compose, lifecycle, withHandlers, withState, withProps } from 'recompose'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import NewTireSize from './NewTireSize'
import {
  initialize,
  handleSelectValue,
  handlerTireSizeResetOnPrevStep,
  navigateToSERPPage,
  navigateToTrailerSERPPage
} from './actions'
import { reset } from '../VehicleConfigurator/components/withTireSize/actions'
import { DIAMETER, FRONT, REAR } from '../VehicleConfigurator/components/withTireSize/constants'
import {
  FRONT_TIRE_SIZE_STEP_NAMES,
  REAR_TIRE_SIZE_STEP_NAMES,
  STEP_NAMES_FOR_STEPPER_RESET,
  MOST_COMMON_ENDPOINT_MIN,
  MOST_COMMON_ENDPOINT_MAX,
  MOST_COMMON_ENDPOINT_ALL_MAX,
  MOST_UNCOMMON_ENDPOINT_MIN,
  MOST_UNCOMMON_ENDPOINT_MAX,
  SHOW_ALL_SIZES,
  HIDE_ALL_SIZES
} from '../NewTireSearch/constant'
import {
  resetTireSizePickedValues,
  getOptions
} from '../NewTireSearch/helpers'
import { hideNewShopTiresModal } from '../NewShopTiresModal/actions'
import { TRAILER_SOURCE } from 'constant'
import { formatTireSize } from 'components/OneColumnSearchResultPage/helpers'
import { setDefaultSizeName, handleSelectAllValues } from './helpers'

const withDataLoading = withState('dataLoading', 'setDataLoading', false)
const stepperStepState = withState('step', 'setStep', 0)
const tireSizeSelectorState = withState('optionsData', 'setOptionsData', [])
const tireSizeSelectorEndpointState = withState('endpointData', 'setEndpointData', [])
const searchInputValueState = withState('search', 'setSearch', '')
const withSizeNameState = withState(
  'sizeName',
  'setSizeName',
  ({ ...props }) => setDefaultSizeName({ ...props })
)
const withRearTireSizeCheckedState = withState('rearTireSizeChecked', 'setRearTireSizeChecked', false)
const withActivePositionState = withState('activePosition', 'setActivePosition', FRONT)
const withStepNamesState = withState('stepNames', 'setStepNames', FRONT_TIRE_SIZE_STEP_NAMES)
const withFullFrontTireSizeLabel = withState('fullFrontTireSizeLabel', 'setFullFrontTireSizeLabel', '')
const withAllSizesState = withState('isShowAllSizes', 'setIsShowAllSizes', false)

const tireState = withState('data', 'setData', {
  front: {
    width: null,
    ratio: null,
    diameter: null
  },
  rear: {
    width: null,
    ratio: null,
    diameter: null
  }
})

const withFetchingCustomTireSizes = lifecycle({
  componentDidMount() {
    const {
      initialize,
      setDataLoading,
      setOptionsData,
      setEndpointData,
      reset,
      sizeName,
      isFromTrailer,
      isAddRearSize,
      setFullFrontTireSizeLabel,
      setStepNames,
      setData,
      tireSizePreData = {},
      setStep,
      setRearTireSizeChecked,
      setActivePosition,
      isRemoveRearSize,
      setSizeName,
      handleSelectValue,
      isEditTireSize,
      isEditRearSize
    } = this.props
    reset()
    const {
      front: { width: frontWidth, ratio: frontRatio, diameter: frontDiameter } = {}
    } = tireSizePreData
    const frontTireSizesLabel = formatTireSize(frontWidth?.label, frontRatio?.label, frontDiameter?.label)
    const lastFrontStepIndex = 2

    if (isAddRearSize && isEditTireSize) {
      handleSelectAllValues({ handleSelectValue, tireSizePreData })
      setFullFrontTireSizeLabel(frontTireSizesLabel)
      setStepNames(REAR_TIRE_SIZE_STEP_NAMES)
      setData(tireSizePreData)
      setStep(3)
      setRearTireSizeChecked(true)
      setActivePosition(REAR)
    }

    if (isRemoveRearSize && isEditTireSize) {
      handleSelectAllValues({ handleSelectValue, tireSizePreData })
      setSizeName(FRONT_TIRE_SIZE_STEP_NAMES[lastFrontStepIndex - 1].value)
      setData(tireSizePreData)
      setStep(lastFrontStepIndex)
    }

    if (isEditRearSize && isEditTireSize) {
      handleSelectAllValues({ handleSelectValue, tireSizePreData })
      setFullFrontTireSizeLabel(frontTireSizesLabel)
      setStepNames(REAR_TIRE_SIZE_STEP_NAMES)
      setData(tireSizePreData)
      setStep(5)
      setRearTireSizeChecked(true)
      setActivePosition(REAR)
    }

    if (!isRemoveRearSize && !isEditTireSize) {
      setDataLoading(true)
      initialize({
        name: sizeName,
        setDataLoading,
        setOptionsData,
        setEndpointData,
        isFromTrailer,
        isRemoveRearSize
      })
    }
  },
  componentDidUpdate({
    search: prevSearch,
    data: prevSizes,
    rearTireSizeChecked: prevRearTireSizeChecked,
    step: prevStep
  }) {
    const {
      search,
      setEndpointData,
      optionsData,
      data,
      setDataLoading,
      setOptionsData,
      initialize,
      sizeName,
      activePosition,
      step,
      rearTireSizeChecked,
      setActivePosition,
      setStepNames,
      setSearch,
      setData,
      setStep,
      handlerTireSizeResetOnPrevStep,
      stepNames,
      setSizeName,
      setIsShowAllSizes,
      isFromTrailer
    } = this.props

    if (!isEqual(step, prevStep)) {
      setIsShowAllSizes(false)
    }

    if (!isEqual(prevSizes, data)) {
      setDataLoading(true)
      initialize({
        name: sizeName,
        position: activePosition,
        setDataLoading,
        setOptionsData,
        setEndpointData,
        isFromTrailer
      })
    }

    if (prevRearTireSizeChecked !== rearTireSizeChecked && !rearTireSizeChecked && step > 2) {
      const indexForSizeNameReset = 1
      const lastFrontStepIndex = 2
      const fullListOfStepNames = [...FRONT_TIRE_SIZE_STEP_NAMES, ...REAR_TIRE_SIZE_STEP_NAMES]

      setActivePosition(FRONT)
      setStepNames(FRONT_TIRE_SIZE_STEP_NAMES)
      setSearch('')
      setData(resetTireSizePickedValues({
        data,
        clickedIndex: lastFrontStepIndex,
        stepNames: fullListOfStepNames
      }))
      setStep(2)
      setSizeName(stepNames[indexForSizeNameReset].value)
      handlerTireSizeResetOnPrevStep({
        positions: [FRONT, REAR],
        names: STEP_NAMES_FOR_STEPPER_RESET
      })
    }

    if (!search && !isEqual(prevSearch, search)) {
      setEndpointData(optionsData)
      setIsShowAllSizes(false)
    }

    if (!!search && !isEqual(prevSearch, search)) {
      setIsShowAllSizes(true)
    }
  }
})

const withNewTireSizeModalHandlers = withHandlers({
  onSubmit: ({
    navigateToSERPPage,
    handleSelectValue,
    activePosition,
    isFromTrailer,
    navigateToTrailerSERPPage,
    queryParams = ''
  }) => (value) => {
    const endpointValue = value.value
    handleSelectValue({ position: activePosition, name: DIAMETER, value: endpointValue })

    if (isFromTrailer) {
      navigateToTrailerSERPPage()
    }

    if (!isFromTrailer) {
      navigateToSERPPage({ queryParams })
    }
  },
  toggleCheckbox: ({ setRearTireSizeChecked, rearTireSizeChecked }) => () => {
    setRearTireSizeChecked(!rearTireSizeChecked)
  },
  allSizesToggleHandler: ({ setIsShowAllSizes, isShowAllSizes }) => () => setIsShowAllSizes(!isShowAllSizes)
})

const withNewTireSizeProps = withProps(({
  endpointData,
  step,
  isShowAllSizes
}) => {
  const isWidthStep = step === 0 || step === 3

  return {
    mostCommonEndpointData: getOptions({
      isWidthStep,
      endpointData,
      min: MOST_COMMON_ENDPOINT_MIN,
      max: MOST_COMMON_ENDPOINT_MAX
    }),
    mostCommonEndpointAllData: getOptions({
      isWidthStep,
      endpointData,
      min: MOST_COMMON_ENDPOINT_MIN,
      max: MOST_COMMON_ENDPOINT_ALL_MAX,
      includeAlphaSizes: true
    }),
    mostUncommonEndpointData: getOptions({
      isWidthStep,
      endpointData,
      min: MOST_UNCOMMON_ENDPOINT_MIN,
      max: MOST_UNCOMMON_ENDPOINT_MAX
    }),
    showSizesButtonLabel: !isShowAllSizes ? SHOW_ALL_SIZES : HIDE_ALL_SIZES,
    isWidthStep
  }
})

const withInfo = withProps(({
  source,
  tireSizePreData = {}
}) => ({
  isFromTrailer: source === TRAILER_SOURCE,
  isEditTireSize: Boolean(Object.keys(tireSizePreData).length)
}))

export default compose(
  connect(null, {
    initialize,
    reset,
    handleSelectValue,
    handlerTireSizeResetOnPrevStep,
    navigateToSERPPage,
    hideNewShopTiresModal,
    navigateToTrailerSERPPage
  }),
  tireSizeSelectorState,
  withInfo,
  tireSizeSelectorEndpointState,
  withDataLoading,
  stepperStepState,
  tireState,
  searchInputValueState,
  withSizeNameState,
  withRearTireSizeCheckedState,
  withActivePositionState,
  withStepNamesState,
  withFullFrontTireSizeLabel,
  withAllSizesState,
  withFetchingCustomTireSizes,
  withNewTireSizeModalHandlers,
  withNewTireSizeProps
)(NewTireSize)
