import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ScreenReader = ({ id, children }) => <span id={ id } data-sr-only>{ children }</span>

ScreenReader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  id: PropTypes.string
}

export const NonScreenReader = ({ id, withoutMouseEvents, children }) =>
  <Span id={ id } withoutMouseEvents={ withoutMouseEvents } aria-hidden="true">{ children }</Span>

NonScreenReader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  id: PropTypes.string,
  withoutMouseEvents: PropTypes.bool
}

const Span = styled.span`
  pointer-events: ${(props) => props.withoutMouseEvents ? 'none' : null};
`
