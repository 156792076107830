import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'


const FrontTireSizePlaceholder = ({
  placeholderTitle,
  onClick,
  className
}) =>
  <PlaceholderButton className={ className } onClick={ onClick }>
    <CheckCircleIcon asset="check-circle" />
    {placeholderTitle}
    <EditIcon asset="edit-green" />
  </PlaceholderButton>

export default FrontTireSizePlaceholder

FrontTireSizePlaceholder.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  placeholderTitle: PropTypes.string
}

const PlaceholderButton = styled.button`
  position: relative;
  background: transparent;
  border: none;
  color: ${Theme.colors.light_green};
  font-size: 18px;
  cursor: pointer;

  &::before {
    content: 'Front Tire Size';
    position: absolute;
    top: -20px;
    left: 0;
    font-size: 12px;
    width: 95px;
    text-transform: capitalize;
    color: ${Theme.colors.gainsboro};
  }

  @media (max-width: 500px) {
    display: flex;
    font-size: 16px;
  }
`

const CheckCircleIcon = styled(Icon)`
  margin-right: 10px;

  @media (max-width: 500px) {
    margin-right: 5px;
    height: 16px;
  }
`

const EditIcon = styled(Icon)`
  height: 18px;
  margin-left: 5px;
`
