import { createSelector } from 'helpers/reselect'

const getPageLocation = ({
  router: { location },
  SSR: { location: sSRLocation = {}} = {}
}) => location || sSRLocation

export const getLocationState = createSelector(
  [getPageLocation],
  ({ state }) => state
)

export const getLocationPathname = createSelector(
  [getPageLocation],
  ({ pathname }) => pathname
)

export const getLocationSearch = createSelector(
  [getPageLocation],
  ({ search }) => search
)

export const getLocationHash = createSelector(
  [getPageLocation],
  ({ hash }) => hash
)

export const getQueryParams = createSelector(
  [getPageLocation],
  ({ query }) => query
)

export default getPageLocation
