import { compose, withState, lifecycle, withHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { TIRE_SIZES_NOT_FOUND_MODAL } from 'constant'
import NewYMME from './NewYMME'
import {
  initialize,
  handleYearSelect,
  handleMakeSelect,
  handleModelSelect,
  handleSubModelSelect,
  submit,
  handleYmmeReset,
  handlerYmmeResetOnPrevStep,
  trigerConfirmTireSizeModal
} from './actions'
import { hideNewShopTiresModal } from '../NewShopTiresModal/actions'
import { makeGetYmmeState } from '../common/hoc/withYmme/selectors'
import { hide } from 'redux-modal'
import { getTireSizes, navigateToConfirmTireSize } from '../VehicleConfigurator/actions'
import { SUB_MODELS } from '../NewTireSearch/constant'
import { refresh } from '../../actions/router'

const withDataLoading = withState('dataLoading', 'setDataLoading', false)
const stepperStepState = withState('step', 'setStep', 0)
const ymmeSelectorState = withState('optionsData', 'setOptionsData', [])
const ymmeSelectorEndpointState = withState('endpointData', 'setEndpointData', [])
const searchInputValueState = withState('search', 'setSearch', '')
const vehicleState = withState('data', 'setData', {
  year: null,
  make: null,
  model: null,
  subModel: null
})
const preVehicleDetailsLoadingStatusState = withState(
  'preVehicleDetailsLoadingStatus',
  'setPreVehicleDetailsLoadingStatus',
  ({ vehiclePreData = {}}) => Boolean(Object.keys(vehiclePreData).length)
)

const makeMapStateToProps = () => {
  const getYmmeState = makeGetYmmeState()
  const mapStateToProps = (state, props) => getYmmeState(state, props)

  return mapStateToProps
}

const withResetYMME = withHandlers({
  resetYmme: ({ handleYmmeReset, ymmeName }) => () =>
    handleYmmeReset({ ymmeName })
})

const withSubmitHandlers = withHandlers({
  onSubmit: ({
    handleSubModelSelect,
    ymmeName,
    submit,
    getTireSizes,
    navigateToConfirmTireSize,
    hideNewShopTiresModal,
    setDataLoading,
    withShowConfirmTireSizeModal,
    trigerConfirmTireSizeModal,
    source,
    promo,
    skipTireSizeExperience,
    refresh,
    isOnlyVehicle
  }) => (value) => {
    const endpointValue = value.value
    let vehicleId
    setDataLoading(true)

    handleSubModelSelect({ ymmeName, value: endpointValue })
      .then(data => {
        vehicleId = data
      })

    submit({ ymmeName, skipTireSizeExperience })
      .then(() => getTireSizes({
        vehicleId,
        tireSizesNotFoundModalProps: {
          onCancelClick: () => hide(TIRE_SIZES_NOT_FOUND_MODAL)
        }
      }))
      .then(hideNewShopTiresModal)
      .then(() => {
        if (withShowConfirmTireSizeModal && !skipTireSizeExperience) {
          trigerConfirmTireSizeModal({ source, promo, isOnlyVehicle })
        }

        if (!withShowConfirmTireSizeModal && !skipTireSizeExperience) {
          navigateToConfirmTireSize()
        }

        if (skipTireSizeExperience) {
          refresh()
        }
      })
  }
})

const withFetchingYearsOnDidMount = lifecycle({
  componentDidMount() {
    const {
      ymmeName,
      setOptionsData,
      initialize,
      setEndpointData,
      setDataLoading,
      resetYmme,
      vehiclePreData = {},
      setStep,
      setData,
      handlerYmmeResetOnPrevStep,
      handleYearSelect,
      handleMakeSelect,
      handleModelSelect,
      isEditVehicle,
      preVehicleDetailsLoadingStatus
    } = this.props
    if (isEditVehicle) {
      const { year, make, model } = vehiclePreData

      handleYearSelect({ ymmeName, value: year.value })
      handleMakeSelect({ ymmeName, value: make.value })
      handleModelSelect({ ymmeName, value: model.value })
      setStep(3)
      setData(vehiclePreData)
      handlerYmmeResetOnPrevStep({ ymmeName, names: [SUB_MODELS]})
    } else {
      resetYmme()
    }
    setDataLoading(true)
    initialize({ ymmeName, setOptionsData, setEndpointData, setDataLoading, preVehicleDetailsLoadingStatus })
  },
  componentDidUpdate({ search: prevSearch, data: prevVehicle }) {
    const {
      search,
      setEndpointData,
      optionsData,
      data,
      initialize,
      setOptionsData,
      ymmeName,
      setDataLoading,
      preVehicleDetailsLoadingStatus
    } = this.props

    if (!isEqual(prevVehicle, data)) {
      setDataLoading(true)
      initialize({ ymmeName, setOptionsData, setEndpointData, setDataLoading, preVehicleDetailsLoadingStatus })
    }

    if (!search && !isEqual(prevSearch, search)) {
      setEndpointData(optionsData)
    }
  }
})

const withInfo = withProps(({
  vehiclePreData = {}
}) => ({
  isEditVehicle: Boolean(Object.keys(vehiclePreData).length)
}))


export default compose(
  connect(makeMapStateToProps, {
    initialize,
    handleYearSelect,
    handleMakeSelect,
    handleModelSelect,
    handleSubModelSelect,
    submit,
    navigateToConfirmTireSize,
    getTireSizes,
    hideNewShopTiresModal,
    handleYmmeReset,
    handlerYmmeResetOnPrevStep,
    trigerConfirmTireSizeModal,
    refresh
  }),
  ymmeSelectorState,
  preVehicleDetailsLoadingStatusState,
  withDataLoading,
  stepperStepState,
  searchInputValueState,
  vehicleState,
  ymmeSelectorEndpointState,
  withResetYMME,
  withInfo,
  withFetchingYearsOnDidMount,
  withSubmitHandlers
)(NewYMME)
