import { VEHICLE_MILEAGE } from 'constant'

export const getStatesOptions = (states = []) =>
  states.map(
    ({ stateCode }) => ({ label: stateCode, value: stateCode })
  )

export const integersOnly = (fieldValue, prevValue) => {
  if (!fieldValue) {
    return fieldValue
  }

  // Normalize field value formatted by addCommas helper
  const normalizedValue = fieldValue.replace(/,/g, '')

  if (Number(normalizedValue) >= 0) {
    const newValue = Number.parseInt(normalizedValue, 10)

    // Avoiding weird behavior
    return newValue <= 10 ** 6 ? newValue : 10 ** 6
  }

  return prevValue
}

export const createInitialValues = ({
  mileage
}) => ({
  [VEHICLE_MILEAGE]: mileage || ''
})

export const preparePersonalizationData = ({
  userVehicleId,
  values: {
    mileage
  },
  values
}) => ({
  mileage: String(mileage),
  userVehicleId,
  ...values
})
