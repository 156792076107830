import { FROM_PROMOTIONS_URL_FLAG, REGIONS_PAGE } from 'constant'
import getSearchByVehicleUrl from 'helpers/getSearchByVehicleUrl'
import qs from 'qs'

export const getEligibleTiresUrl = ({
  associatedPromotionsLink,
  promotionId,
  vehicle,
  hasActiveVehicle,
  associatedPromoId
}) => {
  const urlPrefix = `${hasActiveVehicle ? getSearchByVehicleUrl(vehicle) : ''}?promotionId=`
  const baseUrl = associatedPromoId ? `${urlPrefix}${associatedPromoId}&originalPromoId=${promotionId}` :
    ((associatedPromotionsLink && `${associatedPromotionsLink}&originalPromoId=${promotionId}`) || `${urlPrefix}${promotionId}`)

  return `${baseUrl}&${FROM_PROMOTIONS_URL_FLAG}`
}

export const getRegionNameLinkFromSearch = ({ search = '' }) => {
  const { regionName = '' } = qs.parse(search.slice(1)) || {}
  return regionName ? `${REGIONS_PAGE}${regionName.toLowerCase()}` : ''
}

export const getAssociatedPromoId = (associatedPromotionsLink = '') => associatedPromotionsLink.replace(/^(.*?)\?promotionId=/, '')
