import constant from 'lodash/constant'

export const getInitialSearchMask = constant(undefined)

export const getInitialState = () => ({
  originalSearchResults: [],
  showLimitedSearchResultsForMobile: true,
  searchRadius: undefined,
  searchError: {},
  searchCoords: undefined,
  searchMask: getInitialSearchMask(),
  errorForm: undefined,
  searchMethod: undefined,
  initialSearchMethod: undefined,
  isLoading: false,
  selectedSearchResult: ''
})
