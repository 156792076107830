import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'

const DropdownOption = ({ className, displayName, handleSelect, ...props }) =>
  <Option { ...props } className={ className } onClick={ handleSelect }>
    { displayName }
  </Option>

export default DropdownOption

DropdownOption.propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node
  ]),
  handleSelect: PropTypes.func
}

const Option = styled.div`
  ${Mixin.font.GothamBook(14)}
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  color: ${Theme.colors.black};
  ${props => props.selected && css`
    background-color: ${Theme.colors.transparent_grey_hover};
  `}
  text-align: left;

  :hover {
    cursor: pointer;
    background-color: ${Theme.colors.transparent_grey_hover};
  }
`
