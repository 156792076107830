import { push } from 'connected-react-router'
import { rest, isApiError } from 'services'
import { CONFIRM_TIRE_SIZES, TAB_YMME_NAME, HEADER_SOURCE, YMME_NAME } from 'constant'
import { setVehicleTireSizes } from 'actions/confirmTireSize'
import { showNewShopTiresModal } from '../NewShopTiresModal/actions'

/**
 * gets vehicleId as first argument and object with modal handlers as second argument.
 */
export const getTireSizes = ({ vehicleId, tireSizesNotFoundModalProps }) => async dispatch => {
  try {
    const { garageResponse: { tireSizes } = {}} = await rest.api.tireSizes({ urlKeys: { vehicleId }})

    dispatch(setVehicleTireSizes(tireSizes))

    return tireSizes
  } catch (err) {
    if (isApiError(err)) {
      dispatch(showNewShopTiresModal({
        tabName: TAB_YMME_NAME,
        ymmeName: YMME_NAME,
        withShowConfirmTireSizeModal: true,
        source: HEADER_SOURCE
      }))
    }

    return Promise.reject()
  }
}

export const navigateToConfirmTireSize = () => push(CONFIRM_TIRE_SIZES)
