import React from 'react'
import styled from 'styled-components'
import Media from 'components/common/Media'
import { PlayPauseButton } from './PlayPauseButton'

export default ({ isPlaying, togglePlayPause }) => dots =>
  <div>
    <Wrapper>
      <Media.Desktop>
        <li className="default-play-pause-button">
          <PlayPauseButton isPlaying={ isPlaying } togglePlayPause={ togglePlayPause } />
        </li>
      </Media.Desktop>
      { dots }
    </Wrapper>
    <Media.Mobile>
      <MobilePlayPauseWrapper className="default-play-pause-button">
        <PlayPauseButton isPlaying={ isPlaying } togglePlayPause={ togglePlayPause } />
      </MobilePlayPauseWrapper>
    </Media.Mobile>
  </div>

const Wrapper = styled.ul`
  padding: 0;
  margin: 0;
  text-align: left;
  display: flex;
`

const MobilePlayPauseWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`
