import React from 'react'
import PropTypes from 'prop-types'
import { FieldWrapper } from '../SharedStyles'
import PopularSizesCustomOption from './PopularSizesCustomOption'
import { buildSteps } from '../../helpers'
import { WIDTH, RATIO, DIAMETER } from './constants'
import styled from 'styled-components'

const TireSizeSelector = ({
  model: {
    width: widthModel,
    ratio: ratioModel,
    diameter: diameterModel
  },
  data: {
    width,
    ratio,
    diameter
  },
  handlers: {
    width: widthHandlers,
    ratio: ratioHandlers,
    diameter: diameterHandlers
  },
  noSpace,
  setRef,
  showPopularSizes,
  multiColumnMenu,
  DropdownComponent,
  MultiColumnMenuComponent,
  handleMultiColumnModalShow,
  onMultiColumnWidthSelect,
  onMultiColumnRatioSelect,
  onMultiColumnDiameterSelect
}) =>
  <React.Fragment>
    <TireSizeFieldWrapper inline noSpace={ noSpace } data-at-ymme-width>
      <DropdownComponent
        full
        static
        compressedRows
        optionsMaxHeight={ 115 }
        { ...width }
        { ...widthModel }
        { ...widthHandlers }
        innerRef={ ref => setRef({ name: WIDTH, ref }) }
        components={ showPopularSizes ? {
          Option: PopularSizesCustomOption
        } : undefined }
        onFocus={ () => handleMultiColumnModalShow() } />
    </TireSizeFieldWrapper>
    <TireSizeFieldWrapper inline noSpace={ noSpace } data-at-ymme-ratio>
      <DropdownComponent
        full
        static
        compressedRows
        optionsMaxHeight={ 115 }
        { ...ratio }
        { ...ratioModel }
        { ...ratioHandlers }
        innerRef={ ref => setRef({ name: RATIO, ref }) }
        horizontalOffset={ multiColumnMenu ? -122 : undefined }
        components={ showPopularSizes ? {
          Option: PopularSizesCustomOption
        } : undefined }
        onFocus={ () => handleMultiColumnModalShow(1) } />
    </TireSizeFieldWrapper>
    <TireSizeFieldWrapper inline noSpace={ noSpace } data-at-ymme-diameter>
      <DropdownComponent
        full
        static
        compressedRows
        optionsMaxHeight={ 115 }
        { ...diameter }
        { ...diameterModel }
        { ...diameterHandlers }
        innerRef={ ref => setRef({ name: DIAMETER, ref }) }
        horizontalOffset={ multiColumnMenu ? -241 : undefined }
        components={ showPopularSizes ? {
          Option: PopularSizesCustomOption
        } : undefined }
        onFocus={ () => handleMultiColumnModalShow(2) } />
    </TireSizeFieldWrapper>
    { multiColumnMenu &&
      <MultiColumnMenuComponent steps={ buildSteps([
        { value: width.value, onClick: onMultiColumnWidthSelect, ...widthModel },
        { value: ratio.value, onClick: onMultiColumnRatioSelect, ...ratioModel },
        { value: diameter.value, onClick: onMultiColumnDiameterSelect, ...diameterModel }
      ]) } />
    }
  </React.Fragment>

TireSizeSelector.propTypes = {
  data: PropTypes.shape({
    width: PropTypes.shape(),
    ratio: PropTypes.shape(),
    diameter: PropTypes.shape()
  }),
  DropdownComponent: PropTypes.elementType,
  handleMultiColumnModalShow: PropTypes.func,
  handlers: PropTypes.shape({
    width: PropTypes.shape(),
    ratio: PropTypes.shape(),
    diameter: PropTypes.shape()
  }),
  model: PropTypes.shape({
    width: PropTypes.shape(),
    ratio: PropTypes.shape(),
    diameter: PropTypes.shape()
  }),
  multiColumnMenu: PropTypes.bool,
  MultiColumnMenuComponent: PropTypes.elementType,
  noSpace: PropTypes.bool,
  onMultiColumnDiameterSelect: PropTypes.func,
  onMultiColumnRatioSelect: PropTypes.func,
  onMultiColumnWidthSelect: PropTypes.func,
  setRef: PropTypes.func,
  showPopularSizes: PropTypes.bool
}

const TireSizeFieldWrapper = styled(FieldWrapper)`
  @media (max-width: 380px) {
    width: 100%;
    padding-right: 0;

    & + & {
      padding-left: 0;
    }

    &:last-child {
      width: 100%;
    }
  }
`

export default TireSizeSelector
