import React from 'react'
import PropTypes from 'prop-types'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import { type } from 'components/Login/Login'
import ModalPage from 'components/ModalPage/ModalPage'
import * as DD from '../../components/HeaderDropdown/HeaderDropdown'
import * as Utility from '../../components/UtilityMenuStyles'

const HeaderSignInRegister = ({
  links: [
    {
      displayName,
      link: {
        path
      } = {}
    } = {}
  ] = [],
  elementId,
  combinedRefSetter,
  combinedHandlers,
  active,
  stopPropagation,
  focusBlurHoverHandlers,
  mobileMainMenuExpanded
}) =>
  <DD.Wrapper
    id={ elementId }
    active={ active }
    ref={ combinedRefSetter }
    data-at-header-sign-in-register-wrapper>
    <Media.Desktop>
      <DD.Title
        tabIndex="0"
        active={ active }
        { ...combinedHandlers }
        data-at-dropdown-focus>
        <Icon alt="" asset="icon-account" width="14" height="14" margin="-2px 7px 0 0" />
        { displayName }
        <Icon
          alt=""
          asset="arrow-icon-full-white-down"
          alternative="arrow-icon-full-white-up"
          condition={ active }
          width="7"
          height="4"
          margin="0 0 0 7px" />
      </DD.Title>
      <DD.Content
        right
        width="auto"
        hidden={ !active }
        { ...focusBlurHoverHandlers }
        { ...stopPropagation }>
        <ModalPage src={ path } type={ type } />
      </DD.Content>
    </Media.Desktop>

    <Media.Mobile>
      <Utility.Menu hidden={ mobileMainMenuExpanded }>
        <Utility.Item>
          <DD.TitleLink to={ path } data-at-title-link>
            <Icon alt="" asset="icon-account" width="14" height="14" margin="-2px 7px 0 0" />
            { displayName }
          </DD.TitleLink>
        </Utility.Item>
      </Utility.Menu>
    </Media.Mobile>
  </DD.Wrapper>

HeaderSignInRegister.propTypes = {
  active: PropTypes.bool,
  combinedHandlers: PropTypes.shape(),
  combinedRefSetter: PropTypes.func,
  elementId: PropTypes.string,
  focusBlurHoverHandlers: PropTypes.shape(),
  links: PropTypes.arrayOf(PropTypes.shape()),
  mobileMainMenuExpanded: PropTypes.bool,
  stopPropagation: PropTypes.shape(),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

export default HeaderSignInRegister
