export default {
  ssr: {
    port: process.env.PORT || '3005'
  },

  webpack: {
    host: process.env.BOT_DEV_HOST || 'http://localhost',
    port: process.env.BOT_DEV_PORT || '3001'
  },

  proxy: {
    host: process.env.BOT_PROXY_HOST || 'http://10.6.221.58',
    port: process.env.BOT_PROXY_PORT || '8080',
    root: process.env.BOT_PROXY_ROOT || '/csa',
    origin: process.env.BOT_PROXY_ORIGIN || 'dev-preview.bigotires.com',
    prefix: process.env.BOT_PROXY_PREFIX || '/restApi',
    basePath: process.env.BOT_API_BASEPATH || '/dp/v1'
  }
}
