import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Icon from 'components/common/Icon/Icon'

const SpinnerIcon = ({ asset = 'spinner', alt = 'loading...', className }) =>
  <SpinnerIconView className={ className } asset={ asset } alt={ alt } aria-hidden="true" />


SpinnerIcon.propTypes = {
  alt: PropTypes.string,
  asset: PropTypes.string,
  className: PropTypes.string
}
export default SpinnerIcon


const spin = keyframes`
  0% { transform: translate(-50%, -50%) rotate(-360deg); }
  100% { transform: translate(-50%, -50%) rotate(0deg); }
`

const SpinnerIconView = styled(Icon)`
  animation: ${spin} 1.7s linear infinite;
  transform-origin: center;
  width: 60px;
  height: 60px;
`
