export default [
  'BOTAllTireSizeDetailsCartridge',
  'BOTRecentlyViewedProducts',
  'BOTTireLineDetails',
  'BOTTireLineReviewsCartridge',
  'BOTTireLineSpecification',
  'BOTTireSizeLineDetailCartridge',
  'OneColumnTireLinePage'
]

/*
Appears on:
/tires/line/nitto/ntgeo-neogen/prod4580255,
/tires/sku/nitto/ntgeo-neogen/225-45zr17-94w-xl/000000000001002883
*/
