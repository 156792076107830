import React from 'react'
import PropTypes from 'prop-types'
import Back from '../Back/Back'

const NavigationBack = ({ displayName, handleNavigationBack, ...props }) =>
  <Back prevent { ...props } handler={ handleNavigationBack }>{ displayName || 'Back' }</Back>

export default NavigationBack

NavigationBack.propTypes = {
  displayName: PropTypes.string,
  handleNavigationBack: PropTypes.func
}
