import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import { setAppointmentsCount } from './actions'
import MyAppointmentsMenuLink from './MyAppointmentsMenuLink'

export default compose(
  withType({ type: 'MyAppointmentsMenuLink' }),
  connect(null, { setAppointmentsCount }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { appointmentsCount, setAppointmentsCount } = this.props

      setAppointmentsCount(appointmentsCount)
    },
    componentDidUpdate({ appointmentsCount, setAppointmentsCount }) {
      setAppointmentsCount(appointmentsCount)
    }
  })
)(MyAppointmentsMenuLink)
