import {
  fetchValues,
  setValues,
  setBaseVehicleId,
  setSubModelTypes,
  setVehicleId,
  selectValue,
  resetItems,
  setError,
  resetError
} from '../common/hoc/withYmme/actions'
import { hide } from 'redux-modal'
import { YEARS, MAKES, MODELS, SUB_MODELS } from '../NewTireSearch/constant'
import { rest, isApiError } from 'services'
import { getMakesStructure, getYearStructure, getModelStructure, getSubModelStructure } from './helpers'
import {
  getYmmeSubModelTypes,
  getYmmeValues,
  getYmmeForConfirmTireSizePage,
  getYmmeMetaState
} from '../common/hoc/withYmme/selectors'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { addVehicle } from 'actions/addVehicle'
import { showConfirmTireSizeModal } from '../AddVehicleModal/actions'
import { CONFIRM_TIRE_SIZE_MODAL, HEADER_SOURCE, GLOBAL_SEARCH_SOURCE, PROMO_DETAILS_SOURCE, GENERAL_SOURCE } from 'constant'
import { showPersonalizeModal } from '../../actions/addVehicle'
import { push } from 'connected-react-router'
import getSearchByVehicleUrl from 'helpers/getSearchByVehicleUrl'
import { getLocationPathname } from 'selectors/getPageLocation'
import isWinterTiresPage from 'helpers/isWinterTiresPage'
import isEVTiresPage from 'helpers/isEVTiresPage'
import { refresh } from 'actions/router'

export const handleYmmeReset = ({ ymmeName }) => dispatch => {
  dispatch(resetItems({ names: [YEARS, MAKES, MODELS, SUB_MODELS], ymmeName }))
  dispatch(resetError({ ymmeName }))
}

export const handlerYmmeResetOnPrevStep = ({ ymmeName, names }) => dispatch => {
  dispatch(resetItems({ names, ymmeName }))
  dispatch(resetError({ ymmeName }))
}

const handleApiError = ({ err, ymmeName }) => dispatch => {
  if (isApiError(err)) {
    dispatch(setError({ error: err.status.description, ymmeName }))
  }
}

const getFieldCurrentValue = (fieldName, state, props) =>
  getYmmeValues(state, props)[fieldName]

export const fetchYears = ({
  ymmeName,
  setOptionsData,
  setEndpointData,
  setDataLoading,
  preVehicleDetailsLoadingStatus
}) => async dispatch => {
  dispatch(fetchValues({ name: YEARS, ymmeName }))

  try {
    const { garageResponse: { years }} = await rest.api.getYears()
    setOptionsData(getYearStructure(years))
    setEndpointData(getYearStructure(years))
    setDataLoading(preVehicleDetailsLoadingStatus)
    dispatch(setValues({ name: YEARS, value: years, ymmeName }))
  } catch (err) {
    setDataLoading(false)
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const fetchMakes = ({
  ymmeName,
  setOptionsData,
  setEndpointData,
  year,
  setDataLoading,
  preVehicleDetailsLoadingStatus
}) => async (dispatch, getState) => {
  dispatch(fetchValues({ name: MAKES, ymmeName }))

  try {
    const { garageResponse: { makes }} = await rest.api.getMakes({ urlKeys: { year }})
    setOptionsData(getMakesStructure(makes))
    setEndpointData(getMakesStructure(makes))
    setDataLoading(preVehicleDetailsLoadingStatus)
    dispatch(setValues({ name: MAKES, value: makes, ymmeName }))
  } catch (err) {
    setDataLoading(false)
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const fetchModels = ({
  ymmeName,
  setOptionsData,
  setEndpointData,
  year,
  make,
  setDataLoading,
  preVehicleDetailsLoadingStatus
}) => async (dispatch, getState) => {
  dispatch(fetchValues({ name: MODELS, ymmeName }))

  try {
    const { garageResponse: { modelTypes }} = await rest.api
      .getModels({
        urlKeys: {
          makeId: make,
          year
        }
      })

    setOptionsData(getModelStructure(modelTypes))
    setEndpointData(getModelStructure(modelTypes))
    setDataLoading(preVehicleDetailsLoadingStatus)
    dispatch(setValues({ name: MODELS, value: modelTypes, ymmeName }))
  } catch (err) {
    setDataLoading(false)
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const fetchSubModels = ({
  ymmeName,
  setOptionsData,
  setEndpointData,
  year,
  make,
  model,
  setDataLoading
}) => async (dispatch, getState) => {
  dispatch(fetchValues({ name: SUB_MODELS, ymmeName }))

  try {
    const { garageResponse: { subModelTypes, baseVehicleId }} = await rest.api
      .getSubModels({
        urlKeys: {
          makeId: make,
          year,
          modelId: model
        }
      })

    setOptionsData(getSubModelStructure(subModelTypes))
    setEndpointData(getSubModelStructure(subModelTypes))
    setDataLoading(false)
    dispatch(setValues({ name: SUB_MODELS, value: subModelTypes, ymmeName }))
    dispatch(setBaseVehicleId({ ymmeName, value: baseVehicleId }))
    dispatch(setSubModelTypes({ ymmeName, value: subModelTypes }))
  } catch (err) {
    setDataLoading(false)
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const initialize = ({
  ymmeName, setOptionsData, setEndpointData, setDataLoading, preVehicleDetailsLoadingStatus
}) => async (dispatch, getState) => {
  const state = getState()
  const year = getFieldCurrentValue(YEARS, state, { ymmeName })
  const make = getFieldCurrentValue(MAKES, state, { ymmeName })
  const model = getFieldCurrentValue(MODELS, state, { ymmeName })
  const subModel = getFieldCurrentValue(SUB_MODELS, state, { ymmeName })

  if (!year) {
    await dispatch(fetchYears({
      ymmeName,
      setOptionsData,
      setEndpointData,
      setDataLoading
    }))
  }

  if ((!make || preVehicleDetailsLoadingStatus) && year) {
    await dispatch(fetchMakes({
      ymmeName,
      setOptionsData,
      setEndpointData,
      year,
      setDataLoading,
      preVehicleDetailsLoadingStatus
    }))
  }

  if (((!model || preVehicleDetailsLoadingStatus) && make)) {
    await dispatch(fetchModels({
      ymmeName,
      setOptionsData,
      setEndpointData,
      make,
      year,
      setDataLoading,
      preVehicleDetailsLoadingStatus
    }))
  }

  if (!subModel && model) {
    await dispatch(fetchSubModels({
      ymmeName,
      setOptionsData,
      setEndpointData,
      make,
      year,
      model,
      setDataLoading
    }))
  }

  if (year && make && model && subModel) {
    const subModelTypes = getYmmeSubModelTypes(getState(), { ymmeName })
    const { vehicleId } = subModelTypes.find(({ subModelId }) => subModel === subModelId)

    dispatch(selectValue({ name: SUB_MODELS, value: subModel, ymmeName }))
    dispatch(setVehicleId({ ymmeName, value: vehicleId }))
  }
}

export const handleYearSelect = ({
  value,
  ymmeName
}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(YEARS, getState(), { ymmeName })) {
    dispatch(selectValue({ name: YEARS, value, ymmeName }))
  }
}

export const handleMakeSelect = ({
  value,
  ymmeName
}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(MAKES, getState(), { ymmeName })) {
    dispatch(selectValue({ name: MAKES, value, ymmeName }))
  }
}

export const handleModelSelect = ({
  value,
  ymmeName
}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(MODELS, getState(), { ymmeName })) {
    dispatch(selectValue({ name: MODELS, value, ymmeName }))
  }
}

export const submit = ({ ymmeName, skipTireSizeExperience }) => async (dispatch, getState) => {
  const state = getState()
  const values = getYmmeValues(state, { ymmeName })

  const vehicleDetails = getYmmeForConfirmTireSizePage(state, { ymmeName })
  const { isLoading, isValid } = getYmmeMetaState(state, { ymmeName })

  if (isValid && !isLoading) {
    dispatch(setVehicleDetails(vehicleDetails))
    if (skipTireSizeExperience) {
      const { vehicleResponseType: { userVehicleId } = {}} = await dispatch(addVehicle(vehicleDetails)) || {}
      return Promise.resolve(userVehicleId)
    }

    return Promise.resolve(values)
  }

  return Promise.reject()
}

export const handleSubModelSelect = ({ value, ymmeName }) => async (dispatch, getState) => {
  const state = getState()

  if (value !== getFieldCurrentValue(SUB_MODELS, state, { ymmeName })) {
    dispatch(selectValue({ name: SUB_MODELS, value, ymmeName }))
    const subModelTypes = getYmmeSubModelTypes(state, { ymmeName })
    const { vehicleId } = subModelTypes && subModelTypes.find(({ subModelId }) => value === subModelId)
    dispatch(setVehicleId({ ymmeName, value: vehicleId }))
    return Promise.resolve(vehicleId)
  }
}

export const trigerConfirmTireSizeModal = ({
  source = HEADER_SOURCE,
  promo,
  isOnlyVehicle
}) => (dispatch, getState) => {
  dispatch(showConfirmTireSizeModal({
    source,
    promo,
    isOnlyVehicle,
    handleTiresSelect: async ({
      vehicle,
      frontTireSize,
      rearTireSize,
      loadIndex,
      rearLoadIndex,
      speedRating,
      rearSpeedRating
    }) => {
      const payload = {
        ...vehicle,
        loadIndex,
        rearLoadIndex,
        speedRating,
        rearSpeedRating,
        tireFrontSize: frontTireSize,
        tireRearSize: rearTireSize
      }
      const isWinterTiresPageStatus = isWinterTiresPage(getLocationPathname(getState()))
      const isEVTiresPageStatus = isEVTiresPage(getLocationPathname(getState()))
      const { vehicleResponseType: { userVehicleId, vehicleDetails }} = await dispatch(addVehicle({ ...payload }))

      dispatch(hide(CONFIRM_TIRE_SIZE_MODAL))

      const searchByVehicleUrl = getSearchByVehicleUrl({ ...vehicle, frontTireSize, rearTireSize })

      if (source === GENERAL_SOURCE) {
        dispatch(showPersonalizeModal({
          vehicle,
          frontTireSize,
          rearTireSize,
          userVehicleId,
          vehicleDetails
        }))
      }

      if (source === PROMO_DETAILS_SOURCE) {
        dispatch(push(`${searchByVehicleUrl}${promo || ''}`))
      }

      if (source === HEADER_SOURCE || source === GLOBAL_SEARCH_SOURCE) {
        if (isWinterTiresPageStatus || isEVTiresPageStatus) {
          dispatch(refresh())
        } else {
          dispatch(push(searchByVehicleUrl))
        }
      }
    }
  }
  ))
}

export const getYmmeOptions = ({ ymmeName }) => (dispatch, getState) => {
  const options = {
    yearOptions: dispatch(fetchValues({ name: YEARS, ymmeName })),
    makeOptions: dispatch(fetchValues({ name: MAKES, ymmeName })),
    modelOptions: dispatch(fetchValues({ name: MODELS, ymmeName })),
    subModelOptions: dispatch(fetchValues({ name: SUB_MODELS, ymmeName }))
  }

  return options
}
