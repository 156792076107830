import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Head from 'components/Head'
import ModalBoxList from 'components/ModalBoxList'
import Spinner from 'components/common/Spinner'
import SkipLink from 'components/common/SkipLink/SkipLink'
import ClientRendering from 'components/common/ClientRendering/ClientRendering'
import Media from 'components/common/Media'
import Init3rdParty from './Init3rdParty'
import Toolbox from './Toolbox'
import getComponent from 'helpers/component-helper'
import GlobalStyles from './globalStyles'
import DatePickerStyles from './datePickerStyles'

const Main = ({ data, isFetching, isLoading, isModal, ariaProps = {}}) =>
  <Fragment>
    <Head data={ data } />
    <GlobalStyles />
    <DatePickerStyles />
    {
      (isFetching || isLoading) &&
        <Spinner />
    }
    <ClientRendering>
      <Media.HighContrastTest />
      <Init3rdParty />
      <Media.Mobile>
        <Toolbox />
      </Media.Mobile>
    </ClientRendering>
    <Container isModal={ isModal } { ...ariaProps }>
      <SkipLink />
      { getComponent(data) }
      <ModalBoxList />
    </Container>
  </Fragment>


Main.propTypes = {
  ariaProps: PropTypes.shape(),
  data: PropTypes.shape({
    '@type': PropTypes.string.isRequired
  }),
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isModal: PropTypes.bool
}

const Container = styled.div`
  ${props => props.isModal && css`
    @media print {
      display: none;
    }
  `}
`

export default Main
