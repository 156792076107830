import { createAction } from 'redux-actions'
import { rest, isApiError } from 'services'
import { isCurrentVehicle } from 'helpers/ymme'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { addVehicle } from 'actions/addVehicle'
import { fieldState } from './model'
import { YEARS, MAKES, MODELS, SUB_MODELS } from './constants'
import { YMME_NAME } from 'components/AddVehicleModal/constants'
import {
  getYmmeValues,
  getYmmeMetaState,
  getYmmeForConfirmTireSizePage,
  getYmmeFields,
  getYmmeSubModelTypes,
  getYmmeInitialized
} from './selectors'
import validateFn from './validate'
import isEmpty from 'lodash/isEmpty'

const getFieldCurrentValue = (fieldName, state, props) =>
  getYmmeValues(state, props)[fieldName]

// internal use only. Aren't supposed to used on decorated components
export const setFilter = createAction('YMME/SET_FILTER')
export const setValues = createAction('YMME/SET_VALUES')
export const selectValue = createAction('YMME/SELECT_VALUE')
export const resetItem = createAction('YMME/RESET_ITEM')
export const resetItems = createAction('YMME/RESET_ITEMS')
export const toggleItem = createAction('YMME/TOGGLE_ITEM')
export const setErrors = createAction('YMME/SET_ERRORS')
export const expand = createAction('YMME/EXPAND')
export const collapse = createAction('YMME/COLLAPSE')
export const fetchValues = createAction('YMME/FETCH_VALUES')
export const setError = createAction('YMME/SET_ERROR')
export const resetError = createAction('YMME/RESET_ERROR')

export const setVehicleId = createAction('YMME/SET_VEHICLE_ID')
export const setBaseVehicleId = createAction('YMME/SET_BASE_VEHICLE_ID')
export const setSubModelTypes = createAction('YMME/SET_SUBMODEL_TYPES')
export const setYmmeInitialized = createAction('YMME/SET_YMME_INITIALIZED')

export const registerYmme = createAction('YMME/REGISTER')
export const unregisterYmme = createAction('YMME/UNREGISTER')

export const handleYmmeReset = ({ ymmeName }) => dispatch => {
  dispatch(selectValue({ name: YEARS, value: fieldState.value, ymmeName }))
  dispatch(resetItems({ names: [MAKES, MODELS, SUB_MODELS], ymmeName }))
  dispatch(resetError({ ymmeName }))
}

const createSetFilterActionCreator = name => ({ value, ymmeName }) => setFilter({ name, value, ymmeName })
const createToggleItemActionCreator = name => ({ ymmeName }) => toggleItem({ name, ymmeName })

export const handleYearsFilterChange = createSetFilterActionCreator(YEARS)
export const handleMakesFilterChange = createSetFilterActionCreator(MAKES)
export const handleModelsFilterChange = createSetFilterActionCreator(MODELS)
export const handleSubModelsFilterChange = createSetFilterActionCreator(SUB_MODELS)

export const toggleYears = createToggleItemActionCreator(YEARS)
export const toggleMakes = createToggleItemActionCreator(MAKES)
export const toggleModels = createToggleItemActionCreator(MODELS)
export const toggleSubModels = createToggleItemActionCreator(SUB_MODELS)

const handleApiError = ({ err, ymmeName }) => dispatch => {
  if (isApiError(err)) {
    dispatch(setError({ error: err.status.description, ymmeName }))
  }
}

export const fetchYears = ({ ymmeName }) => async dispatch => {
  dispatch(fetchValues({ name: YEARS, ymmeName }))

  try {
    const { garageResponse: { years }} = await rest.api.getYears()

    dispatch(setValues({ name: YEARS, value: years, ymmeName }))
    dispatch(collapse({ name: YEARS, ymmeName }))
  } catch (err) {
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const fetchMakes = ({ ymmeName }) => async (dispatch, getState) => {
  const { years: year } = getYmmeValues(getState(), { ymmeName })

  dispatch(fetchValues({ name: MAKES, ymmeName }))

  try {
    const { garageResponse: { makes }} = await rest.api.getMakes({ urlKeys: { year }})
    dispatch(collapse({ ymmeName }))
    dispatch(setValues({ name: MAKES, value: makes, ymmeName }))
  } catch (err) {
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const fetchModels = ({ ymmeName }) => async (dispatch, getState) => {
  const { makes, years } = getYmmeValues(getState(), { ymmeName }) // TODO change selector

  dispatch(fetchValues({ name: MODELS, ymmeName }))

  try {
    const { garageResponse: { modelTypes }} = await rest.api
      .getModels({
        urlKeys: {
          makeId: makes,
          year: years
        }
      })

    dispatch(collapse({ ymmeName }))
    dispatch(setValues({ name: MODELS, value: modelTypes, ymmeName }))
  } catch (err) {
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const fetchSubModels = ({ ymmeName }) => async (dispatch, getState) => {
  const { makes, years, models } = getYmmeValues(getState(), { ymmeName }) // TODO change

  dispatch(fetchValues({ name: SUB_MODELS, ymmeName }))

  try {
    const { garageResponse: { subModelTypes, baseVehicleId }} = await rest.api
      .getSubModels({
        urlKeys: {
          makeId: makes,
          year: years,
          modelId: models
        }
      })

    dispatch(collapse({ ymmeName }))
    dispatch(setValues({ name: SUB_MODELS, value: subModelTypes, ymmeName }))
    dispatch(setBaseVehicleId({ ymmeName, value: baseVehicleId }))
    dispatch(setSubModelTypes({ ymmeName, value: subModelTypes }))
  } catch (err) {
    dispatch(handleApiError({ err, ymmeName }))
  }
}

export const handleYearSelect = ({
  value,
  ymmeName
}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(YEARS, getState(), { ymmeName })) {
    dispatch(selectValue({ name: YEARS, value, ymmeName }))
    dispatch(resetItems({ names: [MAKES, MODELS, SUB_MODELS], ymmeName }))
    await dispatch(fetchMakes({ ymmeName }))
    return dispatch(expand({ name: MAKES, ymmeName }))
  }

  dispatch(toggleYears({ ymmeName }))
}

export const handleMakeSelect = ({
  value,
  ymmeName
}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(MAKES, getState(), { ymmeName })) {
    dispatch(selectValue({ name: MAKES, value, ymmeName }))
    dispatch(resetItems({ names: [MODELS, SUB_MODELS], ymmeName }))
    await dispatch(fetchModels({ ymmeName }))
    return dispatch(expand({ name: MODELS, ymmeName }))
  }

  dispatch(toggleMakes({ ymmeName }))
}

export const handleModelSelect = ({
  value,
  ymmeName
}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(MODELS, getState(), { ymmeName })) {
    dispatch(selectValue({ name: MODELS, value, ymmeName }))
    dispatch(resetItems({ names: [SUB_MODELS], ymmeName }))
    await dispatch(fetchSubModels({ ymmeName }))
    return dispatch(expand({ name: SUB_MODELS, ymmeName }))
  }

  dispatch(toggleModels({ ymmeName }))
}

export const handleSubModelSelect = ({ value, ymmeName }) => async (dispatch, getState) => {
  const state = getState()

  if (value !== getFieldCurrentValue(SUB_MODELS, state, { ymmeName })) {
    dispatch(selectValue({ name: SUB_MODELS, value, ymmeName }))
    const subModelTypes = getYmmeSubModelTypes(state, { ymmeName })
    const { vehicleId } = subModelTypes && subModelTypes.find(({ subModelId }) => value === subModelId)

    dispatch(setVehicleId({ ymmeName, value: vehicleId }))
  }

  dispatch(toggleSubModels({ ymmeName }))
}

export const initialize = ({ ymmeName, vehicle }) => async (dispatch, getState) => {
  const fields = getYmmeFields(getState(), { ymmeName })
  const ymmeInitialized = getYmmeInitialized(getState(), { ymmeName })

  const {
    years: {
      values = []
    } = {}
  } = fields

  if (values.length === 0) {
    await dispatch(fetchYears({ ymmeName }))
  }

  if (!vehicle || isEmpty(vehicle) || isCurrentVehicle(fields, vehicle) || ymmeName === YMME_NAME) {
    !ymmeInitialized && setYmmeInitialized({ ymmeName, value: true })
    return
  }

  const { year, make, model, subModel } = vehicle
  if (!isNaN(year)) {
    dispatch(selectValue({ name: YEARS, value: year, ymmeName }))
    await dispatch(fetchMakes({ ymmeName }))
  }
  if (!isNaN(make)) {
    dispatch(selectValue({ name: MAKES, value: make, ymmeName }))
    await dispatch(fetchModels({ ymmeName }))
  }
  if (!isNaN(model)) {
    dispatch(selectValue({ name: MODELS, value: model, ymmeName }))
    await dispatch(fetchSubModels({ ymmeName }))
  }
  if (!isNaN(subModel)) {
    const subModelTypes = getYmmeSubModelTypes(getState(), { ymmeName })
    const { vehicleId } = subModelTypes?.find(({ subModelId }) => subModel === subModelId)

    dispatch(selectValue({ name: SUB_MODELS, value: subModel, ymmeName }))
    dispatch(setVehicleId({ ymmeName, value: vehicleId }))
  }
  !ymmeInitialized && setYmmeInitialized({ ymmeName, value: true })
  dispatch(collapse({ ymmeName }))
}

export const validate = ({ ymmeName }) => (dispatch, getState) => {
  const ymmeValues = getYmmeValues(getState(), { ymmeName })
  const errors = validateFn(ymmeValues)

  dispatch(setErrors({ errors, ymmeName }))
}

/**
 * takes handleSubmit function which will be called if ymme is valid and nothing is loading
 * first of all it runs validation. After this we get fresh state with validation errors if there were any.
 * In case some field is still in loading state or there is an validation error we don't do anything.
 * @param handleSubmit
 * @param ymmeName
 * @returns {function(*, *)}
 */
export const submit = ({ ymmeName, skipTireSizeExperience }) => async (dispatch, getState) => {
  dispatch(validate({ ymmeName }))

  const state = getState()
  const values = getYmmeValues(state, { ymmeName })

  const vehicleDetails = getYmmeForConfirmTireSizePage(state, { ymmeName })
  const { isLoading, isValid } = getYmmeMetaState(state, { ymmeName })

  if (isValid && !isLoading) {
    dispatch(setVehicleDetails(vehicleDetails))
    if (skipTireSizeExperience) {
      const { vehicleResponseType: { userVehicleId } = {}} = await dispatch(addVehicle(vehicleDetails)) || {}
      return Promise.resolve(userVehicleId)
    }
    return Promise.resolve(values)
  }

  return Promise.reject()
}
