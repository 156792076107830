import { show, hide } from 'redux-modal'
import { SubmissionError } from 'redux-form'
import { rest, isApiError } from 'services'
import { refresh } from 'actions/router'
import { FIND_SERVICE_HISTORY_MODAL, VEHICLE_AND_DATA_MISMATCH_CODE, VEHICLE_AND_DATA_MISMATCH_FLAG } from './constants'
import { getServiceHistoryApiDetails } from './helpers'

export const showFindServiceHistoryModal = ({ userVehicleId }) => show(FIND_SERVICE_HISTORY_MODAL, { userVehicleId })
export const hideFindServiceHistoryModal = () => hide(FIND_SERVICE_HISTORY_MODAL)

export const findServiceHistory = ({ licensePlate, state, VIN, userVehicleId }) => dispatch => {
  const { method, payload } = getServiceHistoryApiDetails({ licensePlate, state, VIN, userVehicleId })

  return rest.api[method](payload)
    .then(() => {
      dispatch(refresh())
      dispatch(hideFindServiceHistoryModal())
    })
    .catch(err => {
      if (isApiError(err)) {
        const { code, description } = err.status

        throw new SubmissionError({
          _error: code === VEHICLE_AND_DATA_MISMATCH_CODE ? VEHICLE_AND_DATA_MISMATCH_FLAG : description
        })
      }
    })
}
