import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getCurrentStore } from 'selectors'
import withType from 'components/common/hoc/withType'
import active from 'components/common/hoc/active'
import withFilteredHoverHandlers from 'components/common/hoc/withFilteredHoverHandlers'
import withHeaderHorizontalNav from 'components/common/hoc/withHeaderHorizontalNav'
import withCombinedActiveStatesAndHandlers from 'components/common/hoc/withCombinedActiveStatesAndHandlers'
import HamburgerMyStoreMenu from './HamburgerMyStoreMenu'

const mapStateToProps = state => ({
  store: getCurrentStore(state)
})

export default compose(
  withType({ type: 'HamburgerMyStoreMenu', placeholders: ['links']}),
  connect(mapStateToProps),
  active,
  withFilteredHoverHandlers,
  withCombinedActiveStatesAndHandlers,
  withHeaderHorizontalNav)(HamburgerMyStoreMenu)
