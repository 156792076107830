import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { bazaarVoiceCodeFactory } from 'helpers/bazaarVoice'
import { fullStoryCodeFactory } from 'helpers/fullStory'
import { kenshooCodeFactory } from 'helpers/kenshoo'
import { bingCodeFactory } from 'helpers/bing'
import { facebookCodeFactory } from 'helpers/facebook'
import { generateOneTrustScript, generateOneTrustScriptTag } from 'helpers/oneTrust'
import { audioEyeCodeFactory } from 'helpers/audioEye'
import {
  ONE_TRUST_SCRIPT,
  KENSHOO_SCRIPT,
  BING_SCRIPT,
  FACEBOOK_SCRIPT,
  FULL_STORY_SCRIPT,
  BAZAAR_SCRIPT,
  AUDIO_EYE_SCRIPT
} from './constants'

const { BOT_ONE_TRUST_API_KEY } = (window.GLOBAL_VARS || process.env)
const nonce = window.BOT_NONCE
class Init3rdParty extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render = () =>
    <Helmet>
      <script>
        {
          generateOneTrustScriptTag(BOT_ONE_TRUST_API_KEY)
        }
      </script>
      {
        !this.props.downloadedScripts.includes(ONE_TRUST_SCRIPT) &&
          <script id={ ONE_TRUST_SCRIPT } nonce={ nonce } type="text/javascript">
            {
              generateOneTrustScript()
            }
          </script>
      }

      {
        !this.props.downloadedScripts.includes(KENSHOO_SCRIPT) &&
          <script id={ KENSHOO_SCRIPT } nonce={ nonce } type="text/javascript">
            {
              /* Kenshoo Global Script */
              kenshooCodeFactory()
            }
          </script>
      }

      {
        !this.props.downloadedScripts.includes(BING_SCRIPT) &&
          <script id={ BING_SCRIPT } nonce={ nonce } type="text/javascript">
            {
              /* Bing Global Script */
              bingCodeFactory()
            }
          </script>
      }

      {
        !this.props.downloadedScripts.includes(FACEBOOK_SCRIPT) &&
          <script id={ FACEBOOK_SCRIPT } nonce={ nonce } type="text/javascript">
            {
              /* Facebook Global Script */
              facebookCodeFactory()
            }
          </script>
      }

      {
        this.props.isHomepage &&
        <script type="application/ld+json">{ this.props.organizationSchema }</script>
      }

      {
        this.props.isHomepage &&
          <script type="application/ld+json">{ this.props.homepageBreadcrumbSchema }</script>
      }

      {
        this.props.isHomepage &&
          <script type="application/ld+json">{ this.props.searchBoxSchema }</script>
      }

      {
        this.props.marchex &&
          <script type="text/javascript" src="//rw.marchex.io/2/Ch4NmFdxVxNj7QDr" async />
      }

      {
        this.props.kenshooScripts && this.props.kenshooCIDs.map((cid, index) =>
          <script
            type="text/javascript"
            src={ `https://services.xg4ken.com/js/kenshoo.js?cid=${cid}` }
            key={ index }
            async />
        )
      }

      {
        this.props.fullStory && !this.props.downloadedScripts.includes(FULL_STORY_SCRIPT) &&
          <script id={ FULL_STORY_SCRIPT } nonce={ nonce } type="text/javascript">
            {
              /* FullStory Global Script */
              fullStoryCodeFactory()
            }
          </script>
      }

      {
        this.props.bazaar && !this.props.downloadedScripts.includes(BAZAAR_SCRIPT) &&
          <script id={ BAZAAR_SCRIPT } nonce={ nonce } type="text/javascript">
            {
              /* BazaarVoice reviews Script */
              bazaarVoiceCodeFactory()
            }
          </script>
      }

      {
        !this.props.downloadedScripts.includes(AUDIO_EYE_SCRIPT) &&
          <script id={ AUDIO_EYE_SCRIPT } nonce={ nonce } type="text/javascript">
            {
              /* AudioEye Global Script */
              audioEyeCodeFactory()
            }
          </script>
      }

      {
        <script src="https://cdn.optimizely.com/js/27067340190.js" />
      }

    </Helmet>
}

Init3rdParty.propTypes = {
  bazaar: PropTypes.bool,
  downloadedScripts: PropTypes.arrayOf(PropTypes.string),
  fullStory: PropTypes.bool,
  homepageBreadcrumbSchema: PropTypes.string,
  isHomepage: PropTypes.bool,
  kenshooCIDs: PropTypes.arrayOf(PropTypes.string),
  kenshooScripts: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  marchex: PropTypes.bool,
  organizationSchema: PropTypes.string,
  searchBoxSchema: PropTypes.string
}

export default Init3rdParty
