import React from 'react'
import PropTypes from 'prop-types'

const ONTOUCHSTART = 'ontouchstart'

class ScrollDisable extends React.Component {
  constructor(props) {
    super(props)
    this.isMobile = (ONTOUCHSTART in window) || false
    this.x = 0
    this.y = 0
  }

  componentDidMount() {
    window.addEventListener('scroll', this.disableScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.disableScroll)
  }

  disableScroll = e => {
    if (this.isMobile) {
      e.preventDefault()
      e.returnValue = false

      return
    }

    window.scrollTo(this.x, this.y)
  }

  render() {
    return this.props.children
  }
}

ScrollDisable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default ScrollDisable
