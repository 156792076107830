import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TeaserTitle = ({ title }) =>
  <TeaserSpan className="generic-banner-headline" data-at-teaser-span>
    { title }
  </TeaserSpan>

TeaserTitle.propTypes = {
  title: PropTypes.string
}

export default TeaserTitle

const TeaserSpan = styled.span`
  display: inline-block;
`
