import { Validator, errorMessages } from 'services/validator'
import {
  LOGIN_PROPERTY,
  PASSWORD_PROPERTY
} from './constants'

export default Validator.combineValidators({
  [LOGIN_PROPERTY]: [
    {
      validators: Validator.required(),
      errorMessage: errorMessages.EMAIL_ERROR_MESSAGE
    }, {
      validators: Validator.email(),
      errorMessage: errorMessages.EMAIL_ERROR_MESSAGE
    }
  ],
  [PASSWORD_PROPERTY]: {
    validators: Validator.required(),
    errorMessage: errorMessages.PASSWORD_EMPTY
  }
})
