export default [
  'VehicleMakerSelector',
  'VehicleModelSelector',
  'VehicleYearSelector',
  'VehicleSubmodelSelector',
  'BreadcrumbPanel',
  'BOTSizeSelector'
]

/*
Appears on:
/tires/by-vehicle
/tires/by-vehicle/*
/tires/by-vehicle/* /* (Indexable YMME)
*/
