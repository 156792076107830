import axios from 'axios'
import { GOOGLE_PLACE_AUTOCOMPLETE_PREFIX } from 'constant'

const { BOT_GOOGLE_PLACE_AUTOCOMPLETE_API_KEY } = window.GLOBAL_VARS || {}

const FORMAT = 'json'
const GET_PLACE_SUGGESTIONS_URL = `${GOOGLE_PLACE_AUTOCOMPLETE_PREFIX}/${FORMAT}`
const COUNTRY_RESTRICTION = 'country:us'

const PARAMS = {
  key: BOT_GOOGLE_PLACE_AUTOCOMPLETE_API_KEY,
  components: COUNTRY_RESTRICTION,
  radius: 1000 // in KM
}

export const getGooglePlaceSuggestions = (input, queryOptions) => {
  const url = GET_PLACE_SUGGESTIONS_URL
  const options = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET'
    },
    params: {
      ...PARAMS,
      ...queryOptions,
      input
    }
  }

  return axios
    .get(url, options)
    .catch(err => {
      console.log('Error', err)
      // TODO add winston logger here to log all errors
      // TODO add service for managing errors
      throw new Error(err)
    })
}
