export const generateOneTrustScriptTag = (oneTrustApiKey) => `!(function () {
      var t = function () {
        var oneTrustSdk = document.getElementById('oneTrustSdk')
        if(!oneTrustSdk) {
          var el = document.createElement("script");
          el.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
          el.type = "text/javascript";
          el.setAttribute("defer", "true");
          el.setAttribute('data-domain-script','${oneTrustApiKey}');
          el.id = "oneTrustSdk";
          document.getElementsByTagName("body")[0].appendChild(el);
        }
      }
    if("complete" !== document.readyState) {
      window.addEventListener ? window.addEventListener("load", t) : window.attachEvent && window.attachEvent("onload", t)
    } else {
      t();
    }
  })();`

export const generateOneTrustScript = () => `
  function OptanonWrapper() {}
`
