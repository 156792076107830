export const getSelectedVehicle = ({ app }) => {
  const mainGarageContentList = app.data.contents[0].mainGarageContent
  const userVehicleDetails = mainGarageContentList.filter(item => item.name && item.name === 'BOTGarageVehicles')
  const selectedVehicle = userVehicleDetails[0].UserVehicleDetails.userVehicle.filter(item => item.isActiveVehicle)

  return selectedVehicle[0]
}

export const getShopByLicencePlate = state => state.shopByLicencePlate
export const getStates = state => getShopByLicencePlate(state).states


export const getUserVehicleDetails = ({ app }) => {
  const mainGarageContentList = app.data.contents[0].mainGarageContent
  const userVehicleDetails = mainGarageContentList.filter(item => item.name && item.name === 'BOTGarageVehicles')

  return userVehicleDetails[0].UserVehicleDetails
}
