import { withProps } from 'recompose'
import { ReactSelectDropdownInput } from 'components/common/FormElements/Input'
import { shouldDropdownBeCleaned } from './helpers'

const withPropsAdapter = withProps(({ options = [], error, value, innerRef, isDisabled = false }) => {
  const isError = Boolean(error)
  const disabled = !options.length
  return {
    isError,
    disabled: isDisabled || disabled,
    ref: innerRef,
    value: shouldDropdownBeCleaned({ isError, disabled, value }) ? null : value
  }
})

export default withPropsAdapter(ReactSelectDropdownInput)
