export default [
  'BOTAccessYourAppointmentInfo',
  'BOTMyAppointmentsDetails',
  'BOTNoAccountMessage',
  'ForgotEmail',
  'ForgotPassword',
  'RegisterUser',
  'RegisterUserForm',
  'ResetForgottenPassword'
]

/*
Appears on:
/my-account/forgot-email,
/my-account/forgot-password,
/my-account/my-appointments?loggedin=true,
/my-account/my-appointments,
/my-account/register,
/my-account/register/registration-form,
/my-account/reset-password
*/
