import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Modal from 'components/common/Modal'
import ShareViaEmailForm from './components/ShareViaEmailForm'

const ShareViaEmailModal = ({
  handleHide,
  title,
  description,
  ...props
}) =>
  <Modal closeHandler={ handleHide } title={ title }>
    <Instruction>{ description }</Instruction>
    <ShareViaEmailForm { ...props } handleHide={ handleHide } />
  </Modal>

export default ShareViaEmailModal

ShareViaEmailModal.propTypes = {
  description: PropTypes.string,
  handleHide: PropTypes.func,
  title: PropTypes.string
}

const Instruction = styled.div`
  ${Mixin.responsive('margin', '0 0 25px', '3px 0 30px')}
`
