import { compose, withProps, withHandlers, lifecycle } from 'recompose'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import OptionsList from './OptionsList'
import { callPropperSelectHandle, scrollCurrentStepIntoView } from '../../helpers'
import {
  REAR_TIRE_SIZE_STEP_NAMES,
  TIRE_SIZE_TITLE,
  YMME_TITLE
} from '../../constant'
import { FRONT, REAR } from 'components/VehicleConfigurator/components/withTireSize/constants'
import { formatTireSize } from 'components/OneColumnSearchResultPage/helpers'

const withOptionsListHandlers = withHandlers({
  nextStepHandler: ({
    setData,
    data,
    setStep,
    step,
    setSearch,
    handleYearSelect,
    ymmeName,
    handleMakeSelect,
    handleModelSelect,
    handleSubModelSelect,
    handleSelectValue,
    stepNames,
    place,
    setSizeName,
    activePosition,
    rearTireSizeChecked,
    setActivePosition,
    setStepNames,
    setFullFrontTireSizeLabel
  }) => (value) => {
    let stepName = ''

    if (activePosition) {
      stepName = activePosition === REAR ? stepNames[step - 3].value : stepNames[step].value
    } else {
      stepName = stepNames[step]
    }

    const endpointValue = value.value
    const isLastFrontStep = (stepNames.length - 1 === step) && (activePosition === FRONT)

    if (place === YMME_TITLE) {
      callPropperSelectHandle({
        handleYearSelect,
        handleMakeSelect,
        handleModelSelect,
        handleSubModelSelect,
        step,
        value: endpointValue,
        ymmeName
      })
    }

    if (place === TIRE_SIZE_TITLE) {
      handleSelectValue({ position: activePosition, name: stepName, value: endpointValue })
      setSizeName(stepName)
    }

    setSearch('')

    if (activePosition) {
      if (isLastFrontStep && rearTireSizeChecked) {
        const { width, ratio } = data.front
        const diameter = endpointValue
        const frontTireSizesLabel = formatTireSize(width.value, ratio.value, diameter)
        setFullFrontTireSizeLabel(frontTireSizesLabel)

        setActivePosition(REAR)
        setStepNames(REAR_TIRE_SIZE_STEP_NAMES)
        setSizeName('')
      }

      setData({
        ...cloneDeep(data),
        [activePosition]: {
          ...data[activePosition],
          [stepName]: value
        }
      })
    } else {
      setData({
        ...data,
        [stepName]: value
      })
    }

    setStep(step => step + 1)
    scrollCurrentStepIntoView(step)
  }
})

const withOptionsProps = withProps(({
  stepNames,
  step,
  onSubmit,
  nextStepHandler,
  rearTireSizeChecked,
  activePosition
}) => {
  const totalQtyOfFrontSteps = 3
  let isLastStep = stepNames.length - 1 === step
    || (activePosition === REAR && stepNames.length - 1 === step - totalQtyOfFrontSteps)

  if (rearTireSizeChecked && (activePosition === FRONT)) {
    isLastStep = false
  }

  return {
    click: isLastStep ? onSubmit : nextStepHandler
  }
})

const withOptionsListLifecycle = lifecycle({
  componentDidUpdate({ list: prevList }) {
    const { list } = this.props

    if (!isEqual(prevList, list)) {
      const optionsListElement = document.querySelector('.modal-body')
      optionsListElement.scrollTop = 0
    }
  }
})

export default compose(
  withOptionsListHandlers,
  withOptionsProps,
  withOptionsListLifecycle
)(OptionsList)

