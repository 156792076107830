import { withHandlers, withProps } from 'recompose'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import withOuterClickDetection from 'components/common/hoc/withOuterClickDetection'
import AutocompleteInput from './AutocompleteInput'

const DEBOUNCE_INTERVAL = 500

const withDebouncedFetchSuggestions = withProps(({ onFetchSuggestions = () => {} }) => ({
  onFetchSuggestions: debounce(onFetchSuggestions, DEBOUNCE_INTERVAL)
}))

const withChangeHandler = withHandlers({
  handleEnterText: ({ onFetchSuggestions, onChange }) => event => {
    onChange(event)

    onFetchSuggestions(event.target.value)
  },

  handleOptionSelect: ({ onBlur, onChooseSuggestion }) => (...args) => {
    onChooseSuggestion(...args)
    onBlur()
  },

  onOutsideClick: ({ onBlur }) => () => onBlur()
})

export default compose(
  withDebouncedFetchSuggestions,
  withChangeHandler,
  withOuterClickDetection
)(AutocompleteInput)
