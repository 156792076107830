import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import Text from 'components/common/Text/Text'
import VehicleImage from 'components/common/VehicleImage'
import { DefaultPopoverInnerContent, DefaultPopoverLink } from 'components/common/Popover/elements'

export const VehicleNickname = styled.h2`
  margin: 0 0 46px 0;
  text-transform: capitalize;
  word-break: break-word;
  color: ${Theme.colors.black_new};
  ${Mixin.font.GothamBold(24)}
  transform: scale(1, 1.3);
`

export const CompactVehicleNickname = styled(VehicleNickname)`
  margin: 0 0 10px 0;
  ${Mixin.font.GothamBold(18, 700, 21)}
  text-align: left;
  transform: scale(1, 1.3);
`

export const VehicleYMME = styled.p`
  ${Mixin.font.GothamBook(14, 400, 18)}
  color: ${Theme.colors.medium_grey};
  margin: 0 0 24px 0;
`

export const CompactVehicleYMME = styled(VehicleYMME)`
  ${Mixin.font.GothamBook(14, 400, 17)}
  text-align: left;
  margin: 0 0 8px 0;
`

export const VehicleImg = styled(VehicleImage)`
  width: 100%;
  max-width: 250px;
  height: auto;
  margin: 0 auto 24px;
`

export const CompactVehicleImg = styled(VehicleImg)`
  max-width: unset;
  margin: 0;
`

export const TrailerImage = styled(Icon)`
  width: 100%;
  max-width: 230px;
  height: auto;
  margin: 0 auto 24px;
`

export const CompactTrailerImage = styled(TrailerImage)`
  margin: 0;
`

export const MileageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 46px;
`

export const CompactMileageWrapper = styled(MileageWrapper)`
  margin-bottom: 0;
  justify-content: start;
  align-items: center;
`

export const MileageIcon = styled(Icon)`
  width: 64px;
`

export const MileageValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  transform: scale(1, 1.3);
`

export const MileageValue = styled(Text)`
  ${Mixin.font.GothamBold(48, 700, 38)}
  color: ${Theme.colors.black_new};
  margin: 0 10px 0 14px;
  transform: scale(1, 1.3);
`

export const CompactMileageValue = styled(MileageValue)`
  ${Mixin.font.GothamBold(18, 700, 19)}
  color: #5e5e5e;
  margin: 0 10px 0 0;
`

export const MileageEntity = styled.div`
  ${Mixin.font.GothamBold(20, 700, 16)}
  color: ${Theme.colors.black_new};
`

export const EditIcon = styled(Icon)`
  width: 19px;
  height: 19px;
  cursor: pointer;
  margin: 0 0 0 15px;
`

export const CompactEditIcon = styled(EditIcon)`
  height: 100%;
  width: auto;
  margin: 0;
`

export const ActionButton = styled(Button)`
  cursor: pointer;
`

export const PopoverInnerContent = styled(DefaultPopoverInnerContent)`
  width: 150px;
  left: auto;
  right: 0;
  top: -15px;
  bottom: auto;

  &::after {
    content: none;
  }
`

export const PopoverLink = styled(Button)`
  padding: 0;
  width: 30px;
  height: 30px;
  transform: none;
  border: none;
  background-color: ${Theme.colors.transparent};
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background: ${Theme.colors.off_white};
    border: none;
  }
`

export const Selected = styled.div`
  position: absolute;
  color: ${Theme.colors.green};
  top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 23px;
  padding: 6px 9px;
  border: 1px solid ${Theme.colors.green};
  border-radius: 10px;
  background: rgba(15, 135, 75, 0.08);
  ${Mixin.font.GothamBook(14)};
`

export const Circle = styled(Icon)`
  margin-right: 7px;
`

export const OptionsDwopdownWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 22px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CustomPopoverLink = styled(DefaultPopoverLink)`
  ${Mixin.font.GothamMedium(14, 500, 16)}
  margin: 0 0 0 15px;
  color: ${Theme.colors.black_new};

  &:hover {
    text-decoration: none;
  }
`

export const CustomPopoverIneerContent = styled(DefaultPopoverInnerContent)`
  text-transform: none;
  width: 165px;
`
