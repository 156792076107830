import { STORAGE_CONFIRM_TIRE_SIZE } from 'constant'
import { TIRE_SIZES, VEHICLE_DETAILS, EXTRA_VEHICLE_DETAILS } from './constants'
import { getConfirmTireSizeData } from './selectors'
import {
  setVehicleDetails,
  setVehicleTireSizes,
  loadConfirmTireSizeData,
  cleanConfirmTireSizeData,
  setExtraVehicleDetails
} from 'actions/confirmTireSize'


export default store => next => action => {
  if (action.type === String(loadConfirmTireSizeData)) {
    const confirmTireSizeFromStorage = sessionStorage.getItem(STORAGE_CONFIRM_TIRE_SIZE)

    let confirmTireSizeParsed

    try {
      confirmTireSizeParsed = JSON.parse(confirmTireSizeFromStorage)
    } catch (err) {
      console.error(err)
    }

    return next({ type: action.type, payload: confirmTireSizeParsed })
  }

  const confirmTireSizeData = getConfirmTireSizeData(store.getState())

  if (action.type === String(setVehicleDetails)) {
    sessionStorage.setItem(STORAGE_CONFIRM_TIRE_SIZE, JSON.stringify({
      ...confirmTireSizeData,
      [VEHICLE_DETAILS]: action.payload
    }))
  }

  if (action.type === String(setVehicleTireSizes)) {
    sessionStorage.setItem(STORAGE_CONFIRM_TIRE_SIZE, JSON.stringify({
      ...confirmTireSizeData,
      [TIRE_SIZES]: action.payload
    }))
  }

  if (action.type === String(setExtraVehicleDetails)) {
    sessionStorage.setItem(STORAGE_CONFIRM_TIRE_SIZE, JSON.stringify({
      ...confirmTireSizeData,
      [EXTRA_VEHICLE_DETAILS]: action.payload
    }))
  }

  if (action.type === String(cleanConfirmTireSizeData)) {
    sessionStorage.removeItem(STORAGE_CONFIRM_TIRE_SIZE)
  }

  return next(action)
}
