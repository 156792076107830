import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import Link from 'components/common/Link/Link'
import Media from 'components/common/Media'

const AccordionItem = ({
  pageLocation: {
    pathname
  },
  onClick,
  isActiveCategory,
  onEnterKeypress,
  handleHide = () => {},
  item: {
    categoryName,
    articlesList
  }
}) => (
  <>
    <Wrapper
      className="accordion"
      contentVisible={ isActiveCategory }
      href="#"
      onClick={ onClick }
      onKeyDown={ onEnterKeypress }>
      <Title>
        {categoryName}
      </Title>
      <ArrowIcon
        asset={ isActiveCategory ? 'arrow-up-light-blue' : 'arrow-down-light-blue' }
        alt="Arrow icon" />
    </Wrapper>
    {
      isActiveCategory &&
      <div>
        {articlesList.map(({ articleName, articleUrl }) => (
          <LinkItem
            key={ articleName }
            to={ articleUrl }
            isActiveArticle={ pathname === articleUrl }
            onClick={ handleHide }>
            {articleName}
          </LinkItem>
        ))}
      </div>
    }
  </>
)

AccordionItem.propTypes = {
  handleHide: PropTypes.func,
  isActiveCategory: PropTypes.bool,
  item: PropTypes.shape(),
  onClick: PropTypes.func,
  onEnterKeypress: PropTypes.func,
  pageLocation: PropTypes.string
}

export default AccordionItem

const Wrapper = styled.a`
  display: flex;
  width: 100%;
  padding: 25px 10px 25px 20px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  border-bottom: none;
  text-decoration: none;
  ${props => props.contentVisible && css`
    background-color: #f0f0f0;
  `}

  &:last-child {
    border: 1px solid #d7d7d7;
  }
`

const Title = styled.p`
  margin: 0 auto 0 0;
  font-weight: 600;
  color: ${Theme.colors.blue};
`

const ArrowIcon = styled(Icon)`
  width: 20px;
`

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: 0 20px;
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  ${Media.desktop`
    &:hover {
      color: ${Theme.colors.bright_red_new}
      text-decoration: none;
    }
  `}
  ${props => props.isActiveArticle && css`
    color: ${Theme.colors.black}
    pointer-events: none;
    font-weight: 700;
  `}
`
