import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import { FooterMenuLink } from './FooterMenuLink'
import { expandQuickAppointmentButton } from 'helpers/custom-events'
import { getCurrentStoreIsE2EAppointment } from 'selectors/getCurrentStore'
import { REQUEST_APPOINTMENT, SCHEDULE_APPOINTMENT } from 'constant'

const mapStateToProps = (state) => ({
  isE2EAppointment: getCurrentStoreIsE2EAppointment(state)
})

const withHandleExpandQuickAppointmentButton = withProps({
  onClick: event => {
    event.preventDefault()

    expandQuickAppointmentButton()
  }
})

const withQuickAppointmentLinkProps = withProps(({
  isE2EAppointment,
  displayName
}) => ({
  displayName: (displayName === REQUEST_APPOINTMENT && isE2EAppointment) ? SCHEDULE_APPOINTMENT : displayName
}))

export default compose(
  withType({ type: 'BOTAppointmentFooterMenuLink', placeholders: ['links']}),
  connect(mapStateToProps),
  withHandleExpandQuickAppointmentButton,
  withQuickAppointmentLinkProps
)(FooterMenuLink)

