import { rest, isApiError } from 'services'
import { FRONT, REAR, WIDTH, RATIO, DIAMETER } from '../VehicleConfigurator/components/withTireSize/constants'
import {
  getFieldCurrentValue,
  requestValuesFailure,
  resetSomeFields,
  selectValue,
  setValues
} from '../VehicleConfigurator/components/withTireSize/actions'
import { getAllValues } from '../VehicleConfigurator/components/withTireSize/selectors'
import { getSizeStructure } from './helpers'
import {
  getNextName,
  getRequestData
} from '../VehicleConfigurator/components/withTireSize/helpers'
import { navigateToSearchByTireSizes } from 'actions/shopByTires'
import { addTrailer } from 'actions/addVehicle'
import { extractSizes } from 'helpers/createTireSizeString'
import { hideNewShopTiresModal } from '../NewShopTiresModal/actions'

export const fetchSize = ({
  position,
  name,
  setOptionsData,
  setEndpointData,
  setDataLoading,
  isFromTrailer
}) => async (dispatch, getState) => {
  const nextName = getNextName(name)
  const tireSizeValues = getAllValues(getState())[position]
  const customTireSizeRequestData = getRequestData(name, tireSizeValues)
  let tireSizeParams = { ...customTireSizeRequestData }

  if (isFromTrailer) {
    tireSizeParams = { ...tireSizeParams, isTrailer: true }
  }

  // TODO: Add logic to check if it's a trailers page and add additional boolean payload attribute 'isTrailer'
  try {
    const {
      tireSizes: {
        type,
        tireSize = []
      } = {}
    } = await rest.api.customTireSize(tireSizeParams)
    setOptionsData(getSizeStructure(tireSize))
    setEndpointData(getSizeStructure(tireSize))
    dispatch(setValues({ position, name: type, value: tireSize }))
    setDataLoading(false)
  } catch (err) {
    if (isApiError(err)) {
      dispatch(requestValuesFailure({ position, name: nextName, error: err.status ? err.status.description : '' }))
    }
  }
}

export const handleSelectValue = ({
  position,
  name,
  value
} = {}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(name, position, getState)) {
    dispatch(selectValue({ position, name, value }))
  }
}

export const navigateToTrailerSERPPage = () => async (dispatch, getState) => {
  const tireSizes = getAllValues(getState())
  const extraQueryParams = ''
  const isTrailer = true
  const { tireFrontSize, tireRearSize } = extractSizes(tireSizes)

  await dispatch(addTrailer({
    TireSize: tireFrontSize,
    RearTireSize: tireRearSize
  }))
  await dispatch(hideNewShopTiresModal())
  dispatch(navigateToSearchByTireSizes(tireSizes, extraQueryParams, isTrailer))
}

export const navigateToSERPPage = ({ queryParams = '' }) => async (dispatch, getState) => {
  const tireSizes = getAllValues(getState())
  await dispatch(hideNewShopTiresModal())
  dispatch(navigateToSearchByTireSizes(tireSizes, queryParams))
}

export const initialize = ({
  name,
  position = FRONT,
  setOptionsData,
  setEndpointData,
  setDataLoading,
  isFromTrailer
}) => async (dispatch, getState) => {
  const width = getFieldCurrentValue(WIDTH, position, getState)
  const ratio = getFieldCurrentValue(RATIO, position, getState)
  const diameter = getFieldCurrentValue(DIAMETER, position, getState)

  if (!width) {
    await dispatch(
      fetchSize({
        name,
        position,
        setOptionsData,
        setEndpointData,
        setDataLoading,
        isFromTrailer
      })
    )
  }

  if (!ratio && width) {
    await dispatch(
      fetchSize({
        name,
        position,
        setOptionsData,
        setEndpointData,
        setDataLoading
      })
    )
  }

  if (!diameter && ratio) {
    await dispatch(
      fetchSize({
        name,
        position,
        setOptionsData,
        setEndpointData,
        setDataLoading
      })
    )
  }
}

export const handlerTireSizeResetOnPrevStep = ({ positions, names }) => dispatch => {
  if (positions.length > 1) {
    const frontNames = names.slice(0, -3)
    const rearNames = names.slice(-3)

    dispatch(resetSomeFields({ position: FRONT, name: frontNames }))
    dispatch(resetSomeFields({ position: REAR, name: rearNames }))
  } else {
    dispatch(resetSomeFields({ position: positions[0], name: names }))
  }
}
