import { connect } from 'react-redux'
import { hide, show } from 'redux-modal'
import { compose, withHandlers, withProps, lifecycle } from 'recompose'
import isEqual from 'lodash/isEqual'
import withShowVehicleConfiguratorModalPreconfigured from 'components/common/hoc/withShowVehicleConfiguratorModalPreconfigured'
import { navigateToSearchByVehicle } from 'actions/shopByVehicle'
import { updateVehicle } from 'actions/addVehicle'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { getTireSizes } from 'components/VehicleConfigurator/actions'
import { navigateToTireSearch } from 'components/PromotionDetailsPage/actions'
import { init } from 'components/Main/actions'
import { getHasActiveVehicleStatus } from 'selectors'
import { getLocationPathname } from 'selectors/getPageLocation'
import { getActiveVehicleId, getActiveVehicleTireSize } from 'selectors/getActiveVehicle'
import { getConfirmTireSizeData } from 'components/VehicleConfigurator/components/ConfirmTireSize/components/withTireSizeConfirmation/selectors'
import { getYmmeForConfirmTireSizePage } from './withYmme/selectors'
import { triggerExtraTiresSelectCallbacks } from 'helpers/ymme'
import { getEligibleTiresUrl } from 'components/PromotionDetailsPage/helpers'
import { CONFIRM_TIRE_SIZE_MODAL, TIRE_SIZES_NOT_FOUND_MODAL, VEHICLE_CONFIGURATOR_TIRE_SIZE_TAB, CONFIRM_TIRE_SIZES } from 'constant'
import { YMME_NAME } from 'components/VehicleConfigurator/components/ShopByVehicle/constants'
import isWinterTiresPage from 'helpers/isWinterTiresPage'
import isEVTiresPage from 'helpers/isEVTiresPage'
import { refresh } from 'actions/router'

const mapStateToProps = (state, { userVehicleId = '' }) => {
  const { vehicleDetails: { vehicleId }} = getConfirmTireSizeData(state) || {}

  return {
    vehicleId,
    vehicleDetails: getYmmeForConfirmTireSizePage(state, { ymmeName: YMME_NAME }),
    userVehicleId: userVehicleId || getActiveVehicleId(state),
    enableOnClick: getHasActiveVehicleStatus(state) && !getActiveVehicleTireSize(state),
    pathname: getLocationPathname(state),
    isWinterTiresPage: isWinterTiresPage(getLocationPathname(state)),
    isEVTiresPage: isEVTiresPage(getLocationPathname(state))
  }
}

const withLifecycle = lifecycle({
  componentDidMount() {
    const {
      vehicleId,
      setVehicleDetails,
      vehicleDetails,
      pathname
    } = this.props
    if (!vehicleId && vehicleDetails && pathname !== CONFIRM_TIRE_SIZES) {
      setVehicleDetails(vehicleDetails)
    }
  },
  componentDidUpdate({ vehicleDetails: prevVehicleDetails }) {
    const {
      setVehicleDetails,
      vehicleDetails,
      pathname
    } = this.props
    if (!isEqual(prevVehicleDetails, vehicleDetails) && vehicleDetails && pathname !== CONFIRM_TIRE_SIZES) {
      setVehicleDetails(vehicleDetails)
    }
  }
})

const withShowConfirmTireSizeModalPreconfigured = withHandlers({
  showConfirmTireSizeModalPreconfigured: ({
    show,
    hide,
    updateVehicle,
    navigateToSearchByVehicle,
    userVehicleId,
    navigateToTireSearch,
    init,
    pathname,
    isWinterTiresPage,
    isEVTiresPage,
    refresh
  }) => ({
    promoDetailsProps,
    garageUserVehicleId,
    productDetailsPageProps: {
      preventNavigation,
      tiresSelectExtraCallbacks
    } = {}}) =>
    show(CONFIRM_TIRE_SIZE_MODAL, {
      handleTiresSelect: async ({
        vehicle,
        frontTireSize,
        rearTireSize,
        loadIndex,
        rearLoadIndex,
        speedRating,
        rearSpeedRating
      }) => {
        await updateVehicle({
          ...vehicle,
          tireFrontSize: frontTireSize,
          tireRearSize: rearTireSize,
          userVehicleId: garageUserVehicleId || userVehicleId,
          loadIndex,
          rearLoadIndex,
          speedRating,
          rearSpeedRating
        })
        hide(CONFIRM_TIRE_SIZE_MODAL)
        if (!preventNavigation) {
          if (promoDetailsProps) {
            const url = getEligibleTiresUrl({
              ...promoDetailsProps, vehicle: { ...vehicle, frontTireSize, rearTireSize }
            })
            navigateToTireSearch({ url })
          } else if (isWinterTiresPage || isEVTiresPage) {
            refresh()
          } else {
            navigateToSearchByVehicle({ ...vehicle, frontTireSize, rearTireSize })
          }
        } else {
          init({ pathname })
        }
        triggerExtraTiresSelectCallbacks({ tiresSelectExtraCallbacks, userVehicleId })
      }
    })
})

const withTireSizeNotFoundModalProps = withProps(({ hide, showVehicleConfiguratorModalPreconfigured }) => ({
  tireSizesNotFoundModalProps: {
    onContinueClick: () => showVehicleConfiguratorModalPreconfigured({ activeTab: VEHICLE_CONFIGURATOR_TIRE_SIZE_TAB }),
    onCancelClick: () => hide(TIRE_SIZES_NOT_FOUND_MODAL)
  }
}))

const withTriggerConfirmTireSizemodal = withHandlers({
  triggerConfirmTireSizeModal: ({
    getTireSizes,
    vehicleId,
    tireSizesNotFoundModalProps,
    showConfirmTireSizeModalPreconfigured,
    ...props
  }) => (e, promoDetailsProps = null, garageUserVehicleId = '', productDetailsPageProps = {}, additionalVehicleId) => {
    e && e.preventDefault() && e.preventDefault()

    getTireSizes({ vehicleId: additionalVehicleId || vehicleId, tireSizesNotFoundModalProps })
      .then(() =>
        showConfirmTireSizeModalPreconfigured({
          promoDetailsProps,
          garageUserVehicleId,
          productDetailsPageProps
        }))
  }
})

const withConfigureOnClick = withProps(({ triggerConfirmTireSizeModal, enableOnClick }) => ({
  onClick: enableOnClick ? triggerConfirmTireSizeModal : undefined
}))

export default compose(
  connect(mapStateToProps, {
    show,
    hide,
    navigateToSearchByVehicle,
    updateVehicle,
    getTireSizes,
    setVehicleDetails,
    navigateToTireSearch,
    init,
    refresh
  }),
  withLifecycle,
  withShowConfirmTireSizeModalPreconfigured,
  withShowVehicleConfiguratorModalPreconfigured,
  withTireSizeNotFoundModalProps,
  withTriggerConfirmTireSizemodal,
  withConfigureOnClick
)
