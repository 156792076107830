import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'redux-form'
import Media from 'components/common/Media'
import SearchInput from './components/SearchModalInput'
import { Wrapper as BOTGlobalSearchOptionsWrapper } from '../GlobalSearchModal/components/BOTGlobalSearchOptions'

// TODO check for passing corrected prop to Field
const SearchModalForm = ({ handleSubmit, inputProps }) =>
  <Wrapper>
    <Field
      data-at-globalsearchmodal-searchinput
      { ...inputProps }
      component={ SearchInput }
      handleSubmit={ handleSubmit } />
  </Wrapper>

export default SearchModalForm

SearchModalForm.propTypes = {
  handleSubmit: PropTypes.func,
  inputProps: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string
  })
}

const Wrapper = styled.div`
  margin-top: 8px;
  ${Media.desktop`
    margin-top: 21px;
  `}

  & + ${BOTGlobalSearchOptionsWrapper} {
    margin-top: 30px;
  }
`
