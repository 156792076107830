import React from 'react'
import PropTypes from 'prop-types'
import Theme from 'components/common/Theme/Theme'
import styled, { css } from 'styled-components'
import { components } from 'react-select'

const ReactSelectOption = ({
  children,
  isFocused,
  innerProps,
  setKeypressScopeRef,
  ...props
}) =>
  <li
    { ...innerProps }
    ref={ setKeypressScopeRef }
    role="option"
    aria-selected={ isFocused }
    tabIndex="0" >
    <StyledOption { ...props }>
      { children }
    </StyledOption>
  </li>

ReactSelectOption.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape(),
  isFocused: PropTypes.bool,
  selectProps: PropTypes.shape(),
  setKeypressScopeRef: PropTypes.func
}

export default ReactSelectOption

const StyledOption = styled(components.Option)`
  && {
    ${props => props.isSelected && css`
      background-color: ${Theme.colors.red};
      color: ${Theme.colors.white};
    `}
  }

  :active {
    &&& {
      background-color: ${Theme.colors.red};
      color: ${Theme.colors.white};
    }
  }

  :hover {
    background-color: ${Theme.colors.grey};
  }
`
