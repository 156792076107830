import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 288px;
  ${Mixin.property('full', `
    width: 100%;
  `)}
`

export const Options = styled.div`
  position: absolute;
  top: 40px;
  z-index: 1100;
  padding: 10px 0;
  width: 100%;
  max-height: ${props => props.maxHeight || 250}px;
  overflow-y: auto;
  background-color: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.transparent_light_blue};
  border-top: none;
  box-shadow: 0 0 2px ${Theme.colors.transparent_blue};
`
