import { handleActions } from 'redux-actions'
import dayjs from 'dayjs'
import {
  loadShoppingData,
  setShoppingData,
  cleanShoppingData,
  setFreeAlignmentData,
  setAvailabilityData,
  setCalendarStartDateForAddedSkus,
  setCalendarStartDateForRemovedSkus,
  resetCalendarStartDate,
  setCalendarStartDateToDefaultValue
} from 'actions/shoppingData'
import { QUOTED_ITEMN_AND_SERVICES, FREE_ALIGNMENT_TOOLTIP } from './constants'

// TODO remove mocks from initial state after finish integration.
const initialState = {
  _links: null,
  _state: null,
  calendarStartDate: null,
  totalCount: 0,
  availability: {},
  [QUOTED_ITEMN_AND_SERVICES]: {
    [FREE_ALIGNMENT_TOOLTIP]: {},
    otdPricingToolTipMessage: '',
    installationKits: [],
    orderId: '',
    quoteSummary: {},
    timeSlot: {},
    appliedPromotions: [],
    productPriceChangedQty: 0,
    tppToolTipMessage: '',
    promoCode: [],
    services: [],
    products: []
  }
}

const handleSetFreeAlignmentData = (state, payload) => ({
  ...state,
  [QUOTED_ITEMN_AND_SERVICES]: {
    ...state[QUOTED_ITEMN_AND_SERVICES],
    [FREE_ALIGNMENT_TOOLTIP]: {
      ...state[QUOTED_ITEMN_AND_SERVICES][FREE_ALIGNMENT_TOOLTIP],
      ...payload
    }
  }
})

const handleSetAvailabilityData = (state, payload) => ({
  ...state,
  availability: payload
})

const handleSetCalendarStartDateToDefaultValue = (state, payload) => ({
  ...state,
  calendarStartDate: payload
})

const handleSetCalendarStartDateForAddedSkus = (state, payload) => {
  let { calendarStartDate } = state
  if (payload) {
    const currentDate = dayjs(payload.split(' ')[0], 'MM-DD-YYYY').format('MM/DD/YYYY')
    /*
      This condition is added to stop calling calendar apis call if all the skus added to cart
      has AvailableToday status(i.e current date) bcoz bydefault the calendar api always return
      slots for current date only
    */
    if (!calendarStartDate && dayjs(currentDate).isAfter(dayjs().format('MM/DD/YYYY'))) {
      return { ...state, calendarStartDate: currentDate }
    }
    // checking whether the calendarStartDate comes before the currentdate
    // in order to fetch the appointment slots from the furthest date
    if (dayjs(calendarStartDate).isBefore(dayjs(currentDate))) {
      calendarStartDate = currentDate
    }
  }
  return { ...state, calendarStartDate }
}

/*
  This function is used to tackle all the appointment slots updation in case skus are removed.
  It receives 2 values in the payload 1. bvId of the removed product 2. Items added to to cart.
  It filters out remaining skus on the basis of removed product bvId.
  It loops throug the remaining skus and then looks for the next future date to be used
  for updating the appointment slots.
*/
const handleSetCalendarStartDateForRemovedSkus = (state, payload) => {
  const { skuId, addedSkus = []} = payload
  const remainingSkus = addedSkus?.filter(item => item.partNo !== skuId)
  let nextFutureDate = dayjs().format('MM/DD/YYYY')

  if (remainingSkus.length) {
    remainingSkus.forEach(sku => {
      if (sku.availableAsOf) {
        const availableDate = dayjs(sku.availableAsOf.split(' ')[0], 'MM-DD-YYYY').format('MM/DD/YYYY')
        if (nextFutureDate && dayjs(nextFutureDate).isBefore(dayjs(availableDate))) {
          nextFutureDate = availableDate
        }
      }
    })
  }

  return { ...state, calendarStartDate: nextFutureDate }
}

const shoppingData = handleActions({
  [setShoppingData]: (state, { payload }) => ({ ...state, ...payload }),
  [loadShoppingData]: (state, { payload }) => payload || initialState,
  [cleanShoppingData]: () => initialState,
  [setFreeAlignmentData]: (state, { payload }) => handleSetFreeAlignmentData(state, payload),
  [setAvailabilityData]: (state, { payload }) => handleSetAvailabilityData(state, payload),
  [setCalendarStartDateForAddedSkus]: (state, { payload }) => handleSetCalendarStartDateForAddedSkus(state, payload),
  [setCalendarStartDateToDefaultValue]: (state, { payload }) =>
    handleSetCalendarStartDateToDefaultValue(state, payload),
  [setCalendarStartDateForRemovedSkus]: (state, { payload }) =>
    handleSetCalendarStartDateForRemovedSkus(state, payload),
  [resetCalendarStartDate]: (state) => ({ ...state, calendarStartDate: null })
}, initialState)

export default shoppingData
