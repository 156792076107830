import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/common/Button/Button'

const AddButton = ({ children, ...props }) =>
  <Button { ...props } full="mobile">
    { children }
  </Button>

AddButton.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  handleClick: PropTypes.func
}

export default AddButton
