import { INPUT_ERROR_PLACEHOLDER } from 'constant'
import { Validator } from 'services/validator'
import { SEARCH_FIELD_NAME } from '../constants'

export default Validator.combineValidators({
  [SEARCH_FIELD_NAME]: {
    validators: Validator.required(),
    errorMessage: INPUT_ERROR_PLACEHOLDER
  }
})
