import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HOME } from 'constant'
import Link from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'

const HeaderGroup = ({
  placeholders: {
    headerContent = []
  } = {},
  isQuotePage,
  isQuickAppointmentPage,
  ...props
}) => (
  isQuotePage || isQuickAppointmentPage
    ? (
      <LogoWrapper data-at-header-logo >
        <Link to={ HOME }>
          <Icon asset="logo" alt="BigO Tire" width={ 228 } height={ 27 } />
        </Link>
      </LogoWrapper>
    )
    : (
      <Group>
        <Wrapper role="menubar">{ headerContent }</Wrapper>
      </Group>
    )
)

HeaderGroup.propTypes = {
  '@type': PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  isQuickAppointmentPage: PropTypes.bool,
  isQuotePage: PropTypes.bool,
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    headerContent: PropTypes.arrayOf()
  })
}

export default HeaderGroup

const Group = styled.nav`
  display: table-cell;
  margin-right: auto;

  :first-child {
    text-align: left;
  }

  > ul > li {
    display: inline-block;

    :first-child {
      a::before,
      button::before {
        display: none;
      }
    }
  }
`

const Wrapper = styled.ul`
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
`

const LogoWrapper = styled.div`
  display: table-cell;
  width: 228px;
  text-align: center;
  vertical-align: middle;
  ${Media.desktop`
    margin: 0 auto 0 0;
  `}
`
