import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

dayjs.extend(calendar)

const timeDifferenceFormatting = {
  sameDay: '[Today], MMMM D, YYYY',
  nextDay: '[Tomorrow], MMMM D, YYYY',
  nextWeek: 'dddd, MMMM D, YYYY',
  sameElse: 'dddd, MMMM D, YYYY'
}

export const getFormattedTime = (appointmentDate = '', format) =>
  appointmentDate ? dayjs(appointmentDate, format).calendar(null, timeDifferenceFormatting) : ''

export const getDateByFormat = ({ date = '', format }) =>
  date ? dayjs(date).format(format) : ''

const dateRegex = /(\d{4})-(\d{2})-(\d{2})/

export const normalizeDate = (date = '') => {
  const matchedDate = date.match(dateRegex)
  return matchedDate ? `${matchedDate[2]}/${matchedDate[3]}/${matchedDate[1]}` : date
}
