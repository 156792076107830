import { inputTypes } from 'components/common/FormElements/Input'
import { LICENCE_NAME, STATE_NAME, LICENCE_MAX_LENGTH } from './constants'

export default stateOptions => ({
  licence: {
    name: LICENCE_NAME,
    maxLength: LICENCE_MAX_LENGTH,
    label: 'License Plate Number (no dashes)',
    required: true
  },

  state: {
    name: STATE_NAME,
    label: 'Select State',
    inputType: inputTypes.SELECT_DROPDOWN_INPUT,
    options: stateOptions,
    isSearchable: false,
    required: true
  }
})
