import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/common/Modal'

const ContentModal = ({
  data: { content, title, modalHeight, modalWidth, hideScrollY } = {},
  handleHide
}) =>
  <Modal
    title={ title || '' }
    closeHandler={ handleHide }
    modalHeight={ modalHeight }
    modalWidth={ modalWidth }
    hideScrollY={ hideScrollY }>
    <ModalWrapper dangerouslySetInnerHTML={{ __html: content }} />
  </Modal>

export default ContentModal

ContentModal.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string
  }),
  handleHide: PropTypes.func
}

const ModalWrapper = styled.div`
  height: 100%;
`
