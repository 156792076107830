import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  TODAYS_HOURS,
  TOMORROW_HOURS,
  CLOSED_TODAY,
  CLOSED_TOMORROW,
  VIEW_STORE_DETAILS,
  CONTACT_STORE_MODAL_TITLE,
  RIGHT_CONTENT_WRAPPER
} from './constants'
import Media from 'components/common/Media'
import { formatPhoneNumber } from 'helpers/phone-number'
import { Map, MapMarker } from 'components/common/GoogleMaps'
import { getLeftPostionInPixelsById } from 'src/helpers/utils'
import {
  CallStoreModal,
  CallText,
  PhoneLinkText,
  MainContentWrapper,
  StoreLocationOnMap,
  RightContentWrapper,
  StoreAddress,
  TodaysTimeWrapper,
  DisplayTimeWrapper,
  TimeTextHeading,
  TimeTextValue,
  TomorrowTimeWrapper,
  StoreDetailsCta
} from './styles'

const ContactStoreModal = React.memo(({
  storeDetails: {
    storeComingOpenHours,
    storeComingClosedHours,
    tomorrowStoreComingOpenHours,
    tomorrowStoreComingClosedHours,
    phoneNumbers,
    address: {
      address1,
      city,
      state,
      zipcode,
      latitude,
      longitude
    } = {},
    storeDetailsUrl
  } = {},
  closeContactStoreModal,
  dynamicTop,
  storeOpenToday,
  storeOpenTomorrow,
  storeSpecialHoursToday,
  storeSpecialHoursTomorrow
}) => {
  const MAP_LINK = `https://www.google.com/maps/?q=${address1},${city},${state},${zipcode}`

  const [contactNumberDynamicLeft, setContactNumberDynamicLeft] = useState()

  useEffect(() => {
    setContactNumberDynamicLeft(getLeftPostionInPixelsById({ id: RIGHT_CONTENT_WRAPPER }))
  }, [])

  return (
    <CallStoreModal
      closeHandler={ closeContactStoreModal }
      dynamicTop={ dynamicTop }
      title={ CONTACT_STORE_MODAL_TITLE }>
      { <div>
        <Media.Desktop>
          <CallText>
            { formatPhoneNumber(phoneNumbers[0]) }
          </CallText>
        </Media.Desktop>
        <Media.Mobile>
          <PhoneLinkText
            number={ formatPhoneNumber(phoneNumbers[0]) }
            contactNumberDynamicLeft={ contactNumberDynamicLeft } />
        </Media.Mobile>
        <MainContentWrapper>
          <StoreLocationOnMap>
            <Map center={{ longitude, latitude }} defaultZoom={ 8 } >
              <MapMarker position={{ longitude, latitude }} />
            </Map>
          </StoreLocationOnMap>
          <RightContentWrapper id={ RIGHT_CONTENT_WRAPPER }>
            <StoreAddress target="_blank" href={ MAP_LINK }>
              { address1 }, { city }, { state }, { zipcode }
            </StoreAddress>
            <TodaysTimeWrapper>
              { storeOpenToday
                ? <DisplayTimeWrapper hidden={ !storeComingOpenHours || !storeComingClosedHours }>
                  <TimeTextHeading>
                    {TODAYS_HOURS}
                  </TimeTextHeading>
                  <TimeTextValue>
                    { storeSpecialHoursToday || `${storeComingOpenHours} - ${storeComingClosedHours}` }
                  </TimeTextValue>
                </DisplayTimeWrapper>
                : <TimeTextHeading>{CLOSED_TODAY}</TimeTextHeading>
              }
            </TodaysTimeWrapper>
            <TomorrowTimeWrapper>
              { storeOpenTomorrow
                ? <DisplayTimeWrapper hidden={ !tomorrowStoreComingOpenHours || !tomorrowStoreComingClosedHours }>
                  <TimeTextHeading>
                    {TOMORROW_HOURS}
                  </TimeTextHeading>
                  <TimeTextValue>
                    { storeSpecialHoursTomorrow || `${tomorrowStoreComingOpenHours} - ${tomorrowStoreComingClosedHours}` }
                  </TimeTextValue>
                </DisplayTimeWrapper>
                : <TimeTextHeading>{CLOSED_TOMORROW}</TimeTextHeading>
              }
            </TomorrowTimeWrapper>
            <StoreDetailsCta to={ storeDetailsUrl } onClick={ closeContactStoreModal }>
              {VIEW_STORE_DETAILS}
            </StoreDetailsCta>
          </RightContentWrapper>
        </MainContentWrapper>
      </div> }
    </CallStoreModal>
  )
})

ContactStoreModal.propTypes = {
  closeContactStoreModal: PropTypes.func,
  dynamicTop: PropTypes.number,
  storeDetails: PropTypes.shape(),
  storeOpenToday: PropTypes.bool,
  storeOpenTomorrow: PropTypes.bool,
  storeSpecialHoursToday: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  storeSpecialHoursTomorrow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
}

export default ContactStoreModal
