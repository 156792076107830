import { inputTypes } from 'components/common/FormElements/Input'
import {
  FIND_SERVICE_HISTORY_FORM_LICENSE_FIELD,
  FIND_SERVICE_HISTORY_FORM_STATE_FIELD,
  FIND_SERVICE_HISTORY_FORM_VIN_FIELD
} from './constants'

const createViewModel = (statesOptions = []) => ({
  license: {
    label: 'License Plate',
    name: FIND_SERVICE_HISTORY_FORM_LICENSE_FIELD,
    'data-at-findservicehistorymodal-licenseplate': true
  },
  state: {
    label: 'State',
    name: FIND_SERVICE_HISTORY_FORM_STATE_FIELD,
    inputType: inputTypes.SELECT_DROPDOWN_INPUT,
    options: statesOptions
  },
  vin: {
    name: FIND_SERVICE_HISTORY_FORM_VIN_FIELD,
    label: 'VIN',
    'data-at-findservicehistorymodal-vin': true
  }
})

export default createViewModel
