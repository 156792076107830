import React from 'react'
import Media from 'components/common/Media'
import TeaserDesktop from './TeaserDesktop'
import TeaserMobile from './TeaserMobile'

const Teaser = props =>
  <React.Fragment>
    <Media.Mobile>
      <TeaserMobile { ...props } />
    </Media.Mobile>
    <Media.Desktop>
      <TeaserDesktop { ...props } />
    </Media.Desktop>
  </React.Fragment>


export default Teaser
