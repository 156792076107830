import { isSSR } from 'helpers/isSSR'
import { GOOGLE_OPTIMIZE_ACTIVATION_EVENTS } from './constants'

/* global dataLayer */
if (isSSR && !global.dataLayer) {
  global.dataLayer = []
}

export default store => next => action => {
  try {
    if (isSSR && global.dataLayer.length) {
      global.dataLayer = []
    }

    GOOGLE_OPTIMIZE_ACTIVATION_EVENTS.includes(action.type) && dataLayer.push({ event: 'optimize.activate' })
  } catch (err) {
    console.log('Error occurred while Google Analytic event dispatch', err)
  }

  return next(action)
}
