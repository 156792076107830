import React from 'react'
import PropTypes from 'prop-types'
import getComponent from 'helpers/component-helper'
import Modal from 'components/common/Modal'

const UpdateCancelInstructionsModal = ({
  handleHide,
  updateCancelAppointment = []
}) =>
  <Modal closeHandler={ handleHide } title="NOTICE">
    { updateCancelAppointment.map(item => getComponent(item)) }
  </Modal>

UpdateCancelInstructionsModal.propTypes = {
  handleHide: PropTypes.func,
  updateCancelAppointment: PropTypes.arrayOf(PropTypes.shape())
}

export default UpdateCancelInstructionsModal
