import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'
import Mixin from 'components/common/Mixin'

const SuccessfulSubmitModal = ({
  handleHide,
  onConfirm,
  confirmButtonText = '',
  description = ''
}) =>
  <CustomModal title="Thank you!" closeHandler={ handleHide }>
    <Instructions data-at-instructions>{ description }</Instructions>
    { onConfirm && <Button data-at-confirm-button full="mobile" onClick={ onConfirm }>{ confirmButtonText }</Button> }
  </CustomModal>

SuccessfulSubmitModal.propTypes = {
  confirmButtonText: PropTypes.string,
  description: PropTypes.string,
  handleHide: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SuccessfulSubmitModal

const Instructions = styled.p`
  line-height: 22px;
  margin-top: 0;
`
const CustomModal = styled(Modal)`
  ${Mixin.responsive('padding', '25px 24px 46px', '60px 16px 40px')}
  ${Media.mobile`
    text-align: center;
  `}
`
