import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import { SearchInput } from 'components/NewTireSearch/SharedStyles'

const LicensePlateInput = ({ getLicensePlateHandler, plate, nextClick, error }) =>
  <>
    <SearchInputWrapper>
      <Wrapper>
        <PlateInput
          placeholder="License Plate Number (No Dashes)"
          autoComplete="off"
          value={ plate }
          onChange={ getLicensePlateHandler } />
        <SearchIcon asset="search-secondary" />
      </Wrapper>
      <NextButton tabIndex="0" onClick={ nextClick }>GO</NextButton>
    </SearchInputWrapper>
    { error && <Error>{ error }</Error>}
  </>

export default LicensePlateInput

LicensePlateInput.propTypes = {
  error: PropTypes.string,
  getLicensePlateHandler: PropTypes.func,
  nextClick: PropTypes.func,
  plate: PropTypes.string
}

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media (max-width: 500px) {
    display: block;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const PlateInput = styled(SearchInput)`
  width: 300px;
  padding: 0 13px 0 37px;
  height: 36px;
  border-radius: 3px;
  ${Media.mobile`
    background-color: ${Theme.colors.transparent};
  `}

  @media (max-width: 500px) {
    width: 100%;
  }
`

const SearchIcon = styled(Icon)`
  width: 13px;
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
`

const NextButton = styled.button`
  height: 36px;
  border-radius: 3px;
  border: 1px solid ${Theme.colors.gainsboro};
  background-color: ${Theme.colors.white};
  width: 114px;
  color: ${Theme.colors.light_red};
  margin-left: 10px;
  ${Mixin.font.GothamBold()}
  cursor: pointer;

  &:hover {
    background-color: ${Theme.colors.light_grey};
  }

  @media (max-width: 500px) {
    margin: 20px 0 0;
  }
`

const Error = styled.div`
  ${Mixin.font.GothamBook(12, 400)};
  color: ${Theme.colors.bright_red_new};
  display: inline-block;
  margin-top: 7px;
`
