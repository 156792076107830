import { compose, withProps } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import ArticlesAccordionModal from './ArticlesAccordionModal'
import { ARTICLES_ACCORDION_MODAL } from '../../constants'
import { isiOSDevice } from '../../helpers'

// We are using this prop for styles update due to we have some styles bug on iOS devices. See ticket BOT-3667.
const withInfo = withProps(() => ({
  isiOSDevice: isiOSDevice()
}))

export default compose(
  registerReduxModal({ name: ARTICLES_ACCORDION_MODAL, destroyOnHide: true }),
  withInfo
)(ArticlesAccordionModal)
