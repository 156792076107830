import React from 'react'
import HeaderDropdownItem from './components/HeaderDropdown/HeaderDropDownItem'
import { FRANCHISE_ELEMENT_ID } from './HeaderFranchise'
import { CUSTOMER_SERV_ELEMENT_ID } from './HeaderCustomerService'

export const CC_ELEMENT_ID = 'header-credit-card'

const HeaderCreditCard = ({ ...props }) =>
  <HeaderDropdownItem
    id={ CC_ELEMENT_ID }
    data-at-header-creditcard
    nextItem={ FRANCHISE_ELEMENT_ID }
    prevItem={ CUSTOMER_SERV_ELEMENT_ID }
    addPaddingBottom
    { ...props } />

HeaderCreditCard.type = 'HeaderCreditCard'

export default HeaderCreditCard
