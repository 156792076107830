import { createSelector } from 'helpers/reselect'

const getHasActiveVehicleWithTireSize = ({
  app: {
    data: {
      userdata: {
        mygarage: {
          hasActiveVehicleWithTireSize
        } = {}
      } = {}
    } = {}
  } = {}
} = {}) => hasActiveVehicleWithTireSize

export default createSelector(
  [getHasActiveVehicleWithTireSize],
  hasActiveVehicleWithTireSize => hasActiveVehicleWithTireSize
)
