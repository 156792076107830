import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import {
  BOT_VEHICLE_SELECTOR_ID,
  BOT_MY_STORE_ID,
  STICKY_HEADER_ID,
  STICKY_HEADER_COMMON_AREA_ID,
  BOT_GLOBAL_MESSAGE_ID,
  BOT_COMPARE_PAGE_HEADER_PORTAL_ID,
  BOT_GLOBAL_MESSAGE_PORTAL_ID,
  BOT_VEHICLE_SELECTOR_PLACEHOLDER_ID
} from 'constant'

const StickyHeader = ({ sticky, hideCompareHeader, isSystemAlertDisplayed, ...props }) =>
  <React.Fragment>
    <Wrapper { ...props } id={ STICKY_HEADER_ID }>
      <CommonHeaderArea id={ STICKY_HEADER_COMMON_AREA_ID }>
        <div id={ BOT_GLOBAL_MESSAGE_ID } data-sticky-hide />
        <MyStoreWrapper data-sticky-hide id={ BOT_MY_STORE_ID } />
        <VehicleSelectorWrapper id={ BOT_VEHICLE_SELECTOR_ID } data-sticky-hide />
      </CommonHeaderArea>
      {/* <div id={ BOT_APPOINTMENT_PROGRESS_BAR_ID } /> */}
      <ComparePageHeaderWrapper
        id={ BOT_COMPARE_PAGE_HEADER_PORTAL_ID }
        sticky={ sticky }
        isSystemAlertDisplayed={ isSystemAlertDisplayed }
        hideCompareHeader={ hideCompareHeader } />
    </Wrapper>
    {/* in case if you need to 'teleport' placeholder as well */}
    <div id={ BOT_GLOBAL_MESSAGE_PORTAL_ID } />
    <div id={ BOT_VEHICLE_SELECTOR_PLACEHOLDER_ID } />
  </React.Fragment>


StickyHeader.propTypes = {
  hideCompareHeader: PropTypes.bool,
  isSystemAlertDisplayed: PropTypes.bool,
  sticky: PropTypes.bool
}

export default StickyHeader

const CommonHeaderArea = styled.div`
  ${Media.desktop`
    [data-sticky] & {
      display: none;
    }
  `}
`

const Wrapper = styled.div`
  position: fixed;
  top: ${props => props.dynamicTop}px;
  left: 0;
  right: 0;
  z-index: 999;
  ${Media.desktop`
    [data-sticky] & {
      top: unset;
    }
  `}

  &::after {
    content: '';
    box-shadow: 0 2px 4px ${Theme.colors.transparent_grey_hover};
    width: 100%;
    height: 4px;
    display: block;
    margin-top: -4px;
    ${Media.desktop`
      [data-sticky] & {
        display: none;
      }
    `}
  }
`

const VehicleSelectorWrapper = styled.div`
  position: relative;
  z-index: 1;
  ${Media.mobile`
    top: -1px;
  `}
`

const MyStoreWrapper = styled.div`
  position: relative;
  z-index: 0;
  ${Media.mobile`
    top: -1px;
  `}
`

const ComparePageHeaderWrapper = styled.div`
  ${Media.mobile`
    position: relative;
    top: -1px;
    ${props => props.sticky && css`
      border-top: 2px solid ${Theme.colors.light_grey}
      top: -2px;
    `}
  `}
  ${props => props.hideCompareHeader && css`
    display: none;
  `}
  ${Media.desktop`
    ${props => !props.isSystemAlertDisplayed && props.sticky && css`
      position: relative;
      top: -55px;
    `}
  `}
`
