import { compose, lifecycle, withStateHandlers, withProps, withHandlers, branch } from 'recompose'
import debounce from 'lodash/debounce'
import active from 'components/common/hoc/active'
import Theme from 'components/common/Theme/Theme'
import Popover from './Popover'
import { computePopoverPositioning } from './helpers'
import { uid } from 'helpers/utils'

const withUniqueId = withProps(() => ({
  popoverUID: `popover_${uid()}`
}))

const withPopoverPosition = withStateHandlers({
  popoverPosition: {
    top: null,
    right: null,
    bottom: null,
    left: null,
    tickLeft: null,
    tickRight: null
  }
},
{
  setLinkRef: () => ref => ({ linkRef: ref }),
  setPopoverWrapperRef: () => ref => ({ popoverWrapperRef: ref }),
  setPopoverContentRef: () => ref => ({ popoverContentRef: ref }),
  customToggleHandler: ({ linkRef, popoverWrapperRef, popoverContentRef }, { ...props }) => () => ({
    popoverPosition: computePopoverPositioning({ linkRef, popoverWrapperRef, popoverContentRef, ...props })
  })
}
)

const withHandleResize = withHandlers({
  handleResize: ({ customToggleHandler, active }) =>
    debounce(() => active && customToggleHandler(), Theme.scrollDebounceTimeout)
})

const withHandleContentFocus = withHandlers({
  handleContentFocus: ({ active, popoverContentRef, linkRef }) => () =>
    setTimeout(() => {
      if (document.activeElement === popoverContentRef && active && linkRef) {
        linkRef.focus()
      }
    }, 500)
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { handleResize } = this.props
    window.addEventListener('resize', handleResize)
  },
  componentWillUnmount() {
    const { handleResize } = this.props
    window.removeEventListener('resize', handleResize)
  },
  componentDidUpdate({ active: prevActive }) {
    const { active, popoverContentRef, linkRef, onToggle } = this.props

    if (!prevActive && active &&
        linkRef && document.activeElement === linkRef &&
        popoverContentRef) {
      popoverContentRef.focus()
    }
    prevActive !== active && onToggle && onToggle(active)
  }
})

const withHoverHandlers = withHandlers({
  onMouseEnter: ({ toggleHandlers: { onClick }}) => () => onClick(),
  onMouseLeave: ({ deactivate }) => () => deactivate()
})

export default compose(
  withUniqueId,
  withPopoverPosition,
  active,
  withHandleResize,
  withHandleContentFocus,
  withLifecycle,
  branch(
    ({ showOnHover }) => showOnHover,
    withHoverHandlers
  )
)(Popover)
