import { MY_FAVORITES_KEY } from 'constant'
import {
  loadFavoriteSkusSuccess,
  addFavoriteSuccess,
  removeFavoriteSuccess,
  clearFavorites
} from './actions'

const favoritesMiddleware = ({ getState }) => next => action => {
  const result = next(action)

  if (typeof localStorage === 'undefined') {
    return result
  }

  switch (action.type) {
    case String(loadFavoriteSkusSuccess):
    case String(addFavoriteSuccess):
    case String(removeFavoriteSuccess):
      try {
        const { favorites: { favoriteSkus = []}} = getState()
        if (favoriteSkus.length) {
          const serialized = JSON.stringify(favoriteSkus)
          localStorage.setItem(MY_FAVORITES_KEY, serialized)
        } else {
          localStorage.removeItem(MY_FAVORITES_KEY)
        }
      } catch (e) {
        console.error(e)
      }
      break
    case String(clearFavorites):
      localStorage.removeItem(MY_FAVORITES_KEY)
      break
    default: break
  }
  return result
}

export default favoritesMiddleware
