import range from 'lodash/range'
import padStart from 'lodash/padStart'
import { MONTHS, DAYS_IN_MONTHS } from 'constant'

const getOptions = range => range
  .map(number => number ? padStart(number, 2, '0') : '')

export const getOptionsFromRange = (from, to) => getOptions(range(from, to))

export const getDaysFromMonth = month =>
  getOptionsFromRange(1, month ? DAYS_IN_MONTHS[MONTHS.indexOf(month)] + 1 : 32)

export const convertToOptions = (options = []) =>
  options.map(option => ({ value: option, label: option }))

export const convertNumbersToOptions = (options = []) =>
  options.map(option => ({ value: option, label: option.toString() }))

export const convertStatesToOptions = (states = []) =>
  states.map(({ stateCode }, index) => {
    const state = stateCode || states[index]
    return { label: state, value: state }
  })

export const convertSortToOptions = (sortOptions = []) =>
  sortOptions.map(({ label, navigationState, ...sortOptionProps }) => ({
    ...sortOptionProps,
    navigationState,
    label,
    value: navigationState
  }))
