import { handleActions } from 'redux-actions'
import { setNumberOfTiresOptionsAvailable, setNumberOfTiresOptionsLoading } from './actions'

const initialState = {
  numberOfAvailableTires: null,
  isNumberOfTiresLoading: false
}

const handleAvailableTiresOptionsSuccess = (state, { payload: { numberOfAvailableTires }}) => ({
  ...state,
  numberOfAvailableTires
})

const setIsNumberOfTiresLoading = (state, { payload: isNumberOfTiresLoading }) => ({
  ...state,
  isNumberOfTiresLoading
})

const reducer = handleActions({
  [setNumberOfTiresOptionsAvailable]: handleAvailableTiresOptionsSuccess,
  [setNumberOfTiresOptionsLoading]: setIsNumberOfTiresLoading
}, initialState)

export default reducer
