import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class ClientRendering extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isClientSide: false }
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ isClientSide: true })
  }

  render() {
    const { children } = this.props
    const { isClientSide } = this.state

    return (
      isClientSide ?
        <Fragment>{ children }</Fragment> : null
    )
  }
}

ClientRendering.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default ClientRendering
