import { lifecycle, compose } from 'recompose'
import { alignPromotionTileSize } from './PromotionsList/helpers'

const withLifeCycle = lifecycle({
  componentDidMount() {
    alignPromotionTileSize()
    window.addEventListener('resize', alignPromotionTileSize)
  },
  componentDidUpdate() {
    alignPromotionTileSize()
  },
  componentWillUnmount() {
    window.removeEventListener('resize', alignPromotionTileSize)
  }
})

export default compose(withLifeCycle)

