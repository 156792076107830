import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'


const Placeholder = ({ className, ...props }) =>
  <Wrapper className={ className } { ...props } />

Placeholder.propTypes = {
  alternative: PropTypes.string,
  className: PropTypes.string,
  condition: PropTypes.bool,
  height: PropTypes.string
}

export default Placeholder


const Wrapper = styled.div`
  visibility: hidden;
  height: ${props => props.condition ? props.alternative : props.height};
  ${props => props.desktopOnly && Mixin.desktopOnly()};
  ${props => props.mobileOnly && Mixin.mobileOnly()};
`
