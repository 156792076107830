import { compose, withHandlers } from 'recompose'
import withKeyPress from 'components/common/hoc/withKeyPress/withKeyPress'
import {
  focusNextOrPrevItem,
  getFocusedSubItemIndex,
  focusCurrentItem,
  notEventTargetException
} from './helpers'

const withCombinedRefSetter = withHandlers({
  combinedRefSetter: ({ setKeypressScopeRef, setFocusHoverStateBlockRef, setInnerRef }) => (ref) => {
    setFocusHoverStateBlockRef && setFocusHoverStateBlockRef(ref)
    setKeypressScopeRef && setKeypressScopeRef(ref)
    setInnerRef && setInnerRef(ref)
  }
})

const withNavigationHandlers = withHandlers({
  right: ({ nextItem, isSubmenu, isInnerFocusedHovered }) => (e) => {
    if (notEventTargetException(e)) {
      if (isSubmenu && getFocusedSubItemIndex(e) >= 0) {
        focusCurrentItem({ e })
      } else {
        focusNextOrPrevItem({ e, name: nextItem, openSubmenu: isInnerFocusedHovered })
      }
    }
  },
  left: ({ prevItem, isSubmenu, isInnerFocusedHovered }) => (e) => {
    if (notEventTargetException(e)) {
      if (isSubmenu && getFocusedSubItemIndex(e) >= 0) {
        focusCurrentItem({ e })
      } else {
        focusNextOrPrevItem({ e, name: prevItem, openSubmenu: isInnerFocusedHovered })
      }
    }
  }
})


export default compose(
  withNavigationHandlers,
  withKeyPress,
  withCombinedRefSetter
)
