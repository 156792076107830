import React from 'react'
import PropTypes from 'prop-types'
import Image from 'components/common/Image'
import { generateUrl } from './helpers'
import {
  ANGLE_VIEW,
  SIDE_VIEW,
  H_ALIGNMENT,
  V_ALIGNMENT,
  DEFAULT_VIEW,
  DEFAULT_COLOR,
  DEFAULT_H_ALIGNMENT,
  DEFAULT_V_ALIGNMENT,
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  NOT_VEHICLE_IMAGE_URL
} from './constants'

const VehicleImage = ({
  year,
  make,
  model,
  submodel = '',
  color = DEFAULT_COLOR,
  view = DEFAULT_VIEW,
  vAlign = DEFAULT_V_ALIGNMENT,
  hAlign = DEFAULT_H_ALIGNMENT,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  mobileWidth = width,
  mobileHeight = height,
  exactMatch = false,
  hasError,
  onErrorHandler,
  alt,
  ...otherProps
}) => {
  const src = generateUrl({ year, make, model, submodel, color, view, vAlign, hAlign, height, width, exactMatch })
  const mobileSrc = generateUrl({
    year,
    make,
    model,
    submodel,
    color,
    view,
    vAlign,
    hAlign,
    height: mobileHeight,
    width: mobileWidth,
    exactMatch
  })

  return <Image
    src={ !hasError ? src : NOT_VEHICLE_IMAGE_URL }
    mobileSrc={ !hasError ? mobileSrc : NOT_VEHICLE_IMAGE_URL }
    alt=""
    height={ `${height}px` }
    mobileHeight={ `${mobileHeight}px` }
    width={ `${width}px` }
    mobileWidth={ `${mobileWidth}px` }
    onError={ onErrorHandler }
    { ...otherProps } />
}

VehicleImage.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string,
  exactMatch: PropTypes.bool,
  hAlign: PropTypes.oneOf(H_ALIGNMENT),
  hasError: PropTypes.bool,
  height: PropTypes.number,
  make: PropTypes.string,
  mobileHeight: PropTypes.number,
  mobileWidth: PropTypes.number,
  model: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape()
  ]),
  onErrorHandler: PropTypes.func,
  submodel: PropTypes.string,
  vAlign: PropTypes.oneOf(V_ALIGNMENT),
  view: PropTypes.oneOf([SIDE_VIEW, ANGLE_VIEW]),
  width: PropTypes.number,
  year: PropTypes.number
}

export default VehicleImage
