export const formatTireSize = (tireSize = '') =>
  tireSize.replace(/\D/g, '')

const getConcatedTireSize = ({ frontTireSize = '', rearTireSize = '' }) =>
  `/${formatTireSize(frontTireSize)}/${rearTireSize ? formatTireSize(rearTireSize) : ''}`

const getFormattedVehicleModel = (...vehicle) =>
  vehicle.map(property => property.replace(/-/g, '--').replace(/[/\\()+.^:,$%\s]/g, '-').toLowerCase()).join('/')

export default ({ yearDesc, makeDesc = '', modelDesc = '', subModelDesc = '', frontTireSize, rearTireSize, vehicleType }) => {
  if (vehicleType === 'trailer') {
    return `/tires/by-size${getConcatedTireSize({ frontTireSize, rearTireSize })}`
  }

  return `/tires/by-vehicle/${yearDesc}/${getFormattedVehicleModel(makeDesc, modelDesc, subModelDesc)}${frontTireSize ? getConcatedTireSize({
    frontTireSize,
    rearTireSize
  }) : ''}`.toLowerCase()
}
