import {
  googleAnalyticProductAddToCart,
  googleAnalyticPurchase
} from 'actions/googleAnalytic'
import { setKenshooEventData, kenshooQuickAddService } from 'components/common/hoc/with3rdPartyData/actions'
import { getCartDetail } from 'components/ShoppingQuote/actions'
import { servicesCheckboxClick } from 'actions/thirdParty'
import { getQuoteInfo } from 'selectors/shoppingData'
import { getKenshooRevenue } from 'selectors/thirdPartyData'
import { validateAddToCartItem, createAddToQuoteConversionData } from './helpers'
import { ADD_TIRES, ADD_SERVICES, APPT_CONFIRMATION } from 'constant'

export default store => next => action => {
  try {
    if (action.type === String(googleAnalyticProductAddToCart)) {
      const { product: { skuId = '' } = {}} = action.payload
      if (skuId) {
        const { getState, dispatch } = store
        dispatch(getCartDetail())
          .then(() => {
            const { products = [], services = []} = getQuoteInfo(getState())

            if (validateAddToCartItem(skuId)) {
              const { revenue } = createAddToQuoteConversionData(products, skuId)
              dispatch(setKenshooEventData({ conversionType: ADD_TIRES, revenue }))
            } else {
              const { averageSalePrice: revenue } = services.length &&
                services.filter(({ serviceId }) => serviceId === skuId)[0]

              dispatch(setKenshooEventData({ conversionType: ADD_SERVICES, revenue }))
            }
            return next(action, { products, services })
          })
      }
    }

    if (action.type === String(servicesCheckboxClick)) {
      const { dispatch } = store
      const { revenue = '' } = action.payload
      dispatch(setKenshooEventData({ conversionType: ADD_SERVICES, revenue }))
      dispatch(kenshooQuickAddService({ revenue }))
    }

    if (action.type === String(googleAnalyticPurchase)) {
      const { getState, dispatch } = store
      const state = getState()
      const quickApptRevenue = getKenshooRevenue(state)
      const { revenue: quoteApptRevenue } = getQuoteInfo(state)
      dispatch(
        setKenshooEventData({
          conversionType: APPT_CONFIRMATION,
          revenue: quickApptRevenue || quoteApptRevenue
        })
      )
    }
  } catch (err) {
    console.log('Error occured during Google Analytic event dispatch', err)
  }

  return next(action)
}
