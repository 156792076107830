import { compose, withHandlers, withProps, withState, withStateHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import ServiceRecordModal from './ServiceRecordModal'
import { withRouter } from 'react-router-dom'
import { refresh } from 'actions/router'
import {
  ADD_TYPE_SERVICE_RECORD_MODAL,
  EDIT_TYPE_SERVICE_RECORD_MODAL,
  SERVICE_MODAL_FORM,
  SERVICE_RECORD_MODAL
} from '../../../../constants'
import { reduxForm } from 'redux-form'
import { addServiceRecord, editServiceRecord } from 'components/ServiceHistory/actions'
import { connect } from 'react-redux'
import { getUserVehicleId } from '../../../../selectors'
import {
  formatDateToPayloadFormat,
  generateServicesPayloadString,
  hasTireRotationService,
  hasOilChangeService,
  parseStringDateToDateObject,
  prepareMileage,
  isServiceRecordDuplicate,
  prepareForDuplicateCheckRecord
} from 'components/ServiceHistory/helpers'

const withServiceDateState = withState('serviceDate', 'setServiceDate', ({ record }) => record
  ? parseStringDateToDateObject(record.serviceDate)
  : new Date())

const withTireRotationState = withState('isTireRotationChecked', 'setIsTireRotationChecked', ({ record }) => record
  ? hasTireRotationService(record.serviceNames)
  : false
)

const withOilChangeState = withState('isOilChangeChecked', 'setOilChangeChecked', ({ record }) => record
  ? hasOilChangeService(record.serviceNames)
  : false)

const withMileageState = withState('mileage', 'setMileage', ({ record }) => record ? Number(record.mileage) : '')


const withNoServiceSelectedErrorState = withState('noServiceSelectedError', 'setNoServiceSelectedError', false)
const withEmptyMileageErrorState = withState('emptyMileageError', 'setEmptyMileageError', false)
const withEmptyDateErrorState = withState('emptyDateError', 'setEmptyDateError', false)
const withServiceRecordApiMismatchErrorState = withState('serviceRecordApiMismatchError', 'setServiceRecordApiMismatchError', false)
const withServiceRecordApiFutureErrorHandlerState = withState('serviceRecordApiFutureErrorHandler', 'setServiceRecordApiFutureErrorHandler', false)
const withDuplicateServiceRecordErrorState = withState('duplicateServiceRecordError', 'setDuplicateServiceRecordError', false)

const mapStateToProps = state => ({
  userVehicleId: getUserVehicleId(state)
})

const withPaymentOptionsBannerState = withStateHandlers(({ isTireRotationSelected, isOilChangeSelected }) => ({
  tireRotationSelected: isTireRotationSelected || false,
  oilChangeSelected: isOilChangeSelected || false
}),
{
  toggleTireRotationSelected: (tireRotationSelected) => () => ({ tireRotationSelected: !tireRotationSelected }),
  toggleOilChangeSelected: (oilChangeSelected) => () => ({ oilChangeSelected: !oilChangeSelected })
})

const withInitialValuesProps = withProps(({ mileage }) => ({
  initialValues: {
    mileage
  }
}))

const withServiceRecordModalHandlers = withHandlers({
  handleSubmit: ({
    userVehicleId,
    serviceDate,
    isTireRotationChecked,
    isOilChangeChecked,
    mileage,
    setNoServiceSelectedError,
    setEmptyMileageError,
    setEmptyDateError,
    type,
    handleHide,
    record,
    setRecordData,
    history,
    setServiceRecordApiMismatchError,
    setServiceRecordApiFutureErrorHandler,
    recordData,
    setDuplicateServiceRecordError
  }) => () => {
    const formattedServiceDate = formatDateToPayloadFormat(serviceDate, type)
    const servicesString = generateServicesPayloadString({ isTireRotationChecked, isOilChangeChecked })

    if (!servicesString) {
      setNoServiceSelectedError(true)
      setTimeout(() => {
        setNoServiceSelectedError(false)
      }, 5000)

      return
    }

    if (!formattedServiceDate) {
      setEmptyDateError(true)

      setTimeout(() => {
        setEmptyDateError(false)
      }, 5000)

      return
    }

    if (!mileage) {
      setEmptyMileageError(true)

      setTimeout(() => {
        setEmptyMileageError(false)
      }, 5000)

      return
    }

    if (isServiceRecordDuplicate(
      recordData,
      prepareForDuplicateCheckRecord({ mileage, servicesString, formattedServiceDate })
    )) {
      setDuplicateServiceRecordError(true)

      setTimeout(() => {
        setDuplicateServiceRecordError(false)
      }, 5000)

      return
    }

    if (type === ADD_TYPE_SERVICE_RECORD_MODAL) {
      const payload = {
        serviceDate: formattedServiceDate,
        mileage: prepareMileage(String(mileage)),
        userVehicleId,
        services: servicesString
      }
      addServiceRecord({
        setRecordData,
        payload,
        history,
        handleHide,
        setServiceRecordApiMismatchError,
        setServiceRecordApiFutureErrorHandler
      })
    }

    if (type === EDIT_TYPE_SERVICE_RECORD_MODAL) {
      const { serviceId } = record
      const payload = {
        mileage: prepareMileage(String(mileage)),
        serviceId,
        services: servicesString,
        serviceDate: formattedServiceDate,
        userVehicleId
      }
      editServiceRecord({
        setRecordData,
        payload,
        handleHide,
        setServiceRecordApiMismatchError,
        setServiceRecordApiFutureErrorHandler
      })
    }
  },
  handleOilChangeOnChange: ({ setOilChangeChecked, isOilChangeChecked }) => () =>
    setOilChangeChecked(!isOilChangeChecked),
  handleTireRotationOnChange: ({ setIsTireRotationChecked, isTireRotationChecked }) => () =>
    setIsTireRotationChecked(!isTireRotationChecked),
  handleMileageOnChange: ({ setMileage }) => (event) => {
    setMileage(event.target.value)
  },
  onKeyPressTireRotation: ({ setIsTireRotationChecked, isTireRotationChecked }) => e => {
    if (e.key === 'Enter') {
      setIsTireRotationChecked(!isTireRotationChecked)
    }
  },
  onKeyPressOilChange: ({ setOilChangeChecked, isOilChangeChecked }) => e => {
    if (e.key === 'Enter') {
      setOilChangeChecked(!isOilChangeChecked)
    }
  }
})

export default compose(
  registerReduxModal({ name: SERVICE_RECORD_MODAL }),
  connect(mapStateToProps, { addServiceRecord, refresh }),
  withRouter,
  withServiceDateState,
  withNoServiceSelectedErrorState,
  withEmptyMileageErrorState,
  withEmptyDateErrorState,
  withServiceRecordApiMismatchErrorState,
  withServiceRecordApiFutureErrorHandlerState,
  withDuplicateServiceRecordErrorState,
  withTireRotationState,
  withOilChangeState,
  withMileageState,
  withPaymentOptionsBannerState,
  withServiceRecordModalHandlers,
  withInitialValuesProps,
  reduxForm({ form: SERVICE_MODAL_FORM })
)(ServiceRecordModal)
