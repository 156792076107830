import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'

export default styled.div`
  padding: 4px;
  border-radius: 50%;
  color: ${Theme.colors.white};
  background: ${props => props.backgroundColor || Theme.colors.bright_red_new};
  text-align: center;
  vertical-align: middle;
  ${Mixin.font.GothamBold(12)}
  line-height: 12px;
  ${props => props.disabled && css`
    && {
      color: ${Theme.colors.grey};
      border: 1px solid ${Theme.colors.grey};
      background-color: ${Theme.colors.off_white};
    }
  `}
`
