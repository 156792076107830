import { replace } from 'connected-react-router'
import { rest } from 'services'
import { HOME } from 'constant'
import { cleanShoppingData } from 'actions/shoppingData'
import { clearQuickAppointmentData } from 'components/QuickAppointmentPage/actions'
import { cleanMyAppointments } from 'actions/myAppointments'
import { clearAppointmentConfirmationContactDetails } from 'components/AppointmentConfirmationPage/Checkout/components/AppointmentInfoDrawer/actions'
import { clearFavorites } from 'components/MyFavorites/actions'

export const logout = () => dispatch =>
  rest.api
    .logout()
    .then(() => dispatch(replace(HOME, { update: true })))
    .then(() => dispatch(cleanShoppingData()))
    .then(() => dispatch(cleanMyAppointments()))
    .then(() => dispatch(clearQuickAppointmentData()))
    .then(() => dispatch(clearAppointmentConfirmationContactDetails()))
    .then(() => dispatch(clearFavorites()))
    .catch(() => dispatch(replace(HOME, { update: true })))
