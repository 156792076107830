import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Expander from 'components/common/Expander'
import Media from 'components/common/Media'
import { FRONT, REAR } from 'components/VehicleConfigurator/components/withTireSize/constants'
import { Title, FieldLine, FindButton, Instruction, InnerWrapper } from '../SharedStyles'
import TireSizeSelector from '../TireSizeSelector'
import TireSizeTooltip from '../TireSizeTooltip'
import { DEFAULT_SEARCH_BUTTON_LABEL } from './constants'

const ShopByTires = ({
  activeVehicle: {
    hasStaggeredFitment
  } = {},
  children,
  className,
  model,
  handlers,
  tireSize: { front, rear } = {},
  handleRearTireSizeToggle,
  handleSubmit,
  notTrailerPageOrTrailer,
  setExpanderToggleHandlers,
  showPopularSizes,
  isModal,
  searchButtonLabel,
  isTrailerPage
}) =>
  <InnerWrapper className={ className } data-at-shop-by-tire-wrapper>
    <Title data-at-title="shop-by-tires">Enter Your Tire Size</Title>
    <FieldLine data-at-ymme-front>
      <fieldset>
        <legend><Media.ScreenReader>Enter Your Tire Size</Media.ScreenReader></legend>
        <TireSizeSelector
          position={ FRONT }
          data={ front }
          model={ model.front }
          handlers={ handlers.front }
          showPopularSizes={ showPopularSizes }
          allowMultiColumnMenu={ !isModal } />
      </fieldset>
    </FieldLine>
    {!isTrailerPage && (
      <Expander
        parentToggleSetter={ setExpanderToggleHandlers }
        disableResetOnLocationChange
        expandedByDefault={ hasStaggeredFitment && notTrailerPageOrTrailer }
        title="Add a Different Rear Tire Size"
        onClick={ handleRearTireSizeToggle }>
        <FieldLine data-at-ymme-rear>
          <fieldset>
            <legend><Media.ScreenReader>Add a Different Rear Tire Size</Media.ScreenReader></legend>
            <TireSizeSelector
              position={ REAR }
              data={ rear }
              model={ model.rear }
              handlers={ handlers.rear }
              showPopularSizes={ showPopularSizes }
              allowMultiColumnMenu={ !isModal } />
          </fieldset>
        </FieldLine>
      </Expander>
    )}

    {
      children ?
        React.Children.map(children, element =>
          React.cloneElement(element, { onSubmit: handleSubmit })) :
        <FindButton
          data-at-find-my-tires-button
          full
          large
          important
          onClick={ handleSubmit }>{searchButtonLabel}</FindButton>
    }
    <Instruction>
      <CustomTooltip centered small title="Where can I find my tire size?" />
    </Instruction>
  </InnerWrapper>

ShopByTires.defaultProps = {
  searchButtonLabel: DEFAULT_SEARCH_BUTTON_LABEL
}

ShopByTires.propTypes = {
  activeVehicle: PropTypes.shape(),
  children: PropTypes.node,
  className: PropTypes.string,
  handleRearTireSizeToggle: PropTypes.func,
  handlers: PropTypes.shape({
    rear: PropTypes.shape(),
    front: PropTypes.shape()
  }),
  handleSubmit: PropTypes.func,
  isModal: PropTypes.bool,
  isTrailerPage: PropTypes.bool,
  model: PropTypes.shape({
    rear: PropTypes.shape(),
    front: PropTypes.shape()
  }),
  notTrailerPageOrTrailer: PropTypes.bool,
  searchButtonLabel: PropTypes.string,
  setExpanderToggleHandlers: PropTypes.func,
  showPopularSizes: PropTypes.bool,
  tireSize: PropTypes.shape()
}

export default ShopByTires

const CustomTooltip = styled(TireSizeTooltip)`
  & [data-content] {
    padding: 30px 5px 5px;
    text-align: center;
    ${Media.desktop`
      width: 390px;
      margin-left: -195px;
    `}
  }
`
