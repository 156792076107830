import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import getComponent from 'helpers/component-helper'
import CarTile from '../CarTile'

const VehicleEditorModal = ({
  data: { modalBoxContent },
  handleModalClose,
  vehicleData: {
    masterVehicle,
    vehicleColor
  }
}) =>
  <CustomModal
    data-at-editvehiclemodal
    title="Edit Vehicle"
    closeHandler={ handleModalClose }>
    <CarTileRow>
      <CarTile { ...masterVehicle } vehicleColor={ vehicleColor ? `#${vehicleColor}` : undefined } />
    </CarTileRow>
    {
      modalBoxContent.map((data, index) =>
        React.cloneElement(getComponent(data), { key: index }))
    }
  </CustomModal>

export default VehicleEditorModal

VehicleEditorModal.propTypes = {
  data: PropTypes.shape({
    modalBoxContent: PropTypes.arrayOf(PropTypes.shape())
  }),
  handleModalClose: PropTypes.func,
  vehicleData: PropTypes.shape({
    masterVehicle: PropTypes.shape(),
    vehicleColor: PropTypes.string
  })
}

const CustomModal = styled(Modal)`
  h1 {
    text-align: center;
  }

  ${Media.desktop`
    width: 480px;
    padding: 25px 24px 0;
  `}
`

const CarTileRow = styled.div`
  text-align: center;
  ${Mixin.responsive('margin-bottom', '20px', '41px')}
`
