import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import PromotionsList from 'components/PromotionResultList/PromotionsList'
import Link from 'components/common/Link/Link'

const CustomPromotionModal = ({
  handleCloseModal,
  promotionResultList = [],
  noPromotionsMessage = '',
  regionName = [],
  components: {
    BeforeComponent = <></>,
    TimerComponent = <></>,
    AfterComponent = <></>
  } = {}
}) =>
  <CustomModal closeHandler={ handleCloseModal } className="promotion-countdown-modal">
    <ModalContentWrapper className="promotion-countdown-modal__content">
      {BeforeComponent}
      {TimerComponent}
      {AfterComponent}
    </ModalContentWrapper>
    <PromotionsWrapper>
      <PromotionsList
        blackFridayModal
        handleCloseModal={ handleCloseModal }
        regionName={ regionName }
        promotionResultList={ promotionResultList }
        noPromotionsMessage={ noPromotionsMessage } />
    </PromotionsWrapper>
    <DealsLinkText>
      For full promotional details, please visit <DealsLink to="/deals" onClick={ handleCloseModal }>www.bigotires.com/deals</DealsLink>.
    </DealsLinkText>
  </CustomModal>

CustomPromotionModal.propTypes = {
  components: PropTypes.shape({
    BeforeComponent: PropTypes.shape(),
    TimerComponent: PropTypes.shape(),
    AfterComponent: PropTypes.shape()
  }),
  handleCloseModal: PropTypes.func,
  noPromotionsMessage: PropTypes.string,
  promotionResultList: PropTypes.PropTypes.arrayOf(),
  regionName: PropTypes.string
}

export default CustomPromotionModal

const CustomModal = styled(Modal)`
  ${Mixin.responsive('padding', '25px 24px 46px', '60px 16px 40px')}
`

const ModalContentWrapper = styled.div`
  display: block;
`

const PromotionsWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  ${Media.mobile`
    margin-top: 45px;
  `}
`

const DealsLinkText = styled.p`
  margin: 30px 0;
  font-size: 18px;
  text-align: center;
`

const DealsLink = styled(Link)`
  text-decoration: underline;
  font-size: 18px;
`
