export default [
  'BOTArticlesSearchResults',
  'BOTBrandsSearchResults',
  'BOTBreadcrumbBackGlobalSearch',
  'BOTGlobalSearch',
  'BOTGlobalSearchBreadcrumbs',
  'BOTGlobalSearchRefinementMenu',
  'BOTGlobalSearchSummary',
  'BOTGuidedNavigation',
  'BOTProductSearchResults',
  'BOTRecommendedSearchResult',
  'BOTRefinementMenu',
  'BOTSearchResult',
  'BOTServicesSearchResults',
  'BOTTireComparePanel',
  'GenericPromotionBlock',
  'OneColumnSearchResultPage',
  'TireResultList',
  'TireSearchGuidedNavigation'
]

/*
Appears on:
/global-search?Ntt=Michelin,
/global-search/articles?Ntt=Oil+change,
/global-search/brands?Ntt=Michelin,
/global-search/services?Ntt=Oil+change,
/global-search/tires?Ntt=Michelin,
/global-tires-pagination?Ntt=Michelin&No=12&Nrpp=12,
/tires/by-size/2254517/,
/tires/by-vehicle/2018/audi/a3/premium/2254517/
*/
