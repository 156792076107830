export default [
  'BOTBreadcrumbTwoColumnsLink',
  'BOTCareerDescriptionTeaser',
  'BOTCareersPageBanner',
  'BOTCareersContent',
  'BOTLocationOpportunitiesTeaser',
  'BOTMyStoreDetails',
  'BOTPrintCartridge',
  'BOTPromotionDetails',
  'BOTQuoteBreadcrumbTwoColumnsLink',
  'BOTTireCompareBreadcrumbTwoColumnsLink'
]

/*
Appears on:
/careers,
/deals,
/deal/-70-instant-savings/promo5930002,
/deal?promotionId=promo5930002,
/location/ca-los-angeles-90064/005768,
/quote,
/tires/compare-tires?
*/
