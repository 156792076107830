import { hide, show } from 'redux-modal'
import { push } from 'connected-react-router'
import { ADD_VEHICLE_MODAL, CONFIRM_TIRE_SIZE_MODAL, PROMOTIONS_DETAIL_MODAL_PAGE } from 'constant'
import { addVehicle } from 'actions/addVehicle'
import getSearchByVehicleUrl from 'helpers/getSearchByVehicleUrl'

export const hidePromotionsDetailModal = () => hide(PROMOTIONS_DETAIL_MODAL_PAGE)

export const addNewVehicle = ({ promo }) => dispatch => dispatch(show(ADD_VEHICLE_MODAL, {
  title: 'Add A Vehicle',
  confirmTireSizeModalProps: {
    handleTiresSelect: async ({
      vehicle,
      frontTireSize,
      rearTireSize,
      loadIndex,
      rearLoadIndex,
      speedRating,
      rearSpeedRating
    }) => {
      await dispatch(
        addVehicle({
          ...vehicle,
          tireFrontSize: frontTireSize,
          tireRearSize: rearTireSize,
          loadIndex,
          rearLoadIndex,
          speedRating,
          rearSpeedRating
        }
        ))
      dispatch(hide(CONFIRM_TIRE_SIZE_MODAL))
      dispatch(hidePromotionsDetailModal())

      const searchByVehicleUrl = getSearchByVehicleUrl({ ...vehicle, frontTireSize, rearTireSize })

      dispatch(push(`${searchByVehicleUrl}${promo || ''}`))
    }
  }
}))

export const navigateToTireSearch = ({ url }) => dispatch => {
  dispatch(hidePromotionsDetailModal())

  return dispatch(push(url))
}
