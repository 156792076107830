import { handleActions } from 'redux-actions'
import { setStoreDetailsInformation } from './actions'

const initialState = {
  storeNum: '',
  storeAddress: {
    address1: '',
    address2: null,
    city: '',
    latitude: '',
    longitude: '',
    state: '',
    zipcode: ''
  },
  storeId: '',
  workingHours: []
}

const storeDetailsInformation = handleActions({
  [setStoreDetailsInformation]: (state, { payload: { storeDetails }}) =>
    ({
      ...state,
      ...storeDetails
    })
}, initialState)

export default storeDetailsInformation
