import { createAction } from 'redux-actions'
import { rest, isApiError } from 'services'
import { getAllValues, getMeta, getFrontSizeValues, getRearSizeValues, getRearSizeCommittedValues } from './selectors'
import { getRequestData, isLastField, getNextNames, getNextName, parseTireSize } from './helpers'
import { FRONT, REAR, WIDTH, RATIO, DIAMETER, DEFAULT_TIRE_SIZE, TRAILER_PAGE_URL } from './constants'
import validate from './validate'
import isEqual from 'lodash/isEqual'

export const getFieldCurrentValue = (name, position, getState, isModal) =>
  getAllValues(getState(), { isModal })[position][name]

export const requestValuesStart = createAction('TIRE_SIZE/REQUEST_VALUES_START')
export const requestValuesFailure = createAction('TIRE_SIZE/REQUEST_VALUES_FAILURE')
export const selectValue = createAction('TIRE_SIZE/SELECT_VALUE')
export const commitValues = createAction('TIRE_SIZE/COMMIT_VALUES')
export const setValues = createAction('TIRE_SIZE/SET_VALUES')
export const setFilter = createAction('TIRE_SIZE/SET_FILTER')
export const activate = createAction('TIRE_SIZE/ACTIVATE')
export const deactivate = createAction('TIRE_SIZE/DEACTIVATE')
export const toggle = createAction('TIRE_SIZE/TOGGLE')
export const setErrors = createAction('TIRE_SIZE/SET_ERRORS')
export const resetSomeFields = createAction('TIRE_SIZE/RESET_SOME_FIELDS')
export const resetAllFields = createAction('TIRE_SIZE/RESET_ALL_FIELDS')
export const resetField = createAction('TIRE_SIZE/RESET_FIELD')

export const reset = createAction('TIRE_SIZE/RESET')
export const resetToCommitted = createAction('TIRE_SIZE/RESET_TO_COMMITTED')

export const openRearTireSize = createAction('TIRE_SIZE/OPEN_REAR_TIRE_SIZE')
export const closeRearTireSize = createAction('TIRE_SIZE/CLOSE_REAR_TIRE_SIZE')

export const submitTireSize = createAction('TIRE_SIZE/SUBMIT_TIRE_SIZE')
export const resetSubmittedTireSize = createAction('TIRE_SIZE/CLEAR_SUBMITTED_TIRE_SIZE')

export const getCustomTireSize = ({ position = FRONT, name, isModal } = {}) => async (dispatch, getState) => {
  const nextName = getNextName(name)

  try {
    const tireSizeValues = getAllValues(getState(), { isModal })[position]
    const customTireSizeRequestData = getRequestData(name, tireSizeValues)
    const tireSizeParams = { ...customTireSizeRequestData }
    let isTrailerPage = false

    if (window && window.location) {
      const currentPathName = window.location.pathname
      isTrailerPage = currentPathName.includes(TRAILER_PAGE_URL)
    }

    if (isTrailerPage && !isModal) {
      tireSizeParams.isTrailer = isTrailerPage
    }

    dispatch(requestValuesStart({ position, name: nextName, isModal }))

    const {
      tireSizes: {
        type,
        tireSize = []
      } = {}
    } = await rest.api.customTireSize(tireSizeParams)

    dispatch(setValues({ position, name: type, value: tireSize, isModal }))
  } catch (err) {
    if (isApiError(err)) {
      dispatch(requestValuesFailure({ position, name: nextName, error: err.status ? err.status.description : '', isModal }))
    }
  }
}

export const setDefaultState = ({ isModal }) => dispatch => {
  dispatch(reset({ isModal }))
  dispatch(getCustomTireSize({ isModal }))
}

export const setCommittedState = ({ isModal }) => dispatch => {
  dispatch(resetToCommitted({ isModal }))
}

/**
 * request values for all selectors and set their's value to defaults( passed in )
 */
export const initialize = ({
  position = FRONT,
  activeVehicle = {},
  notTrailerPageOrTrailer,
  defaultTireSize: {
    width,
    ratio,
    diameter
  } = DEFAULT_TIRE_SIZE,
  isModal,
  showPopularSizes
} = {}) =>
  async (dispatch, getState) => {
    const { frontTireSize, hasStaggeredFitment, rearTireSize } = activeVehicle
    if (frontTireSize && notTrailerPageOrTrailer) {
      const [activeWidth = '', activeRatio = '', activeDiameter = ''] = parseTireSize(frontTireSize)
      dispatch(selectValue({ position, name: WIDTH, value: activeWidth, isModal }))
      dispatch(selectValue({ position, name: RATIO, value: activeRatio, isModal }))
      dispatch(selectValue({ position, name: DIAMETER, value: activeDiameter, isModal }))
      if (hasStaggeredFitment) {
        const [activeRearWidth, activeRearRatio, activeRearDiameter] = parseTireSize(rearTireSize)
        dispatch(selectValue({ position: REAR, name: WIDTH, value: activeRearWidth, isModal }))
        dispatch(selectValue({ position: REAR, name: RATIO, value: activeRearRatio, isModal }))
        dispatch(selectValue({ position: REAR, name: DIAMETER, value: activeRearDiameter, isModal }))
      }
      await dispatch(getCustomTireSize({ position, isModal }))
      await dispatch(getCustomTireSize({ position, name: WIDTH, isModal }))
      await dispatch(getCustomTireSize({ position, name: RATIO, isModal }))
      if (hasStaggeredFitment) {
        await dispatch(getCustomTireSize({ position: REAR, isModal }))
        await dispatch(getCustomTireSize({ position: REAR, name: WIDTH, isModal }))
        await dispatch(getCustomTireSize({ position: REAR, name: RATIO, isModal }))
      }
    } else {
      await dispatch(getCustomTireSize({ position, isModal }))
    }

    const state = getState()

    dispatch(commitValues({
      [FRONT]: getFrontSizeValues(state, { isModal }),
      [REAR]: getRearSizeValues(state, { isModal }),
      isModal
    }))
  }

export const handleSelectValue = ({
  position,
  name,
  value,
  isModal
} = {}) => async (dispatch, getState) => {
  if (value !== getFieldCurrentValue(name, position, getState, isModal)) {
    dispatch(selectValue({ position, name, value, isModal }))

    if (!isLastField(name)) {
      dispatch(resetSomeFields({ position, name: getNextNames(name), isModal }))

      await dispatch(getCustomTireSize({ position, name, isModal }))
      dispatch(activate({ position, name: getNextName(name), isModal }))
    }
  }
}

const deactivateAllInPosition = (position, isModal) => dispatch =>
  [WIDTH, RATIO, DIAMETER].forEach(name =>
    dispatch(deactivate({ name, position, isModal })))

export const handleToggle = ({ name, position = FRONT, isModal } = {}) => dispatch => {
  dispatch(toggle({ name, position, isModal }))
  if (name === WIDTH) {
    dispatch(deactivate({ name: RATIO, position, isModal }))
    dispatch(deactivate({ name: DIAMETER, position, isModal }))
  } else if (name === RATIO) {
    dispatch(deactivate({ name: WIDTH, position, isModal }))
    dispatch(deactivate({ name: DIAMETER, position, isModal }))
  } else {
    dispatch(deactivate({ name: WIDTH, position, isModal }))
    dispatch(deactivate({ name: RATIO, position, isModal }))
  }
  dispatch(deactivateAllInPosition(position === FRONT ? REAR : FRONT, isModal))
}

export const handleValidate = ({ isModal }) => (dispatch, getState) => {
  const tireSizeValues = getAllValues(getState(), { isModal })
  const frontTireSizeErrors = validate(tireSizeValues[FRONT])
  const rearTireSizeErrors = validate(tireSizeValues[REAR])

  dispatch(setErrors({ position: FRONT, errors: frontTireSizeErrors, isModal }))
  dispatch(setErrors({ position: REAR, errors: rearTireSizeErrors, isModal }))
}

export const submit = (isModal) => (dispatch, getState) => {
  dispatch(handleValidate({ isModal }))

  const state = getState()
  const tireSizeValues = getAllValues(state, { isModal })

  dispatch(commitValues({
    [FRONT]: getFrontSizeValues(state, { isModal }),
    [REAR]: getRearSizeValues(state, { isModal }),
    isModal
  }))

  const { isLoading, isValid } = getMeta(state, { isModal })

  if (!isLoading && isValid) {
    return { valid: true, tireSizes: tireSizeValues }
  }

  return { valid: false, tireSizes: {}}
}

export const resetRearSizeFields = ({ isModal }) => dispatch =>
  dispatch(resetAllFields({ position: REAR, isModal }))

export const handleCloseRearTireSize = ({ isModal }) => (dispatch, getState) => {
  dispatch(closeRearTireSize({ isModal }))
  const selectedRearValues = getRearSizeValues(getState(), { isModal })
  const committedRearValues = getRearSizeCommittedValues(getState(), { isModal })
  if (Object.values(selectedRearValues).some(value => !value) || !isEqual(selectedRearValues, committedRearValues)) {
    dispatch(resetRearSizeFields({ isModal }))
  }
}
