import { rest } from 'services'
import { refresh } from 'actions/router'
import { navigateToSearchByVehicle } from 'actions/shopByVehicle'
import { navigateToSearchByTireSizes } from 'actions/shopByTires'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { isSearchByTiresUrl, isSearchByTrailer } from 'helpers/location'
import { getIsTrailer } from 'helpers/vehicle'
import { getPageLocation } from 'selectors'
import { BRAND_DETAILS_PAGE, BRAND_COMPATIBILITY_QUERY_NAME, BRAND_COMPATIBILITY_COMPATIBLE, COMPARE_PAGE } from 'constant'
import { getDefaultPathname } from '../../../BrandDetailsPage/BrandTireLines/helpers'
import { push } from 'connected-react-router'
import getSearchByVehicleUrl from 'helpers/getSearchByVehicleUrl'

export const makeActiveVehicle = ({
  masterVehicle = {},
  tireRearSize,
  tireFrontSize,
  userVehicleId,
  vehicleType,
  formattedSize,
  triggerConfirmTireSizeModal
} = {}) => (dispatch, getState) => {
  const location = getPageLocation(getState())
  const areWeOnSearchByTires = isSearchByTiresUrl(getPageLocation(getState()))
  const areWeOnTiresTrailers = isSearchByTrailer(getPageLocation(getState()))
  const isBrandDetailsPage = location.pathname.startsWith(BRAND_DETAILS_PAGE)
  const isComparePage = location.pathname.startsWith(COMPARE_PAGE)
  const isTrailer = getIsTrailer({ vehicleType })
  const { vehicleId } = masterVehicle
  const queryCount = Object.keys(location.query || {}).length
  dispatch(setVehicleDetails(masterVehicle))

  if (areWeOnSearchByTires && !formattedSize && !isTrailer) {
    triggerConfirmTireSizeModal(null, null, userVehicleId, {}, vehicleId)
  } else {
    rest.api.makeActiveVehicle({ activeVehicleId: userVehicleId })
      .then(() => {
        const vehicle = { ...masterVehicle, frontTireSize: tireFrontSize, rearTireSize: tireRearSize }

        if (areWeOnSearchByTires || areWeOnTiresTrailers) {
          return dispatch(isTrailer ?
            navigateToSearchByTireSizes(vehicle, null, isTrailer) :
            navigateToSearchByVehicle(vehicle))
        }

        if (isBrandDetailsPage) {
          if (queryCount && queryCount > 1) {
            const defaultBrandPathname = getDefaultPathname(location)
            return dispatch(push(`${defaultBrandPathname}?${BRAND_COMPATIBILITY_QUERY_NAME}=${BRAND_COMPATIBILITY_COMPATIBLE}`))
          }

          return dispatch(refresh())
        }

        if (isComparePage) {
          const { query: {
            compareItems
          } = {},
          pathname } = location

          return dispatch(push(`${pathname}?compareItems=${compareItems}&srpPathname=${getSearchByVehicleUrl(vehicle)}`))
        }

        return dispatch(refresh())
      })
  }
}

