import { compose, withHandlers, withStateHandlers } from 'recompose'
import withEventListener from 'components/common/hoc/withEventListener'
import { TOGGLE_STORE_DETAILS_BOTTOM_BANNER } from 'constant'
import Toolbox from './Toolbox'

const withToolboxStateHandlers = withStateHandlers(
  {
    isOpen: false,
    bottomAligned: true
  },
  {
    handleToggle: ({ isOpen }) => () => ({ isOpen: !isOpen }),
    setIsBottomAligned: () => bottomAligned => ({ bottomAligned })
  }
)

const withStoreDetailsPageEventListener = withEventListener({
  eventName: TOGGLE_STORE_DETAILS_BOTTOM_BANNER,
  getHandler: ({ setIsBottomAligned }) => ({ detail: { isOpen }}) => setIsBottomAligned(!isOpen)
})

const withToolboxHandlers = withHandlers({
  handleFeedbackClick: () => e => {
    const feedBackBtnWrapper = document.getElementById('smg-feedbackbtn').shadowRoot
    feedBackBtnWrapper &&
      [...feedBackBtnWrapper.childNodes]
        .forEach(childNode => childNode.nodeName === 'DIV' && childNode.childNodes[0].click(e))
  },
  handleAudioEyeClick: () => e => {
    const aeBtn = document.getElementById('ae_launcher')
    aeBtn && aeBtn.click(e)
  }
})

export default compose(
  withToolboxStateHandlers,
  withStoreDetailsPageEventListener,
  withToolboxHandlers
)(Toolbox)
