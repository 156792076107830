import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import BOTFavoritesNotification from 'components/MyFavorites/BOTFavoritesNotification'

const PageFooter = ({ placeholders: { footerContentBlocks }}) =>
  <Wrapper data-printless>
    { footerContentBlocks }
    <BOTFavoritesNotification />
  </Wrapper>

PageFooter.propTypes = {
  placeholders: PropTypes.shape({
    footerContentBlocks: PropTypes.arrayOf()
  })
}

PageFooter.type = 'PageFooter'
PageFooter.placeholders = ['footerContentBlocks']

const Wrapper = styled.footer`
  background: ${Theme.colorPrimary};
  color: ${Theme.colorPrimaryAlternative};
`

export default PageFooter
