import { compose, withProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { getUserName } from 'selectors'
import active from 'components/common/hoc/active'
import withFilteredHoverHandlers from 'components/common/hoc/withFilteredHoverHandlers'
import withHeaderHorizontalNav from 'components/common/hoc/withHeaderHorizontalNav'
import withCombinedActiveStatesAndHandlers from 'components/common/hoc/withCombinedActiveStatesAndHandlers'
import withType from 'components/common/hoc/withType'
import { focusSubmenuItem } from 'components/Header/components/HeaderDropdown/HeaderDropDownItem/helpers'
import {
  focusCurrentItem,
  focusNextOrPrevItem,
  getFocusedSubItemIndex
} from 'components/common/hoc/withHeaderHorizontalNav/helpers'
import { SIGN_IN_ELEMENT_ID } from '../constants'
import { CART_ELEMENT_ID } from 'components/Header/HeaderCart/constants'
import { STORE_ELEMENT_ID } from 'components/Header/MyStore/constants'
import HeaderAccountMenu from './HeaderAccountMenu'

const mapStateToProps = state => ({
  userName: getUserName(state)
})

const withNavigationAndEscapeHandlers = withHandlers({
  escape: ({ setFocusHoverState }) => (e) =>
    focusCurrentItem({ e, setFocusHoverState }),
  down: ({
    links,
    nextItem,
    active,
    activate
  }) => (e) => {
    e.preventDefault()
    !active && activate()
    const nextSubItemIndex = getFocusedSubItemIndex(e) + 1
    if (nextSubItemIndex < links.length) {
      focusSubmenuItem({ e, index: nextSubItemIndex })
    } else {
      focusNextOrPrevItem({ e, name: nextItem, openSubmenu: true })
    }
  },
  up: ({ prevItem }) => (e) => {
    e.preventDefault()
    const prevSubItemIndex = getFocusedSubItemIndex(e) - 1
    if (prevSubItemIndex >= 0) {
      focusSubmenuItem({ e, index: prevSubItemIndex })
    } else if (prevSubItemIndex === -1) {
      focusCurrentItem({ e })
    } else {
      focusNextOrPrevItem({ e, name: prevItem, openSubmenu: true })
    }
  }
})

const withEnhancedProps = withProps(({
  userName,
  displayName,
  links = []
}) => ({
  title: userName ? `Hi, ${userName}` : displayName,
  isSubmenu: links.length,
  elementId: SIGN_IN_ELEMENT_ID,
  nextItem: CART_ELEMENT_ID,
  prevItem: STORE_ELEMENT_ID
}))

export default compose(
  withType({ type: 'HeaderAccountMenu', placeholders: ['links']}),
  connect(mapStateToProps),
  active,
  withFilteredHoverHandlers,
  withCombinedActiveStatesAndHandlers,
  withEnhancedProps,
  withNavigationAndEscapeHandlers,
  withHeaderHorizontalNav
)(HeaderAccountMenu)
