export const getLabelGetter = options => fieldValue => {
  const foundOption = options.find(({ value }) => value === fieldValue) || {}

  return foundOption.label || ''
}

export const findLabelUsingValue = ({ values, value }) => {
  const option = values.find(({ value: fieldValue }) => value === fieldValue) || {}

  return option.label
}

export const mapFields = ({ label, value }) => options => ({
  label: options[label],
  value: options[value]
})

export const isInitialized = field =>
  Boolean((field.values && field.values.length))


export const isLoading = fields => Object.values(fields)
  .map(({ loading }) => loading)
  .some(Boolean)

export const isValid = fields => Object.values(fields)
  .map(value => value.error)
  .every(error => !error)


export const makeReducer = (ymmeStateSlice, reducer) => (state, action) => {
  const { payload: { ymmeName } = {}} = action

  if (!ymmeName || !state[ymmeName]) {
    return state
  }

  return {
    ...state,
    [ymmeName]: {
      ...state[ymmeName],
      [ymmeStateSlice]: reducer(state[ymmeName][ymmeStateSlice], action)
    }
  }
}

export const processVehicleData = ({
  yearDesc,
  makeId,
  modelId,
  subModelId,
  vehicleName
}) => yearDesc ? {
  year: Number(yearDesc),
  make: Number(makeId),
  model: Number(modelId),
  subModel: Number(subModelId),
  vehicleName
} : {}
