export default [
  'BOTAboutStore',
  'BOTStoreAmenities',
  'BOTStoreInformation',
  'BOTStoreServices',
  'StoreDetailsOneColumnPage'
]

/*
Appears on:
/location/ca-los-angeles-90064/005768,
/regions/bay-area
*/
