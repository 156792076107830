import {
  NAMES,
  WIDTH,
  RATIO,
  DIAMETER,
  TIRE_SIZE_PARSING_REGEX,
  TIRE_SIZE_DECIMAL_PARSING_REGEX,
  DEFAULT_TIRE_SIZE
} from './constants'

export const checkLoading = tires => Object.values(tires)
  .map(({ loading }) => loading)
  .some(Boolean)

export const checkValid = tires => Object.values(tires)
  .map(value => value.error)
  .every(error => !error)

export const isInitialized = field =>
  (field.initialized || field.loading)

export const createHandlers = ({ position, names, click, select, filter, isModal }) =>
  names.reduce((acc, name) => ({
    ...acc,
    [name]: {
      onClick: () => click({ position, name, isModal }),
      onChange: selection => select({ position, name, value: selection ? selection.value : '', isModal })
    }
  }), {})

export const replaceTireDecimal = (size = '') =>
  size ? size.replace(TIRE_SIZE_DECIMAL_PARSING_REGEX, (matches = []) => matches[1] + (matches[2] || '0')) : size

export const getOptions = values =>
  values.map(value => ({ label: value, value }))

export const getRequestData = (name, values) => {
  switch (name) {
    case WIDTH:
      return {
        [WIDTH]: values[WIDTH]
      }

    case RATIO:
      return {
        [WIDTH]: values[WIDTH],
        [RATIO]: values[RATIO]
      }

    default:
      return {}
  }
}

export const getNextName = name => NAMES[NAMES.indexOf(name) + 1] || NAMES[0]
export const getNextNames = name => NAMES.slice(NAMES.indexOf(name) + 1)
export const isLastField = name => NAMES.indexOf(name) === NAMES.length - 1

const concatTireSize = ({ [WIDTH]: width, [RATIO]: ratio, [DIAMETER]: diameter }) =>
  `${width}${ratio}${diameter}`

export const getTiresSearchResultsPageUrl = ({ front = {}, rear = {}} = {}) =>
  `/tires/by-size/${concatTireSize(front)}/${concatTireSize(rear)}`

export const parseTireSize = tireSize =>
  tireSize ? (tireSize.match(TIRE_SIZE_PARSING_REGEX) || []).slice(1, 4) :
    [...Object.keys(DEFAULT_TIRE_SIZE).map((key) => DEFAULT_TIRE_SIZE[key])]
