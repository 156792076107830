import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Mixin from 'components/common/Mixin'

export const PlayPauseButton = ({ togglePlayPause, isPlaying }) =>
  <Button aria-label={ isPlaying ? 'Pause Carousel' : 'Play Carousel' } onClick={ togglePlayPause }>
    { isPlaying ? <PlayIcon asset="pause" alt="" /> : <PlayIcon asset="play" alt="" /> }
  </Button>

PlayPauseButton.propTypes = {
  isPlaying: PropTypes.bool,
  togglePlayPause: PropTypes.func
}

const Button = styled.button`
  ${Mixin.responsive('width', '12px', '20px !important')}
  ${Mixin.responsive('height', '12px', '20px !important')}
  padding: 0;
  border: none;
  background: transparent !important;
  cursor: pointer;
`

const PlayIcon = styled(Icon)`
  ${Mixin.responsive('width', '14px', '20px')}
  ${Mixin.responsive('height', '13px', '20px')}
  vertical-align: baseline;
`
