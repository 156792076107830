import { lifecycle, compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import withYmme from 'components/common/hoc/withYmme'
import { getLocationPathname } from 'selectors/getPageLocation'
import getActiveVehicle from 'selectors/getActiveVehicle'
import { getYmmeInitialized } from 'components/common/hoc/withYmme/selectors'
import { processVehicleData } from 'components/common/hoc/withYmme/helpers'
import { HOME } from 'constant'
import { YEARS } from 'components/common/hoc/withYmme/constants'
import { YMME_NAME } from 'components/VehicleConfigurator/components/ShopByVehicle/constants'

const withLifecycle = lifecycle({
  UNSAGE_componentWillMount() {
    const { mainYmmeInitialized, registerYmme, ymmeName } = this.props
    !mainYmmeInitialized && registerYmme({ ymmeName })
  },
  componentDidMount() {
    const { isHomepage, vehicle, mainYmmeInitialized, initialize, years, ymmeName, setValues } = this.props

    years && setValues({ name: YEARS, value: years, ymmeName })
    !isHomepage && !isEmpty(vehicle) && !mainYmmeInitialized && initialize({ ymmeName, vehicle })
  },
  componentDidUpdate({ vehicle: { vehicleName: prevVehicleName } = {}}) {
    const { isHomepage, vehicle: { vehicleName } = {}, vehicle, initialize, ymmeName } = this.props
    !isHomepage && !isEqual(prevVehicleName, vehicleName) && vehicleName && initialize({ ymmeName, vehicle })
  }
})

export default compose(
  connect(state =>
    ({
      isHomepage: getLocationPathname(state) === HOME,
      vehicle: processVehicleData(getActiveVehicle(state)),
      mainYmmeInitialized: getYmmeInitialized(state, { ymmeName: YMME_NAME })
    })),
  withProps(() => ({ customLifecycle: true })),
  withYmme({ name: YMME_NAME }),
  withLifecycle
)
