import React, { Fragment } from 'react'
import styled from 'styled-components'
import { A } from 'components/common/Link/Link'

const CARFAX_TERMS_AND_CONDITION_URL = 'https://www.carfax.com/company/legal-disclaimer'

const CarfaxCheckboxLabel = () =>
  <Fragment>
    I agree to the myCARFAX
    <TermsLink data-at-connect-carfax-terms target="_blank" href={ CARFAX_TERMS_AND_CONDITION_URL }>
      terms and conditions
    </TermsLink>,
    and authorize Big O Tires to activate my FREE myCARFAX Account.
  </Fragment>

export default CarfaxCheckboxLabel

const TermsLink = styled(A)`
  margin: 0 0 0 5px;
  font-size: 12px;
  font-weight: 600;
`
