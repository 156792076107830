import React from 'react'
import PropTypes from 'prop-types'
import { filterLazyProps } from './helpers'

const LazyDiv = ({ content, setContentRef, ...props }) =>
  <div ref={ setContentRef } dangerouslySetInnerHTML={{ __html: content }} { ...filterLazyProps(props) } />

LazyDiv.propTypes = {
  content: PropTypes.string,
  setContentRef: PropTypes.func
}

export default LazyDiv
