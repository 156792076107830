import { rest, isApiError } from 'services'
import { refresh } from 'actions/router'
import { change, untouch, SubmissionError } from 'redux-form'
import { createAction } from 'redux-actions'
import { hide } from 'redux-modal'
import { preparePersonalizationData } from './helpers'
import { PERSONALIZE_VEHICLE_MODAL, LICENSE_PLATE_DO_NOT_MATCH_CODE } from 'constant'
import { triggerNotification } from 'components/common/Notification/helpers'
import { isErrorCode } from 'helpers/utils'

export const getStatesRequest = createAction('SHOP_BY_LICENCE_PLATE/GET_STATES_REQUEST')
export const getStatesSuccess = createAction('SHOP_BY_LICENCE_PLATE/GET_STATES_SUCCESS')
export const getStatesFailure = createAction('SHOP_BY_LICENCE_PLATE/GET_STATES_FAILURE')

export const resetFormFields = (fields = {}) => dispatch => {
  const values = Object.keys(fields)
  values
    .forEach(value => {
      dispatch(change(PERSONALIZE_VEHICLE_MODAL, value, values[value]))
      dispatch(untouch(PERSONALIZE_VEHICLE_MODAL, value))
    })
}


export const getStatesApi = () => async dispatch => {
  dispatch(getStatesRequest())

  try {
    const { garageResponse: { states }} = await rest.api.getStates()
    dispatch(getStatesSuccess(states))
  } catch (err) {
    dispatch(getStatesFailure(err))
  }
}

export const handleSubmit = ({ ...values }, dispatch, props) => {
  const { userVehicleId } = props
  return rest.api.updateVehiclePersonalDetails(
    preparePersonalizationData({ values, userVehicleId })
  )
    .then(() => {
      dispatch(hide(PERSONALIZE_VEHICLE_MODAL))
      dispatch(refresh())
      triggerNotification()
    })
    .catch(err => {
      if (isApiError(err)) {
        if (
          isErrorCode({ err, code: LICENSE_PLATE_DO_NOT_MATCH_CODE })
        ) {
          throw new SubmissionError({
            _error: err.statusList[0].description
          })
        }
      }
    })
}
