import { createSelector } from 'helpers/reselect'

export const getCurrentStore = ({ myStore }) => myStore

export const getCurrentStoreNumber = createSelector(
  [getCurrentStore],
  myStore => myStore && myStore.storeNumber
)

export const getCurrentStoreId = createSelector(
  [getCurrentStore],
  myStore => myStore && myStore.storeId
)

export const getCurrentStorePhoneNumber = createSelector(
  [getCurrentStore],
  myStore => {
    const {
      phoneNumbers: [
        phoneNumber
      ] = []
    } = (myStore || {}) // myStore can be null

    return phoneNumber || ''
  }
)

export const getCurrentStoreEnableAppointmentFlag = createSelector(
  [getCurrentStore],
  myStore => myStore && myStore.appointmentEnable
)

export const getCurrentStoreIsE2EAppointment = createSelector(
  [getCurrentStore],
  myStore => myStore && myStore.isE2EAppointment
)

export default getCurrentStore
