import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Carousel from 'components/common/Carousel'
import Theme from 'components/common/Theme/Theme'
import VehicleTile from '../VehicleTile'
import prevAltArrow from 'components/common/Carousel/assets/Prev-alt.svg'
import nextAltArrow from 'components/common/Carousel/assets/Next-alt.svg'
import prevAltArrowDisabled from 'components/common/Carousel/assets/PrevDisabled-alt.svg'
import nextAltArrowDisabled from 'components/common/Carousel/assets/NextDisabled-alt.svg'

const GarageVehicles = ({
  carouselSettingsDesktop,
  carouselSettingsMobile,
  UserVehicleDetails: {
    userVehicle = []
  } = {},
  slideTrackWidth,
  makeActiveVehicle,
  triggerConfirmTireSizeModal
}) =>
  <React.Fragment>
    <Media.Desktop>
      <CarouselWrapper
        slideTrackWidth={ slideTrackWidth }
        config={ carouselSettingsDesktop }
        carouselWrapperProps={{ 'data-at-carousel-wrapper': 'garage-vehicles' }}>
        {
          userVehicle
            .map((vehicleData, index) =>
              <VehicleTile
                key={ vehicleData.userVehicleId || index }
                index={ index }
                { ...vehicleData }
                handleMakeVehicleActive={ () => makeActiveVehicle({ ...vehicleData, triggerConfirmTireSizeModal }) } />
            )
        }
      </CarouselWrapper>
    </Media.Desktop>
    <Media.Mobile>
      <CarouselWrapper
        config={ carouselSettingsMobile }
        carouselWrapperProps={{ 'data-at-carousel-wrapper': 'garage-vehicles' }}>
        {
          userVehicle
            .map((vehicleData = {}, index) =>
              <VehicleTile
                key={ vehicleData.userVehicleId || index }
                index={ index }
                { ...vehicleData }
                handleMakeVehicleActive={ () => makeActiveVehicle({ ...vehicleData, triggerConfirmTireSizeModal }) } />
            )
        }
      </CarouselWrapper>
    </Media.Mobile>
  </React.Fragment>

GarageVehicles.propTypes = {
  carouselSettingsDesktop: PropTypes.shape(),
  carouselSettingsMobile: PropTypes.shape(),
  makeActiveVehicle: PropTypes.func,
  slideTrackWidth: PropTypes.number,
  triggerConfirmTireSizeModal: PropTypes.func,
  UserVehicleDetails: PropTypes.shape()
}

export default GarageVehicles

const CarouselWrapper = styled(Carousel)`
  position: relative;
  ${Media.desktop`
    display: table-cell;
  `}

  ${Media.mobile`
    padding: 0 23px;
  `}

  .slick-prev {
    background: ${Theme.colors.white} url(${prevAltArrow}) center/contain no-repeat;
    ${Mixin.responsive('left', '-8px', '-32px')};
    height: 100%;
    z-index: 1;
  }

  .slick-next {
    background: ${Theme.colors.white} url(${nextAltArrow}) center/contain no-repeat;
    ${Mixin.responsive('right', '-8px', '-32px')};
    height: 100%;
    z-index: 1;
  }

  .slick-prev.slick-disabled {
    background: ${Theme.colors.white} url(${prevAltArrowDisabled}) center/contain no-repeat;
    background-size: contain;
    height: 100%;
  }

  .slick-next.slick-disabled {
    background: ${Theme.colors.white} url(${nextAltArrowDisabled}) center/contain no-repeat;
    background-size: contain;
    height: 100%;
  }

  .slick-arrow + .slick-list {
    margin: 0;
    ${Mixin.responsive('padding', '0 26px', '0')}
  }

  .slick-track {
    margin: 0;
    width: ${props => props.slideTrackWidth}px !important;
  }
`
