import {
  BREAD_PAYMENT_SDK,
  EDS_MAIL_ACCEPT_PAGE
} from './constants'

export const loadBreadPaymentSdk = (callback) => {
  const existingScript = document.getElementById(BREAD_PAYMENT_SDK)

  if (existingScript) {
    callback && callback()
    return
  }

  const initScript = document.createElement('script')
  initScript.type = 'text/javascript'
  const initScriptText = 'if(window.BreadPayments=window.BreadPayments||{snippetVersion:"1.2.1"},!BreadPayments.init){var b=BreadPayments;b.on=function(){var t=["on"].concat(Array.prototype.slice.call(arguments));(b.q=b.q||[]).push(t)},b.submitRtps=function(){var t=["submitRtps"].concat(Array.prototype.slice.call(arguments));(b.q=b.q||[]).push(t)},b.setup=function(){var t=["setup"].concat(Array.prototype.slice.call(arguments));(b.q=b.q||[]).push(t)},b.registerPlacements=function(){var t=["registerPlacements"].concat(Array.prototype.slice.call(arguments));(b.q=b.q||[]).push(t)}}'
  initScript.appendChild(document.createTextNode(initScriptText))
  document.body.appendChild(initScript)

  const loadScript = document.createElement('script')
  loadScript.src = 'https://connect.breadpayments.com/unified.sdk.js'
  loadScript.id = BREAD_PAYMENT_SDK
  loadScript.setAttribute('async', 'true')
  loadScript.setAttribute('data-bread-payments-key', '9d5fe90e-e5bc-427a-bf68-cd4d18892ee2')
  document.body.appendChild(loadScript)

  loadScript.onload = () => {
    window.BreadPayments.setup({
      env: window.location.origin !== 'https://www.bigotires.com' ? 'STAGE' : 'PROD'
    })

    callback && callback()
  }
}

export const runBreadPayment = (updatedTotalPrice) => {
  if (!window.BreadPayments) {
    return
  }

  const placementsElements = [].slice.call(document.querySelectorAll('div[data-bp]'))

  const placements = placementsElements.map(element => {
    const placement = {
      placementId: element.getAttribute('data-bd-placement-id'),
      locationType: element.getAttribute('data-bd-location-type'),
      financingType: element.getAttribute('data-bd-financial-type'),
      domID: element.getAttribute('id')
    }

    let totalPriceAttr = element.getAttribute('data-bd-total-price')
    totalPriceAttr = totalPriceAttr && updatedTotalPrice !== undefined ? updatedTotalPrice : totalPriceAttr

    if (totalPriceAttr) {
      const totalPrice = Math.round(parseFloat(totalPriceAttr) * 100)
      return { ...placement, order: { totalPrice }}
    }

    return placement
  })

  window.BreadPayments.registerPlacements(placements)
}

export const runBreadBatchProcessing = (pathName, search) => {
  if (!window.BreadPayments) {
    return
  }

  const setupData = {
    financingType: 'card',
    locationType: 'landing'
  }

  if (pathName === EDS_MAIL_ACCEPT_PAGE) {
    window.BreadPayments.submitRtps({ ...setupData, customerAcceptedOffer: true })
  } else if (search && search.toLowerCase().includes('prescreenid')) {
    window.BreadPayments.submitRtps(setupData)
  }
}

export const injectDynamicParameters = ({ content, totalPrice }) =>
  Boolean(totalPrice) && Boolean(content) ? content.replace(/TOTALPRICE/g, totalPrice) : content
