import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { LOGO_IMAGE } from './constants'
import { generateSitewideSchema } from './helpers'

const Head = ({
  title,
  meta,
  link,
  canonicalUrl,
  image,
  description,
  isPageForSitewideSchema
}) =>
  <Helmet
    title={ title }
    meta={ meta }
    link={ link } >
    <meta property="og:url" content={ canonicalUrl } />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={ title } />
    <meta property="og:description" content={ description } />
    <meta property="og:image" content={ image || LOGO_IMAGE } />
    <meta property="og:site_name" content="Big O Tires" />
    <meta name="title" content={ title } />
    <meta name="description" content={ description } />
    <meta name="robots" content="index, follow" />
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="language" content="English" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@bigotires" />
    <meta name="twitter:title" content={ title } />
    <meta name="twitter:description" content={ description } />
    <meta name="twitter:image" content={ image || LOGO_IMAGE } />
    <meta name="twitter:creator" content="@bigotires" />

    {isPageForSitewideSchema && (
      <script type="application/ld+json">
        {generateSitewideSchema(canonicalUrl, title, description)}
      </script>
    )}
  </Helmet>

Head.propTypes = {
  canonicalUrl: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  isPageForSitewideSchema: PropTypes.bool,
  link: PropTypes.arrayOf(PropTypes.shape({
    rel: PropTypes.string,
    href: PropTypes.string
  })),
  meta: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ])
  })),
  title: PropTypes.string
}

export default Head
