import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { OPTIONS_WRAPPER_CLASS_NAME, TIRE_SIZE_TITLE } from '../../constant'


const MobileFixedWrapper = ({ children, place }) =>
  <Wrapper place={ place }>
    { children }
  </Wrapper>

export default MobileFixedWrapper

MobileFixedWrapper.propTypes = {
  children: PropTypes.node,
  place: PropTypes.string
}

const Wrapper = styled.div`
  @media (max-width: 700px) {
    top: 62px;
    position: fixed;
    background: #fff;
    width: calc(100vw - 16px);
    max-width: 100vw;
    margin-left: calc(50% - 50vw + -12px);
    z-index: 100;

    & + ${`.${OPTIONS_WRAPPER_CLASS_NAME}`} {
      overflow-y: scroll;
      margin-top: 280px;
      ${props => props.place !== TIRE_SIZE_TITLE && css`
        margin-top: 230px;
      `}
    }
  }
`
