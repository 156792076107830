import { withProps, compose } from 'recompose'
import withType from 'components/common/hoc/withType'
import { generateItemListElement } from 'helpers/SEOSchema'
import BreadcrumbLink from './BreadcrumbLink'

const withItemListElement = withProps(({ links = []}) => ({
  itemListElement: generateItemListElement(links)
}))

export default compose(
  withType({
    type: [
      'BOTBreadcrumbLink',
      'BOTBreadcrumbLink',
      'BOTBrandBreadcrumbLink',
      'BOTTireLineBreadcrumbLink',
      'BOTProductBreadcrumbLink',
      'BOTSearchResultBreadcrumbLink'
    ],
    placeholders: ['links']}),
  withItemListElement
)(BreadcrumbLink)
