import React from 'react'
import PropTypes from 'prop-types'
import { A } from 'components/common/Link/Link'

const PhoneLink = ({
  bolder,
  className,
  displayFormat,
  href,
  handlePhoneLinkClick,
  children
}) =>
  <A
    bolder
    className={ className }
    target="_self"
    href={ `tel:${href.length === 10 ? '+1' : ''}${href}` }
    name={ href }
    onClick={ handlePhoneLinkClick }>
    { !children ? displayFormat : children}
  </A>

export default PhoneLink

PhoneLink.propTypes = {
  bolder: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  displayFormat: PropTypes.string,
  handlePhoneLinkClick: PropTypes.func,
  href: PropTypes.string
}
