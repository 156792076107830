import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from 'components/common/Text/Text'
import Modal from 'components/common/Modal'
import Media from 'components/common/Media'
import { nullComponent } from 'helpers/utils'

const GenericMessageModal = ({
  text,
  title,
  handleCancel,
  handleHide,
  SubComponents = nullComponent
}) =>
  <CustomModal
    data-at-genericmessagemodal
    title={ title }
    closeHandler={ handleHide }>
    <StyledText title={ title }>{ text }</StyledText>
    <SubComponents closeHandler={ handleHide } />
  </CustomModal>

export default GenericMessageModal

GenericMessageModal.propTypes = {
  handleCancel: PropTypes.func,
  handleHide: PropTypes.func,
  SubComponents: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func
  ]),
  text: PropTypes.string,
  title: PropTypes.string
}

const CustomModal = styled(Modal)`
  ${Media.desktop`
    padding: 25px 24px 67px;
  `}
`

const StyledText = styled(Text)`
  ${props => !props.title && css`
    margin-top: 40px;
    display: inline-block;
  `}
`
