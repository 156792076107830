import { compose, withStateHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import registerModalPage from 'components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { FORGOT_PASSWORD_MODAL } from 'constant'
import { FORGOT_PASSWORD_TITLE, THANK_YOU_TITLE } from '../constants'
import ForgotPasswordModal from './ForgotPasswordModal'

const withForgotPasswordTitleChange = withStateHandlers(
  {
    forgotPasswordTitle: FORGOT_PASSWORD_TITLE
  },
  {
    setForgotPasswordTitleSuccess: () => (title = THANK_YOU_TITLE) => ({ forgotPasswordTitle: title }),
    setForgotPasswordTitle: () => (title = FORGOT_PASSWORD_TITLE) => ({ forgotPasswordTitle: title })
  }
)

export default compose(
  registerReduxModal({ name: FORGOT_PASSWORD_MODAL }),
  registerModalPage({
    rootType: MODAL_BOX_TYPE,
    pageSrc: ({ path = '' }) => path
  }),
  withForgotPasswordTitleChange
)(ForgotPasswordModal)
