import { compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import registerModalPage from 'components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { FORGOT_USERNAME_MODAL } from 'constant'
import ForgotUsernameModal from './ForgotUsernameModal'

export default compose(
  registerReduxModal({ name: FORGOT_USERNAME_MODAL }),
  registerModalPage({
    rootType: MODAL_BOX_TYPE,
    pageSrc: ({ path = '' }) => path
  })
)(ForgotUsernameModal)
