import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'redux-form'
import Mixin from 'components/common/Mixin'
import { Input } from 'components/common/FormElements/Input'
import Button from 'components/common/Button/Button'
import Banner from 'components/common/Banner/Banner'

const EmailForm = ({
  model,
  submitting,
  handleSubmit,
  handleHide,
  error,
  ...props
}) =>
  <form noValidate onSubmit={ handleSubmit }>
    <InputFieldWrapper>
      <Field full static { ...model.email } component={ Input } />
    </InputFieldWrapper>
    <InputFieldWrapper>
      <Field full static { ...model.message } component={ Input } />
    </InputFieldWrapper>
    {
      error &&
      <CustomBanner error_alternative>{ error }</CustomBanner>
    }
    <SubmitWrapper>
      <Button full="mobile" stacked="mobile" disabled={ submitting }>{ submitting ? 'LOADING...' : 'SEND' } </Button>
      <Button full="mobile" stacked="mobile" bolder link onClick={ handleHide }>Cancel</Button>
    </SubmitWrapper>
  </form>

EmailForm.propTypes = {
  error: PropTypes.string,
  handleHide: PropTypes.func,
  handleSubmit: PropTypes.func,
  model: PropTypes.shape({
    email: PropTypes.string,
    message: PropTypes.string
  }),
  submitting: PropTypes.bool
}

export default EmailForm

const InputFieldWrapper = styled.div`
  ${Mixin.responsive('margin-top', '20px', '15px')}
`

const SubmitWrapper = styled.div`
  margin-top: 20px;
`

const CustomBanner = styled(Banner)`
  margin-bottom: 0;
`
