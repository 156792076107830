import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/common/Button/Button'
import Switcher from './Switcher'
import styled from 'styled-components'

const LicenceSwitcher = ({ handleSwitch, alternateHeading, ...props }) =>
  <Switcher dataAttributes={{ 'data-at-vehicle-plate-number-switcher': true }} alternateHeading={ alternateHeading } asset="plate" { ...props }>
    <Fragment>
      { alternateHeading ? 'Find Your Vehicle By Entering' : 'Not sure? Find your vehicle by entering its' }&nbsp;
    </Fragment>
    <LicenseSwitcherButton link bolder prevent data-at-license-switcher-button onClick={ handleSwitch }>
      License Plate Number
    </LicenseSwitcherButton>
  </Switcher>

export default LicenceSwitcher

const LicenseSwitcherButton = styled(Button)`
  && {
    padding: 0 0 3px;
  }
`

LicenceSwitcher.propTypes = {
  alternateHeading: PropTypes.bool,
  handleSwitch: PropTypes.func
}
