import styled, { css } from 'styled-components'
import Media from 'components/common/Media'
import { H1 } from 'components/common/Text/Text'
import Button from 'components/common/Button/Button'
import Theme from 'components/common/Theme/Theme'

export const CarfaxTitle = styled.span`
  display: inline-block;
  margin: 0 8px 0 0;
  line-height: 19px;
  vertical-align: middle;
  ${props => props.left && css`
    margin-bottom: 25px;
    vertical-align: top;
  `}
  ${props => props.block && css`
    ${Media.mobile`
      display: block;
    `}
  `}
`

export const Logo = styled.img`
  max-width: 100%;
  ${props => props.middle && css`
    vertical-align: middle;
  `}
`

export const Headline = styled(H1)`
  ${Media.mobile`
    font-size: 20px;
    line-height: 43px;
  `}
`

export const ActionButton = styled(Button)`
  cursor: pointer;
`


export const ButtonsWrapper = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CustomButton = styled(ActionButton)`
  margin: 30px 0 !important;
  color: ${Theme.colors.red};
  background: ${Theme.colors.transparent};
  border: none;

  &:hover {
    background: ${Theme.colors.transparent};
    color: ${Theme.colors.red};
  }
`
