import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Icon from 'components/common/Icon/Icon'
import VehicleImage from 'components/common/VehicleImage'

export const Tile = styled.div.attrs(({ isActiveVehicle }) => ({
  'data-at-carbar-vehicle': isActiveVehicle ? null : true,
  'data-at-carbar-vehicle-active': isActiveVehicle ? true : null
}))`
  position: relative;
  ${Mixin.responsive('width', '18.2em', '19em')}
  ${Media.mobileNarrow`
    width: 17em;
  `}
  height: 7.142em;
  vertical-align: middle;
  border: 1px solid ${Theme.colors.light_grey};
  border-spacing: 0 0;
  ${Mixin.responsive('padding', '10px 10px 10px 100px', '8px 8px 8px 100px')}
  ${Media.mobileNarrow`
    padding-left: 85px;
  `}
  ${Media.desktop`
    margin-right: 15px;
  `}
  ${Media.mobile`
    margin: 0 auto;
  `}
`

export const TileIcon = styled(VehicleImage)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${Mixin.responsive('margin-left', '-90px', '-95px')}
  ${Media.mobileNarrow`
    margin-left: -80px;
  `}
`

export const TileDescription = styled.div`
  display: inline-block;
  min-height: 2.714em;
  padding-bottom: 25px;
  overflow: hidden;
  font-weight: 600;
  vertical-align: middle;
  max-height: 3.214em;
  text-overflow: ellipsis;
`

export const Circle = styled(Icon)`
  position: absolute;
  top: 8px;
  right: 12px;
`
