import { compose } from 'redux'
import { connect } from 'react-redux'
import { getPageLocation } from 'selectors'
import AccordionItem from './AccordionItem'
import { lifecycle, withHandlers, withState } from 'recompose'
import { isCategoryContainActiveArticle } from '../../../helpers'
import { KEY_EVENT_CODES } from 'components/common/hoc/withKeyPress/constants'

const mapStateToProps = state => ({
  pageLocation: getPageLocation(state)
})

const withActiveCategoryState = withState('isActiveCategory', 'setIsActiveCategory', ({
  contentVisible
}) => contentVisible)

const withKeypressHandlers = withHandlers({
  onEnterKeypress: ({ onClick }) => (e) => {
    e.PreventDefault()
    e.stopPropagation()

    if (e.key === KEY_EVENT_CODES.enter) {
      onClick()
    }
  }
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const {
      onClick,
      pageLocation: {
        pathname
      },
      item: {
        articlesList
      }} = this.props

    if (isCategoryContainActiveArticle({ pathname, articlesList })) {
      onClick()
    }
  },
  componentDidUpdate({ contentVisible: prevContentVisible }) {
    const { contentVisible, setIsActiveCategory } = this.props

    if ((contentVisible !== prevContentVisible) && contentVisible) {
      setIsActiveCategory(true)
    }

    if ((contentVisible !== prevContentVisible) && !contentVisible) {
      setIsActiveCategory(false)
    }
  }
})

export default compose(
  connect(mapStateToProps),
  withActiveCategoryState,
  withKeypressHandlers,
  withLifecycle
)(AccordionItem)
