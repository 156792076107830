import { compose } from 'redux'
import { connect } from 'react-redux'
import { withProps, withHandlers } from 'recompose'
import * as actions from './actions'
import getActiveVehicle from 'selectors/getActiveVehicle'
import { getYmmeExperience } from 'selectors/thirdPartyData'
import { getLocationPathname } from 'selectors/getPageLocation'
import { getTireSize } from './selectors'
import { createHandlers } from './helpers'
import getViewModel from './viewModel'
import { HOME, TIRES_LANDING_PAGE } from 'constant'
import { FRONT, REAR, NAMES, POPULAR_TIRE_SIZES } from './constants'

const withRearTireSizeToggleHandler = withHandlers({
  handleRearTireSizeToggle: ({
    isRearTireSizeVisible,
    openRearTireSize,
    handleCloseRearTireSize,
    getCustomTireSize,
    isModal
  }) => () => {
    if (isRearTireSizeVisible) {
      handleCloseRearTireSize({ isModal })
    } else {
      openRearTireSize({ isModal })
      getCustomTireSize({ position: REAR, isModal })
    }
  }
})

const withShopByTiresHandlers = withProps(({ handleToggle, handleSelectValue, setFilter, isModal }) => ({
  handlers: {
    front: createHandlers({
      position: FRONT,
      names: NAMES,
      click: handleToggle,
      select: handleSelectValue,
      filter: setFilter,
      isModal
    }),
    rear: createHandlers({
      position: REAR,
      names: NAMES,
      click: handleToggle,
      select: handleSelectValue,
      filter: setFilter,
      isModal
    })
  }
}))

const withViewModel = withProps(({ tireSize: { front, rear } = {}}) => ({
  model: {
    front: getViewModel({
      width: front.width.values,
      ratio: front.ratio.values,
      diameter: front.diameter.values
    }),
    rear: getViewModel({
      width: rear.width.values,
      ratio: rear.ratio.values,
      diameter: rear.diameter.values
    })
  }
}))

const mapStateToProps = (state, props) => {
  const pathname = getLocationPathname(state)
  const { isModal } = props

  return {
    activeVehicle: getActiveVehicle(state),
    showPopularSizes: (pathname === HOME || pathname === TIRES_LANDING_PAGE) &&
      !isModal && getYmmeExperience(state) === 'c',
    ...getTireSize(state, props)
  }
}

const withEnhancedWidthValues = withProps(({ tireSize = {}, showPopularSizes }) =>
  ({
    tireSize: showPopularSizes ? {
      ...tireSize,
      front: {
        ...tireSize.front,
        width: {
          ...tireSize.front.width,
          values: [...POPULAR_TIRE_SIZES, ...tireSize.front.width.values]
        }
      },
      rear: {
        ...tireSize.rear,
        width: {
          ...tireSize.rear.width,
          values: [...POPULAR_TIRE_SIZES, ...tireSize.rear.width.values]
        }
      }
    } : tireSize
  }))

export default compose(
  connect(mapStateToProps, actions),
  withEnhancedWidthValues,
  withRearTireSizeToggleHandler,
  withShopByTiresHandlers,
  withViewModel
)
