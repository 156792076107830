export const SIZE_NOT_LISTED_OPTION = 'My tire size isn\'t listed'
export const NEXT_APPOINTMENT_TEXT = 'Next Appointment'
export const NO_APPOINTMENTS_TEXT = 'You don\'t have any upcoming appointments yet.'
export const INFO_MESSAGE_TEXT = 'Appointments are only captured from website requests and not from phone call requests'
export const ADD_YOUR_VEHICLE_TEXT = 'Add your first vehicle in order to follow your next appointments'
export const YOU_CAN_REQUEST_APPOINTMENT_TEXT = 'You can request a quick appointment.'
export const VEHICLE_NAME_PLACEHOLDER = 'MY GARAGE 1ST CAR'
export const MILEAGE_PLACEHOLDER = '0'
export const MILEAGE_RECOMMENDATIONS_DEFAULT = '21,548'
export const MILEAGE_RECOMMENDATIONS_TITLE = 'KEEP YOUR MILEAGE UPDATED'
export const ADD_VEHICLE_BUTTON_LABEL = 'Add a Vehicle'
export const LEARN_MORE_BUTTON_LABEL = 'Learn More'
export const VEHICLE_DESCRIPTION_PLACEHOLDER = 'Add a vehicle to shop products, schedule appointments, view service history and more.'
export const CUSTOM_PLAY_PAUSE_BUTTON_CLASS = 'custom-play-pause-button'
export const LICENCE_PLATE_INVALID = 'Please enter a valid License Plate Number.'
export const STATE_EMPTY = 'Please enter your State.'
export const MILEAGE_EMPTY = 'Please enter your Mileage.'
export const NEXT_APPOINTMENT_MY_GARAGE_CLASS = 'next-appointment-my-garage'
export const SUCCESS_UPDATE_MESSAGE = 'Your vehicle was updated successfully.'
export const GENERIC_TRAILER_TYPE = 'rtVehicleType'
export const TRAILER_TYPE = 'trailer'

export const VEHICLE_DETAILS_URL = '/my-account/vehicle-details'
export const VIEW_VEHICLE_DETAILS_BUTTON_LABEL = 'View Vehicle Details'
export const NOT_ACTIVE_VEHICLE_QUERY_PARAMS = 'wasNotActiveVehicle'
