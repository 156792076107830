export default [
  'TireConfirmation',
  'TireConfirmationModal'
]

/*
Appears on:
/confirm-tire-size,
/modals/confirm-tire-size
*/
