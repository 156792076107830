import {
  SEARCH_BY_VEHICLE_REGEXP,
  TIRE_DETAILS_REVIEWS_QUERY,
  SEARCH_BY_TIRE_SIZE_REGEXP,
  TIRE_SEARCH_REGEX,
  TRAILER_SEARCH_REGEX
} from 'constant'
import qs from 'qs'

export const isSearchByVehicleUrl = ({ pathname = '' } = {}) =>
  SEARCH_BY_VEHICLE_REGEXP.test(pathname)

export const isSearchByTireSizeUrl = ({ pathname = '' } = {}) =>
  SEARCH_BY_TIRE_SIZE_REGEXP.test(pathname)

export const isSearchByTrailer = ({ pathname = '' } = {}) =>
  TRAILER_SEARCH_REGEX.test(pathname)

export const isSearchByTiresUrl = ({ pathname = '' } = {}) =>
  TIRE_SEARCH_REGEX.test(pathname)

export const isTireComparisonUrl = ({ pathname = '' } = {}) =>
  pathname.includes('compare-tires')

export const getReviewsLink = (link = '') =>
  `${link}?tab=${TIRE_DETAILS_REVIEWS_QUERY}`

export const parseSearchStr = ({ search = '' } = {}) =>
  qs.parse(
    search.startsWith('?') ? search.slice(1) : search
  )
