import { handleActions } from 'redux-actions'
import { addReviews, setRenderBVReviews } from './actions'

const initialState = {
  bazaarVoiceInlineReviews: {},
  renderBVReviews: false
}

const bazaarVoiceReviews = handleActions({
  [addReviews]: (state, { payload: { reviews }}) =>
    ({ ...state,
      bazaarVoiceInlineReviews: {
        ...state.bazaarVoiceInlineReviews, ...reviews
      }
    }),
  [setRenderBVReviews]: (state, { payload }) =>
    ({ ...state,
      renderBVReviews: payload
    })
}, initialState)

export default bazaarVoiceReviews
