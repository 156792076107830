// TODO use aproved title instead of 'BigO tire'
export const DEFAULT_PAGE_TITLE = 'BigO Tire'
export const PROMO_DETAIL_PAGE_URL_RGX = '^[^/]*//[^/]*/deal/'

export const LOGO_IMAGE = 'https://tbc.scene7.com/is/image/TBCCorporation/BOT-Logo'

export const EXCLUDED_PAGES_FOR_SITEWIDE_SCHEMA = [
  '/tires/trailer',
  '/tires/winter-snow',
  '/tires/electric-vehicle-tires',
  '/tires/brands',
  '/tires/brand',
  '/tires/by-vehicle',
  '/services/',
  '/resources',
  '/tires/line',
  '/tires/sku'
]
