import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import noop from 'lodash/noop'
import { NavLink } from 'react-router-dom'
import { filterProps } from 'helpers/utils'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import { followArrow } from 'components/common/Button/Button'
import { DEFAULT_PATH, EXTERNAL_LINK } from 'constant'

const LinkComponent = ({
  className,
  children,
  to,
  onClick = noop,
  button,
  isCurrent,
  breadcrumb,
  block,
  bolder,
  isActive, // Stop console errors by pulling out props
  underline,
  ...props
}) => (
  EXTERNAL_LINK.test(to || '') ?
    <A
      { ...props }
      href={ to }
      className={ className }
      button={ button }
      block={ block }
      bolder={ bolder }
      underline={ underline }
      onClick={ onClick }>{ children }</A> :
    <NavLink { ...props } to={ to || DEFAULT_PATH } className={ className } onClick={ onClick }>
      { children }
    </NavLink>
)

LinkComponent.propTypes = {
  block: PropTypes.bool,
  bolder: PropTypes.bool,
  breadcrumb: PropTypes.bool,
  button: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  isActive: PropTypes.oneOf([
    PropTypes.bool,
    PropTypes.func
  ]),
  isCurrent: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  underline: PropTypes.bool
}

const notAllowedProps = ['full', 'followarrow', 'hollow_button']

const Link = styled(props => <LinkComponent { ...filterProps(props, notAllowedProps) } />)`
  color: ${Theme.colors.blue};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  ${props => props.block && css`
    display: block;
  `}
  ${props => props.bolder && css`
    ${Mixin.font.GothamBold()}
  `}
  ${props => props.size && css`
    font-size: ${props.size}px;
  `}
  ${props => props.menu && css`
    color: ${Theme.colors.black};
    text-transform: uppercase;
    ${Mixin.font.GothamBold(16)}
  `}
  ${props => props.important && css`
    color: ${Theme.colors.red};
  `}
  ${props => props.breadcrumb && css`
    padding-right: 21px;
    color: ${Theme.colors.dark_grey};
    ${Mixin.font.GothamBook(12)}
    position: relative;

    :hover {
      text-decoration: none;
    }

    ::before,
    ::after {
      content: '';
      position: absolute;
    }

    ::before {
      top: 3px;
      right: 9px;
      border-left: 5px solid ${Theme.colors.dark_grey};
      border-top: 5px solid ${Theme.colors.transparent};
      border-bottom: 5px solid ${Theme.colors.transparent};
    }

    ::after {
      right: 11px;
      top: 4px;
      border-left: 4px solid #fff;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  `}
  ${props => props.button && css`
    display: inline-block;
    padding: 7px 30px 8px;
    color: ${Theme.colors.white};
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    background: ${Theme.colors.bright_red_new};
    border: 1px solid ${Theme.colors.bright_red_new};
    border-radius: 0;
    ${Mixin.font.GothamBold(13)}
    letter-spacing: 0.05em;
    line-height: 1.05em;
    transform: scale(1, 1.3);

    :hover {
      background: ${Theme.colors.crimson};
      border: 1px solid ${Theme.colors.crimson};
      color: ${Theme.colors.white};
      text-decoration: none;
    }
  `}

  ${props => (props.followarrow || props.follow) && followArrow}
  ${props => props.hollow_button && css`
    display: inline-block;
    padding: 7px 30px 8px;
    color: ${Theme.colors.bright_red_new};
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    letter-spacing: 0.5px;
    background: ${Theme.colors.white};
    border: 1px solid ${Theme.colors.bright_red_new};
    border-radius: 0;
    ${Mixin.font.GothamBold(13)}
    letter-spacing: 0.05em;
    line-height: 1.05em;

    :hover {
      background: ${Theme.colors.crimson};
      color: ${Theme.colors.white};
      text-decoration: none;
    }
  `}

  ${props => props.underline && css`
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  `}

  ${Mixin.property('full', `
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  `)}

  ${props => props.narrow && css`
    transform: scale(1, 1.3);
  `}
`

export default Link

export const A = styled.a.attrs(({
  anchor,
  href,
  prevent,
  disabled,
  handler,
  target
}) => ({
  target: target || ((anchor || href) ? '_self' : '_blank'),
  href: href || '#',
  onClick: e => {
    prevent && e.preventDefault()
    !disabled && handler && handler(e)
  }
}))`
  color: ${Theme.colors.blue};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  ${props => props.block && css`
    display: block;
  `}
  ${props => props.bolder && css`
    ${Mixin.font.GothamBold()}
  `}
  ${props => props.button && css`
    display: inline-block;
    padding: 7px 30px 8px;
    color: ${Theme.colors.white};
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    background: ${Theme.colors.bright_red_new};
    border: 1px solid ${Theme.colors.bright_red_new};
    border-radius: 0;
    ${Mixin.font.GothamBold(13)}
    letter-spacing: 0.05em;
    line-height: 1.05em;
    transform: scale(1, 1.3);

    ${Mixin.property('full', `
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    `)}

    :hover {
      background: ${Theme.colors.crimson};
      border-color: ${Theme.colors.crimson};
      color: ${Theme.colors.white};
      text-decoration: none;
    }
  `}
  ${props => props.disabled && css`
    color: ${Theme.colors.grey};

    :hover {
      text-decoration: none;
      cursor: default;
    }
  `}
  ${props => props.underline && css`
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  `}
`

export const ToggleLink = styled.a.attrs({
  'data-toggle-link': true
})`
  && {
    text-decoration: none;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  color: ${Theme.colors.blue};
`
