export const DAYS_OF_WEEK = [
  { name: 'Sun', title: 'Sunday' },
  { name: 'Mon', title: 'Monday' },
  { name: 'Tue', title: 'Tuesday' },
  { name: 'Wed', title: 'Wednesday' },
  { name: 'Thu', title: 'Thursday' },
  { name: 'Fri', title: 'Friday' },
  { name: 'Sat', title: 'Saturday' }
]

export const DEFAULT_TITLE_FORMAT = 'MMMM YYYY'

export const FORWARD_CHANGE = 1
export const BACKWARD_CHANGE = -1

export const CHANGE_MONTH = 'month'
export const CHANGE_DAY = 'day'
export const CHANGE_FOCUS = 'focus'
export const CHANGE_NONE = 'none'
