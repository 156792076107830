import { push } from 'connected-react-router'
import { refresh } from 'actions/router'
import { getMiniCartData, cleanShoppingData } from 'actions/shoppingData'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { rest } from 'services'

export const makeActiveVehicle = ({
  userVehicleId,
  url,
  showConfirmTireSizeModal = false,
  masterVehicle,
  triggerConfirmTireSizeModal = null,
  updateMiniCart = false
}) => dispatch =>
  rest.api
    .makeActiveVehicle({
      activeVehicleId: userVehicleId
    })
    .then(() => {
      if (updateMiniCart) {
        dispatch(cleanShoppingData())
        dispatch(getMiniCartData())
      }
    })
    .then(() => {
      if (showConfirmTireSizeModal) {
        const { vehicleId } = masterVehicle

        dispatch(setVehicleDetails(masterVehicle))

        triggerConfirmTireSizeModal && triggerConfirmTireSizeModal(null, null, userVehicleId, {}, vehicleId)
      } else {
        dispatch(url ? push(url) : refresh())
      }
    })
