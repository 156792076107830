import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import App from './App'
import configureStore from 'store/configureStore'
import storeProvider from 'store/storeProvider'
import { screenChange } from 'components/common/Media/reducer'
import { DEBOUNCE_TIMEOUT } from 'constant'
import debounce from 'lodash/debounce'

const initialState = window.__INITIAL_STATE__

const history = createBrowserHistory()

history.listen((location, action) => {
  const { hash } = location || {}
  if (hash) {
    setTimeout(() => {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) element.scrollIntoView()
    }, 0)
  }
})

const store = configureStore(initialState, history)

storeProvider.setStore(store)

if (typeof window !== 'undefined') {
  window.React = React
  window.ReactDOM = ReactDOM
}

export default function ConfiguredApp() {
  const screenChangeFn = debounce(() => store.dispatch(screenChange()), DEBOUNCE_TIMEOUT)

  window.addEventListener('resize', screenChangeFn)
  window.addEventListener('orientationchange', screenChangeFn)
  store.dispatch(screenChange())

  return (
    <App store={ store } history={ history } />
  )
}
