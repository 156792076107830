import { withProps } from 'recompose'
import { ICON_WIDTH, ICON_INDENT, BORDER_ADJUSTMENT } from './constants'
import CheckValidationFormField from './CheckValidationFormField'

export default withProps(({
  meta: {
    valid
  },
  input: {
    value
  }
}) => ({
  rightIndent: value && valid ? (ICON_WIDTH + (ICON_INDENT * 2)) - BORDER_ADJUSTMENT : null
}))(CheckValidationFormField)
