import { VEHICLE_DELETE_MODAL } from 'constant'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import VehicleDeleteModal from './VehicleDeleteModal'
import * as actions from './actions'

const withHandleVehicleDelete = withHandlers({
  handleVehicleDelete: ({ deleteVehicle, userVehicleId }) => () =>
    deleteVehicle(userVehicleId)
})

export default compose(
  registerReduxModal({ name: VEHICLE_DELETE_MODAL }),
  connect(null, { ...actions }),
  withHandleVehicleDelete
)(VehicleDeleteModal)

export { actions }
