import { compose, lifecycle } from 'recompose'
import { loadBreadPaymentSdk, runBreadPayment } from '../helpers'
import BreadPaymentsPlacement from './BreadPaymentsPlacement'
import isEqual from 'lodash/isEqual'
import withBreadPayments from '../withBreadPayments'

const withLifeCycle = lifecycle({
  componentDidMount() {
    loadBreadPaymentSdk(() => runBreadPayment())
  },
  componentDidUpdate({ totalPrice: prevTotalPrice }) {
    const { totalPrice } = this.props
    if (!isEqual(totalPrice, prevTotalPrice)) {
      loadBreadPaymentSdk(() => runBreadPayment())
    }
  }
})

export default compose(
  withBreadPayments,
  withLifeCycle)(BreadPaymentsPlacement)
