import { show, hide } from 'redux-modal'
import { EMAIL_SIGN_UP_MODAL } from '../EmailSignUpModal'

export const showEmailSignUpModal = modalInitialData => dispatch => {
  dispatch(show(EMAIL_SIGN_UP_MODAL, { modalInitialData }))
}

export const hideEmailSignUpModal = () => dispatch => {
  dispatch(hide(EMAIL_SIGN_UP_MODAL))
}

