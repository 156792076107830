import { WIDTH, RATIO, DIAMETER } from './constants'
import { getOptions } from './helpers'

export default ({
  width,
  ratio,
  diameter
}) => ({
  width: {
    name: WIDTH,
    label: 'Width',
    options: getOptions(width),
    required: true
  },
  ratio: {
    name: RATIO,
    label: 'Ratio',
    options: getOptions(ratio),
    required: true
  },
  diameter: {
    name: DIAMETER,
    label: 'Diameter',
    options: getOptions(diameter),
    required: true
  }
})
