import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import withRequestApptClick from 'components/common/hoc/withRequestApptClick'
import QuickAppointmentTeaser from './QuickAppointmentTeaser'
import { getCurrentStoreIsE2EAppointment } from 'selectors/getCurrentStore'
import { SCHEDULE_APPOINTMENT, REQUEST_APPOINTMENT } from 'constant'

const mapStateToProps = (state) => ({
  isE2EAppointment: getCurrentStoreIsE2EAppointment(state)
})

const withMakeAnAppointmentProps = withProps(({
  isE2EAppointment
}) => ({
  ctaTitle: isE2EAppointment ? SCHEDULE_APPOINTMENT : REQUEST_APPOINTMENT
}))

export default compose(
  withType({ type: 'QuickAppointmentTeaser' }),
  connect(mapStateToProps),
  withMakeAnAppointmentProps,
  withRequestApptClick
)(QuickAppointmentTeaser)
