import { handleActions } from 'redux-actions'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import { getInitialState, getInitialSearchMask } from './model'
import {
  fetchDataAction,
  fetchLocationsByAddrAction,
  fetchLocationsByCoordsAction,
  resetLocationsAction,
  setInitialSearchMethodAction,
  setSearchRadiusAction,
  setShowAllResultsAction,
  setSelectedSearchResultAction,
  setSearchErrorAction,
  setSearchMask,
  resetSearchMask,
  resetErrorAction,
  resetFormErrorAction,
  resetSearchAction
} from './actions'
import { SEARCH_BY_COORDS, SEARCH_BY_LOCATION } from 'constant'

const handleDataFetch = state => ({
  ...state,
  isLoading: true
})

const handleFetchLocationsByAddr = (state, { payload: { stores = [], searchMask, storesAtDistance }}) => ({
  ...state,
  searchRadius: +storesAtDistance,
  originalSearchResults: stores,
  searchMethod: SEARCH_BY_LOCATION,
  searchMask,
  showLimitedSearchResultsForMobile: true,
  isLoading: false,
  searchError: {}
})

const handleFetchLocationsByCoords = (state, { payload: { stores = [], searchCoords, storesAtDistance }}) => ({
  ...state,
  searchRadius: +storesAtDistance,
  originalSearchResults: stores,
  searchMethod: SEARCH_BY_COORDS,
  searchCoords,
  showLimitedSearchResultsForMobile: true,
  isLoading: false,
  searchError: {}
})

const handleSetInitialSearchMethod = (state, { payload: initialSearchMethod }) => ({
  ...state,
  initialSearchMethod
})

const handleSetSearchMask = (state, { payload: searchMask }) => ({
  ...state,
  searchMask
})

const handleResetSearchMask = state => ({
  ...state,
  searchMask: getInitialSearchMask()
})

const handleResetLocation = state => ({
  ...state,
  originalSearchResults: []
})

const handleResetSearchRadius = (state, { payload: searchRadius }) => ({
  ...state,
  searchRadius
})

const handleSelectISearchResult = (state, { payload: selectedSearchResult }) => ({
  ...state,
  selectedSearchResult
})

const handleSearchError = (state, { payload: { errorData, form }}) => ({
  ...state,
  ...getInitialState(),
  searchError: { ...state.searchError, [form]: errorData },
  errorForm: form,
  searchMask: state.searchMask,
  isLoading: false
})

const handleResetError = state => ({
  ...state,
  isLoading: false,
  searchError: {}
})

const handleResetFormError = (state, { payload: { form }}) => ({
  ...state,
  searchError: { ...cloneDeep(omit(state.searchError, [form])) }
})

const handleShowAllResults = state => ({
  ...state,
  showLimitedSearchResultsForMobile: false
})

const handleResetSearch = state => getInitialState()

export default handleActions({
  [setInitialSearchMethodAction]: handleSetInitialSearchMethod,
  [fetchDataAction]: handleDataFetch,
  [fetchLocationsByAddrAction]: handleFetchLocationsByAddr,
  [fetchLocationsByCoordsAction]: handleFetchLocationsByCoords,
  [resetLocationsAction]: handleResetLocation,
  [setSearchRadiusAction]: handleResetSearchRadius,
  [setShowAllResultsAction]: handleShowAllResults,
  [setSelectedSearchResultAction]: handleSelectISearchResult,
  [setSearchErrorAction]: handleSearchError,
  [setSearchMask]: handleSetSearchMask,
  [resetSearchMask]: handleResetSearchMask,
  [resetErrorAction]: handleResetError,
  [resetFormErrorAction]: handleResetFormError,
  [resetSearchAction]: handleResetSearch
}, getInitialState())
