import { getProductBySkuId } from 'selectors/cart'
import { getGBBDetails } from 'selectors/getGBBDetails'
import { SAVE_FOR_LATER_LIST_SOURCE } from 'constant'
import { getFavoriteSkus } from 'components/MyFavorites/selectors'

export const getPrice = price => Number(price) < 0 ? 0 : Number(price)

export const isRearTire = rearTire => rearTire ? Boolean(rearTire.length) : false

export const getGTMOTDPData = products =>
  Object.values(products.reduce((acc, { otdpAddons = []}) => {
    otdpAddons.filter(({ isSelected }) => isSelected).forEach(({ description, price }) => {
      acc[description] ? acc[description].price = (Number(acc[description].price) + price).toFixed(2) :
        acc[description] = {
          name: description,
          id: description,
          category: 'Taxes and Fees',
          quantity: 1,
          price
        }
    })
    return acc
  }, {}))

export const getGTMServiceData = ({ service, category }) => {
  const {
    name,
    serviceName,
    price,
    averageSalePrice,
    skuId,
    kitId,
    serviceId,
    alignmentId,
    quantity = 1,
    qty = 1
  } = (service || {})

  return {
    name: name || serviceName,
    id: serviceId || alignmentId || kitId || skuId,
    price: price || averageSalePrice || 0,
    quantity: quantity || qty,
    brand: name || serviceName,
    variant: name || serviceName,
    category: category || 'Services'
  }
}

export const getGTMKitData = kit => {
  const {
    quantity = 1,
    name,
    price,
    averageSalePrice,
    kitId: id
  } = (kit || {})

  return {
    name,
    id,
    price: price || averageSalePrice || 0,
    quantity,
    brand: name,
    variant: name,
    category: 'Services'
  }
}

export const getGTMProductData = ({ product, gBBDetails = {}, oeItems = [], evItems = []}) => {
  const {
    productName,
    skuId,
    totalPrice,
    qty,
    brandLineName,
    description,
    available
  } = (product || {})
  const { title: dimension2 } = gBBDetails[skuId] || {}

  return {
    name: productName,
    id: skuId,
    price: getPrice(totalPrice),
    brand: brandLineName || productName,
    category: 'Tires',
    variant: description,
    quantity: qty,
    dimension1: available ? 'in_stock' : 'Backordered',
    dimension2: dimension2 || 'none',
    dimension30: oeItems.includes(skuId),
    dimension31: evItems.includes(skuId)
  }
}

export const getGTMAddToCartProduct = ({
  product,
  quantity,
  label,
  dimension2,
  dimension28,
  dimension30,
  dimension31
} = {}) => {
  const {
    name,
    skuId: id,
    size: isTires,
    brandName: brand,
    price,
    listPrice,
    averageSalePrice,
    salePrice, // TODO check whether we need to use "salePrice" or "listPrice"
    description: variant,
    availability: dimension1
  } = (product || {})

  return {
    name,
    id,
    brand,
    price: isTires ? getPrice(price || salePrice || listPrice) : getPrice(averageSalePrice),
    variant,
    category: isTires ? 'Tires' : 'Services',
    quantity,
    dimension1,
    dimension28,
    dimension30,
    dimension31,
    ...label ? { dimension2: label } : { dimension2: dimension2 || 'none' }
  }
}

export const getGTMPromotionData = ({ promotion, index, creative } = {}) => {
  const {
    id,
    promoId,
    promotionId,
    name,
    displayName,
    promotionName,
    promotionTitle,
    promotionRebate
  } = (promotion || {})

  return {
    id: `${id || promoId || promotionId}${promotionRebate ? '-mailin' : ''}`,
    name: name || displayName || promotionName || promotionTitle,
    position: index + 1,
    creative
  }
}

export const getGTMPromotionAsProductData = promotion => {
  const {
    id,
    promoId,
    promotionId,
    name,
    displayName,
    promotionName,
    promotionTitle,
    promotionRebate,
    adjustmentAmount
  } = (promotion || {})

  return {
    name: name || displayName || promotionName || promotionTitle,
    id: `${id || promoId || promotionId}${promotionRebate ? '-mailin' : ''}`,
    price: `-${Math.abs(adjustmentAmount)}`,
    category: 'Promotion/Discount',
    variant: promotionRebate ? 'isMailIn' : 'notMailIn',
    quantity: 1
  }
}

export const getAddToCartProductData = (store, product) => {
  const state = store.getState()
  const { skuId, quantity, rearSkuId, rearSKUQuantity, label, oeFlag, evFlag } = product
  const rawProducts = [
    { quantity, product: getProductBySkuId(state, { skuId }) },
    { quantity: rearSKUQuantity, product: getProductBySkuId(state, { skuId: rearSkuId }) }
  ]

  const gBBDetails = getGBBDetails(state)
  const favoriteSkus = getFavoriteSkus(state)
  const { title: dimension2 } = gBBDetails[skuId] || {}

  return {
    rawProducts,
    label,
    dimension2,
    dimension28: favoriteSkus.includes(skuId),
    dimension30: oeFlag,
    dimension31: evFlag
  }
}

// GTM helpers
export const getGTMData = ({ saveForLaterItems = [], favoriteSkus = []}) => {
  try {
    return saveForLaterItems.map((product, index) => {
      const {
        tireLineName,
        skuId,
        listPrice,
        brandName,
        tireSize,
        availableAtCurrentStore,
        label
      } = product
      return {
        name: tireLineName,
        id: skuId,
        price: getPrice(listPrice),
        brand: brandName,
        category: 'Tires',
        variant: tireSize,
        list: SAVE_FOR_LATER_LIST_SOURCE.list,
        position: index + 1,
        dimension1: availableAtCurrentStore,
        dimension2: label || 'none',
        dimension28: favoriteSkus.includes(skuId)
      }
    })
  } catch (err) {
    console.log(err)
  }
}

export const getGTMSaveForLaterData = ({ product, favoriteSkus = []} = {}) => {
  const {
    tireLineName,
    skuId,
    tireSize,
    brandName,
    listPrice,
    price,
    averageSalePrice,
    salePrice, // TODO check whether we need to use "salePrice" or "listPrice"
    quantity
  } = (product || {})

  return {
    name: tireLineName,
    id: skuId,
    brand: brandName,
    price: tireSize ? getPrice(price || salePrice || listPrice) : getPrice(averageSalePrice),
    variant: tireSize,
    category: 'Tires',
    quantity,
    dimension28: favoriteSkus.includes(skuId)
  }
}
