export default [
  'BOTGarageVehicles',
  'GarageContentSlotMain',
  'OneColumnGaragePage',
  'VehicleMainContent'
]

/*
Appears on:
/my-account/vehicle,
/my-account/vehicles?loggedIn=true,
/my-account/vehicles
*/
