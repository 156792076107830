import React from 'react'
import PropTypes from 'prop-types'
import { A } from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'
import Button from 'components/common/Button/Button'

const PrintButton = ({ title, button, handler = () => window.print(), ...props }) =>
  button ?
    <Button { ...props } onClick={ handler } /> :
    <A { ...props } bolder prevent handler={ handler }>
      <Icon asset="print-blue" margin="-2px 8px 0 0" alt="" />
      { title }
    </A>

PrintButton.propTypes = {
  button: PropTypes.bool,
  handler: PropTypes.func,
  title: PropTypes.string
}

export default PrintButton
