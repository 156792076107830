import { compose, lifecycle, withState } from 'recompose'
import CountdownTimer from './CountdownTimer'
import { ONE_SECOND } from './constants'
import { dateToSeconds } from './helpers'

const withTimerState = withState('timer', 'setTimer', 0)
const withTimerDistanceState = withState('distance', 'setDistance', ({ endDate }) => dateToSeconds(endDate) - new Date().getTime())

const withInitialize = lifecycle({
  componentDidMount() {
    const { setDistance, setTimer } = this.props

    const timerId = setInterval(() => {
      setDistance(prevState => prevState - ONE_SECOND)
    }, ONE_SECOND)

    setTimer(timerId)
  },
  componentDidUpdate({ distance: prevDistance }) {
    const { timer, distance } = this.props

    if (prevDistance !== distance && distance === 0) {
      clearInterval(timer)
    }
  },
  componentWillUnmount() {
    const { timer } = this.props

    clearInterval(timer)
  }
})

export default compose(
  withTimerState,
  withTimerDistanceState,
  withInitialize
)(CountdownTimer)
