import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/common/Button/Button'

const AddVehicleButton = ({ className, children, onSubmit = () => {} }) =>
  <Button
    data-at-add-vehicle-button
    full
    important
    className={ className }
    onClick={ onSubmit }>
    { children }
  </Button>

AddVehicleButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSubmit: PropTypes.func
}

export default AddVehicleButton
