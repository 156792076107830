import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { RECORD_DELETE_MODAL } from '../../../../constants'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import RecordDeleteModal from './RecordDeleteModal'
import * as actions from '../../../../actions'
import { getUserVehicleId } from 'components/ServiceHistory/selectors'

const mapStateToProps = state => ({
  userVehicleId: getUserVehicleId(state)
})

const withHandleRecordDelete = withHandlers({
  handleRecordDelete: ({
    deleteServiceRecord,
    serviceId,
    userVehicleId,
    handleHide,
    setRecordData
  }) => () => {
    const payload = {
      userVehicleId,
      serviceId
    }
    deleteServiceRecord({ payload, setRecordData })
    handleHide()
  }
})

export default compose(
  registerReduxModal({ name: RECORD_DELETE_MODAL }),
  connect(mapStateToProps, { ...actions }),
  withHandleRecordDelete
)(RecordDeleteModal)

export { actions }
