import { compose, withProps, withStateHandlers, withHandlers } from 'recompose'

const withFocusHoverState = withStateHandlers({
  isInnerFocusedHovered: false,
  blockRef: null
}, {
  setFocusHoverStateBlockRef: () => ref => ({ blockRef: ref }),
  setFocusHoverState: () => value => ({
    isInnerFocusedHovered: value
  })
})

const withHandleFocusBlurHover = withHandlers({
  handleFocusMouseEnter: ({ setFocusHoverState }) => (e) => {
    setFocusHoverState(true)
  },
  handleBlur: ({ blockRef, setFocusHoverState }) => (e) => {
    setTimeout(() => {
      setFocusHoverState(blockRef.contains(document.activeElement))
    })
  },
  handleMouseLeave: ({ blockRef, setFocusHoverState }) => (e) => {
    setTimeout(() => {
      setFocusHoverState(!!blockRef.querySelector(':hover'))
    })
  }
})

const withPropHandlers = withProps(({ handleFocusMouseEnter, handleBlur, handleMouseLeave }) => ({
  focusBlurHoverHandlers: {
    onFocus: handleFocusMouseEnter,
    onBlur: handleBlur,
    onMouseEnter: handleFocusMouseEnter,
    onMouseLeave: handleMouseLeave
  }
}))

export default compose(
  withFocusHoverState,
  withHandleFocusBlurHover,
  withPropHandlers
)
