import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getFavoriteTotalCount } from 'components/MyFavorites/selectors'
import { MY_FAVORITES_PAGE } from 'constant'
import { loadFavoriteSkus } from './actions'

const mapStateToProps = state => ({
  favoritesTotalCount: getFavoriteTotalCount(state),
  favoritesUrl: MY_FAVORITES_PAGE
})

export default compose(
  connect(mapStateToProps, { loadFavoriteSkus })
)
