import Cookies from 'cookies-js'
import { IS_AUTHORIZED } from 'constant'

/* eslint-disable camelcase */
export default response => {
  if (process.env.BOT_IS_SERVER === 'true') {
    return response
  }
  const { headers = {}} = response

  Cookies.set(IS_AUTHORIZED, headers.isauthorized, { secure: true })

  return response
}
