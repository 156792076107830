import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Media from 'components/common/Media'
import ScrollDisable from 'components/common/ScrollDisable/ScrollDisable'
import { MODAL_DIALOG_ID } from 'constant'

const ESCAPE_CODE = 27

class ModalBox extends React.Component {
  state = {
    isShowing: this.props.isShowing
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscPress)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isShowing !== this.state.isShowing) {
      this.setState({
        isShowing: nextProps.isShowing
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscPress)
  }

  handleClose = () => {
    this.setState({
      isShowing: false
    })

    this.props.onClose && this.props.onClose()
  }

  handleEscPress = e => {
    if (e.keyCode === ESCAPE_CODE) {
      this.handleClose()
    }
  }

  render() {
    const {
      children,
      placeholders: {
        modalBoxContent
      } = {}
    } = this.props

    const content = React.Children.count(children) ?
      children :
      React.Children.map(modalBoxContent, content =>
        React.cloneElement(content, { onClose: this.handleClose }))

    return (
      <ScrollDisable>
        <ModalBoxWrapper>
          <ModalBoxContent role="dialog" aria-labelledby={ MODAL_DIALOG_ID } tabIndex="-1" aria-modal="true">
            { content }
            <CloseIcon
              data-at-modal-close
              asset="cross"
              alt="Close button"
              height={ 14 }
              width={ 14 }
              onClick={ this.handleClose } />
          </ModalBoxContent>
        </ModalBoxWrapper>
      </ScrollDisable>
    )
  }
}

ModalBox.propTypes = {
  children: PropTypes.node,
  isShowing: PropTypes.bool,
  onClose: PropTypes.func,
  placeholders: PropTypes.shape({
    popupContent: PropTypes.array
  })
}

export const type = 'ModalBox'

ModalBox.type = type
ModalBox.placeholders = ['modalBoxContent']

export default ModalBox

const ModalBoxWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 38, 43, 0.34);
`

const ModalBoxContent = styled.div`
  ${Media.mobile`
    height: 100%;
  `}
  ${Media.desktop`
    position: fixed;
    top: 100px;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  `}
`

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
  cursor: pointer;
  background: transparent;
`
