import { replace } from 'connected-react-router'

export const refresh = () => (dispatch, getState) => {
  const {
    router: {
      location: { pathname, search, hash }
    }
  } = getState()

  dispatch(replace({ pathname, search, hash }, { update: true }))
}
