import { createAction } from 'redux-actions'
import { isFunction } from 'helpers/utils'
import {
  SET_GLOBAL_MESSAGE_BANNER,
  CLOSE_GLOBAL_BANNER_MESSAGE,
  SET_QUICK_APPOINTMENT_STEPPER,
  SET_FULL_STORY,
  SET_PROMO_DETAILS_EXPERIENCE,
  SET_3_STEPPER_ENABLED,
  SET_YMME_EXPERIENCE,
  SET_STORE_LOCATOR_PAGE_EXPERIENCE
} from './constants'

// Third Party Redux Actions
export const setGlobalBannerMessage = createAction('THIRDPARTY/SET_GLOBAL_BANNER_MESSAGE')
export const closeGlobalBannerMessage = createAction('THIRDPARTY/CLOSE_GLOBAL_BANNER_MESSAGE')
export const setQuickAppointmentStepper = createAction('THIRDPARTY/SET_QUICK_APPOINTMENT_STEPPER')
export const setPromoDetailsExperience = createAction('THIRDPARTY/SET_PROMO_DETAILS_EXPERIENCE')
export const set3StepperEnabled = createAction('THIRDPARTY/SET_3_STEPPER_ENABLED')
export const setYmmeExperience = createAction('THIRDPARTY/SET_YMME_EXPERIENCE')
export const setStoreLocatorPageExperience = createAction('THIRDPARTY/SET_STORE_LOCATOR_PAGE_EXPERIENCE')

// FullStory Actions
export const setFullStory = createAction('FULLSTORY/SET_FULL_STORY')

// Kenshoo Actions
export const setKenshooEventData = createAction('KENSHOO/SET_KENSHOO_EVENT_DATA')
export const kenshooQuickAddService = createAction('KENSHOO/QUICK_ADD_SERVICE')

// BazaarVoice Actions
export const setBazaar = createAction('BAZAARVOICE/SET_BAZAAR')

const actionMapping = {
  [SET_GLOBAL_MESSAGE_BANNER]: setGlobalBannerMessage,
  [CLOSE_GLOBAL_BANNER_MESSAGE]: closeGlobalBannerMessage,
  [SET_QUICK_APPOINTMENT_STEPPER]: setQuickAppointmentStepper,
  [SET_FULL_STORY]: setFullStory,
  [SET_PROMO_DETAILS_EXPERIENCE]: setPromoDetailsExperience,
  [SET_3_STEPPER_ENABLED]: set3StepperEnabled,
  [SET_YMME_EXPERIENCE]: setYmmeExperience,
  [SET_STORE_LOCATOR_PAGE_EXPERIENCE]: setStoreLocatorPageExperience
}

export const handleUpdate3rdPartyDataEvent = ({ action, value = '' }) => dispatch => {
  if (!!action && isFunction(actionMapping[action])) {
    dispatch(actionMapping[action]({ value }))
  }
}
