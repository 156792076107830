import { getPropertyValuesString } from '../../helpers'

const defaultTo = (value, defaultValue = '') => value || defaultValue

export const getFullName = (data, placeholder) =>
  getPropertyValuesString(data, ['firstName', 'lastName']) || placeholder

export const getFullAddress = ({ address1, address2, city, state, zipCode }, placeholder) =>
  address1 ? [`${address1} ${defaultTo(address2)}`, `${city}, ${state} ${zipCode}`] : placeholder

export const getBirthday = (data, placeholder) =>
  getPropertyValuesString(data, ['birthMonth', 'birthDay']) || placeholder

export const getPersonalInformationFormInitialValues = ({
  firstName,
  lastName,
  address1,
  address2,
  primaryPhone,
  alternatePhone,
  birthDay,
  birthMonth,
  city,
  state,
  zipCode
}) => ({
  firstName: defaultTo(firstName),
  lastName: defaultTo(lastName),
  address1: defaultTo(address1),
  address2: defaultTo(address2),
  primaryPhone: defaultTo(primaryPhone),
  alternatePhone: defaultTo(alternatePhone),
  birthDay: defaultTo(birthDay),
  birthMonth: defaultTo(birthMonth),
  city: defaultTo(city),
  state: defaultTo(state),
  zipCode: defaultTo(zipCode)
})

export const checkAddressVerificationResult = (results, successCode, errorPrefix, errorMessage) => {
  const resultArray = results.split(',')

  return !resultArray.includes(successCode) ||
    resultArray.some(errorCode => errorCode.startsWith(errorPrefix)) ? errorMessage : null
}
