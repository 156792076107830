import { compose, withProps } from 'recompose'
import withInnerFocusBlurHover from 'components/common/hoc/withInnerFocusBlurHover'

const withFilteredHandlers = withProps(({
  focusBlurHoverHandlers: {
    onBlur
  } = {}
}) => ({
  focusBlurHoverHandlers: {
    onBlur
  }
}))

export default compose(
  withInnerFocusBlurHover,
  withFilteredHandlers
)
