import { lifecycle } from 'recompose'
import { getActiveDropdown } from './helpers'

export default ({ dataName, first }) =>
  lifecycle({
    componentDidUpdate(prevProps) {
      const { multiColumnMenu } = this.props
      const activeDropdown = getActiveDropdown(this.props[dataName])
      const prevActiveDropdown = getActiveDropdown(prevProps[dataName])

      if (activeDropdown && activeDropdown !== first && activeDropdown !== prevActiveDropdown) {
        multiColumnMenu ? this.props[activeDropdown].props.onMenuOpen() : this.props[activeDropdown].focus()
      }
    }
  })
