export default [
  'AccountMenuNavigartion',
  'BOTAccountPersonalInformation',
  'Personal Information'
]

/*
Appears on:
/my-account/account-details,
/my-account/my-appointments?loggedin=true,
/my-account/my-appointments,
/my-account/service-history?access=registered,
/my-account/service-history?access=signup,
/my-account/service-history?vehicle=added,
/my-account/vehicles?loggedIn=true,
/my-account/vehicles
*/
