import { handleActions, combineActions } from 'redux-actions'
import {
  updatePersonalInformationRequest,
  updatePersonalInformationSuccess,
  updatePersonalInformationFailure,
  verifyAddressRequest,
  verifyAddressSuccess,
  verifyAddressFailure
} from './actions'

const initialState = {
  isUpdatingInProgress: false,
  isVerifyingInProgress: false
}

const personalInformation = handleActions({
  [updatePersonalInformationRequest]: state => ({
    ...state,
    isUpdatingInProgress: true
  }),
  [combineActions(updatePersonalInformationSuccess, updatePersonalInformationFailure)](state) {
    return { ...state, isUpdatingInProgress: false }
  },
  [verifyAddressRequest]: state => ({
    ...state,
    isVerifyingInProgress: true
  }),
  [combineActions(verifyAddressSuccess, verifyAddressFailure)](state) {
    return { ...state, isVerifyingInProgress: false }
  }
}, initialState)

export default personalInformation
