import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withProps } from 'recompose'
import { getOnClickAndOnKeyPressDownEvents } from 'helpers/getOnClickAndOnKeyPressDownEvents'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Image from 'components/common/Image'
import Button from 'components/common/Button/Button'

const Promotion = ({
  promotionData: {
    promotionImage,
    promotionLinkUrl,
    promotionName,
    promotionTitle,
    promotionId = '',
    promotionShortDescription
  },
  onClick = () => {},
  srcAttrs
}) =>
  <PromoTileWrapper className="promo-tile_wrapper">
    <PromoTile data-at-deals-promotion data-same-dimension data-at-deal={ promotionId }>
      <PromotionHeader>
        {
          promotionImage &&
          <PromotionImage data-at-pd { ...srcAttrs } />
        }
        <PromoTileContent>
          <PromotionTitle>
            { promotionTitle }
          </PromotionTitle>
          <PromotionText data-at-details>
            { promotionShortDescription }
          </PromotionText>
        </PromoTileContent>
      </PromotionHeader>
      <PromoTileFooter>
        <StyledLink
          className="generic-button"
          block
          narrow
          { ...getOnClickAndOnKeyPressDownEvents(onClick) }>
          Get details
        </StyledLink>
      </PromoTileFooter>
    </PromoTile>
  </PromoTileWrapper>

Promotion.propTypes = {
  onClick: PropTypes.func,
  promotionData: PropTypes.shape({
    promotionImage: PropTypes.string,
    promotionLinkUrl: PropTypes.string,
    promotionTitle: PropTypes.string,
    promotionName: PropTypes.string,
    promotionId: PropTypes.string,
    promotionShortDescription: PropTypes.string
  }),
  regionName: PropTypes.string,
  srcAttrs: PropTypes.shape()
}

export default withProps(({ storePromotions, promotionData: { promotionImage }}) => ({
  srcAttrs: storePromotions ? {
    src: promotionImage,
    mobileSrc: promotionImage
  } : {
    src: promotionImage
  }
}))(Promotion)

const SizeContainer = styled.div`
  width: 100%;
`

const PromoTileWrapper = styled.div`
  display: inline-block;
  background: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.light_grey};
  overflow: hidden;
  margin: 16px;
`

const PromoTile = styled(SizeContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  padding-bottom: 20px;
`

const PromotionImage = styled(Image)`
  height: auto;
  max-height: 155px;
  width: 100%;
  min-height: 1px;
  border-bottom: 1px solid ${Theme.colors.light_grey};
`

const PromotionTitle = styled.h2`
  overflow: hidden;
  text-transform: uppercase;
  ${Mixin.font.GothamBold(19, 600, 22)}
  line-height: 1.2;
  transform: scale(1, 1.3);
`

const PromotionHeader = styled.div`
  display: block;
  width: 100%;
  text-align: left;
`

const PromoTileContent = styled.div`
  display: block;
  padding: 15px;
`

const PromoTileFooter = styled.div`
  display: block;
  position: absolute;
  bottom: 20px;
  right: 10px;
`

const StyledLink = styled(Button)`
  font-size: 13px;
  font-weight: 800;
  line-height: 16px;
  padding: 7px 23px 5px !important;
  height: 30px;
  text-decoration: none;
  color: ${Theme.colors.white};

  &:hover {
    text-decoration: none;
    border: none;
  }
`

const PromotionText = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  overflow: hidden;
  ${Mixin.font.GothamBook(16, 400, 20)};
  line-height: 1.2;
`
