import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'
import Link from 'components/common/Link/Link'
import Theme from 'components/common/Theme/Theme'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'


const MenuItemMobile = ({
  title,
  description,
  displayAsButton,
  link: { path } = {},
  onClick = () => {}
}) => {
  const content =
    <Fragment>
      <Title data-at-title>{ title }</Title>
      <Hint data-at-description>{ description }</Hint>
    </Fragment>
  return displayAsButton
    ? (
      <WrapperButton
        full
        onClick={ onClick }
        { ...dynamicDataAttribute('header-make-appointment-tile-mobile', toOneString(title)) }>
        { content }
      </WrapperButton>
    )
    : (
      <WrapperLink
        to={ path }
        onClick={ onClick }
        { ...dynamicDataAttribute('header-make-appointment-tile-mobile', toOneString(title)) }>
        { content }
      </WrapperLink>
    )
}

MenuItemMobile.propTypes = {
  description: PropTypes.string,
  displayAsButton: PropTypes.bool,
  link: PropTypes.shape({
    path: PropTypes.string
  }),
  onClick: PropTypes.func,
  title: PropTypes.string
}

export default MenuItemMobile

const WrapperButton = styled(Button)`
  padding: 20px 20px 20px 1px;
  margin-left: 0;
  text-transform: none;
  border: none;
  border-bottom: 1px solid ${Theme.colors.light_grey};
  background-color: transparent;
  text-align: initial;
  ${Mixin.font.GothamBook(16)}
  color: ${Theme.colors.white};

  & + & {
    margin-left: 0;
  }

  :hover {
    background: ${Theme.colors.light_grey};
  }
`

const WrapperLink = styled(Link)`
  display: block;
  padding: 20px 20px 20px 1px;
  border-bottom: 1px solid ${Theme.colors.light_grey};
  text-transform: none;
  ${Mixin.font.GothamBook(16)}
  color: ${Theme.colors.white};

  :hover {
    text-decoration: none;
  }
`

const Hint = styled.div`
  padding-top: 6px;
  ${Mixin.font.GothamBookItalic(12)}
  color: ${Theme.colors.white};
`

const Title = styled.div`
  ${Mixin.font.GothamBook(16)}
  color: ${Theme.colors.white};
`
