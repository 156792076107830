// OE/EV Flag constants
export const OE_TOOLTIP_MESSAGE = 'These are the Original Equipment (OE) tires your vehicle came equipped with from the factory. Choosing OE tires is the best way to get the specific performance characteristics the manufacturer intended. All other tires are considered replacement tires for your make and model.'
export const OE_FLAG_TYPE = 'OE'
export const OE_FLAG_NAME = 'Came With Vehicle'

export const EVS_FLAG_TYPE = 'EVS'
export const EVS_FLAG_ICON_NAME = 'EV'
export const EVS_FLAG_NAME = 'Electric Vehicle Specified'
export const EVS_TOOLTIP_MESSAGE = 'Original Equipment on Electric Vehicle and/or designed by manufacturer specifically for Electric Vehicle usage'

export const EVC_FLAG_TYPE = 'EVC'
export const EVC_FLAG_ICON_NAME = 'EV-C'
export const EVC_FLAG_NAME = 'Electric Vehicle Compatible'
export const EVC_TOOLTIP_MESSAGE = 'Tire specifications meet defined criteria to be used on an Electric Vehicles'

export const EV_FLAG_SPEC = 'EV Flag'
