import { STAGGERED } from './constants'

export const getMultipleSizesInformation = make =>
  `${make} equips your vehicle with more than one possible size during production.
  Please select the size installed on your vehicle from the choices below.`

export const getStaggeredInformation = () =>
  'Your vehicle manufacturer’s recommended tire specs include different front and rear tire sizes (i.e. staggered fitment). Learn about '

export const transformTireSizes = (tireSizes = []) =>
  tireSizes.reduce((acc, tireSize) => ({
    ...acc,
    ...tireSize.type === STAGGERED ?
      { [STAGGERED]: (acc[STAGGERED] || []).concat(tireSize) } :
      { [tireSize.diameter]: (acc[tireSize.diameter] || []).concat(tireSize) }
  }), {})

export const getVehicleName = ({
  makeDesc = '',
  yearDesc = '',
  modelDesc = '',
  subModelDesc = ''
} = {}) => `${yearDesc} ${makeDesc} ${modelDesc} ${subModelDesc}`

export const hasMultipleTireSizes = tireSizes => {
  const diameters = Object.keys(tireSizes)

  return diameters.length > 1 || diameters.some(diameter => tireSizes[diameter].length > 1)
}

export const hasStaggeredFitment = tireSizes =>
  tireSizes[STAGGERED]
