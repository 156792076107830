import Cookies from 'cookies-js'
import { compose, lifecycle, withState } from 'recompose'
import { SESSION_EXPIRED, SESSION_EXPIRED_MESSAGE } from 'constant'
import SessionTimeOutMessage from './SessionTimeOutMessage'
import { dispatchPageHeaderChanged } from 'helpers/custom-events'

const withSessionTimeoutState = withState('sessionTimeoutMessage', 'setSessionTimeoutMessage', null)

const withLifecycle = lifecycle({
  componentDidMount() {
    const { setSessionTimeoutMessage } = this.props
    const sessionDidTimeout = Cookies.get(SESSION_EXPIRED) === 'true'

    if (sessionDidTimeout) {
      setSessionTimeoutMessage(SESSION_EXPIRED_MESSAGE)
      setTimeout(() => {
        Cookies.expire(SESSION_EXPIRED)
        setSessionTimeoutMessage(null)
        dispatchPageHeaderChanged()
      }, 15000)
    }
  }
})

export default compose(
  withSessionTimeoutState,
  withLifecycle
)(SessionTimeOutMessage)
