import { connect } from 'react-redux'
import { compose, withProps, lifecycle } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { TIRE_SIZES_NOT_FOUND_MODAL } from 'src/constant'
import TireSizesNotFoundModal from './TireSizesNotFoundModal'
import { YMME_NAME, HEADER_SOURCE, TAB_TIRE_SIZE_NAME } from 'constant'
import { showNewShopTiresModal, hideNewShopTiresModal } from '../../../NewShopTiresModal/actions'

const withInit = lifecycle({
  componentDidMount() {
    const { hideNewShopTiresModal } = this.props
    hideNewShopTiresModal()
  }
})

const withTireSizesNotFoundModalProps = withProps(({
  showNewShopTiresModal
}) => ({
  addNewVehicle:
    () => showNewShopTiresModal({
      tabName: TAB_TIRE_SIZE_NAME,
      ymmeName: YMME_NAME,
      source: HEADER_SOURCE,
      withShowConfirmTireSizeModal: true
    })
}))

export default compose(
  registerReduxModal({ name: TIRE_SIZES_NOT_FOUND_MODAL }),
  connect(null, { showNewShopTiresModal, hideNewShopTiresModal }),
  withTireSizesNotFoundModalProps,
  withInit
)(TireSizesNotFoundModal)

export {
  TIRE_SIZES_NOT_FOUND_MODAL
}
