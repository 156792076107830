import { reset } from 'redux-form'
import { show, hide } from 'redux-modal'
import { CONFIRM_TIRE_SIZE_MODAL, ADD_VEHICLE_MODAL } from 'constant'
import { YMME_NAME, LICENSE_PLATE_NAME } from './constants'
import { withYmmeInners } from 'components/common/hoc/withYmme'

const {
  actions: {
    handleYmmeReset
  } = {}
} = withYmmeInners

export const showConfirmTireSizeModal = props => show(CONFIRM_TIRE_SIZE_MODAL, props)

export const showAddVehicleModal = props => show(ADD_VEHICLE_MODAL, props)
export const hideAddVehicleModal = () => hide(ADD_VEHICLE_MODAL)

export const resetYmme = () => handleYmmeReset({ ymmeName: YMME_NAME })
export const resetLicencePlateForm = () => reset(LICENSE_PLATE_NAME)
