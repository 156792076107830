import { branch, compose, mapProps } from 'recompose'
import CustomBack from './Back'
import withBackNavigation from 'components/common/hoc/withBackNavigation'

const withSetHandlerNavigation = mapProps(({ handler, handleNavigationBack, ...props }) => ({
  ...props,
  handleNavigationBack,
  handler: handleNavigationBack
}))

const Back = branch(
  ({ defaultHistory }) => defaultHistory,
  compose(
    withBackNavigation,
    withSetHandlerNavigation
  )
)(CustomBack)

export default Back
