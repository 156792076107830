import React from 'react'
import Modal from 'components/common/Modal'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import HTMLContainer from 'components/common/HTMLContainer/HTMLContainer'

const TPPModal = ({
  data: {
    modalBoxContent = []
  } = {},
  handleHide
}) =>
  <CustomModal title="Tire Protection Plan" closeHandler={ handleHide } >
    {
      modalBoxContent.map(({ content }, index) =>
        <HTMLContainer key={ index } content={ content } />
      )
    }
  </CustomModal>

TPPModal.propTypes = {
  data: PropTypes.shape(),
  handleHide: PropTypes.func
}

export default TPPModal

const CustomModal = styled(Modal)`
  text-align: center;
`
