import { Validator } from 'services/validator'
import { WIDTH, RATIO, DIAMETER, WIDTH_EMPTY, RATIO_EMPTY, DIAMATER_EMPTY } from './constants'

export default Validator.combineValidators({
  [WIDTH]: {
    validators: Validator.required(),
    errorMessage: WIDTH_EMPTY
  },
  [RATIO]: {
    validators: Validator.required(),
    errorMessage: RATIO_EMPTY
  },
  [DIAMETER]: {
    validators: Validator.required(),
    errorMessage: DIAMATER_EMPTY
  }
})
