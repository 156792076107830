import { compose, withHandlers, withProps, withState } from 'recompose'
import { reduxForm } from 'redux-form'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import EditMileageModal from './EditMileageModal'
import validate from './validate'
import { handleSubmit } from './actions'
import createViewModel from './viewModel'
import { createInitialValues } from 'components/MyGarage2.0/components/VehiclePersonalizationModal/components/VehicleDetails/helpers'
import { EDIT_MILEAGE_MODAL, EDIT_MILEAGE_FORM } from '../../constants'

const withShowMileageCompareNotificationState = withState('showMileageCompareNotification', 'setShowMileageCompareNotification', false)

const withEditMileageProps = withProps(({
  mileage
}) => ({
  model: createViewModel(),
  initialValues: createInitialValues({ mileage })
}))

const withMileageCompareHandler = withHandlers({
  onChange: ({ displayCurrentMileage, setShowMileageCompareNotification }) => (event) => {
    if (event.mileage < displayCurrentMileage) {
      setShowMileageCompareNotification(true)
    }

    if (event.mileage >= displayCurrentMileage) {
      setShowMileageCompareNotification(false)
    }
  }
})

export default compose(
  registerReduxModal({ name: EDIT_MILEAGE_MODAL }),
  withShowMileageCompareNotificationState,
  withEditMileageProps,
  withMileageCompareHandler,
  reduxForm({ validate, form: EDIT_MILEAGE_FORM, onSubmit: handleSubmit })
)(EditMileageModal)
