import React from 'react'
import PropTypes from 'prop-types'
import DropdownOption from '../common/DropdownOption'
import CommonTextInput from '../CommonTextInput'
import * as UI from '../SharedStyles'

const AutocompleteInput = ({
  suggestions,
  handleEnterText,
  formatSuggestion,
  handleOptionSelect,
  onBlur,
  ...props
}) =>
  <CommonTextInput
    { ...props }
    autoComplete="off"
    onChange={ handleEnterText }>
    {
      props.active && suggestions.length > 0 &&
      <UI.Options>
        {
          suggestions.map((value, index) =>
            <DropdownOption
              key={ index }
              value={ value }
              displayName={ formatSuggestion(value) }
              onSelect={ handleOptionSelect } />
          )
        }
      </UI.Options>
    }
  </CommonTextInput>

export default AutocompleteInput

AutocompleteInput.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  formatSuggestion: PropTypes.func,
  handleEnterText: PropTypes.func,
  handleOptionSelect: PropTypes.func,
  input: PropTypes.shape(),
  meta: PropTypes.shape({
    active: PropTypes.bool
  }),
  onBlur: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.any)
}

AutocompleteInput.defaultProps = {
  formatSuggestion: f => f,
  onChooseSuggestion: () => {}
}

