import React, { Fragment } from 'react'
import { components } from 'react-select'
import PropTypes from 'prop-types'
import { ReactSelectInput } from '../../components'

const ReactSelectValueContainer = ({
  children,
  selectProps,
  getStyles,
  theme,
  cx,
  childrenWithoutInput,
  innerRef,
  onFocus,
  onBlur,
  onChange,
  isSearchable,
  ...props
}) =>
  <components.ValueContainer getStyles={ getStyles } theme={ theme } cx={ cx } { ...props }>
    {
      <Fragment>
        { children }
        {
          !isSearchable && <ReactSelectInput
            form={ selectProps.form }
            name={ selectProps.name }
            selectProps={ selectProps }
            getStyles={ getStyles }
            theme={ theme }
            cx={ cx }
            readOnly
            innerRef={ innerRef }
            onFocus={ onFocus }
            onBlur={ onBlur }
            onChange={ onChange } />
        }
      </Fragment>
    }
  </components.ValueContainer>

export default ReactSelectValueContainer

ReactSelectValueContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  childrenWithoutInput: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  cx: PropTypes.func,
  getStyles: PropTypes.func,
  innerRef: PropTypes.func,
  isSearchable: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  selectProps: PropTypes.shape(),
  theme: PropTypes.shape()
}
