import { compose } from 'redux'
import { connect } from 'react-redux'
import { withProps } from 'recompose'
import withType from 'components/common/hoc/withType'
import withShowConfirmTireSizeModalPreconfigured from 'components/common/hoc/withShowConfirmTireSizeModalPreconfigured'
import { generateCarouselSettingsDesktop, generateCarouselSettingsMobile } from './helpers'
import * as actions from './actions'
import GarageVehicles from './GarageVehicles'
import { APPROXIMATE_TILE_WIDTH } from './constants'

const withCarouselSettings = withProps(({ UserVehicleDetails: { userVehicle = []} = {}}) => ({
  carouselSettingsDesktop: generateCarouselSettingsDesktop(userVehicle.length),
  carouselSettingsMobile: generateCarouselSettingsMobile(userVehicle.length),
  slideTrackWidth: userVehicle.length * APPROXIMATE_TILE_WIDTH
}))

export default compose(
  withType({ type: 'GarageVehicles' }),
  withShowConfirmTireSizeModalPreconfigured,
  connect(null, actions),
  withCarouselSettings
)(GarageVehicles)
