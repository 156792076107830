import axios from 'axios'

const PARTNER_ID = '116463443'
const GET_EXPRESS_ADDRESS_URL = 'https://expressentry.melissadata.net/web/ExpressAddress'
const VERIFY_ADDRESS_URL = 'https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify'
const MAX_RECORDS = 300
const FORMAT = 'json'
const DEFAULT_VERIFY_ADDRESS_ACT = 'Check'
const DEFAULT_VERIFY_ADDRESS_OPT = 'AdvancedAddressCorrection:on'
const PARAMS = {
  id: PARTNER_ID,
  format: FORMAT
}

export const getExpressAddress = query => {
  const url = GET_EXPRESS_ADDRESS_URL
  const options = {
    params: {
      ...PARAMS,
      line1: query,
      maxrecords: MAX_RECORDS
    }
  }

  return axios
    .get(url, options)
    .catch(err => {
      // TODO add winston logger here to log all errors
      // TODO add service for managing errors
      throw new Error(err)
    })
}

export const verifyAddress = ({ address1: a1, city, state, postalCode: postal }) => {
  const url = VERIFY_ADDRESS_URL
  const options = {
    params: {
      ...PARAMS,
      act: DEFAULT_VERIFY_ADDRESS_ACT,
      opt: DEFAULT_VERIFY_ADDRESS_OPT,
      a1,
      city,
      state,
      postal
    }
  }

  return axios
    .get(url, options)
    .catch(err => {
      // TODO add winston logger here to log all errors
      // TODO add service for managing errors
      throw new Error(err)
    })
}
