import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Modal from 'components/common/Modal'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Button from 'components/common/Button/Button'
import Stepper from 'components/common/Stepper'
import UpdatingBlock from 'components/common/UpdatingBlock/UpdatingBlock'

const MultiColumnModal = ({
  headerSteps,
  currentStep,
  currentStepName,
  currentStepOptions,
  currentStepSelectedValue,
  isLoading,
  setStep,
  handleStepForward,
  handleHide
}) =>
  <Modal closeHandler={ handleHide } title={ `${currentStep + 1}. ${currentStepName}` }>
    <Stepper
      noLastStep
      currentStepIndex={ currentStep }
      nextStepHandler={ setStep }
      steps={ headerSteps } />
    <StepContent>
      <Spinner condition={ isLoading }>
        <OptionListWrapper>
          { currentStepOptions.map(({ label, value }) =>
            <OptionWrapper key={ value }>
              <Option
                full
                selectedOption={ currentStepSelectedValue === value }
                onClick={ () => handleStepForward(value) }>
                { label }
              </Option>
            </OptionWrapper>
          )}
        </OptionListWrapper>
      </Spinner>
    </StepContent>
  </Modal>

export default MultiColumnModal

MultiColumnModal.propTypes = {
  currentStep: PropTypes.number,
  currentStepName: PropTypes.string,
  currentStepOptions: PropTypes.arrayOf(PropTypes.shape()),
  currentStepSelectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleHide: PropTypes.func,
  handleStepForward: PropTypes.func,
  headerSteps: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  setStep: PropTypes.func
}

const StepContent = styled.div`
  border-top: 1px solid ${Theme.colors.light_grey};
  margin-top: 10px;
  padding: 6px;
`

const Spinner = styled(UpdatingBlock)`
  height: 450px;
`

const OptionListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const OptionWrapper = styled.div`
  width: 50%;
  padding: 12px 8px;
`

const Option = styled(Button)`
  ${Mixin.font.GothamBook(14, 400, 15)}
  height: 45px;
  padding: 10px 7px;
  background-color: transparent;
  text-transform: uppercase;
  color: ${Theme.colors.dark_grey};
  ${props => props.selectedOption && css`
    background: ${Theme.colors.red};
    color: ${Theme.colors.white};
  `}
`
