import { css } from 'styled-components'
import {
  MOBILE_EDGE,
  DESKTOP_NARROW_EDGE,
  TABLET_SMALL_EDGE,
  MOBILE_NARROW_EDGE,
  TABLET_NARROW_EDGE,
  MEDIUM_MOBILE
} from './constants'

export const desktop = (...args) => css`
  @media (min-width: ${MOBILE_EDGE + 1}px) {
    ${css(...args)}
  }
`
export const tablet = (...args) => css`
  @media (min-width: ${MOBILE_EDGE + 1}px) and (max-width: ${DESKTOP_NARROW_EDGE}px) {
    ${css(...args)}
  }
`
export const tabletSmall = (...args) => css`
  @media (min-width: ${MOBILE_EDGE + 1}px) and (max-width: ${TABLET_SMALL_EDGE}px) {
    ${css(...args)}
  }
`
export const tabletNarrow = (...args) => css`
  @media (max-width: ${TABLET_NARROW_EDGE - 1}px) {
    ${css(...args)}
  }
`
export const tabletMedium = (...args) => css`
  @media (min-width: ${TABLET_NARROW_EDGE}px) and (max-width: ${MOBILE_EDGE}px) {
    ${css(...args)}
  }
`
export const mediumLargeDevices = (...args) => css`
  @media (min-width: ${TABLET_NARROW_EDGE}px) {
    ${css(...args)}
  }
`
export const mobile = (...args) => css`
  @media (max-width: ${MOBILE_EDGE}px) {
    ${css(...args)}
  }
`
export const mobileNarrow = (...args) => css`
  @media (max-width: ${MOBILE_NARROW_EDGE}px) {
    ${css(...args)}
  }
`
export const mediumMobile = (...args) => css`
  @media (max-width: ${MEDIUM_MOBILE - 1}px) {
    ${css(...args)}
  }
`
export const print = (...args) => css`
  @media print {
    ${css(...args)}
  }
`
export const IE9 = (...args) => css`
  /* stylelint-disable unit-no-unknown, number-leading-zero  */
  @media screen and (min-width: 0\\0) and (min-resolution: .001dpcm) {
    ${css(...args)}
  }
  /* stylelint-enable  */
`
export const IE = (...args) => css`
  /* stylelint-disable unit-no-unknown, number-leading-zero  */
  @media screen and (min-width: 0\\0), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    ${css(...args)}
  }
  /* stylelint-enable  */
`
