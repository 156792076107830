import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/common/Link/Link'
import Button from 'components/common/Button/Button'
import styled from 'styled-components'

const AlreadyInQuoteSubComponent = ({ quotePageURL, closeHandler }) =>
  <ButtonWrapper>
    <Link button important to={ quotePageURL } onClick={ closeHandler }>
      View Quote
    </Link>
    <Button bolder link onClick={ closeHandler }>
      Continue Shopping
    </Button>
  </ButtonWrapper>

export default AlreadyInQuoteSubComponent

AlreadyInQuoteSubComponent.propTypes = {
  closeHandler: PropTypes.func,
  quotePageURL: PropTypes.string
}

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 40px;
`
