import axios from 'axios'

const cancelRequest = {}
class RequestBuilder {
  constructor(url, basePath) {
    // properties
    this.url = `https://${basePath}${url}`
    this.method = 'get'
    this.queryParams = []
    this.data = {}
    this.timeout = 100000
    this.responseType = 'json'
    this.cancelKey = `${url}`
    this.successHandler = () => {}
    this.errorHandler = () => {}
    this.requestHeaders = {}
  }

  withMethod(method) {
    this.method = method
    return this
  }

  withQueryParam(key, value) {
    this.queryParams.push([key, value])
    return this
  }

  // only send query params when value is not null
  withOptionalQueryParam(key, value) {
    if (key && value) this.queryParams.push([key, value])
    return this
  }

  // allow either a single object, or an array of objects
  // objects in format: { key: '', value: '' }
  withRequestHeader(headers) {
    if (!headers || typeof headers !== 'object') return this

    // make sure we're working with an array
    const headersArray = (headers instanceof Array) ? headers : [headers]

    // for each array value
    headersArray.forEach((header) => {
      const { key, value } = header
      if (key && value) {
        this.requestHeaders[key] = value
      }
    })

    return this
  }

  withData(data) {
    this.data = data
    return this
  }

  withSuccessHandler(callback) {
    this.successHandler = callback
    return this
  }

  withErrorHandler(callback) {
    this.errorHandler = callback
    return this
  }

  getAuthHeaders() {
    /* need to add token and other stuff once authentication is enabled
    authHeaders = {
      Authorization: `bearer }`
    } */
    return this
  }

  getUrlParams() {
    return this.queryParams
      .map(queryParam => queryParam.join('=')).join('&')
  }

  getRequestHeaders() {
    return this.requestHeaders
  }

  clearRequestHeaders() {
    this.requestHeaders = {}
  }

  async execute() {
    const CancelToken = axios.CancelToken
    const urlParams = this.getUrlParams()
    const url = `${this.url}${urlParams ? `?${urlParams}` : ''}`

    // get request headers
    const headers = this.getRequestHeaders()

    // clear request headers
    this.clearRequestHeaders()

    const request = {
      url,
      headers,
      timeout: this.timeout,
      method: this.method,
      data: this.data,
      responseType: this.responseType,
      cancelToken: new CancelToken((c) => {
        cancelRequest[`${this.cancelKey}`] = c
      })
    }
    return axios(request).then(this.successHandler).catch(this.errorHandler)
  }
}

export {
  cancelRequest
}

export default RequestBuilder
