import React from 'react'
import { compose, withProps, withHandlers, withState, lifecycle } from 'recompose'
import ReactAutosuggestInput from './ReactAutosuggestInput'
import CommonTextInput from '../CommonTextInput'
import DropdownOption from '../common/DropdownOption'

const withInputValue = withState('inputValue', 'setInputValue', '')

const withAutocompleteHandlers = withHandlers({
  handleSuggestionsUpdate: ({ onFetchSuggestions }) => ({ value }) => {
    onFetchSuggestions(value)
  },

  handleChange: ({ onChange, setInputValue }) => (event, { newValue }) => {
    setInputValue(newValue)
    onChange(newValue)
  },
  handleSuggestionSelect: ({ onChooseSuggestion }) => (event, { suggestion }) => {
    event.preventDefault()

    onChooseSuggestion(suggestion)
  }
})

const withAutocompleteRenders = withHandlers({
  renderSuggestion: ({ formatSuggestion }) => (suggestion, { isHighlighted }) =>
    <DropdownOption
      value={ suggestion }
      selected={ isHighlighted }
      displayName={ formatSuggestion(suggestion) } />,

  renderInputComponent:
    ({
      formatSuggestion,
      getSuggestionValue,
      onChooseSuggestion,
      onFetchSuggestions,
      onSuggestionsClearRequested,
      inputValue,
      setInputValue,
      handleSuggestionsUpdate,
      handleChange,
      handleSuggestionSelect,
      inputProps,
      ...props
    }) => ({ ref, ...inputProps }) =>
      <CommonTextInput { ...props } { ...inputProps } />
})

const withLifecycle = lifecycle({
  componentDidUpdate(prevProps) {
    const { value, setInputValue } = this.props

    if (value !== prevProps.value && value === '') {
      setInputValue(value)
    }
  }
})

const withInputProps = withProps(({ inputValue, placeholder, handleChange, onFocus, onBlur, type, value }) => ({
  inputProps: {
    type,
    value: inputValue || value,
    placeholder,
    onFocus,
    onChange: handleChange,
    onBlur: () => onBlur() // IT'S A REDUX-FORM FEATURE :). See https://redux-form.com/7.4.2/examples/react-widgets/
  }
}))

export default compose(
  withInputValue,
  withLifecycle,
  withAutocompleteHandlers,
  withInputProps,
  withAutocompleteRenders
)(ReactAutosuggestInput)
