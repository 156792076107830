import { compose } from 'redux'
import { withProps } from 'recompose'
import toggle from 'components/common/hoc/toggle'
import YmmePlate from './YmmePlate'

const withTireSizesNotFoundModalProps = withProps(({ resetYmme, changeActiveTab, shopByTiresIndex }) => ({
  tireSizesNotFoundModalProps: {
    onContinueClick: () => {
      changeActiveTab(shopByTiresIndex)
      resetYmme()
    },
    onCancelClick: resetYmme
  },
  isModal: true
}))

export default compose(
  toggle,
  withTireSizesNotFoundModalProps
)(YmmePlate)
