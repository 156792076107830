import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mixin from 'components/common/Mixin'

const BOTGlobalSearchOptions = ({ placeholders: { content }}) =>
  <Wrapper>
    { content }
  </Wrapper>

export default BOTGlobalSearchOptions

BOTGlobalSearchOptions.propTypes = {
  placeholders: PropTypes.shape({
    content: PropTypes.arrayOf()
  })
}

export const Wrapper = styled.div`
  ${Mixin.responsive('margin-bottom', '45px', '54px')}
`
