import { withState, withHandlers, lifecycle, compose } from 'recompose'
import debounce from 'lodash/debounce'
import { ACTIVATE_MARCHEX_SCRIPT, DEBOUNCE_INTERVAL } from 'constant'

const withMarchexState = withState('marchex', 'setMarchex', true)

const withTriggerMarchexReinject = withHandlers({
  triggerMarchexReinject: ({ setMarchex }) => () => {
    const marchexScriptElem = document.querySelector('script[src="//rw.marchex.io/2/Ch4NmFdxVxNj7QDr"]')
    marchexScriptElem &&
    marchexScriptElem.parentNode &&
    marchexScriptElem.parentNode.removeChild &&
    marchexScriptElem.parentNode.removeChild(marchexScriptElem)
    setMarchex(false)
  }
})

const withHandleActivateMarchexScript = withHandlers({
  handleActivateMarchexScript: ({ triggerMarchexReinject }) => debounce(triggerMarchexReinject, DEBOUNCE_INTERVAL)
})

const withMarchexLifecycle = lifecycle({
  componentDidMount() {
    const { handleActivateMarchexScript } = this.props
    window.addEventListener(ACTIVATE_MARCHEX_SCRIPT, handleActivateMarchexScript)
  },
  UNSAFE_componentWillReceiveProps({ location: { pathname: nextPathname = '' } = {}, marchex: nextMarchex }) {
    const { location: { pathname: currentPathname = '' } = {}, setMarchex, triggerMarchexReinject } = this.props
    if (currentPathname && nextPathname && currentPathname !== nextPathname) {
      triggerMarchexReinject()
    } else {
      !nextMarchex && setMarchex(true)
    }
  },
  componentWillUnmount() {
    const { handleActivateMarchexScript } = this.props
    window.removeEventListener(ACTIVATE_MARCHEX_SCRIPT, handleActivateMarchexScript)
  }
})

export default compose(
  withMarchexState,
  withTriggerMarchexReinject,
  withHandleActivateMarchexScript,
  withMarchexLifecycle
)

