import React from 'react'
import styled from 'styled-components'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'
import VehicleRadioInput from '../VehicleRadioInput'

const ConfirmYourVehicleForm = ({
  model,
  handleSubmit,
  handleHide,
  onSubmit
}) =>
  <Form noValidate onSubmit={ handleSubmit(onSubmit) }>
    <VehiclesList>
      {
        model.map(({ vehicle, ...inputProps }, index) =>
          <Field key={ index } { ...inputProps } vehicle={ vehicle } component={ VehicleRadioInput } />
        )
      }
    </VehiclesList>
    <ButtonsWrapper>
      <Button full="mobile" stacked="mobile">
        CONTINUE
      </Button>
      <Button link full="mobile" stacked="mobile" type="button" onClick={ handleHide }>
        Cancel
      </Button>
    </ButtonsWrapper>
  </Form>

ConfirmYourVehicleForm.propTypes = {
  handleHide: PropTypes.func,
  handleSubmit: PropTypes.func,
  model: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func
}

export default ConfirmYourVehicleForm

const Form = styled.form`
  ${Media.mobile`
    position: absolute;
    height: 100%;
    overflow: auto;
    padding-bottom: 250px;
    padding-right: 16px;
  `}
`

const VehiclesList = styled.div`
  ${Mixin.responsive('margin-top', '0', '10px')}
  ${Media.desktop`
    max-height: 41vh;
    overflow-y: auto;
  `}
`

const ButtonsWrapper = styled.div`
  ${Mixin.responsive('margin-top', '40px', '20px')}
`
