import { filterProps } from 'helpers/utils'

const propsToRemove = [
  '@type',
  'lazyPlaceholder',
  'contentRef',
  'originalContent',
  'images',
  'setImages',
  'bold',
  'inline',
  'localStoreOpportunitiesMode',
  'storeNumber',
  'hasSelectedStore',
  'hasResourceUrl',
  'storeEmail',
  'resourceUrl',
  'linkText',
  'search',
  'pathName'
]

export const filterLazyProps = (props) => filterProps(props, propsToRemove)
