import { connect } from 'react-redux'
import { compose, lifecycle, withState, withProps, withHandlers } from 'recompose'
import { getKenshooEventData } from 'selectors/thirdPartyData'
import { KENSHOO_CIDS as kenshooCIDs, KENSHOO_SERVER_ID } from './constants'

const mapStateToProps = state => ({ kenshooEventData: getKenshooEventData(state) })

const withKenshooCIDs = withProps(() => ({ kenshooCIDs }))

const withKenshooScriptsState = withState('kenshooScripts', 'setKenshooScripts', null)

const withHandleKenshooEvent = withHandlers({
  handleKenshooEvent: ({ kenshooCIDs }) => ({ conversionType, revenue }) => {
    const { kenshoo } = window
    kenshoo && kenshoo.trackConversion && kenshooCIDs.forEach(cid =>
      kenshoo.trackConversion(KENSHOO_SERVER_ID, cid, {
        conversionType,
        revenue
      })
    )
  }
})

const withKenshooLifecycle = lifecycle({
  UNSAFE_componentWillReceiveProps({ kenshooEventData: nextKenshooEventData, kenshooScripts: nextKenshooScripts }) {
    const { kenshooEventData, handleKenshooEvent, setKenshooScripts } = this.props
    const { kenshoo, kenshooMounted } = window

    if (kenshooEventData !== nextKenshooEventData) {
      handleKenshooEvent(nextKenshooEventData)
    } else if (!kenshoo && !nextKenshooScripts && kenshooMounted) {
      setKenshooScripts(true)
    }
  }
})

export default compose(
  connect(mapStateToProps),
  withKenshooCIDs,
  withKenshooScriptsState,
  withHandleKenshooEvent,
  withKenshooLifecycle
)
