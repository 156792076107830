import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import { DEFAULT_TYPE_TIMER, LARGE_TYPE_TIMER, SMALL_TYPE_TIMER } from './constants'
import {
  millisecondsToDays,
  millisecondsToHours,
  millisecondsToMinutes,
  millisecondsToSeconds
} from './helpers'

const CountdownTimer = ({
  hideSeconds,
  type,
  distance,
  timerLabels: {
    days: daysLabel,
    hours: hoursLabel,
    minutes: minutesLabel,
    seconds: secondsLabel
  }
}) =>
  <TimerContainer className={ `countdown-timer countdown-timer_${type}` } >
    <CountdownTimerUnit className="countdown-timer__unit" >
      <UnitNumbers className="countdown-timer__unit-numbers">
        {millisecondsToDays(distance) < 10 ? '0' : ''}{millisecondsToDays(distance)}
      </UnitNumbers>
      <UnitLabel className="countdown-timer__unit-label">
        {daysLabel}
      </UnitLabel>
    </CountdownTimerUnit>

    <CountdownTimerUnit className="countdown-timer__unit" >
      <UnitNumbers className="countdown-timer__unit-numbers">
        {millisecondsToHours(distance) < 10 ? '0' : ''}{millisecondsToHours(distance)}
      </UnitNumbers>
      <UnitLabel className="countdown-timer__unit-label">
        {hoursLabel}
      </UnitLabel>
    </CountdownTimerUnit>

    <CountdownTimerUnit className="countdown-timer__unit" >
      <UnitNumbers className="countdown-timer__unit-numbers">
        {millisecondsToMinutes(distance) < 10 ? '0' : ''}{millisecondsToMinutes(distance)}
      </UnitNumbers>
      <UnitLabel className="countdown-timer__unit-label">
        {minutesLabel}
      </UnitLabel>
    </CountdownTimerUnit>

    {
      !hideSeconds &&
      <CountdownTimerUnit className="countdown-timer__unit" >
        <UnitNumbers className="countdown-timer__unit-numbers">
          {millisecondsToSeconds(distance) < 10 ? '0' : ''}{millisecondsToSeconds(distance)}
        </UnitNumbers>
        <UnitLabel className="countdown-timer__unit-label">
          {secondsLabel}
        </UnitLabel>
      </CountdownTimerUnit>
    }

  </TimerContainer>

CountdownTimer.propTypes = {
  distance: PropTypes.number,
  hideSeconds: PropTypes.bool,
  timerLabels: PropTypes.shape({
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string
  }),
  type: PropTypes.string
}

CountdownTimer.defaultProps = {
  type: DEFAULT_TYPE_TIMER,
  timerLabels: {
    days: 'days',
    hours: 'hrs',
    minutes: 'mins',
    seconds: 'sec'
  },
  hideSeconds: false
}

const TimerContainer = styled.div`
  max-width: 290px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.countdown-timer_${SMALL_TYPE_TIMER} {
    max-width: 220px;

    & .countdown-timer__unit {
      min-width: 49px;
      height: 44px;
    }
  }

  &.countdown-timer_${LARGE_TYPE_TIMER} {
    max-width: 620px;

    & .countdown-timer__unit {
      min-width: 139px;
      height: 95px;
      border: solid 1px black;
    }

    & .countdown-timer__unit-numbers {
      margin-bottom: 5px;
      font-size: 40px;
    }

    & .countdown-timer__unit-label {
      font-size: 14px;
    }
  }
`

const CountdownTimerUnit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  height: 57px;
  padding: 3px 10px;
  border-radius: 4px;
  background-color: ${Theme.colors.white};
`

const UnitNumbers = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${Theme.colors.bright_red_new};
`

const UnitLabel = styled.span`
  font-size: 8px;
  font-weight: 800;
  color: ${Theme.colors.semidark_grey};
  text-transform: uppercase;
`

export default CountdownTimer
