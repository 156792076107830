export const FORM_NAME = 'appointmentConfirmationForm'

export const APPOINTMENT_SUCCESS_MODAL = 'appointmentSuccess'
export const APPOINTMENT_CHANGE_MODAL = 'appointmentChange'

// Form fields
export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const PHONE_NUMBER = 'phoneNumber'
export const EMAIL = 'email'
export const USER_PLAN = 'userPlan'

// legend titles
export const CONTACT_INFORMATION = 'Contact Information'
export const WAIT_DROPOFF_HEADER = 'Wait/Drop Off'

export const CUSTOMER_HEADER_ID = 'customer-header'
export const WAIT_DROPOFF_HEADER_ID = 'wait-dropoff-header'
