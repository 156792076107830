import { branch, compose, renderNothing } from 'recompose'
import { connect } from 'react-redux'
import { getPageLocation } from 'selectors'
import withType from 'components/common/hoc/withType'
import FooterTopContentBlock from './FooterTopContentBlock'
import { isQuoteSummaryPage, isQuickAppointmentPage } from 'helpers/quoteRelatedPages'

const mapStateToProps = state => {
  const pageLocation = getPageLocation(state)

  return {
    isQuotePage: isQuoteSummaryPage(pageLocation && pageLocation.pathname),
    isQuickAppointmentPage: isQuickAppointmentPage(pageLocation && pageLocation.pathname)
  }
}

const withHiddenHeaderOnQuotePage = branch(
  ({ isQuotePage, isQuickAppointmentPage }) => isQuotePage || isQuickAppointmentPage,
  renderNothing
)

export default compose(
  withType({
    type: 'FooterTopContentBlock',
    placeholders: ['footerContents']
  }),
  connect(mapStateToProps),
  withHiddenHeaderOnQuotePage
)(FooterTopContentBlock)
