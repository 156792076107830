import { isFunction } from 'helpers/utils'
import { INPUT_ERROR_PLACEHOLDER } from 'constant'

// Returns decorated event handlers to have a possibility to provide custom event handlers.
// It's required to call redux-form event handlers to update values, change focus state and etc.
export const getMergedEventHandlers = (userEventHandlers = {}, reduxFormInputHandlers = {}) =>
  Object.keys(userEventHandlers)
    .reduce((eventHandlers, eventName) => ({
      ...eventHandlers,
      [eventName]: (...args) => {
        const userHandler = userEventHandlers[eventName]
        const inputHandler = reduxFormInputHandlers[eventName]

        if (isFunction(inputHandler)) {
          reduxFormInputHandlers[eventName](...args)
        }

        if (isFunction(userHandler)) {
          userEventHandlers[eventName](...args)
        }
      }
    }), {})

export const isErrorState = ({ submitFailed, error } = {}) => submitFailed && error && error !== INPUT_ERROR_PLACEHOLDER
