import { compose, withHandlers, lifecycle, withState } from 'recompose'

const withPreventScriptDownload = withState('downloadedScripts', 'setDownloadedScripts', [])

const withStateHandlers = withHandlers({
  updateScriptList: ({ downloadedScripts, setDownloadedScripts, watchScripts = []}) => () => {
    const newDownloadedScripts = watchScripts.filter(script =>
      document.querySelector(`#${script}`) !== null && !downloadedScripts.includes(script)
    )
    setDownloadedScripts([...downloadedScripts, ...newDownloadedScripts])
  }
})

const withLifecycleEvents = lifecycle({

  componentDidUpdate({ location: prevLocation }) {
    const { updateScriptList, location } = this.props
    location !== prevLocation && updateScriptList()
  },

  componentDidMount() {
    const { updateScriptList } = this.props
    window.addEventListener('load', updateScriptList)
  }
})

export default compose(
  withPreventScriptDownload,
  withStateHandlers,
  withLifecycleEvents
)
