import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Text from 'components/common/Text/Text'

const FooterSocilaMedia = ({ placeholders: { socilaMediaItems = []} = {}, ...props }) =>
  <SocialBlock>
    <FollowUs narrow headline_inversed>Follow Us!</FollowUs>
    { socilaMediaItems }
  </SocialBlock>

FooterSocilaMedia.propTypes = {
  '@type': PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    socilaMediaItems: PropTypes.arrayOf(PropTypes.node)
  })
}

FooterSocilaMedia.type = 'FooterSocilaMedia'
FooterSocilaMedia.placeholders = ['socilaMediaItems']

export default FooterSocilaMedia

const SocialBlock = styled.div`
  ${Media.desktop`
    display: inline-block;
    float: right;
    height: 70px;
    vertical-align: middle;
  `}
  ${Media.mobile`
    margin: 20px 0;
    text-align: center;
  `}
  ${Mixin.middle}
`

const FollowUs = styled(Text)`
  display: inline-block;
`
