import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Modal from 'components/common/Modal'
import ArticlesAccordion from '../ArticlesAccordion'
import { ARTICLES_ACCORDION_MODAL_TITLE } from '../../constants'

const ArticlesAccordionModal = ({
  articleCategories = [],
  handleHide,
  isiOSDevice
}) =>
  <Container
    isiOSDevice={ isiOSDevice }
    title={ ARTICLES_ACCORDION_MODAL_TITLE }
    closeHandler={ handleHide }>
    <ArticlesAccordion
      items={ articleCategories }
      handleHide={ handleHide } />
  </Container>

ArticlesAccordionModal.propTypes = {
  articleCategories: PropTypes.arrayOf(PropTypes.shape()),
  handleHide: PropTypes.func,
  isiOSDevice: PropTypes.bool
}

export default ArticlesAccordionModal

const Container = styled(Modal)`
  max-width: 530px;
  width: 100%;
  height: 100vh;

  & .modal-header {
    padding: 20px 20px;
    border: none;

    & h1 {
      text-transform: capitalize;
    }
  }

  & .modal-body {
    padding: 0;
  }

  ${props => props.isiOSDevice && css`
    & .modal-header {
      padding-top: 80px;
    }

    & [data-at-modal-close] {
      padding-top: 95px;
    }

    & .modal-body {
      padding: 0 0 100px 0;
    }
  `}
`
