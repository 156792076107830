import { HOME } from 'constant'
import { connect } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'

const mapStateToProps = ({ router: { location }}) => ({ location })

const withNavigation = withHandlers({
  handleNavigationBack: ({ location: { key }, goBack, push }) => () => {
    key ? goBack() : push(HOME)
  }
})

export default compose(
  connect(mapStateToProps, { goBack, push }),
  withNavigation
)
