import { createSelector } from 'helpers/reselect'

// selector
export const getStoreDetails = ({
  storeDetailsInformation
} = {}) => storeDetailsInformation

export const getStoreName = createSelector(
  [getStoreDetails],
  ({
    storeAddress: {
      address1
    } = {}
  } = {}) => address1
)

export default createSelector(
  [getStoreDetails]
)
