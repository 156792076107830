import Carousel from 'components/common/Carousel'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'

export const HomePageCarousel = styled(Carousel)`
  padding-bottom: 30px;

  .slick-dots {
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: 100%;
    display: flex !important;
    justify-content: center;
  }

  .slick-dots li.slick-active button {
    background-color: ${Theme.colors.grey};
  }

  .slick-dots li button {
    background-color: ${Theme.colors.light_grey};
  }
`
