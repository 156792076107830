import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from 'components/common/Text/Text'
import Media from 'components/common/Media'
import VehicleImage from 'components/common/VehicleImage'

const CarTile = ({ model, makeDesc, modelDesc, subModelDesc, yearDesc, vehicleColor }) =>
  <Wrapper data-at-car-tile>
    <VehicleImg>
      <Image
        make={ makeDesc }
        model={ modelDesc }
        submodel={ subModelDesc }
        year={ parseInt(yearDesc, 10) }
        height={ 60 }
        width={ 120 }
        color={ vehicleColor } />
    </VehicleImg>
    <VehicleDetails>
      <Text data-at-year size="12">{ yearDesc }</Text>
      <Text data-at-model bold block size="18">{ model }</Text>
    </VehicleDetails>
  </Wrapper>

export default CarTile

CarTile.propTypes = {
  makeDesc: PropTypes.string,
  model: PropTypes.string,
  modelDesc: PropTypes.string,
  subModelDesc: PropTypes.string,
  vehicleColor: PropTypes.string,
  yearDesc: PropTypes.string
}

const Wrapper = styled.div`
  ${Media.desktop`
    margin: 30px 0px;
    display: inline-block;
  `}
`

const VehicleImg = styled.div`
  width: 120px;
  margin: 0 auto;
  text-align: center;
  ${Media.desktop`
    display: table-cell;
    vertical-align: middle;
  `}
`

const Image = styled(VehicleImage)`
  display: block;
`

const VehicleDetails = styled.div`
  text-align: left;
  ${Media.desktop`
    display: table-cell;
    vertical-align: middle;
    padding-left: 24px;
  `}
`
