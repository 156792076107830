import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'
import {
  STORE_CHANGE_MODAL_TITLE,
  USE_MY_STORE_BUTTON_TEXT,
  FIND_ANOTHER_STORE_BUTTON_TEXT
} from './constants'

const StoreChangeModal = ({
  handleClose,
  showOneButtonModal,
  handleUseMyStore,
  handleFindAnotherStore
}) =>
  <CustomModal
    closeHandler={ handleClose }
    modalName="Store Change">
    <ModalTitle>{ STORE_CHANGE_MODAL_TITLE }</ModalTitle>

    <ButtonsWrapper>
      {!showOneButtonModal && <Button onClick={ handleUseMyStore }>{ USE_MY_STORE_BUTTON_TEXT }</Button>}

      <Button onClick={ handleFindAnotherStore }>
        {FIND_ANOTHER_STORE_BUTTON_TEXT}
      </Button>
    </ButtonsWrapper>

  </CustomModal>

export default StoreChangeModal

StoreChangeModal.propTypes = {
  handleClose: PropTypes.func,
  handleFindAnotherStore: PropTypes.func,
  handleUseMyStore: PropTypes.func,
  showOneButtonModal: PropTypes.bool
}

const CustomModal = styled(Modal)`
  border: 0;
  width: 500px;
  ${Media.mobile`
    height: auto;
    width: auto;
  `}
  & .modal-header {
    display: none;
  }

  & .modal-body {
    padding: 0 15px;
    padding: 30px 15px;
  }
`

const ModalTitle = styled.p`
  margin: 0 0 25px;
  text-align: center;
  overflow-y: hidden;
  font-weight: 600;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 390px) {
    font-size: 11px;
  }
`

