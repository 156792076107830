export const USER_COUPONS_PROMOS = 'coupons'
export const REBATE_PROMOS = 'rebate'
export const NATURAL_NUMBER_REGEXP = /^[1-9]\d*$/
export const ALIGNMENT_SERVICE = 'wheel-alignment'
export const SIX_MONTHS_FINANCING_MODAL_ID = 'sixPaymentsModalContent'
export const TWELVE_MONTHS_FINANCING_MODAL_ID = 'twelvePaymentsModalContent'
export const SIX_MONTHS_FINANCING_STORE_EXCLUSIONS_KEY = 'six_months_financing_promo_excluded_stores'
export const TWELVE_MONTHS_FINANCING_STORE_EXCLUSIONS_KEY = 'twelve_months_financing_promo_excluded_stores'
export const SIX_MONTHS = '6'
export const TWELVE_MONTHS = '12'
