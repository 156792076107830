export const FIND_LOWER_PRICE_FORM = 'findLowerPrice'

export const FIND_LOWER_PRICE_FORM_FIRST_NAME = 'firstName'
export const FIND_LOWER_PRICE_FORM_LAST_NAME = 'lastName'
export const FIND_LOWER_PRICE_FORM_LOWER_PRICE = 'lowerPrice'
export const FIND_LOWER_PRICE_FORM_LOWER_PRICE_LINK = 'lowerPriceLink'
export const FIND_LOWER_PRICE_FORM_EMAIL = 'email'
export const FIND_LOWER_PRICE_FORM_PHONE = 'phoneNumber'
export const FIND_LOWER_PRICE_FORM_POSTAL_CODE = 'postalCode'

