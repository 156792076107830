import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { show } from 'redux-modal'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import StoreNotEligibleForPromoModal from './StoreNotEligibleForPromoModal'
import { STORE_NOT_ELIGIBLE_FOR_PROMOTION_MODAL } from 'components/QuickAppointmentPage/ThreeStepper/constants.js'
import { STORE_LOCATOR_MODAL_PAGE } from 'constant'

const withPromoNotEligible = withHandlers({
  proceedWithoutPromo: ({ selectStore, handleHide }) => () => {
    selectStore()
    handleHide()
  },
  changeStore: ({ showStoreLocatorModal, handleHide }) => () => {
    handleHide()
    showStoreLocatorModal(STORE_LOCATOR_MODAL_PAGE, { isAppointments: true })
  }
})

export default compose(
  connect(null, { showStoreLocatorModal: show }),
  registerReduxModal({ name: STORE_NOT_ELIGIBLE_FOR_PROMOTION_MODAL, destroyOnHide: true }),
  withPromoNotEligible
)(StoreNotEligibleForPromoModal)
