import { compose } from 'recompose'
import { staticHtmlImgLazyLoad } from 'components/common/hoc/withLazyLoader'
import LazyHtmlDiv from './LazyDiv'
import LazyHtmlP from './LazyP'

export const LazyP = compose(
  staticHtmlImgLazyLoad
)(LazyHtmlP)

export const LazyDiv = compose(
  staticHtmlImgLazyLoad
)(LazyHtmlDiv)
