import { handleActions } from 'redux-actions'
import {
  setFiltersData,
  removeFiltersData,
  setGlobalSearchView
} from './actions'

const initialState = {
  filters: [],
  filtersMap: {},
  displayGlobalSearch: false
}

const handleFilterDataSet = ({ filters, filtersMap, ...state }, {
  payload: {
    name: filterGroup,
    navigation
  }
}) => ({
  ...state,
  filters: [...filters.filter(item => item.filterGroup !== filterGroup), { filterGroup, navigation }],
  filtersMap: {
    ...filtersMap,
    ...navigation.reduce((acc, { refinements }) => ({
      ...acc,
      ...refinements.reduce((acc, refinement) => ({
        ...acc,
        [refinement.navigationState]: refinement
      }), {})
    }), {})
  }
})

const handleFilterDataRemove = ({ filters, ...state }, { payload: { name }}) => ({
  ...state,
  filters: filters.filter(({ filterGroup }) => filterGroup !== name)
})

const globalSearchPage = handleActions({
  [setFiltersData]: handleFilterDataSet,
  [removeFiltersData]: handleFilterDataRemove,
  [setGlobalSearchView]: (state, { payload: displayGlobalSearch }) => ({
    ...state,
    displayGlobalSearch
  })
}, initialState)

export default globalSearchPage
