import { compose } from 'redux'
import { withHandlers, withState } from 'recompose'
import ArticlesAccordion from './ArticlesAccordion'

const withActiveIndexState = withState('activeIndex', 'setActiveIndex', null)

const withAccordionHandlers = withHandlers({
  onItemClick: ({ setActiveIndex, activeIndex }) => (index) => {
    setActiveIndex(index === activeIndex ? null : index)
  }
})

export default compose(
  withActiveIndexState,
  withAccordionHandlers
)(ArticlesAccordion)
