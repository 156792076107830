import { handleActions } from 'redux-actions'
import { setInitialCarFaxSuccess } from './actions'

const initialState = {
  carfax: {
    initialCarFaxSuccess: false
  }
}

const serviceHistoryPage = handleActions({
  [setInitialCarFaxSuccess]: (state, { payload: { initialCarFaxSuccess }}) =>
    ({
      ...state,
      carfax: { ...state.carfax, initialCarFaxSuccess }
    })
}, initialState)

export default serviceHistoryPage
