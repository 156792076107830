import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { handleAddNewVehicle } from 'actions/addVehicle'
import { navigateToServices } from 'actions/shopServices'
import { getHasActiveVehicleStatus } from 'selectors'
import { ADDING_WITHOUT_SELECTED_VEHICLE } from 'constant'

const withClickHandler = withHandlers({
  navigateToServicesOrShowAddVehicle: ({
    hasActiveVehicle,
    handleAddNewVehicle,
    navigateToServices
  }) => e => {
    if (!hasActiveVehicle) {
      handleAddNewVehicle({
        description: ADDING_WITHOUT_SELECTED_VEHICLE,
        tiresSelectExtraCallbacks: [() => navigateToServices()],
        skipUpdatePage: true
      })
    } else {
      navigateToServices()
    }
  }
})

const mapStateToProps = state => ({
  hasActiveVehicle: getHasActiveVehicleStatus(state),
  isLoading: state.app.isLoading || state.app.isFetching
})

export default compose(
  connect(mapStateToProps, { handleAddNewVehicle, navigateToServices }),
  withClickHandler
)
