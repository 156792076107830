import React from 'react'
import PropTypes from 'prop-types'
import { injectProps } from 'helpers/utils'

const ContentSlotHeader = ({ noStickyHeader, placeholders: { contents = []} = {}, ...props }) =>
  <div data-printless>
    { injectProps(contents, { noStickyHeader })}
  </div>

ContentSlotHeader.propTypes = {
  '@type': PropTypes.string,
  contentPaths: PropTypes.arrayOf(PropTypes.string),
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  name: PropTypes.string,
  noStickyHeader: PropTypes.bool,
  placeholders: PropTypes.shape({
    contents: PropTypes.array
  }),
  ruleLimit: PropTypes.number,
  templateIds: PropTypes.arrayOf(PropTypes.any),
  templateTypes: PropTypes.arrayOf(PropTypes.string)
}

ContentSlotHeader.type = 'ContentSlotHeader'
ContentSlotHeader.placeholders = ['contents']

export default ContentSlotHeader
