export const extractQuickAppointmentConfirmationData = ({
  orderDetails: {
    orderId,
    revenue
  } = {},
  selectedServiceItems: {
    selectedServiceDetailsList = []
  } = {}
}) => ({
  orderId,
  tax: 0,
  revenue,
  totalPrice: 0,
  services: selectedServiceDetailsList
})
