import { connect } from 'react-redux'
import { compose } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { CHANGED_AVAILABILITY_PRICE_MODAL } from './constants'
import { clearProductsWithChangedAvailabilityOrPrice } from 'components/common/hoc/withAddToQuote/actions.js'
import ChangedAvailabilityPriceModal from './ChangedAvailabilityPriceModal'

const mapStateToProps = state => ({
  phoneNumber: state.myStore?.phoneNumbers[0]
})

export default compose(
  registerReduxModal({ name: CHANGED_AVAILABILITY_PRICE_MODAL }),
  connect(mapStateToProps, {
    clearProductsWithChangedAvailabilityOrPrice
  })
)(ChangedAvailabilityPriceModal)

