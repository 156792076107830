import {
  activateGoogleOptimize,
  applyNowCTAClick,
  phoneLinkClick,
  servicesCheckboxClick
} from 'actions/thirdParty'
import {
  googleAnalyticProductImpressions,
  googleAnalyticProductClicks,
  googleAnalyticProductDetailsView,
  googleAnalyticProductAddToCart,
  googleAnalyticProductRemoveFromCart,
  googleAnalyticPromotionImpressions,
  googleAnalyticPromotionClicks,
  googleAnalyticCheckout,
  googleAnalyticPurchase,
  googleAnalyticStoreNumberPush,
  googleAnalyticQuickStepperActionBarClick
} from 'actions/googleAnalytic'

export const GOOGLE_OPTIMIZE_ACTIVATION_EVENTS = [
  String(activateGoogleOptimize),
  String(googleAnalyticProductImpressions),
  String(googleAnalyticProductClicks),
  String(googleAnalyticProductDetailsView),
  String(googleAnalyticProductAddToCart),
  String(googleAnalyticProductRemoveFromCart),
  String(googleAnalyticProductAddToCart),
  String(googleAnalyticPromotionImpressions),
  String(googleAnalyticPromotionClicks),
  String(googleAnalyticCheckout),
  String(googleAnalyticPurchase),
  String(googleAnalyticStoreNumberPush),
  String(googleAnalyticQuickStepperActionBarClick),
  String(applyNowCTAClick),
  String(phoneLinkClick),
  String(servicesCheckboxClick)
]
export const PRODUCT_IMPRESSION = 'Impressions'
export const ADD_TO_CART = 'Add to Cart'
export const CONFIRMATION = 'Order Completed'
