import { Validator, errorMessages } from 'services/validator'
import {
  FIND_SERVICE_HISTORY_FORM_LICENSE_FIELD,
  FIND_SERVICE_HISTORY_FORM_VIN_FIELD
} from './constants'

export default Validator.combineValidators({
  [FIND_SERVICE_HISTORY_FORM_LICENSE_FIELD]: {
    errorMessage: errorMessages.FIND_SERVICE_HISTORY_LICENSE_PLATE_NUMBER_INVALID,
    validators: Validator.licensePlate()
  },
  [FIND_SERVICE_HISTORY_FORM_VIN_FIELD]: {
    errorMessage: errorMessages.FIND_SERVICE_HISTORY_VIN_INVALID,
    validators: Validator.vin()
  }
})
