import { SubmissionError } from 'redux-form'
import { rest, isApiError } from 'services'
import { hide } from 'redux-modal'
import { refresh } from 'actions/router'
import { setInitialCarFaxSuccess } from '../../components/BOTServiceHistory/actions'
import { CONNECT_TO_CARFAX_MODAL } from '../../constants'

export const connectToCarfax = ({ email }) => dispatch =>
  rest.api.findServiceHistory({ email })
    .then(() => {
      dispatch(setInitialCarFaxSuccess({ initialCarFaxSuccess: true }))
      dispatch(hide(CONNECT_TO_CARFAX_MODAL))
      dispatch(refresh())
    })
    .catch(err => {
      if (isApiError(err)) {
        throw new SubmissionError({ _error: err.status.description })
      } else {
        throw new SubmissionError({ _error: 'There are some errors.' })
      }
    })
