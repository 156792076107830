import { APPT_CONFIRMATION } from 'constant'

export const initialize3rdPartyDataLayer = ({ ary = [], handler }) => ary.forEach((data) => handler(data))

export const mutate3rdPartyDataLayer = ({ ary, handler }) => (data) => {
  Array.prototype.push.call(ary, data)
  handler(data)
  ary.length > 50 && ary.shift()
}

export const evaluateFullStoryRatio = ({ fullStory }, value) => fullStory !== null ?
  fullStory : (Math.random() <= (Number(value) / 100))

export const evaluateKenshooRevenue = ({ conversionType }, { kenshoo: { revenue = 0 } = {}}) =>
  conversionType === APPT_CONFIRMATION ? 0 : revenue
