import React from 'react'
import PropTypes from 'prop-types'
import { ListWrapper, Col } from '../../SharedStyles'
import {
  TIRE_SIZE_TITLE,
  YMME_TITLE,
  LICENSE_PLATE_TITLE
} from '../../constant'
import styled, { css } from 'styled-components'

const OptionsList = ({ list, click, place, step }) =>
  <List isTireSizeModal={ place === TIRE_SIZE_TITLE }>
    {
      list.map((row, index) =>
        <Column
          isTireSizeModal={ place === TIRE_SIZE_TITLE }
          isYMMEModal={ place === YMME_TITLE }
          isLicensePlateModal={ place === LICENSE_PLATE_TITLE }
          step={ step }
          alt={ row.label }
          tabIndex="0"
          key={ row.label + index }
          onClick={ () => click(row) } >
          { row.label }
        </Column>
      )
    }
  </List>

export default OptionsList

OptionsList.propTypes = {
  click: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.shape()),
  place: PropTypes.string,
  step: PropTypes.number
}

const List = styled(ListWrapper)`
  ${props => props.isTireSizeModal && css`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 700px) {
      display: block;
    }
  `}
`

const Column = styled(Col)`
  ${props => props.isTireSizeModal && css`
    max-width: 135px;
    width: 100%;
  `}
  font-size: 18px;
  line-height: 30px;
  ${props => (props.isTireSizeModal || (props.isYMMEModal && props.step === 0)) && css`
    @media (max-width: 700px) {
      text-align: center;
      ${props => props.isTireSizeModal && css`
        max-width: unset;
        width: auto;
      `}
    }
  `}

  @media (max-width: 700px) {
    line-height: 44px;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 345px) {
    font-size: 16px;
  }
`
