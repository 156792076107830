import { createAction } from 'redux-actions'

export const googleAnalyticProductAddToCart = createAction('GTM/ADD_TO_CART')
export const googleAnalyticProductRemoveFromCart = createAction('GTM/REMOVE_FROM_CART')
export const googleAnalyticProductImpressions = createAction('GTM/PRODUCT_IMPRESSIONS')
export const googleAnalyticProductClicks = createAction('GTM/PRODUCT_CLICKS')
export const googleAnalyticProductDetailsView = createAction('GTM/PRODUCT_DETAILS_VIEW')
export const googleAnalyticPromotionImpressions = createAction('GTM/PROMOTION_IMPRESSIONS')
export const googleAnalyticPromotionClicks = createAction('GTM/PROMOTION_CLICKS')
export const googleAnalyticCheckout = createAction('GTM/CHECKOUT')
export const googleAnalyticPurchase = createAction('GTM/PURCHASE')
export const googleAnalyticStoreNumberPush = createAction('GTM/STORE_NUMBER_PUSH')
export const googleAnalyticQuickStepperActionBarClick = createAction('GTM/QUICK_STEPPER_ACTION_BAR_CLICK')
export const googleAnalytic3StepperActionBarClick = createAction('GTM/3_STEPPER_ACTION_BAR_CLICK')
export const googleAnalyticZeroTireSearchResults = createAction('GTM/ZERO_TIRE_SEARCH_RESULTS')
export const googleAnalyticQuickApptSubmissionError = createAction('GTM/QUICK_APPOINTMENT_SUBMISSION_ERROR')
export const googleAnalyticDatepickerTomorrowSelected = createAction('GTM/DATEPICKER_TOMORROW_SELECTED')
export const googleAnalyticProductAddToCartWithoutQuoteModal = createAction('GTM/ADD_TO_CART_FROM_SAVED_FOR_LATER_LIST')
export const googleAnalyticDatepickerTodaysSelected = createAction('GTM/DATEPICKER_TODAYS_SELECTED')
export const googleAnalyticEmailAddressFill = createAction('GTM/EMAIL_ADDRESS_FILL')
