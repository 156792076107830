export const EMAIL_SIGN_UP_FORM = 'emailSignUpPopUpForm'

export const EMAIL_PROPERTY = 'email'
export const FIRST_NAME_PROPERTY = 'firstName'
export const LAST_NAME_PROPERTY = 'lastName'
export const ADDRESS_LINE1_PROPERTY = 'address1'
export const CITY_PROPERTY = 'city'
export const STATE_PROPERTY = 'state'
export const ZIP_CODE_PROPERTY = 'zipCode'
export const MONTH_PROPERTY = 'birthMonth'
export const DAY_PROPERTY = 'birthDay'
export const MAKE_PROPERTY = 'makeDesc'
export const YEAR_PROPERTY = 'yearDesc'
export const MODEL_PROPERTY = 'modelDesc'
export const SUBMODEL_PROPERTY = 'subModelDesc'
export const MILEAGE_PROPERTY = 'mileage'
export const POLICY_PROPERTY = 'policy'

export const POLICY_LABEL = 'I understand and agree that registration on or use of this site constitutes acceptance of our '

export const REQUIRED_HINT = '*Required.'

export const AGREEMENT_LABEL = 'Agreement*'

export const SUCCEED_HINT = 'Thank you for signing up!'

export const UNKNOWN_ERROR = 'Something wrong happened. Please try later.'

export const SIGN_UP_YMME = 'SIGN_UP_YMME'

export const PERSONAL_INFORMATION = 'Personal Information'
export const VEHICLE_INFORMATION = 'Vehicle Information'
