import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'
import Link from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'


const MenuItemDesktop = ({
  title,
  description,
  icon,
  displayAsButton,
  ctaTitle = 'Request Appointment',
  link: { path } = {},
  onClick = () => {},
  ...props
}) =>
  <Wrapper { ...props } { ...dynamicDataAttribute('header-make-appointment-tile', toOneString(title)) }>
    <Picture asset={ icon } data-at-picture alt="" />
    <Headline data-at-title>{ title }</Headline>
    <Description data-at-description>{ description }</Description>
    { displayAsButton
      ? <Button data-at-button narrow onClick={ onClick }> { ctaTitle } </Button>
      : <Link button to={ path } data-at-button onClick={ onClick }>
        { ctaTitle }
        <Media.ScreenReader>{ description }</Media.ScreenReader>
      </Link>
    }
    <Separator data-at-separator>Or</Separator>
  </Wrapper>

MenuItemDesktop.propTypes = {
  ctaTitle: PropTypes.string,
  description: PropTypes.string,
  displayAsButton: PropTypes.bool,
  icon: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.string
  }),
  onClick: PropTypes.func,
  title: PropTypes.string
}

export default MenuItemDesktop

const Separator = styled.div`
  position: absolute;
  top: 50%;
  right: -15px;
  z-index: 1;
  display: none;
  width: 30px;
  padding: 8px 0;
  font-weight: 600;
  color: ${Theme.colors.sub_grey};
  text-transform: uppercase;
  background-color: ${Theme.colors.white};
  transform: translateY(-50%);
`

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  padding: 0 21px;
  text-align: center;
  vertical-align: bottom;

  & + & {
    border-left: 1px solid ${Theme.colors.black_new};
  }

  &:first-child {
    & ${Separator} {
      display: block;
    }
  }
`

const Picture = styled(Icon)`
  width: 34px;
  height: 34px;
  margin-bottom: 15px;
`

const Headline = styled.div`
  padding-bottom: 5px;
  color: ${Theme.colors.sub_grey};
`

const Description = styled.div`
  min-height: 28px;
  margin-bottom: 15px;
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
  white-space: normal;
  color: ${Theme.colors.medium_grey};
`
