import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import Modal from 'components/common/Modal'
import getComponent from 'helpers/component-helper'

const AddToQuoteModal = ({
  data: { modalBoxContent },
  handleHide,
  isItStaggeredFitmentProduct
}) =>
  <ModalBox
    title="Added to Your Quote"
    isItStaggeredFitmentProduct={ isItStaggeredFitmentProduct }
    closeHandler={ handleHide }>
    {
      modalBoxContent.map((data, index) =>
        React.cloneElement(getComponent(data), { key: index }))
    }
  </ModalBox>

export default AddToQuoteModal

AddToQuoteModal.propTypes = {
  data: PropTypes.shape({
    modalBoxContent: PropTypes.arrayOf(PropTypes.shape())
  }),
  handleHide: PropTypes.func,
  isItStaggeredFitmentProduct: PropTypes.bool
}

const ModalBox = styled(Modal)`
  ${Mixin.responsive('padding', '25px 24px 0', '60px 16px 8px')}
  overflow-y: auto;
  ${props => !props.isItStaggeredFitmentProduct && css`
    & .modal-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: 20px 0 20px 15px;
    }
  `}
`
