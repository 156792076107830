import { handleActions } from 'redux-actions'
import { setAppointmentsCount } from './actions'
import { cleanMyAppointments } from 'actions/myAppointments'

const initialState = { appointmentsCount: 0 }

export default handleActions({
  [setAppointmentsCount]: (state, { payload }) => ({
    ...state,
    appointmentsCount: payload
  }),
  [cleanMyAppointments]: () => initialState
}, initialState)
