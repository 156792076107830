import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import { handleAddNewVehicle as initiateAddNewVehicle } from 'actions/addVehicle'
import withAddNewVehicleHandler from '../withAddNewVehicleHandler'
import ExtendedGarageVehicleSelectorBlock from './ExtendedGarageVehicleSelectorBlock'
import { plurality } from 'helpers/nouns-verbs'
import { VEHICLE_TEXT, IN_YOUR_GARAGE_TEXT } from './constants'

const withFormattedStrings = withProps(({
  garageVehicles: [{
    UserVehicleDetails: {
      noOfVehicles: numOfVehiclesInGarage
    } = {}
  } = {}] = []
}) => ({
  vehiclesCountHint: `${numOfVehiclesInGarage} ${plurality(numOfVehiclesInGarage, VEHICLE_TEXT)} ${IN_YOUR_GARAGE_TEXT}`
}))

export default compose(
  withType({ type: 'ExtendedGarageVehicleSelectorBlock', placeholders: ['garageVehicles']}),
  connect(null, { initiateAddNewVehicle }),
  withAddNewVehicleHandler,
  withFormattedStrings
)(ExtendedGarageVehicleSelectorBlock)
