import withType from 'components/common/hoc/withType'
import FooterMenuLink from './FooterMenuLink'

export default withType({
  type: 'FooterMenuLink',
  placeholders: ['links']
})(FooterMenuLink)

// view can be used in other files
export { FooterMenuLink }
