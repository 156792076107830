import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import SpinnerIcon from '../SpinnerIcon/SpinnerIcon'
import { SPINNER_ID } from 'constant'

const Spinner = ({ roleEnabled, handleRef, handleBlur }) =>
  <Wrapper
    id={ SPINNER_ID }
    ref={ handleRef }
    tabIndex="-1"
    role={ roleEnabled ? 'alert' : undefined }
    aria-live={ roleEnabled ? 'assertive' : 'off' }
    onBlur={ handleBlur }>
    <Media.ScreenReader>Content is loading...</Media.ScreenReader>
    <LoadingSpinnerIcon asset="spinner" alt="" />
  </Wrapper>

Spinner.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleRef: PropTypes.func.isRequired,
  roleEnabled: PropTypes.bool.isRequired
}

export default Spinner

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Theme.colors.transparent_overlay};
  z-index: 1200;
`

const LoadingSpinnerIcon = styled(SpinnerIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  ${Mixin.responsive('width', '100px', '60px')}
  ${Mixin.responsive('height', '100px', '60px')}
`
