import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Theme from 'components/common/Theme/Theme'
import { ActiveStep, InactiveStep, StepWrapper, StepIcon } from 'components/NewTireSearch/SharedStyles'
import { isLastElement, isActiveElement, isPicked, setPickedName } from '../../helpers'
import { YMME_STEPPER_STEP_ID } from '../../constant'

const Stepper = ({
  stepNames,
  step,
  totalSteps,
  data,
  prevStepHandler,
  dataLoading
}) =>
  <Wrapper>
    {stepNames.map((name, index) =>
      <StepWrapper key={ name + index } id={ YMME_STEPPER_STEP_ID } >
        { isActiveElement(step, index) && <ActiveStepElement tabIndex="0">{ name }</ActiveStepElement> }
        { (!isActiveElement(step, index)) && (
          <InactiveStepElement
            tabIndex={ isPicked(data, name) ? '0' : '-1' }
            isPicked={ isPicked(data, name) }
            disabled={ dataLoading }
            onClick={ () => prevStepHandler(name) }>
            { setPickedName(data, name) }
          </InactiveStepElement>
        )}
        { isLastElement(totalSteps, index) && <StepIcon asset="arrow-right-gray-secondary" width={ 9 } /> }
      </StepWrapper>
    )}
  </Wrapper>

export default Stepper

Stepper.propTypes = {
  data: PropTypes.shape(),
  dataLoading: PropTypes.bool,
  prevStepHandler: PropTypes.func,
  step: PropTypes.number,
  stepNames: PropTypes.arrayOf(PropTypes.string),
  totalSteps: PropTypes.number
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  margin-bottom: 10px;
  border-bottom: 3px solid ${Theme.colors.white_smoke};

  @media (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`

const ActiveStepElement = styled(ActiveStep)`
  @media (max-width: 500px) {
    font-size: 16px;
  }
`

const InactiveStepElement = styled(InactiveStep)`
  ${props => props.disabled && css`
    pointer-events: none;
    cursor: default;
  `}
  @media (max-width: 500px) {
    font-size: 16px;
  }
`
