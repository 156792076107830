import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Popover from 'components/common/Popover'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import { DefaultPopoverInnerContent, DefaultPopoverLink } from 'components/common/Popover/elements'
import Image from 'components/common/Image'

const PopoverInnerContent = styled(DefaultPopoverInnerContent)`
  padding: 30px 5px 5px;
  width: 390px;
  ${Media.mobile`
    width: 320px;
  `}
`

const PopoverLink = styled(DefaultPopoverLink)`
  vertical-align: middle;
  ${Mixin.font.GothamBold(12)}
`

const TireSizeTooltip = ({ title, ...props }) =>
  <Popover
    components={{ PopoverLink, PopoverInnerContent }}
    title={ title }
    showIcon
    small
    { ...props }>
    <TireSizeImage
      data-at-tire-size-tooltip-image
      src="//tbc.scene7.com/is/image/TBCCorporation/bot-tiresize?wid=378&qlt=40"
      alt="The tire size is set by the numbers on the tire. The numbers are width, ratio and diameter in order" />
  </Popover>

TireSizeTooltip.propTypes = {
  title: PropTypes.string
}

export default TireSizeTooltip

const TireSizeImage = styled(Image)`
  max-width: 100%;
`
