import { isSSR } from 'helpers/isSSR'

export const generateAggregateRating = (reviewCount, ratingCount) => reviewCount ? {
  '@type': 'AggregateRating',
  ratingValue: ratingCount,
  reviewCount
} : undefined

export const generateSchemaDescription = (benefits, features) => (benefits.length || features.length) ?
  benefits.join('. ') || features.join('. ') : undefined

export const generateSchemaUrl = (pathname = '') => {
  const domain = isSSR ? process.env.BOT_PROXY_ORIGIN : window.location.host
  return `http://${domain}${pathname}`
}

const isLinkValid = (pathname = '') =>
  pathname ? `${window.location.origin}${pathname}` : window.location.href

export const generateItemListElement = (links = []) =>
  links.map(({ displayName, link }, index) => {
    const pathname = typeof link === 'object' ? link.path : link

    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': !isSSR ? isLinkValid(pathname) : '',
        name: displayName
      }
    }
  })
