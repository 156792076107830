import React from 'react'
import PropTypes from 'prop-types'
import TitleWrapper from '../NewTireSearch/components/TitleWrapper'
import Stepper from '../NewTireSearch/components/Stepper'
import Search from '../NewTireSearch/components/Search'
import LicensePlateInput from '../NewTireSearch/components/LicensePlateInput'
import OptionsList from '../NewTireSearch/components/OptionsList'
import { CustomUpdatingBlock } from '../NewTireSearch/SharedStyles'
import { LICENSE_PLATE_STEP_NAMES, LICENSE_PLATE_TITLE, OPTIONS_WRAPPER_CLASS_NAME } from '../NewTireSearch/constant'
import Banner from 'components/common/Banner/Banner'
import MobileFixedWrapper from '../NewTireSearch/components/MobileFixedWrapper'

const NewLicensePlate = ({
  handleHide,
  step,
  endpointData = [],
  data: {
    plate
  },
  data,
  dataLoading,
  licenseError,
  ...props
}) =>
  <>
    <MobileFixedWrapper place={ LICENSE_PLATE_TITLE }>
      <TitleWrapper title={ LICENSE_PLATE_TITLE } />
      <Stepper
        step={ step }
        stepNames={ LICENSE_PLATE_STEP_NAMES }
        data={ data }
        { ...props } />
      { !plate
        ? (
          <LicensePlateInput
            plate={ plate }
            { ...props } />
        )
        : (
          <Search
            step={ step }
            stepNames={ LICENSE_PLATE_STEP_NAMES }
            place={ LICENSE_PLATE_TITLE }
            { ...props } />
        )
      }
    </MobileFixedWrapper>
    { licenseError && plate &&
      <CustomUpdatingBlock className={ OPTIONS_WRAPPER_CLASS_NAME } condition={ dataLoading }>
        <Banner>{ licenseError }</Banner>
      </CustomUpdatingBlock>
    }
    { plate && !licenseError &&
      <CustomUpdatingBlock className={ OPTIONS_WRAPPER_CLASS_NAME } condition={ dataLoading }>
        { Boolean(endpointData.length) && (
          <OptionsList
            stepNames={ LICENSE_PLATE_STEP_NAMES }
            list={ endpointData }
            step={ step }
            { ...props } />
        )}
      </CustomUpdatingBlock>
    }
  </>

export default NewLicensePlate

NewLicensePlate.propTypes = {
  data: PropTypes.shape(),
  dataLoading: PropTypes.bool,
  endpointData: PropTypes.arrayOf(PropTypes.shape()),
  handleHide: PropTypes.func,
  licenseError: PropTypes.string,
  step: PropTypes.number
}
