import { handleActions } from 'redux-actions'
import {
  setNotInRangeOfPromoDates,
  setPromotionDetails,
  setModalWasShown,
  setShowCallStorePromoExpirationNotification,
  setShowInRangePromoExpirationNotification
} from './actions'

const initialState = {
  appointmentDatesNotInRangeOfPromoDates: false,
  promotionDetailsList: [],
  modalWasShown: false,
  showCallStorePromoExpirationNotification: false,
  showInRangePromoExpirationNotification: false
}

const setAppointmentDatesNotInRangeOfPromoDates = (state, { payload }) => ({
  ...state,
  appointmentDatesNotInRangeOfPromoDates: payload
})

const setPromotionDetailsList = (state, { payload }) => ({
  ...state,
  promotionDetailsList: payload
})

const setIsModalWasShown = (state, { payload }) => ({
  ...state,
  modalWasShown: payload
})

const setCallStorePromoExpiration = (state, { payload }) => ({
  ...state,
  showCallStorePromoExpirationNotification: payload
})

const setInRangePromoExpiration = (state, { payload }) => ({
  ...state,
  showInRangePromoExpirationNotification: payload
})

const promotionExpirationStatus = handleActions({
  [setNotInRangeOfPromoDates]: setAppointmentDatesNotInRangeOfPromoDates,
  [setPromotionDetails]: setPromotionDetailsList,
  [setShowCallStorePromoExpirationNotification]: setCallStorePromoExpiration,
  [setShowInRangePromoExpirationNotification]: setInRangePromoExpiration,
  [setModalWasShown]: setIsModalWasShown
}, initialState)

export default promotionExpirationStatus
