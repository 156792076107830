import { Validator, errorMessages } from 'services/validator'
import { EMAIL_FIELD } from './constants'

export default Validator.combineValidators({
  [EMAIL_FIELD]: [
    {
      validators: Validator.required(),
      errorMessage: errorMessages.EMAIL_EMPTY_MESSAGE
    },
    {
      validators: Validator.email(),
      errorMessage: errorMessages.EMAIL_ERROR_MESSAGE
    }
  ]
})
