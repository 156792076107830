import { createAction } from 'redux-actions'
import { isDirty, submit } from 'redux-form'
import { show, hide } from 'redux-modal'
import { setVehicleTireSizes } from 'actions/confirmTireSize'
import { makeActiveVehicle } from '../Garage/components/BOTGarageVehicles/actions'
import filterVehicleDetails from 'helpers/filterVehicleDetails'
import { withYmmeInners } from 'components/common/hoc/withYmme'
import { CONFIRM_TIRE_SIZE_MODAL, VEHICLE_EDITOR_MODAL, TIRE_SIZES_NOT_FOUND_MODAL } from 'src/constant'
import { isApiError, rest } from 'src/services'
import {
  ACTION_CONFIRMATION_MODAL,
  VEHICLE_DETAILS_TAB,
  MAKE_AND_MODEL_TAB,
  VEHICLE_DETAILS_FORM,
  CHANGE_TABS_ACTION,
  VEHICLE_EDITOR_LICENCE_FORM,
  VEHICLE_EDITOR_YMME,
  CUSTOM_TIRE_SIZE_TEXT,
  TIRES_NOT_FOUND_BUTTON_TEXT,
  CLOSE_MODAL_ACTION,
  TIRES_NOT_FOUND_DESCRIPTION
} from './constants'
import {
  isLicenceFormActive,
  getUserVehicleId,
  getVehicleUpdateHandler,
  getCurrentTab,
  getDataFetcher,
  isYmmeDirty
} from './selectors'

export const closeModal = () => hide(VEHICLE_EDITOR_MODAL)

const showConfirmationModal = props => dispatch => {
  dispatch(show(ACTION_CONFIRMATION_MODAL, props))
}

export const changeTab = createAction('VEHICLE_EDITOR/CHANGE_TAB')

export const resetModal = createAction('VEHICLE_EDITOR/RESET_MODAL')

export const setVehicleData = createAction('VEHICLE_EDITOR/SET_VEHICLE_DATA')

export const setDataFetcher = createAction('VEHICLE_EDITOR/SET_DATA_FETCHER')

export const toggleYmme = createAction('VEHICLE_EDITOR/TOGGLE_YMME')

export const resetMakeAndModel = createAction('VEHICLE_EDITOR/RESET_MAKE_AND_MODEL')

export const changeVehicleUpdateHandler = createAction('VEHICLE_EDITOR/CHANGE_VEHICLE_UPDATE_HANDLER')

export const updateGarage = ({ userVehicleId }) => dispatch => {
  dispatch(makeActiveVehicle({ userVehicleId, updateMiniCart: true }))
  dispatch(hide(CONFIRM_TIRE_SIZE_MODAL))
  dispatch(closeModal())
}

export const updateModal = () => async (dispatch, getState) => {
  const state = getState()
  const fetchVehicleData = getDataFetcher(state)

  await fetchVehicleData()

  const currentTab = getCurrentTab(state)
  const newTab = currentTab === VEHICLE_DETAILS_TAB ?
    MAKE_AND_MODEL_TAB :
    VEHICLE_DETAILS_TAB

  dispatch(changeTab(newTab))
}

export const updateVehicle = ({ vehicleId }) => async (dispatch, getState) => {
  try {
    const { garageResponse: { tireSizes } = {}} = await rest.api.tireSizes({ urlKeys: { vehicleId }})

    dispatch(setVehicleTireSizes(tireSizes))

    dispatch(show(CONFIRM_TIRE_SIZE_MODAL, {
      isSkipEditVehicle: true,
      async handleTiresSelect({
        vehicle,
        loadIndex,
        rearLoadIndex,
        speedRating,
        rearSpeedRating,
        frontTireSize,
        rearTireSize = null
      }) {
        const userVehicleId = getUserVehicleId(getState())

        await rest.api.updateVehicle({
          ...filterVehicleDetails(vehicle),
          userVehicleId,
          loadIndex,
          rearLoadIndex,
          speedRating,
          rearSpeedRating,
          tireFrontSize: frontTireSize,
          tireRearSize: rearTireSize
        })

        const onSuccess = getVehicleUpdateHandler(getState())

        dispatch(onSuccess({ userVehicleId }))

        dispatch(hide(CONFIRM_TIRE_SIZE_MODAL))
      },
      customSizeButtonText: CUSTOM_TIRE_SIZE_TEXT
    }))
  } catch (err) {
    if (isApiError(err)) {
      dispatch(show(TIRE_SIZES_NOT_FOUND_MODAL, {
        continueButtonText: TIRES_NOT_FOUND_BUTTON_TEXT,
        modalText: TIRES_NOT_FOUND_DESCRIPTION
      }))
    }
  }
}


const {
  actions: { submit: validateYmme },
  selectors: { getYmmeTireSizesParams }
} = withYmmeInners

const validateChanges = ({ action, confirmationExplanation }) => (dispatch, getState) => {
  const state = getState()
  const commitAction = () => dispatch(action)
  const currentTab = getCurrentTab(state)

  if (currentTab === VEHICLE_DETAILS_TAB) {
    const formName = VEHICLE_DETAILS_FORM
    const hasChanges = isDirty(formName)(state)

    if (hasChanges) {
      return dispatch(showConfirmationModal({
        confirmationExplanation,
        onCancel: commitAction,
        onUpdate() {
          dispatch(submit(formName))
        }
      }))
    }
  }

  if (currentTab === MAKE_AND_MODEL_TAB && isLicenceFormActive(state)) {
    const formName = VEHICLE_EDITOR_LICENCE_FORM
    const hasChanges = isDirty(formName)(state)

    if (hasChanges) {
      return dispatch(showConfirmationModal({
        confirmationExplanation,
        onCancel: commitAction,
        onUpdate() {
          dispatch(submit(formName))
        }
      }))
    }
  }

  if (currentTab === MAKE_AND_MODEL_TAB) {
    const ymmeName = VEHICLE_EDITOR_YMME
    const hasChanges = isYmmeDirty(state, { ymmeName })

    if (hasChanges) {
      return dispatch(showConfirmationModal({
        confirmationExplanation,
        onCancel: commitAction,
        async onUpdate() {
          await dispatch(validateYmme({ ymmeName }))

          const { vehicleId } = (getYmmeTireSizesParams(state, { ymmeName }) || {})

          dispatch(updateVehicle({ vehicleId }))
        }
      }))
    }
  }

  commitAction()
}

export const handlePositionChange = newTab => (dispatch, getState) => {
  dispatch(changeVehicleUpdateHandler(updateModal))

  dispatch(validateChanges({
    action: changeTab(newTab),
    confirmationExplanation: CHANGE_TABS_ACTION
  }))
}

export const handleModalClose = () => dispatch => {
  dispatch(changeVehicleUpdateHandler(updateGarage))

  dispatch(validateChanges({
    action: closeModal(),
    confirmationExplanation: CLOSE_MODAL_ACTION
  }))
}
