// Kenshoo timezone tracking group CIDs
export const KENSHOO_CIDS = [
  'b93cc2d5-d72f-42c7-8ecc-8e8c953c39ab',
  '326f534c-59b1-4790-81de-756bb7fe462e',
  'e85ac326-4f58-4d55-a4cd-11af56f0b46f',
  'b1634da5-8dbe-4dbe-8346-f7276284d5dc'
]

// Kenshoo server ID
export const KENSHOO_SERVER_ID = '3404'

export const ONE_TRUST_SCRIPT = 'OneTrustScript'

export const KENSHOO_SCRIPT = 'KenshooScript'

export const BING_SCRIPT = 'BingScript'

export const FACEBOOK_SCRIPT = 'FacebookScript'

export const FULL_STORY_SCRIPT = 'FullStoryScript'

export const BAZAAR_SCRIPT = 'BazaarVoiceScript'

export const AUDIO_EYE_SCRIPT = 'AudioEyeScript'

