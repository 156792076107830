import { compose } from 'redux'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import withYmme from 'components/common/hoc/withYmme'
import { getTireSizes } from '../../actions'
import { ShopByVehicleView } from '../ShopByVehicle'

const withHandleSubmit = withHandlers({
  handleCustomSubmit: ({
    onSubmit,
    ymmeTireSizesParams: {
      vehicleId
    } = {},
    getTireSizes,
    tireSizesNotFoundModalProps,
    handleGettingTireSizeSuccess,
    skipTireSizeExperience,
    tiresSelectExtraCallbacks
  }) => () =>
    onSubmit()
      .then((userVehicleId) => {
        !skipTireSizeExperience && getTireSizes({ vehicleId, tireSizesNotFoundModalProps })
        return { userVehicleId, tiresSelectExtraCallbacks }
      })
      .then(props => handleGettingTireSizeSuccess(props))
})

// ShopByVehicleModal which is wrapped in basic HOCs
export default compose(
  connect(null, { getTireSizes }),
  // TODO move name to constants
  withYmme({ name: 'YmmeModal' }),
  withHandleSubmit
)(ShopByVehicleView)

// pure ShopByVehicleModalBase component
export { ShopByVehicleView }
