import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import { generateId } from '../../helpers'

export const LabelComponent = ({
  active,
  disabled,
  isError,
  value,
  ...props
}) =>
  <InputLabel
    { ...props }
    isError={ isError }
    isFilled={ Boolean(value) || active }
    disabled={ disabled }
    htmlFor={ generateId({ ...props }) }>
    { props.label }
  </InputLabel>

LabelComponent.propTypes = {
  active: PropTypes.bool,
  bordered: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  handlers: PropTypes.shape(),
  input: PropTypes.shape(),
  isError: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.node
}

const InputLabel = styled.label`
  position: absolute;
  top: 11px;
  left: 11px;
  color: ${Theme.colors.dark_grey};
  ${Mixin.font.GothamBook(14)}
  ${props => props.isError && css`
    color: ${Theme.colors.bright_red_new};
  `}
  ${props => props.disabled && css`
    color: ${Theme.colors.grey};
  `}
  ${props => props.isFilled && css`
    color: ${Theme.colors.dark_grey};
    top: -11px;
    padding: 2px;
    background-color: ${Theme.colors.white};
    ${Mixin.font.GothamBook(12)}
    ${props => props.isError && css`
      color: ${Theme.colors.bright_red_new};
    `}
  `}
`
