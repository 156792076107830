import { compose, lifecycle, withHandlers, withStateHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import StickyHeaderWrapper from './StickyHeaderWrapper'
import debounce from 'lodash/debounce'
import { getHeightById } from 'src/helpers/utils'
import Theme from 'components/common/Theme/Theme'
import sticky from 'components/common/hoc/withSticky'
import { dispatchPageHeaderChanged } from 'helpers/custom-events'
import { getIsModal } from 'selectors/modal'
import {
  HEADER_ID,
  STICKY_HEADER_COMMON_AREA_ID,
  PAGE_HEADER_CHANGED,
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_MAX_HEIGHT
} from 'constant'
import { isMobile } from 'components/common/Media/helpers'

const withInitialHeaderHeight = withProps(() => ({
  initialHeaderHeight: isMobile() ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_MAX_HEIGHT
}))

const withSetHeaderState = withStateHandlers(({ initialHeaderHeight }) => ({
  dynamicHeight: initialHeaderHeight,
  stickyScrollBoundary: 0
}), {
  setHeaderAttributes: () => ({ dynamicHeight }) => ({
    dynamicHeight,
    stickyScrollBoundary: [...document.querySelectorAll('[data-sticky-hide]')].reduce((arr, { offsetHeight = 0 }) => arr + offsetHeight, 0)
  })
})

const withHandleChange = withHandlers({
  handleHeaderChange: ({ setHeaderAttributes, dynamicHeight, isScrolledToEdge, isModal }) =>
    debounce(() => {
      if (!isModal) {
        const headerContainerNewHeight = getHeightById({ id: HEADER_ID, fallback: 0 })
        const headerStickNewHeight = getHeightById({ id: STICKY_HEADER_COMMON_AREA_ID, fallback: 0 })
        const placeHolderNewHeight = headerContainerNewHeight + headerStickNewHeight
        if (placeHolderNewHeight !== dynamicHeight) {
          setHeaderAttributes({ dynamicHeight: placeHolderNewHeight })
        }
      }
    }, Theme.scrollDebounceTimeout)
})

const withGlobalMessageListeners = lifecycle({
  componentDidMount() {
    const { handleHeaderChange } = this.props
    handleHeaderChange()
    window.addEventListener('resize', handleHeaderChange)
    window.addEventListener(PAGE_HEADER_CHANGED, handleHeaderChange)
  },
  componentDidUpdate({ sticky: prevSticky }) {
    const { handleHeaderChange, sticky } = this.props
    !sticky && handleHeaderChange()
    prevSticky !== sticky && dispatchPageHeaderChanged()
  },
  componentWillUnmount() {
    const { handleHeaderChange } = this.props
    window.removeEventListener('resize', handleHeaderChange)
    window.removeEventListener(PAGE_HEADER_CHANGED, handleHeaderChange)
  }
})

export default compose(
  withInitialHeaderHeight,
  withSetHeaderState,
  sticky,
  connect(state => ({ isModal: getIsModal(state) })),
  withHandleChange,
  withGlobalMessageListeners
)(StickyHeaderWrapper)
