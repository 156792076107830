import { getPageLocation } from 'selectors'
import { QUICK_APPOINTMENT_PAGE, QUOTE_SUMMARY_PAGE } from 'constant'
import { showActiveCartReminderModal } from 'components/ActiveCartReminderModal/actions'

export const showActiveCartCustomerExperienceModal = () => (dispatch, getState) => {
  const location = getPageLocation(getState())
  const isQuickAppointmentPage = location.pathname.startsWith(QUICK_APPOINTMENT_PAGE)
  const isQuotePage = location.pathname.startsWith(QUOTE_SUMMARY_PAGE)
  const { totalCount = 0 } = JSON.parse(localStorage.getItem('SHOPPING_DATA')) || {}
  const wasShown = sessionStorage.getItem('activeCartCustomerExperienceWasShown') === 'true'

  if (!(isQuickAppointmentPage || isQuotePage) && !wasShown) {
    dispatch(showActiveCartReminderModal({ totalCount }))
  }
}
