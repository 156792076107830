import { compose, lifecycle, withState, withProps } from 'recompose'
import isEqual from 'lodash/isEqual'
import { performLazyLoad, addNode, removeNode, processStaticHtmlComponents } from './helpers'
import { LAZY_IMG_PLACEHOLDER, LAZY_LOADED_CLASS } from './constants'

const withLazyPlaceholder = withProps(() => ({
  lazyPlaceholder: LAZY_IMG_PLACEHOLDER
}))

const withImageRef = withState('imgRef', 'setImgRef', null)
const withLoadedProps = withProps(({ imgRef }) => ({
  isLoaded: () => imgRef && imgRef.dataset[LAZY_LOADED_CLASS]
}))

export const imgLazyLoad = compose(
  withImageRef,
  withLoadedProps,
  withLazyPlaceholder,
  lifecycle({
    componentDidUpdate({ imgRef: prevImgRef, src: prevSrc }) {
      const { src, imgRef, noLazyLoad } = this.props
      if (!noLazyLoad && imgRef && !isEqual(imgRef, prevImgRef)) {
        prevImgRef && removeNode(prevImgRef)
        addNode(imgRef)
      }

      if (src !== prevSrc && imgRef) {
        imgRef.dataset[LAZY_LOADED_CLASS] = ''
        performLazyLoad(imgRef)
      }
    },
    componentWillUnmount() {
      const { imgRef, noLazyLoad } = this.props

      if (!noLazyLoad) {
        removeNode(imgRef)
      }
    }
  })
)

// logic for staticHTML
const withContentRef = withState('contentRef', 'setContentRef', null)
const withImageState = withState('images', 'setImages', [])

export const staticHtmlImgLazyLoad = compose(
  withContentRef,
  withImageState,
  lifecycle({
    componentDidUpdate({ contentRef: prevContentRef, content: prevContent }) {
      const { contentRef, images, setImages, content } = this.props

      if (
        (prevContentRef == null && contentRef) ||
        (isEqual(contentRef, prevContentRef) && !isEqual(content, prevContent))
      ) {
        processStaticHtmlComponents({ images, setImages, contentRef })
      }
    },
    componentWillUnmount() {
      const { images } = this.props
      images.forEach(img => img && removeNode(img))
    }
  })
)
