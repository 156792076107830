import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Button from 'components/common/Button/Button'
import Mixin from 'components/common/Mixin'
import { MAKE_AN_APPOINTMENT_BUTTON_ID } from 'constant'
import MenuItemWrapper from '../MenuItemWrapper'

const MakeAnAppointmentWrapper = ({
  name,
  mobileMainMenuExpanded,
  mobileMainMenuToggleHandlers,
  active,
  setInnerRef,
  stopPropagation,
  toggleHandlers,
  placeholders: { menus = []} = {},
  setFocusRef,
  setIsHovering,
  isHovering
}) =>
  <React.Fragment>
    <Media.Desktop>
      <MenuWrapper active={ active } ref={ setInnerRef } data-at-header-makeappointment>
        <ApptButton
          id={ MAKE_AN_APPOINTMENT_BUTTON_ID }
          ref={ setFocusRef }
          menu
          onMouseEnter={ () => setIsHovering(true) }
          onMouseLeave={ () => setIsHovering(false) }
          { ...toggleHandlers }>
          <Icon
            alt="Calendar"
            asset={ `${isHovering ? 'book-appointment-chartreuse' : 'book-appointment-black'}` }
            width="30px"
            margin="-5px 10px 0 0" />
          <Title>{ name }</Title>
        </ApptButton>
        <MyStoreWrapper hidden={ !active } { ...stopPropagation }>
          { menus }
        </MyStoreWrapper>
      </MenuWrapper>
    </Media.Desktop>
    <Media.Mobile>
      <MenuItemWrapper
        mobileMainMenuExpanded={ mobileMainMenuExpanded }
        mobileMainMenuToggleHandlers={ mobileMainMenuToggleHandlers }
        links={ menus }
        name={ name } />
    </Media.Mobile>
  </React.Fragment>

MakeAnAppointmentWrapper.propTypes = {
  active: PropTypes.bool,
  isHovering: PropTypes.string,
  mobileMainMenuExpanded: PropTypes.bool,
  mobileMainMenuToggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.node)
  }),
  setFocusRef: PropTypes.func,
  setInnerRef: PropTypes.func,
  setIsHovering: PropTypes.func,
  stopPropagation: PropTypes.shape({
    onClick: PropTypes.func
  }),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

export default MakeAnAppointmentWrapper


const MenuWrapper = styled.div`
  position: relative;
  z-index: 20;
  margin-right: 7px;
  top: -4px;
  ${props => props.active && css`
    box-shadow: 0 0 8px ${Theme.colors.transparent_grey_hover};

    ::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
      background: ${Theme.colors.white};
      z-index: 10;
    }
  `}
`

const ApptButton = styled(Button)`
  padding: 24px 29px 20px 29px;
  margin: 0 0 -8px 0;
  position: relative;
  z-index: 20;
  border: none;
  background: ${Theme.colors.chartreuse};
  color: ${Theme.colors.black_new};
  text-transform: uppercase;
  transform: none;
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: ${Theme.colors.chartreuse};
    background: ${Theme.colors.black_new};
    border: none;
  }
`

const Title = styled.span`
  display: inline-block;
  ${Mixin.font.GothamBold(16)}
  transform: scale(1, 1.3);
  letter-spacing: 0.03em;
`

const MyStoreWrapper = styled.div`
  position: absolute;
  top: 115%;
  right: 0;
  z-index: 10;
  width: auto;
  padding: 20px 0;
  margin-top: -1px;
  background: ${Theme.colors.white};
  box-shadow: 0 0 10px ${Theme.colors.transparent_grey_hover};
  border-top: 4px solid ${Theme.colors.chartreuse};
`
