import {
  googleAnalyticProductAddToCart,
  googleAnalyticPurchase
} from 'actions/googleAnalytic'
import { applyNowCTAClick, phoneLinkClick } from 'actions/thirdParty'
import { validateAddToCartItem } from './helpers'

export default store => next => action => {
  try {
    window.uetq = window.uetq || []
    if (action.type === String(googleAnalyticProductAddToCart)) {
      const { product: { skuId = '' } = {}} = action.payload
      if (skuId) {
        validateAddToCartItem(skuId) ? window.uetq.push({ ec: 'Tire-Search-Results', ea: 'Add-to-Quote', el: '', ev: '0' }) :
          window.uetq.push({ ec: 'Services-Pages', ea: 'Add-to-Quote', el: '', ev: '0' })
      }
    }

    if (action.type === String(googleAnalyticPurchase)) {
      window.uetq.push({ ec: 'Single Page Appointment', ea: 'Submit Order', el: '', ev: '0' })
    }

    if (action.type === String(phoneLinkClick)) {
      window.uetq.push({ ec: 'Click to Call', ea: 'Click to Call', el: '', ev: '0' })
    }

    if (action.type === String(applyNowCTAClick)) {
      window.uetq.push({ ec: 'Credit-Card', ea: 'Apply-Today', el: '', ev: '0' })
    }
  } catch (err) {
    console.log('Error occured during Google Analytic event dispatch', err)
  }

  return next(action)
}
