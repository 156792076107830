import { radiuses } from './constants'
import { parseSearchStr } from 'helpers/location'

export const storesFoundFor = (results, searchMask) =>
  `${results} store${results > 1 ? 's' : ''} found for "${searchMask}"`


export const storesFoundNearYou = (results) =>
  `${results} stores found near your location`

export const storesFromApiResponse = ({ storesType: { stores = []} = {}}) => stores

export const apiSearchErrorToObject = ({
  status: { code, description },
  errorPayload: {
    storesType: {
      storeBrands = []
    } = {}
  } = { }
}) => ({ code, description, brands: storeBrands.filter(({ brandName, logoPath }) => brandName && logoPath) })

export const distanceToSearchRadius = distance => {
  const roundedValue = Math.round(distance)
  const minRange = radiuses[0]
  const maxRange = radiuses[radiuses.length - 1]

  return roundedValue > maxRange ? maxRange :
    roundedValue < minRange ? minRange : roundedValue
}

export const isFromInvalidStorePage = ({ search } = {}) =>
  search && parseSearchStr({ search }).fromStore === 'invalid'
