export default [
  'BOTBrandMarketingSpotligh',
  'BOTBrandRefinementMenu',
  'BOTCommonBrandTireResultList',
  'BOTNonCompatibleBrandRefinementMenu',
  'BOTPromotionsLink',
  'BrandBreadcrumbs',
  'BrandDetail',
  'BrandDetailAndMarketingSpotligh',
  'BrandGuidedNavigation',
  'BrandLines',
  'BrandRefinementMenu',
  'OneColumnBrandPage'
]

/*
Appears on:
/tires/brand/michelin/29400024,
/tires/brands
*/
