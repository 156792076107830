import { lifecycle, compose } from 'recompose'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { bazaarCheck } from './helpers'
import { setBazaar } from 'components/common/hoc/with3rdPartyData/actions'
import { getBazaar } from 'selectors/thirdPartyData'

const withBazaarVoiceLifecyle = lifecycle({
  componentDidMount() {
    const { bazaar, setBazaar, location: { pathname = '' } = {}} = this.props
    !bazaar && setBazaar({ bazaar: bazaarCheck(pathname) })
  },
  componentDidUpdate({ location: { pathname: prevPathname = '' } = {}}) {
    const { bazaar: currentBazaar, setBazaar, location: { pathname = '' } = {}} = this.props
    !isEqual(pathname, prevPathname) && !currentBazaar && setBazaar({ bazaar: bazaarCheck(pathname) })
  }
})

export default compose(
  connect(state => ({ bazaar: getBazaar(state) }), { setBazaar }),
  withBazaarVoiceLifecyle
)
