import { createSelector } from 'helpers/reselect'

// selector
const getUserName = ({
  app: {
    data: {
      userdata: {
        userName
      } = {}
    } = {}
  } = {}
} = {}) => userName

export default createSelector(
  [getUserName],
  userName => userName
)
