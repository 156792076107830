import { RIDE_STYLER_API_KEY } from 'constant'
import {
  RENDER_VEHICLE_URL,
  KEY,
  VEHICLE,
  VIEW,
  HEIGHT,
  WIDTH,
  COLOR,
  POSITION_X,
  POSITION_Y,
  V_ALIGNMENT,
  H_ALIGNMENT,
  SEARCH_OPTIONS,
  EXACT_MATCH,
  CLOSEST_MATCH
} from './constants'

const append = ({ key, value }) =>
  value ? `${key}=${encodeURIComponent(value.toString().trim())}` : ''

export const getVAlignment = (value) => V_ALIGNMENT.indexOf(value)

export const getHAlignment = (value) => H_ALIGNMENT.indexOf(value)

export const extractProps = (props) => ({
  year: props.year,
  make: props.make,
  model: props.model,
  submodel: props.submodel,
  color: props.color,
  vAlign: props.vAlign,
  hAlign: props.hAlign,
  height: props.height,
  width: props.width,
  mobileHeight: props.mobileHeight,
  mobileWidth: props.mobileWidth
})

export const generateUrl = ({
  make,
  year,
  model,
  submodel,
  view,
  width,
  height,
  color,
  vAlign,
  hAlign,
  exactMatch
}) => {
  const urlParams = [
    {
      key: KEY,
      value: RIDE_STYLER_API_KEY
    },
    {
      key: VEHICLE,
      value: `${year} ${make} ${model} ${submodel}`
    },
    {
      key: SEARCH_OPTIONS,
      value: exactMatch ? EXACT_MATCH : CLOSEST_MATCH
    },
    {
      key: VIEW,
      value: view
    },
    {
      key: HEIGHT,
      value: height
    },
    {
      key: WIDTH,
      value: width
    },
    {
      key: COLOR,
      value: color
    },
    {
      key: POSITION_X,
      value: getHAlignment(hAlign)
    },
    {
      key: POSITION_Y,
      value: getVAlignment(vAlign)
    }
  ]
  const formattedParams = urlParams.map(item => append(item)).filter(item => item.length).join('&')
  return `${RENDER_VEHICLE_URL}?${formattedParams}`
}
