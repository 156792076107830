import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { getQuoteTotalCount } from 'selectors/shoppingData'
import withType from 'components/common/hoc/withType'
import { QUOTE_SUMMARY_PAGE } from 'constant'
import HeaderCart from './HeaderCart'
import withMyFavoritesCounter from 'components/MyFavorites/withFavoritesCounter'
import { showActiveCartCustomerExperienceModal } from 'actions/showActiveCartCustomerExperience'
import { getPageLocation, getHasActiveVehicleStatus } from 'selectors'
import { getIsUserAuthorized } from '../../MyFavorites/selectors'
import { checkIsAbandonedCart } from 'helpers/check-abandoned-cart'

const mapStateToProps = state => ({
  quoteTotalCount: getQuoteTotalCount(state),
  quoteURL: QUOTE_SUMMARY_PAGE,
  pageLocation: getPageLocation(state),
  isAuthorized: getIsUserAuthorized(state),
  hasActiveVehicle: getHasActiveVehicleStatus(state)
})

const withLifecycle = lifecycle({
  componentDidMount() {
    const { quoteTotalCount, isAuthorized, showActiveCartCustomerExperienceModal } = this.props
    const activeCartCustomerExperienceWasShown = sessionStorage.getItem('activeCartCustomerExperienceWasShown')

    if (isAuthorized && Number(quoteTotalCount) && !activeCartCustomerExperienceWasShown) {
      showActiveCartCustomerExperienceModal()
      sessionStorage.setItem('activeCartCustomerExperienceWasShown', 'true')
    }
  },
  componentDidUpdate({ isAuthorized: prevIsAuthorized }) {
    const { showActiveCartCustomerExperienceModal, quoteTotalCount, isAuthorized } = this.props

    if (checkIsAbandonedCart() && (!Number(quoteTotalCount) || quoteTotalCount === '0')) {
      sessionStorage.setItem('activeCartCustomerExperienceWasShown', 'true')
    }

    if (checkIsAbandonedCart() && Number(quoteTotalCount)) {
      showActiveCartCustomerExperienceModal()
    }

    if (isAuthorized !== prevIsAuthorized && isAuthorized && Number(quoteTotalCount)) {
      showActiveCartCustomerExperienceModal()
    }
  }
})

export default compose(
  withType({ type: 'HeaderCart' }),
  connect(mapStateToProps, { showActiveCartCustomerExperienceModal }),
  withMyFavoritesCounter,
  withLifecycle
)(HeaderCart)
