import { css } from 'styled-components'
import { HIGH_CONTRAST_CLASS_NAME } from '../constants'

export const highContrast = (...args) => css`
  /* stylelint-disable unit-no-unknown, number-leading-zero  */
  .${HIGH_CONTRAST_CLASS_NAME} & {
    ${css(...args)}
  }
  /* stylelint-enable  */
`
