import { handleActions } from 'redux-actions'
import { setSkuInformation } from './actions'

const initialState = {
  skuInformation: {}
}

const findLowerPrice = handleActions({
  [setSkuInformation]: (state, { payload: { skuInformation }}) => ({
    skuInformation
  })
}, initialState)

export default findLowerPrice
