import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import Link, { A } from 'components/common/Link/Link'
import Button from 'components/common/Button/Button'
import Text from 'components/common/Text/Text'
import { formatPhoneNumber } from 'helpers/phone-number'
import * as DD from '../../../components/HeaderDropdown/HeaderDropdown'
import GoogleMapImage from '../../../GoogleMapImage'
import OrSeparator from 'components/common/OrSeparator/OrSeparator'

const SelectedMyStore = ({
  storeDetails: {
    store: {
      storeComingOpenHours,
      storeComingClosedHours,
      tomorrowStoreComingOpenHours,
      tomorrowStoreComingClosedHours,
      phoneNumbers,
      mapCenter: {
        latitude,
        longitude
      } = {},
      appointmentEnable,
      address: {
        address1,
        city,
        state,
        zipcode
      } = {},
      storeDetailsUrl
    } = {}
  } = {},
  rightLink = {},
  leftLink = {},
  active,
  combinedRefSetter,
  combinedHandlers,
  handleOpenMakeAnAppointment,
  storeOpenToday,
  storeOpenTomorrow,
  storeSpecialHoursToday,
  storeSpecialHoursTomorrow,
  stopPropagation,
  handlePhoneLinkClick,
  wrapperID,
  appointmnetBtnTitle,
  storePhoneNumber,
  hideCallStore
}) => {
  const MAP_LINK = `https://www.google.com/maps/?q=${address1},${city},${state},${zipcode}`

  const Title =
    <TitleWrapper
      tabIndex="0"
      active={ active }
      { ...combinedHandlers }
      data-noseparator
      data-at-dropdown-focus >
      <Media.Desktop><Location asset="location-white" alt="" width="10" height="14" margin="-2px 7px 0 0" data-static /></Media.Desktop>
      <Media.Mobile><Location asset="location" alt="" width="10" height="14" margin="-2px 7px 0 0" data-static /></Media.Mobile>
      <MyStoreTitle size="12" >My Store:
        <StoreInfo>
          { address1 }, { city }, { state }, { zipcode }
        </StoreInfo>
      </MyStoreTitle>
      <Media.Desktop>
        <ArrowIcon
          asset="arrow-icon-full-white-down"
          alternative="arrow-icon-full-white-up"
          condition={ active }
          width="8"
          height="4"
          data-static
          alt="" />
      </Media.Desktop>
      <Media.Mobile>
        <ArrowIcon
          asset="arrow-icon-full-black-down"
          alternative="arrow-icon-full-black-up"
          condition={ active }
          width="8"
          height="4"
          data-static
          alt="" />
      </Media.Mobile>
    </TitleWrapper>

  const MyStoreWrapper =
    <StoreWrapper left { ...stopPropagation }>
      <A { ...combinedHandlers } href={ MAP_LINK } target="_blank">
        <GoogleMapImage address1={ address1 } city={ city } state={ state } zipcode={ zipcode } />
      </A>
      <ContactInfo>
        <ContactColumn>
          <Text data-at-store-title block bolder>Find Us At</Text>
          <A
            data-at-my-store-find-address
            target="_blank"
            href={ MAP_LINK }><Text block bolder>{ address1 }, { city }, { state }, { zipcode }</Text></A>
        </ContactColumn>
        <ContactColumn>
          { storeOpenToday ?
            <Time hidden={ !storeComingOpenHours || !storeComingClosedHours }>
              <Text block bolder>Today&apos;s Hours</Text>
              <div>
                {
                  storeSpecialHoursToday || `${storeComingOpenHours} - ${storeComingClosedHours}`
                }
              </div>
            </Time> :
            <Time>
              <Text block bolder>Closed Today</Text>
            </Time>
          }
          { storeOpenTomorrow ?
            <div hidden={ !tomorrowStoreComingOpenHours || !tomorrowStoreComingClosedHours }>
              <Text block bolder>Tomorrow&apos;s Hours</Text>
              <div>
                {
                  storeSpecialHoursTomorrow || `${tomorrowStoreComingOpenHours} - ${tomorrowStoreComingClosedHours}`
                }
              </div>
            </div> :
            <div>
              <Text block bolder>Closed Tomorrow</Text>
            </div>
          }
        </ContactColumn>
      </ContactInfo>
      { appointmentEnable &&
        <StoreButton
          data-at-store-make-an-appointment-btn
          full
          narrow
          onClick={ handleOpenMakeAnAppointment }>{ appointmnetBtnTitle }</StoreButton>
      }
      <OrSeparator />
      <CallStoreText>
        Call store to get help or schedule appointment.
      </CallStoreText>
      <Media.Desktop>
        <PhoneButton aria-label={ `Call store ${formatPhoneNumber(storePhoneNumber)}` }>
          <Icon asset="phone-red" height={ 13 } width={ 13 } />
          <PhoneRed>{ formatPhoneNumber(storePhoneNumber) }</PhoneRed>
        </PhoneButton>
      </Media.Desktop>
      <Media.Mobile>
        <PhoneButtonLink href={ `tel:+1${storePhoneNumber}` }>
          <Icon asset="phone-red" height={ 13 } width={ 13 } />
          <PhoneRed>{ formatPhoneNumber(storePhoneNumber) }</PhoneRed>
        </PhoneButtonLink>
      </Media.Mobile>
      <LinkWrapper appointmentEnable={ appointmentEnable }>
        <Link data-at-store-details-link to={ storeDetailsUrl }>View Store Details</Link>
        <Link data-at-change-store-link to={ rightLink.path }>Change Store</Link>
      </LinkWrapper>
    </StoreWrapper>

  return (
    <>
      {!hideCallStore && (
        <Media.Desktop>
          <PhoneWrapper>
            <Icon asset="phone-white" height={ 13 } width={ 13 } />
            <PhoneNumberWrapper>Call store:
              <PhoneNumber>{ formatPhoneNumber(storePhoneNumber) }</PhoneNumber>
            </PhoneNumberWrapper>
          </PhoneWrapper>
        </Media.Desktop>
      )}

      <DDWrapper
        id={ wrapperID }
        active={ active }
        ref={ combinedRefSetter }
        data-at-header-mystore>
        <b>{ Title }</b>
        {!hideCallStore && (
          <Media.Mobile>
            <PhoneWrapperMobile>
              <Icon asset="phone-black" height={ 13 } width={ 13 } />
              <PhoneNumberWrapper>Call store:
                <PhoneNumberLink href={ `tel:+1${storePhoneNumber}` }>{ formatPhoneNumber(storePhoneNumber) }</PhoneNumberLink>
              </PhoneNumberWrapper>
            </PhoneWrapperMobile>
          </Media.Mobile>
        )}

        { active && MyStoreWrapper }
      </DDWrapper>
    </>

  )
}

SelectedMyStore.propTypes = {
  active: PropTypes.bool,
  address1: PropTypes.string,
  appointmnetBtnTitle: PropTypes.string,
  city: PropTypes.string,
  combinedHandlers: PropTypes.shape(),
  combinedRefSetter: PropTypes.func,
  expanded: PropTypes.bool,
  focusBlurHoverHandlers: PropTypes.shape(),
  handleOpenMakeAnAppointment: PropTypes.func,
  handlePhoneLinkClick: PropTypes.func,
  hideCallStore: PropTypes.bool,
  leftLink: PropTypes.shape({}),
  phoneNumbers: PropTypes.arrayOf(PropTypes.string),
  rightLink: PropTypes.shape(),
  state: PropTypes.string,
  stopPropagation: PropTypes.shape(),
  storeComingClosedHours: PropTypes.string,
  storeComingOpenHours: PropTypes.string,
  storeDetails: PropTypes.shape(),
  storeDetailsUrl: PropTypes.string,
  storeOpenToday: PropTypes.bool,
  storeOpenTomorrow: PropTypes.bool,
  storePhoneNumber: PropTypes.string,
  storeSpecialHoursToday: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  storeSpecialHoursTomorrow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  }),
  tomorrowStoreComingClosedHours: PropTypes.string,
  tomorrowStoreComingOpenHours: PropTypes.string,
  wrapperID: PropTypes.string,
  zipcode: PropTypes.string
}

export default SelectedMyStore

const TitleWrapper = styled(DD.Title)`
  ${Media.desktop`
    z-index: 1;
  `}
  ${Media.mobile`
    border-top: 1px solid ${Theme.colors.light_grey};
    position: relative;
    padding: 17px 35px 14px 35px;
    &:hover, &:focus {
      border-top: 1px solid ${Theme.colors.light_grey};
    }
  `}
`

const MyStoreTitle = styled(Text)`
  display: inline-block;
  vertical-align: bottom;
  ${Mixin.responsive('width', '150px', '100%')}
  ${Mixin.ellipsis()}
  ${Media.mobile`
    color: ${Theme.colors.black_new};
  `}
`

const StoreWrapper = styled(DD.Content)`
  padding: 16px;
  ${Media.desktop`
    width: 320px;
  `}
  ${Media.mobile`
    width: 'auto';
  `}
  ${Media.mobileNarrow`
    height: 220px;
    overflow-y: auto;
  `}
`

const ContactInfo = styled.div`
  display: table;
  width: 100%;
  margin-top: 20px;
  line-height: 20px;
`

const ContactColumn = styled.div`
  display: table-cell;
  width: 50%;
  vertical-align: top;

  & + & {
    padding-left: 9px;
  }
`

const LinkWrapper = styled.div`
  text-align: center;
  ${Mixin.responsive('margin-bottom', '14px', '0')}
  ${props => !props.appointmentEnable && css`
    margin-top: 20px;
  `}

  & ${Link} {
    position: relative;
    ${Mixin.font.GothamBold(12)}
  }

  & ${Link} + ${Link} {
    margin-left: 20px;

    ::before {
      position: absolute;
      top: 3px;
      left: -11px;
      width: 1px;
      height: 12px;
      background: ${Theme.colors.light_grey};
      content: '';
    }
  }
`

const StoreButton = styled(Button)`
  margin: 24px 0 0;
`

const Time = styled.div`
  margin-bottom: 20px;
`

const Location = styled(Icon)`
  ${Media.mobile`
    position: absolute;
    top: 20px;
    left: 16px;
  `}
`

const ArrowIcon = styled(Icon)`
  ${Media.mobile`
    position: absolute;
    top: 24px;
    right: 16px;
  `}
`

const DDWrapper = styled(DD.Wrapper)`
  background: ${Theme.colors.white};
`

const StoreInfo = styled.span`
  margin: 0 0 0 5px;
  font-size: 12px;
  ${Mixin.font.GothamBold()}
`

const PhoneWrapper = styled.button`
  display: flex;
  align-items: center;
  background: ${Theme.colors.crimson};
  border: none;

  @media (max-width: 1380px) {
    display: none;
  }
`

const PhoneNumberWrapper = styled.p`
  display: flex;
  align-items: center;
  margin: 0 0 0 5px;
  font-size: 12px;
  color: ${Theme.colors.white};
  ${Media.mobile`
    color: ${Theme.colors.black_new};
    align-items: end;
  `}
`

const PhoneNumber = styled.p`
  margin: 0 0 0 3px;
  ${Mixin.font.GothamBold()}
  font-size: 12px;
  color: ${Theme.colors.white};
`

const CallStoreText = styled.p`
  margin: 0 0 15px;
  text-align: center;
  font-size: 12px;
`

const PhoneButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  margin: 0 0 15px;
  text-align: center;
  border: 2px solid ${Theme.colors.bright_red_new};
  background: ${Theme.colors.white};
`

const PhoneRed = styled.p`
  margin: 0 0 0 5px;
  color: ${Theme.colors.bright_red_new};
`

const PhoneNumberLink = styled.a`
  margin: 0 0 0 3px;
  text-decoration: underline;
  color: #1466ae;
`

const PhoneWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin: 0 0 0 16px;
  font-size: 12px;
  line-height: 12px;
  background: ${Theme.colors.white};
`

const PhoneButtonLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  margin: 0 0 15px;
  text-decoration: none;
  text-align: center;
  border: 2px solid ${Theme.colors.red};
`
