import { connect } from 'react-redux'
import { lifecycle, compose } from 'recompose'
import registerModalPage from 'components/common/hoc/registerModalPage'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { GLOBAL_SEARCH_MODAL } from 'constant'
import { getPageLocation } from 'selectors'
import { getBazaar } from 'selectors/thirdPartyData'
import { setBazaar } from 'components/common/hoc/with3rdPartyData/actions'
import { resetModal } from '../../actions'
import { getModalData, getSearchAutoSuggestions } from '../../selectors'
import GlobalSearchModal from './GlobalSearchModal'

const pageSrc = '/modals/global-search'

const withLifecycle = lifecycle({
  componentDidMount() {
    const { bazaar, setBazaar } = this.props
    !bazaar && setBazaar({ bazaar: true })
  },
  componentDidUpdate({ location }) {
    if (this.props.location !== location) {
      this.props.handleHide()
    }
  },

  componentWillUnmount() {
    this.props.resetModal()
  }
})

const mapStateToProps = state => {
  const { title } = getModalData(state)

  return {
    title,
    location: getPageLocation(state),
    autoSuggestions: getSearchAutoSuggestions(state),
    bazaar: getBazaar(state)
  }
}

export default compose(
  registerReduxModal({ name: GLOBAL_SEARCH_MODAL }),
  registerModalPage({
    pageSrc,
    rootType: MODAL_BOX_TYPE
  }),
  connect(mapStateToProps, { resetModal, setBazaar }),
  withLifecycle
)(GlobalSearchModal)
