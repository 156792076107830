import React from 'react'
import PropTypes from 'prop-types'
import TitleWrapper from '../NewTireSearch/components/TitleWrapper'
import Stepper from '../NewTireSearch/components/Stepper'
import Search from '../NewTireSearch/components/Search'
import OptionsList from '../NewTireSearch/components/OptionsList'
import { CustomUpdatingBlock } from '../NewTireSearch/SharedStyles'
import { OPTIONS_WRAPPER_CLASS_NAME, YMME_STEP_NAMES, YMME_TITLE } from '../NewTireSearch/constant'
import Banner from 'components/common/Banner/Banner'
import MobileFixedWrapper from '../NewTireSearch/components/MobileFixedWrapper'

const NewYMME = ({
  handleHide,
  step,
  endpointData = [],
  dataLoading,
  ymmeError,
  ...props
}) =>
  <>
    <MobileFixedWrapper place={ YMME_TITLE }>
      <TitleWrapper title={ YMME_TITLE } />
      <Stepper
        step={ step }
        stepNames={ YMME_STEP_NAMES }
        place={ YMME_TITLE }
        dataLoading={ dataLoading }
        { ...props } />
      <Search
        stepNames={ YMME_STEP_NAMES }
        step={ step }
        { ...props } />
    </MobileFixedWrapper>
    { ymmeError
      ? <Banner data-at-service-rep-assist-number>{ ymmeError }</Banner>
      : (
        <CustomUpdatingBlock className={ OPTIONS_WRAPPER_CLASS_NAME } condition={ dataLoading }>
          { Boolean(endpointData.length) && (
            <OptionsList
              stepNames={ YMME_STEP_NAMES }
              place={ YMME_TITLE }
              list={ endpointData }
              step={ step }
              { ...props } />
          )}
        </CustomUpdatingBlock>
      )
    }
  </>

export default NewYMME

NewYMME.propTypes = {
  dataLoading: PropTypes.bool,
  endpointData: PropTypes.arrayOf(PropTypes.shape()),
  handleHide: PropTypes.func,
  step: PropTypes.number,
  ymmeError: PropTypes.string
}
