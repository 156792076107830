import { focusCurrentItem } from 'components/common/hoc/withHeaderHorizontalNav/helpers'

export const focusSubmenuItem = ({ e, index }) => {
  const subNavItem = e.currentTarget.querySelector(`[data-at-headerdropdown-item="${index}"]`)
  if (subNavItem) {
    setTimeout(() => {
      subNavItem.focus()
    })
  }
}

export const focusLastSubmenuItem = ({ e, prevItem, deactivate }) => {
  const navItem = e.currentTarget.parentElement.querySelector(`#${prevItem}`)
  const subNavItem = navItem && navItem.querySelector('li:last-of-type > a')
  if (subNavItem) {
    const navItemBtn = navItem.querySelector('[data-at-dropdown-title]')
    setTimeout(() => navItemBtn.click())
    setTimeout(() => subNavItem.focus(), 100)
  } else {
    focusCurrentItem({ e })
    deactivate && deactivate()
  }
}
