import React from 'react'
import PropTypes from 'prop-types'
import { withStateHandlers, compose, lifecycle } from 'recompose'

const withOuterClickDetection = compose(
  withStateHandlers(
    { wrapperRef: null },
    {
      setComponentRef: () => component => ({ wrapperRef: component }),

      handleOutsideClick: ({ wrapperRef }, { onOutsideClick }) => event => {
        if (wrapperRef && !wrapperRef.contains(event.target)) {
          onOutsideClick(event)
        }
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      document.addEventListener('click', this.props.handleOutsideClick)
    },

    componentWillUnmount() {
      document.removeEventListener('click', this.props.handleOutsideClick)
    }
  })
)

export default WrappedComponent => {
  const WrapperComponent = ({ children, setComponentRef, wrapperRef, ...props }) =>
    <div ref={ setComponentRef }>
      <WrappedComponent { ...props } />
    </div>

  WrapperComponent.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    setComponentRef: PropTypes.func,
    wrapperRef: PropTypes.node
  }

  return withOuterClickDetection(WrapperComponent)
}
