import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import withType from 'components/common/hoc/withType'
import registerModalPage from '../../../common/hoc/registerModalPage'
import FindServiceHistoryModal from './FindServiceHistoryModal'
import { FIND_SERVICE_HISTORY_MODAL, FIND_SERVICE_HISTORY_PAGE_SRC } from './constants'
import { findServiceHistory, hideFindServiceHistoryModal } from './actions'
import { submitFindServiceHistoryForm } from './components/FindServiceHistoryForm/actions'
import { isSubmitBtnDisabled } from './selectors'

const withOnSubmit = withHandlers({
  handleSubmit: ({ userVehicleId, findServiceHistory }) => values =>
    findServiceHistory({ ...values, userVehicleId })
})

const mapStateToProps = state => ({
  isSubmitBtnDisabled: isSubmitBtnDisabled(state)
})

export default compose(
  withType({ type: 'BOTFindServiceHistory' }),
  connect(mapStateToProps, { findServiceHistory, submitFindServiceHistoryForm, hideFindServiceHistoryModal }),
  registerReduxModal({ name: FIND_SERVICE_HISTORY_MODAL }),
  registerModalPage({ pageSrc: FIND_SERVICE_HISTORY_PAGE_SRC, rootType: 'BOTFindServiceHistory' }),
  withOnSubmit
)(FindServiceHistoryModal)
