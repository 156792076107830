import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Link from 'components/common/Link/Link'
import { getFullYear, dynamicDataAttribute, injectProps, setLinkDataAttrVal } from 'helpers/utils'
import { PRIVACY_WEB_FORM_LINK_TEXT, PRIVACY_WEB_FORM_URL } from './constants'

const FooterLegalLinks = ({ placeholders: { links = []} = {}, ...props }) =>
  <React.Fragment>
    <Reserved>&copy; {getFullYear()} Big O Tires. All Rights Reserved.</Reserved>
    <PrivacyLinks data-at-footer-legallinks>
      {
        React.Children.map(links, link =>
          <Fragment>
            { injectProps(link, { ...dynamicDataAttribute('footer-legal-link', setLinkDataAttrVal(link)) }) }
          </Fragment>
        )
      }
      {
        <PrivacyWebFormLink
          to={ PRIVACY_WEB_FORM_URL }
          target="_blank"
          rel="noopener noreferrer" >
          { PRIVACY_WEB_FORM_LINK_TEXT }
        </PrivacyWebFormLink>
      }
    </PrivacyLinks>
  </React.Fragment>

FooterLegalLinks.propTypes = {
  '@type': PropTypes.string,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    links: PropTypes.arrayOf()
  })
}

FooterLegalLinks.type = 'FooterLegalLinks'
FooterLegalLinks.placeholders = ['links']

export default FooterLegalLinks


const Reserved = styled.span`
  display: inline-block;
  color: ${Theme.colors.white};
  ${Mixin.font.GothamBold(12)}
  ${Mixin.responsive('margin-bottom', '0', '22px')}
`

const PrivacyWebFormLink = styled(Link)`
  border: 0 !important;
  padding: 0 !important;
  white-space: nowrap !important;
  color: ${Theme.colors.white} !important;
  font-size: 12px !important;

  ::before {
    top: 1px !important;
  }

  &:hover {
    text-decoration: underline !important;
    background: initial !important;
    color: ${Theme.colors.chartreuse} !important;
  }
`

const PrivacyLinks = styled.div`
  ${Media.desktop`
    float: right;
    white-space: nowrap;
  `}

  & ${Link},
  ${PrivacyWebFormLink} {
    position: relative;
    color: ${Theme.colors.white};
    ${Mixin.font.GothamBook(12)}

    &:hover {
      color: ${Theme.colors.chartreuse};
    }
  }

  & ${Link} + ${Link},
  ${Link} + ${PrivacyWebFormLink} {
    margin-left: 20px;

    ::before {
      position: absolute;
      top: 3px;
      left: -11px;
      width: 1px;
      height: 12px;
      background: ${Theme.colors.dark_grey};
      content: '';
    }
  }
`
