import { compose, withStateHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import registerModalPage from 'components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { EMAIL_SIGN_UP_MODAL, TITLE_SIGN_UP, TITLE_THANK_YOU } from './constants'
import EmailSignUpModal from './EmailSignUpModal'

const pageSrc = '/modals/email-signup'

const withTitleChange = withStateHandlers(
  {
    modalWindowTitle: TITLE_SIGN_UP
  },
  {
    setModalWindowTitleSucceed: () => (title = TITLE_THANK_YOU) => ({
      modalWindowTitle: title
    }),
    setModalWindowTitle: () => (title = TITLE_SIGN_UP) => ({
      modalWindowTitle: title
    })
  })

export default compose(
  registerReduxModal({ name: EMAIL_SIGN_UP_MODAL }),
  registerModalPage({
    rootType: MODAL_BOX_TYPE,
    pageSrc: ({ modalInitialData: { email } = {}}) => `${pageSrc}?email=${email}`
  }),
  withTitleChange
)(EmailSignUpModal)

export {
  EMAIL_SIGN_UP_MODAL
}
