export default [
  'OneColumnVehicleDetailsPage',
  'BOTTireRotation',
  'BOTOilChange',
  'BOTRecommendedServices'
]

/*
Appears on:
my-account/vehicle-details,
*/
