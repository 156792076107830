import qs from 'qs'
import { show, hide } from 'redux-modal'
import { push } from 'connected-react-router'
import { rest } from 'services'
import { refresh } from './router'
import { navigateToSearchByVehicle } from 'actions/shopByVehicle'
import { getPageLocation } from 'selectors'
import { isSearchByVehicleUrl, isSearchByTrailer } from 'helpers/location'
import { triggerExtraTiresSelectCallbacks } from 'helpers/ymme'
import filterVehicleDetails from 'helpers/filterVehicleDetails'
import {
  CONFIRM_TIRE_SIZE_MODAL,
  ADD_VEHICLE_MODAL,
  TRAILER_TYPE,
  BRAND_DETAILS_PAGE,
  BRAND_COMPATIBILITY_QUERY_NAME,
  BRAND_COMPATIBILITY_COMPATIBLE,
  PERSONALIZE_VEHICLE_MODAL,
  GARAGE_PAGE
} from 'constant'

export const addVehicle = vehicleDetails => async dispatch =>
  rest.api.addVehicle(filterVehicleDetails(vehicleDetails))

export const updateVehicle = vehicleDetails => async dispatch =>
  rest.api.updateVehicle(filterVehicleDetails(vehicleDetails))

export const addGenericVehicle = payload => dispatch =>
  rest.api.addGenericVehicle(payload)

export const addTrailer = trailerDetails => dispatch =>
  dispatch(addGenericVehicle({ ...trailerDetails, userVehicleType: TRAILER_TYPE }))

const updateBrandDetailsPage = ({ location, search }) => push({
  ...location,
  search: qs.stringify({ ...search, [BRAND_COMPATIBILITY_QUERY_NAME]: BRAND_COMPATIBILITY_COMPATIBLE })
})

const updatePage = ({ vehicle, frontTireSize, rearTireSize }) => (dispatch, getState) => {
  const location = getPageLocation(getState())
  const search = qs.parse(location.search.slice(1))
  const isBrandDetailsPage = location.pathname.startsWith(BRAND_DETAILS_PAGE)
  const brandDetailsRequiresReload = location.search.includes(BRAND_COMPATIBILITY_QUERY_NAME)

  dispatch(
    isSearchByVehicleUrl(location) || isSearchByTrailer(location) ?
      navigateToSearchByVehicle({ ...vehicle, frontTireSize, rearTireSize }) :
      isBrandDetailsPage && !brandDetailsRequiresReload ?
        updateBrandDetailsPage({ location, search }) :
        refresh()
  )
}

export const showPersonalizeModal = ({
  skipUpdatePage,
  vehicle,
  frontTireSize,
  rearTireSize,
  userVehicleId,
  vehicleDetails,
  tiresSelectExtraCallbacks
}) => (dispatch, getState) => {
  const location = getPageLocation(getState())
  const isGaragePage = location.pathname.startsWith(GARAGE_PAGE)

  if (isGaragePage) {
    dispatch(show(PERSONALIZE_VEHICLE_MODAL, { userVehicleId, vehicleDetails }))
  }

  if (!skipUpdatePage && !isGaragePage) {
    dispatch(updatePage({ vehicle, frontTireSize, rearTireSize }))
  }
  triggerExtraTiresSelectCallbacks({ tiresSelectExtraCallbacks, userVehicleId })
}

export const handleAddNewVehicle = ({
  tiresSelectExtraCallbacks,
  description,
  skipTireSizeExperience,
  setPendingAddToQuote,
  skipUpdatePage
} = {}) =>
  dispatch =>
    dispatch(show(ADD_VEHICLE_MODAL, {
      description,
      title: 'Add A Vehicle',
      skipTireSizeExperience,
      setPendingAddToQuote,
      tiresSelectExtraCallbacks,
      confirmTireSizeModalProps: {
        handleTiresSelect: async ({
          vehicle,
          frontTireSize,
          rearTireSize,
          loadIndex,
          rearLoadIndex,
          speedRating,
          rearSpeedRating
        }) => {
          const payload = {
            ...vehicle,
            loadIndex,
            rearLoadIndex,
            speedRating,
            rearSpeedRating,
            tireFrontSize: frontTireSize,
            tireRearSize: rearTireSize
          }
          const { vehicleResponseType: { userVehicleId, vehicleDetails }} = await dispatch(addVehicle({ ...payload }))
          dispatch(hide(CONFIRM_TIRE_SIZE_MODAL))
          dispatch(showPersonalizeModal({
            skipUpdatePage,
            vehicle,
            frontTireSize,
            rearTireSize,
            userVehicleId,
            vehicleDetails,
            tiresSelectExtraCallbacks
          }))
        }
      }
    }))
