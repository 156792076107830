import React from 'react'
import PropTypes from 'prop-types'
import BreadPaymentsPlacement from 'components/common/BreadPayments/BreadPaymentsPlacement'

const BOTApplyCreditCard = ({ domId, className, locationType, placementId }) =>
  <BreadPaymentsPlacement
    domId={ domId }
    placementId={ placementId }
    locationType={ locationType }
    className={ className } />

BOTApplyCreditCard.propTypes = {
  className: PropTypes.string,
  domId: PropTypes.string,
  locationType: PropTypes.string,
  placementId: PropTypes.string
}

export default BOTApplyCreditCard

