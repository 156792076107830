import { createAction } from 'redux-actions'

export const register = createAction(
  'SHOW_HIDE_PASSWORD_INPUTS/REGISTER'
)

export const unregister = createAction(
  'SHOW_HIDE_PASSWORD_INPUTS/UNREGISTER'
)

export const changeType = createAction(
  'SHOW_HIDE_PASSWORD_INPUTS/CHANGE_TYPE'
)
