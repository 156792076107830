import Cookies from 'cookies-js'
import storeProvider from 'store/storeProvider'
import { cleanShoppingData, getMiniCartData } from 'actions/shoppingData'
import { TOKEN_EXPIRED, CLEAR_CART, SESSION_EXPIRED } from 'constant'

export default response => {
  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return response
  }

  const { headers } = response

  // in case our token is expired or invalid we need to force localStorage clean up and reload the page to be sure
  // that nothing broken and no stale data stored in localStorage
  if (headers[TOKEN_EXPIRED]) {
    localStorage.clear()
    Cookies.set(SESSION_EXPIRED, true)
    window.location.reload()
  } else if (headers[CLEAR_CART]) {
    const { dispatch } = storeProvider.getStore()

    dispatch(cleanShoppingData())
    dispatch(getMiniCartData())
  }

  return response
}
