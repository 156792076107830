import { handleActions } from 'redux-actions'
import {
  loadFavoriteSkusSuccess,
  loadFavoriteItemsSuccess,
  loadNonCompatibleFavoriteItemsSuccess,
  addFavoriteSuccess,
  removeFavoriteSuccess,
  clearFavorites,
  restoreFavoritesSuccess,
  loadFavoritesGiftListIdSuccess
} from './actions'


const initialState = {
  favoriteSkus: [],
  favoriteItems: [],
  giftListId: '',
  nonCompatibleFavoriteItems: []
}

const handleLoadFavoriteSkusSuccess = (state, { payload = []}) => ({
  ...state,
  favoriteSkus: payload
})

const handleLoadFavoriteItemsSuccess = (state, { payload = []}) => ({
  ...state,
  favoriteItems: payload
})

const handleLoadNonCompatibleFavoriteItemsSuccess = (state, { payload = []}) => ({
  ...state,
  nonCompatibleFavoriteItems: payload
})

const handleAddFavoriteSuccess = (state, { payload }) => ({
  ...state,
  favoriteSkus: [...state.favoriteSkus, payload]
})

const handleRemoveFavoriteSkuSuccess = (state, { payload }) => ({
  ...state,
  favoriteSkus: state.favoriteSkus.filter(x => x !== payload)
})

const handleLoadFavoritesGiftListIdSuccess = (state, { payload }) => ({
  ...state,
  giftListId: payload
})

const reducer = handleActions({
  [loadFavoriteSkusSuccess]: handleLoadFavoriteSkusSuccess,
  [loadFavoriteItemsSuccess]: handleLoadFavoriteItemsSuccess,
  [loadNonCompatibleFavoriteItemsSuccess]: handleLoadNonCompatibleFavoriteItemsSuccess,
  [addFavoriteSuccess]: handleAddFavoriteSuccess,
  [removeFavoriteSuccess]: handleRemoveFavoriteSkuSuccess,
  [restoreFavoritesSuccess]: (state, { payload }) => payload || initialState,
  [clearFavorites]: () => initialState,
  [loadFavoritesGiftListIdSuccess]: handleLoadFavoritesGiftListIdSuccess
}, initialState)

export default reducer
