
import { EMAIL_FIELD, MESSAGE_FIELD } from './constants'

export default {
  [EMAIL_FIELD]: {
    name: 'email',
    type: 'email',
    label: 'Email Address',
    required: true
  },
  [MESSAGE_FIELD]: {
    name: 'message',
    label: 'Your Message',
    inputType: 'textarea',
    rows: 10
  }
}
