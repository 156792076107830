import React from 'react'
import { withRouter } from 'react-router-dom'

/* eslint-disable react/prop-types */

export default Component => {
  const Redirect = ({ history, ...props }) =>
    <Component { ...props } redirectTo={ (to, ...args) => Promise.resolve(history.push(to, ...args)) } />

  Redirect.displayName = `WithRedirect(${Component.displayName || Component.name})`

  return withRouter(Redirect)
}
