import VehicleImage from './VehicleImage'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { extractProps } from './helpers'
import isEqual from 'lodash/isEqual'
import {
  ANGLE_VIEW,
  SIDE_VIEW,
  DEFAULT_VIEW
} from './constants'


const withErrorHandler = withHandlers({
  onErrorHandler: ({ hasError, setError, view, setView }) => () => {
    if (view === ANGLE_VIEW) {
      setView(SIDE_VIEW)
    } else if (!hasError) {
      setError(true)
    }
  }
})

const withResetErrorAfterUpdate = lifecycle({
  componentDidUpdate(prevProps) {
    const prevUrlProps = extractProps(prevProps)
    const newUrlProps = extractProps(this.props)

    if (!isEqual(newUrlProps, prevUrlProps)) {
      this.props.setError(false)
      this.props.setView(this.props.initialView || DEFAULT_VIEW)
    }
  }
})

export default compose(
  withState('hasError', 'setError', false),
  withState('view', 'setView', props => props.initialView || DEFAULT_VIEW),
  withResetErrorAfterUpdate,
  withErrorHandler
)(VehicleImage)
