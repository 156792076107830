export const FORM_NAME = 'globalSearchModal'

export const SEARCH_FIELD_NAME = 'search'

export const ERROR_MESSAGE = 'No results found, but here are some other ways to search'

// search categories
export const BRANDS = 'brands'
export const ARTICLES = 'articles'
export const PRODUCTS = 'products'
export const SERVICES = 'services'
