import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import DatePicker from 'react-datepicker'
import Modal from 'components/common/Modal'
import styled, { css } from 'styled-components'
import { SERVICE_SUBTITLE_TEXT } from '../../../../constants'
import Theme from 'components/common/Theme/Theme'
import Icon from 'components/common/Icon/Icon'
import Mixin from 'components/common/Mixin'
import Button from 'components/common/Button/Button'
import { integersOnly } from 'components/MyGarage2.0/components/VehiclePersonalizationModal/components/VehicleDetails/helpers'
import { addCommas } from 'components/MyGarage2.0/helpers'

const ServiceRecordModal = ({
  handleHide,
  service: {
    title,
    description
  },
  handleSubmit,
  serviceDate,
  setServiceDate,
  isTireRotationChecked,
  isOilChangeChecked,
  handleTireRotationOnChange,
  handleOilChangeOnChange,
  handleMileageOnChange,
  mileage,
  noServiceSelectedError,
  emptyMileageError,
  emptyDateError,
  onKeyPressTireRotation,
  onKeyPressOilChange,
  serviceRecordApiMismatchError,
  duplicateServiceRecordError,
  serviceRecordApiFutureErrorHandler
}) =>
  <Container data-at-service-record-modal closeHandler={ handleHide }>
    <ModalTitle>{title}</ModalTitle>
    <ModalDescription>
      {description}
    </ModalDescription>

    <InputsWrapper>
      <Subtitle >{ SERVICE_SUBTITLE_TEXT }</Subtitle>
      <CheckboxWrapper >
        <Label
          className="tire-rotation-label"
          htmlFor="tire-rotation"
          tabIndex="0"
          noServiceSelectedError={ noServiceSelectedError }
          isChecked={ isTireRotationChecked }
          onKeyPress={ onKeyPressTireRotation }>
          <CheckIcon asset={ isTireRotationChecked ? 'checkmark-box-filled-green' : 'checkmark-box-grey' } />
          Tire Rotation
          <Field
            className="checkbox-input"
            name="tire-rotation"
            id="tire-rotation"
            component="input"
            checked={ isTireRotationChecked }
            type="checkbox"
            onChange={ handleTireRotationOnChange } />
        </Label>

        <Label
          className="oil-change-label"
          tabIndex="0"
          htmlFor="oil-change"
          noServiceSelectedError={ noServiceSelectedError }
          isChecked={ isOilChangeChecked }
          onKeyPress={ onKeyPressOilChange }>
          <CheckIcon asset={ isOilChangeChecked ? 'checkmark-box-filled-green' : 'checkmark-box-grey' } />
          Oil change
          <Field
            className="checkbox-input"
            name="oil-change"
            id="oil-change"
            component="input"
            checked={ isOilChangeChecked }
            type="checkbox"
            onChange={ handleOilChangeOnChange } />
        </Label>
        { noServiceSelectedError && <ErrorNotificationMessage>Please select the service.</ErrorNotificationMessage> }
      </CheckboxWrapper>
      <DatePickerWrapper emptyDateError={ emptyDateError }>
        <DatePicker
          showIcon
          selected={ serviceDate }
          onChange={ (date) => setServiceDate(date) } />

        <DatePickerIcon asset="car-calendar" width="18px" height="20px" alt="Calendar icon" />
        { emptyDateError && <ErrorNotificationMessage>Please enter the date of service.</ErrorNotificationMessage> }
      </DatePickerWrapper>

      <MileageInputWrapper>
        <MileageLabel
          className="mileage-label"
          htmlFor="mileage"
          emptyMileageError={ emptyMileageError }>
          <Field
            className="mileage-input"
            name="mileage"
            id="mileage"
            component="input"
            placeholder="Odometer at Service"
            autoComplete="off"
            value={ mileage }
            normalize={ integersOnly }
            format={ addCommas }
            onChange={ handleMileageOnChange } />
        </MileageLabel>
        { emptyMileageError && <ErrorNotificationMessage>Please enter the Odometer.</ErrorNotificationMessage> }
      </MileageInputWrapper>
      { serviceRecordApiMismatchError &&
        <ServiceRecordGeneralErrorMessage>
          The mileage entered is <b>lower</b> than a previous service record for this vehicle.
          Please <b>correct mileage or date</b> to continue.
        </ServiceRecordGeneralErrorMessage>
      }
      { duplicateServiceRecordError &&
        <ServiceRecordGeneralErrorMessage>
          The service record you are adding already exists. Please check the mileage and date to add a new service.
        </ServiceRecordGeneralErrorMessage>
      }
      { serviceRecordApiFutureErrorHandler &&
        <ServiceRecordGeneralErrorMessage>
          The service record you are trying to add is set for a date in the future.
          Please make sure you are submitting service history for dates in the past.
        </ServiceRecordGeneralErrorMessage>
      }
      <ModalButtonsWrapper>
        <ActionButton
          type="submit"
          narrow
          data-at-garage-servicerecordmodal-submit
          onClick={ handleSubmit }>
          {title}
        </ActionButton>
        <CancelButton data-at-garage-servicerecordmodal-cancel onClick={ handleHide }>
          Cancel
        </CancelButton>
      </ModalButtonsWrapper>
    </InputsWrapper>

  </Container>

ServiceRecordModal.propTypes = {
  duplicateServiceRecordError: PropTypes.bool,
  emptyDateError: PropTypes.bool,
  emptyMileageError: PropTypes.bool,
  handleHide: PropTypes.func,
  handleMileageOnChange: PropTypes.func,
  handleOilChangeOnChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleTireRotationOnChange: PropTypes.func,
  isOilChangeChecked: PropTypes.bool,
  isTireRotationChecked: PropTypes.bool,
  mileage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  noServiceSelectedError: PropTypes.bool,
  onKeyPressOilChange: PropTypes.func,
  onKeyPressTireRotation: PropTypes.func,
  service: PropTypes.shape(),
  serviceDate: PropTypes.instanceOf(Date),
  serviceRecordApiFutureErrorHandler: PropTypes.bool,
  serviceRecordApiMismatchError: PropTypes.bool,
  setServiceDate: PropTypes.func
}

export default ServiceRecordModal

const Container = styled(Modal)`
  max-width: 406px;
  width: 100%;
  height: auto;

  & .modal-body {
    padding: 4px 32px 42px;
  }

  @media (max-width: 838px) {
    max-width: 100%;
    height: 100%;
  }
`

const ModalTitle = styled.div`
  margin: 0 0 24px;
  color: ${Theme.colors.black};
  text-align: center;
  text-transform: uppercase;
  ${Mixin.font.GothamBold(20, 600, 24)};
  transform: scale(1, 1.3);
`

const ModalDescription = styled.div`
  margin: 0 0 16px;
  ${Mixin.font.GothamBook(14, 400, 16)};
  color: ${Theme.colors.black};
  text-align: start;
`

const InputsWrapper = styled.div`
  width: 100%;
`

const Subtitle = styled.p`
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 1.2;
`

const CheckboxWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 0 28px 0;
`

const Label = styled.label`
  display: flex;
  padding: 5px 19px 5px 10px;
  border: 1px solid ${Theme.colors.medium_grey};
  border-radius: 4px;
  cursor: pointer;
  ${props => props.isChecked && css`
    border-color: ${Theme.colors.green};
    color: ${Theme.colors.green};
  `}
  ${props => props.noServiceSelectedError && css`
    border-color: ${Theme.colors.bright_red_new};
  `}

  &:nth-child(2) {
    margin-left: 24px;
  }

  & .checkbox-input {
    display: none;
  }
`

const MileageInputWrapper = styled.div`
  position: relative;
`

const CheckIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin: 0 6px 0 0;
`

const DatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 24px;

  & .react-datepicker__calendar-icon {
    display: none; /* Fix of the SVG icon bug. See ticket BOT-3227 */
  }

  & input {
    position: relative;
    width: 100%;
    padding: 14px 14px 9px 14px;
    font-weight: 700;
    border-radius: 0;
    border: 1px solid ${Theme.colors.medium_grey};
    ${props => props.emptyDateError && css`
      border-color: ${Theme.colors.bright_red_new};
    `}
  }

  &::before {
    position: absolute;
    content: 'Date of service';
    top: -8px;
    left: 13px;
    padding: 0 5px;
    font-size: 14px;
    color: ${Theme.colors.medium_grey};
    z-index: 100;
    background: ${Theme.colors.white};
    ${props => props.emptyDateError && css`
      color: ${Theme.colors.bright_red_new};
    `}
  }
`

const DatePickerIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  pointer-events: none;
`

const MileageLabel = styled.label`
  width: 100%;

  & .mileage-input {
    width: 100%;
    padding: 14px 14px 9px 14px;
    font-weight: 700;
    border-radius: 0;
    border: 1px solid ${Theme.colors.medium_grey};
    ${props => props.emptyMileageError && css`
      border-color: ${Theme.colors.bright_red_new};
    `}

    &::placeholder {
      font-weight: 400;
    }
  }
`

const ModalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0 0 0;
`

export const ActionButton = styled(Button)`
  cursor: pointer;
`

const CancelButton = styled(ActionButton)`
  margin: 30px 0 0 !important;
  color: ${Theme.colors.bright_red_new};
  background: ${Theme.colors.transparent};
  border: none;

  &:hover {
    background: ${Theme.colors.transparent};
    color: ${Theme.colors.crimson};
    border: none;
  }
`

const ErrorNotificationMessage = styled.p`
  position: absolute;
  bottom: -16px;
  left: 0;
  ${Mixin.font.GothamBook(10, 400, 13)};
  color: ${Theme.colors.bright_red_new};
  margin: 0;
`

const ServiceRecordGeneralErrorMessage = styled(ErrorNotificationMessage)`
  position: static;
  margin: 15px 0 0 0;

  b {
    ${Mixin.font.GothamBold(10, 600, 13)};
  }
`
