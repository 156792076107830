import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { MODAL_CONTENT_ID } from 'constant'

const toggle = (WrappedComponent, defaultValue, afterToggleEventName) => {
  class Toggle extends PureComponent {
    constructor(props) {
      super(props)

      this.defaultValue = Boolean(props.expandedByDefault !== undefined ? props.expandedByDefault : defaultValue)
      this.state = {
        expanded: this.defaultValue,
        toggleBlockRef: null
      }
      this.handlers = {
        onClick: () => this.toggle()
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { disableResetOnLocationChange } = nextProps
      const isDifferentLocation = this.props.location !== nextProps.location
      const isSameLocationPath = this.props.location.path === nextProps.location.path

      if (isDifferentLocation && isSameLocationPath && !disableResetOnLocationChange) {
        this.reset()
      }
    }

    onBlur = () => {
      const { expanded, toggleBlockRef } = this.state
      const { isModal, preventHandleBlur } = this.props
      setTimeout(() => {
        if (!isModal &&
          !preventHandleBlur &&
          expanded &&
          document.activeElement !== document.querySelector('main') &&
          document.activeElement !== document.querySelector(`[id="${MODAL_CONTENT_ID}"]`) &&
          toggleBlockRef &&
          toggleBlockRef.contains &&
          !toggleBlockRef.contains(document.activeElement)) {
          this.toggle()
        }
      })
    }

    setToggleBlockRef = (el) => { this.state.toggleBlockRef = el }

    reset = () => this.setState({ expanded: this.defaultValue })

    toggle = () => {
      this.setState({ expanded: !this.state.expanded })
      if (afterToggleEventName) {
        window.dispatchEvent(new CustomEvent(afterToggleEventName))
      }
    }

    render = () =>
      <WrappedComponent
        { ...this.props }
        toggle={ this.toggle }
        expanded={ this.state.expanded }
        handleToggleBlur={ this.onBlur }
        setToggleBlockRef={ this.setToggleBlockRef }
        toggleHandlers={ this.handlers } />
  }

  Toggle.propTypes = {
    afterToggleEventName: PropTypes.shape({
      path: PropTypes.string
    }),
    disableResetOnLocationChange: PropTypes.bool,
    expandedByDefault: PropTypes.bool,
    isModal: PropTypes.bool,
    location: PropTypes.shape({
      path: PropTypes.string
    }),
    preventHandleBlur: PropTypes.bool
  }

  const ToggleWithRouter = withRouter(Toggle)

  WrappedComponent.type && (ToggleWithRouter.type = WrappedComponent.type)

  return ToggleWithRouter
}

export default toggle
