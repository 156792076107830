import {
  QUOTE_PAGE,
  QUOTE_SUMMARY_PAGE,
  QUOTE_APPOINTMENT_INFO_PAGE,
  APPOINTMENT_CONFIRMATION_PAGE,
  ORDER_CONFIRMATION_PAGE,
  QUICK_APPOINTMENT_PAGE
} from 'constant'

export const isQuoteSummaryPage = page =>
  page === QUOTE_SUMMARY_PAGE

export const isQuoteControlPage = page =>
  page === QUOTE_PAGE

export const isQuickAppointmentPage = page =>
  page === QUICK_APPOINTMENT_PAGE || page === APPOINTMENT_CONFIRMATION_PAGE || page === ORDER_CONFIRMATION_PAGE

export const isQuotePage = page =>
  isQuoteControlPage(page) || isQuoteSummaryPage(page)

export const isApptConfirmationPage = path =>
  path.startsWith(APPOINTMENT_CONFIRMATION_PAGE) || path.startsWith(QUOTE_APPOINTMENT_INFO_PAGE)
