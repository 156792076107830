import React from 'react'
import PropTypes from 'prop-types'
import CommonLink from 'components/common/Link/Link'

const Link = ({ displayName = '', link: { path } = {}, altTag, '@type': type, ...props }) =>
  <CommonLink { ...props } to={ path || '' }>{ displayName }</CommonLink>

Link.propTypes = {
  '@type': PropTypes.string,
  altTag: PropTypes.string,
  displayName: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.string
  })
}

Link.type = 'Link'

export default Link
