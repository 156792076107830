import Desktop from './Desktop'
import Mobile from './Mobile'
import { ScreenReader, NonScreenReader } from './ScreenReader'
import HighContrastTest, { highContrast } from './HighContrastMode'
import { desktop, tablet, tabletSmall, mobile, print, IE, IE9, mobileNarrow, tabletNarrow, tabletMedium, mediumMobile, mediumLargeDevices } from './mixins'

export default {
  Desktop,
  Mobile,
  ScreenReader,
  NonScreenReader,
  desktop,
  tablet,
  tabletSmall,
  mobile,
  print,
  mobileNarrow,
  IE,
  IE9,
  HighContrastTest,
  highContrast,
  tabletNarrow,
  mediumMobile,
  mediumLargeDevices,
  tabletMedium
}

export { MOBILE_EDGE, DESKTOP_NARROW_EDGE } from './constants'
