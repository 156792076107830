import { PERSONALIZE_VEHICLE_MODAL } from 'constant'
import { connect } from 'react-redux'
import { reduxForm, initialize } from 'redux-form'
import isEqual from 'lodash/isEqual'
import { withProps, compose, lifecycle } from 'recompose'
import withNormalizeValue from 'components/common/hoc/withNormalizeValue'
import { handleSubmit, getStatesApi, resetFormFields } from './actions'
import { createInitialValues, getStatesOptions } from './helpers'
import createViewModel from './viewModel'
import VehicleDetails from './VehicleDetails'
import { getStates } from 'components/MyGarage2.0/selectors'
import { getValues } from './selectors'
import validate from './validate'

const mapStateToProps = state => {
  const { licencePlateNumber } = getValues(state) || {}
  return {
    licencePlateNumber,
    personalizationValues: getValues(state),
    states: getStates(state)
  }
}

const withLifecycles = lifecycle({
  componentDidMount() {
    const { getStatesApi, states } = this.props
    if (!states || !states.length) { getStatesApi() }
  },
  componentDidUpdate({ licencePlateNumber: prevLicencePlateNumber }) {
    const { licencePlateNumber, resetFormFields } = this.props
    if (!isEqual(licencePlateNumber, prevLicencePlateNumber) && !licencePlateNumber) {
      resetFormFields({ state: null })
    }
  },
  componentWillUnmount() {
    const { resetFormFields } = this.props
    resetFormFields({
      nickname: null,
      mileage: null,
      licencePlateNumber: null,
      state: null
    })
  }
})

const withVehicleProps = withProps(({
  vehicleDetails,
  states
}) => {
  const stateOptions = getStatesOptions(states)
  return {
    model: createViewModel(stateOptions),
    initialValues: createInitialValues({ ...vehicleDetails })
  }
})

export default compose(
  connect(mapStateToProps, { getStatesApi, resetFormFields, initialize }),
  withLifecycles,
  withVehicleProps,
  reduxForm({ validate, form: PERSONALIZE_VEHICLE_MODAL, onSubmit: handleSubmit }),
  withNormalizeValue
)(VehicleDetails)
