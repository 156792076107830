import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  PRICE_CHANGED,
  AVAILABILITY_CHANGED,
  AVAILABILITY_PRICE_CHANGED,
  DESCRIPTION,
  CHANGE,
  AVAILABILITY,
  PRICE,
  AVAILABILITY_PRICE
} from './constants'
import Mixin from 'components/common/Mixin'
import Modal from 'components/common/Modal'
import Product from './Product'

const ChangedAvailabilityPriceModal = ({
  productsWithChangedAvailabilityOrPrice = [],
  handleHide,
  clearProductsWithChangedAvailabilityOrPrice,
  phoneNumber
}) => {
  useEffect(() => () => clearProductsWithChangedAvailabilityOrPrice(), [])

  const availabilityChanged = productsWithChangedAvailabilityOrPrice.some(
    product => product.hasAvailabilityChanged && !product.hasPriceChanged
  )
  const bothChanged = productsWithChangedAvailabilityOrPrice.some(
    product => product.hasPriceChanged && product.hasAvailabilityChanged
  )

  const title = bothChanged ? AVAILABILITY_PRICE_CHANGED : availabilityChanged ? AVAILABILITY_CHANGED : PRICE_CHANGED
  const changeText = bothChanged ? AVAILABILITY_PRICE : availabilityChanged ? AVAILABILITY : PRICE
  const description = `${DESCRIPTION} ${changeText} ${CHANGE}.`

  return (
    <Wrapper>
      <ModalBox
        title={ title }
        closeHandler={ handleHide }>
        <ModalDescription>{ description }</ModalDescription>
        {
          productsWithChangedAvailabilityOrPrice.map((product, index) =>
            <Product key={ index } first={ !index } product={ product } phoneNumber={ phoneNumber } />
          )
        }
      </ModalBox>
    </Wrapper>
  )
}

ChangedAvailabilityPriceModal.propTypes = {
  clearProductsWithChangedAvailabilityOrPrice: PropTypes.func,
  handleHide: PropTypes.func,
  phoneNumber: PropTypes.string,
  productsWithChangedAvailabilityOrPrice: PropTypes.arrayOf(PropTypes.shape())
}

export default ChangedAvailabilityPriceModal

const Wrapper = styled.div`
  padding: 20px;
`

const ModalBox = styled(Modal)`
  overflow-y: auto;
  ${Mixin.responsive('padding', '25px 24px 30px', '60px 16px 40px')}

  & .modal-header {
    border-bottom: none;
  }
`

const ModalDescription = styled.p`
  padding-bottom: 30px;
  border-bottom: 1px solid #bbb;
  margin-bottom: 20px;
  margin-top: -10px;
  font-weight: 700;
`
