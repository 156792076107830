import React from 'react'
import PropTypes from 'prop-types'
import HeaderDropdownItem from './components/HeaderDropdown/HeaderDropDownItem'
import { CC_ELEMENT_ID } from './HeaderCreditCard'
import { STORE_ELEMENT_ID } from './MyStore/constants'

export const FRANCHISE_ELEMENT_ID = 'header-franchise'
export const FLEET_ELEMENT_ID = 'header-fleet-services'
export const KNOWLEDGE_CENTER_ID = 'header-knowledge-center'
const isFranchiseComponent = (name) => name === 'Franchise Opportunities'
const isKnowledgeCenterComponent = (name) => name === 'Knowledge Center'

const HeaderFranchise = ({ name, ...props }) =>
  <HeaderDropdownItem
    data-at-header-franchiseopportunities={ isFranchiseComponent(name) }
    name={ name }
    id={ isFranchiseComponent(name)
      ? FRANCHISE_ELEMENT_ID : isKnowledgeCenterComponent(name)
        ? KNOWLEDGE_CENTER_ID : FLEET_ELEMENT_ID }
    nextItem={ isFranchiseComponent(name) ? FLEET_ELEMENT_ID : STORE_ELEMENT_ID }
    prevItem={ isFranchiseComponent(name) ? CC_ELEMENT_ID : FRANCHISE_ELEMENT_ID }
    { ...props } />


HeaderFranchise.propTypes = {
  name: PropTypes.string
}

HeaderFranchise.type = 'HeaderFranchise'

export default HeaderFranchise
