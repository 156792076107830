export default [
  'BOTSearchTrailerTires',
  'BOTSearchTrailerTiresPanel',
  'BOTSkuDetail',
  'BOTTrailerTireLines',
  'BOTTrailerTiresTeaser',
  'OneColumnProductPage'
]

/*
Appears on:
/tires/sku/nitto/ntgeo-neogen/225-45zr17-94w-xl/000000000001002883,
/tires/trailer,
/tires/winter-snow
*/
