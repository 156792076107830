export default [
  'BOTStoreDeals',
  'PromotionResultList',
  'PromotionResultListMyGarage'
]

/*
Appears on:
/deals,
/regions/bay-area,
/location/ca-los-angeles-90064/005768
/my-account/vehicles
*/
