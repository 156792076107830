import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import redArrow from './assets/redArrow.svg'
import whiteArrow from './assets/whiteArrow.svg'

/* Standard Button */
const GenericButton = styled.button.attrs({
  'data-common-button': true
})`
  display: inline-block;
  padding: 7px 30px 8px;
  color: ${Theme.colors.white};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  background: ${Theme.colors.bright_red_new};
  border: 1px solid ${Theme.colors.bright_red_new};
  border-radius: 0;
  ${Mixin.font.GothamBold(13)}
  line-height: 1.05em;
  letter-spacing: 0;
  cursor: pointer;

  :hover {
    background: ${Theme.colors.crimson};
    color: ${Theme.colors.white};
    border: 1px solid ${Theme.colors.crimson};
  }

  & + & {
    margin-left: 10px;
  }

  /* Vertical orientation */
  ${Mixin.property('stacked', `
    display: block;

    & + & {
      margin-left: 0;
      margin-top: 15px;
    }
  `)}
`

export const followArrow = css`
  position: relative;

  ::after {
    content: '';
    background: url(${whiteArrow}) no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 14px;
    line-height: 0;
    transform: translateY(-50%);
  }
`

const Button = styled(GenericButton)`
   /* stylelint-disable */
  /* Different button styles */
  ${props => props.secondary && css`
    background: ${Theme.colors.white};
    border-color: ${Theme.colors.bright_red_new};
    color: ${Theme.colors.bright_red_new};

    @supports (-webkit-overflow-scrolling: touch) {
      :hover {
        background-color: ${Theme.colors.white};
        color: ${Theme.colors.crimson};
      }
    }
  `}
  ${props => props.transparent && css`
    color: ${Theme.colors.white};
    border-color: ${Theme.colors.white};
    background: ${Theme.colors.transparent_white};

    :hover {
      background: ${Theme.colors.transparent_white_hover};
    }
  `}
  ${props => props.disabled && css`
    && {
      background: ${Theme.colors.white_smoke};
      border-color: ${Theme.colors.grey};
      color: ${Theme.colors.grey};
    }
  `}
  ${props => props.filter && css`
    background-color: ${Theme.colors.off_white};
    border: 1px solid ${Theme.colors.light_grey};
    color: ${Theme.colors.dark_grey};

    :hover {
      background: ${Theme.colors.transparent_white_hover};
    }
  `}

  /* Sizes */
  ${props => props.large && css`
    ${Mixin.font.GothamBold(19)}
    padding: 12px 30px;
  `}
  
  ${props => props.small && css`
    ${Mixin.font.GothamBold(13)}
  `}

  ${props => props.narrow && css`
    letter-spacing: 0.03em;
    font-stretch: condensed;
    transform: scale(1, 1.3);
  `}

  ${props => (props.followarrow || props.follow) && followArrow}

  /* Special styles */
  ${props => props.follow && css`
    min-width: 360px;
    padding: 12px 30px 12px 20px;
    line-height: 24px;
    letter-spacing: 0;
    ${Mixin.font.GothamMedium(18)}

    ::after {
      height: 16px;
    }
  `}


  ${props => props.follow_alternative && css`
    position: relative;
    padding: 16px 35px 12px 20px;;
    color: ${Theme.colors.bright_red_new};
    text-align: left;
    text-transform: uppercase;
    vertical-align: middle;
    letter-spacing: 0;
    background: ${Theme.colors.white};
    border: 1px solid ${Theme.colors.bright_red_new};
    border-radius: 0;
    ${Mixin.font.GothamBold(18)}
    ${Mixin.responsive('min-width', '300px', '288px')}

    ::after {
      content: url(${redArrow});
      position: absolute;
      right: 15px;
      top: 55%;
      transform: translateY(-50%);
    }

    :hover {
      background: ${Theme.colors.bright_red};
      color: ${Theme.colors.white};

        ::after {
          content: url(${whiteArrow});
        }
    }
  `}

  ${props => props.link && css`
    && {
      background: none;
      padding: 10px;
      border: none;
      color: ${Theme.colors.blue};
      text-transform: none;
      letter-spacing: normal;
      
      :hover {
        text-decoration: underline;
      }

      ${Mixin.font.GothamBook(14)}
      ${props => props.disabled && css`
        color: ${Theme.colors.grey};

        :hover {
          text-decoration: none;
          cursor: default;
        }
      `}
    }
  `}

  ${props => props.bolder && css`
    && {
      ${Mixin.font.GothamBold()}
    }
  `}
  
  ${props => props.underline && css`
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  `}

  ${props => props.saveForLater && css`
    padding: 5px 10px;
  `}
  
  ${props => props.shareFavoritesLink && css`
    width: 255px;
  `}

  /* Utility attributes */
  ${Mixin.property('full', `
    width: 100%;
  `)}

  /* Special behavior */
  ${props => props.full === 'special' && css`
    padding-left: 10px;
    padding-right: 10px;
  `}
`

export default Button
