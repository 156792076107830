import { closeHeaderSystemAlertState, openHeaderSystemAlertState } from './actions'
import { handleActions } from 'redux-actions'

const initialState = {
  isVisible: true
}

const headerSystemAlertReducer = handleActions({
  [closeHeaderSystemAlertState]: (state) =>
    ({
      ...state,
      isVisible: false
    }),
  [openHeaderSystemAlertState]: (state) =>
    ({
      ...state,
      isVisible: true
    })
}, initialState)

export default headerSystemAlertReducer
