import React from 'react'
import PropTypes from 'prop-types'


const BreadPaymentsPlacement = ({ placementId, financingType = 'card', locationType, domId, totalPrice, className }) =>
  <div
    id={ domId }
    data-bp="true"
    data-bd-placement-id={ placementId }
    data-bd-location-type={ locationType }
    data-bd-financial-type={ financingType }
    data-bd-total-price={ totalPrice }
    className={ className } />


BreadPaymentsPlacement.propTypes = {
  className: PropTypes.string,
  domId: PropTypes.string,
  financingType: PropTypes.string,
  locationType: PropTypes.string,
  placementId: PropTypes.string,
  totalPrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

export default BreadPaymentsPlacement
