export const handleResponse = response => {
  if (process.env.BOT_IS_SERVER !== 'true' && process.env.BOT_LOG_REQUESTS === 'true') {
    const { status, statusText, headers, data, request: { method, path }} = response
    console.log('----------axios response-----------')
    console.log(`${method} ${path}`)
    console.log(`${status} ${statusText}`)

    console.log('-----headers-----')
    console.log(
      require('util').inspect(headers, {
        colors: false,
        depth: 3,
        compact: false
      })
    )
    console.log('-----data-----')
    console.log(
      require('util').inspect(data, {
        colors: false,
        depth: 3,
        compact: false
      })
    )
    console.log('----------/axios response-----------')
  }
  return response
}

export const handleError = error => {
  if (process.env.BOT_IS_SERVER !== 'true' && process.env.BOT_LOG_REQUESTS === 'true') {
    const { config } = error
    console.log('----------axios request failed-----------')
    console.log(
      require('util').inspect(config, {
        colors: false,
        depth: 3,
        compact: false
      })
    )
    console.log('----------/axios request failed-----------')
  }

  return Promise.reject(error)
}

export default [handleResponse, handleError]
