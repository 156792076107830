export default [
  'BOTDescriptionTeaser',
  'BOTShopByBrandMessage',
  'BOTShopForTires',
  'BOTShopForTiresHeader',
  'BOTShopForTiresTeaser'
]

/*
Appears on:
/tires
*/
