export default [
  'BOTArticleDetails',
  'BOTContactUs',
  'BOTContactUsForm',
  'BOTFleetServicesContactUsForm',
  'BOTRelatedArticles'
]

/*
Appears on:
/contact-us,
/fleet-services-contact-us,
/fleet-and-commercial-accounts,
/fleet-services,
/resources/all-about-tires/service-backed-by-1200-service-central-locations
*/
