/**
 * Function receives string with phone number, like '9999999999', and returns string, like '(999) 999-9999'
 * @param {string} number
 * @param {string} placeholder
 * @returns {string}
 */
export const formatPhoneNumber = (number, placeholder = '') => {
  if (!number) {
    return placeholder
  }

  const result = number.match(/\d/g).reverse().slice(0, 10).reverse()

  result.splice(-4, 0, '-')

  if (result.length >= 10) {
    result.splice(3, 0, ') ')
    result.splice(0, 0, '(')
  }

  return result.join('')
}

export const normalizePhoneNumber = number =>
  number.replace(/[^0-9]/g, '')

// Prepare phone number before API call
export const preparePhoneNumber = (number = '') => ({
  areaCode: number.slice(0, 3),
  firstThree: number.slice(3, 6),
  lastFour: number.slice(6)
})

export const composePhoneNumberFromObject = ({ areaCode, firstThree, lastFour }) =>
  areaCode && `${areaCode}${firstThree}${lastFour}`

export const composeFormattedPhoneNumberFromObject = ({ areaCode, firstThree, lastFour } = {}) =>
  areaCode ? `(${areaCode}) ${firstThree}-${lastFour}` : ''

export const getPhoneNumberFromObjectWithPrefix = phoneNumber =>
  `tel:+1${composePhoneNumberFromObject(phoneNumber)}`
