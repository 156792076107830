import { connect } from 'react-redux'
import { compose, lifecycle, withProps } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
// TODO: default export in index file causes cyclic dependency. Analyze differences between BOT and TA
import registerModalPage from 'src/components/common/hoc/registerModalPage'
import { type as MODAL_BOX_TYPE } from 'components/ModalBox/ModalBox'
import { VEHICLE_EDITOR_MODAL } from 'constant'
import { setDataFetcher, handleModalClose } from '../actions'
import VehicleEditorModal from './VehicleEditorModal'

const pageSrc = '/my-account/vehicle'

const withVehicleDetailsProps = withProps(({ data: { modalBoxContent }}) => {
  const vehicleData = modalBoxContent[0].UserVehicleDetails
  return {
    vehicleData
  }
})

const withDataFetcher = lifecycle({
  componentDidMount() {
    this.props.setDataFetcher(this.props.fetchModalPage)
  }
})

export default compose(
  registerReduxModal({ name: VEHICLE_EDITOR_MODAL }),
  registerModalPage({
    rootType: MODAL_BOX_TYPE,
    pageSrc: ({ userVehicleId }) => `${pageSrc}?userVehicleId=${userVehicleId}`
  }),
  connect(null, { setDataFetcher, handleModalClose }),
  withDataFetcher,
  withVehicleDetailsProps
)(VehicleEditorModal)
