import { compose, withProps } from 'recompose'
import withType from 'components/common/hoc/withType'
import BOTApplyCreditCard from './BOTApplyCreditCard'

const uniqueDomId = () => {
  const dateString = Date.now().toString(36)
  const randomness = Math.random().toString(36).substr(2)
  return dateString + randomness
}

export default compose(
  withType({ type: 'BOTApplyCreditCard' }),
  withProps(() => ({ domId: `apply-now-${uniqueDomId()}` }))
)(BOTApplyCreditCard)
