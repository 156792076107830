import { compose, lifecycle, withStateHandlers } from 'recompose'
import isEqual from 'lodash/isEqual'
import withKeyPressHandlers from './withKeyPressHandlers'

const withRefState = withStateHandlers(
  {
    boundRef: null
  },
  {
    setBoundRef: () => ref => ({ boundRef: ref })
  }
)

const withKeyPress = compose(
  withKeyPressHandlers,
  withRefState,
  lifecycle({
    componentDidMount() {
      const { wrapperRef, global, setBoundRef } = this.props
      const boundElem = (global || !wrapperRef) ? document : wrapperRef
      setBoundRef(boundElem)
      boundElem.addEventListener('keydown', this.props.handleLocalKeyPress)
    },
    componentDidUpdate({ wrapperRef: prevWrapperRef }) {
      const { wrapperRef, global, setBoundRef, boundRef } = this.props
      if (!global &&
        wrapperRef &&
        wrapperRef.addEventListener &&
        !isEqual(prevWrapperRef, wrapperRef)
      ) {
        boundRef && boundRef.removeEventListener && boundRef.removeEventListener('keydown', this.props.handleLocalKeyPress)
        setBoundRef(wrapperRef)
        wrapperRef.addEventListener('keydown', this.props.handleLocalKeyPress)
      }
    },
    componentWillUnmount() {
      const { boundRef } = this.props
      boundRef && boundRef.removeEventListener && boundRef.removeEventListener('keydown', this.props.handleLocalKeyPress)
    }
  })
)

export default withKeyPress
