import { createSelector } from 'helpers/reselect'

export const getShoppingData = state => state.shoppingData

export const getIsQuoteExist = createSelector(
  [getShoppingData],
  ({ _state }) => Boolean(_state)
)

export const getQuoteItemAndServices = createSelector(
  [getShoppingData],
  ({ quotedItemAndServices = {}}) => quotedItemAndServices
)

export const getQuoteServices = createSelector(
  [getQuoteItemAndServices],
  ({ services }) => services
)

export const getFreeAligmentTooltip = createSelector(
  [getQuoteItemAndServices],
  ({ freeAligmentTooltip }) => freeAligmentTooltip
)

export const getShoppingDataState = createSelector(
  [getShoppingData],
  ({ _state }) => _state
)

export const getQuoteTotalCount = createSelector(
  [getShoppingData],
  ({ totalCount }) => totalCount
)

export const getSaveForLaterItems = createSelector(
  [getShoppingData],
  ({ quotedItemAndServices: { savedItemVOList }}) => savedItemVOList
)

// initially designed for GTM
export const getQuoteInfo = createSelector(
  [getQuoteItemAndServices],
  quotedItemAndServices => {
    const {
      services,
      products,
      installationKits,
      orderId,
      freeAligmentTooltip = {},
      quoteSummary: {
        estimatedTaxes = 0,
        revenue,
        shopFees = 0
      } = {},
      appliedPromotions: promotions = []
    } = (quotedItemAndServices || {})

    return {
      services,
      products,
      promotions,
      installationKits,
      orderId,
      revenue,
      tax: estimatedTaxes,
      freeAligmentTooltip,
      shopFees
    }
  }
)

export const getProducts = createSelector(
  [getQuoteItemAndServices],
  quotedItemAndServices => {
    const {
      products
    } = (quotedItemAndServices || {})

    return products
  }
)
