import { findDOMNode } from 'react-dom'
import { withStateHandlers } from 'recompose'
import { buildKeyCallbackProps } from './helpers'

const withKeyPressHandlers = withStateHandlers(
  { wrapperRef: null },
  {
    setKeypressScopeRef: () => component => ({ wrapperRef: component }),
    handleLocalKeyPress: ({ wrapperRef }, props) => event => {
      if (event instanceof KeyboardEvent) {
        let ref = wrapperRef
        if (!props.global && wrapperRef && !wrapperRef.contains) {
          ref = findDOMNode(wrapperRef) // eslint-disable-line
        }

        if (props.global || (ref && ref.contains(event.target))) {
          buildKeyCallbackProps({ event, props })
        }
      }
    }
  }
)

export default withKeyPressHandlers
