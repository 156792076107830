import React from 'react'
import PropTypes from 'prop-types'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'
import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Mixin from 'components/common/Mixin'
import Link from 'components/common/Link/Link'

const BOTBreadcrumbHome = ({
  displayName,
  link,
  active,
  button = false,
  onClick
}) =>
  <BreadcrumbWrapper>
    <Breadcrumb
      isCurrent={ active }
      aria-current={ active ? button ? 'step' : 'page' : null }
      { ...dynamicDataAttribute('breadcrumb-link', toOneString(displayName)) }
      breadcrumb={ !active }
      to={ link }
      onClick={ onClick }>
      { displayName }
    </Breadcrumb>
  </BreadcrumbWrapper>

BOTBreadcrumbHome.propTypes = {
  active: PropTypes.bool,
  button: PropTypes.bool,
  displayName: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func
}

export default BOTBreadcrumbHome

const Breadcrumb = styled(Link)`
  ${props => props.isCurrent && css`
    && {
      pointer-events: none;
      color: ${Theme.colors.dark_grey};
      ${Mixin.font.GothamBook(12)}

      &:hover {
        text-decoration: none;
      }
    }
  `}
`

const BreadcrumbWrapper = styled.li`
  display: inline-block;
`
