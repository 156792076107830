export default [
  'ActionLinks',
  'BOTRecentlyAddedProductService',
  'BOTRecommendedProductService',
  'ModalBox'
]

/*
Appears on:
/modals/add-products-and-services-to-quote,
/modals/appointment-email,
/modals/confirm-tire-size,
/modals/email-signup,
/modals/find-lower-price?skuId=000000000001087501-BOT,
/modals/find-service-history?loggedIn=true,
/modals/find-service-history,
/modals/global-search,
/modals/price-changed,
/modals/promo-lightbox,
/modals/store-locator-modal,
/my-account/forgot-email,
/my-account/forgot-password,
/my-account/vehicle
*/
