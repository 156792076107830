export const audioEyeCodeFactory = () => `
  !(function () {
    var t = function () {
      var t = document.createElement("script");
      (t.src = "https://ws.audioeye.com/ae.js"), (t.type = "text/javascript"), t.setAttribute("defer", ""), document.getElementsByTagName("body")[0].appendChild(t);
      var audioEyeStyle = document.createElement("style");  audioEyeStyle.type="text/css"; audioEyeStyle.appendChild(document.createTextNode("#ae_launcher.ae-cta-position-preset-right-lower { bottom: 90px! important; }")); document.getElementsByTagName("body")[0].appendChild(audioEyeStyle);
    };
    "complete" !== document.readyState ? (window.addEventListener ? window.addEventListener("load", t) : window.attachEvent && window.attachEvent("onload", t)) : t();
    })();
`
