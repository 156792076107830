export const FRONT = 'front'
export const REAR = 'rear'

export const WIDTH = 'width'
export const RATIO = 'ratio'
export const DIAMETER = 'diameter'

export const NAMES = [WIDTH, RATIO, DIAMETER]

export const FORM = 'inPage'
export const MODAL = 'inModal'
export const SUBMITTED = 'submittedValues'

export const DEFAULT_TIRE_SIZE = {
  width: '205',
  ratio: '75',
  diameter: '14'
}

export const POPULAR_TIRE_SIZES = [
  '265',
  '275',
  '235',
  '225',
  '205',
  '245',
  '285',
  '215',
  '255',
  '35'
]

export const TIRE_SIZE_PARSING_REGEX =
  /[a-zA-Z]*([\d.]{1,})[/x-]{1,}(\d{1,3}\.*\d{0,3})[a-zA-Z]*R[a-zA-Z]*(\d{1,3}\.{0,1}\d{0,1})/

export const TIRE_SIZE_DECIMAL_PARSING_REGEX = /(\.)(\d)(\d*)/g
// error messages
export const WIDTH_EMPTY = 'Please specify Width of the tires.'
export const RATIO_EMPTY = 'Please specify Ratio of the tires.'
export const DIAMATER_EMPTY = 'Please specify Diameter of the tires.'

export const TRAILER_PAGE_URL = '/trailer'
