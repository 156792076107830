import { reduxForm } from 'redux-form'
import { scrollToHeader } from 'helpers/scroll'

const onSubmitFailHandler = (form, isModal) => (errors = {}) => {
  const hasErrors = Object.keys(errors).filter(key => errors[key])
  const erroneousField = hasErrors[0]
  const errorElement =
    document.querySelector(`input[form="${form}"][name="${erroneousField}"] ~ button`) ||
    document.querySelector(`input[form="${form}"][name="${erroneousField}"]`) ||
    document.querySelector(`textarea[form="${form}"][name="${erroneousField}"]`)

  if (errorElement) {
    !isModal && scrollToHeader({ element: errorElement, padding: 60 })
    errorElement.focus()
  }
}

export default ({ onSubmitFail = onSubmitFailHandler, form, isModal, ...props }) =>
  reduxForm({ ...props, form, onSubmitFail: onSubmitFail(form, isModal) })
