import { handleActions } from 'redux-actions'
import { subscriptionCompleted } from './actions'
import { initialState } from './model'

const handleSubscription = state => ({
  ...state,
  isSubscribed: true
})

export default handleActions({
  [subscriptionCompleted]: handleSubscription
}, initialState)

