import { compose } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import { getVehicleCount } from 'selectors'
import HeaderAccountMenuGarageLink from './HeaderAccountMenuGarageLink'

const mapStateToProps = state => ({
  vehicleCount: getVehicleCount(state)
})

export default compose(
  withType({ type: 'GarageLink' }),
  connect(mapStateToProps)
)(HeaderAccountMenuGarageLink)
