import React from 'react'
import PropTypes from 'prop-types'
import { FieldWrapper } from '../SharedStyles'
import { buildSteps } from '../../helpers'
import MultiColumnModal from '../MultiColumnModal'
import { YEARS, MAKES, MODELS, SUB_MODELS } from './constants'

const YmmeSelectors = ({
  model: {
    years,
    makes,
    models,
    subModels
  },
  ymme: {
    years: ymmeYears,
    makes: ymmeMakes,
    models: ymmeModels,
    subModels: ymmeSubModels
  },
  ymmeHandlers: {
    years: ymmeHandlersYears,
    makes: ymmeHandlersMakes,
    models: ymmeHandlersModels,
    subModels: ymmeHandlersSubModels
  },
  setRef,
  DropdownComponent,
  multiColumnMenu,
  handleYearDropdownFocus,
  handleMultiColumnModalShow,
  onMultiColumnYearSelect,
  onMultiColumnMakeSelect,
  onMultiColumnModelSelect,
  onMultiColumnSubModelSelect,
  userVehicleDetails: {
    editVehicle
  } = {}
}) =>
  <React.Fragment>
    <FieldWrapper data-at-ymme-year>
      <DropdownComponent
        static
        optionsMaxHeight={ 225 }
        isDisabled={ editVehicle === false }
        { ...years }
        { ...ymmeYears }
        { ...ymmeHandlersYears }
        innerRef={ ref => setRef({ name: YEARS, ref }) }
        handleMobileClick={ () => handleMultiColumnModalShow() }
        onFocus={ handleYearDropdownFocus } />
    </FieldWrapper>
    <FieldWrapper data-at-ymme-make>
      <DropdownComponent
        static
        optionsMaxHeight={ 170 }
        isDisabled={ editVehicle === false }
        { ...makes }
        { ...ymmeMakes }
        { ...ymmeHandlersMakes }
        innerRef={ ref => setRef({ name: MAKES, ref }) }
        verticalOffset={ multiColumnMenu ? -50 : undefined }
        handleMobileClick={ () => handleMultiColumnModalShow(1) } />
    </FieldWrapper>
    <FieldWrapper data-at-ymme-model>
      <DropdownComponent
        static
        optionsMaxHeight={ 120 }
        isDisabled={ editVehicle === false }
        { ...models }
        { ...ymmeModels }
        { ...ymmeHandlersModels }
        innerRef={ ref => setRef({ name: MODELS, ref }) }
        verticalOffset={ multiColumnMenu ? -100 : undefined }
        handleMobileClick={ () => handleMultiColumnModalShow(2) } />
    </FieldWrapper>
    <FieldWrapper data-at-ymme-submodel>
      <DropdownComponent
        static
        menuPlacement="top"
        isDisabled={ editVehicle === false }
        { ...subModels }
        { ...ymmeSubModels }
        { ...ymmeHandlersSubModels }
        innerRef={ ref => setRef({ name: SUB_MODELS, ref }) }
        verticalOffset={ multiColumnMenu ? -150 : undefined }
        handleMobileClick={ () => handleMultiColumnModalShow(3) } />
    </FieldWrapper>
    { multiColumnMenu &&
      <MultiColumnModal steps={ buildSteps([
        { value: ymmeYears.value, onClick: onMultiColumnYearSelect, ...years },
        { value: ymmeMakes.value, onClick: onMultiColumnMakeSelect, ...makes },
        { value: ymmeModels.value, onClick: onMultiColumnModelSelect, ...models },
        { value: ymmeSubModels.value, onClick: onMultiColumnSubModelSelect, ...subModels }
      ]) } />
    }
  </React.Fragment>

YmmeSelectors.propTypes = {
  DropdownComponent: PropTypes.elementType,
  getLabelGetter: PropTypes.func,
  handleMultiColumnModalShow: PropTypes.func,
  handleYearDropdownFocus: PropTypes.func,
  isGaragePage: PropTypes.bool,
  model: PropTypes.shape({
    years: PropTypes.shape(),
    makes: PropTypes.shape(),
    models: PropTypes.shape(),
    subModels: PropTypes.shape()
  }),
  multiColumnMenu: PropTypes.bool,
  onMultiColumnMakeSelect: PropTypes.func,
  onMultiColumnModelSelect: PropTypes.func,
  onMultiColumnSubModelSelect: PropTypes.func,
  onMultiColumnYearSelect: PropTypes.func,
  setRef: PropTypes.func,
  userVehicleDetails: PropTypes.shape({
    editVehicle: PropTypes.bool
  }),
  ymme: PropTypes.shape({
    years: PropTypes.shape(),
    makes: PropTypes.shape(),
    models: PropTypes.shape(),
    subModels: PropTypes.shape()
  }),
  ymmeHandlers: PropTypes.shape({
    years: PropTypes.shape(),
    makes: PropTypes.shape(),
    models: PropTypes.shape(),
    subModels: PropTypes.shape()
  })
}

export default YmmeSelectors
