export const VEHICLE_DETAILS_TAB = 0
export const MAKE_AND_MODEL_TAB = 1

// FORMS
export const VEHICLE_EDITOR_YMME = 'vehicleEditor'
export const VEHICLE_DETAILS_FORM = 'vehicleDetailsForm'
export const VEHICLE_EDITOR_LICENCE_FORM = 'vehicleEditorLicencePlate'

// MODALS
export const ACTION_CONFIRMATION_MODAL = 'vehicleEditorConfirmation'

// Action confirmation texts
export const CHANGE_TABS_ACTION = 'If you switch tabs, the updates made to your vehicle will be lost.'
export const CLOSE_MODAL_ACTION = 'If you close the modal, the updates made to your vehicle will be lost.'

// Make and model texts
export const CUSTOM_TIRE_SIZE_TEXT = 'Custom Size Results'
export const TIRES_NOT_FOUND_BUTTON_TEXT = 'Close'
export const TIRES_NOT_FOUND_DESCRIPTION = 'Sorry! No Tire Size(s) have been found for the vehicle you selected. Please select another vehicle.'

export const VEHICLE_DETAILS_INSTRUCTIONS = 'Click on the fields to edit your current vehicle details.'
export const MAKE_MODEL_INSTRUCTIONS = 'Any changes on this information will replace your current vehicle.'
export const DISABLED_MAKE_MODEL_INSTRUCTIONS = 'The year, make, and model of this car can\'t be edited because it is linked to past services with the license plate.'
