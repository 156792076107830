export default [
  'BOTStoreContactSERPPage',
  'BOTStoreContactBrandPage',
  'BOTStoreContactPage'
]

/*
Appears on:
Tires/Shop by Vehicle,
Tires/Shop by Tire Size,
Tires/Shop by Tire Brand,
Tires/Shop Trailer Tires,
Tires/Shop Winter Tires,
Tires/Shop EV Tires,
My Favorites page
*/
