import { markPromotionModalShown } from './actions'
import { handleActions } from 'redux-actions'

const initialState = {
  shown: false
}

const promotionModalReducer = handleActions({
  [markPromotionModalShown]: (state) =>
    ({
      ...state,
      shown: true
    })
}, initialState)

export default promotionModalReducer
