import { getArrayObjectByParam } from 'helpers/utils'
import { PROMO_DETAIL_PAGE_URL_RGX } from './constants'

const isRegionalPromo = ({ mainContent }) => {
  const [{
    promotionDetails: {
      isNationwidePromotions,
      promotionExpired
    } = {}
  } = {}] = getArrayObjectByParam({ array: mainContent, value: 'BOTPromotionDetails' })

  return !isNationwidePromotions || promotionExpired
}

const noIndexTests = {
  [PROMO_DETAIL_PAGE_URL_RGX]: isRegionalPromo
}

export const noIndexRunTests = ({ canonical, mainContent = []}) => {
  const _canonical = canonical || ''
  let output = []
  Object.keys(noIndexTests).forEach(key => {
    if (_canonical.match(key) && noIndexTests[key]({ mainContent })) {
      output = [{
        name: 'robots',
        content: 'noindex,nofollow'
      }]
    }
  })
  return output
}
