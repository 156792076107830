import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { dynamicDataAttribute, setLinkDataAttrVal, injectProps } from 'helpers/utils'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Icon from 'components/common/Icon/Icon'
import Link from 'components/common/Link/Link'
import { menuItemStyles } from '../../components/HeaderDropdown/HeaderDropdownMenu'
import * as DD from '../../components/HeaderDropdown/HeaderDropdown'
import * as Utility from '../../components/UtilityMenuStyles'

const HeaderAccountMenu = ({
  title,
  placeholders: {
    links = []
  },
  mobileMainMenuExpanded,
  active,
  elementId,
  combinedRefSetter,
  stopPropagation,
  combinedHandlers,
  focusBlurHoverHandlers
}) =>
  <DD.Wrapper
    id={ elementId }
    active={ active }
    ref={ combinedRefSetter }
    data-at-header-account>
    <Media.Desktop>
      <DD.Title
        tabIndex="0"
        active={ active }
        data-at-dropdown-focus
        data-at-dropdown-title
        { ...combinedHandlers }>
        <Icon asset="icon-account" width="14" height="14" margin="-2px 7px 0 0" />
        { title }
        <Icon
          asset="arrow-icon-full-white-down"
          alternative="arrow-icon-full-white-up"
          alt=""
          condition={ active }
          width="7"
          height="4"
          margin="0 0 0 7px" />
      </DD.Title>
      <DD.Content width="190px" hidden={ !active } { ...stopPropagation }>
        <LinkWrapper data-at-header-account-links>
          {
            React.Children.map(links, (link, index) =>
              injectProps(link, {
                ...dynamicDataAttribute('header-account-link', setLinkDataAttrVal(link)),
                ...dynamicDataAttribute('headerdropdown-item', index),
                ...focusBlurHoverHandlers
              }))
          }
        </LinkWrapper>
      </DD.Content>
    </Media.Desktop>
    <Media.Mobile>
      <Utility.Menu hidden={ mobileMainMenuExpanded }>
        <Utility.Item>
          <DD.Title
            bolder
            active={ active }
            addPaddingBottom={ active }
            { ...combinedHandlers }>
            <Icon data-static asset="icon-account" width="14" height="14" margin="-2px 7px 0 0" />
            { title }
            <Icon asset="arrow-right-white" width={ 10 } hidden={ active } />
          </DD.Title>
          <DD.Content hidden={ !active } { ...stopPropagation }>
            <LinkWrapper>
              { links }
            </LinkWrapper>
          </DD.Content>
        </Utility.Item>
      </Utility.Menu>
    </Media.Mobile>
  </DD.Wrapper>

HeaderAccountMenu.propTypes = {
  active: PropTypes.bool,
  combinedHandlers: PropTypes.shape(),
  combinedRefSetter: PropTypes.func,
  elementId: PropTypes.string,
  focusBlurHoverHandlers: PropTypes.shape(),
  links: PropTypes.arrayOf(PropTypes.shape()),
  mobileMainMenuExpanded: PropTypes.bool,
  placeholders: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape())
  }),
  stopPropagation: PropTypes.shape({
    onClick: PropTypes.func
  }),
  title: PropTypes.string,
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

export default HeaderAccountMenu

const LinkWrapper = styled.div`
  & ${Link} {
    ${menuItemStyles};
    ${Mixin.responsive('padding', '10px 0 9px 35px', '17px 0 14px 24px')};
  }
`
