import React from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { dynamicDataAttribute, toOneString } from 'helpers/utils'
import { components } from 'react-select'

const ReactSelectOption = ({
  children,
  isFocused,
  innerProps,
  selectProps: { handleOptionMouseOver },
  ...props
}) =>
  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  <li role="option" aria-selected={ isFocused } { ...innerProps } onMouseOver={ handleOptionMouseOver }>
    <components.Option isFocused={ isFocused } { ...props }>
      { children }
    </components.Option>
  </li>

const withModifiedInnerProps = withProps(({ innerProps = {}, label }) =>
  ({ innerProps: { ...dynamicDataAttribute('option', toOneString(label)), ...innerProps }}))

export default withModifiedInnerProps(ReactSelectOption)

ReactSelectOption.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape(),
  isFocused: PropTypes.bool,
  selectProps: PropTypes.shape()
}
