import styled from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import Media from 'components/common/Media'

export const Menu = styled.div`
  padding: 0 16px;
  background-color: ${Theme.colors.crimson};
`

export const Item = styled.div`
  padding: 4px 0;
  border-bottom: 1px solid ${Theme.colors.light_grey};
  color: ${Theme.colors.white};
  ${Media.mobile`
    :not(:first-child):last-child {
      border: none;
    }
  `}
`
