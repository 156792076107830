import { compose, withHandlers } from 'recompose'
import registerReduxModal from 'components/common/hoc/registerReduxModal'
import { noop } from 'helpers/utils'
import ConfirmationModal from './ConfirmationModal'
import { CONFIRMATION_MODAL } from '../../constants'

const withConfirmationModalHandlers = withHandlers({
  handleUpdate: ({ handleHide, onUpdate = noop }) => () => {
    handleHide()
    onUpdate()
  },
  handleCancel: ({ handleHide, onCancel = noop }) => () => {
    handleHide()
    onCancel()
  }
})

export default compose(
  registerReduxModal({ name: CONFIRMATION_MODAL }),
  withConfirmationModalHandlers
)(ConfirmationModal)
