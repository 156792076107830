import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Media from 'components/common/Media'
import Link from 'components/common/Link/Link'
import Button from 'components/common/Button/Button'
import HTMLContainer from 'components/common/HTMLContainer/HTMLContainer'
import Theme from 'components/common/Theme/Theme'
import Columns from 'components/common/Columns/Columns'
import * as UI from './SharedStyles'
import PromoBanner from './PromoBanner'
import { QUICK_APPOINTMENT_PAGE } from 'constant'
import {
  EXPERIENCE_A,
  PROMO_TITLE,
  PROMO_TITLE_ID,
  TEST_A_BANNER_SRC_DESKTOP,
  TEST_B_BANNER_SRC_DESKTOP,
  TEST_BANNER_SRC_MOBILE
} from './constants'

const TestBOTPromotionDetails = ({
  promotionDetails: {
    promoImageContent,
    hasEligibleProducts,
    promotionDescription,
    termsAndConditions,
    promotionExpired,
    promotionTitle,
    promotionRebate,
    linkToRebateDoc
  } = {},
  isModal,
  handleSeeEligibleTiresClick,
  handleFindStoreClick,
  regionNameLink,
  hasStore,
  promoDetailsExperience
}) =>
  <Fragment>
    <Media.Desktop>
      <UI.PromoTitle id={ PROMO_TITLE_ID }>{ PROMO_TITLE }</UI.PromoTitle>
      <PromoInfoBlock experienceA={ promoDetailsExperience === EXPERIENCE_A }>
        <StyledPromoColumns width={ promoDetailsExperience === EXPERIENCE_A ? '50% 50%' : '30% 70%' }>
          <PromoBanner
            testSrc={ promoDetailsExperience === EXPERIENCE_A ?
              TEST_A_BANNER_SRC_DESKTOP : TEST_B_BANNER_SRC_DESKTOP } />
          <StyledPromoInfoCTABlock experienceA={ promoDetailsExperience === EXPERIENCE_A }>
            {
              promotionDescription &&
              <TestStyledPromoDesc data-at-promotion-description content={ promotionDescription } />
            }
            {
              promotionExpired &&
              <UI.ExpiredPromotionMessage>
                This promotion has expired.
                {
                  promotionRebate && linkToRebateDoc &&
                  <Fragment>
                    &nbsp;You can still download the rebate form <Link
                      data-at-promotion-details-link
                      data-printless
                      underline
                      bolder
                      size="20"
                      to={ linkToRebateDoc }>Here
                    </Link>.
                  </Fragment>
                }
              </UI.ExpiredPromotionMessage>
            }
            <CTABlock>
              {
                hasEligibleProducts && !promotionExpired &&
                <Button
                  data-at-promotion-details-link
                  full="mobile"
                  data-printless
                  onClick={ handleSeeEligibleTiresClick }>See Eligible Tires
                </Button>
              }
              {
                !hasStore && !promotionExpired &&
                <TestEligibleTiresLink
                  data-at-find-a-store-link
                  button
                  full="mobile"
                  data-printless
                  onClick={ handleFindStoreClick }>Find A Store
                </TestEligibleTiresLink>
              }
            </CTABlock>
            <CTABlock>
              {
                !hasEligibleProducts && !isModal && !promotionExpired &&
                <TestEligibleTiresLink
                  data-at-make-an-appointment-link
                  button
                  full="mobile"
                  data-printless
                  to={ QUICK_APPOINTMENT_PAGE }>Request Appointment
                </TestEligibleTiresLink>
              }
            </CTABlock>
          </StyledPromoInfoCTABlock>
        </StyledPromoColumns>
      </PromoInfoBlock>
      {
        termsAndConditions &&
        <TestTermsAndConditions data-at-promotion-termsandconditions>
          <UI.TermsTitle>Terms & Conditions</UI.TermsTitle>
          <HTMLContainer content={ termsAndConditions } />
        </TestTermsAndConditions>
      }
    </Media.Desktop>
    <Media.Mobile>
      <div>
        <PromoBanner
          promoImageContent={ promoImageContent || '' }
          promotionTitle={ promotionTitle }
          testSrc={ TEST_BANNER_SRC_MOBILE } />
      </div>
      <UI.PromoTitle>{ PROMO_TITLE }</UI.PromoTitle>
      <UI.DescriptionWrapper>
        {
          hasEligibleProducts && !promotionExpired &&
          <UI.EligibleTiresButton
            data-at-promotion-details-link
            full="mobile"
            data-printless
            onClick={ handleSeeEligibleTiresClick }>See Eligible Tires
          </UI.EligibleTiresButton>
        }
        {
          promotionExpired &&
          <UI.ExpiredPromotionMessage>
            This promotion has expired.
            {
              promotionRebate && linkToRebateDoc &&
              <Fragment>
                &nbsp;You can still download the rebate form <Link
                  data-at-promotion-details-link
                  data-printless
                  underline
                  bolder
                  size="20"
                  to={ linkToRebateDoc }>Here
                </Link>.
              </Fragment>
            }
          </UI.ExpiredPromotionMessage>
        }
        {
          promotionDescription &&
          <UI.PromotionDescription data-at-promotion-description content={ promotionDescription } />
        }
        {
          !hasStore && !promotionExpired &&
          <UI.EligibleTiresLink
            data-at-find-a-store-link
            button
            full="mobile"
            data-printless
            onClick={ handleFindStoreClick }>Find A Store
          </UI.EligibleTiresLink>
        }
        {
          !hasEligibleProducts && !isModal && !promotionExpired &&
          <UI.EligibleTiresLink
            data-at-make-an-appointment-link
            button
            full="mobile"
            data-printless
            to={ QUICK_APPOINTMENT_PAGE }>Request Appointment
          </UI.EligibleTiresLink>
        }
        {
          !promotionExpired &&
          <UI.HeadlineWrapper>
            <UI.Logo medium inline asset="location-red" alt="" />
            <UI.Headline data-at-appointment-headline headline inline>Present In Store</UI.Headline>
          </UI.HeadlineWrapper>
        }
        {
          promotionRebate && linkToRebateDoc &&
          <UI.EligibleTiresLink
            data-at-promotion-details-link
            data-printless
            underline
            bolder
            block
            rel="noopener noreferrer"
            to={ linkToRebateDoc }>Print Rebate Form(s)
          </UI.EligibleTiresLink>
        }
        {
          regionNameLink &&
          <UI.EligibleTiresLink
            data-at-promotion-regionname-link
            underline
            bolder
            data-printless
            block
            to={ regionNameLink }>View All Regional Offers
          </UI.EligibleTiresLink>
        }
        {
          termsAndConditions &&
          <UI.TermsAndConditions data-at-promotion-termsandconditions>
            <UI.TermsTitle>Terms & Conditions</UI.TermsTitle>
            <UI.TermsAndConditionsFootnote id="footnote" content={ termsAndConditions } tabIndex="-1" />
          </UI.TermsAndConditions>
        }
      </UI.DescriptionWrapper>
    </Media.Mobile>
  </Fragment>

TestBOTPromotionDetails.propTypes = {
  handleFindStoreClick: PropTypes.func,
  handleSeeEligibleTiresClick: PropTypes.func,
  hasStore: PropTypes.bool,
  isModal: PropTypes.bool,
  promoDetailsExperience: PropTypes.string,
  promotionDetails: PropTypes.shape({
    promoImageContent: PropTypes.string,
    mobileImagePath: PropTypes.string,
    hasEligibleProducts: PropTypes.bool,
    eligibleProductLink: PropTypes.string,
    promotionExpired: PropTypes.bool,
    promotionTitle: PropTypes.string,
    promotionDescription: PropTypes.string,
    promotionRebate: PropTypes.bool,
    linkToRebateDoc: PropTypes.string,
    termsAndConditions: PropTypes.string
  }),
  regionNameLink: PropTypes.string
}

export default TestBOTPromotionDetails

const StyledPromoColumns = styled(Columns)`
  margin: 0 auto;
`

const StyledPromoInfoCTABlock = styled.div`
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
  background: ${Theme.colors.white};
  ${props => !props.experienceA && css`
    border: 1px solid ${Theme.colors.almost_grey};
  `}
`

const TestStyledPromoDesc = styled(HTMLContainer)`
  margin-bottom: 50px;
`

const PromoInfoBlock = styled.div`
  ${props => props.experienceA && css`
    background: ${Theme.colors.off_white};
  `}
  padding: 25px 100px;
  width: 100%;
  margin-bottom: 20px;
`

const TestEligibleTiresLink = styled(Link)`
  margin-left: 20px;
`

const CTABlock = styled.div`
  white-space: nowrap;
  margin-bottom: 15px;
`

const TestTermsAndConditions = styled(UI.TermsAndConditions)`
  padding: 0 100px;
`
